import styled from '@emotion/styled';
import { Modal } from 'react-bootstrap';
import circle from '../../../assets/logo/circle.svg';

export const CardMobileDiv = styled.div`
  margin-bottom: 8px;
  padding: 16px 20px;
  box-shadow: 0px 1px 4px rgba(35, 42, 64, 0.2);
  border-radius: 16px;
  position: relative;

  .card-title {
    margin: 0;
    color: #262758;
    font-weight: 600;
    font-size: 14px;
  }
  .vector {
    background: #f5f7fc;
    border-radius: 8px;
    width: 32px;
    height: 32px;
    text-align: center;
    cursor: pointer;
    border: none;
  }
  .status {
    width: 84px;
    text-align: center;
    border-radius: 16px;
    font-size: 12px;
    padding: 4px 0;
    font-weight: 600;
  }
  .statusActive {
    background: #d4fad4;
    color: #3d8a68;
  }
  .statusInactive {
    background: #f9e4d2;
    color: #9a0a0d;
  }
  .InactiveHead {
    color: #8990a3;
  }
  .Inactive {
    background: #e6e9f0;
    color: #8990a3;
  }
  .statusInvited {
    background: #fcf2cf;
    color: #914123;
  }
  .statusNotInvited {
    background: #e6e9f0;
    color: #191919;
  }

  .agency-details {
    font-size: 12px;
    color: #5a5f70;
    margin-top: 11px;
    margin-bottom: 18px;
  }

  .actionButton {
    font-weight: 600;
    background: #ecf4f9;
    text-align: center;
    padding: 5px;
    border-radius: 8px;
    color: #262758;
    width: 80%;
    height: 32px;
    font-size: 14px;
  }
  .activeActionButton {
    background: #e6e9f0;
    color: #262758;
  }
  .disabledActionButton {
    background: #ecf4f9;
    color: #262758;
  }
  .dropdownvector {
    width: 32px;
    height: 32px;
    text-align: center;
    border-radius: 8px;
    background: #f5f7fc;
    padding: 3px;
    border: none;
  }

  .calls {
    margin-right: 12px;
  }

  .subHead {
    padding-right: 8px;
    margin-right: 20px;
    border-right: 1px solid #d1d6e5;
  }

  .role-div {
    text-align: center;
    border-radius: 16px;
    font-size: 12px;
    padding: 4px 16px;
    font-weight: 600;
  }
  .primaryName {
    margin-left: 10px;
  }
  .addressName {
    margin-top: 12px;
  }
  .subHeadLocation {
    padding-right: 8px;
    margin-right: 20px;
  }
  .borderLocation {
    border-right: 1px solid #e6e9f0;
    height: 26px;
    position: absolute;
    right: 78px;
  }
  .dropdown-div {
    position: absolute;
    background: #fff;
    box-shadow: 0px 1px 4px 0px #26275833;
    border-radius: 8px;
    padding: 4px 16px;
    right: 0px;
    bottom: -61px;
    z-index: 4;
    width: 144px;
    cursor: pointer;
    line-height: 14px;
    div {
      padding: 4px 0;
    }

    .delete-div {
      color: #9a0a0d;
      display: flex;
      justify-content: space-between;
      align-items: center;
      border-top: 1px solid #e6e9f0;
    }

    .edit-div {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
  }
  .onlyEdit-dropdown-div {
    position: absolute;
    background: #fff;
    box-shadow: 0px 1px 4px 0px #26275833;
    border-radius: 8px;
    padding: 8px 16px;
    right: 0px;
    bottom: -30px;
    z-index: 4;
    width: 144px;
    height: 30px;
    cursor: pointer;
    line-height: 14px;
    .edit-div {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
  }
  }
`;

export const StyledModal = styled.div`
  position: relative;
  .crossbutton {
    position: absolute;
    background: #ffffff;
    right: 0;
    top: 0;
    border: none;
  }

  .cross {
    width: 20px;
  }

  h2 {
    font-size: 20px;
  }

  .subhead {
    font-size: 14px;
    line-height: 20px;
    color: #5c698c;
    margin-top: 8px;
  }

  .contact-para {
    font-weight: 600;
    margin-top: 24px;
    color: #000000;
  }

  .agency-fields {
    width: 99%;
    margin: 16px auto;
    color: #262758;
  }
  .agency-fields::placeholder {
    color: #262758;
  }

  .cancel-button {
    height: 48px;
    background: none;
    color: #262758;
    font-weight: 600;
    font-size: 16px;
    margin-left: auto;
    margin-right: 30px;
  }
  button {
    border: none;
  }
  .agentButton {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: #262758;
    color: #f5f7fc;
    border-radius: 8px;
    width: 160px;
    height: 48px;
    padding: 0 20px;
    &:disabled {
      background: #d1d6e5;
      border: none;
    }
  }

  .buttonsDiv {
    border-top: 1px solid #e6e9f0;
    position: fixed;
    width: 88%;
    bottom: 0;
    left: 20px;
    background: #fff;
    z-index: 2;
    padding: 20px 0px;
  }
  .field-section {
    padding-bottom: 50px;
    height: 84vh;
    overflow-y: scroll;
    scrollbar-color: transparent transparent;

    ::-webkit-scrollbar {
      width: 0 !important;
      display: none;
    }
  }

  .errorVal {
    margin-top: 12px;
    font-size: 14px;
    color: #cc0000;
    display: flex;
    align-items: center;
  }

  .warning {
    width: 20px;
    margin-right: 10px;
  }
  .form-check-input {
    width: 24px;
    height: 24px;
    margin-right: 12.48px;
  }
  .CheckboxText {
    font-weight: 600;
    color: #000;
  }
  .disabledCheckboxText {
    font-weight: 600;
    color: #8990a3;
  }
  .fields-section {
    padding-top: 50px;
  }
  .HeadContact {
    padding-bottom: 20px;
    border-bottom: 1px solid #e6e9f0;
  }
  @media screen and (min-width: 576px) {
    width: 360px;
    .subhead {
      max-width: 300px;
    }
    .buttonsDiv {
      left: 48px;
      width: 81%;
      padding: 20px 0px;
    }
    .field-section {
      padding-bottom: 50px;
      height: 74vh;
      overflow-y: scroll;
      scrollbar-color: transparent transparent;

      ::-webkit-scrollbar {
        width: 0 !important;
        display: none;
      }
    }
  }
  @media screen and (min-width: 1200px) {
    width: 420px;

    .cross {
      width: 32px;
    }

    h2 {
      font-size: 24px;
    }

    .buttonsDiv {
      left: 48px;
      width: 84%;
      padding: 20px 0px;
    }
  }
  @media screen and (min-width: 1900px) {
    width: 576px;

    h2 {
      font-size: 28px;
    }

    .subhead {
      font-size: 16px;
      max-width: 450px;
    }

    .contact-para {
      font-size: 20px;
    }

    .agency-fields {
      margin: 20px auto;
    }

    .cancel-button {
      height: 64px;
      margin-right: 36px;
    }

    .agentButton {
      width: 200px;
      height: 64px;
    }

    .buttonsDiv {
      left: 48px;
      width: 85%;
      padding: 20px 0px;
    }
  }
  @media screen and (min-width: 2800px) {
    width: 1152px;

    .cross {
      width: 64px;
    }

    h2 {
      font-size: 56px;
    }

    .subhead {
      font-size: 28px;
      max-width: 906px;
      line-height: 40px;
      margin-bottom: 48px;
    }

    .contact-para {
      font-size: 40px;
      margin-top: 48px;
    }

    .agency-fields {
      margin: 40px auto;
    }

    .cancel-button {
      height: 128px;
      margin-right: 72px;
      font-size: 32px;
    }

    .agentButton {
      width: 400px;
      height: 128px;
      font-size: 32px;
      border-radius: 16px;
      padding: 40px;

      img {
        width: 25px;
      }
    }

    .buttonsDiv {
      padding: 80px 0px;
    }
  }
`;

export const PipelineInfoStyles = styled.div`
  background: #ffffff;
  .content-div {
    padding: 18px 20px 60px 20px;
  }
  .navbar-button {
    margin-right: 16px;
    background: #e6e9f0;
    border-radius: 8px;
    border: none;
    color: #262758;
    font-size: 16px;
    font-weight: 600;
    min-width: 40px;
    height: 40px;
  }
  .topbarButtons {
    position: fixed;
    right: 10%;
  }
  .filter-button {
    background: #e6e9f0;
    border-radius: 8px;
    width: 212px;
  }
  .createButton {
    width: 204px;
    padding: 8px 20px;
    background: #262758;
    border-radius: 8px;
    color: #f5f7fc;
    .buttonAdd {
      padding-left: 20px;
    }
  }
  .PipelineCard {
    margin: 24px 0px 0px 0px;
    border-radius: 8px;
    padding: 16px 24px;
    background: #ffffff;
    width: 100%;
    height: 100.69px;
  }
  .checkboxDiv {
    padding: 15px 24px;
    padding-left: 8px;
    margin-right: 24px;
    border-right: 1px solid #e6e9f0;
  }
  .switch {
    position: relative;
    display: inline-block;
    border-radius: 40px;
    width: 60px;
    height: 34px;
    background: #dfe6f5;
    transition: 0.4s;

    .inputCheckbox {
      opacity: 0;
      width: 0;
      height: 0;
    }
  }
  .slider {
    position: absolute;
    border-radius: 40px;
    cursor: pointer;
    top: 0;

    right: 0;
    left: 0;
    bottom: 0;
  }
  .slider:before {
    position: absolute;
    content: '';
    height: 26px;
    width: 26px;
    left: 4px;
    bottom: 4px;
    border-radius: 50%;
    background: #ffffff;
    transition: 0.4s;
  }
  .inputCheckbox:checked + .slider:before {
    transform: translateX(26px);
  }
  .inputCheckbox:checked + .slider {
    background: #58c070;
  }
  .NextVector {
    width: 12px;
    transform: rotate(-90deg);
  }
  .PipelineStage {
    padding: 0px 10px 0px 10px;
  }
  .PipelineStagesRow {
    padding-bottom: 10px;
  }
  .SubHead {
    font-weight: 600;
    font-size: 20px;
  }
  .SubHead + span span {
    padding-left: 0;
  }
  .vector {
    background: #f5f7fc;
    border-radius: 8px;
    width: 40px;
    height: 40px;
    text-align: center;
    cursor: pointer;
    posiotn: relative;
  }
  .everyoneDiv {
    width: 115px;
    margin-right: 24px;
    background: #e6e9f0;
    height: 24px;
    border-radius: 16px;
    color: #191919;
    margin-top: 8px;
    font-size: 12px;
    font-weight: 600;
  }
  .eye {
    margin-right: 8px;
  }
  .leftArrow {
    transform: rotate(180deg);
    filter: invert(0%) sepia(14%) saturate(2240%) hue-rotate(295deg)
      brightness(0%) contrast(103%);
    margin-right: 15px;
    cursor: pointer;
  }
  .dropdown-div {
    position: absolute;
    background: #fff;
    box-shadow: 0px 1px 4px 0px #26275833;
    border-radius: 8px;
    padding: 4px 16px;
    right: 0px;
    bottom: -78px;
    z-index: 1;
    width: 144px;
    cursor: pointer;
    line-height: 14px;
    div {
      padding: 6px 0;
    }

    .delete-div {
      color: #9a0a0d;
      display: flex;
      justify-content: space-between;
      align-items: center;
      border-top: 1px solid #e6e9f0;
    }

    .edit-div {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
    .junk-div {
      display: flex;
      justify-content: space-between;
      align-items: center;
      border-top: 1px solid #e6e9f0;
    }
  }
  .HeadNavigator {
    position: fixed;
    top: 16px;
  }
  .searchField {
    padding: 6px;
    margin: 10px 10px;
    border-radius: 8px;
    background: #e6e9f0;
  }
  .search-Field {
    padding: 6px;
    margin: 10px 4px;
    border-radius: 8px;
    background: #e6e9f0;
  }
  .inputSearchField {
    outline: none;
    border: none;
    background: #e6e9f0;
  }
  .filterField {
    padding: 8px;
    margin-right: 8px;
  }
  .mobHead {
    margin: 16px 0 8px 20px;
  }
  @media screen and (min-width: 576px) {
    background: #fafafa;
    .content-div {
      padding: 90px 32px 60px 32px;
    }

    .switch {
      width: 36px;
      height: 20px;
      margin-top: 9px;
    }
    .slider {
      top: 0;

      right: 0;
      left: 0;
      bottom: 0;
    }
    .slider:before {
      height: 14px;
      width: 14px;
      left: 4px;
      bottom: 3px;
    }
    .inputCheckbox:checked + .slider:before {
      transform: translateX(15px);
    }
  }
  @media screen and (min-width: 1200px) {
    .content-div {
      padding: 98px 48px 0 130px;
    }
  }
  @media screen and (min-width: 1900px) {
    .content-div {
      padding: 116px 120px 0px 208px;
    }

    .switch {
      width: 44px;
      height: 24px;
      margin-top: 7px;
    }
    .slider {
      top: 0;

      right: 0;
      left: 0;
      bottom: 0;
    }
    .slider:before {
      width: 18px;
      height: 18px;
      left: 5px;
      bottom: 3px;
    }
    .inputCheckbox:checked + .slider:before {
      transform: translateX(18px);
    }
  }
`;

export const StyledDeleteModal = styled(Modal)`
  .closeButton {
    position: absolute;
    right: 18px;
    top: 18px;
    cursor: pointer;
  }

  .errorVal {
    margin-top: 12px;
    font-size: 14px;
    color: #cc0000;
    display: flex;
    align-items: center;

    .warning {
      width: 20px;
      margin-right: 10px;
    }
  }

  h2 {
    margin-bottom: 16px;
  }

  .sub-heading {
    font-size: 14px;
    margin-bottom: 24px;
  }

  .action-div {
    font-size: 16px;
    font-weight: 600;
    padding-bottom: 16px;
    margin-bottom: 16px;
    border-bottom: 1px solid #e6e9f0;
  }

  .action {
    margin-top: 16px;
    border: 1px solid #cad9f3;
    padding: 14px 18px;
    border-radius: 8px;
    cursor: pointer;
    display: flex;
    align-items: center;
  }

  .action.active {
    border: 1px solid #262758;
  }

  .form-check-input {
    margin-right: 16px;
    width: 20px;
    height: 20px;
  }

  .form-check-input:checked[type='radio'] {
    background-image: url(${circle});
    background-color: #fff;
    box-shadow: none;
  }

  .buttonsDiv {
    border-top: 1px solid #e6e9f0;
    padding-top: 32px;
    justify-content: right;
  }
  .secondaryButton {
    border: none;
    background: none;
    font-size: 16px;
    color: #262758;
    font-weight: 600;
    padding-left: 10px;
  }

  .WarningButton {
    height: 48px;
    width: 219px;
    font-weight: 600;
    color: #fff;
    background: #cc0000;
    border-radius: 8px;
    border: none;
    padding: 0 25px;
    margin-left: 30px;
  }
  .deleteButton {
    height: 48px;
    width: 156px;
    font-weight: 600;
    color: #fff;
    background: #cc0000;
    border-radius: 8px;
    border: none;
    padding: 0 25px;
    margin-left: 30px;
  }
  .PipelineFlow {
    background: #f5f7fc;
    border-radius: 8px;
    margin: 23px 0 30px 0;
    height: 70px;
    padding: 0 20px;
    font-size: 14px;
  }
  .nameSpace {
    margin-left: 5px;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .delete-img {
    margin-bottom: 32px;
  }

  @media screen and (min-width: 576px) {
    .modal-content {
      width: 456px;
    }
  }

  @media screen and (min-width: 1200px) {
    .delete-img {
      margin-bottom: 48px;
    }
  }

  @media screen and (max-width: 576px) {
    .modal-dialog {
      margin: 0;
    }
    &.fade {
      .modal-dialog {
        transform: translate(0, 50px);
      }
    }
    &.show {
      .modal-dialog {
        transform: none;
      }
    }
    .modal-content {
      position: fixed;
      bottom: 0;
      width: 100vw;
      border-radius: 16px 16px 0px 0px;
      padding: 56px 20px 32px 20px;
      border: 0;
      box-shadow: 0px 4px 16px 0px #26275833;
    }
  }
`;

export const SettingsDiv = styled.div`
  .setting-card {
    width: 100%;
    padding: 12px;
    background: #ffffff;
    border-radius: 8px;
    margin-bottom: 12px;
    position: relative;

    .setting-logo {
      width: 44px;
      height: 44px;
      border-radius: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 0 12px 0 0;
      background: #fafafa;

      img {
        width: 28px;
      }
    }
    h6 {
      font-weight: 600;
      margin-bottom: 4px;
    }

    .setting-description {
      font-size: 12px;
      color: #8990a3;
    }

    :hover {
      box-shadow: 0px 6px 24px 0px #26275833;
      cursor: pointer;
    }
  }

  .active-card {
    box-shadow: 0px 6px 24px 0px #26275833;
  }

  .inactive-card {
    opacity: 0.7;
  }

  .disabled-card,
  .disabled-card:hover {
    opacity: 0.4;
    cursor: not-allowed;
    box-shadow: none;
  }

  .results-div {
    position: absolute;
    top: 48px;
    box-shadow: 0px 2px 8px 0px #26275833;
    background: #fff;
    z-index: 1;
    border-radius: 8px;
    padding: 4px;
    width: 100%;
    left: 0;

    button {
      background: #fff;
      border: 0;
      padding: 8px 12px;
      width: 100%;
      border-radius: 8px;
      text-align: left;

      :hover {
        background: #f5f7fc;
      }
    }
  }

  .info {
    position: relative;
    .tooltiptext {
      visibility: hidden;
      width: 240px;
      background: #191919;
      color: #d1d6e5;
      padding: 16px 24px;
      border-radius: 8px;
      font-size: 14px;
      text-align: left;
      position: absolute;
      z-index: 1;
      top: 30px;
      right: -8px;

      ::after {
        content: ' ';
        position: absolute;
        top: -10px;
        right: 12px;
        margin-left: -5px;
        border-width: 5px;
        border-style: solid;
        border-color: transparent transparent #191919 transparent;
      }
    }

    :hover {
      .tooltiptext {
        visibility: visible;
      }
    }
  }

  @media screen and (min-width: 576px) {
    .setting-card {
      width: 49.1%;
      padding: 16px;
      margin-bottom: 16px;

      .setting-logo {
        margin: 0 16px 0 0;
      }
    }
  }

  @media screen and (min-width: 1200px) {
    .setting-card {
      width: 32.4%;
      padding: 16px 12px;
      text-align: center;

      .setting-logo {
        width: 52px;
        height: 52px;
        margin: 0 auto 16px auto;
        img {
          width: 32px;
        }
      }

      h6 {
        margin-bottom: 8px;
      }

      .setting-description {
        font-size: 14px;
      }
    }

    .info {
      position: absolute;
      top: 12px;
      right: 12px;
    }
  }

  @media screen and (min-width: 1900px) {
    .setting-card {
      width: 24.1%;
      padding: 20px 16px;
      margin-bottom: 20px;

      .setting-logo {
        width: 68px;
        height: 68px;
        margin-bottom: 20px;

        img {
          width: 40px;
        }
      }

      h6 {
        font-size: 20px;
      }
    }

    .info {
      top: 16px;
      right: 16px;
      img {
        width: 28px;
      }

      .tooltiptext {
        top: 35px;
        right: -5px;
      }
    }
  }

  @media screen and (max-width: 576px) {
    .content-div {
      height: 100vh;
      background: #fafafa;

      .setting-description {
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
        max-width: 196px;
      }
    }

    .search-div {
      max-width: 100%;
      width: 100%;
    }
  }
`;

export const MobilePipelineDeleteStyle = styled.div`
  .field-section {
    padding-bottom: 20px;
    height: 70vh;
    overflow-y: scroll;
    scrollbar-color: transparent transparent;

    ::-webkit-scrollbar {
      width: 0 !important;
      display: none;
    }
  }

  .nav-logo {
    width: 26px;
    height: 26px;
    margin: 0 auto;
  }
  .avatar-img {
    border-radius: 50%;
    width: 28px;
    height: 28px;
  }
  .agency-fields {
    width: 320px;
    margin: 16px auto;
    color: #262758;
  }
  .agency-fields::placeholder {
    color: #262758;
  }
  .row {
    border: 1px solid #f5f5f5;
  }

  .quickFormsDropDown {
    position: fixed;
    bottom: 60px;
    transform: translate(0);
    transition: all 0.5s ease-in-out;
    left: 0;
    animation-name: formDrawer;
    animation-duration: 0.4s;
    width: 100vw;
    padding: 32px;
    background: #fff;
    border-radius: 16px 16px 0px 0px;
    backdrop-filter: blur(10px);
    @keyframes formDrawer {
      from {
        transform: translateY(100%);
      }
      to {
        transform: translateY(0%);
      }
    }
    z-index: 5;

    h2 {
      padding-top: 12px;
      padding-bottom: 12px;
      font-size: 20px;
      color: #262758;
    }

    .closed {
      animation-duration: 0.3s;
      transform: translateY(0%);
    }
  }

  .head {
    font-weight: 600;
    font-size: 12px;
    color: #262758;
    margin-top: 10px;
  }

  .drawerHandle {
    width: 88px;
    border: 4px solid #e6e9f0;
    margin: 0 auto;
  }
  .backgroundQuickAdd {
    left: 0;
    right: 0;
    bottom: 60px;
    top: 0;
    position: fixed;
    background: rgba(35, 42, 64, 0.6);
    z-index: 3;
  }
  .crossbutton {
    position: absolute;
    background: #ffffff;
    right: 8px;
    top: 14px;
    border: none;
  }

  .cross {
    width: 20px;
  }
  .closeButton {
    position: absolute;
    right: 18px;
    top: 18px;
    cursor: pointer;
  }

  h2 {
    margin-bottom: 0;
  }

  .sub-heading {
    font-size: 14px;
    margin-top: 16px;
    margin-bottom: 24px;
  }
  .PipelineFlow {
    background: #f5f7fc;
    border-radius: 8px;
    margin: 23px 0 30px 0;
    height: 70px;
    padding: 0 20px;
    font-size: 14px;
  }
  .nameSpace {
    margin-left: 5px;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .buttonsDiv {
    margin-top: 50px;
    justify-content: right;
  }

  .WarningButton {
    height: 48px;
    width: 219px;
    font-weight: 600;
    color: #fff;
    background: #cc0000;
    border-radius: 8px;
    border: none;
    padding: 0 25px;
    margin-left: 30px;
  }
  .deleteButton {
    height: 48px;
    width: 156px;
    font-weight: 600;
    color: #fff;
    background: #cc0000;
    border-radius: 8px;
    border: none;
    padding: 0 25px;
    margin-left: 30px;
  }

  .delete-img {
    margin: 20px 0;
  }
`;

export const LargeStyledModal = styled.div`
  position: relative;
  width: 100vw;
  .crossbutton {
    position: absolute;
    background: #ffffff;
    right: 37px;
    top: 0;
    border: none;
  }
  .cross {
    width: 20px;
  }

  h2 {
    font-size: 20px;
  }

  .subhead {
    font-size: 14px;
    line-height: 20px;
    color: #5c698c;
    margin-top: 8px;
  }

  .contact-para {
    font-weight: 600;
    margin-top: 24px;
    color: #000000;
  }

  .goal-fields {
    width: 99%;

    color: #262758;
  }
  .goal-fields::placeholder {
    color: #262758;
  }

  .cancel-button {
    height: 48px;
    background: none;
    color: #262758;
    font-weight: 600;
    font-size: 16px;
    margin-left: auto;
    margin-right: 30px;
  }
  button {
    border: none;
  }
  .agentButton {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: #262758;
    color: #f5f7fc;
    border-radius: 8px;
    width: 160px;
    height: 48px;
    padding: 0 20px;
  }

  .buttonsDiv {
    border-top: 1px solid #e6e9f0;
    position: fixed;
    width: 90%;
    bottom: 0;
    left: 20px;
    background: #fff;
    z-index: 2;
    padding: 20px 0px;
  }
  .field-section {
    padding-bottom: 50px;
    height: 84vh;
    overflow-y: scroll;
    scrollbar-color: transparent transparent;

    ::-webkit-scrollbar {
      width: 0 !important;
      display: none;
    }
  }

  .errorVal {
    margin-top: 12px;
    font-size: 14px;
    color: #cc0000;
    display: flex;
    align-items: center;
  }

  .warning {
    width: 20px;
    margin-right: 10px;
  }
  .form-check-input {
    width: 24px;
    height: 24px;
    margin-right: 12.48px;
  }
  .CheckboxText {
    font-weight: 600;
    color: #000;
  }
  .disabledCheckboxText {
    font-weight: 600;
    color: #8990a3;
  }
  .checkboxDiv {
    padding: 15px 0px;
    margin-right: 24px;
    &.edit-checkboxDiv {
      padding: 13px 0px 28px;
    }
  }

  .error-container {
    .errorVal {
      width: 100%;
      background: #f8e3df;
      color: #000;
      border-radius: 8px;
      border-left: solid 8px #cc0000;
      padding: 10px;
      margin: 2px 0 -26px 0;
    }
  }
  .switch {
    position: relative;
    display: inline-block;
    border-radius: 40px;
    width: 48px;
    height: 24px;
    background: #cad9f3;
    transition: 0.4s;

    .inputCheckbox {
      opacity: 0;
      width: 0;
      height: 0;
    }
  }
  .slider {
    position: absolute;
    border-radius: 40px;
    cursor: pointer;
    top: 0;
    right: 0;
    left: 2px;
    bottom: 0;
  }
  .slider:before {
    position: absolute;
    content: '';
    height: 18px;
    width: 18px;
    left: 0;
    bottom: 3px;
    border-radius: 50%;
    background: #ffffff;
    transition: 0.4s;
  }
  .inputCheckbox:checked + .slider:before {
    transform: translateX(26px);
  }
  .inputCheckbox:checked + .slider {
    background: #58c070;
  }
  .firstFields {
    margin-top: 24px;
  }
  .Goal-field {
    margin: 8px 3px;
    width: 88%;
    color: #262758;
  }
  .ListHeading {
    width: 487px;
    font-size: 16px;
    font-weight: 600;
    height: 54px;
    background: #fafafa;
    border-radius: 8px 8px 0px 0px;
    padding: 0 24px;
  }
  .ListDetails {
    width: 487px;
    font-size: 16px;
    font-weight: 600;
    height: 54px;
    background: #f5f7fc;
    margin: 8px 0;
    border-radius: 8px 8px 0px 0px;
    padding-left: 24px;
    padding-right: 0px;
  }
  .TotalHeading {
    width: 487px;
    font-size: 16px;
    font-weight: 600;
    height: 54px;
    background: #ecf4f9;
    color: #191919;
    border-radius: 8px 8px 0px 0px;
    padding: 0 67px 0 24px;
  }
  .detailsContainer {
    max-height: 50vh;
    width: 487px;
    padding-bottom: 6px;
    overflow: auto;

    &::-webkit-scrollbar {
      width: 0px;
      height: 159px;
    }
  }
  .inLineFIeld {
    width: 100px;
    position: relative;
    .editDesc {
      padding-right: 2px;
      top: 18px;
      left: 103px;
      position: absolute;
    }
    .smalledit {
      left: 60px;
    }
    .prefixDollar {
      position: absolute;
      padding-right: 2px;
      font-size: 14px;
      font-weight: 600;
      top: 16px;
      z-index: 2;
      left: 6px;
    }
    :focus-within {
      .editDesc {
        display: none;
      }
      .smalledit {
        left: 80px;
      }
    }
  }
  .totalPremiumDiv {
    margin-right: 60px;
  }
  .info {
    position: relative;
    .tooltiptext {
      visibility: hidden;
      width: 240px;
      background: #191919;
      color: #d1d6e5;
      padding: 16px 24px;
      border-radius: 8px;
      font-size: 14px;
      text-align: left;
      position: absolute;
      z-index: 1;
      top: 30px;
      right: -8px;

      ::after {
        content: ' ';
        position: absolute;
        top: -10px;
        right: 12px;
        margin-left: -5px;
        border-width: 5px;
        border-style: solid;
        border-color: transparent transparent #191919 transparent;
      }
    }

    :hover {
      .tooltiptext {
        visibility: visible;
      }
    }
  }
  .AgencyListContainer {
    max-width: 487px;
    max-height: 65vh;
    overflow-x: auto;
    padding-right: 42px;
    &::-webkit-scrollbar {
      width: 0px;
      height: 159px;
    }
  }
  .inputField {
    margin-top: 0px;
  }
  .modalHeading.edit-goal-modalHeading {
    padding-bottom: 20px;
  }
  @media screen and (min-width: 576px) {
    width: 100%;
    height: 100%;
    padding-right: 14px;
    padding-bottom: 80px;
    overflow-y: auto;
    overflow-x: auto;
    &::-webkit-scrollbar {
      width: 7px;
      height: 159px;
    }
    .crossbutton {
      right: 18px;
    }
    .subhead {
      max-width: 300px;
    }
    .buttonsDiv {
      left: 48px;
      width: 89%;
      padding: 20px 8px;
    }
    .modalHeading {
      padding-bottom: 32px;
      border-bottom: 1px solid #e6e9f0;
      &.edit-goal-modalHeading {
        padding-bottom: 25px;
      }
    }
    .firstFields {
      margin-top: 24px;
    }
    .Goal-field {
      width: 360px;
    }
    .ListHeading {
      width: 100%;
      padding-left: 32px;
    }
    .ListDetails {
      width: 100%;
      margin: 8px 0;
      padding-left: 32px;
    }
    .TotalHeading {
      width: 100%;
      padding: 0 96px 0 24px;
    }
    .detailsContainer {
      width: 100%;
      overflow-y: auto;
      overflow-x: hidden;
      &::-webkit-scrollbar {
        width: 0px;
        height: 159px;
      }
    }
    .inLineFIeld {
      width: 130px;
      .editDesc {
        top: 16px;
        left: 1250px;
      }
      .smalledit {
        left: 80px;
      }
    }
    .AgencyListContainer {
      max-width: 100%;
      max-height: 100%;
      overflow-x: hidden;
      padding-right: 0px;
    }
    .totalPremiumDiv {
      margin-right: 3rem;
    }
    .ListHeading {
      padding-right: 50px;
    }
  }
  @media screen and (min-width: 1200px) {
    width: 979px;
    .cross {
      width: 32px;
    }
    .edit-checkboxDiv {
      padding-bottom: 20px;
    }
    .modalHeading.edit-goal-modalHeading {
      padding-bottom: 20px;
    }
    h2 {
      font-size: 24px;
    }
    .buttonsDiv {
      width: 92%;
      padding: 20px 0px;
    }
    .Goal-field {
      margin: 8px 3px;
      width: 420px;
    }
    .detailsContainer {
      max-height: 47vh;
    }
    .totalPremiumDiv {
      margin-right: 0px;
    }
  }
  @media screen and (min-width: 1900px) {
    width: 1260px;
    h2 {
      font-size: 28px;
    }
    .edit-checkboxDiv {
      padding-bottom: 28px;
    }
    .modalHeading.edit-goal-modalHeading {
      padding-bottom: 32px;
    }

    .Goal-field {
      margin: 8px 0px;
      width: 576px;
    }

    .cancel-button {
      height: 64px;
      margin-right: 36px;
    }

    .agentButton {
      width: 200px;
      height: 64px;
    }

    .buttonsDiv {
      left: 60px;
      right: 60px;
      width: 90%;
      padding: 20px 0px;
    }
    .detailsContainer {
      max-height: 42vh;
    }
    .totalPremiumDiv {
      margin-right: -3rem;
    }
  }
  @media screen and (min-width: 2800px) {
    width: 1152px;

    .cross {
      width: 64px;
    }

    h2 {
      font-size: 56px;
    }

    .subhead {
      font-size: 28px;
      max-width: 906px;
      line-height: 40px;
      margin-bottom: 48px;
    }

    .contact-para {
      font-size: 40px;
      margin-top: 48px;
    }

    .cancel-button {
      height: 128px;
      margin-right: 72px;
      font-size: 32px;
    }

    .agentButton {
      width: 400px;
      height: 128px;
      font-size: 32px;
      border-radius: 16px;
      padding: 40px;

      img {
        width: 25px;
      }
    }

    .buttonsDiv {
      padding: 80px 0px;
    }
  }
`;
