import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';
import moment from 'moment';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { AppDispatch } from '../../../store';
import {
  onUpdateOpportunityDetails,
  getOpportunityDetails,
  OpportunitiesSliceState,
  selectOpportunity,
} from '../../../store/opportunities/opportunitiesSlice';
import {
  getCustomFields,
  selectAllCustomFields,
} from '../../../store/customFields/customFieldsSlice';
import { getPipelines } from '../../../store/pipelines/pipelineSlice';
import { fetchAgents } from '../../../api/agents';
import { fetchAccounts } from '../../../api/account';
import { fetchProducts } from '../../../api/product';
import { getLeadSourceTypes } from '../../../api/lead';
import Modal from '../../../components/EditDetailsModal';
import SuccessCard from '../../../components/SuccessCard';
import InputField from '../../../components/DetailsEditFormInput';
import EditDate from '../../../components/DateFieldEdit';
import SelectField from '../../../components/SelectFieldEdit';
import SearchDropdown from '../../../components/SearchDropdown';
import CustomFieldInput from '../../../components/CustomfieldsInput';
import { IProducer } from '../../../interfaces/Agent';
import { IAccount } from '../../../interfaces/Account';
import { IPipelineStage, IPipeline } from '../../../interfaces/Opportunity';
import { IProduct } from '../../../interfaces/Product';
import { ILeadSourceType } from '../../../interfaces/Lead';
import { StyledEditOpportunityDetailsDiv } from './styles';
import { SuccessAlign } from '../../../styles/SuccessAlign';
import cross from '../../../assets/logo/cross.svg';
import tick from '../../../assets/logo/tick.svg';
import bar from '../../../assets/logo/bar.svg';
import warning from '../../../assets/logo/warning.svg';

const OpportinitySchema = Yup.object().shape({
  opportunityName: Yup.string().required('Please enter the name.'),
  pipelineStage: Yup.object().shape({
    value: Yup.string().required('Please select a pipeline stage'),
  }),
  pipeline: Yup.object().shape({
    value: Yup.string().required('Please select a pipeline'),
  }),
});

const EditOpportunityDetails = ({
  UUID,
  show,
  closeModal,
}: {
  UUID: string;
  show: string;
  closeModal: Function;
}) => {
  const dispatch = useDispatch<AppDispatch>();

  const {
    register,
    handleSubmit,
    reset,
    setValue,
    control,
    watch,
    getValues,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(OpportinitySchema),
  });

  const opportunityDetails = useSelector(selectOpportunity);

  const watchAllFields = watch();
  const watchPipeline = watch('pipeline');

  const [showSuccess, setshowSuccess] = useState(false);
  const [producers, setProducers] = useState<IProducer[]>([]);
  // const [accounts, setAccounts] = useState<IAccountNames[]>([]);
  const [productCategory, setProductCategory] = useState<IProduct[]>([]);
  const [policySearch, setPolicySearch] = useState('');
  const [policy, setPolicy] = useState('');
  const [loadingStatus, setLoadingStatus] = useState('idle');
  const [wonState, setWonState] = useState(false);
  const [producerSearch, setProducerSearch] = useState('');
  const [producer, setProducer] = useState('');
  const [producerName, setProducerName] = useState('');
  const [user, setUser] = useState<any>({});
  const [productType, setProductType] = useState('');
  const [productUUID, setProductUUID] = useState('');

  const [accounts, setAccounts] = useState<any[]>([]);

  const [accountSearch, setAccountSearch] = useState('');
  const [account, setAccount] = useState('');
  const [isCommercial, setCommercial] = useState(false);

  const [submit, setSubmit] = useState(false);
  const [pipelines, setPipelines] = useState<any[]>([]);
  const [sourceTypes, setSourceTypes] = useState<ILeadSourceType[]>([]);

  const [isLoading, setIsLoading] = useState(false);

  const onSetPipelineValue = (item?: IPipeline) => {
    if (item) {
      const temp = item.PipelineStages.reduce((t: any, o) => {
        if (o.nextStageId) {
          t[o.nextStageId] = o;
        } else {
          t['last'] = o;
        }
        return t;
      }, {});

      let result: IPipelineStage[] = [];

      let p: any = 'last';

      while (temp[p]) {
        result.unshift(temp[p]);
        p = temp[p].id;
      }

      return result
        .filter((item, index) => index < result.length - 2)
        .map((el: any) => {
          return { label: el.name, value: el.uuid };
        });
    }
    return [];
  };

  useEffect(() => {
    const user = JSON.parse(localStorage.getItem('user') || '{}');
    if (user) {
      setUser(user);
    }
    dispatch(
      getPipelines({
        sortBy: '',
        orderBy: '',
        searchBy: '',
        successCB: (data: IPipeline) => {},
      })
    ); // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (policy !== '') {
      dispatch(
        getPipelines({
          sortBy: '',
          orderBy: '',
          searchBy: '',
          successCB: (data: any) => {
            setPipelines(data);
          },
          agencyUUID: '',
          productUUID: productType === 'PRODUCT' ? policy : '',
          customProductUUID: productType === 'CUSTOM_PRODUCT' ? policy : '',
        })
      );
    } else {
      setPipelines([]);
    }
    setValue('pipeline', '');
    setValue('pipelineStage', '');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [policy]);

  useEffect(() => {
    dispatch(
      getCustomFields({
        uuid: '',
        resourceName: 'OPPORTUNITY',
        successCB: () => {},
      })
    ); // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const customFields: any = useSelector(selectAllCustomFields);

  useEffect(() => {
    customFields.map((item: any) => {
      if (item.FieldType?.name === 'DATEPICKER' && item.data?.defaultValue) {
        setValue(item.uuid, moment(item.data?.defaultValue).toDate());
      }
      if (item.FieldType?.name === 'MULTIPLE_CHOICE') {
        const filteredData: any = item.data.options.filter(
          (it: any) => it.default === true
        );
        setValue(
          item.uuid,
          filteredData.map((el: any) => {
            return { value: el.order, label: el.value };
          })
        );
      }
      if (item.FieldType?.name === 'SINGLE_CHOICE') {
        setValue(item.uuid, {
          label:
            item.data.options[parseInt(item.data?.defaultValue, 10)]?.value,
          value:
            item.data.options[parseInt(item.data?.defaultValue, 10)]?.order,
        });
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customFields]);

  const onSubmit = (data: any) => {
    data.customFields = {};
    customFields.map((item: any) => {
      if (item.uuid in data) {
        data.customFields[item.uuid] = data[item.uuid];
      }
    });
    setSubmit(true);
    if (policy) {
      setSubmit(false);
      data.action = 'updateOpportunity';
      data.agentUUID = producer;
      data.accountUUID = account;
      if (productType === 'PRODUCT') {
        data.productUUID = productUUID;
      } else if (productType === 'CUSTOM_PRODUCT') {
        data.customProductUUID = productUUID;
      }
      data.pipeline = data.pipeline?.value;
      data.pipelineStage = data.pipelineStage.value;
      data.statusUUID = data.opportunityStatus?.value;
      data.sourceName = data.sourceName?.label;

      dispatch(
        onUpdateOpportunityDetails({
          data,
          UUID,
          successCB: () => {
            closeModal('closing');
            setTimeout(() => {
              setshowSuccess(true);
            }, 300);
            setTimeout(() => {
              reset();
              setshowSuccess(false);
              setTimeout(() => {
                closeModal('closed');
              }, 300);
            }, 2000);
          },
          errorCB: () => {},
        })
      );
    }
  };

  const onCloseModal = () => {
    closeModal('closing');
    setTimeout(() => {
      closeModal('closed');
    }, 300);
  };

  const loading = useSelector(
    (state: { opportunities: OpportunitiesSliceState }) =>
      state.opportunities.isLoading
  );

  const [producerUserUUID, setProducerUserUUID] = useState('');

  useEffect(() => {
    if (show === 'open') {
      setIsLoading(true);
      dispatch(
        getOpportunityDetails({
          uuid: UUID,
          successCB: (Opportunity: any) => {
            if (Opportunity.data.Account.AccountType.name === 'COMMERCIAL') {
              setCommercial(true);
            }
            if (Opportunity.data.CustomProduct) {
              setProductType('CUSTOM_PRODUCT');
              setPolicy(Opportunity?.data.CustomProduct?.uuid);
              setProductUUID(Opportunity?.data.CustomProduct?.uuid);
              setPolicySearch(
                Opportunity.data.CustomProduct?.name
                  .toLowerCase()
                  .split('_')
                  .join(' ')
              );
            } else if (Opportunity.data.Product) {
              setProductType('PRODUCT');
              setProductUUID(Opportunity?.data.Product?.uuid);
              setPolicy(Opportunity?.data.Product?.uuid);
              setPolicySearch(
                Opportunity.data.Product?.name
                  .toLowerCase()
                  .split('_')
                  .join(' ')
              );
            }

            setProducerUserUUID(Opportunity.data.Agent.User.uuid);
            setProducerName(
              `${Opportunity.data.Agent.User.firstName} ${
                Opportunity.data.Agent.User.lastName
                  ? Opportunity.data.Agent.User.lastName
                  : ''
              }`
            );
            setProducer(Opportunity.data.Agent.uuid);
            Opportunity?.data?.PipelineStage?.name === 'Won'
              ? setWonState(true)
              : setWonState(false);
            setValue('sourceName', {
              label: Opportunity.data.sourceName,
              value: Opportunity.data.sourceName,
            });
            setValue('pipeline', {
              label: Opportunity.data.PipelineStage.Pipeline.name,
              value: Opportunity.data.PipelineStage.Pipeline.uuid,
            });
            setValue('pipelineStage', {
              label: Opportunity.data.PipelineStage?.name,
              value: Opportunity.data.PipelineStage?.uuid,
            });
            // setStage(Opportunity.data.OpportunityStatus.uuid);
            // setStageSearch(Opportunity.data.OpportunityStatus.name);
            setAccountSearch(Opportunity.data.Account.name);
            setAccount(Opportunity.data.Account.uuid);
            // setValue('productCategory', {
            //   label: Opportunity.data.ProductCategory.name,
            //   value: Opportunity.data.ProductCategory.uuid,
            // });

            setValue('opportunityName', Opportunity.data.name);
            setValue('premium', Opportunity?.data.premium);
            if (Opportunity.data.effectiveDate) {
              setValue(
                'effectiveDate',
                moment(Opportunity.data.effectiveDate).toDate()
              );
            } else {
              setValue('effectiveDate', null);
            }
            if (Opportunity.data.expectedCloseDate) {
              setValue(
                'expectedCloseDate',
                moment(Opportunity.data.expectedCloseDate).toDate()
              );
            } else {
              setValue('expectedCloseDate', null);
            }
            setTimeout(() => {
              if (Opportunity.data.OpportunityCustomField?.length > 0) {
                Opportunity.data.OpportunityCustomField.map((item: any) => {
                  if (
                    item.CustomField.FieldType?.name === 'DATEPICKER' ||
                    item.CustomField.FieldType?.name === 'TIME'
                  ) {
                    setValue(
                      item.CustomField.uuid,
                      moment(item.value).toDate()
                    );
                  } else {
                    setValue(item.CustomField.uuid, item.value);
                  }
                });
              }
            }, 500);
            setIsLoading(false);
          },
        })
      );
    } // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [show]);

  const loadPolicy = (value: string) => {
    if (loadingStatus === 'idle' || loadingStatus === 'succeeded') {
      setLoadingStatus('loading');
      fetchProducts(
        (data: IProduct[]) => {
          setProductCategory(data);
          setLoadingStatus('succeeded');
        },
        'name',
        'asc',
        value,
        '',
        true,
        isCommercial ? 'COMMERCIAL' : 'PERSONAL'
      );
    }
  };

  useEffect(() => {
    if (watchPipeline) {
      setValue('pipelineStage', '');
    } // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [watchPipeline]);

  useEffect(() => {
    if (user?.UserRole?.name === 'AGENT') {
      setProducerSearch('Me');
    } else {
      if (user.uuid === producerUserUUID) {
        setProducerSearch('Me');
      } else {
        setProducerSearch(producerName);
      }
    }
  }, [user, producerName, producerUserUUID]);

  const loadProducer = (value: string) => {
    if (
      loadingStatus === 'idle' ||
      loadingStatus === 'succeeded' ||
      user?.UserRole?.name === 'PRINCIPAL_AGENT'
    ) {
      setLoadingStatus('loading');
      fetchAgents(
        (data: IProducer[]) => {
          setProducers(data);
          setLoadingStatus('succeeded');
        },
        'id',
        'asc',
        value,
        'True'
      );
    }
  };

  const loadAccount = (value: string) => {
    if (loadingStatus === 'idle' || loadingStatus === 'succeeded') {
      setLoadingStatus('loading');
      fetchAccounts(
        'name',
        'asc',
        value,
        25,
        0,
        isCommercial ? 'COMMERCIAL' : 'PERSONAL',
        (data: IAccount[]) => {
          setAccounts(data);
          setLoadingStatus('succeeded');
        },
        'True'
      );
    }
  };
  useEffect(() => {
    getLeadSourceTypes((data: ILeadSourceType[]) => {
      setSourceTypes(data);
    });
  }, []);

  return (
    <>
      <SuccessAlign>
        <div className={`success-container ${showSuccess && 'open-drawer'} `}>
          <SuccessCard
            title={'Success adding Opportunity'}
            message={`<b>${getValues(
              'opportunityName'
            )}</b> Opportunity has been updated<b>`}
          />
          <button
            onClick={() => {
              reset();
              setshowSuccess(false);
            }}
            className='crossbutton'
          >
            <img src={cross} className='cross' alt='cross-icon' />
          </button>
        </div>
      </SuccessAlign>
      <StyledEditOpportunityDetailsDiv>
        <Modal open={show}>
          <div className='entire-div'>
            <div className='topdiv'>
              <div className='opphead-div'></div>
              <div className='text-overflow-container'>
                <h2 className='d-none d-sm-none d-xl-block opp-heading-overflow'>
                  {opportunityDetails?.name}
                </h2>
                <span className='tooltiptext'>
                  <span className='tooltipInnertext'>
                    {opportunityDetails?.name}
                  </span>
                </span>
              </div>
              <div className='d-flex align-items-center d-md-none d-sm-block d-none d-xl-none'>
                <h2 className=''> {opportunityDetails?.name}</h2>
                {/* <p className='subhead'>Click on the details to edit</p> */}
              </div>
              <div className='d-block d-sm-flex d-sm-none d-lg-none d-xl-none'>
                <h2 className=''>{opportunityDetails?.name}</h2>
                <p className=''>Click on the details to edit</p>
              </div>
              <div className='d-none d-md-block d-md-flex d-sm-none d-lg-none d-xl-none align-items-center'>
                <h2 className=''>{opportunityDetails?.name}</h2>
                <img className='barImg' src={bar} alt='bar-icon' />
                <p className='tab-head'>Click on the details to edit</p>
              </div>

              <div>
                <button className='cross-btn'>
                  <img
                    className='cross'
                    src={cross}
                    alt='cross-icon'
                    onClick={() => {
                      reset();
                      onCloseModal();
                    }}
                  />
                </button>
              </div>
              <div className='d-flex personal-commercial'></div>
            </div>
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className='scroll-bar  '>
                <div>
                  <div>
                    <h2 className='contact-info'>Opportunity Info</h2>
                  </div>
                  <div className=' d-flex flex-wrap '>
                    <div className='edit-fields'>
                      <SelectField
                        options={sourceTypes.map((item) => {
                          return {
                            label: item.name,
                            options: item.LeadSources.map((source) => {
                              return {
                                label: source.name,
                                value: source.uuid,
                              };
                            }),
                          };
                        })}
                        name='sourceName'
                        placeholder='Opportunity source'
                        control={control}
                        disabled={wonState}
                      />
                    </div>

                    <div className='edit-fields search'>
                      <SearchDropdown
                        name='productCategory'
                        placeholder='Select Policy'
                        isEdit={true}
                        isEditDetail={true}
                        values={policySearch}
                        searchValue={productCategory}
                        setSearch={setPolicySearch}
                        status={loadingStatus}
                        className={`${!policy && submit && 'error-field'} ${
                          policySearch && 'filled'
                        } text-capitalize`}
                        register={register}
                        control={control}
                        loadFunction={loadPolicy}
                        setUUID={setPolicy}
                        required
                        setProductUUID={setProductUUID}
                        disabled={wonState}
                        content={productCategory?.map((item) => (
                          <div
                            className='searchValuesCard d-flex align-items-center'
                            onClick={() => {
                              setPolicySearch(
                                item?.name.toLowerCase().split('_').join(' ')
                              );
                              setPolicy(item?.uuid);
                              setProductUUID(item?.uuid);
                              setProductType(item?.type);
                            }}
                          >
                            <div>
                              <h6 className='text-capitalize'>
                                {item.name.toLowerCase().split('_').join(' ')}
                              </h6>
                            </div>
                          </div>
                        ))}
                      />
                      {!policy && submit ? (
                        <div className='error-val'>
                          <div className='errorVal'>
                            <img
                              src={warning}
                              alt='warning'
                              className='warning'
                            />
                            Policy type required
                          </div>
                        </div>
                      ) : null}

                      {/* <SearchDropdown
                        name='opportunityStatus'
                        placeholder='Stage'
                        isEdit={true}
                        addName={true}
                        values={stageSearch}
                        searchValue={status}
                        setSearch={setStageSearch}
                        status={loadingStatus}
                        className={`${
                          errors.opportunityStatus && 'error-field'
                        } ${stageSearch && 'filled'}`}
                        register={register}
                        control={control}
                        loadFunction={loadStage}
                        content={status?.map((item) => (
                          <div
                            className='searchValuesCard d-flex align-items-center'
                            onClick={() => {
                              setStageSearch(item?.name);
                              setStage(item?.uuid);
                            }}
                          >
                            <div>
                              <h6>{item.name}</h6>
                            </div>
                          </div>
                        ))}
                      /> */}
                    </div>

                    <div className='edit-fields'>
                      {/* <SelectField
                        options={productCategory.map((item) => {
                          return { label: item.name, value: item.uuid };
                        })}
                        name='productCategory'
                        placeholder='Select policy'
                        control={control}
                      /> */}
                      <SelectField
                        options={pipelines.map((item) => {
                          return { label: item.name, value: item.uuid };
                        })}
                        name='pipeline'
                        placeholder='Pipeline'
                        control={control}
                        isEdit={true}
                        required
                        disabled={wonState}
                        noOptionsMessage={() => 'Please select a policy first'}
                      />
                      {errors.pipeline ? (
                        <div className='errorVal'>
                          <img
                            src={warning}
                            alt='warning'
                            className='warning'
                          />
                          {errors.pipeline?.message
                            ? 'Please select a Pipeline'
                            : errors.pipeline?.value?.message}
                        </div>
                      ) : null}
                    </div>
                    <div className='edit-fields'>
                      {pipelines && (
                        <SelectField
                          options={onSetPipelineValue(
                            pipelines.find(
                              (item) =>
                                item.uuid === watchAllFields.pipeline?.value
                            )
                          )}
                          name='pipelineStage'
                          placeholder='Pipeline stage'
                          required
                          noOptionsMessage={() =>
                            'Please select a pipeline first'
                          }
                          control={control}
                          disabled={wonState}
                          className={`${
                            errors.pipelineStage && 'error-field'
                          } ${watchAllFields.pipelineStage && 'filled'}`}
                        />
                      )}
                      {errors.pipelineStage ? (
                        <div className='errorVal'>
                          <img
                            src={warning}
                            alt='warning'
                            className='warning'
                          />
                          {errors.pipelineStage?.message
                            ? 'Please select a stage'
                            : errors.pipelineStage?.value?.message}
                        </div>
                      ) : null}
                    </div>
                    <div className='edit-fields account-drop search'>
                      {/* <SelectField
                        options={accounts.map((item) => {
                          return { label: item.name, value: item.uuid };
                        })}
                        name='account'
                        placeholder='Account associated to'
                        control={control}
                      /> */}
                      <SearchDropdown
                        name='account'
                        placeholder='Account associated to'
                        isEdit={true}
                        isEditDetail={true}
                        values={accountSearch}
                        searchValue={accounts}
                        setSearch={setAccountSearch}
                        status={loadingStatus}
                        className={`${accountSearch && 'filled'}`}
                        register={register}
                        control={control}
                        loadFunction={loadAccount}
                        disabled
                        content={accounts?.map((item) => (
                          <div
                            className='searchValuesCard d-flex align-items-center'
                            onClick={() => {
                              setAccountSearch(item?.name);
                              setAccount(item?.uuid);
                            }}
                          >
                            <div className='profile-pic d-flex align-items-center justify-content-center'>
                              {item?.name?.charAt(0)}
                            </div>
                            <div>
                              <h6>{item.name}</h6>
                              <ul className='user-details'>
                                <li>
                                  {isCommercial
                                    ? item?.businessEmail
                                    : item?.AccountContacts[0]?.Contact?.ContactDetails?.find(
                                        (value: any) => value.type === 'EMAIL'
                                      )?.value}
                                </li>
                                <li>
                                  {isCommercial
                                    ? item?.businessPhoneNumber
                                    : item?.AccountContacts[0]?.Contact?.ContactDetails?.find(
                                        (value: any) =>
                                          value.type === 'PHONENUMBER'
                                      )?.value}
                                </li>
                              </ul>
                            </div>
                          </div>
                        ))}
                      />
                    </div>
                    <div className='edit-fields search'>
                      {/* <SelectField
                        options={producers.map((item) => {
                          return {
                            label: `${item.User.firstName} ${
                              item.User.lastName ? item.User.lastName : ''
                            }`,
                            value: item.uuid,
                          };
                        })}
                        name='producer'
                        placeholder='Select producer'
                        control={control}
                      /> */}
                      <SearchDropdown
                        name='producer'
                        placeholder='Select producer'
                        isEdit={true}
                        isEditDetail={true}
                        addName={true}
                        values={producerSearch}
                        searchValue={producers}
                        setSearch={setProducerSearch}
                        status={loadingStatus}
                        className={`${producerSearch && 'filled'}`}
                        register={register}
                        control={control}
                        loadFunction={loadProducer}
                        disabled={user?.UserRole?.name === 'AGENT' || wonState}
                        setUUID={setProducer}
                        content={producers?.map((item) => (
                          <div
                            className='searchValuesCard d-flex align-items-center'
                            onClick={() => {
                              setProducerSearch(
                                `${item.User.firstName} ${
                                  item.User.lastName ? item.User.lastName : ''
                                }`
                              );
                              setProducer(item?.uuid);
                            }}
                          >
                            <div className='profile-pic d-flex align-items-center justify-content-center'>
                              {item?.User?.firstName?.charAt(0)}
                              {item?.User?.lastName?.charAt(0)}
                            </div>
                            <div>
                              <h6>
                                {`${item.User.firstName} ${
                                  item.User.lastName ? item.User.lastName : ''
                                }`}
                              </h6>
                              <ul className='user-details'>
                                <li>{item.User?.email}</li>
                                <li>{item?.User?.phoneNumber}</li>
                              </ul>
                            </div>
                          </div>
                        ))}
                      />
                    </div>
                    <div className='edit-fields'>
                      <InputField
                        name='opportunityName'
                        placeholder='Opportunity name'
                        type='text'
                        register={register}
                        disabled={wonState}
                        required={true}
                      />
                      {errors.opportunityName ? (
                        <div className='errorVal'>
                          <img
                            src={warning}
                            alt='warning'
                            className='warning'
                          />
                          {errors.opportunityName?.message}
                        </div>
                      ) : null}
                    </div>
                    <div className='edit-fields'>
                      <InputField
                        name='premium'
                        placeholder='Opportunity value'
                        type='number'
                        register={register}
                        disabled={wonState}
                      />
                    </div>
                    <div className='edit-fields'>
                      <EditDate
                        name='effectiveDate'
                        placeholder='Effective date'
                        control={control}
                        disabled={wonState}
                      />
                    </div>
                    <div className='edit-fields'>
                      <EditDate
                        name='expectedCloseDate'
                        placeholder='Estimated close date'
                        control={control}
                        disabled={wonState}
                      />
                    </div>
                  </div>
                  <div className='d-flex flex-wrap'>
                    {customFields?.length > 0
                      ? customFields?.map((item: any, index: number) => (
                          <div className='edit-fields'>
                            <CustomFieldInput
                              item={item}
                              register={register}
                              control={control}
                              value={watchAllFields[item.uuid]}
                              isEdit={true}
                              disabled={wonState}
                            />
                          </div>
                        ))
                      : null}
                  </div>
                </div>
              </div>

              <div className='buttonsDiv d-flex align-items-center '>
                <button
                  onClick={() => {
                    reset();
                    onCloseModal();
                  }}
                  className='cancel-button'
                  type='button'
                >
                  Cancel
                </button>
                <button
                  type='submit'
                  className='saveButton'
                  onClick={() => setSubmit(true)}
                  disabled={
                    show === 'closing' ||
                    (watchAllFields?.sourceName?.label ===
                      opportunityDetails?.sourceName &&
                      (policy === opportunityDetails?.Product?.uuid ||
                        policy === opportunityDetails?.CustomProduct?.uuid) &&
                      watchAllFields?.pipeline?.value ===
                        opportunityDetails?.PipelineStage?.Pipeline?.uuid &&
                      watchAllFields?.pipelineStage?.value ===
                        opportunityDetails?.PipelineStage?.uuid &&
                      watchAllFields?.opportunityName ===
                        opportunityDetails?.name &&
                      watchAllFields?.premium === opportunityDetails?.premium &&
                      moment(watchAllFields.effectiveDate)?.toISOString() ===
                        opportunityDetails?.effectiveDate &&
                      moment(
                        watchAllFields.expectedCloseDate
                      )?.toISOString() ===
                        opportunityDetails?.expectedCloseDate &&
                      producer === opportunityDetails?.Agent?.uuid &&
                      (customFields?.length > 0
                        ? customFields?.every((item: any) => {
                            const opportunityCustom =
                              opportunityDetails?.OpportunityCustomField?.find(
                                (el: any) => el.CustomField.uuid === item.uuid
                              );
                            if (item.FieldType.name === 'SINGLE_CHOICE') {
                              if (opportunityCustom) {
                                return (
                                  watchAllFields[item.uuid]?.label ===
                                  opportunityCustom.value?.label
                                );
                              } else if (
                                opportunityCustom === undefined &&
                                watchAllFields[item.uuid]?.label !==
                                  undefined &&
                                watchAllFields[item.uuid]?.value !== undefined
                              ) {
                                return false;
                              }
                              return true;
                            } else if (
                              item.FieldType.name === 'MULTIPLE_CHOICE'
                            ) {
                              if (opportunityCustom) {
                                return (
                                  watchAllFields[item.uuid].every(
                                    (watchEl: any) => {
                                      return opportunityCustom.value.find(
                                        (opEl: any) =>
                                          opEl.label === watchEl.label
                                      );
                                    }
                                  ) &&
                                  opportunityCustom.value.every(
                                    (watchEl: any) => {
                                      return watchAllFields[item.uuid].find(
                                        (opEl: any) =>
                                          opEl.label === watchEl.label
                                      );
                                    }
                                  )
                                );
                              } else if (
                                opportunityCustom === undefined &&
                                watchAllFields[item.uuid]?.length > 0
                              ) {
                                return false;
                              }
                              return true;
                            } else {
                              if (opportunityCustom) {
                                return (
                                  watchAllFields[item.uuid] ===
                                  opportunityCustom.value
                                );
                              } else if (
                                opportunityCustom === undefined &&
                                watchAllFields[item.uuid] !== '' &&
                                watchAllFields[item.uuid] !== undefined &&
                                watchAllFields[item.uuid] !== false
                              ) {
                                return false;
                              }
                              return true;
                            }
                          })
                        : true)) ||
                    isLoading
                  }
                >
                  Save <img className='tick' src={tick} alt='tick-sign' />
                </button>
              </div>
            </form>
          </div>
        </Modal>
      </StyledEditOpportunityDetailsDiv>
    </>
  );
};

export default EditOpportunityDetails;
