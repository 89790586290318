import styled from '@emotion/styled';

export const StyledPipelineModal = styled.div`
  width: 100%;
  height: 100vh;
  position: fixed;
  left: 0;
  top: 0;
  z-index: 10;
  .fields {
    margin-top: 16px;
  }
  .pipeline-field-container {
    @media screen and (max-width: 576px) {
      flex-direction: column;
      .fields {
        margin-top: 8px;
        margin-bottom: 8px;
      }
    }
  }
  .stages-slider {
    h6 {
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      line-height: 24px;
    }
    margin-top: 16px;
    margin-bottom: 24px;
  }

  .cross-btn {
    background: #ffffff;
    border: none;
  }

  .delete-button {
    background: #cc0000;
    border-radius: 8px;
    border: 0;
    color: #f5f7fc;
    font-weight: 600;
    font-size: 14px;
    padding: 8px 20px;
    margin-right: 28px;
  }
  .delete-buttonMob {
    background: #ffffff;
    border-radius: 8px;
    border: 0;
    color: #f5f7fc;
    font-weight: 600;
    font-size: 14px;
    padding: 2px 20px;
  }
  .cross-btnMob {
    background: #ffffff;
    border: none;
    padding: 2px 20px 2px 24px;
    border-left: 1px solid #e6e9f0;
  }

  .top-div {
    padding-bottom: 20px;
    @media screen and (max-width: 576px) {
      border-bottom: 0;
    }
  }

  .buttonsDiv {
    border-top: 1px solid #e6e9f0;
    position: fixed;
    width: 89%;
    bottom: 0;
    left: 20px;
    background: #fff;
    z-index: 2;
    padding: 20px 0px;
  }
  .cancel-button {
    height: 24px;
    background: none;
    color: #262758;
    font-weight: 600;
    font-size: 16px;
    margin-left: auto;
    margin-right: 30px;
    border: none;
  }
  .saveButton {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: #262758;
    color: #f5f7fc;
    border-radius: 8px;
    width: 140px;
    height: 48px;
    padding: 0 20px;
    margin-left: 0px;
    font-weight: 600;
    font-size: 16px;
    &:disabled {
      background: #d1d6e5;
      border: none;
    }
  }
  .stage-list {
    margin-top: 48px;
    .header-div {
      padding: 12px 24px;
      margin: 2px 0;
      background: #fafafa;
      border-radius: 8px 8px 0px 0px;
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
    }

    .stage-card {
      font-weight: 600;
      font-size: 14px;
      line-height: 20px;
      background: #f5f7fc;
      border-radius: 4px;
      margin: 2px 0;
      padding: 12px 24px;
      img {
        margin-left: 8px;
      }
    }
  }
  .pipeline-slider {
    padding-left: 5px;
    margin-top: 25px;
  }
  .rc-slider-rail {
    background: #cad9f3;
    height: 2px;
  }

  .rc-slider-dot {
    background: #5c698c;
    border: 0;
    width: 4px;
    height: 4px;
    bottom: 1px;
  }

  .rc-slider-handle {
    border: 0;
    opacity: 1;
    box-shadow: 0px 1px 4px 0px #26275833;
    height: 24px;
    width: 24px;
    margin-top: -10px;
  }

  .rc-slider-mark {
    font-size: 16px;
  }

  .rc-slider-mark-text {
    color: #000;
    padding-top: 4px;
    font-weight: 600;

    .disabled-mark {
      color: #d1d6e5;
    }
  }
  .editingLogo {
    width: 15px;
  }
  .fields {
    width: 48%;
    @media screen and (max-width: 576px) {
      width: 100%;
    }
  }

  @media screen and (max-width: 1200px) {
    .drawer-container {
      width: 100%;
    }

    .stage-options {
      max-height: 40vh;
      overflow: scroll;
      padding-bottom: 120px;
      &::-webkit-scrollbar {
        width: 0px;
        background: transparent;
      }
    }
  }

  @media screen and (min-width: 576px) {
    width: 960px;

    .buttonsDiv {
      padding: 20px 0px;
      width: 91%;
      left: 48px;
    }
  }
  @media screen and (min-width: 1200px) {
    .drawer-content {
      width: 883px;
    }
    h2 {
      font-size: 24px;
      margin-bottom: 0px;
    }

    .pipeline-form {
      padding-top: 24px;
    }
    .fields {
      width: 420px;
    }

    h6 {
      font-weight: 600;
      margin-bottom: 0;
    }

    .error-message {
      margin-top: 12px;
      font-size: 14px;
      color: #cc0000;
      display: flex;
      align-items: center;

      img {
        margin-right: 5px;
      }
    }

    .stage-info {
      font-size: 14px;

      color: #5a5f70;
    }

    .stages-slider {
      padding: 24px 0;
    }

    .pipeline-slider {
      padding-left: 5px;
      margin-top: 25px;
    }

    .rc-slider-rail {
      background: #cad9f3;
      height: 2px;
    }

    .rc-slider-dot {
      background: #5c698c;
      border: 0;
      width: 4px;
      height: 4px;
      bottom: 1px;
    }

    .rc-slider-handle {
      border: 0;
      opacity: 1;
      box-shadow: 0px 1px 4px 0px #26275833;
      height: 24px;
      width: 24px;
      margin-top: -10px;
    }

    .rc-slider-mark {
      font-size: 16px;
    }

    .stage-list {
      margin-top: 60px;
    }

    .header-div {
      background: #fafafa;
      padding: 12px 24px;
      border-radius: 8px 8px 0px 0px;
      font-size: 14px;
      color: #000;
      margin-bottom: 8px;
    }

    .stage-card {
      width: 100%;
      background: #f5f7fc;
      margin-bottom: 4px;
      font-weight: 600;
      padding: 15px 24px;
      border-radius: 4px;

      input:focus {
        border: none;
        outline: none;
        background: #f5f7fc;
        font-weight: 600;
        box-shadow: 0px 2px #000;
      }

      input::-webkit-outer-spin-button,
      input::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
      }

      input[type='number'] {
        -moz-appearance: textfield;
      }

      .percent-container::after {
        content: '%';
      }
      .buttonsDiv {
        padding: 20px 0px;
        width: 84%;
        left: 48px;
      }
    }
  }
  @media screen and (min-width: 1900px) {
    .cancel-button {
      height: 24px;
    }
    .saveButton {
      width: 200px;
      height: 64px;
    }
    .buttonsDiv {
      padding: 20px 0px;
      width: 88%;
      left: 48px;
    }

    .stage-list {
      margin-top: 75px;
    }
  }
`;
