import React, { useState, useEffect, useRef } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';
import Skeleton from 'react-loading-skeleton';
import { Spinner } from 'react-bootstrap';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { AppDispatch } from '../../../store';
import SideBar from '../../../components/SideBar';
import TopBar from '../../../components/TopBar';
import { StyledAgencySettings } from './styles';
import arrow from '../../../assets/logo/arrow.svg';
import camera from '../../../assets/logo/camera.svg';
import plus from '../../../assets/logo/blackplus.svg';
import InputField from '../../../components/InputField';
import SelectField from '../../../components/SelectField';
import PhoneNumberField from '../../../components/PhoneNumberFormat';
import warning from '../../../assets/logo/warning.svg';
import SearchDropdown from '../../../components/SearchDropdown';
import MobileNav from '../../../components/MobileTabNav';
import tick from '../../../assets/logo/tick.svg';
import blackplus from '../../../assets/logo/blackplus.svg';
import {
  AgentsSliceState,
  getAgents,
  selectAgency,
} from '../../../store/agents/agentsSlice';
import cross from '../../../assets/logo/cross.svg';
import LicensedStateModal from './components/AddLicensedStateModal';
import { fetchAgencyTypes } from '../../../api/admin';
import { IAgencyType } from '../../../interfaces/Agency';
import {
  AgenciesSliceState,
  updateAgencyInfo,
} from '../../../store/agencies/agenciesSlice';
import { IProducer } from '../../../interfaces/Agent';
import { fetchAgents } from '../../../api/agents';
import SuccessCard from '../../../components/SuccessCard';
import { SuccessAlign } from '../../../styles/SuccessAlign';
import LocalStorageService from '../../../utils/LocalStorageService';
import EditInputField from '../../../components/CustomInputField';
import edit from '../../../assets/logo/edit.svg';
import EditLocationModal from '../LocationSettings/components/EditLocationModal';
import { getAllLocations } from '../../../store/location/locationSlice';

const AgencySettingsPage = () => {
  const phoneRegExp = /^(?:\(\d{3}\)|\d{3})[- ]?\d{3}[- ]?\d{4}$/;
  const urlRegex =
    /[(http(s)?):\\(www.)?a-zA-Z0-9@:%._+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_+.~#?&//=]*)/gi;
  const AgencySchema = Yup.object().shape(
    {
      name: Yup.string().required('Agency name is required'),
      email: Yup.string()
        .email('Invalid email. Please try again')
        .required('Please enter your email '),
      agencyType: Yup.object().shape({
        value: Yup.string().required('Please select a type'),
      }),
      officePhoneNumber: Yup.string()
        .required('Phone Number is required')
        .matches(
          /[(][0-9]{3}[)][-\s][0-9]{3}[-][0-9]{4}$/,
          'Phone number is not valid'
        ),
      mobilePhoneNumber: Yup.string()
        .nullable()
        .notRequired()
        .when('mobilePhoneNumber', {
          is: (value: any) => value?.length,
          then: (rule: any) =>
            rule.matches(phoneRegExp, 'Entered phone number is not valid.'),
        }),
      faxNumber: Yup.string()
        .nullable()
        .notRequired()
        .when('faxNumber', {
          is: (value: any) => value?.length,
          then: (rule: any) =>
            rule.matches(phoneRegExp, 'Entered phone Fax number is not valid.'),
        }),
      websiteUrl: Yup.string()
        .nullable()
        .notRequired()
        .when('websiteUrl', {
          is: (value: any) => value?.length,
          then: (rule: any) =>
            rule.matches(urlRegex, 'Entered url is not valid.'),
        }),
      facebookUrl: Yup.string()
        .nullable()
        .notRequired()
        .when('facebookUrl', {
          is: (value: any) => value?.length,
          then: (rule: any) =>
            rule.matches(urlRegex, 'Entered url is not valid.'),
        }),
      twitterUrl: Yup.string()
        .nullable()
        .notRequired()
        .when('twitterUrl', {
          is: (value: any) => value?.length,
          then: (rule: any) =>
            rule.matches(urlRegex, 'Entered url is not valid.'),
        }),
      instagramUrl: Yup.string()
        .nullable()
        .notRequired()
        .when('instagramUrl', {
          is: (value: any) => value?.length,
          then: (rule: any) =>
            rule.matches(urlRegex, 'Entered url is not valid.'),
        }),
      youtubeUrl: Yup.string()
        .nullable()
        .notRequired()
        .when('youtubeUrl', {
          is: (value: any) => value?.length,
          then: (rule: any) =>
            rule.matches(urlRegex, 'Entered url is not valid.'),
        }),
      linkedInUrl: Yup.string()
        .nullable()
        .notRequired()
        .when('linkedInUrl', {
          is: (value: any) => value?.length,
          then: (rule: any) =>
            rule.matches(urlRegex, 'Entered url is not valid.'),
        }),
    },
    [
      ['faxNumber', 'faxNumber'],
      ['websiteUrl', 'websiteUrl'],
      ['facebookUrl', 'facebookUrl'],
      ['twitterUrl', 'twitterUrl'],
      ['instagramUrl', 'instagramUrl'],
      ['youtubeUrl', 'youtubeUrl'],
      ['linkedInUrl', 'linkedInUrl'],
      ['mobilePhoneNumber', 'mobilePhoneNumber'],
    ]
  );

  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    control,
    reset,
    watch,
    setValue,
    getValues,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(AgencySchema),
  });
  const agencyDetails = useSelector(selectAgency);

  const watchAllFields = watch();
  const { uuid } = useParams();
  const inputRef = useRef<HTMLInputElement>(null);

  const [isLocations, setLocations] = useState(false);
  const [loadingStatus, setLoadingStatus] = useState('idle');
  const [submit, setSubmit] = useState(false);
  const [showLicensedStates, setShowLicensedStates] = useState('closed');
  const [jurisdictions, setJurisdictions] = useState<any[]>([]);
  const [Uuid, setUuid] = useState('');
  const [agencyTypes, setAgencyTypes] = useState<IAgencyType[]>([]);
  const [avatar, setAvatar] = useState<string>();
  const [producers, setProducers] = useState<IProducer[]>([]);
  const [producerSearch, setProducerSearch] = useState('');
  const [producer, setProducer] = useState('');
  const [showSuccess, setshowSuccess] = useState(false);
  const [showEditModal, setShowEditModal] = useState('closed');
  const [locationUUID, setLocationUUID] = useState('');
  const [changedValue, setChangedValue] = useState<any>();
  const [agencyError, setAgencyError] = useState<any>({});
  const [profilePicError, setProfilePicError] = useState('');
  const loadingStatusState = useSelector(
    (state: { agencies: AgenciesSliceState }) => state.agencies.status
  );

  const agentLoadingStatus = useSelector(
    (state: { agents: AgentsSliceState }) => state.agents.status
  );

  const [assignedLocation, setAssignedLocation] = useState({
    assignedLocation: '',
  });

  const dispatch = useDispatch<AppDispatch>();
  const user = LocalStorageService.getUser();

  let uuidValue = '';

  useEffect(() => {
    if (user.UserRole.name === 'ADMIN') {
      uuidValue = uuid || '';
    } else {
      uuidValue = user?.Agent?.Agencies?.uuid;
    }
  }, [user]);

  useEffect(() => {
    dispatch(
      getAgents({
        agencyUUID: uuidValue,
        sortBy: '',
        orderBy: '',
        searchBy: '',
        limit: 1,
        offset: 0,
        successCB: (data: any) => {
          setJurisdictions(data.agency.Jurisdictions);
          setValue('name', data.agency?.name);
          setValue('email', data.agency?.email);
          setValue('officePhoneNumber', data.agency?.phoneNumber);
          setValue('mobilePhoneNumber', data.agency?.mobilePhoneNumber);
          setUuid(data.agency?.uuid);
          setValue('agencyType', {
            label: data.agency?.AgencyType?.name,
            value: data.agency?.AgencyType?.uuid,
          });
          setValue('websiteUrl', data?.agency?.websiteUrl);
          setValue('youtubeUrl', data?.agency?.youtubeUrl);
          setValue('instagramUrl', data?.agency?.instagramUrl);
          setValue('facebookUrl', data?.agency?.facebookUrl);
          setValue('twitterUrl', data?.agency?.twitterUrl);
          setValue('linkedInUrl', data?.agency?.linkedInUrl);
          setValue('faxNumber', data?.agency?.faxNumber);
          setAvatar(data?.agency?.logo);
          setTimeout(() => {
            setProducerSearch(
              `${data.agency?.AgencyOwner?.firstName} ${data.agency?.AgencyOwner?.lastName}`
            );
          }, 10);

          setProducer(data.agency?.AgencyOwner?.uuid);
          setAssignedLocation({
            assignedLocation:
              data?.agency?.Locations[0]?.name +
              ', ' +
              data?.agency?.Locations[0]?.state,
          });
          setLocationUUID(data.agency?.Locations[0]?.uuid);
        },
      })
    );
  }, []);

  useEffect(() => {
    setAssignedLocation({
      assignedLocation: `${changedValue?.location?.name} ${changedValue?.location?.state} `,
    });
    setLocationUUID(changedValue?.location?.uuid);
  }, [changedValue]);
  useEffect(() => {
    if (loadingStatus === 'idle' || loadingStatus === 'succeeded') {
      if (user?.UserRole.name === 'ADMIN' && uuid) {
        dispatch(
          getAllLocations({
            minimal: 'True',
            agencyUUID: uuid,
            successCB: (data: any) => {},
          })
        );
      } else {
        dispatch(
          getAllLocations({
            minimal: 'True',
            successCB: (data: any) => {},
          })
        );
      }
    }
  }, []);
  const loadProducer = (value: string) => {
    if (
      loadingStatus === 'idle' ||
      loadingStatus === 'succeeded' ||
      user?.UserRole?.name === 'PRINCIPAL_AGENT'
    ) {
      setLoadingStatus('loading');
      fetchAgents(
        (data: IProducer[]) => {
          setProducers(data);
          setLoadingStatus('succeeded');
        },
        'id',
        'asc',
        value,
        'True'
      );
    }
  };

  const onSetItem = (name: string, value: string) => {};
  const [fileChange, setFileChange] = useState(false);

  const onSubmit = (value: any) => {
    let canSubmit = true;
    if (!producer && submit) {
      canSubmit = false;
    }
    let formData = new FormData();
    for (let [key, data] of Object.entries(value)) {
      let val: any = data;
      formData.append(key, val);
    }
    if (value.type || value.type === '') {
      formData.append('action', 'uploadLogo');
      formData.append('agencyUUID', `${uuidValue}`);
      formData.append('avatarLogo', value);
    } else {
      formData.append('action', 'updateAgencyInfo');
      formData.append('agencyTypeUUID', value.agencyType?.value);
      formData.append('agencyOwnerUUID', producer);
    }
    if (canSubmit) {
      dispatch(
        updateAgencyInfo({
          uuid: `${uuidValue}`,
          data: formData,
          successCB: (data: any) => {
            setFileChange(false);
            setAvatar(data.logo);
            setTimeout(() => {}, 300);
            setTimeout(() => {
              setTimeout(() => {}, 300);
            }, 4000);
            setshowSuccess(true);
            setTimeout(() => {
              setshowSuccess(false);
            }, 2000);
          },
          errorCB: (err: any) => {
            setAgencyError(err);
          },
        })
      );
    }
  };

  const onDeleteJurisdiction = (value: any) => {
    let data = {
      action: 'deleteLicensedStates',
      jurisdictionUUID: [{ uuid: value.uuid }],
    };
    if (uuidValue) {
      dispatch(
        updateAgencyInfo({
          uuid: uuidValue,
          data: data,
          successCB: (data: any) => {
            setJurisdictions(data.Jurisdictions);
          },
          errorCB: () => {},
        })
      );
    }
  };

  useEffect(() => {
    fetchAgencyTypes((data: IAgencyType[]) => {
      setAgencyTypes(data);
    });
  }, []);

  const handleClick = () => {
    if (inputRef && inputRef.current) inputRef.current.click();
  };

  const handleFileChange = (event: any) => {
    setAgencyError({ avatarLogo: '' });
    setFileChange(true);
    let fileObj = event.target.files[0];
    let size = fileObj.size >= 2e6;
    if (!fileObj) {
      return;
    } else if (
      (fileObj.type.split('/')[1] === 'jpg' ||
        fileObj.type.split('/')[1] === 'png' ||
        fileObj.type.split('/')[1] === 'jpeg') &&
      fileObj.size < 2e6
    ) {
      setProfilePicError(``);
      onSubmit(fileObj);
    } else {
      fileObj = {};
      if (size) {
        return setProfilePicError(`Image size too large`);
      } else {
        return setProfilePicError(`Invalid file format`);
      }
    }
  };

  return (
    <>
      <StyledAgencySettings>
        <div className='d-none d-xl-block'>
          <SideBar />
        </div>
        <TopBar>
          <div
            className='head-mob d-flex align-items-center'
            onClick={() =>
              user?.UserRole?.name === 'ADMIN'
                ? navigate(`/admin/agency/${uuid}`)
                : navigate('/settings')
            }
            role='button'
          >
            <img src={arrow} className='leftarrow  ' alt='leftarrow' />
            <h2>Agency Settings</h2>
          </div>
        </TopBar>
        <div className='agencySettingsContent'>
          <div className='topDiv'>
            <div className='d-flex '>
              <p
                onClick={() => setLocations(false)}
                className={
                  !isLocations ? 'naVigatorbuttonsActive' : 'naVigatorbuttons'
                }
              >
                General Info
              </p>
              <div className='locationText'>
                <p
                  onClick={() => {
                    setLocations(true);
                    user?.UserRole?.name === 'PRINCIPAL_AGENT'
                      ? navigate(`/settings/Agencysettings/location`)
                      : navigate(`/admin/agency/${uuid}/settings/location`);
                  }}
                  className={
                    isLocations ? 'naVigatorbuttonsActive' : 'naVigatorbuttons'
                  }
                >
                  Locations
                </p>
              </div>
            </div>
          </div>
          <div className='scrollBar'>
            <div className='subText'>Upload a logo</div>
            {(agentLoadingStatus === 'loading' ||
              loadingStatusState === 'loading') &&
            !fileChange ? (
              <div className='d-flex CameraLogoDiv'>
                <Skeleton
                  height={91}
                  width={91}
                  style={{ borderRadius: '100px' }}
                />
                <Skeleton
                  height={91}
                  width={170}
                  style={{ marginLeft: '24px' }}
                />
              </div>
            ) : (
              <>
                <div className='d-flex CameraLogoDiv'>
                  {fileChange && loadingStatusState === 'loading' ? (
                    <div className='logo-spinner'>
                      <Spinner animation='border' role='status'>
                        <span className='visually-hidden'>Loading...</span>
                      </Spinner>
                    </div>
                  ) : null}
                  {avatar ? (
                    <img className='uploadedImg' src={avatar} alt='image' />
                  ) : (
                    <div className='cameraDiv'>
                      <img className='' src={camera} alt='cameraIcon' />
                    </div>
                  )}
                  <button
                    className='logoButton'
                    onClick={() => {
                      handleClick();
                    }}
                  >
                    Add a logo{' '}
                    <img className='plusLogo' src={plus} alt='plus-icon' />
                  </button>
                  <h6 className='instructionText'>
                    *Please upload .jpg,.png,.jpeg files &lt;2MB
                  </h6>

                  <input
                    className=''
                    style={{ display: 'none' }}
                    ref={inputRef}
                    type='file'
                    onChange={handleFileChange}
                  />
                </div>
                <div>
                  {profilePicError ? (
                    <div className='errorValImge d-flex align-items-center'>
                      <img src={warning} alt='warning' className='warning' />
                      <span style={{ fontSize: 14, fontWeight: 600 }}>
                        {profilePicError}
                      </span>
                    </div>
                  ) : null}
                </div>
              </>
            )}

            <div className='formContent'>
              <form onSubmit={handleSubmit(onSubmit)}>
                <div className='subText'>Agency Information</div>
                <div>
                  <div className='d-sm-flex justify-content-between'>
                    {agentLoadingStatus === 'loading' ||
                    loadingStatusState === 'loading' ? (
                      <>
                        <div className='fields'>
                          <Skeleton height={62} />
                        </div>
                        <div className='fields'>
                          <Skeleton height={62} />
                        </div>
                      </>
                    ) : (
                      <>
                        <div className='fields'>
                          <InputField
                            name='name'
                            type='text'
                            placeholder='Agency name'
                            register={register}
                            required
                            className={`${errors.name && 'error-field'} ${
                              watchAllFields.name && 'filled'
                            }`}
                          />
                          {errors.name ? (
                            <div className='error-div'>
                              <img
                                src={warning}
                                alt='warning'
                                className='warning'
                              />
                              {errors.name?.message}
                            </div>
                          ) : null}
                        </div>
                        <div className='fields'>
                          <SelectField
                            options={agencyTypes.map((item) => {
                              return { value: item.uuid, label: item.name };
                            })}
                            name='agencyType'
                            placeholder='Agency type'
                            control={control}
                            isEdit={true}
                            required
                          />
                          {errors.agencyType ? (
                            <div className='error-div'>
                              <img
                                src={warning}
                                alt='warning'
                                className='warning'
                              />
                              {errors.agencyType?.message
                                ? 'Please select a type'
                                : errors.agencyType?.value?.message}
                            </div>
                          ) : null}
                        </div>
                      </>
                    )}
                  </div>
                  <div className='d-sm-flex justify-content-between'>
                    {agentLoadingStatus === 'loading' ||
                    loadingStatusState === 'loading' ? (
                      <>
                        <div className='fields'>
                          <Skeleton height={62} />
                        </div>
                        <div className='fields'>
                          <Skeleton height={62} />
                        </div>
                      </>
                    ) : (
                      <>
                        <div className='fields'>
                          <PhoneNumberField
                            name='officePhoneNumber'
                            type='tel'
                            placeholder='Phone number(office)'
                            control={control}
                            required
                            className={`${
                              errors.officePhoneNumber && 'error-field'
                            } ${watchAllFields.officePhoneNumber && 'filled'}`}
                          />
                          {errors.officePhoneNumber ? (
                            <div className='error-div'>
                              <img
                                src={warning}
                                alt='warning'
                                className='warning'
                              />
                              {errors.officePhoneNumber?.message}
                            </div>
                          ) : null}
                        </div>
                        <div className='fields'>
                          <PhoneNumberField
                            name='mobilePhoneNumber'
                            type='tel'
                            placeholder='Phone number(cell)'
                            control={control}
                            className={`${
                              errors.mobilePhoneNumber && 'error-field'
                            } ${watchAllFields.mobilePhoneNumber && 'filled'}`}
                          />
                          {errors.mobilePhoneNumber ? (
                            <div className='error-div'>
                              <img
                                src={warning}
                                alt='warning'
                                className='warning'
                              />
                              {errors.mobilePhoneNumber?.message}
                            </div>
                          ) : null}
                        </div>
                      </>
                    )}
                  </div>
                  <div className='d-sm-flex justify-content-between'>
                    {agentLoadingStatus === 'loading' ||
                    loadingStatusState === 'loading' ? (
                      <>
                        <div className='fields'>
                          <Skeleton height={62} />
                        </div>
                        <div className='fields'>
                          <Skeleton height={62} />
                        </div>
                      </>
                    ) : (
                      <>
                        <div className='fields'>
                          <PhoneNumberField
                            name='faxNumber'
                            type='tel'
                            control={control}
                            placeholder='Fax number'
                            className={`${errors.faxNumber && 'error-field'} ${
                              watchAllFields.faxNumber && 'filled'
                            }`}
                          />
                          {errors.faxNumber ? (
                            <div className='error-div'>
                              <img
                                src={warning}
                                alt='warning'
                                className='warning'
                              />
                              {errors.faxNumber?.message}
                            </div>
                          ) : null}
                        </div>
                        <div className='fields'>
                          <InputField
                            name='email'
                            type='text'
                            placeholder='Email'
                            required
                            register={register}
                            className={`${errors.email && 'error-field'} ${
                              watchAllFields.email && 'filled'
                            }`}
                          />
                          {errors.email ? (
                            <div className='error-div'>
                              <img
                                src={warning}
                                alt='warning'
                                className='warning'
                              />
                              {errors.email?.message}
                            </div>
                          ) : null}
                        </div>
                      </>
                    )}
                  </div>
                  <div className='d-sm-flex justify-content-between'>
                    {agentLoadingStatus === 'loading' ||
                    loadingStatusState === 'loading' ? (
                      <div className='fields'>
                        <Skeleton height={62} />
                      </div>
                    ) : (
                      <div className='fields'>
                        <InputField
                          name='websiteUrl'
                          type='text'
                          placeholder='Website'
                          register={register}
                          className={`${errors.websiteUrl && 'error-field'} ${
                            watchAllFields.websiteUrl && 'filled'
                          }`}
                        />
                        {errors.websiteUrl ? (
                          <div className='error-div'>
                            <img
                              src={warning}
                              alt='warning'
                              className='warning'
                            />
                            {errors.websiteUrl?.message}
                          </div>
                        ) : null}
                      </div>
                    )}
                    {agentLoadingStatus === 'loading' ||
                    loadingStatusState === 'loading' ? (
                      <div className='fields'>
                        <Skeleton height={62} />
                      </div>
                    ) : (
                      <div className='fields'>
                        <SearchDropdown
                          name='agencyOwner'
                          placeholder='Agency owner'
                          isEdit={true}
                          addName={true}
                          values={producerSearch}
                          searchValue={producers}
                          setSearch={setProducerSearch}
                          status={loadingStatus}
                          className={`${producerSearch && 'filled'}`}
                          register={register}
                          control={control}
                          required
                          loadFunction={loadProducer}
                          setUUID={setProducer}
                          disabled={user?.UserRole?.name === 'AGENT'}
                          content={producers?.map((item) => (
                            <div
                              className='searchValuesCard d-flex align-items-center'
                              onClick={() => {
                                setProducerSearch(
                                  `${item?.User.firstName} ${
                                    item?.User.lastName
                                      ? item?.User.lastName
                                      : ''
                                  }`
                                );
                                setProducer(item?.User.uuid);
                              }}
                            >
                              <div className='profile-pic d-flex align-items-center justify-content-center'>
                                {item?.User?.firstName?.charAt(0)}
                                {item?.User?.lastName?.charAt(0)}
                              </div>
                              <div>
                                <h6>
                                  {`${item.User.firstName} ${
                                    item.User.lastName ? item.User.lastName : ''
                                  }`}
                                </h6>
                                <ul className='user-details'>
                                  <li>{item.User?.email}</li>
                                  <li>{item?.User?.phoneNumber}</li>
                                </ul>
                              </div>
                            </div>
                          ))}
                        />
                        {!producer && submit ? (
                          <div className='error-div'>
                            <img
                              src={warning}
                              alt='warning'
                              className='warning'
                            />
                            Producer not found
                          </div>
                        ) : (
                          ''
                        )}
                      </div>
                    )}
                  </div>
                  <div className='subText'>Primary location</div>
                  {agentLoadingStatus === 'loading' ||
                  loadingStatusState === 'loading' ? (
                    <div className='locationFields'>
                      <Skeleton height={62} />
                    </div>
                  ) : (
                    <div className='locationFields'>
                      <EditInputField
                        type='text'
                        placeholder='location'
                        name='assignedLocation'
                        values={assignedLocation}
                        onSetItem={onSetItem}
                        readonly={true}
                      />
                      <img
                        className='editBtn'
                        src={edit}
                        alt='edit'
                        onClick={() => setShowEditModal('open')}
                      />
                    </div>
                  )}
                  <div className='subText'>Social Media Links</div>
                  <div className='d-sm-flex justify-content-between'>
                    {agentLoadingStatus === 'loading' ||
                    loadingStatusState === 'loading' ? (
                      <>
                        <div className='fields'>
                          <Skeleton height={62} />
                        </div>
                        <div className='fields'>
                          <Skeleton height={62} />
                        </div>
                      </>
                    ) : (
                      <>
                        <div className='fields'>
                          <InputField
                            name='facebookUrl'
                            type='text'
                            placeholder='Facebook'
                            register={register}
                            className={`${
                              errors.facebookUrl && 'error-field'
                            } ${watchAllFields.facebookUrl && 'filled'}`}
                          />
                          {errors.facebookUrl ? (
                            <div className='error-div'>
                              <img
                                src={warning}
                                alt='warning'
                                className='warning'
                              />
                              {errors.facebookUrl?.message}
                            </div>
                          ) : null}
                        </div>
                        <div className='fields'>
                          <InputField
                            name='twitterUrl'
                            type='text'
                            placeholder='Twitter'
                            register={register}
                            className={`${errors.twitterUrl && 'error-field'} ${
                              watchAllFields.twitterUrl && 'filled'
                            }`}
                          />
                          {errors.twitterUrl ? (
                            <div className='error-div'>
                              <img
                                src={warning}
                                alt='warning'
                                className='warning'
                              />
                              {errors.twitterUrl?.message}
                            </div>
                          ) : null}
                        </div>
                      </>
                    )}
                  </div>
                  <div className='d-sm-flex justify-content-between'>
                    {agentLoadingStatus === 'loading' ||
                    loadingStatusState === 'loading' ? (
                      <>
                        <div className='fields'>
                          <Skeleton height={62} />
                        </div>
                        <div className='fields'>
                          <Skeleton height={62} />
                        </div>
                      </>
                    ) : (
                      <>
                        <div className='fields'>
                          <InputField
                            name='linkedInUrl'
                            type='text'
                            placeholder='Linkedin'
                            register={register}
                            className={`${
                              errors.linkedInUrl && 'error-field'
                            } ${watchAllFields.linkedInUrl && 'filled'}`}
                          />
                          {errors.linkedInUrl ? (
                            <div className='error-div'>
                              <img
                                src={warning}
                                alt='warning'
                                className='warning'
                              />
                              {errors.linkedInUrl?.message}
                            </div>
                          ) : null}
                        </div>
                        <div className='fields'>
                          <InputField
                            name='youtubeUrl'
                            type='text'
                            placeholder='Youtube'
                            register={register}
                            className={`${errors.youtubeUrl && 'error-field'} ${
                              watchAllFields.youtubeUrl && 'filled'
                            }`}
                          />
                          {errors.youtubeUrl ? (
                            <div className='error-div'>
                              <img
                                src={warning}
                                alt='warning'
                                className='warning'
                              />
                              {errors.youtubeUrl?.message}
                            </div>
                          ) : null}
                        </div>
                      </>
                    )}
                  </div>
                  {agentLoadingStatus === 'loading' ||
                  loadingStatusState === 'loading' ? (
                    <>
                      <div className='fields'>
                        <Skeleton height={62} />
                      </div>
                    </>
                  ) : (
                    <div className='fields'>
                      <InputField
                        name='instagramUrl'
                        type='text'
                        placeholder='Instagram'
                        register={register}
                        className={`${errors.instagramUrl && 'error-field'} ${
                          watchAllFields.instagramUrl && 'filled'
                        }`}
                      />
                      {errors.instagramUrl ? (
                        <div className='error-div'>
                          <img
                            src={warning}
                            alt='warning'
                            className='warning'
                          />
                          {errors.instagramUrl?.message}
                        </div>
                      ) : null}
                    </div>
                  )}

                  <div className='subText'>States Licensed In</div>
                  {agentLoadingStatus === 'loading' ||
                  loadingStatusState === 'loading' ? (
                    <>
                      <div className='d-flex mt-3'>
                        <Skeleton height={30} width={30} />
                        <Skeleton
                          height={30}
                          width={78}
                          style={{ marginLeft: '8px' }}
                        />
                        <Skeleton
                          height={30}
                          width={78}
                          style={{ marginLeft: '8px' }}
                        />
                      </div>
                    </>
                  ) : (
                    <>
                      <div className='d-flex mt-3'>
                        <button
                          className='addLicenseButton'
                          type='button'
                          onClick={() => setShowLicensedStates('open')}
                        >
                          <img src={blackplus} alt='plus' />
                        </button>
                        {jurisdictions?.map((item: any, index: number) => {
                          return (
                            <button type='button' className='licensedList'>
                              {item.code}
                              <img
                                onClick={() => {
                                  onDeleteJurisdiction(item);
                                }}
                                className='licenseCross'
                                src={cross}
                                alt=''
                              />
                            </button>
                          );
                        })}
                      </div>
                    </>
                  )}
                  <div className='buttonDiv d-flex align-items-center '>
                    <button
                      onClick={() => {
                        if (agencyDetails) {
                          setJurisdictions(agencyDetails.Jurisdictions);
                          setValue('name', agencyDetails?.name);
                          setValue('email', agencyDetails?.email);
                          setValue(
                            'officePhoneNumber',
                            agencyDetails?.phoneNumber
                          );

                          if (agencyDetails?.mobilePhoneNumber) {
                            setValue(
                              'mobilePhoneNumber',
                              agencyDetails?.mobilePhoneNumber
                            );
                          } else {
                            setValue('mobilePhoneNumber', '');
                          }

                          setUuid(agencyDetails?.uuid);
                          setValue('agencyType', {
                            label: agencyDetails?.AgencyType?.name,
                            value: agencyDetails?.AgencyType?.uuid,
                          });
                          setValue('websiteUrl', agencyDetails?.websiteUrl);
                          setValue('youtubeUrl', agencyDetails?.youtubeUrl);
                          setValue('instagramUrl', agencyDetails?.instagramUrl);
                          setValue('facebookUrl', agencyDetails?.facebookUrl);
                          setValue('twitterUrl', agencyDetails?.twitterUrl);
                          setValue('linkedInUrl', agencyDetails?.linkedInUrl);
                          setValue('faxNumber', agencyDetails?.faxNumber);
                          setAvatar(agencyDetails.logo);
                          setTimeout(() => {
                            setProducerSearch(
                              `${agencyDetails?.AgencyOwner?.firstName} ${
                                agencyDetails?.AgencyOwner?.lastName || ''
                              }`
                            );
                          }, 50);
                          setProducer(agencyDetails.AgencyOwner.uuid);
                          setAssignedLocation({
                            assignedLocation:
                              agencyDetails?.Locations[0]?.name +
                              ', ' +
                              agencyDetails?.Locations[0]?.state,
                          });
                          setLocationUUID(agencyDetails?.Locations[0]?.uuid);
                        }
                      }}
                      className='cancel-button'
                      type='button'
                    >
                      Cancel
                    </button>
                    <button
                      type='submit'
                      className='agentButton'
                      onClick={() => setSubmit(true)}
                      disabled={
                        watchAllFields?.name === agencyDetails?.name &&
                        watchAllFields?.agencyType?.label ===
                          agencyDetails?.AgencyType?.name &&
                        watchAllFields?.officePhoneNumber ===
                          agencyDetails?.phoneNumber &&
                        watchAllFields?.mobilePhoneNumber ===
                          agencyDetails?.mobilePhoneNumber &&
                        watchAllFields?.faxNumber ===
                          agencyDetails?.faxNumber &&
                        watchAllFields?.email === agencyDetails?.email &&
                        watchAllFields?.websiteUrl ===
                          agencyDetails?.websiteUrl &&
                        watchAllFields?.facebookUrl ===
                          agencyDetails?.facebookUrl &&
                        watchAllFields?.linkedInUrl ===
                          agencyDetails?.linkedInUrl &&
                        watchAllFields?.twitterUrl ===
                          agencyDetails?.twitterUrl &&
                        watchAllFields?.instagramUrl ===
                          agencyDetails?.instagramUrl &&
                        watchAllFields?.youtubeUrl ===
                          agencyDetails?.youtubeUrl &&
                        producer === agencyDetails?.AgencyOwner?.uuid
                      }
                    >
                      Save <img className='tick' src={tick} alt='tick-sign' />
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
        <div className='d-xl-none'>
          <MobileNav />
        </div>
        {showLicensedStates !== 'closed' && (
          <LicensedStateModal
            show={showLicensedStates}
            closeModal={setShowLicensedStates}
            selectedJurisdiction={jurisdictions}
            setSelectedJurisdiction={setJurisdictions}
            UUID={Uuid}
          />
        )}
        {showSuccess && (
          <SuccessAlign>
            <div
              className={`success-container ${showSuccess && 'open-drawer'} `}
            >
              <SuccessCard
                title={'Success updating Agency'}
                message={`<b>${getValues(
                  'name'
                )}</b> Agency has been updated<b>`}
              />
              <button
                onClick={() => {
                  reset();
                  setshowSuccess(false);
                }}
                className='crossbutton'
              >
                <img src={cross} className='cross' alt='cross-icon' />
              </button>
            </div>
          </SuccessAlign>
        )}
        {showEditModal !== 'closed' && (
          <EditLocationModal
            show={showEditModal}
            closeModal={setShowEditModal}
            uuid={locationUUID}
            agencyUUID={uuid}
            setChangedValue={setChangedValue}
            changedValue={changedValue}
            isThisprimaryLocation={true}
          />
        )}
      </StyledAgencySettings>
    </>
  );
};

export default AgencySettingsPage;
