import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router';
import useDebounce from '../../hooks/useDebounce';
import LocalStorageService from '../../utils/LocalStorageService';
import { AppDispatch } from '../../store';
import {
  clearSearchResults,
  getSearchResults,
  SearchSliceState,
  selectResults,
} from '../../store/search/searchSlice';
import SearchCard from '../../pages/Search/components/SearchCard';
import { GlobalSearch } from './styles';
import { EmptyDiv } from '../../pages/MyBook/elements/styles';
import search from '../../assets/logo/search.svg';
import empty from '../../assets/logo/emptyresults.svg';
import close from '../../assets/logo/cross.svg';
import searchboxIcon from '../../assets/logo/searchbox-icon.svg';
import windowsIcon from '../../assets/logo/windowsIcon.svg';
import arrow from '../../assets/logo/arrow.svg';
import emptySearch from '../../assets/logo/emptySearch.svg';
import Skeleton from 'react-loading-skeleton';

export interface IProps {
  setShowSearchDrop: Function;
  showSearchDrop: boolean;
  autofocus?: boolean;
  isTopBar?: boolean;
}

const SearchBar = ({
  setShowSearchDrop,
  showSearchDrop,
  autofocus,
  isTopBar,
}: IProps) => {
  const params = useLocation();
  const navigate = useNavigate();

  const dispatch = useDispatch<AppDispatch>();
  const [types, setTypes] = useState([
    'account',
    'contact',
    'opportunity',
    'policy',
    'user',
    'task',
  ]);

  const [searchBy, setSearchBy] = useState(
    new URLSearchParams(params.search).get('s') || ''
  );
  const [history, setHistory] = useState(LocalStorageService.getHistory());

  var os = 'Unknown OS';
  if (navigator.userAgent.indexOf('Win') !== -1) os = 'Windows';
  if (navigator.userAgent.indexOf('Mac') !== -1) os = 'Mac';
  if (navigator.userAgent.indexOf('Linux') !== -1) os = 'Linux';
  const escFunction = useCallback((event) => {
    if (
      (os === 'Linux' && event.ctrlKey && event.keyCode === 75) ||
      (os === 'Windows' && event.ctrlKey && event.keyCode === 75) ||
      (os === 'Mac' &&
        (event.keyCode === 91 || event.keyCode === 93) &&
        event.keyCode === 75)
    ) {
      event.preventDefault();
      document.getElementById('search')?.focus();
    } // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const debouncedSearchTerm = useDebounce(searchBy, 500);

  useEffect(() => {
    if (debouncedSearchTerm !== '') {
      setShowSearchDrop(true);
      dispatch(
        getSearchResults({
          searchBy: debouncedSearchTerm,
          offset: 0,
          limit: 10,
          types,
        })
      );
    } else {
      dispatch(clearSearchResults);
    } // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedSearchTerm, types]);

  useEffect(() => {
    document.addEventListener('keydown', escFunction, false);

    return () => {
      document.removeEventListener('keydown', escFunction, false);
    }; // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className='global-search d-flex align-items-center'>
      <img src={search} alt='' className='search-button' />
      <input
        onChange={(e) => {
          setSearchBy(e.target.value);
        }}
        placeholder='Search for anything'
        value={searchBy}
        className={searchBy || window.innerWidth < 1200 ? 'filled' : ''}
        id='search'
        autoComplete='off'
        autoFocus={autofocus}
      />
      <img
        src={close}
        alt=''
        className='close-button'
        onClick={() => {
          setSearchBy('');
        }}
      />
      <img
        src={
          navigator.userAgent.indexOf('Mac') === -1
            ? windowsIcon
            : searchboxIcon
        }
        alt=''
        className={
          navigator.userAgent.indexOf('Mac') === -1
            ? 'win-button key-button'
            : 'key-button'
        }
      />
      {debouncedSearchTerm !== '' && showSearchDrop ? (
        isTopBar && window.location.pathname.includes('search') ? (
          <></>
        ) : (
          <SearchDropdown
            searchText={searchBy}
            setShowSearchDrop={setShowSearchDrop}
            setTypes={setTypes}
          />
        )
      ) : (
        <GlobalSearch className='prev-search-container'>
          {history && history.length > 0 ? (
            history.map((item: any, index: number) => (
              <button
                className='prev-search-drop'
                key={index}
                onClick={() => {
                  let updatedHistory = history;
                  updatedHistory.unshift(item);
                  if (updatedHistory?.length > 8) {
                    updatedHistory.pop();
                  }
                  updatedHistory = Array.from(
                    new Map(
                      updatedHistory.map((his: any) => [his['text'], his])
                    ).values()
                  );
                  setHistory(updatedHistory);
                  LocalStorageService.setHistory({
                    text: item.text,
                    type: item.type,
                    data: item.data,
                  });
                  if (item.type === 'contact') {
                    navigate(`/contacts/${item.data.uuid}`);
                  } else if (item.type === 'account') {
                    navigate(`/account/${item.data.uuid}`);
                  } else if (item.type === 'opportunity') {
                    navigate(`/opportunities/${item.data.uuid}`);
                  } else if (item.type === 'policy') {
                    navigate(`/policy/${item.data.uuid}`);
                  } else if (item.type === 'task') {
                    navigate(`/activities/task`, {
                      state: { uuid: item.data.uuid },
                    });
                  } else {
                    navigate(`/search?s=${item.text}`);
                    window.location.reload();
                  }
                }}
              >
                <div className='prev-search-content'>
                  {item.type === 'previous' ? (
                    <div className='profile-img d-flex align-items-center justify-content-center'>
                      <img src={search} alt='' />
                    </div>
                  ) : (
                    <div className='profile-pic d-flex align-items-center justify-content-center'>
                      {item?.text?.charAt(0)}
                    </div>
                  )}
                  <div>{item.text}</div>
                </div>
                <img
                  src={close}
                  alt=''
                  className='cross-icon'
                  onClick={(e) => {
                    e.stopPropagation();
                    let updatedHistory = [...history];
                    updatedHistory.splice(index, 1);
                    setHistory(updatedHistory);
                    LocalStorageService.removeFromHistory(index);
                  }}
                />
              </button>
            ))
          ) : (
            <div className='prev-search-drop'>
              <div>No recent searches</div>
            </div>
          )}
        </GlobalSearch>
      )}
    </div>
  );
};

export default SearchBar;

const SearchDropdown = ({
  searchText,
  setShowSearchDrop,
  setTypes,
}: {
  searchText: string;
  setShowSearchDrop: Function;
  setTypes: Function;
}) => {
  const navigate = useNavigate();
  const results = useSelector(selectResults);
  const loadingStatus = useSelector(
    (state: { search: SearchSliceState }) => state.search.status
  );

  const [key, setKey] = useState('all');

  return (
    <GlobalSearch>
      {loadingStatus === 'loading' ? (
        <>
          {[1, 2, 3].map((item) => (
            <Skeleton height={70} key={item} className='mb-2' />
          ))}
        </>
      ) : (
        <>
          <div className='nav nav-tabs mb-3'>
            <button
              className={`nav-link ${key === 'all' ? 'active' : ''}`}
              onClick={() => {
                setKey('all');
                setTypes([
                  'account',
                  'contact',
                  'opportunity',
                  'policy',
                  'user',
                  'task',
                ]);
              }}
            >
              All
            </button>
            <button
              onClick={() => {
                setTypes(['account']);
                setKey('account');
              }}
              className={`nav-link ${key === 'account' ? 'active' : ''}`}
            >
              Accounts
            </button>
            <button
              onClick={() => {
                setKey('contact');
                setTypes(['contact']);
              }}
              className={`nav-link ${key === 'contact' ? 'active' : ''}`}
            >
              Contacts
            </button>
            <button
              onClick={() => {
                setKey('opportunity');
                setTypes(['opportunity']);
              }}
              className={`nav-link ${key === 'opportunity' ? 'active' : ''}`}
            >
              Opportunities
            </button>{' '}
            <button
              onClick={() => {
                setKey('policy');
                setTypes(['policy']);
              }}
              className={`nav-link ${key === 'policy' ? 'active' : ''}`}
            >
              Policies
            </button>
          </div>

          {results.length === 0 ? (
            <>
              <EmptyDiv className='d-xl-none'>
                <img src={emptySearch} alt='' className='empty-img' />
                <div className='empty-text'>
                  No results found for <b>"{searchText}"</b>
                </div>
              </EmptyDiv>
              <div className='d-none d-xl-flex align-items-center empty-div'>
                <div className='profile-img d-flex align-items-center justify-content-center'>
                  <img src={empty} alt='' />
                </div>
                <div className='empty-search-content'>
                  No results found for <b>"{searchText}"</b>
                </div>
              </div>
            </>
          ) : (
            <>
              <div className='result-count'>{results.length} results found</div>

              {results.slice(0, 5).map((item: any) => (
                <SearchCard item={item} searchText={searchText} />
              ))}
              <div
                className='viewAll-button'
                onClick={() => {
                  LocalStorageService.setHistory({
                    text: searchText,
                    type: 'previous',
                  });
                  navigate(`/search?s=${searchText}`);
                  setShowSearchDrop(false);
                }}
              >
                See all results for <b>"{searchText}"</b>{' '}
                <img src={arrow} alt='' />
              </div>
            </>
          )}
        </>
      )}
    </GlobalSearch>
  );
};
