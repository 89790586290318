import TopBar from '../../../components/TopBar';
import { PolicyContainer, PolicyContent } from './styles';
import arrow from '../../../assets/logo/right-arrow.svg';
import { useNavigate, useParams } from 'react-router';
import Skeleton from 'react-loading-skeleton';
import LocalStorageService from '../../../utils/LocalStorageService';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch } from '../../../store';
import plus from '../../../assets/logo/plus.svg';
import { useEffect, useState, useRef } from 'react';
import { SuccessAlign } from '../../../styles/SuccessAlign';
import SuccessCard from '../../../components/SuccessCard';
import edit from '../../../assets/logo/edit.svg';
import bin from '../../../assets/logo/bin.svg';
import cross from '../../../assets/logo/redcross.svg';
import close from '../../../assets/logo/cross.svg';
import AddPolicyCategoryModal from './components/AddPolicyCategoryModal';
import AddPolicyType from './components/AddPolicyType';
import EditCategoryModal from './components/EditCategoryModal';
import EditPolicyType from './components/EditPolicyType';
import DeleteCategory from './components/DeleteCategory';
import DeletePolicyType from './components/DeletePolicyType';
import {
  getProductCategories,
  selectAllProductCategories,
  ProductCategoriesSliceState,
} from '../../../store/productCategories/productCategoriesSlice';
import whitePlus from '../../../assets/logo/plus.svg';
import empty from '../../../assets/logo/emptystate.svg';
import search from '../../../assets/logo/search.svg';
import closeIcon from '../../../assets/logo/cross.svg';
import filter from '../../../assets/logo/filter-mobile.svg';
import filterIcon from '../../../assets/logo/filter.svg';
import useDebounce from '../../../hooks/useDebounce';
import PolicyFilter from './components/PolicyFilter';
import { FilterContainer } from '../../MyBook/elements/styles';

const PolicySettings = () => {
  const dispatch = useDispatch<AppDispatch>();
  const customProductCategories = useSelector(selectAllProductCategories);
  const { uuid } = useParams();
  const navigate = useNavigate();
  const user = LocalStorageService.getUser();

  const addRef = useRef(null);

  const [showAddOptions, setShowAddOptions] = useState(false);
  const [isSearchClicked, setIsSearchClicked] = useState(false);
  const [searchBy, setSearchBy] = useState('');
  const searchRef = useRef(null);
  const [showFilter, setShowFilter] = useState('closed');
  const [lineOfBusiness, setLineOfBusiness] = useState<any>();
  const [selectedProductCategoryType, setSelectedProductCategoryType] =
    useState<any>();
  const debouncedSearchTerm = useDebounce(searchBy, 500);

  const loadingStatus = useSelector(
    (state: { productCategories: ProductCategoriesSliceState }) =>
      state.productCategories.status
  );

  useEffect(() => {
    dispatch(
      getProductCategories({
        successCB: () => {},
        sortBy: '',
        orderBy: '',
        searchBy: searchBy,
        agencyUUID: uuid,
        customCategoryOnly: false,
        includeCustom: true,
        type:
          selectedProductCategoryType?.value === 'custom'
            ? 'CUSTOM_PRODUCT_CATEGORY'
            : selectedProductCategoryType?.value === 'default'
            ? 'PRODUCT_CATEGORY'
            : undefined,
        lineOfBusiness: lineOfBusiness?.type ? lineOfBusiness?.type : undefined,
      })
    );
  }, [debouncedSearchTerm]);

  const [successMessage, setSuccessMessage] = useState('');
  const [showAddPolicyCategory, setShowAddPolicyCategory] = useState(false);
  const [showAddType, setShowAddType] = useState(false);

  const PolicyFilterFunction = () => {
    dispatch(
      getProductCategories({
        successCB: () => {},
        sortBy: '',
        orderBy: '',
        searchBy: searchBy,
        agencyUUID: uuid,
        customCategoryOnly: false,
        includeCustom: true,
        type:
          selectedProductCategoryType?.value === 'custom'
            ? 'CUSTOM_PRODUCT_CATEGORY'
            : selectedProductCategoryType?.value === 'default'
            ? 'PRODUCT_CATEGORY'
            : undefined,
        lineOfBusiness: lineOfBusiness?.type ? lineOfBusiness?.type : undefined,
      })
    );
  };
  return (
    <PolicyContainer>
      <TopBar>
        <div
          className='head-mob d-flex align-items-center justify-content-between'
          style={{ cursor: 'pointer' }}
          onClick={() =>
            navigate(
              user.UserRole.name === 'ADMIN'
                ? `/admin/agency/${uuid}`
                : `/settings`
            )
          }
        >
          <img src={arrow} className='leftArrow' alt='arrow' />
          <h2>Policy Settings</h2>
        </div>
      </TopBar>
      <PolicyContent>
        <SuccessAlign>
          <div
            className={`success-container ${
              successMessage.length > 0 && 'open-drawer'
            } `}
          >
            <SuccessCard title={`<b>Success!</b>`} message={successMessage} />
            <button
              onClick={() => {
                setSuccessMessage('');
              }}
              className='crossbutton'
            >
              <img src={close} className='cross' alt='cross-icon' />
            </button>
          </div>
        </SuccessAlign>
        {showAddOptions && <div className='addButton-background' />}
        <button
          onClick={() => setShowAddOptions(!showAddOptions)}
          className={`addbutton-mob d-sm-none ${
            showAddOptions ? 'dropOpen' : ''
          }`}
          ref={addRef}
        >
          {showAddOptions && (
            <div className='dropUp'>
              <ul
                className='d-flex justify-content-between w-100 '
                onClick={() => {
                  setShowAddType(true);
                  setShowAddOptions(false);
                }}
              >
                Add policy type <img src={plus} alt='' />
              </ul>
              <ul
                className='d-flex justify-content-between w-100 '
                onClick={() => {
                  setShowAddPolicyCategory(true);
                  setShowAddOptions(false);
                }}
              >
                Add policy category <img src={plus} alt='' />
              </ul>
            </div>
          )}
          <img src={whitePlus} alt='add' className='addButton-img' />
        </button>
        <div className='d-flex justify-content-between'>
          <button
            className='primaryButton addPolicyButton d-none d-sm-flex align-items-center justify-content-between'
            onClick={() => setShowAddType(true)}
          >
            Add policy type <img alt='' src={plus} />
          </button>
          <button
            className='addPolicyButton tertiaryButton d-none d-sm-flex align-items-center justify-content-between'
            onClick={() => setShowAddPolicyCategory(true)}
          >
            Add policy category
            <img alt='' src={plus} />
          </button>
          <div className='book-filter d-none d-sm-flex ms-auto' ref={searchRef}>
            {isSearchClicked || searchBy ? (
              <div className='book-search d-flex align-items-center'>
                <img src={search} alt='' className='search-button' />
                <input
                  onChange={(e) => {
                    setSearchBy(e.target.value);
                  }}
                  autoFocus
                  value={searchBy}
                />
                <img
                  src={closeIcon}
                  alt=''
                  className='close-button'
                  onClick={() => {
                    setSearchBy('');
                    setIsSearchClicked(false);
                  }}
                />
              </div>
            ) : (
              <div
                className='book-shrinked'
                onClick={() => setIsSearchClicked(true)}
              >
                <img src={search} alt='' />
              </div>
            )}

            <span className='divider d-none d-sm-block' />
            <div
              className='book-shrinked d-none d-sm-flex'
              onClick={() => setShowFilter('open')}
            >
              <img src={filterIcon} alt='' />
              {(selectedProductCategoryType ? 1 : 0) +
                (lineOfBusiness ? 1 : 0) >
                0 && (
                <span className='filter-counter'>
                  {(selectedProductCategoryType ? 1 : 0) +
                    (lineOfBusiness ? 1 : 0)}
                </span>
              )}
            </div>
            <span
              className={`divider d-sm-none ${isSearchClicked && 'd-none'}`}
            />
            <div
              className={`book-shrinked d-sm-none ${
                isSearchClicked && 'd-none'
              }`}
              onClick={() => setShowFilter('open')}
            >
              <img src={filter} alt='' />
            </div>
          </div>
        </div>
        <div className='d-flex d-sm-none'>
          <h2 className='HeadNavigator'></h2>
          <div className='d-flex' style={{ marginLeft: '15px' }}>
            <div className='d-flex search-Field w-100'>
              <img className='mx-2' src={search} alt='' />
              <input
                className='inputSearchField mx-2'
                type='text'
                placeholder='Search Products'
                onChange={(e) => {
                  setSearchBy(e.target.value);
                }}
                autoFocus
                value={searchBy}
              />
            </div>
            <div
              className='d-flex search-Field filterField position-relative'
              onClick={() => setShowFilter('open')}
            >
              <img src={filter} alt='' />
              {(selectedProductCategoryType ? 1 : 0) +
                (lineOfBusiness ? 1 : 0) >
                0 && (
                <span className='filter-counter'>
                  {(selectedProductCategoryType ? 1 : 0) +
                    (lineOfBusiness ? 1 : 0)}
                </span>
              )}
            </div>
          </div>
        </div>
        <div className='d-sm-flex flex-wrap cards-container'>
          {loadingStatus === 'loading' ? (
            <>
              <div className='col-12 col-md-4 p-2'>
                <Skeleton height={300} />
              </div>
              <div className='col-12 col-md-4 p-2'>
                <Skeleton height={300} />
              </div>{' '}
              <div className='col-12 col-md-4 p-2'>
                <Skeleton height={300} />
              </div>
            </>
          ) : (
            customProductCategories?.map((item: any) => (
              <CategoryCard
                item={item}
                setSuccessMessage={setSuccessMessage}
                key={item.uuid}
              />
            ))
          )}
        </div>
      </PolicyContent>
      {showAddPolicyCategory && (
        <AddPolicyCategoryModal
          showAddPolicyCategory={showAddPolicyCategory}
          setShowAddPolicyCategory={setShowAddPolicyCategory}
          setSuccessMessage={setSuccessMessage}
        />
      )}
      {showAddType && (
        <AddPolicyType
          showAddType={showAddType}
          setShowAddType={setShowAddType}
          setSuccessMessage={setSuccessMessage}
        />
      )}
      <FilterContainer>
        {showFilter !== 'closed' && (
          <PolicyFilter
            show={showFilter}
            closeModal={setShowFilter}
            filterFunction={PolicyFilterFunction}
            setLOB={setLineOfBusiness}
            LOB={lineOfBusiness}
            selectedCategoryTypes={setSelectedProductCategoryType}
            categoryTypes={selectedProductCategoryType}
          />
        )}
      </FilterContainer>
    </PolicyContainer>
  );
};

export default PolicySettings;

const CategoryCard = ({
  item,
  setSuccessMessage,
}: {
  item: any;
  setSuccessMessage: Function;
}) => {
  const [showEditCategory, setShowEditCategory] = useState(false);
  const [showAddType, setShowAddType] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  return (
    <div className='type-card'>
      <div className='title-div d-flex justify-content-between align-items-center'>
        <h6>{item.name}</h6>
        {item.type === 'CUSTOM_PRODUCT_CATEGORY' ? (
          <div className='d-flex topEditDeletediv'>
            <div className='edit-icon'>
              <div
                className='card-icon'
                onClick={() => setShowEditCategory(true)}
              >
                <img src={edit} alt='' />
              </div>
            </div>

            <div className='card-icon' onClick={() => setShowDeleteModal(true)}>
              <img src={bin} alt='' />
            </div>
          </div>
        ) : null}
      </div>
      {item.Products ? (
        <div className='source-container'>
          <div className='source-content'>
            {item.Products?.map((type: any) => (
              <TypeCard
                category={item}
                type={type}
                key={type.uuid}
                setSuccessMessage={setSuccessMessage}
              />
            ))}
          </div>
        </div>
      ) : null}
      {item.CustomProduct ? (
        <div className='source-container'>
          <div className='source-content'>
            {item.CustomProduct.length === 0 ? (
              <div>
                <div className='empty h-100 '>
                  <img src={empty} alt='empty' />
                </div>
                <div className='text-div'>
                  <span className='spanTxt'>
                    There are no Policy type currently in this Policy Category.
                    Please add a new Policy type
                  </span>
                </div>
              </div>
            ) : (
              item.CustomProduct?.map((type: any) => (
                <TypeCard
                  category={item}
                  type={type}
                  key={type.uuid}
                  setSuccessMessage={setSuccessMessage}
                />
              ))
            )}
          </div>
        </div>
      ) : null}
      {item.type === 'CUSTOM_PRODUCT_CATEGORY' ? (
        <button
          className='addSourceButton'
          onClick={() => setShowAddType(true)}
        >
          Add new policy type
        </button>
      ) : null}

      {showEditCategory && (
        <EditCategoryModal
          showEditCategory={showEditCategory}
          setShowEditCategory={setShowEditCategory}
          category={item}
          setSuccessMessage={setSuccessMessage}
        />
      )}
      {showAddType && (
        <AddPolicyType
          showAddType={showAddType}
          setShowAddType={setShowAddType}
          setSuccessMessage={setSuccessMessage}
          defaultType={item}
        />
      )}
      {showDeleteModal && (
        <DeleteCategory
          category={item}
          showModal={showDeleteModal}
          setShowModal={setShowDeleteModal}
          setSuccessMessage={setSuccessMessage}
        />
      )}
    </div>
  );
};
const TypeCard = ({
  category,
  type,
  setSuccessMessage,
}: {
  category: any;
  type: any;
  setSuccessMessage: Function;
}) => {
  const [showEditPolicyType, setShowEditPolicyType] = useState(false);
  const [showDeleteSource, setShowDeleteSource] = useState(false);

  return (
    <div className='source-card d-flex justify-content-between align-items-center'>
      <div>
        <div className='source-title'>{type.name}</div>
        <div className='source-type'>
          {type.LineOfBusiness?.name.toLowerCase().replace('_', ' ')}
        </div>
      </div>
      {category?.type === 'CUSTOM_PRODUCT_CATEGORY' && (
        <div className='d-flex editDeleteIcon'>
          <div className='edit-icon'>
            <div
              className='card-icon'
              onClick={() => setShowEditPolicyType(true)}
            >
              <img src={edit} alt='' />
            </div>
          </div>

          <div className='card-icon' onClick={() => setShowDeleteSource(true)}>
            <img src={cross} alt='' />
          </div>
        </div>
      )}
      {showEditPolicyType && (
        <EditPolicyType
          policyType={type}
          defaultType={category}
          setShowEditPolicyType={setShowEditPolicyType}
          showEditPolicyType={showEditPolicyType}
          setSuccessMessage={setSuccessMessage}
        />
      )}
      {showDeleteSource && (
        <DeletePolicyType
          showModal={showDeleteSource}
          setShowModal={setShowDeleteSource}
          type={type}
          category={category}
          setSuccessMessage={setSuccessMessage}
        />
      )}
    </div>
  );
};
