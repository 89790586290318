import { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  DragDropContext,
  Droppable,
  DropResult,
  Draggable,
} from 'react-beautiful-dnd';
import { useDispatch, useSelector } from 'react-redux';
import { Carousel } from 'react-responsive-carousel';
import moment from 'moment';
import {
  updateStage,
  getStageOpportuntities,
} from '../../../api/opportunities';
import { AppDispatch } from '../../../store';
import {
  onDeleteOpportunity,
  OpportunitiesSliceState,
} from '../../../store/opportunities/opportunitiesSlice';
import DeleteModal from '../../../components/DeleteModal';
import MobileNav from '../../../components/MobileTabNav';
import OpportinutyWonModal from './OpportunityWonModal';
import XDateModal from './XDateModal';
import LostModal from './LostModal';
import ErrorCard from './ErrorCard';
import WarningCard from './WarningCard';
import StageMovementModal from './StageMovementModal';
import AddQuoteModal from '../../../components/AddQuoteModal';
import EditOpportunityDetails from '../../OpportunityDetails/EditOpportunityDetails';
import { useOutsideClick } from '../../../hooks/useOutsideClick';
import {
  IOpportunity,
  IPipeline,
  IPipelineStage,
  IStageData,
} from '../../../interfaces/Opportunity';
import { KanbanDiv } from '../elements/styles';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import bin from '../../../assets/logo/bin.svg';
import vector from '../../../assets/logo/dropdown.svg';
import edit from '../../../assets/logo/edit.svg';
import plus from '../../../assets/logo/blackplus.svg';
import arrow from '../../../assets/logo/blackRightArrow.svg';
import blackPlus from '../../../assets/logo/blackplus.svg';
import calendar from '../../../assets/logo/calendar.svg';

const KanbanMobileView = ({
  stages,
  pipeline,
  lostStageId,
  xDateStageId,
  selectedProducts,
  selectedOppStatus,
  selectedActive,
  pipeLineStage,
}: {
  stages: IPipelineStage[];
  pipeline: IPipeline;
  lostStageId: string;
  xDateStageId: string;
  selectedProducts: any;
  selectedOppStatus: any;
  selectedActive: any;
  pipeLineStage: any;
}) => {
  const [allStages, setAllStages] = useState<IStageData[]>([]);
  const [showActions, setShowActions] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [action, setAction] = useState('');
  const [selectedOpp, setSelectedOpp] = useState({ id: '', initialIndex: 0 });
  const [errorMessage, setError] = useState('');
  const [showXDateModal, setXDateModal] = useState(false);
  const [opportunity, setOpportunity] = useState<IOpportunity>();
  const [wonOpportunity, setWonOpportunity] = useState('closed');
  const [source, setSource] = useState<any>();
  const [showStageModal, setShowStageModal] = useState('closed');

  const [showQuoteModal, setShowQuoteModal] = useState('closed');
  const [showEditModal, setShowEditModal] = useState('closed');
  const [opportunityUUID, setOpportunityUUID] = useState('');
  const [activeCarousel, setActiveCarousel] = useState<number | undefined>();

  const dispatch = useDispatch<AppDispatch>();
  const [name, setName] = useState('');
  const [UUID, setUUID] = useState('');
  const [showDeleteModal, setShowDeleteModal] = useState('closed');
  const [showSuccess, setShowSuccess] = useState(false);
  const onDelete = (uuid: string, opportunity: IOpportunity) => {
    dispatch(
      onDeleteOpportunity({
        uuid,
        successCB: () => {
          setShowDeleteModal('closed');
          setShowSuccess(true);
          async function fetchOpportunities() {
            const res = await getStageOpportuntities(
              '',
              '',
              '',
              stages,
              selectedOppStatus?.map((item: any) => item.value),
              selectedProducts?.map((item: any) => item.value),
              selectedActive?.value,
              pipeLineStage
            );
            setAllStages(res);
          }
          fetchOpportunities();
          setError(
            `<b>${name}</b> opportunity is deleted'
              )}</b>.`
          );
          setTimeout(() => {
            setShowSuccess(false);
          }, 3000);
        },
      })
    );
  };

  const loadingStatus = useSelector(
    (state: { opportunities: OpportunitiesSliceState }) =>
      state.opportunities?.status
  );

  useEffect(() => {
    async function fetchOpportunities() {
      const res = await getStageOpportuntities(
        '',
        '',
        '',
        stages,
        '',
        selectedProducts?.map((item: any) => item.value),
        selectedActive?.value,
        pipeLineStage
      );
      setAllStages(res);
    }
    fetchOpportunities();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    pipeline,
    selectedOppStatus,
    selectedProducts,
    selectedActive,
    pipeLineStage,
  ]);

  const onLoseItem = (reason: string, xDate: string) => {
    updateStage(
      {
        action: 'changePipelineStage',
        pipelineStageUUID: action === 'LOST' ? lostStageId : xDateStageId,
        reason,
        xDate,
      },
      allStages[Number(selectedOpp.id)].opportunities[selectedOpp.initialIndex]
        .uuid,
      (opp: IOpportunity) => {
        const updatedStages = [...allStages];
        const initialIndex = Number(selectedOpp.id);
        const initialOppIndex = selectedOpp.initialIndex;
        updatedStages[initialIndex].sum -= opp.premium;
        let name = opp.name ? opp.name : '--';
        updatedStages[initialIndex].opportunities.splice(initialOppIndex, 1);
        updatedStages[initialIndex].count -= 1;
        setAllStages(updatedStages);
        setShowModal(false);
        setXDateModal(false);
        action === 'LOST'
          ? setError(`<b>${name}</b> policy is deleted.`)
          : setError(
              `<b>${name}</b> policy moved back till <b>${moment(xDate).format(
                'MMMM Do YYYY'
              )}</b>.`
            );
        setTimeout(() => {
          setError('');
        }, 3000);
      }
    );
  };

  const onDragEnd = ({ destination, source }: DropResult) => {
    if (!destination) return;
    if (destination?.droppableId === 'delete') {
      setSelectedOpp({ id: source.droppableId, initialIndex: source.index });
      setAction('LOST');
      setShowModal(true);
    } else if (destination?.droppableId === 'xDate') {
      setSelectedOpp({ id: source.droppableId, initialIndex: source.index });
      setAction('X-Date');
      setXDateModal(true);
    } else if (source.droppableId !== destination.droppableId) {
      if (Number(destination.droppableId) === stages.length - 1) {
        setOpportunity(
          allStages[Number(source.droppableId)].opportunities[source.index]
        );
        setWonOpportunity('open');
        setSource(source);
      } else {
        setOpportunity(
          allStages[Number(source.droppableId)].opportunities[source.index]
        );
        updateStage(
          {
            action: 'changePipelineStage',
            pipelineStageUUID: stages[Number(destination.droppableId)].uuid,
          },
          allStages[Number(source.droppableId)].opportunities[source.index]
            .uuid,
          (opp: IOpportunity) => {
            const updatedStages = [...allStages];

            const sourceColumn = updatedStages[Number(source.droppableId)];
            const destColumn = updatedStages[Number(destination.droppableId)];
            const sourceOpps = [...sourceColumn.opportunities];
            const [removed] = sourceOpps.splice(source.index, 1);
            sourceColumn.opportunities = sourceOpps;
            destColumn.opportunities = [...destColumn.opportunities, removed];
            sourceColumn.sum -= opp.premium;
            sourceColumn.count -= 1;
            destColumn.sum += opp.premium;
            destColumn.count += 1;

            updatedStages[Number(source.droppableId)] = sourceColumn;
            updatedStages[Number(destination.droppableId)] = destColumn;
            if (
              source.index ===
              allStages[Number(source.droppableId)].opportunities.length
            ) {
              setAllStages([]);
            }
            setAllStages(updatedStages);
            setShowStageModal('open');
          }
        );
      }
    } else {
      return;
    }
  };

  const updateOpportunityData = (opp: IOpportunity) => {
    const updatedStages = [...allStages];
    const initialIndex = Number(selectedOpp.id);
    const initialOppIndex = selectedOpp.initialIndex;
    updatedStages[initialIndex].sum -= opp.premium;
    updatedStages[initialIndex].opportunities.splice(initialOppIndex, 1);
    updatedStages[initialIndex].count -= 1;
  };

  return (
    <KanbanDiv>
      {showSuccess && (
        <ErrorCard message={`<b>${name}</b> is <b> deleted</b> `} show='open' />
      )}
      <div>
        <div className='stage-container' style={{ minHeight: '50vh' }}>
          <DragDropContext onDragEnd={(result) => onDragEnd(result)}>
            {stages?.map((stage, index: number) => (
              <div
                key={stage.uuid}
                className='stage-singles'
                // onMouseEnter={() => setActiveCarousel(index)}
              >
                <Droppable droppableId={`${index}`} direction='horizontal'>
                  {(droppableProvided, snapshot) => {
                    return (
                      <>
                        <div className='stages-div' key={stage.uuid}>
                          <div className='w-100 stage-header'>
                            <h5 className='card-title'>
                              {stage.name}
                              <span className='count-div'>
                                {allStages[index]?.count}
                              </span>
                            </h5>
                          </div>
                        </div>
                        <div
                          {...droppableProvided.droppableProps}
                          ref={droppableProvided.innerRef}
                          style={{ height: '100%' }}
                        >
                          <Carousel
                            showThumbs={false}
                            showArrows={false}
                            showStatus={false}
                            selectedItem={0}
                            className={
                              activeCarousel === index ? 'active-carousel' : ''
                            }
                          >
                            {allStages[index]?.opportunities?.map(
                              (opportunity, oppIndex) => (
                                <Draggable
                                  draggableId={opportunity.uuid}
                                  index={oppIndex}
                                  key={opportunity.uuid}
                                  isDragDisabled={stage.name === 'Won'}
                                >
                                  {(provided, snapshot) => (
                                    <KanbanMobileCard
                                      setShowActions={setShowActions}
                                      provided={provided}
                                      opportunity={opportunity}
                                      setShowEditModal={setShowEditModal}
                                      setShowQuoteModal={setShowQuoteModal}
                                      setOpportunityUUID={setOpportunityUUID}
                                      droppableProvided={droppableProvided}
                                      setActiveCarousel={setActiveCarousel}
                                      index={index}
                                      setShowDeleteModal={setShowDeleteModal}
                                      setName={setName}
                                      setUUID={setUUID}

                                      // uuid={opportunity?.uuid}
                                      // dropRef={dropRef}
                                    />
                                  )}
                                </Draggable>
                              )
                            )}
                          </Carousel>
                          {allStages[index]?.opportunities?.length <= 1
                            ? droppableProvided.placeholder
                            : null}
                        </div>
                      </>
                    );
                  }}
                </Droppable>
              </div>
            ))}
            {showActions && (
              <div className='hover-div'>
                <Droppable droppableId='delete'>
                  {(provided, snapshot) => {
                    return (
                      <button
                        className='deleteButton d-flex align-items-center '
                        style={{
                          backgroundColor: snapshot.isDraggingOver
                            ? '#cc0000'
                            : '#fff',
                          color: snapshot.isDraggingOver ? '#fff' : '#cc0000',
                        }}
                        {...provided.droppableProps}
                        ref={provided.innerRef}
                      >
                        <img
                          src={bin}
                          alt='delete'
                          style={{
                            filter: snapshot.isDraggingOver
                              ? 'brightness(0) saturate(100%) invert(100%) sepia(0%) saturate(7500%) hue-rotate(134deg) brightness(98%) contrast(105%)'
                              : 'none',
                          }}
                        />
                        Lost
                        {provided.placeholder}
                      </button>
                    );
                  }}
                </Droppable>
                <Droppable droppableId='xDate'>
                  {(provided, snapshot) => {
                    return (
                      <button
                        className='xDateButton  d-flex align-items-center'
                        style={{
                          backgroundColor: snapshot.isDraggingOver
                            ? '#262758'
                            : '#fff',
                          color: snapshot.isDraggingOver ? '#fff' : '#262758',
                        }}
                        {...provided.droppableProps}
                        ref={provided.innerRef}
                      >
                        <img
                          src={calendar}
                          alt='xdate'
                          style={{
                            filter: snapshot.isDraggingOver
                              ? 'brightness(0) saturate(100%) invert(100%) sepia(0%) saturate(7500%) hue-rotate(134deg) brightness(98%) contrast(105%)'
                              : 'brightness(0) saturate(100%) invert(12%) sepia(8%) saturate(4027%) hue-rotate(188deg) brightness(95%) contrast(86%)',
                          }}
                        />
                        X-Date
                        {provided.placeholder}
                      </button>
                    );
                  }}
                </Droppable>
              </div>
            )}
          </DragDropContext>
        </div>
      </div>

      <LostModal
        showModal={showModal}
        onCloseModal={() => {
          setShowModal(false);
          setSelectedOpp({ id: '', initialIndex: 0 });
        }}
        action={action}
        setAction={setAction}
        onLoseOpp={onLoseItem}
      />

      {action === 'LOST' && errorMessage.length > 0 && (
        <ErrorCard message={errorMessage} show='open' />
      )}
      {action === 'X-Date' && errorMessage.length > 0 && (
        <WarningCard message={errorMessage} show='open' />
      )}

      {showXDateModal && (
        <XDateModal
          showModal={showXDateModal}
          onCloseModal={() => {
            setXDateModal(false);
            setSelectedOpp({ id: '', initialIndex: 0 });
          }}
          action={action}
          setAction={setAction}
          onLoseOpp={onLoseItem}
        />
      )}
      {wonOpportunity !== 'closed' && opportunity && (
        <OpportinutyWonModal
          show={wonOpportunity}
          setWonOpportunity={setWonOpportunity}
          opportunity={opportunity}
          allStages={allStages}
          setAllStages={setAllStages}
          source={source}
          stages={stages}
        />
      )}
      {showStageModal !== 'closed' && opportunity && (
        <StageMovementModal
          open={showStageModal}
          closeModal={setShowStageModal}
          uuid={opportunity?.uuid}
          stages={stages}
        />
      )}

      {showQuoteModal !== 'closed' && (
        <AddQuoteModal
          show={showQuoteModal}
          closeModal={setShowQuoteModal}
          type='Opportunity'
        />
      )}
      {showEditModal !== 'closed' && (
        <EditOpportunityDetails
          UUID={opportunityUUID}
          show={showEditModal}
          closeModal={setShowEditModal}
        />
      )}
      {showDeleteModal !== 'closed' && (
        <DeleteModal
          showModal={showDeleteModal}
          closeModal={setShowDeleteModal}
          deleteFunction={onDelete}
          uuid={UUID}
          loading={loadingStatus}
          resource='opportunity'
          name={name}
        />
      )}
      <div className='d-block d-xl-none'>
        <MobileNav />
      </div>
    </KanbanDiv>
  );
};

export default KanbanMobileView;

const KanbanMobileCard = ({
  setShowActions,
  provided,
  opportunity,
  setShowQuoteModal,
  setShowEditModal,
  setOpportunityUUID,
  droppableProvided,
  setActiveCarousel,
  index,
  setShowDeleteModal,
  setName,
  setUUID,
}: {
  setShowActions: Function;
  provided: any;
  opportunity: IOpportunity;
  setShowQuoteModal: Function;
  setShowEditModal: Function;
  setOpportunityUUID: Function;
  droppableProvided: any;
  setActiveCarousel: Function;
  index: number;
  setShowDeleteModal: Function;
  setName: Function;
  setUUID: Function;
}) => {
  const [showDropdown, setShowDropdown] = useState(false);
  const dropRef = useRef(null);
  const navigate = useNavigate();
  useOutsideClick(dropRef, setShowDropdown);

  return (
    <div
      onMouseEnter={(e) => setShowActions(true)}
      onTouchStart={(e) => setShowActions(true)}
      onTouchEnd={(e) => setShowActions(false)}
      className='draggable'
      ref={provided.innerRef}
      {...provided.draggableProps}
      {...provided.dragHandleProps}
    >
      <div className='d-flex'>
        <h5 className='card-title'>
          {opportunity.name ? opportunity.name : '--'}
        </h5>
        <span className='amount'>
          $
          {opportunity.premium
            ? opportunity.premium.toLocaleString('en-US')
            : 0}
        </span>
      </div>
      <div className='mb-2' style={{ textAlign: 'left' }}>
        {opportunity.Account.name ? opportunity.Account.name : '--'}
      </div>
      <div className='lastupdate d-flex justify-content-between'>
        <div>
          Last updated: {moment(opportunity.updatedAt).format('MM/DD/YY')}
        </div>
        <button
          className='vector d-flex align-items-center justify-content-center position-relative'
          onClick={() => {
            setShowDropdown(!showDropdown);
          }}
          onMouseEnter={() => {
            setActiveCarousel(index);
          }}
          onMouseLeave={() => {
            setActiveCarousel();
          }}
          ref={dropRef}
        >
          <img src={vector} alt='vector' />
          {showDropdown && (
            <div
              className={`dropdown-div ${
                opportunity?.PipelineStage?.name === 'Won' && 'Wonopportunity'
              }`}
            >
              {opportunity?.PipelineStage?.name !== 'Won' && (
                <>
                  <div
                    className='quotediv'
                    onClick={() => setShowQuoteModal('open')}
                  >
                    Add Quote <img src={plus} alt='plus' />
                  </div>
                </>
              )}
              <div
                className='edit-div'
                onClick={() => {
                  setShowEditModal('open');
                  setOpportunityUUID(opportunity?.uuid);
                  setShowDropdown(false);
                }}
              >
                Edit <img src={edit} alt='bin' />
              </div>
              <div
                className='edit-div'
                style={{ paddingTop: '10px' }}
                onClick={() =>
                  navigate(`/opportunities/${opportunity.uuid}/activities`)
                }
              >
                Add activity <img src={blackPlus} alt='plusIcon' />
              </div>
              <div
                className='delete-div'
                onClick={() => {
                  setShowDeleteModal('open');
                  setUUID(opportunity.uuid);
                  setShowDropdown(false);
                  setName(opportunity.name);
                }}
              >
                Delete <img src={bin} alt='bin' />
              </div>
            </div>
          )}
        </button>
      </div>
      {droppableProvided.placeholder}
    </div>
  );
};
