import moment from 'moment';
import ActionsDropdown from './dropdown';
import call from '../../../assets/logo/phone.svg';
import message from '../../../assets/logo/message-action.svg';
import document from '../../../assets/logo/DocumentTrans.svg';
import mail from '../../../assets/logo/mail.svg';
import reminder from '../../../assets/logo/clock.svg';
import { useDispatch } from 'react-redux';
import { AppDispatch } from '../../../store';
import { updateTaskDetails } from '../../../store/tasks/tasksSlice';
import { useEffect, useState } from 'react';
import Task from './task';
import { EditTask } from '../styles';
const TaskDetail = ({
  item,
  handleDeleteTask,
  taskType,
  taskPriority,
  producers,
  isMobile,
  ApiSuccess,
  setEdited,
}: {
  item: any;
  handleDeleteTask: Function;
  taskType: any;
  taskPriority: any;
  producers: any;
  isMobile?: boolean;
  ApiSuccess: boolean;
  setEdited: Function;
}) => {
  const [taskData, setTasks] = useState<any>({});
  const [showEdit, setShowEdit] = useState(false);
  const dispatch = useDispatch<AppDispatch>();
  const [file, setFile] = useState<any>();
  const [docType, setDocType] = useState('');
  const [docTypeUUID, setDocTypeUUID] = useState('');
  const handleEditTask = (uuid: string, value: any, index?: number) => {
    setShowEdit(true);
    const task: any = {};
    task.taskStatusUUID = value.TaskStatus.uuid;
    task.taskTypeUUID = value.TaskType.uuid;
    task.taskPriorityUUID = value.TaskPriority.uuid;
    task.dueDate = value.dueDate;
    task.description = value.text;
    task.assigneeUUID = value.Assignee.uuid;
    if (value?.Documents?.length > 0) task.file = value?.Documents[0];
    setAssignee(
      producers.find((el: any) => el.User.uuid === value.Assignee.uuid)
    );
    setTasks(task);
  };
  const editTask = () => {
    setAddNoteLoading(true);
    setEdited('Task');
    dispatch(
      updateTaskDetails({
        uuid: item.data.uuid,
        data: taskData,
        successCB: () => {
          setShowEdit(false);
          setAddNoteLoading(false);
          setTimeout(() => {
            setEdited('');
          }, 2000);
        },
      })
    );
  };

  const onItem = (name: string, value: string) => {
    let updatedTask = { ...taskData };
    updatedTask[name] = value;
    setTasks(updatedTask);
  };
  const [assignee, setAssignee] = useState<any>({});
  const [addNoteLoading, setAddNoteLoading] = useState(false);

  return (
    <>
      {showEdit ? (
        <EditTask>
          <div className='info '>
            <div className='d-flex imageCard align-items-center '>
              {!isMobile && (
                <div
                  className={`profile-pic  d-none  d-sm-flex align-items-center justify-content-center`}
                >
                  <div className='profile'>
                    {taskData?.CreatedBy?.firstName?.charAt(0)}
                  </div>
                </div>
              )}
              <div className='task-edit-container'>
                <Task
                  handleTaskSubmit={editTask}
                  setFile={setFile}
                  file={file}
                  setDocType={setDocType}
                  docType={docType}
                  docTypeUUID={docTypeUUID}
                  setDocTypeUUID={setDocTypeUUID}
                  taskType={taskType}
                  taskPriority={taskPriority}
                  assignee={assignee}
                  setAssignee={setAssignee}
                  producers={producers}
                  onItem={onItem}
                  ApiSuccess={ApiSuccess}
                  tasks={taskData}
                  addNoteLoading={addNoteLoading}
                  editTask={true}
                  isMobile={isMobile ? true : false}
                  setShowEdit={setShowEdit}
                />
              </div>
            </div>
          </div>
        </EditTask>
      ) : (
        <div className='info '>
          <div className='d-none d-sm-flex imageCard align-items-center '>
            {!isMobile && (
              <div
                className={`profile-pic d-flex align-items-center justify-content-center`}
              >
                <div className='profile'>
                  {item?.data?.CreatedBy?.firstName?.charAt(0)}
                </div>
              </div>
            )}
            <h2 className='title Heading '>
              {item?.data?.CreatedBy?.firstName}{' '}
              {item?.data?.CreatedBy?.lastName}
            </h2>
            <h2 className=' title added '>
              added a Task and assigned it to{' '}
              <b>
                {item?.data.Assignee?.firstName}{' '}
                {item?.data?.Assignee?.lastName}
              </b>
            </h2>
            <h6 className=' title dateFont '>
              on {moment(item.data.createdAt).format('Do MMMM YYYY, h:mm A')}
            </h6>
          </div>
          <p className='imageCard mobile-imagecard  d-sm-none'>
            {' '}
            <b className='title'>
              {item?.data?.CreatedBy?.firstName}{' '}
              {item?.data?.CreatedBy?.lastName}
            </b>
            <span className=' title added '>
              added a Task and assigned it to{' '}
              <b>
                {item?.data.Assignee?.firstName}{' '}
                {item?.data?.Assignee?.lastName}
              </b>
            </span>
            <span className=' title dateFont '>
              {' '}
              on
              {moment(item?.data?.createdAt).format(
                'Do MMMM YYYY, h:mm A'
              )}{' '}
            </span>
          </p>
          <div className='contentDiv d-sm-flex justify-content-between'>
            <div>
              <div className='d-flex'>
                <h6>
                  {item?.data.TaskType?.name.charAt(0).toUpperCase() +
                    item?.data.TaskType?.name.slice(1).toLowerCase()}
                </h6>
                <span
                  className={` priorityDiv ${
                    item?.data?.TaskPriority?.name === 'LOW'
                      ? 'lowPriority'
                      : item?.data?.TaskPriority?.name === 'MEDIUM'
                      ? 'mediumPriority'
                      : 'highPriority'
                  }`}
                >
                  {item?.data?.TaskPriority?.name.toLowerCase()}
                </span>
              </div>

              <span style={{ marginTop: '-3px' }} className='d-none d-sm-block'>
                {item.data.text}
              </span>
            </div>
            <div className='d-flex d-sm-block'>
              <h6 className='due-div'>Due</h6>
              <span>
                {moment(item.data.dueDate).format('Do MMMM YYYY | h:mm A')}
              </span>
            </div>
            <div className='d-flex'>
              <div className='d-flex align-items-center d-sm-none task-text-mobile'>
                <span>{item.data.text}</span>
              </div>
              <div className='d-flex position-relative text-overflow-container'>
                {taskData?.file && (
                  <a
                    target='_blank'
                    href={taskData?.file?.s3Location}
                    className='task-type-icon-div me-2'
                  >
                    <img src={document} alt='' className='documentIcon mt-2' />
                    <span className='tooltiptext'>
                      <span className='tooltipInnertext'>
                        {taskData?.file?.name}
                      </span>
                    </span>
                  </a>
                )}
              </div>
              <button type='button' className='task-type-icon-div'>
                {item?.data.TaskType?.name === 'CALL' && (
                  <img src={call} alt='' />
                )}
                {item?.data.TaskType?.name === 'EMAIL' && (
                  <img src={mail} alt='' />
                )}
                {item?.data.TaskType?.name === 'SMS' && (
                  <img src={message} alt='' />
                )}
                {item?.data.TaskType?.name === 'REMINDER' && (
                  <img src={reminder} alt='' />
                )}
              </button>
              <ActionsDropdown
                uuid={item.data.uuid}
                item={item.data}
                handleDelete={handleDeleteTask}
                handleEdit={handleEditTask}
              />
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default TaskDetail;
