import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  BrowserRouter as Router,
  Navigate,
  Route,
  Routes,
} from 'react-router-dom';
import Home from './pages/Home';
import Registration from './pages/Register';
import Login from './pages/Login';
import ResetPassword from './pages/ResetPassword';
import ChangePassword from './pages/ChangePassword';
import './App.css';
import PrivateOutlet from './utils/PrivateOutlet';
import PublicOutlet from './utils/PublicOutlet';
import Admin from './pages/Admin';
import MyBook from './pages/MyBook';
import LocalStorageService from './utils/LocalStorageService';
import { getUser, SliceState } from './store/user/userSlice';
import { AppDispatch } from './store';
import AdminOutlet from './utils/AdminOutlet';
import AccountDetails from './pages/AccountDetails';
import OpportunityDetails from './pages/OpportunityDetails';
import ContactDetails from './pages/ContactDetails';
import Pipeline from './pages/Pipeline';
import PolicyDetails from './pages/PolicyDetails';
import Activities from './pages/Activities';
import MyTeamList from './pages/MyTeam';
import Search from './pages/Search';
import MyProfile from './pages/MyProfile';
import Notifications from './pages/Notifications';
import Settings from './pages/Settings';
import GlobalTerms from './pages/GlobalTerms';
import ErrorPage from './pages/ErrorPage';

function App() {
  const dispatch = useDispatch<AppDispatch>();
  const loadingStatus = useSelector(
    (state: { user: SliceState }) => state.user.status
  );

  const access = LocalStorageService.getAccessToken();

  useEffect(() => {
    if (access) {
      if (loadingStatus === 'idle' || loadingStatus === 'succeeded') {
        dispatch(getUser());
      }
    } // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (Notification.permission !== 'granted') {
      Notification.requestPermission();
    }
  }, []);

  return (
    <Router>
      <Routes>
        <Route path='/' element={<PublicOutlet />}>
          <Route path='' element={<Login />} />
        </Route>
        <Route path='/global-terms-condition/*' element={<GlobalTerms />} />
        <Route path='/dashboard' element={<PrivateOutlet />}>
          <Route path='' element={<Home />} />
        </Route>
        <Route path='/register' element={<Registration />} />
        <Route path='/reset-password' element={<ResetPassword />} />
        <Route path='/change-password' element={<ChangePassword />} />
        <Route path='/admin/*' element={<AdminOutlet />}>
          <Route path='*' element={<Admin />} />
        </Route>
        <Route path='/mybook/*' element={<PrivateOutlet />}>
          <Route path='*' element={<MyBook />} />
        </Route>
        <Route path='/account/:uuid/*' element={<PrivateOutlet />}>
          <Route path='*' element={<AccountDetails />} />
        </Route>
        <Route path='/opportunities/:uuid/*' element={<PrivateOutlet />}>
          <Route path='*' element={<OpportunityDetails />} />
        </Route>
        <Route path='/contacts/:uuid/*' element={<PrivateOutlet />}>
          <Route path='*' element={<ContactDetails />} />
        </Route>
        <Route path='/policy/:uuid/*' element={<PrivateOutlet />}>
          <Route path='*' element={<PolicyDetails />} />
        </Route>
        <Route path='/pipeline/*' element={<PrivateOutlet />}>
          <Route path='*' element={<Pipeline />} />
        </Route>
        <Route path='/activities/*' element={<PrivateOutlet />}>
          <Route path='*' element={<Activities />} />
        </Route>
        <Route path='/myprofile/*' element={<PrivateOutlet />}>
          <Route path='*' element={<MyProfile />} />
        </Route>
        <Route path='/myteam/*' element={<PrivateOutlet />}>
          <Route path='*' element={<MyTeamList />} />
        </Route>
        <Route path='/search/*' element={<PrivateOutlet />}>
          <Route path='*' element={<Search />} />
        </Route>
        <Route path='/notification/*' element={<PrivateOutlet />}>
          <Route path='*' element={<Notifications />} />
        </Route>
        <Route path='/settings/*' element={<PrivateOutlet />}>
          <Route path='*' element={<Settings />} />
        </Route>
        <Route path='/error/*' element={<ErrorPage />} />
        <Route path='*' element={<Navigate to='/error/404' replace />} />
      </Routes>
    </Router>
  );
}

export default App;
