import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Moment from 'react-moment';
import { SearchCardDiv } from '../style';
import go from '../../../assets/logo/go.svg';
import LocalStorageService from '../../../utils/LocalStorageService';

export interface IProps {
  item: any;
  searchText: string;
}

const SearchCard = ({ item, searchText }: IProps) => {
  const navigate = useNavigate();
  const [showIcon, setShowIcon] = useState(false);

  const getHighlightedText = (text: string) => {
    const parts = text.split(new RegExp(`(${searchText})`, 'gi'));
    return parts.map((part, i) => (
      <span
        key={i}
        style={
          part?.toLowerCase() === searchText?.toLowerCase()
            ? { background: '#FBF5E5' }
            : {}
        }
      >
        {part}
      </span>
    ));
  };

  return (
    <SearchCardDiv
      className='search-content'
      key={item.uuid}
      onClick={() => {
        LocalStorageService.setHistory({
          type: item.model,
          text: item.name,
          data: item,
        });
        if (item.model === 'contact') navigate(`/contacts/${item.uuid}`);
        else if (item.model === 'account') navigate(`/account/${item.uuid}`);
        else if (item.model === 'opportunity')
          navigate(`/opportunities/${item.uuid}`);
        else if (item.model === 'policy') navigate(`/policy/${item.uuid}`);
        else if (item.model === 'task')
          navigate('/activities/task', { state: { uuid: item.uuid } });
      }}
      onMouseEnter={() => setShowIcon(true)}
      onMouseLeave={() => setShowIcon(false)}
    >
      {item.imageUrl ? (
        <div className='profile-img d-flex align-items-center justify-content-center'>
          <img src={item.imageUrl} alt='' />
        </div>
      ) : (
        <div className='profile-pic d-flex align-items-center justify-content-center'>
          {item?.name
            ?.match(/\b(\w)/g)
            ?.slice(0, 2)
            ?.join('')}
        </div>
      )}
      <div>
        <h6>{getHighlightedText(item.name)}</h6>
        <ul className='user-details'>
          {item.email ? <li>{item?.email}</li> : null}
          <li>
            Linked to:{' '}
            {item.linkedTo?.map((el: any, index: number) => (
              <span
                key={index}
                style={{ cursor: 'pointer' }}
                onClick={(e) => {
                  e.stopPropagation();
                  if (el.model === 'contact') navigate(`/contacts/${el.uuid}`);
                  else if (el.model === 'account')
                    navigate(`/account/${el.uuid}`);
                  else if (el.model === 'opportunity')
                    navigate(`/opportunities/${el.uuid}`);
                  else if (el.model === 'policy')
                    navigate(`/policy/${el.uuid}`);
                }}
              >
                <span style={{ cursor: 'pointer' }} className='link-title'>
                  {el.name}
                  <span className='search-tooltip'>
                    <b>{el.name}</b>
                    {el.model === 'opportunity' ? (
                      <span>
                        {el.description[0]}, Expires on{' '}
                        <Moment format='MM/DD/YYYY'>{el.description[1]}</Moment>
                      </span>
                    ) : (
                      el.description.map((item: string) => (
                        <span className='d-block'>{item}</span>
                      ))
                    )}
                  </span>
                </span>

                {index < item.linkedTo.length - 1 ? ', ' : ''}
              </span>
            ))}
          </li>
        </ul>
      </div>
      {showIcon && (
        <a
          href={
            item.model === 'contact'
              ? `/contacts/${item.uuid}`
              : item.model === 'account'
              ? `/account/${item.uuid}`
              : item.model === 'opportunity'
              ? `/opportunities/${item.uuid}`
              : item.model === 'policy'
              ? `/policy/${item.uuid}`
              : '/search'
          }
          target='_blank'
          rel='noreferrer'
          onClick={(e) => e.stopPropagation()}
          className='go-icon'
        >
          <img src={go} alt='' />
        </a>
      )}
    </SearchCardDiv>
  );
};

export default SearchCard;
