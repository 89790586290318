import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Navigate, Route, Routes, useLocation } from 'react-router-dom';
import { AppDispatch } from '../../store';
import {
  getAllActivtiesCount,
  selectAllActivitiesCount,
  TaskSliceState,
} from '../../store/tasks/tasksSlice';
import { getActivtiesCount } from '../../api/activites';
import MobileTabNav from '../../components/MobileTabNav';
import Link from '../../components/Link';
import SideBar from '../../components/SideBar';
import TopBar from '../../components/TopBar';
import AllTaskEvents from './component/AllTaskEvents';
import Events from './component/Events';
import Tasks from './component/Tasks';
import { StyledTab } from '../../styles/StyledTab';
import { ActivityContentDiv } from './elements/styles';

function Activities() {
  const [dropDownMobile, setDropDownMobile] = useState(false);
  const [showAddTaskEvent, setShowAddTaskEvent] = useState('closed');
  const dispatch = useDispatch<AppDispatch>();
  const location = useLocation();
  const [count, setCount] = useState<any>();
  const [action, setAction] = useState(true);

  useEffect(() => {
    if (action) {
      getActivtiesCount().then((res: any) => {
        setCount(res.data);
      });
      setAction(false);
    }
  }, [action]);

  const currentTab = useSelector(
    (state: { tasks: TaskSliceState }) => state.tasks.currentTab
  );
  useEffect(() => {
    dispatch(getAllActivtiesCount({}));
  }, [showAddTaskEvent, dispatch, action]);
  const ActivitiesCount = useSelector(selectAllActivitiesCount);

  return (
    <>
      <div className='d-none d-xl-block'>
        <SideBar />
      </div>
      <TopBar>
        <div className='d-none d-sm-flex justify-content-between w-100'>
          <h2>Activities</h2>
        </div>
      </TopBar>
      <div className='d-xl-none '>
        <MobileTabNav />
      </div>
      <ActivityContentDiv>
        <div className='d-flex d-sm-none headBar  w-100'>
          <h2>Activities</h2>
        </div>
        <div className='d-none d-sm-block'>
          <StyledTab>
            <Link to='/activities/all'>
              All
              <span className='count'>
                {ActivitiesCount.eventCount !== undefined ||
                ActivitiesCount?.taskCount !== undefined
                  ? currentTab === 'All'
                    ? ActivitiesCount?.eventCount + ActivitiesCount?.taskCount
                    : count?.eventCount + count?.taskCount
                  : 0}
              </span>
            </Link>
            <Link to='/activities/task'>
              Task{' '}
              <span className='count'>
                {currentTab === 'Tasks'
                  ? ActivitiesCount?.taskCount || 0
                  : count?.taskCount || 0}
              </span>
            </Link>
            <Link to='/activities/event'>
              Event
              <span className='count'>
                {currentTab === 'Events'
                  ? ActivitiesCount?.eventCount || 0
                  : count?.eventCount || 0}
              </span>
            </Link>
          </StyledTab>
        </div>
        <StyledTab className='d-sm-none mobile-tab'>
          <div className='Activities '>
            <span
              className='active'
              onClick={() => setDropDownMobile(!dropDownMobile)}
            >
              {location.pathname === '/activities/all' && 'All'}
              {location.pathname === '/activities/task' && 'Task'}
              {location.pathname === '/activities/event' && 'Event'}
            </span>
            <span className='Activecount'>
              {(location.pathname === '/activities/event' &&
                ActivitiesCount?.eventCount) ||
                (location.pathname === '/activities/task' &&
                  ActivitiesCount?.taskCount) ||
                (location.pathname === '/activities/all' &&
                  ActivitiesCount?.eventCount + ActivitiesCount?.taskCount)}
            </span>
            {dropDownMobile && (
              <div className='dropDown'>
                <div
                  className='navMobile '
                  onClick={() => setDropDownMobile(false)}
                >
                  {' '}
                  <Link to='/activities/all'>
                    All
                    <span className='count'>
                      {ActivitiesCount.eventCount !== undefined ||
                      ActivitiesCount?.taskCount !== undefined
                        ? currentTab === 'All'
                          ? ActivitiesCount?.eventCount +
                            ActivitiesCount?.taskCount
                          : count?.eventCount + count?.taskCount
                        : 0}
                    </span>
                  </Link>
                </div>
                <div
                  className='navMobile'
                  onClick={() => setDropDownMobile(false)}
                >
                  <Link to='/activities/task'>
                    Task{' '}
                    <span className='count'>
                      {currentTab === 'Tasks'
                        ? ActivitiesCount?.taskCount || 0
                        : count?.taskCount || 0}
                    </span>
                  </Link>
                </div>
                <div
                  className='navMobile'
                  onClick={() => setDropDownMobile(false)}
                >
                  <Link to='/activities/event'>
                    Event
                    <span className='count'>
                      {currentTab === 'Events'
                        ? ActivitiesCount?.eventCount || 0
                        : count?.eventCount || 0}
                    </span>
                  </Link>
                </div>
              </div>
            )}
          </div>
        </StyledTab>
      </ActivityContentDiv>
      <Routes>
        <Route
          path='/all'
          element={
            <AllTaskEvents
              count={ActivitiesCount}
              showAddTaskEvent={showAddTaskEvent}
              setShowAddTaskEvent={setShowAddTaskEvent}
              setAction={setAction}
            />
          }
        />
        <Route
          path='/task'
          element={
            <Tasks
              count={ActivitiesCount}
              showAddTaskEvent={showAddTaskEvent}
              setShowAddTaskEvent={setShowAddTaskEvent}
              setAction={setAction}
            />
          }
        />
        <Route
          path='/event'
          element={
            <Events
              count={ActivitiesCount}
              showAddTaskEvent={showAddTaskEvent}
              setShowAddTaskEvent={setShowAddTaskEvent}
              setAction={setAction}
            />
          }
        />
        <Route path='*' element={<Navigate to='/activities/all' replace />} />
      </Routes>
    </>
  );
}

export default Activities;
