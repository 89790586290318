import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Table from '../../../assets/logo/Table.svg';
import drop from '../../../assets/logo/dropdown.svg';
import Skeleton from 'react-loading-skeleton';
import { AllActivityContentDiv } from '../elements/styles';
import plus from '../../../assets/logo/blackplus.svg';
import whitePlus from '../../../assets/logo/plus.svg';
import vector from '../../../assets/logo/dropdown.svg';
import filter from '../../../assets/logo/filter.svg';
import search from '../../../assets/logo/search.svg';
import TaskCard from './TaskCard';
import EventCard from './EventCard';
import { AppDispatch } from '../../../store';
import {
  getActivties,
  selectAllActivities,
  TaskSliceState,
  clearActivities,
  getAllActivtiesCount,
  currentTab,
} from '../../../store/tasks/tasksSlice';
import AddTaskEventModal from './AddTaskEventModal';
import DetailsTaskModal from './TaskDetailsModal';
import EventDetailsModal from './EventDetailsModal';
import { FilterContainer } from '../../MyBook/elements/styles';
import EventFilter from './FilterDrawer';
import useDebounce from '../../../hooks/useDebounce';
import close from '../../../assets/logo/cross.svg';
import WarningCard from '../../Pipeline/components/WarningCard';
import ErrorCard from '../../Pipeline/components/ErrorCard';
const AllTaskEvents = ({
  count,
  showAddTaskEvent,
  setShowAddTaskEvent,
  setAction,
}: {
  count: any;
  showAddTaskEvent: string;
  setShowAddTaskEvent: Function;
  setAction: Function;
}) => {
  const [tableView, setTableView] = useState(true);
  const [addButton, setAddButton] = useState(false);
  const [event, setEvent] = useState(false);
  const [thisWeek, setThisWeek] = useState(false);
  const [nextWeek, setNextWeek] = useState(false);
  const [overdew, showOverdew] = useState(false);
  const [UUID, setUUID] = useState('');
  const [taskDrawer, setTaskDrawer] = useState('closed');
  const [eventDrawer, setEventDrawer] = useState('closed');
  const [dew, showDew] = useState(false);
  const [closeAllTabs, setCloseAllTabs] = useState(false);
  const limit = 4;
  const [showToday, setShowToday] = useState(false);

  const sortBy = 'dueDate';
  const orderBy = 'asc';
  const tasksOffset = useSelector(
    (state: { tasks: TaskSliceState }) => state.tasks.tasksOffset
  );
  const eventsOffset = useSelector(
    (state: { tasks: TaskSliceState }) => state.tasks.eventsOffset
  );
  const [showFilter, setShowFilter] = useState('closed');

  const [eventType, setEventType] = useState<any>([]);

  const [taskStatusValue, setTaskStatusValue] = useState([]);
  const [taskType, setTaskType] = useState([]);

  const [taskPriority, setTaskPriority] = useState([]);
  const [assignee, setAssignee] = useState<any>('');

  const dispatch = useDispatch<AppDispatch>();
  const loadingStatus = useSelector(
    (state: { tasks: TaskSliceState }) => state?.tasks.status
  );

  const allActivities = useSelector(selectAllActivities);
  const getActivities = (type: string) => {
    if (loadingStatus === 'idle' || loadingStatus === 'succeeded') {
      dispatch(clearActivities());
      dispatch(
        getActivties({
          sortBy,
          orderBy,
          type,
          limit,
          tasksOffset: 0,
          eventsOffset: 0,
          eventType: eventType?.map((item: any) => item.value),
          taskType: taskType?.map((item: any) => item.value),
          taskStatus: taskStatusValue?.map((item: any) => item.value),
          taskPriority: taskPriority?.map((item: any) => item.value),
          assignee: assignee?.value,
          searchBy,
        })
      );
    }
  };
  const sortedActivities = (
    type: string,
    tasksOffset: number,
    eventsOffset: number
  ) => {
    if (loadingStatus === 'idle' || loadingStatus === 'succeeded') {
      dispatch(
        getActivties({
          sortBy,
          orderBy,
          type,
          limit,
          tasksOffset,
          eventsOffset,
          eventType: eventType?.map((item: any) => item.value),
          taskType: taskType?.map((item: any) => item.value),
          taskStatus: taskStatusValue?.map((item: any) => item.value),
          taskPriority: taskPriority?.map((item: any) => item.value),
          assignee: assignee?.value,
          searchBy,
        })
      );
    }
  };
  const filterEvent = () => {
    showDew(false);
    setShowToday(false);
    setThisWeek(false);
    setNextWeek(false);
    showOverdew(false);
    dispatch(clearActivities());
    dispatch(
      getAllActivtiesCount({
        eventType: eventType?.map((item: any) => item.value),
        taskType: taskType?.map((item: any) => item.value),
        taskStatus: taskStatusValue?.map((item: any) => item.value),
        taskPriority: taskPriority?.map((item: any) => item.value),
        assignee: assignee?.value,
        searchBy: debouncedSearchTerm,
      })
    );
  };

  useEffect(() => {
    showDew(false);
    setShowToday(false);
    setThisWeek(false);
    setNextWeek(false);
    showOverdew(false);
    dispatch(clearActivities());
    setCloseAllTabs(false);
  }, [closeAllTabs]);

  useEffect(() => {
    dispatch(currentTab({ currentTab: 'All' }));
  }, []);

  const [isSearchClicked, setIsSearchClicked] = useState(false);
  const [searchBy, setSearchBy] = useState('');
  const debouncedSearchTerm = useDebounce(searchBy, 500);
  const [onDelete, setDelete] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false);
  const [markAsDone, setMarkAsDone] = useState(false);

  useEffect(() => {
    filterEvent();
  }, [debouncedSearchTerm]);
  return (
    <AllActivityContentDiv>
      <div className='content-div'>
        {showSuccess && markAsDone && (
          <WarningCard
            message={`<b>Congratulation, thank you for your effort</b>`}
            show='open'
          />
        )}
        {showSuccess && onDelete && (
          <ErrorCard
            message={`<b>${'sdfsdf'}'s </b>Task  has  <b>Deleted</b>`}
            show='open'
          />
        )}
        <div className='d-flex TopButtons'>
          <div className='d-flex toggleContainer'>
            <div className='d-none d-sm-flex'>
              <button
                onClick={() => {
                  setTableView(true);
                }}
                className={`d-flex align-items-center justify-content-center toggleImage ${
                  tableView && 'active'
                }`}
              >
                <img src={Table} alt='' />
              </button>
            </div>
            <div className='d-flex d-sm-none'>
              {!isSearchClicked && !searchBy && (
                <>
                  <button
                    onClick={() => {
                      setTableView(true);
                    }}
                    className={`d-flex align-items-center justify-content-center toggleImage m-0 ${
                      tableView && 'active'
                    }`}
                  >
                    <img src={Table} alt='' />
                  </button>
                </>
              )}
            </div>
          </div>

          <div className='d-none d-sm-block'>
            <button
              onClick={() => setAddButton(!addButton)}
              onBlur={() => setAddButton(false)}
              className='tertiaryButton  addButton  d-flex align-items-center justify-content-between '
              type='submit'
            >
              Add
              <img src={drop} alt='' />
              {addButton && (
                <div className='dropDown '>
                  <ul
                    className='tasks d-flex  justify-content-between '
                    onClick={() => {
                      setAddButton(false);
                      setShowAddTaskEvent('open');
                      setEvent(false);
                    }}
                  >
                    Add Task <img src={plus} alt='' />
                  </ul>
                  <ul
                    className='events d-flex  justify-content-between'
                    onClick={() => {
                      setAddButton(false);
                      setShowAddTaskEvent('open');
                      setEvent(true);
                    }}
                  >
                    Add Event <img src={plus} alt='' />
                  </ul>
                </div>
              )}
            </button>
          </div>
          <button
            onClick={() => setAddButton(!addButton)}
            onBlur={() => setAddButton(false)}
            className='addbutton-mob d-sm-none'
          >
            <img src={whitePlus} alt='add' />
            {addButton && (
              <div className='dropUp '>
                <ul
                  className='tasks d-flex justify-content-between w-100 '
                  onClick={() => {
                    setAddButton(false);
                    setShowAddTaskEvent('open');
                    setEvent(false);
                  }}
                >
                  Add Task <img src={plus} alt='' />
                </ul>
                <ul
                  className='events d-flex  justify-content-between w-100'
                  onClick={() => {
                    setAddButton(false);
                    setShowAddTaskEvent('open');
                    setEvent(true);
                  }}
                >
                  Add Event <img src={plus} alt='' />
                </ul>
              </div>
            )}
          </button>
          <div className='d-flex  justify-content-end iconsButton  w-100'>
            {isSearchClicked || searchBy ? (
              <div className='book-search d-flex align-items-center m-0'>
                <img src={search} alt='' className='search-button' />
                <input
                  onChange={(e) => {
                    setSearchBy(e.target.value);
                  }}
                  autoFocus
                  value={searchBy}
                />
                <img
                  src={close}
                  alt=''
                  className='close-button'
                  onClick={() => {
                    setSearchBy('');
                    setIsSearchClicked(false);
                  }}
                />
              </div>
            ) : (
              <div
                className='book-shrinked'
                onClick={() => setIsSearchClicked(true)}
              >
                <img src={search} alt='' />
              </div>
            )}
            <div className='d-none d-sm-flex'>
              <span className={`divider `} />
              <div
                className='d-flex align-items-center position-relative'
                onClick={() => setShowFilter('open')}
                role='button'
              >
                <img src={filter} alt='' className='filter' />
                {(eventType?.length || 0) +
                  (taskPriority?.length || 0) +
                  (taskType?.length || 0) +
                  (taskStatusValue?.length || 0) +
                  (assignee?.value ? 1 : 0) >
                  0 && (
                  <span className='filter-counter'>
                    {(eventType?.length || 0) +
                      (taskPriority?.length || 0) +
                      (taskType?.length || 0) +
                      (taskStatusValue?.length || 0) +
                      (assignee?.value ? 1 : 0)}
                  </span>
                )}
              </div>
            </div>
            <div className='d-flex d-sm-none'>
              {!isSearchClicked && !searchBy && (
                <>
                  <span className={`divider `} />
                  <div
                    className='d-flex align-items-center position-relative'
                    onClick={() => setShowFilter('open')}
                  >
                    <img src={filter} alt='' className='filter' />
                    {(eventType?.length || 0) +
                      (taskPriority?.length || 0) +
                      (taskType?.length || 0) +
                      (taskStatusValue?.length || 0) +
                      (assignee?.value ? 1 : 0) >
                      0 && (
                      <span className='filter-counter'>
                        {(eventType?.length || 0) +
                          (taskPriority?.length || 0) +
                          (taskType?.length || 0) +
                          (taskStatusValue?.length || 0) +
                          (assignee?.value ? 1 : 0)}
                      </span>
                    )}
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
        <div className='activitiesContent'>
          <div
            className='DueDiv d-flex justify-content-between'
            onClick={() => {
              showDew(!dew);
              setShowToday(false);
              setThisWeek(false);
              setNextWeek(false);
              showOverdew(false);
              getActivities('overdue');
            }}
          >
            <div className='d-flex'>
              <div className='countDanger d-flex justify-content-center align-items-center '>
                {count.overdueTaskCount || count?.overdueEventCount
                  ? count?.overdueTaskCount + count?.overdueEventCount
                  : 0}
              </div>
              <span className='DueName'>Overdue</span>
            </div>
            <img src={vector} alt='' className={`vector ${dew && 'invert'}`} />
          </div>
          <div className='ContentsDiv'>
            {dew &&
              allActivities.length > 0 &&
              allActivities.map((item: any, index: number) =>
                item?.type === 'TASK' ? (
                  <TaskCard
                    key={index}
                    Data={item?.data}
                    setUUID={setUUID}
                    setTaskDrawer={setTaskDrawer}
                    setShowSuccess={setShowSuccess}
                    setMarkAsDone={setMarkAsDone}
                    setDelete={setDelete}
                    setAction={setAction}
                  />
                ) : item?.type === 'EVENT' ? (
                  <EventCard
                    key={index}
                    Data={item?.data}
                    setUUID={setUUID}
                    setEventDrawer={setEventDrawer}
                    setShowSuccess={setShowSuccess}
                    setEventDelete={setDelete}
                    setAction={setAction}
                  />
                ) : (
                  ''
                )
              )}
            {dew &&
              count?.overdueTaskCount + count?.overdueEventCount > 0 &&
              loadingStatus === 'loading' &&
              [1, 2, 3].map((item) => (
                <div key={item}>
                  {' '}
                  <Skeleton height={84} key={item} />
                </div>
              ))}
          </div>
          {dew &&
            allActivities.length !==
              count.overdueTaskCount + count.overdueEventCount &&
            (count?.overdueTaskCount >= 4 || count?.overdueEventCount >= 4) && (
              <button
                className='Card showMoreButton d-flex justify-content-center'
                onClick={() =>
                  sortedActivities('overdue', tasksOffset, eventsOffset)
                }
              >
                Show more
              </button>
            )}
          <div
            className='DueDiv d-flex justify-content-between'
            onClick={() => {
              showDew(false);
              setShowToday(!showToday);
              setThisWeek(false);
              setNextWeek(false);
              showOverdew(false);
              getActivities('duetoday');
            }}
          >
            <div className='d-flex'>
              <div className='count d-flex justify-content-center align-items-center '>
                {count.duetodayTaskCount || count?.duetodayEventCount
                  ? count?.duetodayTaskCount + count?.duetodayEventCount
                  : 0}
              </div>
              <span className='DueName'>Due Today</span>
            </div>
            <img
              src={vector}
              alt=''
              className={`vector ${showToday && 'invert'}`}
            />
          </div>
          <div className='ContentsDiv'>
            {showToday &&
              allActivities.length > 0 &&
              allActivities.map((item: any, index: number) =>
                item?.type === 'TASK' ? (
                  <TaskCard
                    key={index}
                    Data={item?.data}
                    setUUID={setUUID}
                    setTaskDrawer={setTaskDrawer}
                    setShowSuccess={setShowSuccess}
                    setMarkAsDone={setMarkAsDone}
                    setDelete={setDelete}
                    setAction={setAction}
                  />
                ) : item?.type === 'EVENT' ? (
                  <EventCard
                    key={index}
                    Data={item?.data}
                    setUUID={setUUID}
                    setEventDrawer={setEventDrawer}
                    setShowSuccess={setShowSuccess}
                    setEventDelete={setDelete}
                    setAction={setAction}
                  />
                ) : (
                  ''
                )
              )}
            {showToday &&
              count?.duetodayTaskCount + count?.duetodayEventCount > 0 &&
              loadingStatus === 'loading' &&
              [1, 2, 3].map((item) => (
                <div key={item}>
                  {' '}
                  <Skeleton height={84} key={item} />
                </div>
              ))}
            {showToday &&
              allActivities.length !==
                count?.duetodayEventCount + count?.duetodayTaskCount && (
                <button
                  className='Card showMoreButton d-flex justify-content-center'
                  onClick={() =>
                    sortedActivities('duetoday', tasksOffset, eventsOffset)
                  }
                >
                  Show more
                </button>
              )}
          </div>
          <div
            className='DueDiv d-flex justify-content-between'
            onClick={() => {
              showDew(false);
              setShowToday(false);
              setThisWeek(!thisWeek);
              setNextWeek(false);
              showOverdew(false);
              getActivities('duethisWeek');
            }}
          >
            <div className='d-flex'>
              <div className='count d-flex justify-content-center align-items-center '>
                {count?.duethisWeekTaskCount || count?.duethisWeekEventCount
                  ? count?.duethisWeekTaskCount + count?.duethisWeekEventCount
                  : 0}
              </div>
              <span className='DueName'>Due This Week</span>
            </div>
            <img
              src={vector}
              alt=''
              className={`vector ${thisWeek && 'invert'}`}
            />
          </div>
          <div className='ContentsDiv'>
            {thisWeek &&
              allActivities.length > 0 &&
              allActivities.map((item: any, index: number) =>
                item?.type === 'TASK' ? (
                  <TaskCard
                    key={index}
                    Data={item?.data}
                    setUUID={setUUID}
                    setTaskDrawer={setTaskDrawer}
                    setShowSuccess={setShowSuccess}
                    setMarkAsDone={setMarkAsDone}
                    setDelete={setDelete}
                    setAction={setAction}
                  />
                ) : item?.type === 'EVENT' ? (
                  <EventCard
                    key={index}
                    Data={item?.data}
                    setUUID={setUUID}
                    setEventDrawer={setEventDrawer}
                    setShowSuccess={setShowSuccess}
                    setEventDelete={setDelete}
                    setAction={setAction}
                  />
                ) : (
                  ''
                )
              )}
            {thisWeek &&
              count?.duethisWeekTaskCount + count?.duethisWeekEventCount > 0 &&
              loadingStatus === 'loading' &&
              [1, 2, 3].map((item) => (
                <div>
                  {' '}
                  <Skeleton height={84} key={item} />
                </div>
              ))}
            {thisWeek &&
              allActivities.length !==
                count?.duethisWeekTaskCount + count?.duethisWeekEventCount && (
                <button
                  className='Card showMoreButton d-flex justify-content-center'
                  onClick={() =>
                    sortedActivities('duethisWeek', tasksOffset, eventsOffset)
                  }
                >
                  Show more
                </button>
              )}
          </div>
          <div
            className='DueDiv d-flex justify-content-between'
            onClick={() => {
              showDew(false);
              setShowToday(false);
              setThisWeek(false);
              setNextWeek(!nextWeek);
              showOverdew(false);
              getActivities('dueNextWeek');
            }}
          >
            <div className='d-flex'>
              <div className='count d-flex justify-content-center align-items-center '>
                {count.dueNextWeekTaskCount || count?.dueNextWeekEventCount
                  ? count?.dueNextWeekTaskCount + count?.dueNextWeekEventCount
                  : 0}
              </div>
              <span className='DueName'>Due Next Week</span>
            </div>
            <img
              src={vector}
              alt=''
              className={`vector ${nextWeek && 'invert'}`}
            />
          </div>
          <div className='ContentsDiv'>
            {nextWeek &&
              allActivities.length > 0 &&
              allActivities.map((item: any, index: number) =>
                item?.type === 'TASK' ? (
                  <TaskCard
                    key={index}
                    Data={item?.data}
                    setUUID={setUUID}
                    setTaskDrawer={setTaskDrawer}
                    setShowSuccess={setShowSuccess}
                    setMarkAsDone={setMarkAsDone}
                    setDelete={setDelete}
                    setAction={setAction}
                  />
                ) : item?.type === 'EVENT' ? (
                  <EventCard
                    key={index}
                    Data={item?.data}
                    setUUID={setUUID}
                    setEventDrawer={setEventDrawer}
                    setShowSuccess={setShowSuccess}
                    setEventDelete={setDelete}
                    setAction={setAction}
                  />
                ) : (
                  ''
                )
              )}
            {nextWeek &&
              count?.dueNextWeekTaskCount + count?.dueNextWeekEventCount > 0 &&
              loadingStatus === 'loading' &&
              [1, 2, 3].map((item) => (
                <div key={item}>
                  {' '}
                  <Skeleton height={84} key={item} />
                </div>
              ))}
            {nextWeek &&
              allActivities.length !==
                count?.dueNextWeekTaskCount + count?.dueNextWeekEventCount && (
                <button
                  className='Card showMoreButton d-flex justify-content-center'
                  onClick={() =>
                    sortedActivities('dueNextWeek', tasksOffset, eventsOffset)
                  }
                >
                  Show more
                </button>
              )}
          </div>
          <div
            className='DueDiv d-flex justify-content-between'
            onClick={() => {
              showDew(false);
              setShowToday(false);
              setThisWeek(false);
              setNextWeek(false);
              showOverdew(!overdew);
              getActivities('upcomingWeek');
            }}
          >
            <div className='d-flex'>
              <div className='countGreen d-flex justify-content-center align-items-center '>
                {count?.upcomingWeekTaskCount || count?.upcomingWeekEventCount
                  ? count?.upcomingWeekTaskCount + count?.upcomingWeekEventCount
                  : 0}
              </div>
              <span className='DueName'>Upcoming</span>
            </div>
            <img
              src={vector}
              alt=''
              className={`vector ${overdew && 'invert'}`}
            />
          </div>
          {overdew &&
            allActivities.length > 0 &&
            allActivities.map((item: any, index: number) =>
              item?.type === 'TASK' ? (
                <TaskCard
                  key={index}
                  Data={item?.data}
                  setUUID={setUUID}
                  setTaskDrawer={setTaskDrawer}
                  setShowSuccess={setShowSuccess}
                  setMarkAsDone={setMarkAsDone}
                  setDelete={setDelete}
                  setAction={setAction}
                />
              ) : item?.type === 'EVENT' ? (
                <EventCard
                  key={index}
                  Data={item?.data}
                  setUUID={setUUID}
                  setEventDrawer={setEventDrawer}
                  setShowSuccess={setShowSuccess}
                  setEventDelete={setDelete}
                  setAction={setAction}
                />
              ) : (
                ''
              )
            )}
          {overdew &&
            count?.upcomingWeekTaskCount + count?.upcomingWeekEventCount > 0 &&
            loadingStatus === 'loading' &&
            [1, 2, 3].map((item) => (
              <div key={item}>
                {' '}
                <Skeleton height={84} key={item} />
              </div>
            ))}
          {overdew &&
            allActivities.length !==
              count?.upcomingWeekTaskCount + count?.upcomingWeekEventCount && (
              <button
                className='Card showMoreButton d-flex justify-content-center'
                onClick={() =>
                  sortedActivities('upcomingWeek', tasksOffset, eventsOffset)
                }
              >
                Show more
              </button>
            )}
        </div>
        {showAddTaskEvent !== 'closed' && (
          <AddTaskEventModal
            show={showAddTaskEvent}
            closeModal={setShowAddTaskEvent}
            activityName={event ? 'Events' : 'Tasks'}
            setAction={setAction}
          />
        )}
        {taskDrawer !== 'closed' && (
          <DetailsTaskModal
            show={taskDrawer}
            closeModal={setTaskDrawer}
            uuid={UUID}
            setAction={setAction}
            setCloseAllTabs={setCloseAllTabs}
          />
        )}
        {eventDrawer !== 'closed' && (
          <EventDetailsModal
            show={eventDrawer}
            closeModal={setEventDrawer}
            uuid={UUID}
            setAction={setAction}
            setCloseAllTabs={setCloseAllTabs}
          />
        )}
        <FilterContainer>
          {showFilter !== 'closed' && (
            <EventFilter
              show={showFilter}
              closeModal={setShowFilter}
              filterFunction={filterEvent}
              eventType={eventType}
              setEventType={setEventType}
              taskType={taskType}
              setTaskType={setTaskType}
              taskStatusValue={taskStatusValue}
              setTaskStatusValue={setTaskStatusValue}
              setTaskPriority={setTaskPriority}
              taskPriority={taskPriority}
              assignee={assignee}
              setAssignee={setAssignee}
              type='all'
            />
          )}
        </FilterContainer>
      </div>
    </AllActivityContentDiv>
  );
};

export default AllTaskEvents;
