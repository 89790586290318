import { CardDiv } from '../../../styles/CardDiv';
import Skeleton from 'react-loading-skeleton';

const LoadingSkeleton = ({ columns }: { columns: number }) => {
  return (
    <>
      {[1, 2, 3, 4].map((index) => (
        <CardDiv key={index}>
          {columns === 6 ? (
            <>
              {[1, 2, 3, 4].map((item) => (
                <td key={item}>
                  <Skeleton height={24} />
                </td>
              ))}
              <td className='d-none d-xl-table-cell'>
                <Skeleton height={24} />
              </td>
            </>
          ) : (
            <>
              {[1, 2, 3, 4].map((item) => (
                <td key={item}>
                  <Skeleton height={24} />
                </td>
              ))}
              {[1, 2].map((item) => (
                <td key={item} className='d-none d-xl-table-cell'>
                  <Skeleton height={24} />
                </td>
              ))}
            </>
          )}
        </CardDiv>
      ))}
    </>
  );
};

export default LoadingSkeleton;
