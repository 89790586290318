import { useSelector } from 'react-redux';
import { useRef, useState } from 'react';
import Skeleton from 'react-loading-skeleton';
import { ProgressBar } from 'react-bootstrap';
import {
  GoalSliceState,
  selectUserGoalCount,
} from '../../../../store/goals/goalSlice';
import Table from '../../../../components/Table';
import EmptyResults from '../../../MyBook/components/Empty';
import UserCard from './UserCard';
import UserTable from './UserTable';
import SetGoalModal from '../../../MyTeam/components/SetGoalDrawer';
import { useOutsideClick } from '../../../../hooks/useOutsideClick';
import { LeaderBoardMobile, MobileCard } from '../../../Home/style';
import vector from '../../../../assets/logo/dropdown.svg';
import sort from '../../../../assets/logo/sort.svg';
import sortup from '../../../../assets/logo/sortup.svg';
import sortdown from '../../../../assets/logo/sortdown.svg';
import dollar from '../../../../assets/logo/dollar.svg';

const UserGoal = ({
  leaders,
  setSortBy,
  setOrderBy,
  sortBy,
  orderBy,
  limit,
  setLimit,
  page,
  setPage,
}: {
  leaders: any;
  setSortBy: Function;
  setOrderBy: Function;
  sortBy: string;
  orderBy: string;
  limit: number;
  setLimit: Function;
  page: number;
  setPage: Function;
}) => {
  const loadingStatus = useSelector(
    (state: { goals: GoalSliceState }) => state.goals.status
  );

  const userCount = useSelector(selectUserGoalCount);

  const handleSort = (sort: string) => {
    if (sort === sortBy) {
      if (orderBy === 'asc') {
        setOrderBy('desc');
      } else {
        setOrderBy('asc');
      }
    } else {
      setSortBy(sort);
    }
  };

  const [showAllLeader, setShowAllLeader] = useState(false);
  const [showGoalModal, setShowGoalModal] = useState('closed');
  const [UUID, setUUID] = useState('');

  return (
    <>
      <div className='leader-scroll d-none d-sm-block user-goal-contain'>
        {loadingStatus === 'loading' || userCount === 0 ? (
          <UserTable
            leaders={leaders}
            setSortBy={setSortBy}
            sortBy={sortBy}
            setOrderBy={setOrderBy}
            orderBy={orderBy}
          />
        ) : leaders && userCount > 0 ? (
          <Table
            limit={limit}
            setLimit={setLimit}
            page={page}
            setPage={setPage}
            count={userCount}
            columns={[
              {
                Header: 'Rank',
                accessor: 'rank',
                width: 50,
                minWidth: 50,
                Cell: ({ row }: any) => (
                  <div className='d-flex align-items-center  h-100'>
                    {row.original.rank || 0}
                    {'.'}
                  </div>
                ),
              },
              {
                accessor: 'name',
                resizable: true,
                sortable: true,
                minWidth: 223,
                Header: () => (
                  <div
                    onClick={() => handleSort('name')}
                    style={{ cursor: 'pointer' }}
                    className='d-flex justify-content-between w-100'
                  >
                    <span>User name</span>
                    <img
                      className='sort'
                      src={
                        sortBy !== 'name'
                          ? sort
                          : orderBy === 'asc'
                          ? sortdown
                          : sortup
                      }
                      alt='sort'
                    />
                  </div>
                ),
                Cell: ({ row }: { row: any }) => (
                  <div className='row-name d-flex align-items-center h-100 text-overflow-container'>
                    <b>{row.original.name ? row.original.name : '--'}</b>
                    <span className='tooltiptext'>
                      <span className='tooltipInnertext'>
                        {row.original.name ? row.original.name : '--'}
                      </span>
                    </span>
                  </div>
                ),
              },
              {
                accessor: 'premiumGoal',
                Header: () => (
                  <div
                    onClick={() => handleSort('premium')}
                    style={{ cursor: 'pointer' }}
                    className='d-flex justify-content-between w-100'
                  >
                    <span>Premium goal</span>
                    <img
                      className='sort'
                      src={
                        sortBy !== 'premium'
                          ? sort
                          : orderBy === 'asc'
                          ? sortdown
                          : sortup
                      }
                      alt='sort'
                    />
                  </div>
                ),
                sortable: true,
                resizable: true,
                Cell: ({ row }: { row: any }) => (
                  <div className='d-flex justify-content-center flex-column h-100'>
                    <ProgressBar
                      className='leader-progress-bar'
                      now={
                        (Math.floor(row.original.policyPremium) /
                          Math.floor(row.original.goalPremium)) *
                        100
                      }
                      label={`${
                        (Math.floor(row.original.policyPremium) /
                          Math.floor(row.original.goalPremium)) *
                        100
                      }%`}
                      visuallyHidden
                    />
                    <label>
                      $
                      {row.original.policyPremium
                        ? row.original.policyPremium
                        : 0}
                      &nbsp;out of ${row.original.goalPremium}
                    </label>
                  </div>
                ),
              },
              {
                accessor: 'policyGoal',
                Header: () => (
                  <div
                    onClick={() => handleSort('policy')}
                    style={{ cursor: 'pointer' }}
                    className='d-flex justify-content-between w-100'
                  >
                    <span>Policy goal</span>
                    <img
                      className='sort'
                      src={
                        sortBy !== 'policy'
                          ? sort
                          : orderBy === 'asc'
                          ? sortdown
                          : sortup
                      }
                      alt='sort'
                    />
                  </div>
                ),
                resizable: true,
                Cell: ({ row }: { row: any }) => (
                  <div className='d-flex justify-content-center flex-column h-100'>
                    <ProgressBar
                      className='leader-progress-bar'
                      now={
                        (Math.floor(row.original.policyCount) /
                          Math.floor(row.original.goalPolicy)) *
                        100
                      }
                      label={`${
                        (Math.floor(row.original.policyCount) /
                          Math.floor(row.original.goalPolicy)) *
                        100
                      }%`}
                      visuallyHidden
                    />
                    <label>
                      {row.original.policyCount ? row.original.policyCount : 0}
                      &nbsp;out of {row.original.goalPolicy}
                    </label>
                  </div>
                ),
              },
              {
                accessor: 'actions',
                Header: 'Actions',
                sticky: 'right',
                resizable: false,
                width: window.innerWidth > 1900 ? 90 : 90,
                Cell: ({ row }: { row: any }) => (
                  <div className='d-flex align-items-center justify-content-between h-100'>
                    <ActionsDropdown
                      agentUUID={row.original.agentUUID}
                      setUUID={setUUID}
                      setShowGoalModal={setShowGoalModal}
                    />
                  </div>
                ),
              },
            ]}
            data={leaders}
          />
        ) : (
          ''
        )}
      </div>
      {showGoalModal !== 'closed' && (
        <SetGoalModal
          show={showGoalModal}
          closeModal={setShowGoalModal}
          agentUUID={UUID}
        />
      )}
      <LeaderBoardMobile className='d-block d-sm-none'>
        {loadingStatus === 'loading' ? (
          <MobileCard>
            {[1, 2, 3].map((item) => (
              <Skeleton height={80} />
            ))}
          </MobileCard>
        ) : loadingStatus === 'succeeded' && leaders?.length > 0 ? (
          leaders?.map((el: any, index: number) =>
            showAllLeader || index < 3 ? (
              <UserCard leader={el} key={el.uuid} index={index} />
            ) : null
          )
        ) : (
          <EmptyResults name='leader' />
        )}
        {leaders?.length > 3 ? (
          <span
            className='show-all'
            onClick={() => setShowAllLeader(!showAllLeader)}
          >
            {showAllLeader ? 'Show less' : 'Show all'}
          </span>
        ) : null}
      </LeaderBoardMobile>
    </>
  );
};

export default UserGoal;

export const ActionsDropdown = ({
  agentUUID,
  setShowEditModal,
  setUUID,
  setShowGoalModal,
}: {
  agentUUID: string;
  setShowEditModal?: Function;
  setUUID: Function;
  setShowGoalModal: Function;
}) => {
  const [showActionsDropdown, setShowActionsDropdown] = useState(false);
  const dropRef = useRef<HTMLButtonElement>(null);
  useOutsideClick(dropRef, setShowActionsDropdown);

  return (
    <button
      className='vector d-flex align-items-center justify-content-center position-relative'
      onClick={() => {
        setShowActionsDropdown(!showActionsDropdown);
        if (dropRef.current) {
          dropRef.current.focus();
        }
      }}
      onBlur={() => setShowActionsDropdown(false)}
      ref={dropRef}
    >
      <img src={vector} alt='vector' />
      {showActionsDropdown && (
        <div className='dropdown-div'>
          {' '}
          <div
            className='edit-div'
            onClick={() => {
              setShowGoalModal('open');
              setUUID(agentUUID);
              setShowActionsDropdown(false);
            }}
          >
            Set Goals <img src={dollar} alt='dollar' />
          </div>
        </div>
      )}
    </button>
  );
};
