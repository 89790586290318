import { useMemo, useState, useRef } from 'react';
import { useTable, useResizeColumns, useFlexLayout } from 'react-table';
import { useSticky } from 'react-table-sticky';
import { StyledTable } from './styles';
import left from '../../assets/logo/leftArrow.svg';
import { useOutsideClick } from '../../hooks/useOutsideClick';

function Table({
  columns,
  data,
  limit,
  setLimit,
  page,
  setPage,
  count,
  className,
}: {
  columns: any;
  data: any;
  limit: number;
  setLimit: Function;
  page: number;
  setPage: Function;
  count: number;
  className?: string;
}) {
  const [dropdownSelected, setDropDownSelected] = useState(false);
  const dropRef = useRef(null);
  useOutsideClick(dropRef, setDropDownSelected);
  const defaultColumn = useMemo(
    () => ({
      minWidth: 80,
      width: 150,
      maxWidth: 400,
    }),
    []
  );

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable(
      {
        columns,
        data,
        defaultColumn,
      },
      useFlexLayout,
      useResizeColumns,
      useSticky
    );

  const headerProps = (props: any, { column }: { column: any }) =>
    getStyles(props, column.align);

  const getStyles = (props: any, align = 'left') => [
    props,
    {
      style: {
        justifyContent: align === 'right' ? 'flex-end' : 'flex-start',
        alignItems: 'flex-start',
        display: 'flex',
      },
    },
  ];

  return (
    <StyledTable>
      <div
        {...getTableProps()}
        className={`table sticky ${className}`}
        style={{ minWidth: 576 }}
      >
        <div className='thead header'>
          {headerGroups.map((headerGroup) => (
            <div {...headerGroup.getHeaderGroupProps()} className='tr'>
              {headerGroup.headers.map(
                (column: any) =>
                  !column.hide && (
                    <div
                      {...column.getHeaderProps(headerProps)}
                      className={`th ${column.headerCellClass}  `}
                    >
                      {column.render('Header')}
                      {column.canResize && column.Header !== 'Acions' && (
                        <div
                          {...column.getResizerProps()}
                          className={`resizer ${
                            column.isResizing ? 'isResizing' : ''
                          } ${
                            column.Header === 'Rank' &&
                            'BorderedFirstColumnHead'
                          }`}
                        />
                      )}
                    </div>
                  )
              )}
            </div>
          ))}
        </div>

        <div {...getTableBodyProps()} className='body tbody'>
          {rows.map((row, i) => {
            prepareRow(row);
            return (
              <div {...row.getRowProps()} className='tr'>
                {row.cells.map((cell: any) => {
                  return (
                    !cell.column.hide && (
                      <div
                        {...cell.getCellProps()}
                        className={
                          cell.column.Header === 'Actions'
                            ? 'td actions'
                            : cell.column.Header === 'Rank'
                            ? 'BorderedFirstColumn'
                            : `td ${cell.column.cellClass}`
                        }
                      >
                        {cell.render('Cell')}
                      </div>
                    )
                  );
                })}
              </div>
            );
          })}
        </div>
      </div>
      <div className='pagination d-flex justify-content-between pb-3'>
        <div className='d-flex align-items-center'>
          Show
          <div
            className='form-select'
            onClick={() => setDropDownSelected(!dropdownSelected)}
            ref={dropRef}
          >
            <span>{limit} Rows</span>
            {dropdownSelected && (
              <div className='position-absolute dropDownContainer'>
                {[10, 25, 50, 100].map((page) => (
                  <div className='spacingDiv'>
                    <ul
                      className='options d-flex'
                      key={page}
                      onClick={() => {
                        setPage(0);
                        setLimit(Number(page));
                      }}
                    >
                      {page} rows
                    </ul>
                  </div>
                ))}
              </div>
            )}
          </div>
        </div>
        <div className='d-flex'>
          <button onClick={() => setPage(page - 1)} disabled={page === 0}>
            <img src={left} alt='' />
          </button>{' '}
          {Array.apply(null, Array(Math.ceil(count / limit))).map(
            (item, index) =>
              Array.apply(null, Array(Math.ceil(count / limit))).length <= 4 ||
              (index < page + 2 && index > page - 2) ||
              index === 0 ||
              index + 1 ===
                Array.apply(null, Array(Math.ceil(count / limit))).length ? (
                <button
                  type='button'
                  className={index === page ? 'active' : ''}
                  onClick={() => setPage(index)}
                  key={index}
                >
                  {index + 1}
                </button>
              ) : (index === page + 2 || index === page - 2) &&
                index + 1 !=
                  Array.apply(null, Array(Math.ceil(count / limit))).length ? (
                <button type='button' key={index}>
                  ...
                </button>
              ) : null
          )}
          <button
            onClick={() => setPage(page + 1)}
            disabled={page + 1 === Math.ceil(count / limit)}
          >
            <img src={left} alt='' style={{ transform: 'rotate(180deg)' }} />
          </button>{' '}
        </div>
      </div>
    </StyledTable>
  );
}

export default Table;
