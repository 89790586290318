import { PickerDiv } from './styles';
import { Controller } from 'react-hook-form';
import calender from '../../assets/logo/calendar.svg';
import 'react-datepicker/dist/react-datepicker.css';
import DatePicker from 'react-datepicker';
import { YearMonthPicker } from '../DateField';

export interface IProps {
  name: string;
  placeholder: string;
  className?: string;
  control?: any;
  isTime?: boolean;
  maxDate?: any;
  minDate?: any;
  dateOfBirth?: boolean;
  disabled?: boolean;
  required?: boolean;
  readOnly?: boolean;
}

const EditDate = ({
  name,
  placeholder,
  isTime,
  maxDate,
  minDate,
  dateOfBirth,
  disabled,
  required,
  readOnly,
  ...props
}: IProps) => {
  const { control } = props;
  return (
    <div>
      <PickerDiv>
        <div className={`d-flex flex-column w-100`}>
          <Controller
            control={control}
            name={name}
            render={({ field }) => (
              <>
                {isTime ? (
                  <DatePicker
                    className='date-input'
                    placeholderText={placeholder}
                    onChange={(date) => field.onChange(date)}
                    selected={field.value}
                    onChangeRaw={(e) => e.preventDefault()}
                    showTimeSelect
                    showTimeSelectOnly
                    timeIntervals={15}
                    timeCaption='Time'
                    dateFormat='h:mm aa'
                    {...props}
                    disabled={disabled}
                  />
                ) : (
                  <DatePicker
                    className='date-input'
                    onChangeRaw={(e) => e.preventDefault()}
                    onChange={(date) => {
                      if (date) {
                        field.onChange(
                          new Date(
                            date?.getTime() - date?.getTimezoneOffset() * 60000
                          )
                        );
                      } else {
                        field.onChange(date);
                      }
                    }}
                    selected={field.value}
                    dateFormat={'MM/dd/yyyy'}
                    readOnly={readOnly}
                    openToDate={
                      dateOfBirth ? new Date('1990/01/1') : new Date()
                    }
                    maxDate={maxDate}
                    minDate={minDate}
                    disabled={disabled}
                    renderCustomHeader={({
                      date,
                      prevYearButtonDisabled,
                      nextYearButtonDisabled,
                      decreaseYear,
                      increaseYear,
                      decreaseMonth,
                      increaseMonth,
                      prevMonthButtonDisabled,
                      nextMonthButtonDisabled,
                    }) => (
                      <YearMonthPicker
                        date={date}
                        prevMonthButtonDisabled={prevMonthButtonDisabled}
                        prevYearButtonDisabled={prevYearButtonDisabled}
                        nextMonthButtonDisabled={nextMonthButtonDisabled}
                        nextYearButtonDisabled={nextYearButtonDisabled}
                        decreaseMonth={decreaseMonth}
                        decreaseYear={decreaseYear}
                        increaseMonth={increaseMonth}
                        increaseYear={increaseYear}
                      />
                    )}
                  />
                )}
              </>
            )}
          />
          <label htmlFor={name} className='input-placeholder'>
            {placeholder}
            {required ? <span className='requiredStar'>*</span> : null}
          </label>
          <div className='CallenderImg'>
            <img src={calender} alt='Calender' />
          </div>
        </div>
      </PickerDiv>
    </div>
  );
};

export default EditDate;
