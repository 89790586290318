import React, { useRef, useState } from 'react';
import Moment from 'react-moment';
import EditQuoteModal from '../../../components/EditQuoteModal';
import ChangeQuoteStageModal from './ChangeQuoteStageModal';
import QuoteModal from './QuoteModal';
import { useOutsideClick } from '../../../hooks/useOutsideClick';
import { QuoteCardDiv } from '../components/styles';
import arrow from '../../../assets/logo/right-arrow.svg';
import bin from '../../../assets/logo/bin.svg';
import vector from '../../../assets/logo/dropdown.svg';

const OpportunityQuoteCardDiv = ({
  details,
  setShowDeleteModal,
  onDelete,
}: {
  details: any;
  setShowDeleteModal: Function;
  onDelete?: Function;
}) => {
  const [showEditQuote, setShowEditQuote] = useState('closed');
  const [showQuoteModal, setShowQuoteModal] = useState('closed');
  const uuid = details.uuid;
  const deleteFun = (uuid: string, name: string) => {
    if (onDelete) {
      onDelete(uuid, name);
    }
  };

  const [currentStage, setCurrentStage] = useState('');

  const [showChangeStageModal, setShowChangeStageModal] = useState('closed');
  const [UUID, setUUID] = useState('');

  const FinalCustom = details?.CustomCarrier
    ? details?.CustomCarrier?.name?.charAt(0).toUpperCase()
    : '';
  const carrier = details?.Carrier?.name?.split(' ', 2);
  const finalCarrier = carrier
    ? carrier[0]?.charAt(0) + carrier[1]?.charAt(0)
    : '--';

  return (
    <QuoteCardDiv>
      {' '}
      <div className='opportunity'>
        <div className='lowContent'>
          <div className='d-flex'>
            {details?.Carrier || details?.CustomCarrier ? (
              <div className='imageCard d-flex align-items-center justify-content-center'>
                {details?.CustomCarrier ? FinalCustom : finalCarrier}
              </div>
            ) : null}
            <div className='quotesName quoteCarrierText'>
              <span className='carrier-head '>
                {details?.Carrier?.name
                  ? details?.Carrier?.name
                  : details?.CustomCarrier?.name}
              </span>
            </div>
            <div
              className={`quote-stage-pill ${
                details.QuoteStage?.name === 'NEW'
                  ? 'yellow-bg'
                  : details.QuoteStage?.name === 'CANCELED' ||
                    details.QuoteStage?.name === 'EXPIRED' ||
                    details.QuoteStage?.name === 'DECLINED'
                  ? 'danger-bg'
                  : details.QuoteStage?.name === 'ACCEPTED'
                  ? 'green-bg'
                  : details.QuoteStage?.name === 'VIEWED'
                  ? 'blue-bg'
                  : ''
              }`}
            >
              {details.QuoteStage?.name.toLowerCase()}
            </div>
          </div>
          <div className='horz-row'></div>
          <div className='oppDetaisl d-flex justify-content-between'>
            <div className='oppDetaislfieldsQuotes  d-flex justify-content-between '>
              <div className='d-flex'>
                <div className='quotesName'>
                  <span className='valHead'>Quote type</span>
                  <span className='valueField'>
                    {' '}
                    {details?.QuoteType?.name
                      .toLowerCase()
                      .split('-')
                      .map(
                        (word: string) =>
                          word[0].toUpperCase() + word.substring(1)
                      )
                      .join(' ')}{' '}
                  </span>
                </div>
              </div>
              <div className='d-block'>
                <span className='valHead d-none d-sm-block'>
                  Effective date
                </span>
                <span className='valueField'>
                  {' '}
                  <Moment format='MM/DD/YYYY'>{details?.effectiveDate}</Moment>
                </span>
              </div>
              <div>
                <span className='valHead'>Est. premium</span>
                <span className='valueField'>
                  ${details.premium.toLocaleString('en-US')}
                </span>
              </div>
            </div>
            <div className=' d-flex align-items-center justify-content-center '>
              {details.QuoteStage.name !== 'CANCELED' &&
              details.QuoteStage.name !== 'EXPIRED' &&
              details.QuoteStage.name !== 'DECLINED' ? (
                <button
                  className='tertiaryButton editquotingbtn '
                  onClick={() => {
                    setShowEditQuote('open');
                  }}
                >
                  Edit Quote
                </button>
              ) : (
                <button
                  className='tertiaryButton editquotingbtn '
                  onClick={() => {
                    setShowQuoteModal('open');
                  }}
                >
                  View Quote
                </button>
              )}
              <ActionsDropdown
                uuid={details.uuid}
                setShowDeleteModal={setShowDeleteModal}
                name={details?.Carrier?.name}
                deleteFun={deleteFun}
                setShowChangeStageModal={setShowChangeStageModal}
                setUUID={setUUID}
                currentStage={details.QuoteStage?.uuid}
                setCurrentStage={setCurrentStage}
                details={details}
              />
            </div>
          </div>
        </div>
      </div>
      {showEditQuote !== 'closed' && (
        <EditQuoteModal
          show={showEditQuote}
          closeModal={setShowEditQuote}
          type='Opportunity'
          UUID={uuid}
        />
      )}
      {showChangeStageModal !== 'closed' && (
        <ChangeQuoteStageModal
          showModal={showEditQuote}
          closeModal={setShowChangeStageModal}
          uuid={UUID}
          currentStage={currentStage}
        />
      )}
      {showQuoteModal !== 'closed' && (
        <QuoteModal
          uuid={uuid}
          showModal={showQuoteModal}
          setShowModal={setShowQuoteModal}
        />
      )}
    </QuoteCardDiv>
  );
};

export default OpportunityQuoteCardDiv;

const ActionsDropdown = ({
  uuid,
  setShowDeleteModal,
  name,
  deleteFun,
  setShowChangeStageModal,
  setUUID,
  currentStage,
  setCurrentStage,
  details,
}: {
  uuid: string;
  setShowDeleteModal?: Function;
  name: string;
  deleteFun: Function;
  setShowChangeStageModal: Function;
  setUUID: Function;
  currentStage: string;
  setCurrentStage: Function;
  details: any;
}) => {
  const [showActionsDropdown, setShowActionsDropdown] = useState(false);

  const dropRef = useRef<HTMLButtonElement>(null);
  useOutsideClick(dropRef, setShowActionsDropdown);

  return (
    <button
      className='vector d-flex align-items-center justify-content-center position-relative'
      onClick={() => {
        setShowActionsDropdown(!showActionsDropdown);
        if (dropRef.current) {
          dropRef.current.focus();
        }
      }}
      onBlur={() => setShowActionsDropdown(false)}
      ref={dropRef}
    >
      <img src={vector} alt='vector' />
      {showActionsDropdown && (
        <div className='dropdown-div'>
          {setShowDeleteModal && (
            <>
              {details.QuoteStage.name !== 'CANCELED' &&
                details.QuoteStage.name !== 'EXPIRED' &&
                details.QuoteStage.name !== 'DECLINED' && (
                  <div
                    className='quotediv'
                    onClick={() => {
                      setShowChangeStageModal('open');
                      setShowActionsDropdown(false);
                      setUUID(uuid);
                      setCurrentStage(currentStage);
                    }}
                  >
                    Change status <img src={arrow} alt='bin' />
                  </div>
                )}

              <div
                className='delete-div'
                onClick={() => {
                  setShowDeleteModal('open');
                  setShowActionsDropdown(false);
                  deleteFun(uuid, name);
                }}
              >
                Delete <img src={bin} alt='bin' />
              </div>
            </>
          )}
        </div>
      )}
    </button>
  );
};
