import axios from './axios';
import { IAddUser } from '../interfaces/User';
import { IAddAgency } from '../interfaces/Agency';

const addUser = (data: IAddUser, successCB: Function, errorCB: Function) => {
  return axios.post('/user', data).then(
    (res) => {
      successCB();
      return res;
    },
    (error) => {
      errorCB(error.response);
    }
  );
};

const addAgency = (
  data: IAddAgency,
  successCB: Function,
  errorCB: Function
) => {
  return axios.post('/agency', data).then(
    (res) => {
      successCB();
      return res;
    },
    (error) => {
      errorCB(error.response);
      throw new Error(error.response);
      // return error.response;
    }
  );
};

const fetchAgencies = (
  sortBy: string,
  orderBy: string,
  searchBy: string,
  limit: number,
  offset: number,
  userGroupUUID?: string
) => {
  return axios
    .get('/agency', {
      params: { sortBy, searchBy, orderBy, limit, offset, userGroupUUID },
    })
    .then(
      (res) => {
        return res;
      },
      (error) => error.response
    );
};

const fetchAgencyAgents = (
  agencyUUID: string | undefined,
  sortBy: string,
  orderBy: string,
  searchBy: string,
  limit: number,
  offset: number,
  userRoleUUID?: string,
  agentStatusUUID?: string
) => {
  return axios
    .get(`/agency/${agencyUUID}`, {
      params: {
        sortBy,
        searchBy,
        orderBy,
        limit,
        offset,
        userRoleUUID,
        agentStatusUUID,
      },
    })
    .then(
      (res) => {
        return res;
      },
      (error) => error.response
    );
};

const fetchCount = (successCB: Function) => {
  return axios.get('/agency/count').then(
    (res) => {
      successCB(res.data.agencyCount, res.data.agentCount);
      return res;
    },
    (error) => error.response
  );
};

const getUserGroups = (successCB: Function) => {
  return axios.get('/agencyGroup').then((res) => {
    successCB(res.data);
  });
};

const updateAgencyDetails = (
  uuid: string,
  data: any,
  successCB: Function,
  errorCB: Function
) => {
  return axios.patch(`/agency/${uuid}/updateAgencyInfo`, data).then(
    (res) => {
      successCB(res.data);
      return res;
    },
    (error) => {
      errorCB(error.response.data);
      throw new Error(error.response?.data?.error);
    }
  );
};

const fetchAgencyTypes = (successCB: Function) => {
  return axios.get(`/agency/agencyType`).then((res) => {
    successCB(res.data);
    return res;
  });
};

export {
  addUser,
  addAgency,
  fetchAgencies,
  fetchAgencyAgents,
  fetchCount,
  getUserGroups,
  updateAgencyDetails,
  fetchAgencyTypes,
};
