import { TaskCardStyle } from '../elements/styles';
// import phone from '../../../assets/logo/phone.svg';
// import timeBell from '../../../assets/logo/timeBell.svg';
import vector from '../../../assets/logo/dropdown.svg';
import space from '../../../assets/logo/space.svg';
import moment from 'moment';
import { useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import edit from '../../../assets/logo/edit.svg';
import { AppDispatch } from '../../../store';
import bin from '../../../assets/logo/bin.svg';
import { useOutsideClick } from '../../../hooks/useOutsideClick';
import {
  deleteAnEvent,
  getAllActivtiesCount,
} from '../../../store/tasks/tasksSlice';
const TaskCard = ({
  Data,
  setUUID,
  setEventDrawer,
  setEventDelete,
  setShowSuccess,
  setAction,
}: {
  Data: any;
  setUUID: Function;
  setEventDrawer: Function;
  setShowSuccess: Function;
  setEventDelete: Function;
  setAction: Function;
}) => {
  const dispatch = useDispatch<AppDispatch>();
  const [showActionsDropdown, setShowActionsDropdown] = useState(false);
  const [showActionsMobileDropdown, setShowActionsMobileDropdown] =
    useState(false);
  const dropRef = useRef(null);
  useOutsideClick(dropRef, setShowActionsDropdown);
  const dropMobileRef = useRef(null);
  useOutsideClick(dropMobileRef, setShowActionsMobileDropdown);

  const onDeleteEvent = (uuid: string) => {
    dispatch(
      deleteAnEvent({
        successCB: () => {
          setAction(true);
          dispatch(getAllActivtiesCount({}));
          setEventDelete(true);
          setShowSuccess(true);
          setTimeout(() => {
            setShowSuccess(false);
            setEventDelete(false);
          }, 2000);
        },
        uuid,
      })
    );
  };

  return (
    <TaskCardStyle>
      <div className='task-Card d-block  d-sm-flex justify-content-between'>
        <div className='eventIndicator '></div>
        <div className='d-flex EventName align-items-center  col-sm-4'>
          <div className='typeImageContainer d-flex align-items-center justify-content-center'>
            <img src={space} alt='' />
          </div>
          <div
            className='textContainer '
            onClick={() => {
              setEventDrawer('open');
              setUUID(Data?.uuid);
            }}
          >
            <div className='d-flex align-items-center '>
              <h2 className='title'>
                Event&nbsp;- &nbsp;{Data?.EventType?.name}
              </h2>
            </div>
            <span className='d-none d-sm-flex'>
              {' '}
              <b className='spaceINnane'>{Data?.name.trim()}</b>{' '}
              <div
                style={{ marginLeft: '20px' }}
                className='text-overflow-container'
              >
                <span className='descriptionSpan'>{Data?.description}</span>
                <span className='tooltiptext'>
                  <span className='tooltipInnertext'>
                    {Data?.description ? Data?.description : '--'}
                  </span>
                </span>
              </div>
            </span>
          </div>
        </div>
        <span className='d-sm-none  '>{Data?.name}</span>
        <div className='valueContainer d-none d-xl-block'>
          <h2 className='titleSub'>Linked to</h2>
          <span className='valueText'>
            {Data?.ContactId
              ? Data?.Contact?.firstName + ' ' + Data?.Contact?.lastName
              : Data?.AccountId
              ? Data?.Account?.name
              : Data?.OpportunityId
              ? Data?.Opportunity?.name
              : Data?.PolicyId
              ? Data.Policy?.Accounts?.name
              : Data.Lead?.data?.contact?.name}
          </span>
        </div>
        <div className=' d-none d-sm-block valueContainer'>
          <h2 className='titleSub'>Period</h2>
          <span className='valueText'>
            {moment(Data?.scheduledDate).format('h:mm A')} -{' '}
            {moment(Data?.dueDate).format('h:mm A')}
          </span>
        </div>
        <div className='d-flex d-sm-none valueContainerMobile'>
          <h2 className='titleSubMobile'>Period</h2>
          <span className='valueTextMobile'>
            {moment(Data?.scheduledDate).format('h:mm A')}-{' '}
            {moment(Data?.dueDate).format('h:mm A')}
          </span>
        </div>
        <div className='d-none d-sm-flex valueContainer'>
          <span className=' d-flex '>
            {Data?.EmailEvents?.length > 0 ? (
              <>
                {Data?.EmailEvents?.map((e: any, index: number) => {
                  return (
                    Data?.EmailEvents &&
                    index < 4 && (
                      <div className='profile-pic d-flex align-items-center justify-content-center'>
                        {e.Contact?.firstName.charAt(0)}
                        {e.Contact?.lastName.charAt(0)}
                      </div>
                    )
                  );
                })}
                {Data?.EmailEvents.length > 4 && (
                  <span className='Guestprofile-pic GuestprofileNumber'>
                    + {Data?.EmailEvents.length - 4}
                  </span>
                )}
              </>
            ) : (
              ''
            )}
            {Data?.PhoneEvents?.length > 0 ? (
              <>
                {Data?.PhoneEvents?.map(
                  (e: any, index: number) =>
                    Data?.PhoneEvents &&
                    index < 4 && (
                      <div className='profile-pic d-flex align-items-center justify-content-center'>
                        {e.Contact?.firstName.charAt(0)}
                        {e.Contact?.lastName.charAt(0)}
                      </div>
                    )
                )}
                {Data?.PhoneEvents.length > 4 && (
                  <span className='Guestprofile-pic GuestprofileNumber'>
                    + {Data?.PhoneEvents.length - 4}
                  </span>
                )}
              </>
            ) : (
              ''
            )}
            {Data?.CalendarEvents?.length > 0 ? (
              <>
                {Data?.CalendarEvents?.map(
                  (e: any, index: number) =>
                    index < 4 && (
                      <div className='profile-pic d-flex align-items-center justify-content-center'>
                        {e.Contact?.firstName.charAt(0)}
                        {e.Contact?.lastName.charAt(0)}
                      </div>
                    )
                )}
                {Data?.CalendarEvents.length > 4 && (
                  <span className='Guestprofile-pic GuestprofileNumber'>
                    + {Data?.CalendarEvents.length - 4}
                  </span>
                )}
              </>
            ) : (
              ''
            )}
          </span>
        </div>
        <span className='d-none d-sm-flex  valueContainerIcons mb-4  align-items-center justify-content-end '>
          {/* <div className='PhoneContainer  d-flex align-items-center justify-content-center '>
            <img src={phone} alt='' />
          </div>
          <div className='CompleteContainer  d-flex align-items-center justify-content-center ms-3'>
            <img src={timeBell} alt='' />
          </div> */}
          <div
            className='CompleteContainer vectorColor  d-flex align-items-center justify-content-center ms-3'
            onClick={() => setShowActionsDropdown(!showActionsDropdown)}
            ref={dropRef}
          >
            <img src={vector} alt='' />
            {showActionsDropdown && (
              <div className='dropdown-div'>
                <div
                  className='edit-div'
                  onClick={() => {
                    setEventDrawer('open');
                    setUUID(Data?.uuid);
                  }}
                >
                  Edit <img src={edit} alt='bin' />
                </div>
                <div className='borderDiv'></div>
                <div
                  className='delete-div'
                  onClick={() => {
                    onDeleteEvent(Data.uuid);
                  }}
                >
                  Delete <img src={bin} alt='bin' />
                </div>
              </div>
            )}
          </div>
        </span>

        <div className='d-flex d-sm-none valueContainerM0bile mb-2 justify-content-between w-100'>
          <div className='d-sm-none valueContainer prifleContainer'>
            <span className=' d-flex profileText'>
              {Data?.EmailEvents?.length > 0 ? (
                <>
                  {Data?.EmailEvents?.map((e: any, index: number) => {
                    return (
                      Data?.EmailEvents &&
                      index < 2 && (
                        <div
                          key={index}
                          className='profile-pic d-flex align-items-center justify-content-center'
                        >
                          {e.Contact?.firstName.charAt(0)}
                          {e.Contact?.lastName.charAt(0)}
                        </div>
                      )
                    );
                  })}
                  {Data?.EmailEvents.length > 2 && (
                    <span className='Guestprofile-pic GuestprofileNumber'>
                      + {Data?.EmailEvents.length - 2}
                    </span>
                  )}
                </>
              ) : (
                ''
              )}
              {Data?.PhoneEvents?.length > 0 ? (
                <>
                  {Data?.PhoneEvents?.map(
                    (e: any, index: number) =>
                      Data?.PhoneEvents &&
                      index < 2 && (
                        <div
                          key={index}
                          className='profile-pic d-flex align-items-center justify-content-center'
                        >
                          {e.Contact?.firstName.charAt(0)}
                          {e.Contact?.lastName.charAt(0)}
                        </div>
                      )
                  )}
                  {Data?.PhoneEvents.length > 2 && (
                    <span className='Guestprofile-pic GuestprofileNumber'>
                      + {Data?.PhoneEvents.length - 2}
                    </span>
                  )}
                </>
              ) : (
                ''
              )}
              {Data?.CalendarEvents?.length > 0 ? (
                <>
                  {Data?.CalendarEvents?.map(
                    (e: any, index: number) =>
                      index < 2 && (
                        <div
                          key={index}
                          className='profile-pic d-flex align-items-center justify-content-center'
                        >
                          {e.Contact?.firstName.charAt(0)}
                          {e.Contact?.lastName.charAt(0)}
                        </div>
                      )
                  )}
                  {Data?.CalendarEvents.length > 2 && (
                    <span className='Guestprofile-pic GuestprofileNumber'>
                      + {Data?.CalendarEvents.length - 2}
                    </span>
                  )}
                </>
              ) : (
                ''
              )}
            </span>
          </div>
          <span className='valueTextMobile d-flex align-items-center '>
            {/* <div className='PhoneContainer  d-flex align-items-center justify-content-center '>
              <img src={phone} alt='' />
            </div>
            <div className='CompleteContainer  d-flex align-items-center justify-content-center ms-3 '>
              <img src={timeBell} alt='' />
            </div> */}
            <div
              className='CompleteContainer vectorColor  d-flex align-items-center justify-content-center ms-3'
              onClick={() =>
                setShowActionsMobileDropdown(!showActionsMobileDropdown)
              }
              ref={dropMobileRef}
            >
              <img src={vector} alt='' />
              {showActionsMobileDropdown && (
                <div className='dropdown-div'>
                  <div
                    className='edit-div'
                    onClick={() => {
                      setEventDrawer('open');
                      setUUID(Data?.uuid);
                    }}
                  >
                    Edit <img src={edit} alt='bin' />
                  </div>
                  <div className='borderDiv'></div>
                  <div
                    className='delete-div'
                    onClick={() => {
                      onDeleteEvent(Data.uuid);
                    }}
                  >
                    Delete <img src={bin} alt='bin' />
                  </div>
                </div>
              )}
            </div>
          </span>
        </div>
      </div>
    </TaskCardStyle>
  );
};

export default TaskCard;
