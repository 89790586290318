import styled from '@emotion/styled';

export const FieldDiv = styled.div`
  display: flex;
  height: 48px;
  position: relative;

  .filled.input-container {
    border: 1px solid #262758;
  }

  .input-container {
    position: relative;
    border: 1px solid #cad9f3;
    border-radius: 8px;
    background: #ffffff;
    :hover {
      border: 1px solid #cad9f3;
      box-shadow: 0px 1px 4px rgba(35, 42, 64, 0.2);
      border-radius: 8px;
    }
    &.squareField {
      border-radius: 0;
    }

    label {
      position: absolute;
      color: #262758;
      pointer-events: none;
      transform: translate(0, 10px);
      transform-origin: top left;
      transition: 200ms cubic-bezier(0, 0, 0.2, 1) 0ms;
      font-size: 16px;
      left: 16px;
      font-weight: 600;
      text-transform: capitalize;
      small {
        font-size: 12px;
        font-weight: 400;
      }
    }
    .prefix-style {
      display: none;
      position: absolute;
      color: #8990a3;
      left: 16px;
      pointer-events: none;
    }
    .monthStyle {
      display: none;
      position: absolute;
      right: 15px;
      pointer-events: none;
      font-weight: 600;
    }
  }
  .CustomField {
    padding-left: 27px !important;
  }
  .disabledStyle {
    color: #cad9f3 !important;
  }
  .small-field {
    border: none;
    .input-field {
      padding: 20px 25px 10px 10px;
      overflow: hidden;
      width: 120px;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    :hover {
      border: none;
    }
  }

  .input-style {
    .input-field {
      padding: 28px 16px 16px 28px;
    }
  }

  input[autocomplete='off']::-webkit-contacts-auto-fill-button,
  input[autocomplete='off']::-webkit-credentials-auto-fill-button {
    visibility: hidden;
    display: none !important;
    pointer-events: none;
    height: 0;
    width: 0;
    margin: 0;
  }

  .input-field {
    width: 100%;
    height: 100%;
    padding: 20px 25px 10px 16px;
    outline: none;
    border-radius: 8px;
    border: 0;
    background: #fff !important;
    font-weight: 600;
    text-transform: inherit;
    :disabled {
      color: #cad9f3;
      background: #f5f7fc !important;
      .input-container::disabled {
        background: #f5f7fc !important;

        label {
          color: #cad9f3 !important;
        }
      }
      .small-field {
        border: none;
      }
    }
  }
  .squareInput {
    border-radius: 0px;
  }
  .input-field.firstInput {
    border-radius: 8px 0px 0px 8px;
  }
  .input-field.lastInput {
    border-radius: 0px 8px 8px 0px;
  }
  :focus-within {
    .input-container {
      border: 1px solid #3c498a;

      label {
        font-size: 12px;
        transform: translate(0, 2px);
        font-weight: 400;
      }
      .prefix-style {
        display: block;
        bottom: 5px;
      }
      .monthStyle {
        display: block;
        top: 14px;
        right: 15px;
        font-weight: 600;
      }
    }
    .small-field {
      border: none;
    }

    .error-field {
      border-radius: 8px;
      border: 1px solid #cc0000;
    }
  }

  .filled,
  .prefilled {
    label {
      font-size: 12px;
      transform: translate(0, 2px);
      font-weight: 400;
    }
    .prefix-style {
      display: block;
      bottom: 5px;
    }
    .monthStyle {
      display: block;
      top: 15px;
      margin-left: 25px;
    }
  }

  .prefilled {
    background: #f3fafe !important;
    border: 1px solid #ecf4f9;
    border-radius: 8px;

    .input-field {
      background: #f3fafe !important;
    }
  }

  .error-field.input-container {
    border-radius: 8px;
    border: 1px solid #cc0000;
  }
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  .squareField {
    border-radius: 0px;
    .input-field {
      border: none;
      border-radius: 0;
    }
    :hover {
      border: 1px solid #cad9f3;
      box-shadow: 0px 1px 4px rgba(35, 42, 64, 0.2);
      border-radius: 0px;
    }
  }
  .firstField {
    border-radius: 8px 0px 0px 8px;
    :hover {
      border: 1px solid #cad9f3;
      box-shadow: 0px 1px 4px rgba(35, 42, 64, 0.2);
      border-radius: 8px 0px 0px 8px;
    }
  }
  .lastField {
    border-radius: 0px 8px 8px 0px;
    :hover {
      border: 1px solid #cad9f3;
      box-shadow: 0px 1px 4px rgba(35, 42, 64, 0.2);
      border-radius: 0px 8px 8px 0px;
    }
  }
  .filled.input-container::disabled {
    border: 1px solid #d1d6e5 !important;
  }
  .input-container::disabled {
    label {
      color: #8990a3;
    }
  }
  .small-field::disabled {
    border: none;
  }

  @media screen and (min-width: 576px) {
    .filled,
    .prefilled {
      .prefix-style {
        bottom: 5px;
      }
    }
    :focus-within {
      .input-container {
        .prefix-style {
          bottom: 5px;
        }
      }
    }
  }
  @media screen and (min-width: 1200px) {
    .filled,
    .prefilled {
      .prefix-style {
        bottom: 5px;
      }
    }
    :focus-within {
      .input-container {
        .prefix-style {
          bottom: 5px;
        }
      }
    }
  }
  @media screen and (min-width: 1900px) {
    height: 64px;
    .small-field {
      height: 48px;
    }
    .input-container {
      label {
        transform: translate(0, 20px);
      }
    }

    .filled,
    .prefilled {
      label {
        transform: translate(0, 2px);
      }
      .prefix-style {
        bottom: 12px;
      }
    }
    :focus-within {
      .input-container {
        .prefix-style {
          bottom: 12px;
        }
      }
    }
  }

  @media screen and (min-width: 2800px) {
    height: 128px;

    .input-container {
      border-radius: 16px;
      label {
        transform: translate(0, 40px);
        font-size: 32px;
        left: 40px;
      }
    }

    :focus-within {
      .input-container {
        label {
          transform: translate(0, 5px);
          font-size: 24px;
        }
      }
    }

    .filled,
    .prefilled {
      label {
        transform: translate(0, 5px);
        font-size: 24px;
      }
    }

    .input-field {
      font-size: 32px;
      padding: 40px;
      border-radius: 16px;
    }
  }
`;
