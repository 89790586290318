import { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import Skeleton from 'react-loading-skeleton';
import { AppDispatch } from '../../../../store';
import {
  CarrierSliceState,
  createCarrier,
  getBatchCarriers,
  selectAllBatchCarriers,
} from '../../../../store/carriers/carriersSlice';
import Table from '../../../../components/Table';
import Modal from '../../../../components/EditDetailsModal';
import EmptyResults from '../../../MyBook/components/Empty';
import PaginationComponent from '../../../../components/PaginationComponent';
import { IProduct } from '../../../../interfaces/Product';
import { CarriersMobileCard } from '../styles';
import { TableDiv } from '../../../../styles/TableDiv';
import { CardDiv } from '../../../../styles/CardDiv';
import { StyledEditDetailsPage } from '../../../AccountDetails/EditAccountDetails/styles';
import cross from '../../../../assets/logo/cross.svg';
import tick from '../../../../assets/logo/right-arrow.svg';
import sort from '../../../../assets/logo/sort.svg';
import sortup from '../../../../assets/logo/sortup.svg';
import sortdown from '../../../../assets/logo/sortdown.svg';
import search from '../../../../assets/logo/search.svg';
import filter from '../../../../assets/logo/filter-mobile.svg';

const CarrierBatchDrawer = ({
  loadingStatus,
  addCarrier,
  onCloseModal,
  setShowFilter,
  debouncedSearchTerm,
  setSearchBy,
  sortBy,
  setSortBy,
  orderBy,
  setOrderBy,
  limit,
  setLimit,
  page,
  setPage,
  filterCount,
}: {
  loadingStatus: string;
  addCarrier: string;
  onCloseModal: Function;
  setShowFilter: Function;
  debouncedSearchTerm: string;
  setSearchBy: Function;
  sortBy: string;
  setSortBy: Function;
  orderBy: string;
  setOrderBy: Function;
  limit: number;
  setLimit: Function;
  page: number;
  setPage: Function;
  filterCount: number;
}) => {
  const dispatch = useDispatch<AppDispatch>();
  const onSubmit = () => {
    let data: {
      carrierUUIDs: string[];
      actionType: string;
      agencyUUID: string;
    } = {
      carrierUUIDs: selectedItems,
      actionType: 'ADD_BATCH',
      agencyUUID: uuid || '',
    };

    dispatch(
      createCarrier({
        data,
        successCB: () => {
          onCloseModal('ADD_BATCH');
          setPage(0);
        },
      })
    );
  };

  const handleSort = (sort: string) => {
    if (sort === sortBy) {
      if (orderBy === 'asc') {
        setOrderBy('desc');
      } else {
        setOrderBy('asc');
      }
    } else {
      setSortBy(sort);
    }
  };

  const { uuid } = useParams();

  const carrierCount = useSelector(
    (state: { carriers: CarrierSliceState }) => state.carriers?.batchCount
  );

  const carriers = useSelector(selectAllBatchCarriers);

  useEffect(() => {
    dispatch(
      getBatchCarriers({
        sortBy,
        searchBy: debouncedSearchTerm,
        orderBy,
        minimal: '',
        agencyUUID: uuid,
        successCB: () => {},
        limit,
        offset: page * limit,
        type: 'BATCH_CARRIER',
      })
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sortBy, orderBy, page, limit, debouncedSearchTerm]);

  const [selectedItems, setSelectedItems] = useState<string[]>([]);

  const checkAll = (data: React.ChangeEvent<HTMLInputElement>) => {
    if (data.target.checked) {
      setSelectedItems(carriers.map((el) => el.uuid));
    } else {
      setSelectedItems([]);
    }
  };

  const selectCarrier = (
    data: React.ChangeEvent<HTMLInputElement>,
    item: { uuid: string }
  ) => {
    if (data.target.checked) {
      setSelectedItems([...selectedItems, item.uuid]);
    } else {
      const selectedCarrierIndex = selectedItems.findIndex(
        (el) => el === item.uuid
      );
      setSelectedItems([
        ...selectedItems.slice(0, selectedCarrierIndex),
        ...selectedItems.slice(selectedCarrierIndex + 1),
      ]);
    }
  };

  return (
    <StyledEditDetailsPage>
      <Modal open={addCarrier}>
        <div className='entire-div batchCariier-container'>
          <div className='topdiv batch-topdiv'>
            <div className='form-title'>
              <h2 className='add-carrier-heading d-none d-sm-block'>
                Select carriers to add them in batch
              </h2>
              <h2 className='add-carrier-heading d-block d-sm-none'>
                Add carriers in batch
              </h2>
            </div>
            <button className='cross-btn close-btn'>
              <img
                className='cross'
                src={cross}
                alt='cross-icon'
                onClick={() => {
                  onCloseModal();
                }}
              />
            </button>
          </div>
          <div className='d-none d-sm-flex justify-content-between searchDivCarrier'>
            <div className='d-flex searchField '>
              <img className='mx-2 searchIcon' src={search} alt='' />
              <input
                className='inputSearchField '
                type='search'
                placeholder='Search carriers'
                onChange={(e) => {
                  setPage(0);
                  setSearchBy(e.target.value);
                }}
              />
            </div>
            <div
              className='d-flex searchField filterField'
              onClick={() => setShowFilter('open')}
            >
              Filter by:&nbsp;
              <b>
                {filterCount === 0 ? 'No' : filterCount}
                &nbsp;filter
              </b>
            </div>
          </div>
          <div className='d-flex d-sm-none justify-content-between searchDivCarrier'>
            <div className='d-flex searchField '>
              <img className='mx-2' src={search} alt='' />
              <input
                className='inputSearchField '
                type='search'
                placeholder='Search carriers'
                onChange={(e) => {
                  setPage(0);
                  setSearchBy(e.target.value);
                }}
              />
            </div>
            <div
              className='d-flex searchField filterField position-relative'
              onClick={() => setShowFilter('open')}
            >
              <img src={filter} alt='' />
              {filterCount > 0 && (
                <span className='filter-counter'>{filterCount}</span>
              )}
            </div>
          </div>
          <form>
            <div className=' d-sm-none mobilecardHeadDiv'>
              {loadingStatus === 'loading' ? (
                [1, 2, 3].map((item) => <Skeleton height={138} key={item} />)
              ) : loadingStatus === 'succeeded' && carriers?.length > 0 ? (
                <>
                  <div className='d-flex align-items-center mb-2 select-all-mobile-container'>
                    <input
                      type='checkbox'
                      className='form-check-input check-all'
                      defaultChecked={carriers.every((el) =>
                        selectedItems.find((carrierEl) => el.uuid === carrierEl)
                      )}
                      checked={carriers.every((el) =>
                        selectedItems.find((carrierEl) => el.uuid === carrierEl)
                      )}
                      onChange={(e) => checkAll(e)}
                    />
                    <b className='mx-2'>
                      {selectedItems?.length
                        ? `${selectedItems?.length} selected`
                        : ''}
                      &nbsp;
                    </b>
                  </div>
                  {carriers.map((item) => (
                    <CarriersMobileCard>
                      <div className='d-flex align-items-center carrier-card-head'>
                        <input
                          type='checkbox'
                          className='form-check-input'
                          defaultChecked={selectedItems?.includes(item?.uuid)}
                          checked={selectedItems?.includes(item?.uuid)}
                          onChange={(e) => selectCarrier(e, item)}
                        />
                        <span className='CarrierName mx-2'>
                          {item.name ? item.name : '-'}
                        </span>
                      </div>
                      <div className='d-block'>
                        <div className='stateandnames '>
                          States :{' '}
                          <b>
                            {item.jurisdictions?.length > 4
                              ? `${String(item.jurisdictions.slice(0, 4))}  ${
                                  item.jurisdictions?.length - 4
                                } others`
                              : String(item.jurisdictions)}
                            {item.jurisdictions?.length < 1 && '-'}
                          </b>
                        </div>
                        <div className='stateandnames '>
                          Policies :{' '}
                          <b>
                            {item.Products?.map((el, index: number) => {
                              if (index < 3) {
                                return `${el.name
                                  ?.toLowerCase()
                                  ?.split('_')
                                  ?.join(' ')}${
                                  index !== item.Products?.length - 1
                                    ? ', '
                                    : ''
                                }`;
                              } else if (index === item.Products?.length - 1) {
                                return ` and ${
                                  item?.Products?.length - 3
                                } others`;
                              }
                            })}
                            {item.Products?.length < 1 && '-'}
                          </b>
                        </div>
                      </div>
                    </CarriersMobileCard>
                  ))}
                  <PaginationComponent
                    limit={limit}
                    setLimit={setLimit}
                    page={page}
                    setPage={setPage}
                    count={carrierCount}
                  />
                </>
              ) : (
                <EmptyResults name='Carriers' />
              )}
            </div>
            {loadingStatus === 'loading' || carrierCount === 0 ? (
              <TableDiv>
                <thead>
                  <tr>
                    <th className='agencyNameHead'>
                      <div className='d-flex'>
                        <div className='w-75 d-flex justify-content-between'>
                          Carrier
                          <img
                            className='sort'
                            src={
                              sortBy !== 'name'
                                ? sort
                                : orderBy === 'asc'
                                ? sortdown
                                : sortup
                            }
                            alt='sort'
                            onClick={() => handleSort('name')}
                          />
                        </div>
                      </div>
                    </th>
                    <th className='statusContainerHead'>States</th>
                    <th className='statusContainerHead'>Policies offered</th>
                  </tr>
                </thead>
                <tbody>
                  {loadingStatus === 'loading' ? (
                    <>
                      {[1, 2, 3].map((index) => (
                        <CardDiv key={index}>
                          {[1, 2, 3].map((item) => (
                            <td key={item}>
                              <Skeleton height={24} />
                            </td>
                          ))}
                        </CardDiv>
                      ))}
                    </>
                  ) : (
                    <tr className='empty-div'>
                      <td colSpan={5} className='d-xl-none'>
                        <EmptyResults name='System defined carriers' />
                      </td>
                      <td colSpan={7} className='d-none d-xl-table-cell'>
                        <EmptyResults name='System defined carriers' />
                      </td>
                    </tr>
                  )}
                </tbody>
              </TableDiv>
            ) : (
              <div className='d-none d-sm-block batch-drawer-table'>
                <div className='scroll-bar  '>
                  <Table
                    limit={limit}
                    setLimit={setLimit}
                    page={page}
                    setPage={setPage}
                    count={carrierCount}
                    columns={[
                      {
                        Header: () => (
                          <div className='d-flex align-items-center  h-100'>
                            <input
                              type='checkbox'
                              className='form-check-input check-all'
                              defaultChecked={carriers.every((el) =>
                                selectedItems.find(
                                  (carrierEl) => el.uuid === carrierEl
                                )
                              )}
                              onChange={(e) => checkAll(e)}
                            />
                          </div>
                        ),
                        accessor: 'checkbox',
                        width: 60,
                        minWidth: 60,
                        maxWidth: 60,
                        Cell: ({ row }: any) => (
                          <div className='d-flex align-items-center  h-100'>
                            <input
                              type='checkbox'
                              className='form-check-input'
                              defaultChecked={selectedItems.includes(
                                row.original.uuid
                              )}
                              onChange={(e) => selectCarrier(e, row.original)}
                            />
                          </div>
                        ),
                      },
                      {
                        accessor: 'name',
                        resizable: true,
                        minWidth: 300,
                        sortable: true,
                        cellClass: 'carrierName',
                        headerCellClass: 'carrierName',
                        Header: () => (
                          <div
                            onClick={() => handleSort('name')}
                            style={{ cursor: 'pointer' }}
                            className='d-flex justify-content-between w-100'
                          >
                            <span>Carrier</span>
                            <img
                              className='sort'
                              src={
                                sortBy !== 'carrierName'
                                  ? sort
                                  : orderBy === 'asc'
                                  ? sortdown
                                  : sortup
                              }
                              alt='sort'
                            />
                          </div>
                        ),
                        Cell: ({ row }: { row: any }) => (
                          <div className='row-name d-flex align-items-center'>
                            {row.original.logo ? (
                              <img
                                className='logoImage'
                                src={row.original.logo}
                                alt=''
                              />
                            ) : (
                              <div className='logoImage'>
                                <span>
                                  {row.original.name[0]}
                                  {row.original.name[1]}
                                </span>
                              </div>
                            )}
                            <b>
                              {' '}
                              {row.original.name ? row.original.name : '-'}
                            </b>
                          </div>
                        ),
                      },
                      {
                        accessor: 'States',
                        Header: 'States',
                        resizable: true,
                        Cell: ({ row }: { row: any }) => (
                          <div className='d-flex'>
                            {row.original.jurisdictions?.length > 4
                              ? `${String(
                                  row.original.jurisdictions.slice(0, 4)
                                )}  ${
                                  row.original.jurisdictions?.length - 4
                                } others`
                              : String(row.original.jurisdictions)}
                            {row.original.jurisdictions?.length < 1 && '-'}
                          </div>
                        ),
                      },
                      {
                        accessor: 'productCategories',
                        Header: 'Policies offered',
                        resizable: true,
                        sortable: true,

                        Cell: ({ row }: { row: any }) => (
                          <>
                            <div className='d-flex align-items-center h-100'>
                              {row.original.Products?.map(
                                (item: IProduct, index: number) => {
                                  if (index < 3) {
                                    return `${item.name
                                      ?.toLowerCase()
                                      ?.split('_')
                                      ?.join(' ')}${
                                      index !==
                                      row.original.Products?.length - 1
                                        ? ', '
                                        : ''
                                    }`;
                                  } else if (
                                    index ===
                                    row.original.Products?.length - 1
                                  ) {
                                    return ` and ${
                                      row.original?.Products?.length - 3
                                    } others`;
                                  }
                                }
                              )}
                            </div>
                            {row.original.Products?.length < 1 && '-'}
                          </>
                        ),
                      },
                    ]}
                    data={carriers}
                  />
                </div>
              </div>
            )}
            <div className='buttonsDiv d-flex align-items-center '>
              <button
                onClick={() => {
                  onCloseModal();
                }}
                className='cancel-button'
                type='button'
              >
                Cancel
              </button>
              <button
                type='button'
                className='saveButton'
                onClick={() => onSubmit()}
                disabled={
                  loadingStatus === 'loading' ||
                  addCarrier === 'closing' ||
                  selectedItems?.length === 0
                }
              >
                Add {selectedItems?.length ? selectedItems?.length : ''}{' '}
                carriers <img className='tick' src={tick} alt='tick-sign' />
              </button>
            </div>
          </form>
        </div>
      </Modal>
    </StyledEditDetailsPage>
  );
};
export default CarrierBatchDrawer;
