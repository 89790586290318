import { useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Moment from 'react-moment';
import { useOutsideClick } from '../../../hooks/useOutsideClick';
import EditOpportunityDetails from '../../../pages/OpportunityDetails/EditOpportunityDetails';
import { CardDiv } from '../../../styles/InnerTabsCardStyles';
import vector from '../../../assets/logo/dropdown.svg';
import arrow from '../../../assets/logo/right-arrow.svg';
import home from '../../../assets/logo/home.svg';
import edit from '../../../assets/logo/edit.svg';
import bin from '../../../assets/logo/bin.svg';

const InnerCard = ({
  name,
  details,
  onDelete,
  loadingStatus,
  showDeleteModal,
  setShowDeleteModal,
  setStartQuoting,
  setOpportunity,
}: {
  name: string;
  details?: any;
  onDelete?: Function;
  loadingStatus?: string;
  showDeleteModal?: string;
  setShowDeleteModal?: Function;
  setStartQuoting: Function;
  setOpportunity: Function;
}) => {
  const navigate = useNavigate();

  const [showEditModal, setShowEditModal] = useState('closed');
  const deleteFun = (uuid: string, name: string) => {
    if (onDelete) {
      onDelete(uuid, name);
    }
  };

  return (
    <CardDiv>
      <div className='opportunity'>
        <div className='topContent d-flex justify-content-between'>
          <div className='topDetails d-flex align-items-center'>
            <div className='imageCard'>
              <img src={home} alt='' />
            </div>
            <div className='d-flex align-items-center'>
              <span className='text-overflow-container oppName-head'>
                <span
                  className='oppName opp-width'
                  onClick={() => navigate(`/opportunities/${details.uuid}`)}
                >
                  {`${details.name ? details.name : 'Unknown'} -  ${
                    details.ProductId
                      ? details.Product.name
                      : details.CustomProduct.name
                  }`}
                </span>
                <span className='tooltiptext tooltip-producer'>
                  <span className='tooltipInnertext'>{details.name}</span>
                </span>
              </span>
              <div className='oppstatus yellowC'>
                <span className='detailsStatusText'>
                  {details.PipelineStage?.name}
                </span>
              </div>
            </div>
          </div>
          <div className='profile-pic d-flex align-items-center justify-content-center'>
            {name === 'Opportunity' && details?.Agent?.User?.avatar
              ? details?.Agent?.User?.avatar
              : details?.Agent?.User?.firstName?.charAt(0) +
                details?.Agent?.User?.lastName?.charAt(0)}
          </div>
        </div>
        <div className='lowContent'>
          <div className='oppDetaisl d-flex justify-content-between'>
            <div className='oppDetaislfields d-flex justify-content-between '>
              {name === 'Opportunity' && (
                <>
                  <div>
                    <div className='valHeaddetails '>Account name</div>
                    <span className='text-overflow-container'>
                      <span className='valueField'>
                        {details.Account?.name}
                      </span>
                      <span className='tooltiptext tooltip-producer'>
                        <span className='tooltipInnertext'>
                          {details.Account?.name}
                        </span>
                      </span>
                    </span>
                  </div>
                  <div>
                    <div className='valHeaddetails'>Effective date</div>
                    <span className='valueField'>
                      {details?.effectiveDate ? (
                        <Moment format='MM/DD/YYYY'>
                          {details?.effectiveDate}
                        </Moment>
                      ) : (
                        'N/A'
                      )}
                    </span>
                  </div>
                  <div>
                    <div className='valHeaddetails'>Est. premium</div>
                    <span className='valueField'>
                      <b>$</b>
                      {details?.premium
                        ? details?.premium.toLocaleString('en-US')
                        : 0}
                    </span>
                  </div>
                </>
              )}
            </div>
            <div className=' buttnsContainer d-flex align-items-center justify-content-center position-relative'>
              <div>
                <button
                  className='tertiaryButton quotingbtn '
                  type='button'
                  onClick={() => {
                    setStartQuoting('open');
                    setOpportunity(details);
                  }}
                >
                  Start Quoting <img className='addIcon' src={arrow} alt='' />
                </button>
              </div>

              <ActionsDropdown
                uuid={details.uuid}
                setShowEditModal={setShowEditModal}
                setShowDeleteModal={setShowDeleteModal}
                name={details.name || details?.Accounts?.name}
                deleteFun={deleteFun}
              />
            </div>
          </div>
        </div>
      </div>
      {details && showEditModal !== 'closed' && (
        <EditOpportunityDetails
          show={showEditModal}
          closeModal={setShowEditModal}
          UUID={details?.uuid}
        />
      )}
    </CardDiv>
  );
};

export default InnerCard;

const ActionsDropdown = ({
  uuid,
  setShowEditModal,
  setShowDeleteModal,
  name,
  deleteFun,
}: {
  uuid: string;
  setShowEditModal: Function;
  setShowDeleteModal?: Function;
  name: string;
  deleteFun: Function;
}) => {
  const [showActionsDropdown, setShowActionsDropdown] = useState(false);

  const dropRef = useRef<HTMLButtonElement>(null);
  useOutsideClick(dropRef, setShowActionsDropdown);

  return (
    <button
      className='vector d-flex align-items-center justify-content-center position-relative'
      onClick={() => {
        setShowActionsDropdown(!showActionsDropdown);
        if (dropRef.current) {
          dropRef.current.focus();
        }
      }}
      ref={dropRef}
      onBlur={() => setShowActionsDropdown(false)}
    >
      <img src={vector} alt='vector' />
      {showActionsDropdown && (
        <div className='dropdown-div'>
          <div
            className='edit-div'
            onClick={() => {
              setShowEditModal('open');
              setShowActionsDropdown(false);
            }}
          >
            Edit <img src={edit} alt='bin' />
          </div>
          {setShowDeleteModal && (
            <div
              className='delete-div'
              onClick={() => {
                setShowDeleteModal('open');
                setShowActionsDropdown(false);
                deleteFun(uuid, name);
              }}
            >
              Delete <img src={bin} alt='bin' />
            </div>
          )}
        </div>
      )}
    </button>
  );
};
