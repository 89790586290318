import Modal from '../../../../components/Modal';
import { StyledModal } from '../../elements/styles';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import InputField from '../../../../components/InputField';
import SearchDropdown from '../../../../components/SearchDropdown';
import cross from '../../../../assets/logo/cross.svg';
import tick from '../../../../assets/logo/tick.svg';
import warning from '../../../../assets/logo/warning.svg';
import { fetchProductCategory } from '../../../../api/productCategory';
import { IProductCategory } from '../../../../interfaces/ProductCategory';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { createCarrier } from '../../../../store/carriers/carriersSlice';
import { AppDispatch } from '../../../../store';
import { useDispatch } from 'react-redux';
import { SuccessAlign } from '../../../../styles/SuccessAlign';
import SuccessCard from '../../../../components/SuccessCard';
import SelectField from '../../../../components/SelectField';
import { fetchProducts } from '../../../../api/product';
import PolicyTypeSelect from './PolicyTypeSelect';

const urlRegex = new RegExp(
  /[(http(s)?):\/\/(www\.)?a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/gi
);

const SignupSchema = Yup.object().shape(
  {
    name: Yup.string()
      .required('Carrier Name is required')
      .max(20, 'The Carrier name cannot exceed 20 characters'),
    iPhoneAppLink: Yup.string()
      .nullable()
      .notRequired()
      .when('iPhoneAppLink', {
        is: (value: any) => value?.length,
        then: (rule: any) =>
          rule.matches(urlRegex, 'Entered url is not valid.'),
      }),
    androidAppLink: Yup.string()
      .nullable()
      .notRequired()
      .when('androidAppLink', {
        is: (value: any) => value?.length,
        then: (rule: any) =>
          rule.matches(urlRegex, 'Entered url is not valid.'),
      }),
    selfServiceLink: Yup.string()
      .nullable()
      .notRequired()
      .when('selfServiceLink', {
        is: (value: any) => value?.length,
        then: (rule: any) =>
          rule.matches(urlRegex, 'Entered url is not valid.'),
      }),
  },
  [
    ['iPhoneAppLink', 'iPhoneAppLink'],
    ['androidAppLink', 'androidAppLink'],
    ['selfServiceLink', 'selfServiceLink'],
  ]
);
const CarrierModal = ({
  loadingStatus,
  addCarrier,
  onCloseModal,
  setAddCarrier,
}: {
  loadingStatus: string;
  addCarrier: string;
  onCloseModal: Function;
  setAddCarrier: Function;
}) => {
  const {
    register,
    handleSubmit,
    control,
    reset,
    getValues,
    watch,
    setValue,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(SignupSchema),
  });
  const watchAllFields = watch();

  const dispatch = useDispatch<AppDispatch>();

  const { uuid } = useParams();
  const [showSuccess, setshowSuccess] = useState(false);
  const onSubmit = (data: any) => {
    data.agencyUUID = uuid || '';
    data.actionType = 'CREATE';
    data.policyTypes =
      data.policyTypes?.map((item: { label: string; value: string }) => {
        return { uuid: item.value };
      }) || [];
    dispatch(
      createCarrier({
        data,
        successCB: () => {
          setAddCarrier('closing');
          setTimeout(() => {
            setshowSuccess(true);
          }, 300);
          setTimeout(() => {
            reset();
            setshowSuccess(false);
            setTimeout(() => {
              setAddCarrier('closed');
            }, 300);
          }, 2000);
        },
      })
    );
  };
  const [productCategory, setProductCategory] = useState<IProductCategory[]>(
    []
  );

  useEffect(() => {
    fetchProductCategory(
      (data: IProductCategory[]) => {
        setProductCategory(data);
      },
      'name',
      'asc',
      '',
      uuid,
      undefined,
      true,
      'CUSTOM_PRODUCT_CATEGORY'
    );
  }, []);

  const [policyOptions, setPolicyOptions] = useState<any>([]);
  useEffect(() => {
    if (productCategory.length > 0) {
      setPolicyOptions([]);
      let op: any = [];
      productCategory.map((item: any) => {
        op.push({ label: item.name, value: item.uuid, isDisabled: true });
        item.CustomProduct?.map((el: any) => {
          op.push({ label: el.name, value: el.uuid });
        });

        return;
      });
      setPolicyOptions([...op]);
    }
  }, [productCategory]);

  return (
    <>
      <SuccessAlign>
        <div className={`success-container ${showSuccess && 'open-drawer'} `}>
          <SuccessCard
            title={'Success adding Custom carrier'}
            message={`<b>${getValues(
              'name'
            )}</b> has been added as custom carrier<b>`}
          />
          <button
            onClick={() => {
              reset();
              setshowSuccess(false);
            }}
            className='crossbutton'
          >
            <img src={cross} className='cross' alt='cross-icon' />
          </button>
        </div>
      </SuccessAlign>
      <Modal open={addCarrier} align='right'>
        <StyledModal>
          <button className='crossbutton' onClick={() => onCloseModal()}>
            <img src={cross} className='cross' alt='cross-icon' />
          </button>
          <div>
            <h2 className='add-carrier-heading'>Add a single carrier</h2>
          </div>
          <div>
            <div>
              <form onSubmit={handleSubmit(onSubmit)}>
                <div className='field-section'>
                  <div className='agency-fields'>
                    <InputField
                      name='name'
                      type='text'
                      placeholder='Name of carrier'
                      register={register}
                      required
                      className={`${errors.name && 'error-field'} ${
                        watchAllFields.name && 'filled'
                      }`}
                    />
                    {errors.name ? (
                      <div className='errorVal'>
                        <img src={warning} alt='warning' className='warning' />
                        {errors.name?.message}
                      </div>
                    ) : null}
                  </div>
                  <div className='agency-fields'>
                    <InputField
                      name='iPhoneAppLink'
                      type='text'
                      placeholder='iPhone app link'
                      register={register}
                      className={`${errors.iPhoneAppLink && 'error-field'} ${
                        watchAllFields.iPhoneAppLink && 'filled'
                      }`}
                    />
                    {errors.iPhoneAppLink ? (
                      <div className='errorVal'>
                        <img src={warning} alt='warning' className='warning' />
                        {errors?.iPhoneAppLink?.message}
                      </div>
                    ) : null}
                  </div>
                  <div className='agency-fields'>
                    <InputField
                      name='androidAppLink'
                      type='text'
                      placeholder='Android app link'
                      register={register}
                      className={`${errors.androidAppLink && 'error-field'} ${
                        watchAllFields.androidAppLink && 'filled'
                      }`}
                    />
                    {errors.androidAppLink ? (
                      <div className='errorVal'>
                        <img src={warning} alt='warning' className='warning' />
                        {errors.androidAppLink?.message}
                      </div>
                    ) : null}
                  </div>
                  <div className='agency-fields'>
                    <InputField
                      name='selfServiceLink'
                      type='text'
                      placeholder='Self-service link'
                      register={register}
                      className={`${errors.selfServiceLink && 'error-field'} ${
                        watchAllFields.selfServiceLink && 'filled'
                      }`}
                    />
                    {errors.selfServiceLink ? (
                      <div className='errorVal'>
                        <img src={warning} alt='warning' className='warning' />
                        {errors.selfServiceLink?.message}
                      </div>
                    ) : null}
                  </div>
                  <div className='agency-fields'>
                    <PolicyTypeSelect
                      options={policyOptions}
                      name='policyTypes'
                      placeholder='Select Policy Type'
                      control={control}
                      isMulti={true}
                    />
                  </div>
                </div>
                <div className='buttonsDiv d-flex align-items-center '>
                  <button
                    onClick={() => {
                      reset();
                      onCloseModal();
                    }}
                    className='cancel-button'
                    type='button'
                  >
                    Cancel
                  </button>
                  <button
                    type='submit'
                    className='agentButton'
                    disabled={
                      loadingStatus === 'loading' || addCarrier === 'closing'
                    }
                  >
                    Save <img className='tick' src={tick} alt='tick-sign' />
                  </button>
                </div>
              </form>
            </div>
          </div>
        </StyledModal>
      </Modal>
    </>
  );
};
export default CarrierModal;
