import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import {
  fetchOpportunities,
  addOpportunity,
  fetchOpportunityDetails,
  updateOpportunityDetails,
  opportunitiesDetailsCount,
  UpdateOpportunityConatcts,
  deleteOpportunity,
} from '../../api/opportunities';
import { IOpportunity } from '../../interfaces/Opportunity';

export type OpportunitiesSliceState = {
  status: string;
  opportunities: IOpportunity[];
  opportunityDetails?: IOpportunity;
  count: number;
  quotesCount: number;
  activitiesCount: number;
  documentsCount: number;
  isLoading: boolean;
  historiesCount: number;
};

interface IProps {
  sortBy: string;
  searchBy: string;
  orderBy: string;
  limit: number;
  offset: number;
  pipelineUUID: any;
  opportunityStatusUUID?: any;
  productCategoryUUID?: any;
  pipelineStageUUID?: any;
  accountUUID?: string;
  producerUUID?: string;
  productUUID?: any;
  customProductUUID?: any;
  successCB?: Function;
}

const initialState: OpportunitiesSliceState = {
  status: 'idle',
  opportunities: [],
  count: 0,
  quotesCount: 0,
  activitiesCount: 0,
  documentsCount: 0,
  isLoading: false,
  historiesCount: 0,
};

export const getOpportunities = createAsyncThunk(
  'opportunities/getOpportunities',
  async ({
    sortBy,
    orderBy,
    searchBy,
    pipelineUUID,
    opportunityStatusUUID,
    productCategoryUUID,
    offset,
    limit,
    pipelineStageUUID,
    accountUUID,
    producerUUID,
    productUUID,
    customProductUUID,
    successCB,
  }: IProps) => {
    const response = await fetchOpportunities(
      sortBy,
      orderBy,
      searchBy,
      pipelineStageUUID,
      pipelineUUID,
      () => {},
      opportunityStatusUUID,
      productCategoryUUID,
      limit,
      offset,
      accountUUID,
      producerUUID,
      productUUID,
      customProductUUID
    );
    if (successCB) successCB(response.data);
    return response.data;
  }
);

export const createOpportunity = createAsyncThunk(
  'opportunities/createOpportunity',
  async ({
    data,
    successCB,
    errorCB,
  }: {
    data: IOpportunity;
    successCB: Function;
    errorCB: Function;
  }) => {
    const response = await addOpportunity(data, successCB, errorCB);
    return response.data;
  }
);

export const getOpportunityDetails = createAsyncThunk(
  'opportunities/getOpportunitytDetails',
  async ({ uuid, successCB }: { uuid?: string; successCB?: Function }) => {
    const response = await fetchOpportunityDetails(uuid);
    if (successCB) {
      successCB(response);
    }
    return response.data;
  }
);

export const onUpdateOpportunityDetails = createAsyncThunk(
  'opportunities/onUpdateOpportunityDetails',
  async ({
    data,
    successCB,
    errorCB,
    UUID,
  }: {
    data: IOpportunity;
    successCB: Function;
    errorCB: Function;
    UUID: string;
  }) => {
    const response = await updateOpportunityDetails(
      data,
      UUID,
      successCB,
      errorCB
    );
    successCB(response.data);
    return response.data;
  }
);

export const onUpdateOpportunityContact = createAsyncThunk(
  'opportunities/onUpdateOpportunityContact',
  async ({
    data,
    successCB,
    errorCB,
    uuid,
  }: {
    data: IOpportunity[];
    successCB: Function;
    errorCB: Function;
    uuid: string;
  }) => {
    const response = await UpdateOpportunityConatcts(data, uuid);
    successCB(response.data);
    return response.data;
  }
);

export const getCount = createAsyncThunk(
  'opportunities/getCount',
  async ({ uuid }: { uuid: string }) => {
    const response = await opportunitiesDetailsCount(uuid);
    return response.data;
  }
);

export const onDeleteOpportunity = createAsyncThunk(
  'opportunities/onDeleteOpportunity',
  async ({ uuid, successCB }: { uuid: string; successCB: Function }) => {
    const response = await deleteOpportunity(uuid);
    successCB();
    return response.data;
  }
);

const opportunitiesSlice = createSlice({
  name: 'opportunities',
  initialState,
  reducers: {
    updateStatus: (state) => {
      state.status = 'idle';
      state.isLoading = false;
    },
    getOpportunityQuoteCount: (state) => {
      state.quotesCount = state.quotesCount + 1;
    },
    addOpportunityDetails: (state, action) => {
      if (state.opportunityDetails) {
        state.opportunityDetails.OpportunityAccountContacts = [
          ...state.opportunityDetails.OpportunityAccountContacts,
          ...action.payload.opportunityAccountContacts,
        ];
      }
    },
  },
  extraReducers(builder) {
    builder
      .addCase(getOpportunities.pending, (state, action) => {
        state.status = 'loading';
      })
      .addCase(getOpportunities.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.opportunities = action.payload.opportunities;
        state.count = action.payload.count;
      })
      .addCase(getOpportunities.rejected, (state, action) => {
        state.status = 'failed';
      })
      .addCase(createOpportunity.fulfilled, (state, action) => {
        state.isLoading = false;
        state.opportunities.unshift(action.payload);
      })
      .addCase(createOpportunity.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(createOpportunity.rejected, (state, action) => {
        state.isLoading = false;
      })
      .addCase(getOpportunityDetails.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(getOpportunityDetails.fulfilled, (state, action) => {
        state.isLoading = false;
        state.opportunityDetails = action.payload;
      })
      .addCase(getOpportunityDetails.rejected, (state, action) => {
        state.isLoading = false;
      })
      .addCase(onUpdateOpportunityDetails.fulfilled, (state, action) => {
        const index = state.opportunities.findIndex(
          (item: IOpportunity) => item.uuid === action.payload.uuid
        );
        state.opportunities[index] = action.payload;
        state.opportunityDetails = action.payload;
        state.isLoading = false;
      })
      .addCase(onUpdateOpportunityDetails.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(onUpdateOpportunityDetails.rejected, (state, action) => {
        state.isLoading = false;
      })
      .addCase(onUpdateOpportunityContact.fulfilled, (state, action) => {
        if (state.opportunityDetails) {
          state.opportunityDetails.OpportunityAccountContacts = [
            ...state.opportunityDetails.OpportunityAccountContacts,
            ...action.payload.opportunityAccountContacts,
          ];
        }
        state.isLoading = false;
      })
      .addCase(onUpdateOpportunityContact.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(onUpdateOpportunityContact.rejected, (state, action) => {
        state.isLoading = false;
      })
      .addCase(getCount.pending, (state, action) => {
        state.status = 'loading';
      })
      .addCase(getCount.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.activitiesCount = action.payload.activities;
        state.documentsCount = action.payload.documents;
        state.quotesCount = action.payload.quotes;
        state.historiesCount = action.payload.histories;
      })
      .addCase(getCount.rejected, (state, action) => {
        state.status = 'failed';
      })
      .addCase(onDeleteOpportunity.pending, (state, action) => {
        state.status = 'loading';
      })
      .addCase(onDeleteOpportunity.fulfilled, (state, action) => {
        state.status = 'succeeded';
        const index = state.opportunities.findIndex(
          (item) => item.uuid === action.payload.uuid
        );
        state.opportunities.splice(index, 1);
      })
      .addCase(onDeleteOpportunity.rejected, (state, action) => {
        state.status = 'failed';
      });
  },
});

export const { updateStatus, getOpportunityQuoteCount, addOpportunityDetails } =
  opportunitiesSlice.actions;

export default opportunitiesSlice.reducer;

export const selectAllOpportunities = (state: {
  opportunities: OpportunitiesSliceState;
}) => state.opportunities?.opportunities;

export const selectOpportunity = (state: {
  opportunities: OpportunitiesSliceState;
}) => state.opportunities.opportunityDetails;

export const selectOpportunitiesCount = (state: {
  opportunities: OpportunitiesSliceState;
}) => {
  const objectCount: { [index: string]: any } = {
    documents: state.opportunities.documentsCount,
    activities: state.opportunities.activitiesCount,
    quotes: state.opportunities.quotesCount,
    histories: state.opportunities.historiesCount,
  };
  return objectCount;
};
