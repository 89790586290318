import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { StyledModal } from '../../elements/styles';
import { SuccessAlign } from '../../../../styles/SuccessAlign';
import cross from '../../../../assets/logo/cross.svg';
import tick from '../../../../assets/logo/tick.svg';
import warning from '../../../../assets/logo/warning.svg';
import InputField from '../../../../components/InputField';
import PhoneNumberInput from '../../../../components/PhoneNumberFormat';
import SelectField from '../../../../components/SelectField';
import SuccessCard from '../../../../components/SuccessCard';
import Modal from '../../../../components/Modal';
import {
  getAllLocations,
  selectAllLocations,
  LocationSliceState,
} from '../../../../store/location/locationSlice';
import { AppDispatch } from '../../../../store';
import {
  addNewAgent,
  AgentsSliceState,
} from '../../../../store/agents/agentsSlice';
import LocalStorageService from '../../../../utils/LocalStorageService';

const phoneRegExp = /^(?:\(\d{3}\)|\d{3})[- ]?\d{3}[- ]?\d{4}$/;

const AgentSchema = Yup.object().shape({
  phoneNumber: Yup.string()
    .matches(phoneRegExp, 'Entered phone number is not valid.')
    .required('Please enter your phone number'),
  userRole: Yup.object().shape({
    value: Yup.string().required('Please select a Role'),
  }),
  locationUUID: Yup.object().shape({
    value: Yup.string().required('Please select a Location'),
  }),
  email: Yup.string()
    .email('Entered email is not valid.')
    .required('Please enter your email '),
  firstName: Yup.string().max(20, 'First name cannot exceed 20 characters'),
  middleName: Yup.string().max(20, 'Middle name cannot exceed 20 characters'),
  lastName: Yup.string()
    .max(20, 'Last name cannot exceed 20 characters')
    .required('Please enter your last name'),
});

const AgentModal = ({
  show,
  closeModal,
}: {
  show: string;
  closeModal: Function;
}) => {
  const dispatch = useDispatch<AppDispatch>();

  const { uuid } = useParams();
  const [success, setSuccess] = useState(false);
  const [emailError, setEmailError] = useState('');
  const [disabled, setDisabled] = useState(false);
  const {
    register,
    handleSubmit,
    control,
    reset,
    getValues,
    watch,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(AgentSchema),
  });

  const watchAllFields = watch();

  const loadingStatus = useSelector(
    (state: { locations: LocationSliceState }) => state.locations?.status
  );

  const locations = useSelector(selectAllLocations);

  const options = [
    // { label: 'Admin', value: 'ADMIN', disabled: false },
    { label: 'Agent', value: 'AGENT', disabled: false },
    { label: 'Principal Agent', value: 'PRINCIPAL_AGENT', disabled: false },
  ];

  const onCloseModal = () => {
    closeModal('closing');
    setTimeout(() => {
      closeModal('closed');
    }, 300);
  };

  const loading = useSelector(
    (state: { agents: AgentsSliceState }) => state.agents.isLoading
  );

  const user = LocalStorageService.getUser();

  useEffect(() => {
    if (loadingStatus === 'idle' || loadingStatus === 'succeeded') {
      if (user?.UserRole.name === 'ADMIN' && uuid) {
        dispatch(
          getAllLocations({
            minimal: 'True',
            agencyUUID: uuid,
            successCB: (data: any) => {},
          })
        );
      } else {
        dispatch(
          getAllLocations({
            minimal: 'True',
            successCB: (data: any) => {},
          })
        );
      }
    }
  }, []);
  const onSubmit = (value: any) => {
    value.userRole = value.userRole.value;
    value.locationUUID = value.locationUUID.value;
    value.agencyUUID = uuid;

    dispatch(
      addNewAgent({
        value,
        successCB: () => {
          setDisabled(true);
          closeModal('closing');
          setTimeout(() => {
            setSuccess(true);
          }, 300);
          setTimeout(() => {
            reset();
            setSuccess(false);
            setTimeout(() => {
              closeModal('closed');
            }, 300);
          }, 4000);
        },
        errorCB: (error: any) => {
          setDisabled(false);
          error.data.message ===
            `Couldn't complete action. Please try again.` &&
            setEmailError('Email already in use');
        },
      })
    );
  };

  return (
    <>
      <SuccessAlign>
        <div
          className={`success-container ${success && show && 'open-drawer'} `}
        >
          <SuccessCard
            title={'Success'}
            message={`<b>${getValues('firstName')} ${getValues(
              'lastName'
            )}</b> has been added as a user for Renegade Team.`}
          />
          <button
            onClick={() => {
              reset();
              setSuccess(false);
            }}
            className='crossbutton'
          >
            <img src={cross} className='cross' alt='cross-icon' />
          </button>
        </div>
      </SuccessAlign>

      <Modal open={show} align='right'>
        <StyledModal>
          <button className='crossbutton' onClick={() => onCloseModal()}>
            <img src={cross} className='cross' alt='' />
          </button>

          <div>
            <h2>Add a user</h2>
          </div>
          <div>
            <p className='subhead'>Provide some basic details about the user</p>
            <div>
              {show && !success && (
                <form onSubmit={handleSubmit(onSubmit)}>
                  <div className='field-section'>
                    <div className='agency-fields'>
                      <InputField
                        register={register}
                        name='firstName'
                        type='text'
                        placeholder='First Name'
                        className={`${errors.firstName && 'error-field'} ${
                          watchAllFields.firstName && 'filled'
                        }`}
                      />
                      {errors.firstName ? (
                        <div className='error-div'>
                          <img
                            src={warning}
                            alt='warning'
                            className='warning'
                          />
                          {errors.firstName?.message}
                        </div>
                      ) : null}
                    </div>

                    <div className='agency-fields'>
                      <InputField
                        register={register}
                        name='middleName'
                        type='text'
                        placeholder='Middle Name'
                        className={`${errors.middleName && 'error-field'} ${
                          watchAllFields.middleName && 'filled'
                        }`}
                      />
                      {errors.middleName ? (
                        <div className='error-div'>
                          <img
                            src={warning}
                            alt='warning'
                            className='warning'
                          />
                          {errors.middleName?.message}
                        </div>
                      ) : null}
                    </div>
                    <div className='agency-fields'>
                      <InputField
                        register={register}
                        name='lastName'
                        type='text'
                        placeholder='Last Name'
                        required
                        className={`${errors.lastName && 'error-field'} ${
                          watchAllFields.lastName && 'filled'
                        }`}
                      />
                      {errors.lastName ? (
                        <div className='error-div'>
                          <img
                            src={warning}
                            alt='warning'
                            className='warning'
                          />
                          {errors.lastName?.message}
                        </div>
                      ) : null}
                    </div>
                    <div className='agency-fields'>
                      <InputField
                        register={register}
                        name='email'
                        type='email'
                        required
                        placeholder='Email'
                        className={`${errors.email && 'error-field'} ${
                          watchAllFields.email && 'filled'
                        }`}
                      />
                      {errors.email || emailError ? (
                        <div className='error-div'>
                          <img
                            src={warning}
                            alt='warning'
                            className='warning'
                          />
                          {errors.email?.message || emailError}
                        </div>
                      ) : null}
                    </div>
                    <div className='agency-fields'>
                      <PhoneNumberInput
                        control={control}
                        name='phoneNumber'
                        type='tel'
                        required
                        placeholder='Phone Number'
                        className={`${errors.phoneNumber && 'error-field'} ${
                          watchAllFields.phoneNumber && 'filled'
                        }`}
                      />
                      {errors.phoneNumber ? (
                        <div className='error-div'>
                          <img
                            src={warning}
                            alt='warning'
                            className='warning'
                          />
                          {errors.phoneNumber?.message}
                        </div>
                      ) : null}
                    </div>
                    <div className='agency-fields'>
                      <SelectField
                        options={locations.map((item) => {
                          return {
                            label: item?.name,
                            value: item.uuid,
                          };
                        })}
                        name='locationUUID'
                        placeholder='Location'
                        required
                        control={control}
                      />
                      {errors.locationUUID ? (
                        <div className='error-div'>
                          <img
                            src={warning}
                            alt='warning'
                            className='warning'
                          />
                          {errors.locationUUID?.message
                            ? 'Please select a location'
                            : errors.locationUUID?.value?.message}
                        </div>
                      ) : null}
                    </div>
                    <div className='agency-fields'>
                      <SelectField
                        options={options}
                        name='userRole'
                        placeholder='Select a role'
                        required
                        control={control}
                      />
                      {errors.userRole ? (
                        <div className='error-div'>
                          <img
                            src={warning}
                            alt='warning'
                            className='warning'
                          />
                          {errors.userRole?.message
                            ? 'Please select a role'
                            : errors.userRole?.value?.message}
                        </div>
                      ) : null}
                    </div>
                  </div>

                  <div className='buttonsDiv d-flex align-items-center'>
                    <button
                      onClick={() => onCloseModal()}
                      className='cancel-button'
                      type='button'
                    >
                      Cancel
                    </button>
                    <button
                      type='submit'
                      className='agentButton '
                      disabled={loading || show === 'closing' || disabled}
                    >
                      Save <img className='tick' src={tick} alt='tick-sign' />
                    </button>
                  </div>
                </form>
              )}
            </div>
          </div>
        </StyledModal>
      </Modal>
    </>
  );
};

export default AgentModal;
