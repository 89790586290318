import sort from '../../../../assets/logo/sort.svg';
import sortup from '../../../../assets/logo/sortup.svg';
import sortdown from '../../../../assets/logo/sortdown.svg';
import UserCard from './UserCard';
import { TableDiv } from '../../../../styles/TableDiv';
import { useSelector } from 'react-redux';
import { IPolicy } from '../../../../interfaces/Policy';
import { PoliciesSliceState } from '../../../../store/policies/policiesSlice';
import LoadingSkeleton from '../../../MyBook/components/Loading';
import EmptyResults from '../../../MyBook/components/Empty';
import { GoalSliceState } from '../../../../store/goals/goalSlice';
import { CardDiv } from '../../../../styles/CardDiv';
import Skeleton from 'react-loading-skeleton';
const UserTable = ({
  leaders,
  setSortBy,
  setOrderBy,
  sortBy,
  orderBy,
}: {
  leaders: any;
  setSortBy: Function;
  setOrderBy: Function;
  sortBy: string;
  orderBy: string;
}) => {
  const loadingStatus = useSelector(
    (state: { goals: GoalSliceState }) => state.goals.status
  );

  const handleSort = (sort: string) => {
    if (sort === sortBy) {
      if (orderBy === 'asc') {
        setOrderBy('desc');
      } else {
        setOrderBy('asc');
      }
    } else {
      setSortBy(sort);
    }
  };

  return (
    <TableDiv>
      <thead>
        <tr>
          <th className='statusContainerHead'>Rank</th>
          <th className='agencyNameHead'>
            <div className='d-flex'>
              <div className='w-75 d-flex justify-content-between'>
                User name
                {/* <img
                  className='sort'
                  src={
                    sortBy !== 'name'
                      ? sort
                      : orderBy === 'asc'
                      ? sortdown
                      : sortup
                  }
                  alt='sort'
                  onClick={() => handleSort('name')}
                /> */}
              </div>
            </div>
          </th>
          <th className='statusContainerHead'>Premium goal</th>
          <th className='statusContainerHead'>Policy goal</th>

          <th className='leadActionsFieldHead'>Actions</th>
        </tr>
      </thead>
      <tbody>
        {loadingStatus === 'loading' ? (
          <>
            {[1, 2, 3, 4].map((index) => (
              <CardDiv key={index}>
                <td>
                  <div className='d-flex align-items-center'>
                    <Skeleton height={24} width={30} />
                  </div>
                </td>
                {[1, 2, 3].map((item) => (
                  <td key={item}>
                    <Skeleton height={24} />
                  </td>
                ))}
                <td className='d-none d-xl-table-cell'>
                  <Skeleton height={24} />
                </td>
              </CardDiv>
            ))}
          </>
        ) : loadingStatus === 'succeeded' && leaders.length > 0 ? (
          leaders.map((el: any, index: number) => (
            <UserCard leader={el} index={index} key={el.uuid} />
          ))
        ) : (
          <tr className='empty-div'>
            <td colSpan={5} className='d-xl-none'>
              <EmptyResults name='user goals' />
            </td>
            <td colSpan={7} className='d-none d-xl-table-cell'>
              <EmptyResults name='user goals' />
            </td>
          </tr>
        )}
      </tbody>
    </TableDiv>
  );
};

export default UserTable;
