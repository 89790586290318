import { useRef, useState } from 'react';
import Moment from 'react-moment';
import { useNavigate } from 'react-router-dom';
import { useOutsideClick } from '../../../hooks/useOutsideClick';
import EditPolicyDetails from '../../../pages/PolicyDetails/EditPolicyDetails';
import { CardDiv } from '../../../styles/InnerTabsCardStyles';
import vector from '../../../assets/logo/dropdown.svg';
import arrow from '../../../assets/logo/right-arrow.svg';
import pin from '../../../assets/logo/cna.svg';
import edit from '../../../assets/logo/edit.svg';
import bin from '../../../assets/logo/bin.svg';
import moment from 'moment';

const DetailsInnerCard = ({
  name,
  details,
  onDelete,
  loadingStatus,
  showDeleteModal,
  setShowDeleteModal,
}: {
  name: string;
  details?: any;
  onDelete?: Function;
  loadingStatus?: string;
  showDeleteModal?: string;
  setShowDeleteModal?: Function;
}) => {
  const navigate = useNavigate();

  const [showEditModal, setShowEditModal] = useState('closed');
  const deleteFun = (uuid: string, name: string) => {
    if (onDelete) {
      onDelete(uuid, name);
    }
  };
  const currentDate = new Date();

  return (
    <CardDiv>
      {' '}
      <div className='opportunity'>
        <div className='topContent d-flex justify-content-between'>
          <div className='topDetails d-flex align-items-center'>
            <>
              <div className='profile-pic d-flex align-items-center justify-content-center'>
                {details?.Accounts?.name
                  ?.split(' ')
                  .map((item: string) => item.substring(0, 1))
                  .join('')}
              </div>
              <span
                className='oppName'
                onClick={() => navigate(`/policy/${details.uuid}`)}
              >
                <div className='d-flex align-items-center'>
                  {details?.Accounts?.name
                    ? details?.Accounts?.name
                    : 'Unknown'}
                </div>
                <div className='policyNumdiv'>
                  <b>#</b>
                  {details?.policyNumber ? details?.policyNumber : '--'}
                </div>
              </span>
              <div className='policyStatus'>
                {moment().isBefore(
                  moment(details?.effectiveDate).format('YYYY-MM-DD'),
                  'day'
                ) && (
                  <span className='policy-stage-pill grey-bg'>
                    {'Inactive'}
                  </span>
                )}
                {moment().isBetween(
                  moment(details?.effectiveDate).format('YYYY-MM-DD'),
                  moment(details?.expiryDate).format('YYYY-MM-DD')
                ) && (
                  <span className='policy-stage-pill green-bg'>{'Active'}</span>
                )}
                {moment(
                  moment(details?.expiryDate).format('YYYY-MM-DD')
                ).isBefore(moment().format('YYYY-MM-DD'), 'day') && (
                  <span className='policy-stage-pill danger-bg'>
                    {'Expired'}
                  </span>
                )}
              </div>
            </>
          </div>
          <div className='d-flex align-items-center'>
            <div className='PremiumDiv'>
              <span className='valHead  '>Premium</span>
              <span className='valueField'>
                <b>$</b>
                {details?.premium
                  ? details?.premium.toLocaleString('en-US')
                  : 0}
              </span>
            </div>
            <div className='profilePicDiv d-flex align-items-center justify-content-center  text-overflow-container'>
              {details?.Agents?.User?.firstName?.charAt(0).toUpperCase()}
              {details?.Agents?.User?.lastName?.charAt(0).toUpperCase()}
              <span className='tooltiptextPolicy'>
                <span className='tooltipInnertext'>
                  {details?.Agents?.User?.firstName +
                    ' ' +
                    details?.Agents?.User?.lastName}
                </span>
              </span>
            </div>
          </div>
        </div>
        <div className='lowContent'>
          <div className='oppDetaisl d-flex justify-content-between'>
            <div className='oppDetaislfields d-flex justify-content-between '>
              <>
                <div className='PinCard'>
                  {details?.Issuer?.Carrier?.logo ? (
                    <img
                      className='CarrierImage'
                      src={details?.Issuer?.Carrier?.logo}
                      alt=''
                    />
                  ) : (
                    <div className='profile-pic CarrierPr  d-flex align-items-center justify-content-center text-overflow-container'>
                      {details.Issuer
                        ? details.Issuer.Carrier?.name?.charAt(0) +
                          details.Issuer.Carrier?.name?.charAt(1)
                        : details.CustomCarrier
                        ? details.CustomCarrier?.name.charAt(0) +
                          details.CustomCarrier?.name.charAt(1)
                        : '--'}
                      <span className='tooltiptext'>
                        <span className='tooltipInnertext'>
                          {details.Issuer
                            ? details.Issuer.Carrier?.name
                            : details.CustomCarrier
                            ? details.CustomCarrier?.name
                            : '--'}
                        </span>
                      </span>
                    </div>
                  )}
                </div>

                <div className='Card'></div>
                <div>
                  <span className='valHead'>Policy type </span>
                  <span className='valueField'>
                    {' '}
                    {details?.Product
                      ? details?.Product?.name
                      : details?.CustomProduct
                      ? details?.CustomProduct?.name
                      : 'N/A'}
                  </span>
                </div>
                <div>
                  <span className='valHead'>Effective date</span>
                  <span className='valueField'>
                    {details?.effectiveDate ? (
                      <Moment format='MM/DD/YYYY'>
                        {details?.effectiveDate}
                      </Moment>
                    ) : (
                      pin
                    )}
                  </span>
                </div>
                <div>
                  <span className='valHead'>Won date</span>
                  <span className='valueField'>
                    {' '}
                    {details?.createdAt ? (
                      <Moment format='MM/DD/YYYY'>{details?.createdAt}</Moment>
                    ) : (
                      'N/A'
                    )}
                  </span>
                </div>
              </>
            </div>
            <div className=' d-flex align-items-center justify-content-center position-relative'>
              <button
                className='tertiaryButton quotingbtn '
                onClick={() => navigate(`/policy/${details.uuid}`)}
              >
                View details <img className='addIcon' src={arrow} alt='' />
              </button>

              <ActionsDropdown
                uuid={details.uuid}
                setShowEditModal={setShowEditModal}
                setShowDeleteModal={setShowDeleteModal}
                name={details.name || details?.Accounts?.name}
                deleteFun={deleteFun}
              />
            </div>
          </div>
        </div>
        {details && showEditModal !== 'closed' && (
          <EditPolicyDetails
            show={showEditModal}
            closeModal={setShowEditModal}
            uuid={details.uuid}
            policy={details}
          />
        )}
      </div>
    </CardDiv>
  );
};

export default DetailsInnerCard;

const ActionsDropdown = ({
  uuid,
  setShowEditModal,
  setShowDeleteModal,
  name,
  deleteFun,
}: {
  uuid: string;
  setShowEditModal: Function;
  setShowDeleteModal?: Function;
  name: string;
  deleteFun: Function;
}) => {
  const [showActionsDropdown, setShowActionsDropdown] = useState(false);

  const dropRef = useRef<HTMLButtonElement>(null);
  useOutsideClick(dropRef, setShowActionsDropdown);

  return (
    <button
      className='vector d-flex align-items-center justify-content-center position-relative'
      onClick={() => {
        setShowActionsDropdown(!showActionsDropdown);
        if (dropRef.current) {
          dropRef.current.focus();
        }
      }}
      ref={dropRef}
      onBlur={() => setShowActionsDropdown(false)}
    >
      <img src={vector} alt='vector' />
      {showActionsDropdown && (
        <div className='dropdown-div'>
          <div
            className='edit-div'
            onClick={() => {
              setShowEditModal('open');
              // setUUID(uuid);
              setShowActionsDropdown(false);
            }}
          >
            Edit <img src={edit} alt='bin' />
          </div>
          {setShowDeleteModal && (
            <div
              className='delete-div'
              onClick={() => {
                setShowDeleteModal('open');
                setShowActionsDropdown(false);
                deleteFun(uuid, name);
              }}
            >
              Delete <img src={bin} alt='bin' />
            </div>
          )}
        </div>
      )}
    </button>
  );
};
