import { useState, useRef } from 'react';
import Moment from 'react-moment';
import { useNavigate } from 'react-router-dom';
import { StyledCard } from '../../elements/styles';
import vector from '../../../../assets/logo/dropdown.svg';
import bin from '../../../../assets/logo/bin.svg';
import edit from '../../../../assets/logo/edit.svg';
import { IAccount } from '../../../../interfaces/Account';
import { useOutsideClick } from '../../../../hooks/useOutsideClick';
import EditAccountDetails from '../../../AccountDetails/EditAccountDetails';

const AccountMobileCard = ({
  account,
  setShowDeleteModal,
  setUUID,
  setName,
}: {
  account: IAccount;
  setShowDeleteModal: Function;
  setUUID: Function;
  setName: Function;
}) => {
  const navigate = useNavigate();

  const [showDropdown, setShowDropdown] = useState(false);
  const [showEditAccountForm, setShowEditAccountForm] = useState('closed');
  const dropRef = useRef(null);
  useOutsideClick(dropRef, setShowDropdown);

  return (
    <StyledCard>
      <div className='d-flex justify-content-between align-items-center'>
        <h6 onClick={() => navigate(`/account/${account.uuid}`)}>
          {account.name ? account.name : '-'}
        </h6>
        <div
          className={`status ${
            account.Policies?.length > 0 ? 'statusActive' : 'statusInvited'
          }`}
        >
          {account.Policies?.length > 0 ? 'Customer' : 'Prospect'}
        </div>
      </div>
      <div className='info-div'>
        <div className=' d-flex'>
          <div className='type-div'>
            ${account?.premium ? account.premium.toLocaleString() : 0}
          </div>
          <div>{account.Opportunities.length} opportunities</div>
        </div>
        Created on <Moment format='MM/DD/YYYY'>{account.createdAt}</Moment>
      </div>

      <div className='d-flex justify-content-between'>
        <div className='toolDiv info d-flex align-items-center h-100'>
          {account.Agent?.User?.avatar ? (
            <img
              src={account.Agent?.User?.avatar}
              alt=''
              className='user-pic'
            />
          ) : (
            <div className='user-pic'>
              {account.Agent?.User?.firstName?.substring(0, 1).toUpperCase()}
              {account.Agent?.User?.lastName?.substring(0, 1).toUpperCase()}
            </div>
          )}
          <span className='tooltiptext tooltip-producer'>
            <span className='tooltipInnertext'>
              {account.Agent?.User?.firstName +
                ' ' +
                account.Agent?.User?.lastName}
            </span>
          </span>
        </div>
        <div className='d-flex'>
          <div
            className='actionButton'
            onClick={() => navigate(`/account/${account.uuid}`)}
          >
            View details
          </div>
          <div className='vector-div'>
            <div
              className='vector'
              onClick={() => setShowDropdown(!showDropdown)}
              ref={dropRef}
            >
              <img src={vector} alt='vector' />
            </div>
            {showDropdown && (
              <div className='dropdown-div'>
                <div
                  className='edit-div'
                  onClick={() => {
                    setShowEditAccountForm('open');
                    setShowDropdown(false);
                  }}
                >
                  Edit <img src={edit} alt='bin' />
                </div>
                <div
                  className='delete-div'
                  onClick={() => {
                    setShowDeleteModal('open');
                    setUUID(account?.uuid);
                    setShowDropdown(false);
                    setName(account?.name);
                  }}
                >
                  Delete <img src={bin} alt='bin' />
                </div>
              </div>
            )}
          </div>
        </div>
        {showEditAccountForm !== 'closed' && (
          <EditAccountDetails
            show={showEditAccountForm}
            closeModal={setShowEditAccountForm}
            uuid={account.uuid}
          />
        )}
      </div>
    </StyledCard>
  );
};

export default AccountMobileCard;
