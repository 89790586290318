import styled from '@emotion/styled';
export const StyledContactModal = styled.div`
  // width: 360px;
  .topdiv {
    width: 100%;
    border-bottom: 1px solid #e6e9f0;
    height: 60px;
  }
  .heading {
    font-size: 20px;
  }
  .entire-div {
    width: 100%;
    padding: 0px 0px;
  }
  .cross-btn {
    position: absolute;
    background: #ffffff;
    right: 14px;
    top: 20px;
    border: none;
  }
  .errorVal {
    margin-top: 12px;
    font-size: 14px;
    color: #cc0000;
    display: flex;
    align-items: center;

    &.emailError {
      background: #f8e3df;
      color: #000;
      border-radius: 8px;
      border-left: solid 8px #cc0000;
      padding: 10px;
      span {
        font-weight: bold;
        text-decoration: underline;
        cursor: pointer;
        max-width: 100%;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
        display: inline-block;
        vertical-align: bottom;
      }
    }
    .email-exist-error {
      max-width: calc(100% - 25px);
    }
  }
  .warning {
    width: 20px;
    margin-right: 10px;
  }
  .textarea {
    width: 89vw;
    margin-top: 16px;
  }
  .addNotesButton {
    margin-top: 40px;
    border: none;
    background: #fff;
  }
  .bigfields {
    width: 100%;
    border-radius: 8px;
    height: 136px;
    padding: 16px 16px;
    max-height: 198px;
    margin: 0 auto;
    border: 1px solid #cad9f3;
    outline: none;
    &:hover {
      border: 1px solid #cad9f3;
      box-shadow: 0px 1px 4px rgba(35, 42, 64, 0.2);
    }

    &:focus,
    &:active {
      border: 1px solid #3c498a;
      outline-offset: 0px;
      outline: none;
    }
  }
  .scroll-bar {
    height: 90vh;
    width: 92vw;
    overflow-y: auto;
    overflow-x: hidden;
    padding-bottom: 185px;
    &::-webkit-scrollbar {
      width: 4px;
      height: 43px;
      margin-right: 10px;
    }

    &::-webkit-scrollbar-track {
      border-radius: 8px;
    }

    &::-webkit-scrollbar-thumb {
      background: #e6e9f0;
    }
  }
  .cross {
    width: 20px;
  }
  .personal {
    width: 56px;
  }
  .commercial {
    width: 92px;
    margin-left: 40px;
  }
  .contact-info {
    left: 49px;
    top: 133.96px;
    color: #000000;
    font-size: 16px;
    line-height: 24px;
    margin-top: 15px;
  }
  .business-info {
    margin-top: 20px;
    color: #000000;
    font-size: 16px;
    line-height: 24px;
  }
  .fields {
    width: 320px;
    margin-top: 16px;
    color: #262758;
    .search-group {
      .input-placeholder {
        left: 16px;
      }
      .input-field {
        padding: 18px 20px 10px 17px;
      }
    }
  }
  .fields::placeholder {
    color: #262758;
  }
  .fields-small {
    width: 320px;
    margin-top: 16px;
  }
  .fields-medium {
    width: 200px;
    margin-top: 16px;
  }
  .fields-xsmall {
    width: 100px;
    margin-top: 16px;
  }
  .small-div {
    width: 320px;
  }
  .buttonsDiv {
    border-top: 1px solid #e6e9f0;
    position: fixed;
    width: 100%;
    bottom: 0;
    left: 0;
    background: #fff;
    z-index: 2;
    padding: 10px 20px;
  }
  .cancel-button {
    height: 24px;
    background: none;
    color: #262758;
    font-weight: 600;
    font-size: 16px;
    margin-left: auto;
    margin-right: 30px;
    border: none;
  }
  .saveButton {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: #262758;
    color: #f5f7fc;
    border-radius: 8px;
    width: 140px;
    height: 48px;
    padding: 0 16px;
    margin-left: 0px;
    &:disabled {
      background: #d1d6e5;
      border: none;
    }
  }
  .navButton {
    position: fixed;
    height: 58px;
    top: 94px;
  }
  .naVigatorbuttons {
    color: #262758;
    background: none;
    border: none;
    cursor: pointer;
  }
  .naVigatorbuttonsActive {
    background: none;
    font-weight: 600;
    border: none;
    border-bottom: 3px solid #262758;
    cursor: pointer;
  }
  .LeadSourcefield {
    font-size: 12px;
    width: 240px;
    height: 32px;
    margin-left: 1px;
    margin-top: 24px;
    margin-bottom: 32px;
  }
  .naVigatorbuttons:hover {
    border-bottom: 3px solid #262758;
  }
  .smallAddbutton {
    margin-right: 10px;
  }

  @media screen and (min-width: 576px) {
    width: 100%;
    .fields {
      width: 420px;
    }
    .scroll-bar {
      width: auto;
      &::-webkit-scrollbar {
        height: 159px;
      }
    }
    .small-div {
      width: 420px;
    }
    .buttonsDiv {
      height: 20px;
      bottom: 0px;
      padding: 38px 54px;
    }

    .topdiv {
      height: 65px;
    }
    .navButton {
      top: 78px;
    }
    .fields-small {
      width: 200px;
    }
    .fields::placeholder {
      color: #262758;
    }
    .fields-xsmall {
      width: 104px;
    }
    .fields-medium {
      width: 240px;
    }
    .cross-btn {
      right: 40px;
      top: 26px;
    }
    .entire-div {
      width: 100%;
      padding: 0px;
    }
    .scroll-bar {
      height: 67vh;
      padding-bottom: 60px;
      padding-right: 7px;
      width: 100%;
    }
    .commercial {
      width: 91px;
      margin-left: 24px;
    }
    .textarea {
      margin-top: 44px;
      width: 100%;
    }
  }
  @media screen and (min-width: 1200px) {
    width: 883px;
    .fields {
      width: 420px;
      margin-top: 16px;
    }
    .bigfields {
      width: 83%;
      height: 136px;
      max-height: 198px;
    }
    .topdiv {
      height: 68px;
    }
    .navButton {
      top: 92px;
    }
    .small-div {
      width: 420px;
    }
    .fields-small {
      width: 200px;
    }
    .fields-medium {
      width: 298px;
      margin-top: 16px;
    }
    .fields-xsmall {
      width: 101px;
      margin-top: 16px;
    }
    .contact-info {
      margin-top: 24px;
    }
    .fields::placeholder {
      color: #262758;
    }
    .cross-btn {
      right: 41px;
      top: 24px;
    }
    .scroll-bar {
      height: 78vh;
      padding-bottom: 80px;

      width: 100%;
    }
    .buttonsDiv {
      width: 100%;
      bottom: 0;
      left: 0;
      z-index: 2;
      padding: 48px 48px;
    }
  }
  @media screen and (min-width: 1900px) {
    width: 1200px;
    .topdiv {
      width: 100%;
      height: 70px;
    }
    .navButton {
      top: 112px;
    }
    .cross {
      width: 32px;
    }
    .personal {
      width: 56px;
    }
    .commercial {
      width: 90px;
      margin-left: 24px;
    }
    .contact-info {
      font-size: 20px;
      line-height: 28px;
      margin-top: 20px;
    }
    .business-info {
      font-size: 20px;
      line-height: 28px;
    }
    .fields {
      width: 576px;
      margin-top: 20px;
    }
    .small-div {
      width: 576px;
    }
    .fields-small {
      width: 278px;
      margin-top: 20px;
    }
    .fields-medium {
      width: 416px;
      margin-top: 20px;
    }
    .fields-xsmall {
      width: 140px;
      margin-top: 20px;
    }
    .fields::placeholder {
      color: #262758;
    }
    .cross-btn {
      right: 60px;
      top: 36px;
    }
    .buttonsDiv {
      bottom: 0;
      padding: 48px 70px;
    }
    .cancel-button {
      height: 24px;
    }
    .saveButton {
      width: 200px;
      height: 64px;
    }
    .heading {
      font-size: 20px;
    }
  }
`;
