import { useEffect, useState } from 'react';
import FilterSelectField from '../../../../components/FilterSelectField';
import Modal from '../../../../components/Modal';
import cross from '../../../../assets/logo/cross.svg';
import { StyledModal } from '../../../../components/FilterDrawer/styles';
import LocalStorageService from '../../../../utils/LocalStorageService';
import { fetchAccounts } from '../../../../api/account';
import { fetchAgents } from '../../../../api/agents';
import { IProducer } from '../../../../interfaces/Agent';
import SearchDropdown from '../../../../components/SearchDropdown';
import { MobileFilterNavDiv } from '../../../../components/FilterMobileDrawer/styles';
import { getOpportunityStatus } from '../../../../api/opportunities';
import {
  IOpportunityStatus,
  IPipeline,
} from '../../../../interfaces/Opportunity';
import { useDispatch } from 'react-redux';
import { AppDispatch } from '../../../../store';
import { getPipelines } from '../../../../store/pipelines/pipelineSlice';
import { IAccount } from '../../../../interfaces/Account';
import { fetchCarriers } from '../../../../api/carriers';
import Skeleton from 'react-loading-skeleton';
import { fetchProducts } from '../../../../api/product';
import { IProduct } from '../../../../interfaces/Product';

const PolicyFilter = ({
  show,
  closeModal,
  filterFunction,
  carrier,
  setProducts,
  setCarrier,
  selectedProducts,
  producer,
  setProducer,
  producerSearch,
  setProducerSearch,
  selectedPolicySoldThrough,
  setSelectedPolicySoldThrough,
  account,
  setAccount,
  carrierSearchText,
  setCarrierSearchText,
  accountSearch,
  setAccountSearch,
  isInnerTab,
  setType,
}: {
  show: string;
  closeModal: Function;
  filterFunction: Function;
  carrier: any;
  selectedProducts: any;
  setProducts: Function;
  setCarrier: Function;
  producer: string;
  setProducer: Function;
  producerSearch: string;
  setProducerSearch: Function;
  setSelectedPolicySoldThrough: Function;
  selectedPolicySoldThrough: any;
  account?: string;
  setAccount?: Function;
  carrierSearchText: string;
  setCarrierSearchText: Function;
  accountSearch: string;
  setAccountSearch: Function;
  isInnerTab?: boolean;
  setType: Function;
}) => {
  const dispatch = useDispatch<AppDispatch>();

  const [product, setProduct] = useState<IProduct[]>([]);

  const [status, setStatus] = useState<IOpportunityStatus[]>([]);

  const onCloseModal = () => {
    closeModal('closing');
    setTimeout(() => {
      closeModal('closed');
    }, 300);
  };
  useEffect(() => {
    getOpportunityStatus((data: IOpportunityStatus[]) => setStatus(data));
    dispatch(
      getPipelines({
        sortBy: '',
        orderBy: '',
        searchBy: '',
        successCB: (data: IPipeline) => {},
        agencyUUID: '',
        productCategoryUUID: '',
      })
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setLoadingStatus('loading');
    fetchProducts(
      (data: IProduct[]) => {
        setProduct(data);
        setLoadingStatus('succeeded');
      },
      '',
      '',
      '',
      '',
      true
    );
  }, []);
  const [producers, setProducers] = useState<IProducer[]>([]);

  const [loadingStatus, setLoadingStatus] = useState('idle');

  const user = LocalStorageService.getUser();

  const loadProducer = (value: string) => {
    if (
      loadingStatus === 'idle' ||
      loadingStatus === 'succeeded' ||
      user?.UserRole?.name === 'PRINCIPAL_AGENT'
    ) {
      // setLoadingStatus('loading');
      fetchAgents(
        (data: IProducer[]) => {
          setProducers(data);
          // setLoadingStatus('succeeded');
        },
        'id',
        'asc',
        value,
        'True'
      );
    }
  };

  const [accounts, setAccounts] = useState<any[]>([]);

  const loadAccount = (value: string) => {
    if (loadingStatus === 'idle' || loadingStatus === 'succeeded') {
      fetchAccounts(
        'name',
        'asc',
        value,
        25,
        0,
        '',
        (data: IAccount[]) => {
          setAccounts(data);
        },
        'True'
      );
    }
  };

  useEffect(() => {
    if (loadingStatus === 'idle' || loadingStatus === 'succeeded') {
      // setLoadingStatus('loading');
      filterFunction();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedProducts, producer, carrier, selectedPolicySoldThrough, account]);

  const [carriers, setCarriers] = useState<any>([]);

  const loadCarrier = (value: string) => {
    if (loadingStatus === 'idle' || loadingStatus === 'succeeded') {
      fetchCarriers('id', 'asc', value, 'True', (data: any) => {
        setCarriers(data);
      });
    }
  };

  const resetCarrier = (value: string) => {
    setCarrier('');
  };

  const policySoldThrough = [
    { label: 'MGA', value: 'MGA' },
    { label: 'Directly appointed carrier', value: 'direct' },
  ];

  const filterContent = () => {
    return (
      <>
        <div>
          <h6 className='head text-uppercase'>Carrier</h6>
        </div>
        {loadingStatus === 'loading' ? (
          <Skeleton height={48} />
        ) : (
          <div className='agency-fields'>
            <SearchDropdown
              name='opportunityCarrierName'
              placeholder='Select'
              isEdit={true}
              values={carrierSearchText}
              searchValue={carriers}
              setSearch={setCarrierSearchText}
              status={loadingStatus}
              className={`${carrierSearchText && 'filled'}`}
              loadFunction={loadCarrier}
              setUUID={resetCarrier}
              content={carriers?.map((item: any) => (
                <div
                  className='searchValuesCard d-flex align-items-center'
                  onClick={() => {
                    setCarrierSearchText(item?.name);
                    setCarrier(item?.uuid);
                    setType(item?.type);
                  }}
                >
                  {item.logo ? (
                    <img src={item.logo} alt='' className='profile-pic' />
                  ) : (
                    <div className='profile-pic d-flex align-items-center justify-content-center'>
                      {item?.name?.charAt(0)}
                      {item?.name?.charAt(0)}
                    </div>
                  )}
                  <div>
                    <h6>{item.name}</h6>
                  </div>
                </div>
              ))}
            />
          </div>
        )}
        <hr className='filter-hr' />
        <div>
          <h6 className='head text-uppercase'>Policy</h6>
        </div>
        {loadingStatus === 'loading' ? (
          <Skeleton height={48} />
        ) : (
          <div className='agency-fields'>
            <FilterSelectField
              options={product.map((item) => {
                return { label: item.name, value: item.uuid, type: item.type };
              })}
              name='policy'
              placeholder='Select'
              isMulti={true}
              onChange={(value: any) => {
                setProducts(value);
              }}
              value={selectedProducts}
            />
          </div>
        )}
        <hr className='filter-hr' />
        <div>
          <h6 className='head text-uppercase'>Policy sold through</h6>
        </div>
        {loadingStatus === 'loading' ? (
          <Skeleton height={48} />
        ) : (
          <div className='agency-fields'>
            <FilterSelectField
              options={policySoldThrough}
              placeholder='Select'
              onChange={(value: any) => {
                setSelectedPolicySoldThrough(value);
              }}
              value={selectedPolicySoldThrough}
            />
          </div>
        )}
        {!isInnerTab && setAccount ? (
          <>
            <hr className='filter-hr' />
            <div>
              <h6 className='head text-uppercase'>Associated account</h6>
            </div>

            {loadingStatus === 'loading' ? (
              <Skeleton height={48} />
            ) : (
              <div className='agency-fields'>
                <SearchDropdown
                  name='account'
                  placeholder='Select'
                  isEdit={true}
                  values={accountSearch}
                  searchValue={accounts}
                  setSearch={setAccountSearch}
                  status={loadingStatus}
                  className={`${accountSearch && 'filled'}`}
                  loadFunction={loadAccount}
                  setUUID={setAccount}
                  content={accounts?.map((item) => (
                    <div
                      className='searchValuesCard d-flex align-items-center'
                      onClick={() => {
                        setAccountSearch(item?.name);
                        setAccount(item?.uuid);
                      }}
                    >
                      <div className='profile-pic d-flex align-items-center justify-content-center'>
                        {item?.name?.charAt(0)}
                      </div>
                      <div>
                        <h6>{item.name}</h6>
                        <ul className='user-details'>
                          <li>
                            {item?.businessEmail ||
                              item?.AccountContacts[0]?.Contact?.ContactDetails?.find(
                                (value: any) => value.type === 'EMAIL'
                              )?.value}
                          </li>
                          <li>
                            {item?.businessPhoneNumber ||
                              item?.AccountContacts[0]?.Contact?.ContactDetails?.find(
                                (value: any) => value.type === 'PHONENUMBER'
                              )?.value}
                          </li>
                        </ul>
                      </div>
                    </div>
                  ))}
                />
              </div>
            )}
          </>
        ) : null}

        {user.UserRole.name === 'PRINCIPAL_AGENT' ? (
          <>
            <hr className='filter-hr' />
            <div>
              <h6 className='head text-uppercase'>Producer</h6>
            </div>
            {loadingStatus === 'loading' ? (
              <Skeleton height={48} />
            ) : (
              <div className='agency-fields'>
                <SearchDropdown
                  name='producer'
                  placeholder='Select'
                  isEdit={true}
                  addName={true}
                  values={producerSearch}
                  searchValue={producers}
                  setSearch={setProducerSearch}
                  status={loadingStatus}
                  className={`${producerSearch && 'filled'}`}
                  loadFunction={loadProducer}
                  setUUID={setProducer}
                  content={producers?.map((item) => (
                    <div
                      className='searchValuesCard d-flex align-items-center'
                      onClick={() => {
                        setProducerSearch(
                          `${item.User.firstName} ${
                            item.User.lastName ? item.User.lastName : ''
                          }`
                        );
                        setProducer(item?.uuid);
                      }}
                    >
                      <div className='profile-pic d-flex align-items-center justify-content-center'>
                        {item?.User?.firstName?.charAt(0)}
                        {item?.User?.lastName?.charAt(0)}
                      </div>
                      <div>
                        <h6>
                          {`${item.User.firstName} ${
                            item.User.lastName ? item.User.lastName : ''
                          }`}
                        </h6>
                        <ul className='user-details'>
                          <li>{item.User?.email}</li>
                          <li>{item?.User?.phoneNumber}</li>
                        </ul>
                      </div>
                    </div>
                  ))}
                />
              </div>
            )}
          </>
        ) : null}
      </>
    );
  };

  return (
    <>
      <div className='d-none d-sm-block'>
        <Modal open={show} align='right'>
          <StyledModal>
            <button className='crossbutton' onClick={() => onCloseModal()}>
              <img src={cross} className='cross' alt='cross-icon' />
            </button>
            <div>
              <h2>Filters</h2>
            </div>
            <div>
              <hr className='filter-hr' />
            </div>
            <div>
              <div>
                {show && (
                  <div>
                    <div className='field-section'>{filterContent()}</div>
                    <div className='buttonsDiv filterButtonContainer d-flex align-items-center justify-content-between'>
                      <div className='filter-count'>
                        {(producer ? 1 : 0) +
                          (selectedPolicySoldThrough?.value ? 1 : 0) +
                          selectedProducts.length +
                          (carrier ? 1 : 0) +
                          (account ? 1 : 0)}{' '}
                        filters added
                      </div>
                      <button
                        type='submit'
                        className='agentButton justify-content-end'
                        onClick={() => {
                          setProducer('');
                          setProducerSearch('');
                          setAccountSearch('');
                          if (setAccount) {
                            setAccount('');
                          }
                          setProducts([]);
                          setCarrier('');
                          setCarrierSearchText('');
                          setSelectedPolicySoldThrough([]);
                        }}
                        disabled={
                          (producer ? 1 : 0) +
                            (selectedPolicySoldThrough?.value ? 1 : 0) +
                            selectedProducts.length +
                            (carrier ? 1 : 0) +
                            (account ? 1 : 0) ===
                          0
                        }
                      >
                        Clear all filters
                      </button>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </StyledModal>
        </Modal>
      </div>
      <div className='d-block d-sm-none'>
        <MobileFilterNavDiv>
          <div className='backgroundQuickAdd'>
            <div className={`quickFormsDropDown closed`}>
              <div className='d-flex  drawerHandle'></div>
              <h2>Filters</h2>
              <button className='crossbutton' onClick={() => onCloseModal()}>
                <img src={cross} className='cross' alt='cross-icon' />
              </button>
              <div className='scroll-filter-mob'>
                {filterContent()}
                <div className='buttonsDiv d-flex align-items-center justify-content-between'>
                  <div className='filter-count'>
                    {(producer ? 1 : 0) +
                      (selectedPolicySoldThrough?.value ? 1 : 0) +
                      selectedProducts.length +
                      (carrier ? 1 : 0) +
                      (account ? 1 : 0)}{' '}
                    filters added
                  </div>
                  <button
                    type='submit'
                    className='agentButton justify-content-end'
                    onClick={() => {
                      setProducer('');
                      setProducerSearch('');
                      setAccountSearch('');
                      if (setAccount) {
                        setAccount('');
                      }
                      setProducts([]);
                      setCarrier('');
                      setCarrierSearchText('');
                      setSelectedPolicySoldThrough([]);
                    }}
                    disabled={
                      (producer ? 1 : 0) +
                        (selectedPolicySoldThrough?.value ? 1 : 0) +
                        selectedProducts.length +
                        (carrier ? 1 : 0) +
                        (account ? 1 : 0) ===
                      0
                    }
                  >
                    Clear all filters
                  </button>
                </div>
              </div>
            </div>
          </div>
        </MobileFilterNavDiv>
      </div>
    </>
  );
};

export default PolicyFilter;
