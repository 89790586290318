import React from 'react';
import { Controller } from 'react-hook-form';
import Select, { components } from 'react-select';

const { ValueContainer, Placeholder } = components;

// @ts-ignore
export const CustomValueContainer = ({ children, ...props }) => {
  return (
    // @ts-ignore
    <ValueContainer {...props}>
      {/*
      // @ts-ignore */}
      <Placeholder {...props} isFocused={props.isFocused}>
        {props.selectProps.placeholder}
      </Placeholder>
      {React.Children.map(children, (child) =>
        child && child.type !== Placeholder ? child : null
      )}
    </ValueContainer>
  );
};
interface IProps {
  [x: string]: any;
  placeholder: string;
  options: any[];
  value?: any;
}

export const customSelectStyles = {
  option: (provided: object, state: any) => ({
    ...provided,
    color: state.isDisabled ? '#8990a3' : '#262758',
    fontWeight: state.isSelected ? '600' : '400',
    background: '#fff',
    fontSize: '16px',
    borderTop: state.isDisabled ? ' 1px solid #E6E9F0' : 'none',
    paddingLeft: '16px',
  }),
  menu: (provided: object, state: any) => ({
    ...provided,
    borderRadius: '8px',
    padding: '4px 0',
    boxShadow: '0px 2px 8px 0px #26275833',
    border: state.isSelected ? '#3c498a' : '#cad9f3',
    '& :first-child': {
      borderTop: 'none',
    },
    maxHeight: '300px',
    overflow: 'auto',
  }),
  dropdownIndicator: (base: any, state: any) => ({
    ...base,
    transform: state.isFocused ? 'rotate(180deg)' : null,
    display: state.isDisabled
      ? 'block'
      : (state.hasValue || state.selectProps.inputValue) && 'none',
  }),
  indicatorSeparator: () => ({ display: 'none' }),
  control: (provided: object, state: any) => ({
    ...provided,
    backgroundColor: state.isDisabled ? '#D1D6E5' : '#fff',
    color: state.isDisabled ? '#8990A3' : '#262758',
    '&:hover': {
      boxShadow: '0px 1px 4px rgba(35, 42, 64, 0.2)',
      borderRadius: '8px',
    },
    display: 'flex',
    boxShadow: 'none',
    border: state.isFocused
      ? '1px solid #3c498a'
      : state.hasValue || state.selectProps.inputValue
      ? '1px solid #262758'
      : '1px solid #cad9f3',
    borderRadius: '8px',
    height: 'auto',
    '@media only screen and (min-width: 1900px)': {
      minHeight: '64px',
    },
    '@media only screen and (min-width: 2800px)': {
      minHeight: '128px',
    },
  }),
  singleValue: (provided: any, state: any) => {
    return {
      ...provided,
      paddingTop: (state.hasValue || state.selectProps.inputValue) && '8px',
      fontWeight: 600,
    };
  },
  valueContainer: (provided: any, state: any) => ({
    ...provided,
    overflow: 'visible',
    paddingTop: state.hasValue || state.selectProps.inputValue ? '12px' : '4px',
  }),
  multiValue: (provided: any, state: any) => ({
    ...provided,
    background: 'transparent',
    fontWeight: '700',
  }),

  placeholder: (provided: any, state: any) => ({
    ...provided,
    position: 'absolute',
    color: state.isDisabled ? '#8990A3' : '#262758',
    top: state.hasValue || state.selectProps.inputValue ? '1px' : '',
    transition: 'top 0.1s, font-size 0.1s',
    fontWeight: state.hasValue || state.selectProps.inputValue ? 400 : 600,
    fontSize: (state.hasValue || state.selectProps.inputValue) && 12,
    marginLeft: '8px',
  }),
};

const PolicyTypeSelect = ({
  name,
  placeholder,
  options,
  control,
  disabled,
  inlineField,
  ...props
}: IProps) => {
  return (
    <Controller
      control={control}
      name={name}
      render={({ field }) => (
        <Select
          styles={customSelectStyles}
          options={options}
          placeholder={placeholder}
          isDisabled={disabled}
          hideSelectedOptions={false}
          components={{
            ValueContainer: CustomValueContainer,
          }}
          isClearable
          {...props}
          {...field}
        />
      )}
    />
  );
};

export default PolicyTypeSelect;
