import { useRef, useState } from 'react';
import { LeadSourceDiv } from './styles';
import dropdown from '../../assets/logo/dropdown.svg';
import { useOutsideClick } from '../../hooks/useOutsideClick';
import { ILeadSource, ILeadSourceType } from '../../interfaces/Lead';
export interface IProps {
  leadSource?: ILeadSource;
  setLeadSource: Function;
  sourceTypes: ILeadSourceType[];
}
const LeadSourceField = ({
  leadSource,
  setLeadSource,
  sourceTypes,
}: IProps) => {
  const [clicked, isClicked] = useState(false);

  const dropRef = useRef(null);

  useOutsideClick(dropRef, isClicked);

  return (
    <div>
      <LeadSourceDiv>
        <div
          className='leadSource d-flex justify-content-between'
          onClick={() => isClicked(true)}
          // onBlur={() => isClicked(false)}
          ref={dropRef}
        >
          Lead Source: {leadSource?.name}
          <img src={dropdown} alt='drop' />
        </div>
        {clicked ? (
          <div className='dropDown'>
            {sourceTypes.map((item) => {
              return (
                <>
                  <h6 className='otherHead'>{item.name}</h6>
                  {item.LeadSources.map((val) => (
                    <div
                      className={
                        leadSource?.name === val.name
                          ? 'inputsoption highlighselected'
                          : 'inputsoption'
                      }
                      onClick={() => {
                        setLeadSource(val);
                        isClicked(false);
                      }}
                      key={val.name}
                    >
                      {val.name}
                    </div>
                  ))}
                  <hr />
                </>
              );
            })}
          </div>
        ) : null}
      </LeadSourceDiv>
    </div>
  );
};
export default LeadSourceField;
