import { useState } from 'react';
import SelectField from '../../../../components/CustomSelectField';
import {
  StyledModal,
  StyledModalMobile,
  // StyledModalMobile,
} from '../../../Pipeline/elements/styles';
import cross from '../../../../assets/logo/cross.svg';
import arrow from '../../../../assets/logo/right-arrow.svg';
import warning from '../../../../assets/logo/warning.svg';
import ErrorCard from '../../../Pipeline/components/ErrorCard';
const DeleteUserModal = ({
  showModal,
  closeModal,
  agents,
  deletingUser,
  onDelete,
  showSuccess,
  setShowSuccess,
  deleteErrorMessage,
  setDeleteErrorMessage,
}: {
  showModal: string;
  closeModal: Function;
  agents: any;
  deletingUser: any;
  onDelete: Function;
  setShowSuccess: Function;
  showSuccess: boolean;
  deleteErrorMessage: string;
  setDeleteErrorMessage: Function;
}) => {
  const [userUUID, setUserUUID] = useState<any>({ userUUID: '' });
  const [errorMessege, setErrorMessege] = useState('');
  const [isValidated, setIsValidated] = useState(false);
  // const loadingStatus = useSelector(
  //   (state: { locations: LocationSliceState }) => state.locations.status
  // );

  const onSetItem = (name: string, value: string) => {
    setUserUUID({ userUUID: value });
    setIsValidated(true);
    setErrorMessege('');
    setDeleteErrorMessage('');
  };

  let userName = {
    deleteUser: deletingUser?.uuid,
  };

  const onDeleteUser = (uuid: string, userUUID: string) => {
    if (isValidated && userUUID) {
      onDelete(uuid, userUUID);
    } else {
      setErrorMessege('please select a User to delete');
    }
  };
  return (
    <div>
      {showSuccess && (
        <ErrorCard
          message={`<b>${deletingUser?.User?.firstName} ${deletingUser?.User?.lastName}</b> is <b> deleted</b> `}
          show='open'
        />
      )}{' '}
      <StyledModal
        show={showModal !== 'closed'}
        centered
        dialogClassName='DeleteModalContainer'
        className='d-none d-sm-block'
      >
        <div>
          <img
            alt=''
            src={cross}
            className='closeButton'
            onClick={() => {
              closeModal('closed');
              setDeleteErrorMessage('');
              setErrorMessege('');
            }}
          />
          <h2>Delete this user?</h2>
          <div className='sub-heading'>
            There are some opportunities, policies and quotes associated to this
            user. Please move these entities to be associated to a different
            user.
          </div>
          <div className='fields'>
            <SelectField
              options={agents.map((item: any) => {
                return {
                  value: item?.uuid,
                  label: item?.User?.firstName,
                };
              })}
              placeholder='From User'
              name='deleteUser'
              disabled
              values={userName}
              onSetItem={onSetItem}
            />
          </div>

          <div className='fields'>
            <SelectField
              options={agents
                .filter((item: any) => item?.uuid !== deletingUser?.uuid)
                .map((item: any) => {
                  return {
                    value: item?.uuid,
                    label: item?.User?.firstName,
                  };
                })}
              placeholder='To User'
              name='userUUID'
              values={userUUID}
              onSetItem={onSetItem}
            />
            {errorMessege.trim() ? (
              <div className='errorVal d-flex align-items-center mt-1 '>
                <img src={warning} alt='warning' className='warning me-2' />
                {errorMessege}
              </div>
            ) : null}
            {deleteErrorMessage ? (
              <div className='errorVal d-flex align-items-center mt-1 '>
                <img src={warning} alt='warning' className='warning me-2' />
                {deleteErrorMessage}
              </div>
            ) : null}
          </div>
          <div className='buttonsDiv d-flex align-items-center justify-content-end'>
            <button
              onClick={() => {
                closeModal('closed');
                setDeleteErrorMessage('');
                setErrorMessege('');
              }}
              className='secondaryButton'
              type='button'
            >
              Cancel
            </button>
            <button
              className='DeleteButton'
              onClick={() => {
                onDeleteUser(deletingUser?.uuid, userUUID.userUUID);
              }}
            >
              Move and Delete
              <img className='arrowl' src={arrow} alt='arrow-function' />
            </button>
          </div>
        </div>
      </StyledModal>
      <StyledModalMobile show={showModal !== 'closed'} className=' d-sm-none'>
        <div>
          <img
            alt=''
            src={cross}
            className='closeButton'
            onClick={() => {
              closeModal('closed');
              setDeleteErrorMessage('');
              setErrorMessege('');
            }}
          />
          <h2>Delete this user?</h2>
          <div className='sub-heading'>
            There are some opportunities, policies and quotes associated to this
            user. Please move these entities to be associated to a different
            user.
          </div>
          <div className='fields'>
            <SelectField
              options={agents.map((item: any) => {
                return {
                  value: item?.uuid,
                  label: item?.User?.firstName,
                };
              })}
              placeholder='From User'
              name='deleteUser'
              disabled
              values={userName}
              onSetItem={onSetItem}
            />
          </div>

          <div className='fields'>
            <SelectField
              options={agents
                .filter((item: any) => item?.uuid !== deletingUser?.uuid)
                .map((item: any) => {
                  return {
                    value: item?.uuid,
                    label: item?.User?.firstName,
                  };
                })}
              placeholder='To User'
              name='userUUID'
              values={userUUID}
              onSetItem={onSetItem}
            />
            {errorMessege.trim() ? (
              <div className='errorVal d-flex align-items-center mt-1 '>
                <img src={warning} alt='warning' className='warning me-2' />
                {errorMessege}
              </div>
            ) : null}
            {deleteErrorMessage ? (
              <div className='errorVal d-flex align-items-center mt-1 '>
                <img src={warning} alt='warning' className='warning me-2' />
                {deleteErrorMessage}
              </div>
            ) : null}
          </div>
          <div className='buttonsDiv d-flex align-items-center justify-content-end'>
            <button
              onClick={() => {
                closeModal('closed');
                setDeleteErrorMessage('');
                setErrorMessege('');
              }}
              className='secondaryButton'
              type='button'
            >
              Cancel
            </button>
            <button
              className='DeleteButton'
              onClick={() => {
                onDeleteUser(deletingUser?.uuid, userUUID.userUUID);
              }}
            >
              Move and Delete
              <img className='arrowl' src={arrow} alt='arrow-function' />
            </button>
          </div>
        </div>
      </StyledModalMobile>
    </div>
  );
};

export default DeleteUserModal;
