import axios from 'axios';
import { IRegisterUser } from '../interfaces/User';
import LocalStorageService from '../utils/LocalStorageService';

const instance = axios.create({
  baseURL: process.env.REACT_APP_BASEURL,
});

const refreshToken = LocalStorageService.getRefreshToken();

export interface IToken {
  access_token: string;
  refresh_token: string;
}

const login = (
  email: string,
  password: string,
  successCB: Function,
  errorCB: Function
) => {
  return instance
    .post(`/auth/login`, {
      email,
      password,
    })
    .then(
      (res) => {
        successCB(res.data);
        return res;
      },
      (error) => {
        errorCB(error.response);
        return error.response;
      }
    );
};

const refresh = () => {
  if (refreshToken) {
    instance
      .post(`/auth/token`, { refreshToken })
      .then((res) => {
        if (res.status === 200) {
          const { access_token, refresh_token } = res.data;
          LocalStorageService.setToken(access_token, refresh_token);
        }
      })
      .catch((error) => {
        LocalStorageService.clearToken();
        window.location.href = '/';
      });
  }
};

const register = (
  data: IRegisterUser,
  successCB: Function,
  errorCB: Function
) => {
  return instance.patch('/auth/user', data).then(
    (res) => {
      successCB(res);
      return res;
    },
    (error) => {
      errorCB('It seems that your link has expired or invalid');
      return error.response;
    }
  );
};

const resetPassword = (
  email: string,
  successCB: Function,
  errorCB: Function
) => {
  return instance.post('/auth/reset-password', { email }).then(
    () => successCB(),
    (error) => {
      errorCB('Please try again');
      return error.response;
    }
  );
};

const changePassword = (
  data: {
    password: string;
    code: string;
  },
  successCB: Function,
  errorCB: Function
) => {
  return instance.post('/auth/change-password', data).then(
    () => successCB(),
    (error) => {
      errorCB('It seems that your link has expired or invalid');
      return error.response;
    }
  );
};

export { login, register, refresh, resetPassword, changePassword };
