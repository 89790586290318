import { useState, useEffect, useRef } from 'react';
import Skeleton from 'react-loading-skeleton';
import moment from 'moment';
import {
  selectAllActivities,
  getActivities,
  TaskSliceState,
  clearActivities,
} from '../../store/tasks/tasksSlice';
import { fetchAgents } from '../../api/agents';
import { fetchContacts } from '../../api/contact';
import { fetchEventType } from '../../api/note';
import plus from '../../assets/logo/blackplus.svg';
import whitePlus from '../../assets/logo/plus.svg';
import DocumentAddModal from './components/DocumentAddModal';
import filter from '../../assets/logo/filter.svg';
import search from '../../assets/logo/search.svg';
import { IProducer } from '../../interfaces/Agent';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch } from '../../store';
import LocalStorageService from '../../utils/LocalStorageService';
import MobileTabNav from '../../components/MobileTabNav';
import {
  fetchTaskPriority,
  fetchTaskStatus,
  fetchTaskType,
} from '../../api/task';
import { useOutsideClick } from '../../hooks/useOutsideClick';
import {
  StyledTab,
  FieldComponents,
  ActivitiesStyledDiv,
  StyledDiv,
} from './styles';
import {
  deleteAnEvent,
  deleteATask,
  deleteNote,
} from '../../store/tasks/tasksSlice';
import { getCount as accountCount } from '../../store/accounts/accountsSlice';
import { getCount as contactCount } from '../../store/contacts/contactsSlice';
import { getCount as opportunityCount } from '../../store/opportunities/opportunitiesSlice';
import { getCount as policyCount } from '../../store/policies/policiesSlice';
import { getLeadCount } from '../../store/leads/leadsSlice';
import MarkdownEditor from '../MarkdownEditor';
import ActionsDropdown from './components/dropdown';
import Task from './components/task';
import NotesMobile from './components/NotesMobile';
import TaskDetail from './components/taskDetails';
import { FilterContainer } from '../../pages/MyBook/elements/styles';
import EventFilter from '../../pages/Activities/component/FilterDrawer';
import close from '../../assets/logo/cross.svg';
import useDebounce from '../../hooks/useDebounce';
import Events from './components/Events';
import EventDetails from './components/EventDetails';
import NotesContainer from '../NotesContainer';
import SuccessCard from '../../pages/Pipeline/components/SuccessCard';
import WarningCard from '../../pages/Pipeline/components/WarningCard';
import ErrorCard from '../../pages/Pipeline/components/ErrorCard';
import warning from '../../assets/logo/warning.svg';

const TasksTab = ({
  addNote,
  addNoteLoading,
  addTask,
  addEvent,
  name,
  uuid,
  isMobile,
  setFile,
  file,
  updateNote,
  docType,
  setDocType,
  setDocTypeUUID,
  ApiSuccess,
  docTypeUUID,
  added,
  edited,
  setEdited,
}: {
  addNote: Function;
  updateNote: Function;
  docType: string;
  setDocTypeUUID: Function;
  setFile: Function;
  file: any;
  docTypeUUID: string;
  setDocType: Function;
  addNoteLoading: boolean;
  addTask: Function;
  ApiSuccess: boolean;
  addEvent: Function;
  name: string;
  uuid?: string;
  isMobile?: boolean;
  added: string;
  edited: string;
  setEdited: Function;
}) => {
  const dispatch = useDispatch<AppDispatch>();
  const allActivities = useSelector(selectAllActivities);
  const loadingStatus = useSelector(
    (state: { tasks: TaskSliceState }) => state.tasks.status
  );
  const notesOffset = useSelector(
    (state: { tasks: TaskSliceState }) => state.tasks.notesOffset
  );
  const tasksOffset = useSelector(
    (state: { tasks: TaskSliceState }) => state.tasks.tasksOffset
  );
  const eventsOffset = useSelector(
    (state: { tasks: TaskSliceState }) => state.tasks.eventsOffset
  );

  const [producers, setProducers] = useState<IProducer[]>([]);
  const [tasks, setTasks] = useState<{
    description?: string;
    taskStatusUUID?: string;
    assigneeUUID?: string;
  }>({});
  const [events, setEvent] = useState<any>({});

  const onItem = (
    name: 'description' | 'taskStatusUUID' | 'assigneeUUID',
    value: string
  ) => {
    let updatedTask = { ...tasks };
    updatedTask[name] = value;
    setTasks(updatedTask);
  };

  const onSetEvent = (name: string, value: string) => {
    let updatedEvent = { ...events };
    updatedEvent[name] = value;
    setEvent(updatedEvent);
  };

  const [taskType, setTaskType] = useState([]);
  const [eventType, setEventType] = useState([]);
  const [taskStatusUUID, setTaskStatusUUID] = useState('');
  const [taskPriority, setTaskPriority] = useState([]);
  const [assignee, setAssignee] = useState<any>({});
  const [guest, setGuest] = useState<any>([]);
  const [guestList, showGuestList] = useState(false);
  const [guests, setGuests] = useState<any[]>([]);
  const [guestUUID, setGuestUUID] = useState<any>([]);
  const [addNotesMobile, setAddNoteMobile] = useState(false);
  const [addButton, setaddButton] = useState(false);
  const [isTaskMobile, setIsTaskMobile] = useState(false);
  const [isEventMobile, setIsEventMobile] = useState(false);
  const [startDate, setStartDate] = useState<any>(new Date());
  const [endDate, setEndDate] = useState(null);
  const [editIndex, setEditIndex] = useState(-1);
  const [deleted, setDeleted] = useState('');
  const [showModal, setShowModal] = useState('closed');
  const [noteError, setNoteError] = useState('');
  const [textCheck, setTextCheck] = useState('');

  let user = LocalStorageService.getUser();
  useEffect(() => {
    /* To Do
      -------
      Prevent the same API call multiple times.
    */
    if (user?.UserRole.name === 'AGENT') {
      setAssignee({ User: user });
    } else {
      fetchAgents(
        (data: IProducer[]) => {
          setProducers(data);
          setAssignee(data[0]);
          onItem('assigneeUUID', data[0]?.User?.uuid);
        },
        'id',
        'asc',
        '',
        'True'
      );
    }
    fetchTaskType((data: any) => {
      setTaskType(data);
    });
    fetchTaskPriority((data: any) => {
      setTaskPriority(data);
    });
    fetchTaskStatus((data: any) => {
      const status = data.find((item: any) => item.name === 'OPEN');
      setTaskStatusUUID(status.uuid);
    });
    fetchContacts('id', 'asc', '', 25, 0, 'True', (data: IProducer[]) => {
      setGuests(data);
    });
    fetchEventType((data: any) => {
      setEventType(data);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const [notes, setNotes] = useState(true);
  const [task, setTask] = useState(false);
  const [event, setEvents] = useState(false);
  const [note, setNote] = useState('');

  const onSubmitNote = () => {
    let canSubmit = true;
    if (note) {
      if (!textCheck.trim()) {
        canSubmit = false;
        setNoteError('Note cannot be blank space');
      }
    }
    if (canSubmit) {
      addNote({ text: note.slice(0, -1) });
    }
  };

  const handleTaskSubmit = () => {
    tasks.taskStatusUUID = taskStatusUUID;
    console.log(tasks);
    addTask(tasks);
  };

  useEffect(() => {
    if (addNoteLoading) {
      setNote('');
      setTasks({
        description: '',
        assigneeUUID: assignee?.User?.uuid,
      });
      setEvent({
        text: '',
        name: '',
        description: '',
      });
      setFile('');
      setDocTypeUUID('');
      setDocType('');
      setGuest([]);
      setStartDate('');
      setEndDate(null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [addNoteLoading]);

  const handleEventSubmit = () => {
    events.guests = guestUUID.map((item: any) => ({ guestUUID: item }));
    events.dueDate = events.scheduledDate[1];
    events.scheduledDate = events.scheduledDate[0];
    if (events.guests && events.dueDate && events.scheduledDate) {
      addEvent(events);
    }
  };

  useEffect(() => {
    setFile('');
    setDocTypeUUID('');
    setDocType('');
  }, [task, event, note]);

  const dropRef = useRef(null);
  useOutsideClick(dropRef, showGuestList);
  const addRef = useRef(null);
  useOutsideClick(addRef, setaddButton);

  const onScrollActivities = (event: any) => {
    const { scrollTop, clientHeight, scrollHeight } = event.currentTarget;
    if (
      Math.round(scrollHeight - scrollTop) <= Math.round(clientHeight) &&
      loadingStatus !== 'loading' &&
      uuid
    ) {
      const uuidName = `${name.toLowerCase()}UUID`;

      dispatch(
        getActivities({
          uuidName,
          uuid,
          showAll: 'true',
          successCB: () => {},
          tasksOffset,
          notesOffset,
          eventsOffset,
          eventType: eventTypeFil?.map((item: any) => item.value),
          taskType: taskTypeFil?.map((item: any) => item.value),
          taskStatus: taskStatusValue?.map((item: any) => item.value),
          taskPriority: taskPriorityFil?.map((item: any) => item.value),
          assignee: assigneeFil?.value,
          searchBy,
        })
      );
    }
  };

  const handleDelete = (UUID: string) => {
    setDeleted('Event');
    dispatch(
      deleteAnEvent({
        uuid: UUID,
        successCB: () => {
          if (name === 'Account' && uuid) {
            dispatch(accountCount({ uuid: uuid }));
          } else if (name === 'Opportunity' && uuid) {
            dispatch(opportunityCount({ uuid: uuid }));
          } else if (name === 'Policy' && uuid) {
            dispatch(policyCount({ uuid: uuid }));
          } else if (name === 'Contact' && uuid) {
            dispatch(contactCount({ uuid: uuid }));
          } else if (name === 'Lead' && uuid) {
            dispatch(getLeadCount({ uuid: uuid }));
          }
          setTimeout(() => {
            setDeleted('');
          }, 2500);
        },
      })
    );
  };

  const handleDeleteNote = (UUID: string) => {
    setDeleted('Note');
    dispatch(
      deleteNote({
        uuid: UUID,
        successCB: () => {
          if (name === 'Account' && uuid) {
            dispatch(accountCount({ uuid: uuid }));
          } else if (name === 'Opportunity' && uuid) {
            dispatch(opportunityCount({ uuid: uuid }));
          } else if (name === 'Policy' && uuid) {
            dispatch(policyCount({ uuid: uuid }));
          } else if (name === 'Contact' && uuid) {
            dispatch(contactCount({ uuid: uuid }));
          } else if (name === 'Lead' && uuid) {
            dispatch(getLeadCount({ uuid: uuid }));
          }
          setTimeout(() => {
            setDeleted('');
          }, 2500);
        },
      })
    );
  };

  const handleDeleteTask = (UUID: string) => {
    setDeleted('Task');
    dispatch(
      deleteATask({
        uuid: UUID,
        successCB: () => {
          if (name === 'Account' && uuid) {
            dispatch(accountCount({ uuid: uuid }));
          } else if (name === 'Opportunity' && uuid) {
            dispatch(opportunityCount({ uuid: uuid }));
          } else if (name === 'Policy' && uuid) {
            dispatch(policyCount({ uuid: uuid }));
          } else if (name === 'Contact' && uuid) {
            dispatch(contactCount({ uuid: uuid }));
          } else if (name === 'Lead' && uuid) {
            dispatch(getLeadCount({ uuid: uuid }));
          }
          setTimeout(() => {
            setDeleted('');
          }, 2500);
        },
      })
    );
  };

  const handleEditNotes = (uuid: string, item: any, index: any) => {
    setEditIndex(index);
  };

  const [showFilter, setShowFilter] = useState('closed');

  const [eventTypeFil, setEventTypeFil] = useState<any>([]);

  const [taskStatusValue, setTaskStatusValue] = useState([]);
  const [taskTypeFil, setTaskTypeFil] = useState([]);

  const [taskPriorityFil, setTaskPriorityFil] = useState([]);
  const [assigneeFil, setAssigneeFil] = useState<any>('');

  const filterEvent = () => {
    const uuidName = `${name.toLowerCase()}UUID`;
    dispatch(clearActivities());
    dispatch(
      getActivities({
        uuidName,
        uuid: uuid || '',
        showAll: 'true',
        successCB: () => {},
        tasksOffset: 0,
        notesOffset: 0,
        eventsOffset: 0,
        eventType: eventTypeFil?.map((item: any) => item.value),
        taskType: taskTypeFil?.map((item: any) => item.value),
        taskStatus: taskStatusValue?.map((item: any) => item.value),
        taskPriority: taskPriorityFil?.map((item: any) => item.value),
        assignee: assigneeFil?.value,
        searchBy,
      })
    );
  };

  const [isSearchClicked, setIsSearchClicked] = useState(false);
  const [searchBy, setSearchBy] = useState('');
  const debouncedSearchTerm = useDebounce(searchBy, 500);

  useEffect(() => {
    if (!isMobile) {
      filterEvent();
    } // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedSearchTerm]);

  useEffect(() => {
    if (note) {
      if (textCheck) {
        setNoteError('');
      }
    }
  }, [note]);

  return (
    <StyledDiv className={`${name === 'Lead' ? 'lead-activity' : ''}`}>
      {added && <SuccessCard message={`<b>${added}</b>  Added `} show='open' />}
      {edited && (
        <WarningCard message={`<b>${edited}</b>  Updated `} show='open' />
      )}
      {deleted && (
        <ErrorCard message={`<b>${deleted}</b> Deleted `} show='open' />
      )}
      <div className='scrollActivity'>
        <div className='activities-div d-none d-sm-block'>
          <StyledTab className='px-4 d-none d-sm-flex'>
            <div
              className={`navItems ${notes ? 'active' : ''}`}
              onClick={(e) => {
                setNotes(true);
                setTask(false);
                setEvents(false);
              }}
            >
              Add Note
            </div>
            <div
              className={`navItems ${task ? 'active' : ''}`}
              onClick={(e) => {
                setTask(true);
                setEvents(false);
                setNotes(false);
              }}
            >
              Add Task
            </div>
            <div
              className={`navItems ${event ? 'active' : ''}`}
              onClick={(e) => {
                setEvents(true);
                setTask(false);
                setNotes(false);
              }}
            >
              Add Event
            </div>
          </StyledTab>
          <FieldComponents>
            {notes && (
              <>
                <div className='tab-content-div'>
                  <div className='fields'>
                    {addNoteLoading ? (
                      <Skeleton height={150} />
                    ) : (
                      <MarkdownEditor
                        placeholder='Write a note here...'
                        note={editIndex === -1 ? note : ''}
                        setNote={setNote}
                        textCheckFunction={setTextCheck}
                      />
                    )}
                  </div>
                  {noteError && (
                    <div className='errorNoteDiv d-flex align-items-center'>
                      <span className='errorVal'>
                        <img className='erricon' src={warning} alt='' />
                        {noteError}
                      </span>
                    </div>
                  )}
                </div>
                <div className='addNoteButtonContainer'>
                  <button
                    className='tertiaryButton'
                    type='button'
                    disabled={
                      note.trim() === '' || editIndex !== -1 || addNoteLoading
                    }
                    onClick={() => onSubmitNote()}
                  >
                    Add Note
                  </button>
                </div>
              </>
            )}
            {task && (
              <Task
                handleTaskSubmit={handleTaskSubmit}
                setFile={setFile}
                file={file}
                setDocType={setDocType}
                docType={docType}
                docTypeUUID={docTypeUUID}
                setDocTypeUUID={setDocTypeUUID}
                taskType={taskType}
                taskPriority={taskPriority}
                assignee={assignee}
                setAssignee={setAssignee}
                producers={producers}
                onItem={onItem}
                ApiSuccess={ApiSuccess}
                tasks={tasks}
                addNoteLoading={addNoteLoading}
              />
            )}

            {event && (
              <Events
                guest={guest}
                guestList={guestList}
                guests={guests}
                onSetEvent={onSetEvent}
                eventType={eventType}
                events={events}
                setStartDate={setStartDate}
                setEndDate={setEndDate}
                endDate={endDate}
                startDate={startDate}
                handleEventSubmit={handleEventSubmit}
                ApiSuccess={ApiSuccess}
                setShowModal={setShowModal}
                file={file}
                setGuestUUID={setGuestUUID}
                guestUUID={guestUUID}
                setGuest={setGuest}
                showGuestList={showGuestList}
              />
            )}
          </FieldComponents>
        </div>
        <ActivitiesStyledDiv>
          <div className='timeline'>
            <div className={!isMobile ? 'card' : ''}>
              <div className='outer'></div>
              <div className='d-flex justify-content-between align-items-center'>
                <h2 className='titleHead'>All Activities</h2>
                <div className='d-none d-sm-flex justify-content-between'>
                  <div className='book-filter d-flex'>
                    {isSearchClicked || searchBy ? (
                      <div className='book-search d-flex align-items-center'>
                        <img src={search} alt='' className='search-button' />
                        <input
                          onChange={(e) => {
                            setSearchBy(e.target.value);
                          }}
                          autoFocus
                          value={searchBy}
                        />
                        <img
                          src={close}
                          alt=''
                          className='close-button'
                          onClick={() => {
                            setSearchBy('');
                            setIsSearchClicked(false);
                          }}
                        />
                      </div>
                    ) : (
                      <div
                        className='book-shrinked'
                        onClick={() => setIsSearchClicked(true)}
                      >
                        <img src={search} alt='' />
                      </div>
                    )}
                    <div
                      className='filter-button d-flex align-items-center justify-content-center'
                      onClick={() => setShowFilter('open')}
                    >
                      Filter by:{' '}
                      <b style={{ fontWeight: 600 }}>
                        {(eventTypeFil?.length || 0) +
                          (taskPriorityFil?.length || 0) +
                          (taskTypeFil?.length || 0) +
                          (taskStatusValue?.length || 0) +
                          (assigneeFil?.value ? 1 : 0) ===
                        0
                          ? 'No'
                          : (eventTypeFil?.length || 0) +
                            (taskPriorityFil?.length || 0) +
                            (taskTypeFil?.length || 0) +
                            (taskStatusValue?.length || 0) +
                            (assigneeFil?.value ? 1 : 0)}{' '}
                        filter
                      </b>
                    </div>
                  </div>
                </div>
                <div className='d-flex d-sm-none'>
                  <img src={search} alt='' className='search' />
                  <span className={`divider`} />
                  <div
                    className='d-flex align-items-center position-relative'
                    onClick={() => setShowFilter('open')}
                  >
                    <img src={filter} alt='' className='filter' />
                    {(eventTypeFil?.length || 0) +
                      (taskPriorityFil?.length || 0) +
                      (taskTypeFil?.length || 0) +
                      (taskStatusValue?.length || 0) +
                      (assigneeFil?.value ? 1 : 0) >
                      0 && (
                      <span className='filter-counter'>
                        {(eventTypeFil?.length || 0) +
                          (taskPriorityFil?.length || 0) +
                          (taskTypeFil?.length || 0) +
                          (taskStatusValue?.length || 0) +
                          (assigneeFil?.value ? 1 : 0)}
                      </span>
                    )}
                  </div>
                </div>
              </div>
              {addButton && <div className='addButton-background' />}
              <button
                onClick={() => setaddButton(!addButton)}
                className={`addbutton-mob d-sm-none ${
                  addButton ? 'dropOpen' : ''
                }`}
                ref={addRef}
              >
                {addButton && (
                  <div className='dropUp'>
                    <ul
                      className='tasks d-flex justify-content-between w-100 '
                      onClick={() => {
                        setaddButton(false);
                        setAddNoteMobile(true);
                      }}
                    >
                      Add Notes <img src={plus} alt='' />
                    </ul>
                    <ul
                      className='tasks d-flex justify-content-between w-100 '
                      onClick={() => {
                        setaddButton(false);
                        setAddNoteMobile(true);
                        setIsTaskMobile(true);
                        setIsEventMobile(false);
                      }}
                    >
                      Add Task <img src={plus} alt='' />
                    </ul>
                    <ul
                      className='tasks d-flex  justify-content-between w-100'
                      onClick={() => {
                        setaddButton(false);
                        setAddNoteMobile(true);
                        setIsEventMobile(true);
                        setIsTaskMobile(false);
                      }}
                    >
                      Add Event <img src={plus} alt='' />
                    </ul>
                  </div>
                )}
                <img src={whitePlus} alt='add' className='addButton-img' />
              </button>
            </div>

            <div className='activity-scroll' onScroll={onScrollActivities}>
              {allActivities?.map((item, index) => (
                <div className='card' key={index}>
                  <div className='outer'> </div>

                  {item?.type === 'NOTE' ? (
                    <div className='info '>
                      <div className='d-flex imageCard align-items-center '>
                        <div
                          className={`profile-pic d-none  d-sm-flex align-items-center justify-content-center`}
                        >
                          <div className='profile'>
                            {item?.data?.CreatedBy?.firstName.charAt(0)}
                          </div>
                        </div>

                        <span className=' d-none d-sm-flex'>
                          <h2 className='title Heading '>
                            {item?.data?.CreatedBy?.firstName}{' '}
                            {item?.data?.CreatedBy?.lastName}
                          </h2>
                          <h2 className=' title added '>added a Note</h2>
                          <h6 className=' title dateFont '>
                            on{' '}
                            {moment(item?.data?.createdAt).format(
                              'Do MMMM YYYY, h:mm A'
                            )}
                          </h6>
                        </span>
                        <p className=' d-sm-none'>
                          {' '}
                          <b className='title'>
                            {item?.data?.CreatedBy?.firstName}{' '}
                            {item?.data?.CreatedBy?.lastName}
                          </b>
                          <span className=' title added '>
                            added a Note on{' '}
                          </span>
                          <span className=' title dateFont '>
                            {' '}
                            {moment(item?.data?.createdAt).format(
                              'Do MMMM YYYY, h:mm A'
                            )}{' '}
                          </span>
                        </p>
                      </div>

                      {editIndex !== index ? (
                        <div className='contentDiv d-flex justify-content-between align-items-center'>
                          <NotesContainer text={item.data.text} />
                          <div className='d-flex event-button-container'>
                            <ActionsDropdown
                              uuid={item.data.uuid}
                              handleDelete={handleDeleteNote}
                              handleEdit={handleEditNotes}
                              index={index}
                            />
                          </div>
                        </div>
                      ) : (
                        <FieldComponents>
                          <>
                            <div className='tab-content-div'>
                              <div className='fields'>
                                <MarkdownEditor
                                  placeholder='Write a note here...'
                                  note={item?.data?.text}
                                  setNote={setNote}
                                  // onBlur={() => {
                                  //   setEditIndex(-1);
                                  // }}
                                />
                              </div>
                            </div>
                            <div className='addNoteButtonContainer'>
                              <button
                                className='tertiaryButton'
                                type='button'
                                disabled={
                                  item?.data?.text === note ||
                                  note.trim() === ''
                                }
                                onClick={() => {
                                  updateNote(
                                    item?.data?.uuid,
                                    {
                                      text: note.slice(0, -1),
                                    },
                                    uuid
                                  );
                                  setEditIndex(-1);
                                }}
                              >
                                Edit Note
                              </button>
                            </div>
                          </>
                        </FieldComponents>
                      )}
                    </div>
                  ) : null}
                  {item?.type === 'TASK' ? (
                    <TaskDetail
                      item={item}
                      ApiSuccess={ApiSuccess}
                      handleDeleteTask={handleDeleteTask}
                      taskType={taskType}
                      taskPriority={taskPriority}
                      producers={producers}
                      setEdited={setEdited}
                    />
                  ) : null}
                  {item.type === 'EVENT' ? (
                    <EventDetails
                      item={item}
                      handleDelete={handleDelete}
                      eventType={eventType}
                      guests={guests}
                      ApiSuccess={ApiSuccess}
                      setShowModal={setShowModal}
                      file={file}
                      setEdited={setEdited}
                    />
                  ) : null}
                </div>
              ))}
            </div>
            {addNotesMobile && uuid && (
              <div className='d-sm-none'>
                <NotesMobile
                  addNote={addNote}
                  handleTaskSubmit={handleTaskSubmit}
                  addTask={addTask}
                  taskType={taskType}
                  producers={producers}
                  setAssignee={setAssignee}
                  taskPriority={taskPriority}
                  assignee={assignee}
                  addEvent={addEvent}
                  onItem={onItem}
                  type={isTaskMobile ? 'task' : isEventMobile ? 'event' : ''}
                  uuid={uuid}
                  tasks={tasks}
                  ApiSuccess={ApiSuccess}
                  show={addNotesMobile}
                  closeModal={setAddNoteMobile}
                  addNoteLoading={addNoteLoading}
                />
              </div>
            )}
            {showModal !== 'closed' && (
              <DocumentAddModal
                show={showModal}
                closeModal={setShowModal}
                file={file}
                setFile={setFile}
                name='task'
                docTypeUUID={docTypeUUID}
                setDocTypeUUID={setDocTypeUUID}
              />
            )}
            <FilterContainer>
              {showFilter !== 'closed' && (
                <EventFilter
                  show={showFilter}
                  closeModal={setShowFilter}
                  filterFunction={filterEvent}
                  eventType={eventTypeFil}
                  setEventType={setEventTypeFil}
                  taskType={taskTypeFil}
                  setTaskType={setTaskTypeFil}
                  taskStatusValue={taskStatusValue}
                  setTaskStatusValue={setTaskStatusValue}
                  setTaskPriority={setTaskPriorityFil}
                  taskPriority={taskPriorityFil}
                  assignee={assigneeFil}
                  setAssignee={setAssigneeFil}
                  type='all'
                />
              )}
            </FilterContainer>
            <div className='d-xl-none'>
              <MobileTabNav />
            </div>
          </div>
        </ActivitiesStyledDiv>
      </div>
    </StyledDiv>
  );
};

export default TasksTab;
