import Document from './DocumentTable';
import DetailsCardMobile from './DocumentTable/components/DetailsCardMobile';
import { InnerFieldStyle } from '../../styles/InnerFieldStyles';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch } from '../../store';
import whitePlus from '../../assets/logo/plus.svg';
import {
  selectAllDocuments,
  getDocuments,
} from '../../store/documents/documentsSlice';
import plus from '../../assets/logo/blackplus.svg';
import AddDocumentsModal from './DocumentTable/components/AddDocumentsModal';
import { FilterContainer } from '../../pages/MyBook/elements/styles';
import DocumentFilter from './DocumentsFilter';
import { IOption } from '../../interfaces/Opportunity';
const Documents = ({
  name,
  uuid,
  isMobile,
}: {
  name: string;
  uuid: string;
  isMobile?: boolean;
}) => {
  const [show, setShow] = useState('closed');

  const [showFilter, setShowFilter] = useState('closed');

  const [documentType, setDocumentType] = useState<IOption[]>([]);

  const dispatch = useDispatch<AppDispatch>();
  const documents = useSelector(selectAllDocuments);

  // useEffect(() => {
  //   if (name === 'lead' && uuid) dispatch(getLeadCount({ uuid }));
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [uuid]);

  useEffect(() => {
    if (uuid && name === 'Contact' && !isMobile) {
      dispatch(
        getDocuments({
          contactUUID: uuid,
          documentTypeUUID: documentType.map((item) => item.value),
        })
      );
    } else if (uuid && name === 'lead' && !isMobile)
      dispatch(
        getDocuments({
          leadUUID: uuid,
          documentTypeUUID: documentType.map((item) => item.value),
        })
      );
    else if (uuid && name === 'Account' && !isMobile)
      dispatch(
        getDocuments({
          accountUUID: uuid,
          documentTypeUUID: documentType.map((item) => item.value),
        })
      );
    else if (uuid && name === 'Opportunity' && !isMobile)
      dispatch(
        getDocuments({
          opportunityUUID: uuid,
          documentTypeUUID: documentType.map((item) => item.value),
        })
      );
    else if (uuid && name === 'Policy' && !isMobile)
      dispatch(
        getDocuments({
          policyUUID: uuid,
          documentTypeUUID: documentType.map((item) => item.value),
        })
      );
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, name, uuid]);

  const filterDocuments = () => {
    if (uuid && name === 'Contact' && !isMobile) {
      dispatch(
        getDocuments({
          contactUUID: uuid,
          documentTypeUUID: documentType.map((item) => item.value),
        })
      );
    } else if (uuid && name === 'lead' && !isMobile)
      dispatch(
        getDocuments({
          leadUUID: uuid,
          documentTypeUUID: documentType.map((item) => item.value),
        })
      );
    else if (uuid && name === 'Account' && !isMobile)
      dispatch(
        getDocuments({
          accountUUID: uuid,
          documentTypeUUID: documentType.map((item) => item.value),
        })
      );
    else if (uuid && name === 'Opportunity' && !isMobile)
      dispatch(
        getDocuments({
          opportunityUUID: uuid,
          documentTypeUUID: documentType.map((item) => item.value),
        })
      );
    else if (uuid && name === 'Policy' && !isMobile)
      dispatch(
        getDocuments({
          policyUUID: uuid,
          documentTypeUUID: documentType.map((item) => item.value),
        })
      );
  };
  return (
    <InnerFieldStyle>
      <div className='taskDiv documentDiv'>
        <div className='taskHead d-flex justify-content-between'>
          <button
            className='tertiaryButton d-none d-sm-block'
            onClick={() => setShow('open')}
          >
            Add documents <img className='addIcon' src={plus} alt='' />
          </button>
          <button
            className='tertiaryButton filterbutton'
            onClick={() => setShowFilter('open')}
          >
            Filter by:&nbsp;{' '}
            <b>
              {documentType?.length === 0 ? 'No' : documentType?.length} filter
            </b>
          </button>
        </div>
        <div className='d-none d-sm-block opportunityDiv'>
          <Document name={name} uuid={uuid} />
        </div>
        <div className=' d-sm-none opportunityDiv'>
          {documents &&
            documents?.map((item: any) => (
              <DetailsCardMobile name={name} uuid={uuid} documents={item} />
            ))}
          <button
            onClick={() => setShow('open')}
            className='addbutton-mob d-sm-none'
          >
            <img src={whitePlus} alt='add' />
          </button>
        </div>

        {show !== 'closed' && (
          <AddDocumentsModal
            show={show}
            closeModal={setShow}
            uuid={uuid}
            name={name}
          />
        )}
        <FilterContainer>
          {showFilter !== 'closed' && (
            <DocumentFilter
              show={showFilter}
              closeModal={setShowFilter}
              documentType={documentType}
              setDocumentType={setDocumentType}
              filterFunction={filterDocuments}
            />
          )}
        </FilterContainer>
      </div>
    </InnerFieldStyle>
  );
};

export default Documents;
