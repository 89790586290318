import { useEffect, useRef, useState } from 'react';
import { useNavigate, useLocation } from 'react-router';
import { useSelector, useDispatch } from 'react-redux';
import { clearUser, selectUser } from '../../store/user/userSlice';
import LocalStorageService from '../../utils/LocalStorageService';
import { useOutsideClick } from '../../hooks/useOutsideClick';
import SearchBar from '../SearchBar';
import { TopBarDiv } from './styles';
import bell from '../../assets/logo/bell.svg';
import whiteBell from '../../assets/logo/whitebell.svg';
import Profile from '../../assets/logo/Profile.svg';
import PaswordKey from '../../assets/logo/PaswordKey.svg';
import logOutButton from '../../assets/logo/redpower.svg';
import NotificationsDropdown from './components/NotificationsDropdown';
import { AppDispatch } from '../../store';
import {
  fetchNotifications,
  NotificationSliceState,
} from '../../store/notifications/notificationsSlice';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import AddQuoteModal from '../AddQuoteModal';

export interface IProps {
  children: React.ReactNode;
  backgroundColor?: string;
}

const TopBar = ({ children, backgroundColor }: IProps) => {
  const dispatch = useDispatch<AppDispatch>();
  const user = useSelector(selectUser);
  const navigate = useNavigate();
  const url = useLocation();
  const [showDrop, setShowDrop] = useState(false);
  const [showNotificationDrop, setShowNotificationDrop] = useState(false);
  const [showSearchDrop, setShowSearchDrop] = useState(false);
  const [showQuoteModal, setShowQuoteModal] = useState('closed');
  const dropRef = useRef(null);
  const searchRef = useRef(null);

  // const audio = new Audio(
  //   'https://drive.google.com/uc?export=download&id=1M95VOpto1cQ4FQHzNBaLf0WFQglrtWi7'
  // );
  const audio = new Audio(
    'http://codeskulptor-demos.commondatastorage.googleapis.com/pang/pop.mp3'
  );
  // const audio = new Audio(
  //   'http://codeskulptor-demos.commondatastorage.googleapis.com/GalaxyInvaders/explosion%2001.wav'
  // );

  const notificationssummaryCount = useSelector(
    (state: { notifications: NotificationSliceState }) =>
      state.notifications.summaryCount
  );

  useOutsideClick(dropRef, setShowDrop);
  useOutsideClick(searchRef, setShowSearchDrop);
  useEffect(() => {
    const interval = setInterval(() => {
      dispatch(
        fetchNotifications({
          limit: 100,
          offset: 0,
          unread: false,
          summary: true,
          successCB: (data: any) => {
            if (notificationssummaryCount !== data?.count) {
              user && user?.NotificationSettings?.isSoundOn && audio.play();
              notify();
              new Notification('HobNob', {
                body: `You have ${data.count} unread notifications`,
              });
            }
          },
        })
      );
    }, 60000);
    return () => clearInterval(interval);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user, notificationssummaryCount]);

  const notify = () =>
    toast('You have new Notifications!', {
      position: 'top-right',
      autoClose: 1000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });

  useEffect(() => {
    dispatch(
      fetchNotifications({
        limit: 100,
        offset: 0,
        unread: false,
        summary: true,
        successCB: (data: any) => {},
      })
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <TopBarDiv style={{ backgroundColor: backgroundColor }}>
      <div
        className={
          window.location.pathname.includes('search') &&
          window.innerWidth < 1200
            ? 'w-100'
            : window.location.pathname.includes('notification')
            ? 'notificationTop'
            : ''
        }
      >
        {children}
      </div>
      <div className='d-none d-xl-flex profile-container ' ref={dropRef}>
        {(user?.UserRole?.name === 'AGENT' || 'PRINCIPAL_AGENT') && (
          <div ref={searchRef} className='d-none d-xl-flex profile-container '>
            <SearchBar
              showSearchDrop={showSearchDrop}
              setShowSearchDrop={setShowSearchDrop}
              isTopBar={true}
            />
            <button
              className='navbar-button action-button'
              onClick={() => setShowQuoteModal('open')}
            >
              Quick quote
            </button>
            <button
              className={`navbar-button ${showNotificationDrop && 'bellIcon'}`}
              onClick={() => {
                setShowNotificationDrop(!showNotificationDrop);
                setShowDrop(false);
              }}
              type='button'
              disabled={url.pathname === '/notification'}
            >
              {showNotificationDrop ? (
                <img src={whiteBell} alt='' />
              ) : (
                <img src={bell} alt='' />
              )}
              {notificationssummaryCount > 0 && (
                <span
                  className={`notificationCounts ${
                    notificationssummaryCount > 99 && 'bigCount'
                  }`}
                >
                  {notificationssummaryCount > 99
                    ? '99 +'
                    : notificationssummaryCount}
                </span>
              )}
            </button>
          </div>
        )}

        {user?.avatar ? (
          <img
            alt='profilepic'
            className='avatar-img'
            src={user?.avatar}
            onClick={() => {
              setShowDrop(!showDrop);
            }}
          />
        ) : (
          <div className='avatar-div' onClick={() => setShowDrop(!showDrop)}>
            {user?.firstName || user?.lastName
              ? user?.firstName?.substring(0, 1).toUpperCase() +
                user?.lastName?.substring(0, 1).toUpperCase()
              : user?.email[0]?.substring(0, 1).toUpperCase()}
          </div>
        )}
        {showDrop ? (
          <div
            className={`profile-drop ${
              user?.UserRole.name === 'ADMIN' && 'onlylogout'
            }`}
          >
            <div className='pointerDiv'></div>
            <div className='d-flex UserImgDiv  align-items-center'>
              {user?.avatar ? (
                <img
                  alt='profilepic'
                  className='avatar-img'
                  src={user?.avatar}
                  onClick={() => setShowDrop(!showDrop)}
                />
              ) : (
                <div
                  className='avatar-div dropDownAvatar'
                  onClick={() => setShowDrop(!showDrop)}
                >
                  {user?.firstName || user?.lastName
                    ? user?.firstName?.substring(0, 1).toUpperCase() +
                      user?.lastName?.substring(0, 1).toUpperCase()
                    : user?.email[0]?.substring(0, 1).toUpperCase()}
                </div>
              )}
              <div className='avatar-name-div d-block'>
                <div className='text-overflow-container'>
                  <h6>{user?.firstName + ' ' + user?.lastName}</h6>
                  <span className='tooltiptext'>
                    <span className='tooltipInnertext'>
                      {user?.firstName || user?.lastName
                        ? user?.firstName + ' ' + user?.lastName
                        : '--'}
                    </span>
                  </span>
                </div>
                <div className='text-overflow-container'>
                  <span className='AgencyNameDiv'>
                    {LocalStorageService.getUser().Agent?.Agencies?.name}
                  </span>
                  <span className='tooltiptext'>
                    <span className='tooltipInnertext'>
                      {LocalStorageService.getUser().Agent?.Agencies?.name}
                    </span>
                  </span>
                </div>
              </div>
            </div>
            {user?.UserRole.name !== 'ADMIN' && (
              <div className='navigationButtonsDiv d-flex flex-column'>
                <button
                  className='profileButtonDiv'
                  onClick={() => {
                    navigate('/myprofile/profilesetting');
                  }}
                >
                  <img src={Profile} className='ButtonImage' alt='' />
                  My Profile
                </button>
                <button
                  className='profileButtonDiv'
                  onClick={() => {
                    navigate('/myprofile/notificationsettings');
                  }}
                >
                  <img src={bell} className='ButtonImage' alt='' />
                  Notification Settings
                </button>
                <button
                  className='profileButtonDiv'
                  onClick={() => {
                    navigate('/myprofile/changepassword');
                  }}
                >
                  <img src={PaswordKey} className='ButtonImage' alt='' />
                  Change Password
                </button>
              </div>
            )}
            <button
              className='logOutButtonDiv'
              onClick={() => {
                dispatch(clearUser());
                LocalStorageService.clearToken();
                navigate('/');
              }}
            >
              <img src={logOutButton} className='ButtonLogOutImage' alt='' />
              Log out
            </button>
          </div>
        ) : null}
        {showNotificationDrop && (
          <NotificationsDropdown
            setShowNotificationDrop={setShowNotificationDrop}
          />
        )}
        <ToastContainer
          position='top-right'
          autoClose={2000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
        {showQuoteModal !== 'closed' && (
          <AddQuoteModal
            show={showQuoteModal}
            closeModal={setShowQuoteModal}
            type='Contact'
            page='quickQuote'
          />
        )}
      </div>
    </TopBarDiv>
  );
};

export default TopBar;
