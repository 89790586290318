import styled from '@emotion/styled';

export const LeadSourceDiv = styled.div`
  width: 240px;
  height: 32px;
  background: #ecf4f9;
  border-radius: 8px;
  color: #191919;
  cursor: pointer;
  .leadSource {
    font-size: 12px;

    font-weight: 600;
    padding: 7px 10px;
  }
  .dropDown {
    position: relative;
    z-index: 1000;
    width: 240px;
    height: 342.76px;
    left: 0.63px;
    padding: 16px 14.67px;
    top: 0px;
    background: #ffffff;
    box-shadow: 0px 4px 16px rgba(35, 42, 64, 0.2);
    border-radius: 8px;
    overflow-y: auto;

    &::-webkit-scrollbar {
      display: none;
    }
  }
  .otherHead {
    font-size: 12px;
    color: #8990a3;
    margin-bottom: 12px;
  }
  .inputsoption {
    color: #262758;
    height: 44px;
    font-size: 16px;
  }
  .highlighselected {
    font-weight: 700;
  }
  .inputsoption:hover {
    font-weight: 700;
  }
  @media screen and (min-width: 576px) {
    .leadSource {
      font-size: 12px;
    }
    .inputsoption {
      height: 36px;
      font-size: 12px;
    }
    .dropDown {
      width: 248.74px;
      height: 292.76px;
    }
  }
  @media screen and (min-width: 1200px) {
    width: 240px;
    height: 32px;
    .leadSource {
      font-size: 14px;
    }
    .inputsoption {
      height: 44px;
      font-size: 14px;
    }
    .dropDown {
      width: 298.74px;
      height: 342.76px;
    }
  }
  @media screen and (min-width: 1900px) {
    width: 322px;
    height: 40px;
    .inputsoption {
      height: 52px;
    }
    .dropDown {
      width: 322px;
      height: 368.76px;
    }
  }
`;
