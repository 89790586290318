import moment from 'moment';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { AppDispatch } from '../../../store';
import { updateEventDetails } from '../../../store/tasks/tasksSlice';
import document from '../../../assets/logo/DocumentTrans.svg';
import { EditTask, FieldComponents } from '../styles';
import ActionsDropdown from './dropdown';
import Events from './Events';

const EventDetails = ({
  item,
  handleDelete,
  eventType,
  guests,
  ApiSuccess,
  setShowModal,
  file,
  setEdited,
}: {
  item: any;
  handleDelete: Function;
  eventType: any;
  guests: any;
  ApiSuccess: boolean;
  setShowModal: Function;
  file: any;
  setEdited: Function;
}) => {
  const [showEdit, setShowEdit] = useState(false);
  const [loading, setLoading] = useState(false);
  const [guestList, showGuestList] = useState(false);
  const [guest, setGuest] = useState<any>([]);
  const [guestUUID, setGuestUUID] = useState<any>([]);

  const [startDate, setStartDate] = useState<any>(new Date());
  const [endDate, setEndDate] = useState<any>(null);

  const dispatch = useDispatch<AppDispatch>();
  const handleEditEvent = (uuid: string, value: any, index?: number) => {
    setShowEdit(true);
    const event: any = {};
    event.eventTypeUUID = value.EventType.uuid;
    event.scheduledDate = [value.scheduledDate, value?.dueDate];
    setStartDate(new Date(value.scheduledDate));
    setEndDate(new Date(value?.dueDate));
    if (value?.EmailEvents?.length > 0) {
      setGuest(value?.EmailEvents.map((item: any) => item?.Contact));
      setGuestUUID(value?.EmailEvents.map((item: any) => item.Contact.uuid));
    } else if (value?.PhoneEvents?.length > 0) {
      setGuest(value?.PhoneEvents.map((item: any) => item?.Contact));
      setGuestUUID(value?.PhoneEvents.map((item: any) => item.Contact.uuid));
    } else if (value?.CalendarEvents?.length > 0) {
      setGuest(value?.CalendarEvents.map((item: any) => item?.Contact));
      setGuestUUID(value?.CalendarEvents.map((item: any) => item.Contact.uuid));
    }

    event.dueDate = value?.dueDate;
    event.name = value.name;
    event.description = value.description;
    if (value?.Documents?.length > 0) event.file = value?.Documents[0];
    setEvents(event);
  };

  const [eventData, setEvents] = useState<any>({});

  useEffect(() => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const editEvent = () => {
    eventData.guests = guestUUID.map((item: any) => ({ guestUUID: item }));
    eventData.dueDate = startDate;
    eventData.scheduledDate = endDate;
    setLoading(true);
    setEdited('Event');
    dispatch(
      updateEventDetails({
        uuid: item.data.uuid,
        data: eventData,
        successCB: () => {
          setShowEdit(false);
          setLoading(false);
          setTimeout(() => {
            setEdited('');
          }, 2500);
        },
      })
    );
  };

  const onSetEvent = (name: string, value: string) => {
    let updatedEvent = { ...eventData };
    updatedEvent[name] = value;
    setEvents(updatedEvent);
  };
  return showEdit ? (
    <EditTask>
      <FieldComponents>
        <div className='info '>
          <div className='d-flex imageCard align-items-center '>
            <div
              className={`profile-pic d-none  d-sm-flex align-items-center justify-content-center`}
            >
              <div className='profile'>
                {item.data.Owner?.firstName?.charAt(0)}
              </div>
            </div>
            <div className='task-edit-container event-edit-container'>
              <Events
                guest={guest}
                guestList={guestList}
                guests={guests}
                onSetEvent={onSetEvent}
                eventType={eventType}
                events={eventData}
                setStartDate={setStartDate}
                setEndDate={setEndDate}
                endDate={endDate}
                startDate={startDate}
                handleEventSubmit={editEvent}
                ApiSuccess={ApiSuccess}
                setShowModal={setShowModal}
                file={file}
                setGuestUUID={setGuestUUID}
                guestUUID={guestUUID}
                setGuest={setGuest}
                showGuestList={showGuestList}
                isEdit={true}
                setShowEdit={setShowEdit}
              />
            </div>
          </div>
        </div>
      </FieldComponents>
    </EditTask>
  ) : (
    <div className='info '>
      <div className='d-none d-sm-flex imageCard align-items-center '>
        <div
          className={`profile-pic  d-none  d-sm-flex align-items-center justify-content-center`}
        >
          <div className='profile'>{item.data.Owner?.firstName?.charAt(0)}</div>
        </div>
        <h2 className='title Heading '>
          {item.data.Owner?.firstName} {item.data.Owner?.lastName}
        </h2>
        <h2 className=' title added '>
          created a new{' '}
          {item?.data.EventType?.name.charAt(0).toUpperCase() +
            item?.data.EventType?.name.slice(1).toLowerCase()}{' '}
          event
          <b> {item.data.name} </b>
        </h2>
        <h6 className=' title dateFont '>
          on {moment(item.data.createdAt).format('Do MMMM YYYY, h:mm A')}
        </h6>
      </div>
      <p className='imageCard mobile-imagecard  d-sm-none'>
        {' '}
        <b className='title'>
          {item.data.Owner?.firstName} {item.data.Owner?.lastName}
        </b>
        <span className=' title added '>
          send a new{' '}
          {item?.data.EventType?.name.charAt(0).toUpperCase() +
            item?.data.EventType?.name.slice(1).toLowerCase()}
          <b>
            {item.data.Assignee?.firstName} {item.data.Assignee?.lastName}
          </b>
        </span>
        <span className=' title dateFont '>
          on {moment(item.data.createdAt).format('Do MMMM YYYY, h:mm A')}
        </span>
      </p>
      <div className='contentDiv d-flex justify-content-between'>
        <div>
          <h6>{item.data?.name}</h6>
          <div>
            <span>{item.data?.description}</span>
          </div>
        </div>
        <div className='d-flex align-items-center'>
          <div className='text-overflow-container d-flex align-items-center'>
            {eventData?.file && (
              <a
                target='_blank'
                href={eventData?.file?.s3Location}
                className='task-type-icon-div me-2'
              >
                <img src={document} alt='' className='documentIcon mt-2' />
                <span className='tooltiptext'>
                  <span className='tooltipInnertext'>
                    {eventData?.file?.name}
                  </span>
                </span>
              </a>
            )}
          </div>
          <div>
            <div className='d-flex event-button-container'>
              <ActionsDropdown
                uuid={item.data.uuid}
                item={item.data}
                handleDelete={handleDelete}
                handleEdit={handleEditEvent}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EventDetails;
