import { useEffect, useState } from 'react';
import FilterSelectField from '../../../../components/FilterSelectField';
import Modal from '../../../../components/Modal';
import cross from '../../../../assets/logo/cross.svg';
import { StyledModal } from '../../../../components/FilterDrawer/styles';
import { MobileFilterNavDiv } from '../../../../components/FilterMobileDrawer/styles';
import Skeleton from 'react-loading-skeleton';
import { fetchProductCategory } from '../../../../api/productCategory';
import { IProductCategory } from '../../../../interfaces/ProductCategory';
import { useParams } from 'react-router';

const CarrierFilter = ({
  show,
  closeModal,
  filterFunction,
  setStatus,
  status,
  setSelectedPolicyTypes,
  selectedPolicyTypes,
}: {
  show: string;
  closeModal: Function;
  filterFunction: Function;
  setStatus: Function;
  status: any;
  setSelectedPolicyTypes: Function;
  selectedPolicyTypes: any;
}) => {
  const onCloseModal = () => {
    closeModal('closing');
    setTimeout(() => {
      closeModal('closed');
    }, 300);
  };

  const [loadingStatus, setLoadingStatus] = useState('idle');

  const statusOption = [
    { label: 'Active', value: 'true' },
    { label: 'Inactive', value: 'false' },
  ];

  const [productCategory, setProductCategory] = useState<IProductCategory[]>(
    []
  );
  const { uuid } = useParams();
  useEffect(() => {
    setLoadingStatus('loading');
    fetchProductCategory(
      (data: IProductCategory[]) => {
        setProductCategory(data);
        setLoadingStatus('succeeded');
      },
      'name',
      'asc',
      '',
      uuid,
      undefined,
      true,
      'CUSTOM_PRODUCT_CATEGORY'
    );
  }, []);

  const [policyOptions, setPolicyOptions] = useState<any>([]);
  useEffect(() => {
    if (productCategory.length > 0) {
      setPolicyOptions([]);
      let op: any = [];
      productCategory.map((item: any) => {
        op.push({ label: item.name, value: item.uuid, isDisabled: true });
        item.CustomProduct?.map((el: any) => {
          op.push({ label: el.name, value: el.uuid });
        });

        return;
      });
      setPolicyOptions([...op]);
    }
  }, [productCategory]);

  useEffect(() => {
    filterFunction();
  }, [selectedPolicyTypes, status]);

  const filterContent = () => {
    return (
      <>
        <div>
          <h6 className='head text-uppercase'>Status</h6>
        </div>
        {loadingStatus === 'loading' ? (
          <Skeleton height={48} />
        ) : (
          <div className='agency-fields'>
            <FilterSelectField
              options={statusOption}
              placeholder='Select'
              onChange={(value: any) => {
                setStatus(value);
              }}
              value={status}
            />
          </div>
        )}
        <hr className='filter-hr' />
        <div>
          <h6 className='head text-uppercase'>Policies Offered</h6>
        </div>
        {loadingStatus === 'loading' ? (
          <Skeleton height={48} />
        ) : (
          <div className='agency-fields'>
            <FilterSelectField
              options={policyOptions}
              placeholder='Select'
              onChange={(value: any) => {
                setSelectedPolicyTypes(value);
              }}
              isMulti={true}
              value={selectedPolicyTypes}
            />
          </div>
        )}
      </>
    );
  };

  return (
    <>
      <div className='d-none d-sm-block'>
        <Modal open={show} align='right'>
          <StyledModal>
            <button className='crossbutton' onClick={() => onCloseModal()}>
              <img src={cross} className='cross' alt='cross-icon' />
            </button>
            <div>
              <h2>Filters</h2>
            </div>
            <div>
              <hr className='filter-hr' />
            </div>
            <div>
              <div>
                {show && (
                  <div>
                    <div className='field-section'>{filterContent()}</div>
                    <div className='buttonsDiv filterButtonContainer d-flex align-items-center justify-content-between'>
                      <div className='filter-count'>
                        {(status?.value ? 1 : 0) + selectedPolicyTypes.length}{' '}
                        filters added
                      </div>
                      <button
                        type='submit'
                        className='agentButton justify-content-end'
                        onClick={() => {
                          setStatus('');
                          setSelectedPolicyTypes([]);
                        }}
                        disabled={
                          (status?.value ? 1 : 0) +
                            selectedPolicyTypes.length ===
                          0
                        }
                      >
                        Clear all filters
                      </button>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </StyledModal>
        </Modal>
      </div>
      <div className='d-block d-sm-none'>
        <MobileFilterNavDiv>
          <div className='backgroundQuickAdd'>
            <div className={`quickFormsDropDown closed`}>
              <div className='d-flex  drawerHandle'></div>
              <h2>Filters</h2>
              <button className='crossbutton' onClick={() => onCloseModal()}>
                <img src={cross} className='cross' alt='cross-icon' />
              </button>
              <div className='scroll-filter-mob'>
                {filterContent()}
                <div className='buttonsDiv d-flex align-items-center justify-content-between'>
                  <div className='filter-count'>
                    {(status?.value ? 1 : 0) + selectedPolicyTypes.length}{' '}
                    filters added
                  </div>
                  <button
                    type='submit'
                    className='agentButton justify-content-end'
                    onClick={() => {
                      setStatus('');
                      setSelectedPolicyTypes([]);
                    }}
                    disabled={
                      (status?.value ? 1 : 0) + selectedPolicyTypes.length === 0
                    }
                  >
                    Clear all filters
                  </button>
                </div>
              </div>
            </div>
          </div>
        </MobileFilterNavDiv>
      </div>
    </>
  );
};

export default CarrierFilter;
