import arrow from '../../../assets/logo/right-arrow.svg';
import plus from '../../../assets/logo/blackplus.svg';
import {
  EditTask,
  FieldComponents,
  NotesMobileDiv,
  StyledGuestComponent,
} from '../styles';
import { useState, useEffect, useRef } from 'react';
import Task from './task';
import { IProducer } from '../../../interfaces/Agent';
import MarkdownEditor from '../../MarkdownEditor';
import DateField from '../../ActivityCustomeDateField';
import InputField from '../../CustomInputField';
import SelectField from '../../ActivityCustomSelectField';
import pin from '../../../assets/logo/pin.svg';
import { useOutsideClick } from '../../../hooks/useOutsideClick';
import { fetchEventType } from '../../../api/events';
import { fetchContacts } from '../../../api/contact';
import DocumentAddModal from './DocumentAddModal';
import GuestListDropDown from '../../../pages/Activities/component/GuestListDropDown';
const NotesMobile = ({
  show,
  closeModal,
  uuid,
  type,
  addNote,
  ApiSuccess,
  handleTaskSubmit,
  taskType,
  taskPriority,
  assignee,
  tasks,
  onItem,
  addNoteLoading,
  producers,
  setAssignee,
  addTask,
  addEvent,
}: {
  addNote: Function;
  handleTaskSubmit: Function;
  taskType: any;
  ApiSuccess: boolean;
  producers: any;
  setAssignee: Function;
  assignee: any;
  onItem: Function;
  taskPriority: any;
  type: string;
  tasks: any;
  addTask: Function;
  addEvent: Function;
  uuid: string;
  addNoteLoading: boolean;
  show: boolean;
  closeModal: Function;
}) => {
  const onCloseModal = () => {
    closeModal('closing');
    setTimeout(() => {
      closeModal(false);
    }, 300);
  };
  const [isTasks, setIsTasks] = useState(false);
  const [isEvents, setIsEvents] = useState(false);
  const [notes, setNotes] = useState(false);
  const [note, setNote] = useState('');
  const [events, setEvent] = useState<any>({});

  const [eventError, setError] = useState<{
    eventTypeUUID?: string;
    period?: string;
    guests?: string;
    name?: string;
  }>();

  useEffect(() => {
    if (type === 'task') {
      setIsTasks(true);
      setIsEvents(false);
      setNotes(false);
    } else if (type === 'event') {
      setIsTasks(false);
      setIsEvents(true);
      setNotes(false);
    } else {
      setNotes(true);
      setIsTasks(false);
      setIsEvents(false);
    }
  }, [type]);

  useEffect(() => {
    setFile('');
  }, [notes, isEvents, isTasks]);

  const onSubmitNote = () => {
    addNote({ text: note });
  };
  const [docType, setDocType] = useState('');
  const [docTypeUUID, setDocTypeUUID] = useState('');
  const [file, setFile] = useState<any>();

  const handleEventSubmit = () => {
    events.guests = guestUUID.map((item: any) => ({ guestUUID: item }));
    events.dueDate = events.scheduledDate[1];
    events.scheduledDate = events.scheduledDate[0];
    addEvent(events);
  };
  const onSetEvent = (name: string, value: string) => {
    let updatedEvent = { ...events };
    updatedEvent[name] = value;
    setEvent(updatedEvent);
  };
  const [guests, setGuests] = useState<any[]>([]);
  const [guestUUID, setGuestUUID] = useState<any>([]);
  const [guest, setGuest] = useState<any>([]);
  const [guestList, showGuestList] = useState(false);
  const [eventType, setEventType] = useState([]);
  const [startDate, setStartDate] = useState<any>(new Date());
  const [endDate, setEndDate] = useState(null);
  const [showModal, setShowModal] = useState('closed');
  const [guestManageDropDown, setGuestManageDropDown] = useState(false);

  const dropRef = useRef(null);
  useOutsideClick(dropRef, showGuestList);
  const dropManageGuestRef = useRef(null);
  useOutsideClick(dropManageGuestRef, setGuestManageDropDown);

  useEffect(() => {
    fetchContacts('id', 'asc', '', 25, 0, 'True', (data: IProducer[]) => {
      setGuests(data);
    });
    fetchEventType((data: any) => {
      setEventType(data);
    }); // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleTaskSubmitWithClose = () => {
    handleTaskSubmit();
    onCloseModal();
  };

  useEffect(() => {
    let errorObj: {
      eventTypeUUID?: string;
      period?: string;
      guests?: string;
      name?: string;
    } = {};
    if (
      eventError?.eventTypeUUID ||
      eventError?.period ||
      eventError?.guests ||
      eventError?.name
    ) {
      if (!events.name) {
        errorObj.name = 'Please enter the title.';
      }
      if (!events.eventTypeUUID) {
        errorObj.eventTypeUUID = 'Please select a type.';
      }
      if (guest.length === 0) {
        errorObj.guests = 'Please select the guests.';
      }
      if (!startDate || !endDate) {
        errorObj.period = 'Please select the period.';
      }
      setError(errorObj);
    }
  }, [events]);

  return (
    <NotesMobileDiv>
      <div className='mobile-notes-container'>
        <div className='navContainer'>
          <span
            className={`navButton ${notes && 'active'}`}
            onClick={() => {
              setNotes(true);
              setIsTasks(false);
              setIsEvents(false);
            }}
          >
            Notes
          </span>
          <span
            className={`navButton ${isTasks && 'active'}`}
            onClick={() => {
              setNotes(false);
              setIsEvents(false);
              setIsTasks(true);
            }}
          >
            Tasks
          </span>
          <span
            className={`navButton ${isEvents && 'active'}`}
            onClick={() => {
              setNotes(false);
              setIsEvents(true);
              setIsTasks(false);
            }}
          >
            Events
          </span>
        </div>
        {notes && (
          <div className='textarea'>
            <MarkdownEditor
              placeholder='Add notes here'
              note={note}
              setNote={setNote}
            />
          </div>
        )}
        {isTasks && (
          <EditTask>
            <div className='info '>
              <div className='d-flex imageCard align-items-center justify-content-center'>
                <div className='task-edit-container'>
                  <Task
                    handleTaskSubmit={handleTaskSubmitWithClose}
                    setFile={setFile}
                    file={file}
                    setDocType={setDocType}
                    docType={docType}
                    docTypeUUID={docTypeUUID}
                    setDocTypeUUID={setDocTypeUUID}
                    taskType={taskType}
                    taskPriority={taskPriority}
                    assignee={assignee}
                    setAssignee={setAssignee}
                    producers={producers}
                    onItem={onItem}
                    ApiSuccess={ApiSuccess}
                    tasks={tasks}
                    addNoteLoading={addNoteLoading}
                    editTask={false}
                    isMobile={true}
                  />
                </div>
              </div>
            </div>
          </EditTask>
        )}
        {isEvents && (
          <>
            <FieldComponents>
              <div className='tab-content-div'>
                <div className='fields col'>
                  <InputField
                    name='name'
                    type='text'
                    placeholder='Event Title'
                    onSetItem={onSetEvent}
                    values={events}
                  />
                  <div className='errorVal'>
                    {eventError?.name ? (
                      <span className=''>{eventError.name} </span>
                    ) : null}
                  </div>
                </div>
                <div className='fields col'>
                  <SelectField
                    options={eventType.map((item: any) => {
                      return {
                        label: `${
                          item?.name
                            ? item?.name.charAt(0).toUpperCase() +
                              item?.name.slice(1).toLowerCase()
                            : ''
                        }`,
                        value: item?.uuid,
                      };
                    })}
                    name='eventTypeUUID'
                    placeholder='Event type'
                    onSetItem={onSetEvent}
                    values={events}
                  />
                  <div className='errorVal'>
                    {eventError?.eventTypeUUID ? (
                      <span className=''>{eventError.eventTypeUUID} </span>
                    ) : null}
                  </div>
                </div>
                <div className=' fields col'>
                  <DateField
                    name='scheduledDate'
                    placeholder='Period'
                    setStartDate={setStartDate}
                    setEndDate={setEndDate}
                    endDate={endDate}
                    startDate={startDate}
                    onSetItem={onSetEvent}
                    values={events}
                    multiselect={true}
                  />
                  <div className='errorVal'>
                    {eventError?.period ? (
                      <span className=''>{eventError.period} </span>
                    ) : null}
                  </div>
                </div>
                <div className='fields col'>
                  <InputField
                    name='description'
                    type='text'
                    placeholder='Description'
                    onSetItem={onSetEvent}
                    values={events}
                  />
                </div>
              </div>
              <div className='d-flex align-items-center justify-content-between addTaskButton guest-addContainer'>
                <StyledGuestComponent isEdit={false}>
                  <div className='d-flex position-relative' ref={dropRef}>
                    <div
                      className='addUser d-flex align-items-center justify-content-center'
                      onClick={() => showGuestList(!guestList)}
                    >
                      <img src={plus} alt='' />
                    </div>
                    {guestList && (
                      <GuestListDropDown
                        contactList={guests}
                        guestUUID={guestUUID}
                        setGuestUUID={setGuestUUID}
                        setGuests={setGuest}
                        guests={guest}
                      />
                    )}
                    {guest?.length > 0 ? (
                      <div className='d-flex align-items-center guestsContainer  col'>
                        {guest?.map((item: any, index: number) => (
                          <>
                            {index < 1 ? (
                              <div
                                className={`profile-pic d-flex align-items-center justify-content-center`}
                                key={index}
                              >
                                <div className='profile'>
                                  {item?.firstName?.charAt(0)}{' '}
                                  {item?.lastName?.charAt(0)}
                                </div>
                              </div>
                            ) : null}
                          </>
                        ))}
                        {guest?.length > 1 ? (
                          <span className='Guestprofile-pic GuestprofileNumber d-flex align-items-center justify-content-center'>
                            +{guest.length - 1}
                          </span>
                        ) : null}
                      </div>
                    ) : (
                      <>
                        <div className='d-flex align-items-center guestsContainer  col'>
                          Add Guest/s
                        </div>
                        <div className='errorVal position-absolute mt-5'>
                          {eventError?.guests ? (
                            <span className=''>{eventError.guests} </span>
                          ) : null}
                        </div>
                      </>
                    )}
                  </div>

                  {guest.length > 0 && (
                    <>
                      <button
                        type='button'
                        className='ManageGuestHead'
                        onClick={() => setGuestManageDropDown(true)}
                        ref={dropManageGuestRef}
                      >
                        Manage Guests
                        {guestManageDropDown && (
                          <GuestListDropDown
                            contactList={guests}
                            guestUUID={guestUUID}
                            setGuestUUID={setGuestUUID}
                            setGuests={setGuest}
                            guests={guest}
                            Manage={true}
                          />
                        )}
                      </button>
                    </>
                  )}
                </StyledGuestComponent>
                <div className='ButtonsContainer d-flex align-items-center'>
                  <span className='DocNameField'>{file?.name}</span>
                  <button
                    className='addDocument d-flex align-items-center justify-content-center '
                    onClick={() => {
                      setShowModal('open');
                    }}
                  >
                    <img src={pin} alt='' className='' />
                  </button>
                </div>
                {showModal !== 'closed' && (
                  <DocumentAddModal
                    show={showModal}
                    closeModal={setShowModal}
                    file={file}
                    setFile={setFile}
                    name='event'
                    docTypeUUID={docTypeUUID}
                    setDocTypeUUID={setDocTypeUUID}
                  />
                )}
              </div>
            </FieldComponents>
          </>
        )}
      </div>
      <div className='buttonsDiv d-flex justify-content-between w-100'>
        <button
          onClick={() => {
            onCloseModal();
          }}
          className=' d-flex backButton align-items-center justify-content-between'
        >
          <img src={arrow} className='cross' alt='cross-icon' />
          <span>Back</span>
        </button>
        {notes && (
          <button
            onClick={() => {
              onSubmitNote();
              onCloseModal();
            }}
            className='  addNotesButton  d-flex  align-items-center justify-content-between'
          >
            <span>Add note</span>
            <img src={plus} className='' alt='cross-icon' />
          </button>
        )}
        {isEvents && (
          <button
            className='addNotesButton  d-flex  align-items-center justify-content-between '
            type='button'
            onClick={() => {
              let errorObj: {
                eventTypeUUID?: string;
                period?: string;
                guests?: string;
                name?: string;
              } = {};

              if (
                guest.length > 0 &&
                startDate &&
                endDate &&
                events.eventTypeUUID &&
                events.name
              ) {
                handleEventSubmit();
                onCloseModal();
              } else {
                if (!events.name) {
                  errorObj.name = 'Please enter the title.';
                }
                if (!events.eventTypeUUID) {
                  errorObj.eventTypeUUID = 'Please select a type.';
                }
                if (guest.length === 0) {
                  errorObj.guests = 'Please select the guests.';
                }
                if (!startDate || !endDate) {
                  errorObj.period = 'Please select the period.';
                }
              }
              setError(errorObj);
            }}
          >
            <span>Add Event</span>
            <img src={plus} className='' alt='cross-icon' />
          </button>
        )}
      </div>
    </NotesMobileDiv>
  );
};

export default NotesMobile;
