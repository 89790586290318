import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import cross from '../../assets/logo/cross.svg';
import tick from '../../assets/logo/tick.svg';
import SelectField from '../SelectField';
import SuccessCard from '../SuccessCard';
import InputField from '../InputField';
import { StyledEditQuoteModal } from './styles';
import { SuccessAlign } from '../../styles/SuccessAlign';
import Modal from '../Modal';
import { AppDispatch } from '../../store';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import SearchDropdown from '../SearchDropdown';
import DateField from '../DateField';
import { fetchAccounts } from '../../api/account';
import { IAccount } from '../../interfaces/Account';
import { fetchCarriers } from '../../api/carriers';
import { fetchOpportunities } from '../../api/opportunities';
import { fetchContacts } from '../../api/contact';
import {
  fetchQuoteStages,
  fetchQuoteTypes,
  fetchCoverageTypes,
} from '../../api/quote';
import warning from '../../assets/logo/warning.svg';
import { selectOpportunity } from '../../store/opportunities/opportunitiesSlice';
import { onUpdateQuotes } from '../../store/quotes/quoteSlice';
import {
  fetchQuoteDetails,
  selectQuote,
  QuoteSliceState,
} from '../../store/quotes/quoteSlice';
import moment from 'moment';
import document from '../../assets/logo/document.svg';
import vector from '../../assets/logo/dropdown.svg';
import bin from '../../assets/logo/bin.svg';
import { selectAllDocuments } from '../../store/documents/documentsSlice';
import {
  deleteADocument,
  getDocuments,
} from '../../store/documents/documentsSlice';

const SignupSchema = Yup.object().shape({
  policyTerm: Yup.object().shape({
    value: Yup.string().required('Policy term is required'),
  }),
  quoteStage: Yup.object().shape({
    value: Yup.string().required('Quote stage is required'),
  }),
  quoteType: Yup.object().shape({
    value: Yup.string().required('Quote type is required'),
  }),
  coverageType: Yup.object().shape({
    value: Yup.string().required('Coverage type is required'),
  }),
  effectiveDate: Yup.string().required('Effective date is required'),
  premium: Yup.string().required('Premium is required'),
  policyTermMonth: Yup.string()
    .matches(/^[0-9]+$/, 'Must be only digits')
    .max(2, 'Maximum 2 digit only'),
});

const EditQuoteModal = ({
  show,
  closeModal,
  type,
  UUID,
}: {
  show: string;
  closeModal: Function;
  type: string;
  UUID: string;
}) => {
  const dispatch = useDispatch<AppDispatch>();
  const { uuid } = useParams();

  const [showSuccess, setshowSuccess] = useState(false);
  const [opportunitySearch, setOpportunitySearch] = useState('');
  const [opportunity, setOpportunity] = useState('');
  const [opportunities, setOpportunities] = useState<any[]>([]);

  const [loadingStatus, setLoadingStatus] = useState('idle');

  const [accountSearch, setAccountSearch] = useState('');
  const [accounts, setAccounts] = useState<any[]>([]);
  const [account, setAccount] = useState('');

  const [errorMessage, setError] = useState('');
  const [isCommercial, setCommercial] = useState(false);
  const [carrierSearchText, setCarrierSearchText] = useState('');
  const [carriers, setCarriers] = useState<any>([]);
  const [carrier, setCarrier] = useState('');

  const [contacts, setContacts] = useState<any[]>([]);
  const [contactSearch, setContactSearch] = useState('');
  const [contact, setContact] = useState('');

  const [quoteStage, setQuoteStage] = useState<any[]>([]);
  const [quoteType, setQuoteType] = useState<any[]>([]);
  const [coverageType, setCoverageType] = useState<any[]>([]);
  const [submit, setSubmit] = useState(false);
  const [file, setFile] = useState<any>();
  const [types, setType] = useState('');
  const [typeUUID, setTypeUUID] = useState('');
  const [carrierType, setCarrierType] = useState('');
  const [carrierUUID, setCarrierUUID] = useState('');

  const {
    register,
    handleSubmit,
    control,
    reset,
    watch,
    setValue,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(SignupSchema),
  });
  const watchPolicyTerm = watch('policyTerm');
  const watchPolicyTermMonth = watch('policyTermMonth');

  const policyTerm = [
    { label: '6 months', value: '6 months' },
    { label: '12 months', value: '12 months' },
    { label: 'Custom', value: 'custom' },
  ];
  const documents = useSelector(selectAllDocuments);

  const DeleteDoc = (uuid: string) => {
    dispatch(deleteADocument({ uuid, successCB: () => {} }));
  };

  const watchAllFields = watch();

  const onCloseModal = () => {
    closeModal('closing');
    setTimeout(() => {
      closeModal('closed');
    }, 300);
  };

  const opportunityDetails = useSelector(selectOpportunity);
  const quoteDetails = useSelector(selectQuote);
  const loading = useSelector(
    (state: { quotes: QuoteSliceState }) => state.quotes.isLoading
  );
  useEffect(() => {
    if (show == 'open') {
      dispatch(
        fetchQuoteDetails({
          uuid: UUID,
          successCB: (quote: any) => {
            setValue('premium', quote.premium);
            if (quote.effectiveDate) {
              setValue('effectiveDate', moment(quote.effectiveDate).toDate());
            }
            if (quote.quotePresentedDate) {
              setValue(
                'quotePresentedDate',
                moment(quote.quotePresentedDate).toDate()
              );
            } else {
              setValue('quotePresentedDate', null);
            }
            setValue('quoteType', {
              label: quote.QuoteType.name
                .toLowerCase()
                .split('-')
                .map(
                  (word: string) => word[0].toUpperCase() + word.substring(1)
                )
                .join(' '),
              value: quote.QuoteType.uuid,
            });
            setValue('quoteStage', {
              label: quote.QuoteStage.name
                .toLowerCase()
                .split('-')
                .map(
                  (word: string) => word[0].toUpperCase() + word.substring(1)
                )
                .join(' '),
              value: quote.QuoteStage.uuid,
            });
            let policyTermValue = policyTerm.filter(
              (item) => item.value === quote.policyTerm
            );
            if (policyTermValue.length > 0) {
              setValue('policyTerm', {
                label: quote.policyTerm,
                value: quote.policyTerm,
              });
            } else {
              setValue('policyTermMonth', quote.policyTerm.split(' ')[0]);
              setValue('policyTerm', { label: 'Custom', value: 'custom' });
            }

            if (quote.CustomCarrier) {
              setCarrierType('CUSTOM_CARRIER');
              setCarrierSearchText(quote?.CustomCarrier?.name);
              setCarrier(quote?.CustomCarrier?.uuid);
              setCarrierUUID(quote?.CustomCarrier?.uuid);
            } else if (quote.Carrier) {
              setCarrierType('CARRIER');
              setCarrierSearchText(quote?.Carrier?.name);
              setCarrier(quote?.Carrier?.uuid);
              setCarrierUUID(quote?.Carrier?.uuid);
            }

            setContactSearch(
              quote.QuoteAccountContact[0].AccountContact.Contact.firstName +
                quote.QuoteAccountContact[0].AccountContact.Contact.lastName
            );
            setContact(
              quote.QuoteAccountContact[0].AccountContact.Contact.uuid
            );
            setValue('coverageType', {
              label: quote.CoverageType.name
                .toLowerCase()
                .split('-')
                .map(
                  (word: string) => word[0].toUpperCase() + word.substring(1)
                )
                .join(' '),
              value: quote.CoverageType.uuid,
            });
            if (quote?.Documents.length > 0) {
              setFile(quote?.Documents[0]);
              setType(quote?.Documents[0].DocumentType?.name);
              setTypeUUID(quote?.Documents[0].DocumentType?.uuid);
              dispatch(getDocuments({ quoteUUID: quote.uuid }));
            }
          },
        })
      );
    }
  }, [show]);

  const loadAccount = (value: string) => {
    if (loadingStatus === 'idle' || loadingStatus === 'succeeded') {
      setLoadingStatus('loading');
      fetchAccounts(
        'name',
        'asc',
        value,
        25,
        0,
        isCommercial ? 'COMMERCIAL' : 'PERSONAL',
        (data: IAccount[]) => {
          setAccounts(data);
          setLoadingStatus('succeeded');
          if (type === 'Opportunity') {
            setAccountSearch(accounts[0]?.name);
          }
        },
        'True',
        type === 'Contact' ? uuid : undefined,
        type === 'Opportunity' ? uuid : undefined
      );
    }
  };

  const onSubmit = (value: any) => {
    let check = true;
    if (watchPolicyTerm?.value === 'custom' && !watchPolicyTermMonth) {
      check = false;
    }
    value.typeUUID = value.quoteType.value;
    value.stageUUID = value.quoteStage.value;
    value.coverageTypeUUID = value.coverageType.value;
    value.accountUUID = type === 'Account' ? uuid : account;
    value.contactUUID = type === 'Contact' ? uuid : contact;
    value.opportunityUUID = type === 'Opportunity' ? uuid : opportunity;
    if (carrierType === 'CARRIER') {
      value.carrierUUID = carrierUUID;
    } else if (carrierType === 'CUSTOM_CARRIER') {
      value.customCarrierUUID = carrierUUID;
    }
    if (watchPolicyTerm?.value === 'custom') {
      value.policyTerm = value.policyTermMonth + ' months';
    } else {
      value.policyTerm = value.policyTerm.value;
    }
    if (UUID && check === true) {
      dispatch(
        onUpdateQuotes({
          data: value,
          successCB: () => {
            closeModal('closing');
            setTimeout(() => {
              setshowSuccess(true);
            }, 300);
            setTimeout(() => {
              reset();
              setshowSuccess(false);
              setTimeout(() => {
                closeModal('closed');
              }, 300);
            }, 4000);
          },
          errorCB: (error: string) => {
            if (typeof error === 'string') setError(error);
          },
          uuid: UUID,
        })
      );
    }
  };

  const loadCarrier = (value: string) => {
    if (loadingStatus === 'idle' || loadingStatus === 'succeeded') {
      setLoadingStatus('loading');
      fetchCarriers(
        'id',
        'asc',
        value,
        'True',
        (data: any) => {
          setCarriers(data);
          setLoadingStatus('succeeded');
        },
        undefined,
        0,
        0,
        opportunity
      );
    }
  };

  const loadContact = () => {
    if (loadingStatus === 'idle' || loadingStatus === 'succeeded') {
      fetchContacts(
        'id',
        'asc',
        contactSearch,
        25,
        0,
        'True',
        (data: any) => {
          setContacts(data);
          setLoadingStatus('succeeded');
        },
        isCommercial ? 'COMMERCIAL' : 'PERSONAL',
        type === 'Account' ? uuid : undefined,
        type === 'Opportunity' ? uuid : undefined
      );
    }
  };

  const loadOpportunity = (value: string) => {
    if (loadingStatus === 'idle' || loadingStatus === 'succeeded') {
      setLoadingStatus('loading');
      fetchOpportunities(
        'name',
        'asc',
        value,

        '',
        '',
        (data: any) => {
          setOpportunities(data?.opportunities);
          setLoadingStatus('succeeded');
        },
        '',
        '',
        25,
        0,
        type === 'Account' ? uuid : undefined
      );
    }
  };

  useEffect(() => {
    fetchQuoteStages((data: any[]) => {
      setQuoteStage(data);
    });
  }, []);

  useEffect(() => {
    fetchQuoteTypes((data: any[]) => {
      setQuoteType(data);
    });
  }, []);

  useEffect(() => {
    fetchCoverageTypes((data: any[]) => {
      setCoverageType(data);
    });
  }, []);

  useEffect(() => {
    if (type === 'Opportunity' && opportunityDetails) {
      setAccountSearch(opportunityDetails?.Account.name);
      setAccount(opportunityDetails?.Account.uuid);
    }
  }, [opportunityDetails]);

  // useEffect(() => {
  //   dispatch(
  //     getDocumentDetails({
  //       uuid: UUID,
  //       successCB: (data: any) => {},
  //     })
  //   );
  // }, []);
  return (
    <>
      <SuccessAlign>
        <div className={`success-container ${showSuccess && 'open-drawer'} `}>
          <SuccessCard
            title={'Success'}
            message={`<b>Quote has been updated</b>.`}
          />
          <button
            onClick={() => {
              reset();
              setshowSuccess(false);
            }}
            className='crossbutton'
          >
            <img src={cross} className='cross' alt='cross-icon' />
          </button>
        </div>
      </SuccessAlign>

      <Modal open={show} align='right'>
        <StyledEditQuoteModal>
          <button className='crossbutton' onClick={() => onCloseModal()}>
            <img src={cross} className='cross' alt='cross-icon' />
          </button>
          <div>
            <h2>Edit quote</h2>
          </div>
          <div className='row'></div>
          <div>
            <div>
              {show && !showSuccess && (
                <form onSubmit={handleSubmit(onSubmit)}>
                  <div className='scroll-bar'>
                    <div className='field-section'>
                      {type === 'Contact' || type === 'Opportunity' ? (
                        <div className='agency-fields'>
                          <SearchDropdown
                            name='account'
                            placeholder=' Associated account'
                            isEdit={true}
                            values={accountSearch}
                            searchValue={accounts}
                            setSearch={setAccountSearch}
                            status={loadingStatus}
                            disabled={type === 'Opportunity'}
                            className={`${
                              !account && submit && 'error-field'
                            } ${accountSearch && 'filled'}`}
                            register={register}
                            control={control}
                            required
                            setUUID={setAccount}
                            loadFunction={loadAccount}
                            content={accounts?.map((item) => (
                              <div
                                className='searchValuesCard d-flex align-items-center'
                                onClick={() => {
                                  setAccountSearch(item?.name);
                                  setAccount(item?.uuid);
                                }}
                              >
                                <div className='profile-pic d-flex align-items-center justify-content-center'>
                                  {item?.name?.charAt(0)}
                                </div>
                                <div>
                                  <h6>{item.name}</h6>
                                  <ul className='user-details'>
                                    <li>
                                      {isCommercial
                                        ? item?.businessEmail
                                        : item?.AccountContacts[0]?.Contact?.ContactDetails?.find(
                                            (value: any) =>
                                              value.type === 'EMAIL'
                                          )?.value}
                                    </li>
                                    <li>
                                      {isCommercial
                                        ? item?.businessPhoneNumber
                                        : item?.AccountContacts[0]?.Contact?.ContactDetails?.find(
                                            (value: any) =>
                                              value.type === 'PHONENUMBER'
                                          )?.value}
                                    </li>
                                  </ul>
                                </div>
                              </div>
                            ))}
                          />

                          {!account && submit ? (
                            <div className='errorVal'>
                              <img
                                src={warning}
                                alt='warning'
                                className='warning'
                              />
                              Account is required
                            </div>
                          ) : null}
                        </div>
                      ) : (
                        <></>
                      )}
                      {type === 'Account' || type === 'Opportunity' ? (
                        <div className='agency-fields'>
                          <SearchDropdown
                            name='contact'
                            placeholder='Associated contact'
                            isEdit={true}
                            values={contactSearch}
                            searchValue={contacts}
                            required
                            setSearch={setContactSearch}
                            status={loadingStatus}
                            className={`${
                              !contact && submit && 'error-field'
                            } ${contactSearch && 'filled'}`}
                            register={register}
                            setUUID={setContact}
                            control={control}
                            type='text'
                            loadFunction={loadContact}
                            content={
                              <>
                                {contacts?.map((item) => (
                                  <div
                                    className='searchValuesCard d-flex align-items-center'
                                    onClick={() => {
                                      setContactSearch(
                                        item?.firstName + item?.lastName
                                      );
                                      setContact(item?.uuid);
                                    }}
                                  >
                                    <div className='profile-pic d-flex align-items-center justify-content-center'>
                                      {item?.firstName?.charAt(0)}
                                    </div>
                                    <div>
                                      <h6>
                                        {' '}
                                        {item?.firstName} {item?.lastName}
                                      </h6>
                                      <ul className='user-details'>
                                        <li>
                                          {
                                            item?.ContactDetails?.find(
                                              (value: any) =>
                                                value.type === 'EMAIL'
                                            )?.value
                                          }
                                        </li>
                                        <li>
                                          {
                                            item?.ContactDetails?.find(
                                              (value: any) =>
                                                value.type === 'PHONENUMBER'
                                            )?.value
                                          }
                                        </li>
                                      </ul>
                                    </div>
                                  </div>
                                ))}
                              </>
                            }
                          />

                          {!contact && submit ? (
                            <div className='errorVal'>
                              <img
                                src={warning}
                                alt='warning'
                                className='warning'
                              />
                              Contact is required
                            </div>
                          ) : null}
                        </div>
                      ) : (
                        <></>
                      )}
                      {type === 'Contact' || type === 'Account' ? (
                        <div className='agency-fields'>
                          <SearchDropdown
                            name='opportunity'
                            placeholder='Associated opportunity'
                            isEdit={true}
                            values={opportunitySearch}
                            searchValue={opportunities}
                            setSearch={setOpportunitySearch}
                            required
                            status={loadingStatus}
                            setUUID={setOpportunity}
                            className={`${
                              !opportunity && submit && 'error-field'
                            } ${opportunitySearch && 'filled'}`}
                            register={register}
                            control={control}
                            loadFunction={loadOpportunity}
                            content={opportunities?.map((item: any) => (
                              <div
                                className='searchValuesCard d-flex align-items-center'
                                onClick={() => {
                                  setOpportunitySearch(item?.name);
                                  setOpportunity(item?.uuid);
                                }}
                              >
                                <div>
                                  <h6>{item?.name}</h6>
                                </div>
                              </div>
                            ))}
                          />

                          {!opportunity && submit && (
                            <div className='errorVal'>
                              <img
                                src={warning}
                                alt='warning'
                                className='warning'
                              />
                              Opportunity is required
                            </div>
                          )}
                        </div>
                      ) : (
                        <></>
                      )}
                      <div className='agency-fields'>
                        <SearchDropdown
                          name='carrierName'
                          placeholder='Carrier'
                          isEdit={true}
                          values={carrierSearchText}
                          searchValue={carriers}
                          setSearch={setCarrierSearchText}
                          status={loadingStatus}
                          required
                          setUUID={setCarrier}
                          setProductUUID={setCarrierUUID}
                          className={`${!carrier && submit && 'error-field'} ${
                            carrierSearchText && 'filled'
                          }`}
                          loadFunction={loadCarrier}
                          content={carriers?.map((item: any) => (
                            <div
                              className='searchValuesCard d-flex align-items-center'
                              onClick={() => {
                                setCarrierSearchText(item?.name);
                                setCarrier(item?.uuid);
                                setCarrierType(item.type);
                                setCarrierUUID(item.uuid);
                              }}
                            >
                              {item.logo ? (
                                <img
                                  src={item.logo}
                                  alt=''
                                  className='profile-pic'
                                />
                              ) : (
                                <div className='profile-pic d-flex align-items-center justify-content-center'>
                                  {item?.name?.charAt(0)}
                                  {item?.name?.charAt(0)}
                                </div>
                              )}
                              <div>
                                <h6>{item.name}</h6>
                              </div>
                            </div>
                          ))}
                        />
                        {!carrier && submit ? (
                          <div className='errorVal'>
                            <img
                              src={warning}
                              alt='warning'
                              className='warning'
                            />
                            Carrier is required
                          </div>
                        ) : null}
                      </div>
                      <div className='agency-fields'>
                        <SelectField
                          name='policyTerm'
                          options={policyTerm}
                          placeholder='Policy term'
                          control={control}
                          isEdit={true}
                          required
                        />
                        {errors.policyTerm ? (
                          <div className='errorVal'>
                            <img
                              src={warning}
                              alt='warning'
                              className='warning'
                            />
                            {errors.policyTerm?.message
                              ? 'Policy term required'
                              : errors.policyTerm?.value?.message}
                          </div>
                        ) : null}
                      </div>
                      {watchPolicyTerm?.value === 'custom' ? (
                        <div className='agency-fields'>
                          <InputField
                            name='policyTermMonth'
                            type='number'
                            placeholder='Policy Term in Months'
                            month='months'
                            register={register}
                            required
                            className={`${
                              errors.policyTermMonth && 'error-field'
                            } ${watchAllFields.policyTermMonth && 'filled'}`}
                          />
                          {watchPolicyTerm?.value === 'custom' &&
                          !watchPolicyTermMonth &&
                          submit ? (
                            <div className='errorVal'>
                              <img
                                src={warning}
                                alt='warning'
                                className='warning'
                              />
                              Policy term in month is required
                            </div>
                          ) : null}
                          {errors.policyTermMonth?.message && (
                            <div className='errorVal'>
                              <img
                                src={warning}
                                alt='warning'
                                className='warning'
                              />
                              {errors.policyTermMonth?.message}
                            </div>
                          )}
                        </div>
                      ) : null}
                      <div className='agency-fields'>
                        <SelectField
                          name='coverageType'
                          options={coverageType.map((item) => {
                            return {
                              value: item.uuid,
                              label: item.name
                                .toLowerCase()
                                .split('-')
                                .map(
                                  (word: string) =>
                                    word[0].toUpperCase() + word.substring(1)
                                )
                                .join(' '),
                            };
                          })}
                          placeholder='Coverage type'
                          control={control}
                          isEdit={true}
                          required
                        />
                        {errors.coverageType ? (
                          <div className='errorVal'>
                            <img
                              src={warning}
                              alt='warning'
                              className='warning'
                            />
                            {errors.coverageType?.message
                              ? 'Quote stage is required'
                              : errors.coverageType?.value?.message}
                          </div>
                        ) : null}
                      </div>
                      <div className='agency-fields'>
                        <SelectField
                          name='quoteStage'
                          options={quoteStage.map((item) => {
                            return {
                              value: item.uuid,
                              label: item.name
                                .toLowerCase()
                                .split('-')
                                .map(
                                  (word: string) =>
                                    word[0].toUpperCase() + word.substring(1)
                                )
                                .join(' '),
                            };
                          })}
                          placeholder='Quote stage'
                          required
                          control={control}
                          isEdit={true}
                        />
                        {errors.quoteStage ? (
                          <div className='errorVal'>
                            <img
                              src={warning}
                              alt='warning'
                              className='warning'
                            />
                            {errors.quoteStage?.message
                              ? 'Quote stage is required'
                              : errors.quoteStage?.value?.message}
                          </div>
                        ) : null}
                      </div>
                      <div className='agency-fields'>
                        <SelectField
                          name='quoteType'
                          options={quoteType.map((item) => {
                            return {
                              value: item.uuid,
                              label: item.name
                                .toLowerCase()
                                .split('-')
                                .map(
                                  (word: string) =>
                                    word[0].toUpperCase() + word.substring(1)
                                )
                                .join(' '),
                            };
                          })}
                          placeholder='Quote type'
                          control={control}
                          required
                          isEdit={true}
                        />
                        {errors.quoteType ? (
                          <div className='errorVal'>
                            <img
                              src={warning}
                              alt='warning'
                              className='warning'
                            />
                            {errors.quoteType?.message
                              ? 'Quote type is required'
                              : errors.quoteType?.value?.message}
                          </div>
                        ) : null}
                      </div>
                      <div className='agency-fields'>
                        <DateField
                          name='effectiveDate'
                          placeholder='Effective date'
                          required
                          control={control}
                          value={watchAllFields.effectiveDate}
                        />
                        {errors.effectiveDate?.message && (
                          <div className='errorVal'>
                            <img
                              src={warning}
                              alt='warning'
                              className='warning'
                            />
                            {errors.effectiveDate?.message}
                          </div>
                        )}
                      </div>
                      <div className='agency-fields'>
                        <DateField
                          name='quotePresentedDate'
                          placeholder='Quote presented date'
                          control={control}
                          value={watchAllFields.quotePresentedDate}
                        />
                      </div>

                      <div className='agency-fields'>
                        <InputField
                          name='premium'
                          type='number'
                          required
                          placeholder='Premium'
                          register={register}
                          prefix='$'
                          className={`${errors.premium && 'error-field'} ${
                            watchAllFields.premium && 'filled'
                          }`}
                        />
                        {errors.premium?.message && (
                          <div className='errorVal'>
                            <img
                              src={warning}
                              alt='warning'
                              className='warning'
                            />
                            {errors.premium?.message}
                          </div>
                        )}
                      </div>
                      {/* {file ? (
                        <div>
                          <div className='bottom-head'>Document (optional)</div>

                          <div className='documentDetails'>
                            {' '}
                            <img src={document} alt='' />
                            <span className='docName'>{file?.name}</span>
                            <div className='itemsDiv d-flex justify-content-center align-items-center'>
                              <button
                                className='documentDropDown d-flex justify-content-between align-items-center'
                                // onClick={() => setDropDown(!dropDown)}
                                // type='button'
                              >
                                {types ? types : 'Document type'}
                                <img src={vector} alt='' />
                                {dropDown && (
                                  <div className='documentTypeDrop'>
                                    {' '}
                                    {DocumentTypes &&
                                      DocumentTypes?.map((item: any) => (
                                        <div
                                          className='listType d-flex align-items-center '
                                          onClick={() => {
                                            setDropDown(false);
                                            setType(item?.name);
                                            setTypeUUID(item.uuid);
                                          }}
                                        >
                                          <ul>{item?.name}</ul>
                                        </div>
                                      ))}
                                  </div>
                                )}
                              </button>
                              <img
                                className='binImage'
                                src={bin}
                                alt=''
                                onClick={() => {
                                  // setFile('');
                                  // setTypeUUID('');
                                  // setType('');
                                  DeleteDoc(UUID);
                                  // setReapload(true);
                                }}
                              />
                            </div>
                          </div>
                        </div>
                      ) : null} */}
                      {documents &&
                        documents?.map((item: any) => (
                          <div>
                            <div className='bottom-head'>Documents</div>
                            <div className='documentDetails'>
                              <img src={document} alt='' />
                              <span className='docName'>{item?.name}</span>
                              <div className='itemsDiv d-flex justify-content-center align-items-center'>
                                <button
                                  className='documentDropDown d-flex justify-content-between align-items-center'
                                  type='button'
                                >
                                  {types ? types : 'Document type'}
                                  <img src={vector} alt='' />
                                </button>
                                <img
                                  className='binImage'
                                  src={bin}
                                  alt=''
                                  onClick={() => {
                                    DeleteDoc(item.uuid);
                                  }}
                                />
                              </div>
                            </div>
                          </div>
                        ))}
                    </div>
                  </div>
                  <div className='buttonsDiv d-flex align-items-center '>
                    <button
                      onClick={() => {
                        reset();
                        onCloseModal();
                      }}
                      className='cancel-button'
                      type='button'
                    >
                      Cancel
                    </button>
                    <button
                      type='submit'
                      className='agentButton'
                      onClick={() => setSubmit(true)}
                      disabled={
                        (watchAllFields?.premium === quoteDetails?.premium &&
                          moment(
                            watchAllFields?.quotePresentedDate
                          ).toISOString() ===
                            moment(
                              quoteDetails?.quotePresentedDate
                            ).toISOString() &&
                          moment(
                            watchAllFields?.effectiveDate
                          ).toISOString() ===
                            moment(quoteDetails?.effectiveDate).toISOString() &&
                          watchAllFields?.quoteType?.value ===
                            quoteDetails?.QuoteType?.uuid &&
                          watchAllFields?.quoteStage?.value ===
                            quoteDetails?.QuoteStage?.uuid &&
                          watchAllFields?.coverageType?.value ===
                            quoteDetails?.CoverageType?.uuid &&
                          carrierUUID === quoteDetails?.Carrier?.uuid &&
                          ((watchAllFields?.policyTerm?.value === 'custom' &&
                            watchPolicyTermMonth + ' months' ===
                              quoteDetails?.policyTerm) ||
                            watchPolicyTerm?.value ===
                              quoteDetails?.policyTerm) &&
                          contact ===
                            quoteDetails?.QuoteAccountContact[0]?.AccountContact
                              ?.Contact?.uuid) ||
                        loading
                      }
                    >
                      Save <img className='tick' src={tick} alt='tick-sign' />
                    </button>
                  </div>
                </form>
              )}
            </div>
          </div>
        </StyledEditQuoteModal>
      </Modal>
    </>
  );
};

export default EditQuoteModal;
