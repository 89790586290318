import { useEffect, useState } from 'react';
import Modal from '../../Modal';
import DocumentsField from '../../DocumentsField';
import { DocumentsMobileModalDiv } from '../../DocumentsInnerTab/DocumentTable/styles';
import tick from '../../../assets/logo/tick.svg';
import document from '../../../assets/logo/document.svg';
import vector from '../../../assets/logo/dropdown.svg';
import bin from '../../../assets/logo/bin.svg';
import cross from '../../../assets/logo/cross.svg';
import warning from '../../../assets/logo/warning.svg';
import { getDocumentType } from '../../../api/documents';
const DocumentAddModal = ({
  show,
  closeModal,
  setDocTypeUUID,
  docTypeUUID,
  name,
  file,
  setFile,
}: {
  file: any;
  setFile: Function;
  show: string;
  name: string;
  docTypeUUID: string;
  setDocTypeUUID: Function;
  closeModal: Function;
}) => {
  const [dropDown, setDropDown] = useState(false);
  const [DocumentTypes, setDocumentTypes] = useState<any>([]);
  const [fileError, setFileError] = useState('');
  const [type, setType] = useState('');
  const [error, setError] = useState('');
  useEffect(() => {
    getDocumentType((data: any) => {
      setDocumentTypes(data);
    });
  }, [show]);

  const erroValidation = () => {
    setError('please select a document type');
    return;
  };
  return (
    <>
      <Modal open={show} align='right'>
        <DocumentsMobileModalDiv>
          <div className='topHead'>
            <h2 className='topHeadTitle'>{'Add document'}</h2>
            <button
              onClick={() => {
                closeModal('closed');
              }}
              className='crossbutton'
            >
              <img src={cross} className='cross' alt='cross-icon' />
            </button>
          </div>

          <div className='drawer-content '>
            <DocumentsField
              setFile={setFile}
              file={file}
              setFileError={setFileError}
            />
          </div>

          {file && (
            <div className='documentDetails'>
              {' '}
              <div className='d-flex align-items-center'>
                <img src={document} alt='' />
                <span className='docName'>{file?.name}</span>
              </div>
              <div className='itemsDiv d-flex justify-content-between align-items-center'>
                <button
                  className='documentDropDown d-flex justify-content-between align-items-center'
                  onClick={() => setDropDown(!dropDown)}
                  type='button'
                >
                  {type ? type : 'Document type'}
                  <img src={vector} alt='' />
                  {dropDown && (
                    <div className='documentTypeDrop'>
                      {' '}
                      {DocumentTypes &&
                        DocumentTypes?.map((item: any) => (
                          <div
                            className='listType d-flex align-items-center '
                            onClick={() => {
                              setDropDown(false);
                              setType(item?.name);
                              setDocTypeUUID(item.uuid);
                            }}
                          >
                            <ul>{item?.name}</ul>
                          </div>
                        ))}
                    </div>
                  )}
                </button>
                <img
                  className='binImage'
                  src={bin}
                  alt=''
                  onClick={() => {
                    setFile('');
                    setDocTypeUUID('');
                    setType('');
                  }}
                />
              </div>
              {error && !docTypeUUID && (
                <div className='errorDIv d-flex align-items-center'>
                  <span className='errorVal'>
                    <img className='erricon' src={warning} alt='' />
                    {error}
                  </span>
                </div>
              )}
            </div>
          )}
          {fileError && (
            <div className='errorDIv d-flex align-items-center'>
              <span className='errorVal'>
                <img className='erricon' src={warning} alt='' />
                {fileError}
              </span>
            </div>
          )}
          <div className='buttonsDiv d-flex align-items-center'>
            <button
              onClick={() => closeModal('closing')}
              className='cancel-button'
              type='button'
            >
              Cancel
            </button>
            <button
              type='button'
              className='saveButton'
              onClick={() => {
                if (!docTypeUUID) {
                  erroValidation();
                } else {
                  closeModal('closing');
                  setTimeout(() => {
                    setTimeout(() => {
                      closeModal('closed');
                    }, 300);
                  }, 2000);
                }
              }}
            >
              Save <img className='tick' src={tick} alt='tick-sign' />
            </button>
          </div>
        </DocumentsMobileModalDiv>
      </Modal>
    </>
  );
};

export default DocumentAddModal;
