import { useState, useRef, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { ToastContainer } from 'react-toastify';
import { useLocation } from 'react-router-dom';
import { useNavigate } from 'react-router';
import { useOutsideClick } from '../../hooks/useOutsideClick';
import { clearUser, selectUser } from '../../store/user/userSlice';
import { NotificationSliceState } from '../../store/notifications/notificationsSlice';
import AddLeadDrawer from '../AddLeadDrawer';
import Link from '../Link';
import AgencyModal from '../../pages/Admin/AgencyList/components/AgencyModal';
import AgentModal from '../../pages/Admin/AgencyDetails/components/AgentModal';
import QuickAccountDrawer from '../../pages/MyBook/Accounts/components/QuickAddDrawer';
import QuickContactFrom from '../../pages/MyBook/Contacts/components/QuickAddForm';
import QuickAddOpportunityModal from '../QuickAddOpportunity';
import PolicyModal from '../../pages/MyBook/Policies/components/AddPolicyModal';
import AddQuoteModal from '../AddQuoteModal';
import MobileTabSidebarDrawer from '../MobileTabNavbarDrawer';
import AddOpportunityDrawer from '../AddOpportunityDrawer';
import LocalStorageService from '../../utils/LocalStorageService';
import { MobileNavDiv } from './style';
import Manage from '../../assets/logo/manage.svg';
import search from '../../assets/logo/search.svg';
import Hamburger from '../../assets/logo/MobileTabHamburger.svg';
import Profile from '../../assets/logo/Profile.svg';
import PaswordKey from '../../assets/logo/PaswordKey.svg';
import logOutButton from '../../assets/logo/redpower.svg';
import AddMob from '../../assets/logo/addMob.svg';
import bell from '../../assets/logo/bell.svg';
import logo from '../../assets/logo/logo.svg';
import contact from '../../assets/logo/contact.svg';
import account from '../../assets/logo/account.svg';
import policy from '../../assets/logo/policy.svg';

function MobileNav() {
  const dispatch = useDispatch();

  const user = useSelector(selectUser);
  const [showQuickAddDropDown, setShowQuickAddDropDown] = useState('closed');
  const [addQuickContact, setAddQuickContact] = useState('closed');
  const [addQuickLead, setAddQuickLead] = useState('closed');
  const [addQuickAccount, setAddQuickAccount] = useState('closed');
  const [addQuickOpportunity, setAddQuickOpportunity] = useState('closed');
  const [showQuickAddAgentAgency, setShowQuickAddAgentAgency] =
    useState('closed');
  const [addQuickAgent, setAddQuickAgent] = useState('closed');
  const [addQuickAgency, setAddQuickAgency] = useState('closed');
  const [showSearch, setShowSearch] = useState(false);
  const navigate = useNavigate();
  const [showDrop, setShowDrop] = useState(false);
  const [showMobileTabSidebarDrawer, setShowMobileTabSidebarDrawer] =
    useState('closed');
  const dropRef = useRef(null);
  const searchRef = useRef(null);
  const url = useLocation();
  const [longOpportunityForm, setLongOpportunityForm] = useState('');
  const [showSearchDrop, setShowSearchDrop] = useState(false);
  const [quickAddPolicy, setQuickAddPolicy] = useState('closed');
  const [showQuoteModal, setShowQuoteModal] = useState('closed');
  useOutsideClick(dropRef, setShowQuickAddDropDown);
  useOutsideClick(searchRef, setShowSearchDrop);
  useOutsideClick(searchRef, setShowSearch);

  const notificationssummaryCount = useSelector(
    (state: { notifications: NotificationSliceState }) =>
      state.notifications.summaryCount
  );

  return (
    <MobileNavDiv ref={searchRef}>
      <div className='navbarcontent d-flex justify-content-between'>
        <div
          onClick={() => {
            setShowDrop(false);
            setShowMobileTabSidebarDrawer('open');
          }}
        >
          <img
            src={Hamburger}
            alt='Hamburger menu'
            className='nav-logo d-block'
          />
        </div>
        {/* <Link to='/dashboard'>
          <img src={DashboardMob} className='nav-logo d-block' alt='logo' />
        </Link> */}
        {user?.UserRole.name !== 'ADMIN' || 'PRINCIPAL_AGENT' ? (
          <>
            <button
              className={`nav-logo manage-logo ${
                url.pathname === '/search' && 'activeDiv'
              }  d-block`}
              onClick={() => {
                navigate('/search');
              }}
            >
              <img
                src={search}
                alt='search'
                className={`nav-logo ${
                  url.pathname === '/search' && 'activeClass'
                } d-block`}
              />
            </button>
            {/* <Link to='/mybook' className='nav-logo manage-logo  d-block'>
              <img src={mybook} alt='logo' />
            </Link>
            <Link to='/pipeline' className='nav-logo manage-logo  d-block'>
              <img src={Manage} alt='logo' />
            </Link> */}

            {/* <Link
              to='/myteam'
              className='nav-logo myteam-logo manage-logo  d-block'
            >
              <img src={myteam} alt='logo' />
            </Link> */}

            <div
              className=' quickAddButton'
              onClick={() =>
                setShowQuickAddDropDown(
                  showQuickAddDropDown === 'closed' ? 'open' : 'closed'
                )
              }
              ref={dropRef}
            >
              <img
                src={AddMob}
                alt='add'
                className={
                  showQuickAddDropDown === 'open'
                    ? 'nav-logo mobileQuickAdd  d-block  '
                    : 'nav-logo  d-block'
                }
              />

              {showQuickAddDropDown === 'open' && (
                <>
                  <div className='backgroundQuickAdd'>
                    <div
                      className={`quickFormsDropDown ${showQuickAddDropDown}`}
                    >
                      <div className='d-flex  drawerHandle'></div>
                      <h2>Quick add</h2>
                      <div
                        className='quickFormBtn'
                        onClick={() => {
                          setAddQuickLead('open');
                        }}
                      >
                        <h6>
                          <img className='imageIcon' src={contact} alt='icon' />
                          Add Lead
                        </h6>
                      </div>
                      <div
                        className='quickFormBtn'
                        onClick={() => {
                          setAddQuickContact('open');
                        }}
                      >
                        <h6>
                          <img className='imageIcon' src={contact} alt='icon' />
                          Add Contact
                        </h6>
                      </div>
                      <div
                        className='quickFormBtn'
                        onClick={() => {
                          setAddQuickAccount('open');
                        }}
                      >
                        <h6>
                          <img className='imageIc' src={account} alt='icon' />
                          Add Account{' '}
                        </h6>
                      </div>
                      <div
                        className='quickFormBtn'
                        onClick={() => {
                          setAddQuickOpportunity('open');
                        }}
                      >
                        <h6>
                          <img className='imageIcon' src={policy} alt='icon' />
                          Add Opportunity{' '}
                        </h6>
                      </div>
                      <div
                        className='quickFormBtn'
                        onClick={() => {
                          setShowQuoteModal('open');
                        }}
                      >
                        {' '}
                        <h6>
                          <img className='imageIc' src={account} alt='icon' />
                          Add Quote{' '}
                        </h6>
                      </div>
                      <div
                        className='quickFormBtn lastQuickFormBtn '
                        onClick={() => {
                          setQuickAddPolicy('open');
                        }}
                      >
                        <h6>
                          <img className='imageIcon' src={policy} alt='icon' />
                          Add Policy{' '}
                        </h6>
                      </div>
                    </div>
                  </div>
                </>
              )}
            </div>
            <div
              className={`nav-logo ${
                url.pathname === '/notification' && 'activeClass'
              }`}
            >
              <img
                src={bell}
                alt='logo'
                className={
                  notificationssummaryCount
                    ? ' d-block nav-logo activatelogo'
                    : 'd-block nav-logo'
                }
                onClick={() => {
                  setShowDrop(false);
                  navigate('/notification');
                }}
              />
              {notificationssummaryCount > 0 && (
                <span
                  className={`notificationCounts ${
                    notificationssummaryCount > 9 && 'smallCount'
                  }`}
                >
                  {notificationssummaryCount > 9
                    ? '9 +'
                    : notificationssummaryCount}
                </span>
              )}
            </div>
          </>
        ) : (
          <>
            <Link to='/admin'>
              <img
                src={Manage}
                className='nav-logo manage-logo  d-block'
                alt='logo'
              />
            </Link>
            <div
              className=' quickAddButton'
              onClick={() =>
                setShowQuickAddAgentAgency(
                  showQuickAddAgentAgency === 'closed' ? 'open' : 'closed'
                )
              }
              ref={dropRef}
            >
              <img
                src={AddMob}
                alt='add'
                className={
                  showQuickAddAgentAgency === 'open'
                    ? 'nav-logo mobileQuickAdd  d-block  '
                    : 'nav-logo  d-block'
                }
              />
              {showQuickAddAgentAgency === 'open' && (
                <>
                  <div className='backgroundQuickAdd'>
                    <div
                      className={`quickFormsDropDown ${showQuickAddAgentAgency}`}
                    >
                      <div className='d-flex drawerHandle'></div>
                      <h2>Quick add</h2>
                      <div
                        className='quickFormBtn'
                        onClick={() => {
                          setAddQuickAgency('open');
                        }}
                      >
                        <h6> Add Agency</h6>
                      </div>
                      <div
                        className='quickFormBtn'
                        onClick={() => {
                          setAddQuickAgent('open');
                        }}
                      >
                        <h6> Add Agent</h6>
                      </div>
                    </div>
                  </div>
                </>
              )}
            </div>
          </>
        )}
        {user?.avatar ? (
          <img
            alt='profilepic'
            className='avatar-img'
            src={user.avatar}
            onClick={() => setShowDrop(!showDrop)}
          />
        ) : (
          // <div className='profileDiv'>
          <div className='avatar-div' onClick={() => setShowDrop(!showDrop)}>
            {user?.firstName || user?.lastName
              ? user?.firstName?.substring(0, 1).toUpperCase() +
                user?.lastName?.substring(0, 1).toUpperCase()
              : user?.email[0]?.substring(0, 1).toUpperCase()}

            {/* </div> */}
          </div>
        )}
        {showDrop ? (
          <div className='profile-drop'>
            <div className='topContainer'>
              <img className='logo-svg' src={logo} alt='logo' />
              <div className='logoAvatar d-flex align-items-center'>
                {user?.avatar ? (
                  <img
                    alt='profilepic'
                    className='avatar-img'
                    src={user?.avatar}
                    onClick={() => setShowDrop(!showDrop)}
                  />
                ) : (
                  <div
                    className='avatar-div'
                    onClick={() => setShowDrop(!showDrop)}
                  >
                    {user?.firstName || user?.lastName
                      ? user?.firstName?.substring(0, 1).toUpperCase() +
                        user?.lastName?.substring(0, 1).toUpperCase()
                      : user?.email[0]?.substring(0, 1).toUpperCase()}
                  </div>
                )}
                <div className='profileNames'>
                  <div className='text-overflow-container'>
                    <h2>{user?.firstName + ' ' + user?.lastName}</h2>
                    <span className='tooltiptext'>
                      <span className='tooltipInnertext'>
                        {user?.firstName + ' ' + user?.lastName}
                      </span>
                    </span>
                  </div>
                  <div className='text-overflow-container'>
                    <h6>
                      {LocalStorageService.getUser()?.Agent?.Agencies?.name}
                    </h6>
                    <span className='tooltiptext'>
                      <span className='tooltipInnertext'>
                        {LocalStorageService.getUser().Agent?.Agencies?.name}
                      </span>
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div className='bottomContainer'>
              <div className='navigationItems'>
                {' '}
                <button
                  className='profileButtonDiv'
                  onClick={() => {
                    setShowDrop(false);
                    navigate('/myprofile/profilesetting');
                  }}
                >
                  <img src={Profile} className='ButtonImage' alt='' />
                  My Profile
                </button>
              </div>
              <div className='navigationItems'>
                {' '}
                <button
                  className='profileButtonDiv'
                  onClick={() => {
                    setShowDrop(false);
                    navigate('/myprofile/notificationsettings');
                  }}
                >
                  <img src={bell} className='ButtonImage' alt='' />
                  Notification Settings
                </button>
              </div>
              <div className='navigationItems'>
                {' '}
                <button
                  className='profileButtonDiv'
                  onClick={() => {
                    setShowDrop(false);
                    navigate('/myprofile/changepassword');
                  }}
                >
                  <img src={PaswordKey} className='ButtonImage' alt='' />
                  Change Password
                </button>
              </div>
              <div className='navigationItems'>
                <button
                  className='logOutButtonDiv'
                  onClick={() => {
                    setShowDrop(false);
                    dispatch(clearUser());
                    LocalStorageService.clearToken();
                    navigate('/');
                  }}
                >
                  <img
                    src={logOutButton}
                    className='ButtonLogOutImage'
                    alt=''
                  />
                  Log out
                </button>
              </div>
            </div>
          </div>
        ) : null}

        {addQuickAccount !== 'closed' && (
          <QuickAccountDrawer
            show={addQuickAccount}
            closeModal={setAddQuickAccount}
          />
        )}
        {addQuickContact !== 'closed' && (
          <QuickContactFrom
            show={addQuickContact}
            closeModal={setAddQuickContact}
          />
        )}
        {addQuickOpportunity !== 'closed' && (
          <QuickAddOpportunityModal
            show={addQuickOpportunity}
            setLongOpportunityForm={setLongOpportunityForm}
            closeModal={setAddQuickOpportunity}
          />
        )}
        {longOpportunityForm !== 'closed' && longOpportunityForm !== '' && (
          <AddOpportunityDrawer
            show={longOpportunityForm}
            closeModal={setLongOpportunityForm}
          />
        )}
        {addQuickLead !== 'closed' && (
          <AddLeadDrawer show={addQuickLead} closeModal={setAddQuickLead} />
        )}
        {addQuickAgent !== 'closed' && (
          <AgentModal show={addQuickAgent} closeModal={setAddQuickAgent} />
        )}
        {addQuickAgency !== 'closed' && (
          <AgencyModal show={addQuickAgency} closeModal={setAddQuickAgency} />
        )}
      </div>
      {showMobileTabSidebarDrawer !== 'closed' && (
        <MobileTabSidebarDrawer
          show={showMobileTabSidebarDrawer}
          closeModal={setShowMobileTabSidebarDrawer}
        />
      )}
      {quickAddPolicy !== 'closed' && (
        <PolicyModal show={quickAddPolicy} closeModal={setQuickAddPolicy} />
      )}
      {showQuoteModal !== 'closed' && (
        <AddQuoteModal
          show={showQuoteModal}
          closeModal={setShowQuoteModal}
          type='Contact'
          page='quickQuote'
        />
      )}
      <ToastContainer
        position='top-right'
        autoClose={2000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </MobileNavDiv>
  );
}

export default MobileNav;
