import { useRef, useState } from 'react';
import Moment from 'react-moment';
import { CardDiv } from '../../../../styles/CardDiv';
import vector from '../../../../assets/logo/dropdown.svg';
import LeadDetailModal from './LeadDetailModal';
import EditLeadDrawer from '../../../../components/EditLeadDrawer';
import bin from '../../../../assets/logo/bin.svg';
import edit from '../../../../assets/logo/edit.svg';
import phone from '../../../../assets/logo/phone.svg';
import message from '../../../../assets/logo/message-action.svg';
import mail from '../../../../assets/logo/mail.svg';
import { useOutsideClick } from '../../../../hooks/useOutsideClick';
import { ILead } from '../../../../interfaces/Lead';
import { SuccessAlign } from '../../../../styles/SuccessAlign';
import SuccessCard from '../../../../components/SuccessCard';
import cross from '../../../../assets/logo/cross.svg';
import QualifyLeadsModal from '../../../../components/QualifyLeadsModal';
import junk from '../../../../assets/logo/junk.svg';
import LeadJunkModal from '../../../../components/LeadJunkModal';

const LeadCard = ({ lead }: { lead: ILead }) => {
  const [showDropdown, setShowDropdown] = useState(false);
  const [showDetailModal, setShowDetailModal] = useState('closed');
  const [showEditDetailModal, setShowEditDetailModal] = useState('closed');
  const [emailCopied, isEmailCopied] = useState(false);
  const [showQualifyLead, setShowQualifyLead] = useState(false);
  const [showJunkLead, setShowJunkLead] = useState(false);

  const dropRef = useRef(null);

  useOutsideClick(dropRef, setShowDropdown);

  const handleCopy = (e: any, value: string) => {
    e.preventDefault();
    navigator.clipboard.writeText(value);
    isEmailCopied(true);
    setTimeout(() => {
      isEmailCopied(false);
    }, 2000);
  };

  const qualifyFunction = (uuid: string) => {
    // if (loadingStatus === 'idle' || loadingStatus === 'succeeded') {
    //   setLoadingStatus('loading');
    //   qualifyLead(
    //     uuid,
    //     (data: any) => {
    //       setShowQualifyLead(false);
    //       setLoadingStatus('succeeded');
    //     },
    //     (err: string) => {
    //       setShowEditDetailModal('open');
    //       setLoadingStatus('succeeded');
    //       setShowQualifyLead(false);
    //     }
    //   );
    // }
  };
  return (
    <CardDiv>
      <td className='row-name name-td'>
        <input type='checkbox' name='remember' className='form-check-input' />
        <b
          onClick={() => {
            setShowDetailModal('open');
          }}
        >
          {lead.data.contact?.firstName
            ? lead.data.contact?.firstName + ' ' + lead.data.contact?.lastName
            : '-'}
        </b>
      </td>
      <td>
        New
        {/* TODO Add status */}
      </td>
      <td>
        {lead.data.accountInfo.accountType?.charAt(0) +
          lead.data.accountInfo.accountType?.slice(1).toLowerCase()}
      </td>
      <td>
        <Moment format='MM/DD/YYYY'>{lead.createdAt}</Moment>
      </td>
      <td className='d-none d-xl-table-cell' style={{ fontWeight: 600 }}>
        <div
          className='clipboard-div txt'
          style={{ cursor: 'pointer' }}
          onClick={(event) => {
            handleCopy(event, `${lead.data.contact?.email}`);
          }}
        >
          {lead.data.contact?.email}
        </div>
      </td>
      <td className='d-none d-xl-table-cell' style={{ fontWeight: 600 }}>
        <div
          style={{ cursor: 'pointer' }}
          onClick={(event) =>
            handleCopy(event, `${lead.data.contact?.phoneNumber}`)
          }
          className='phone-div row-name'
        >
          {lead.data.contact?.phoneNumber}
          <div className='contact-tooltip'>
            <div className='action-item'>
              <img src={phone} alt='phone' />
            </div>
            <div className='action-item'>
              <img src={mail} alt='mail' />
            </div>
            <div className='action-item'>
              <img src={message} alt='message' />
            </div>
          </div>
        </div>
      </td>
      <td className='actionField'>
        <div className='d-flex align-items-center justify-content-between'>
          <div
            className='actionButton'
            onClick={() => setShowQualifyLead(true)}
          >
            Qualify
          </div>
          <div
            className='vector'
            onClick={() => setShowDropdown(!showDropdown)}
            ref={dropRef}
          >
            <img src={vector} alt='vector' />
          </div>

          {showDropdown && (
            <div className='dropdown-div'>
              <div
                className='edit-div'
                onClick={() => {
                  setShowDropdown(false);
                  setShowEditDetailModal('open');
                }}
              >
                Edit <img src={edit} alt='bin' />
              </div>
              onClick={() => setShowJunkLead(true)}
              <div>
                Mark as Junked <img src={junk} alt='junk-icon' />
              </div>
              <div className='delete-div'>
                Delete <img src={bin} alt='bin' />
              </div>
            </div>
          )}
        </div>
        {/* TODO: Loading */}
        {showQualifyLead ? (
          <QualifyLeadsModal
            open={showQualifyLead}
            closeModal={setShowQualifyLead}
            qualifyFunction={qualifyFunction}
            uuid={lead.uuid}
            loading={'idle'}
          />
        ) : null}
        {showDetailModal !== 'closed' && (
          <LeadDetailModal
            show={showDetailModal}
            closeModal={setShowDetailModal}
            UUID={lead.uuid}
          />
        )}
        {showEditDetailModal !== 'closed' && (
          <EditLeadDrawer
            show={showEditDetailModal}
            closeModal={setShowEditDetailModal}
            UUID={lead.uuid}
          />
        )}
        {showJunkLead ? (
          <LeadJunkModal
            open={showJunkLead}
            closeModal={setShowJunkLead}
            junkFunction={qualifyFunction}
            uuid={lead.uuid}
            loading={'idle'}
          />
        ) : null}
      </td>
      {emailCopied && (
        <>
          {' '}
          <SuccessAlign>
            <div
              className={`success-container ${emailCopied && 'open-drawer'} `}
            >
              <SuccessCard
                title={'Success Copying Email'}
                message={`Email has been added to clipboard<b>`}
              />
              <button
                onClick={() => {
                  // reset();
                  isEmailCopied(false);
                }}
                className='crossbutton'
              >
                <img src={cross} className='cross' alt='cross-icon' />
              </button>
            </div>
          </SuccessAlign>
        </>
      )}
    </CardDiv>
  );
};

export default LeadCard;
