import { useState, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { AppDispatch } from '../../../store';
import { StyledCard } from '../../MyBook/elements/styles';
import { useOutsideClick } from '../../../hooks/useOutsideClick';
import { IAgent } from '../../../interfaces/User';
import phone from '../../../assets/logo/phone.svg';
import dollar from '../../../assets/logo/dollar.svg';
import power from '../../../assets/logo/power.svg';
import redpower from '../../../assets/logo/redpower.svg';
import mail from '../../../assets/logo/mail.svg';
import vector from '../../../assets/logo/dropdown.svg';
import DeactivateUser from '../../Admin/AgencyDetails/components/DeactivateUser';
import { updateAgentDetail } from '../../../store/agents/agentsSlice';
import SetGoalModal from './SetGoalDrawer';
import LocalStorageService from '../../../utils/LocalStorageService';

const MyTeamMobileCard = ({
  agent,
  loadingStatus,
}: {
  agent: IAgent;
  loadingStatus: any;
}) => {
  const [showDropdown, setShowDropdown] = useState(false);
  const [showDeactivateModal, setShowDeactivateModal] = useState(false);
  const [showGoalModal, setShowGoalModal] = useState('closed');

  const dropRef = useRef(null);
  useOutsideClick(dropRef, setShowDropdown);
  const dispatch = useDispatch<AppDispatch>();

  const updateAgentStatus = (
    uuid: string,
    data: { action: string; status: string }
  ) => {
    if ((loadingStatus === 'idle' || loadingStatus === 'succeeded') && uuid) {
      dispatch(updateAgentDetail({ uuid, data }));
    }
  };
  const user = LocalStorageService.getUser();

  return (
    <StyledCard>
      <div>
        <div className='d-flex justify-content-between align-items-center'>
          <div>
            <h6>{agent?.User?.firstName + ' ' + agent?.User?.lastName}</h6>
          </div>
          <div
            className={`status ${
              agent?.AgentStatus?.name === 'ACTIVE'
                ? 'statusActive'
                : 'statusInvited'
            }`}
          >
            {agent.AgentStatus?.name
              .toLowerCase()
              .split('_')
              .map((word: any) => word[0].toUpperCase() + word.substring(1))
              .join(' ')}
          </div>
        </div>
        <div className='d-flex'>
          <div className='round-progressbar'></div>
          <div>
            Policy goal{' '}
            <b>
              {agent.policyCount ? agent.policyCount : 0}/{agent.policyGoal}
            </b>
          </div>
        </div>
        <div className='d-flex'>
          <div className='round-progressbar'></div>
          <div>
            Prem.goal{' '}
            <b>
              {'$'}
              {agent.policyPremium
                ? agent.policyPremium.toLocaleString('en-US')
                : 0}
              /{'$'}
              {agent.premiumGoal?.toLocaleString('en-US')}
            </b>
          </div>
        </div>

        <div className='info-div'></div>

        <div className='d-flex justify-content-between'>
          <div>
            {' '}
            <span style={{ fontWeight: 'bold' }}>0</span> Ref.partners
            <br />
            <b>
              {'$'}
              {agent.pipelinePremium
                ? agent.pipelinePremium.toLocaleString('en-US')
                : 0}
            </b>{' '}
            Pl.premium
          </div>
          <div className='d-flex'>
            <div className='action-item'>
              <img src={mail} alt='mail' />
            </div>
            <div className='action-item'>
              <img src={phone} alt='phone' />
            </div>
            <div className='vector-div'>
              <div
                className='vector d-flex align-items-center justify-content-center'
                onClick={() => setShowDropdown(!showDropdown)}
                ref={dropRef}
              >
                <img src={vector} alt='vector' />
              </div>
              {showDropdown && (
                <div className='drop-Div'>
                  <div
                    className='agentdrop-mob '
                    onClick={() => {
                      setShowDropdown(false);
                      setShowGoalModal('open');
                    }}
                  >
                    Set Goals <img src={dollar} alt='dollar' />
                  </div>
                  {agent?.AgentStatus?.name === 'ACTIVE' ? (
                    <div
                      className={` ${
                        agent.User.uuid === user?.uuid
                          ? 'disableStyle'
                          : 'inactive-drop'
                      }`}
                      onClick={() => {
                        if (agent.User.uuid === user?.uuid) {
                          setShowDeactivateModal(false);
                        } else {
                          setShowDeactivateModal(true);
                        }
                      }}
                    >
                      Deactivate
                      <img src={redpower} alt='redpower' />
                    </div>
                  ) : (
                    <div>
                      <div
                        className='active-drop'
                        onClick={() => {
                          updateAgentStatus(agent?.uuid, {
                            action: 'updateStatus',
                            status: 'ACTIVE',
                          });
                        }}
                      >
                        Activate
                        <img src={power} alt='power' />
                      </div>
                    </div>
                  )}
                </div>
              )}
            </div>
          </div>
          {/* {showEditAccountForm !== 'closed' && (
          <EditAccountDrawer
            show={showEditAccountForm}
            closeModal={setShowEditAccountForm}
            uuid={account.uuid}
          />
        )} */}
        </div>
      </div>
      {showDeactivateModal && (
        <DeactivateUser
          showModal={showDeactivateModal}
          closeModal={setShowDeactivateModal}
          desablingAgent={agent}
        />
      )}
      {showGoalModal !== 'closed' && (
        <SetGoalModal
          show={showGoalModal}
          closeModal={setShowGoalModal}
          agentUUID={agent.uuid}
        />
      )}
    </StyledCard>
  );
};

export default MyTeamMobileCard;
