import { useSelector } from 'react-redux';
import EmptyResults from '../../../MyBook/components/Empty';
import LoadingSkeleton from '../../../MyBook/components/Loading';
import { AgentsSliceState } from '../../../../store/agents/agentsSlice';
import { TableDiv } from '../../../../styles/TableDiv';
import sort from '../../../../assets/logo/sort.svg';
import filter from '../../../../assets/logo/filter.svg';
import LocationCard from './LocationCard';
import { ILocation } from '../../../../interfaces/Location';

const LocationTable = ({
  agents,
  setSortBy,
  setOrderBy,
  sortBy,
  orderBy,
}: {
  agents: ILocation[];
  setSortBy: Function;
  setOrderBy: Function;
  sortBy: string;
  orderBy: string;
}) => {
  const loadingStatus = useSelector(
    (state: { locations: AgentsSliceState }) => state.locations.status
  );

  const handleSort = (sort: string) => {
    if (sort === sortBy) {
      if (orderBy === 'asc') {
        setOrderBy('desc');
      } else {
        setOrderBy('asc');
      }
    } else {
      setSortBy(sort);
    }
  };

  return (
    <TableDiv>
      <thead>
        <tr>
          <th className='agencyNameHead'>
            <div className='d-flex'>
              <div className='w-75 d-flex justify-content-between'>
                Location name
                <img
                  className='sort'
                  src={sort}
                  alt='sort'
                  onClick={() => handleSort('name')}
                />
              </div>
            </div>
          </th>
          <th className='roleFieldHead'>
            <div className='role d-flex justify-content-between '>Address</div>
          </th>

          <th className='emailFieldHead d-none d-xl-table-cell'>
            <div className='d-flex justify-content-between'>
              <div> Phone Number </div>
            </div>
          </th>
          <th className='phoneFieldHead d-none d-xl-table-cell'>
            <div className=' d-flex justify-content-between '>Fax number</div>
          </th>
          <th className='actionFieldHead'>Actions</th>
        </tr>
      </thead>
      <tbody>
        {loadingStatus === 'loading' ? (
          <LoadingSkeleton columns={6} />
        ) : loadingStatus === 'succeeded' && agents.length > 0 ? (
          agents.map((el) => <LocationCard />)
        ) : (
          <tr className='empty-div'>
            <td colSpan={5} className='d-xl-none'>
              <EmptyResults name='location' />
            </td>
            <td colSpan={7} className='d-none d-xl-table-cell'>
              <EmptyResults name='location' />
            </td>
          </tr>
        )}
      </tbody>
    </TableDiv>
  );
};

export default LocationTable;
