import { useSelector } from 'react-redux';
import sort from '../../../../assets/logo/sort.svg';
import sortup from '../../../../assets/logo/sortup.svg';
import sortdown from '../../../../assets/logo/sortdown.svg';
import LeadCard from './LeadCard';
import { ILead } from '../../../../interfaces/Lead';
import { LeadsSliceState } from '../../../../store/leads/leadsSlice';
import { TableDiv } from '../../../../styles/TableDiv';
import EmptyResults from '../../components/Empty';
import LoadingSkeleton from '../../components/Loading';

const LeadsTable = ({
  leads,
  setSortBy,
  setOrderBy,
  sortBy,
  orderBy,
}: {
  leads: ILead[];
  setSortBy: Function;
  setOrderBy: Function;
  sortBy: string;
  orderBy: string;
}) => {
  const handleSort = (sort: string) => {
    if (sort === sortBy) {
      if (orderBy === 'asc') {
        setOrderBy('desc');
      } else {
        setOrderBy('asc');
      }
    } else {
      setSortBy(sort);
    }
  };

  const loadingStatus = useSelector(
    (state: { leads: LeadsSliceState }) => state.leads.status
  );

  return (
    <TableDiv>
      <thead>
        <tr>
          <th className='agencyNameHead'>
            <div className='d-flex'>
              <div className='w-75 d-flex justify-content-between'>
                Lead name
                <img
                  className='sort'
                  src={
                    sortBy !== 'firstName'
                      ? sort
                      : orderBy === 'asc'
                      ? sortdown
                      : sortup
                  }
                  alt='sort'
                  onClick={() => handleSort('firstName')}
                />
              </div>
            </div>
          </th>
          <th className='statusContainerHead'>Status</th>
          <th className='statusContainerHead'>Type</th>
          <th className='dateFieldHead'>
            <div className=' d-flex justify-content-between '>
              Added date
              <img
                className='sort'
                src={
                  sortBy !== 'createdAt'
                    ? sort
                    : orderBy === 'asc'
                    ? sortdown
                    : sortup
                }
                alt='sort'
                onClick={() => handleSort('createdAt')}
              />
            </div>
          </th>
          <th className='leadsEmailFieldHead d-none d-xl-table-cell'>Email</th>
          <th className='leadsPhoneFieldHead d-none d-xl-table-cell'>
            Phone number{' '}
          </th>
          <th className='leadActionsFieldHead'>Actions</th>
        </tr>
      </thead>
      <tbody>
        {loadingStatus === 'loading' ? (
          <LoadingSkeleton columns={7} />
        ) : loadingStatus === 'succeeded' && leads.length > 0 ? (
          leads.map((el) => <LeadCard lead={el} key={el.uuid} />)
        ) : (
          <tr className='empty-div'>
            <td colSpan={5} className='d-xl-none'>
              <EmptyResults name='lead' />
            </td>
            <td colSpan={7} className='d-none d-xl-table-cell'>
              <EmptyResults name='lead' />
            </td>
          </tr>
        )}
      </tbody>
    </TableDiv>
  );
};

export default LeadsTable;
