import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import {
  fetchCarriers,
  fetchCarrier,
  UpdateCarrier,
  addCarrier,
  updateCarrierData,
  deleteCarrier,
} from '../../api/carriers';
import { ICarrier } from '../../interfaces/Carriers';

interface IProps {
  sortBy: string;
  searchBy: string;
  orderBy: string;
  minimal: string;
  limit: number;
  offset: number;
  agencyUUID?: string;
  successCB: Function;
  type?: string;
  isActive?: boolean;
  customProductUUIDs?: any;
  jurisdictionUUIDs?: string;
  productUUIDs?: any;
}

export type CarrierSliceState = {
  status: string;
  carriers: ICarrier[];
  count: number;
  batchCarriers: ICarrier[];
  batchCount: number;
};

const initialState: CarrierSliceState = {
  status: 'idle',
  carriers: [],
  count: 0,
  batchCarriers: [],
  batchCount: 0,
};

export const getCarriers = createAsyncThunk(
  'carriers/getCarriers',
  async ({
    sortBy,
    searchBy,
    orderBy,
    minimal,
    agencyUUID,
    successCB,
    limit,
    offset,
    type,
    isActive,
    customProductUUIDs,
    jurisdictionUUIDs,
    productUUIDs,
  }: IProps) => {
    const response = await fetchCarriers(
      sortBy,
      orderBy,
      searchBy,
      minimal,
      () => {},
      false,
      limit,
      offset,
      undefined,
      agencyUUID,
      type,
      isActive,
      customProductUUIDs,
      jurisdictionUUIDs,
      productUUIDs
    );
    successCB(response.data);
    response.data.type = type;
    return response.data;
  }
);

export const getBatchCarriers = createAsyncThunk(
  'carriers/getBatchCarriers',
  async ({
    sortBy,
    searchBy,
    orderBy,
    minimal,
    agencyUUID,
    successCB,
    limit,
    offset,
    type,
    customProductUUIDs,
    jurisdictionUUIDs,
    productUUIDs,
  }: IProps) => {
    const response = await fetchCarriers(
      sortBy,
      orderBy,
      searchBy,
      minimal,
      () => {},
      false,
      limit,
      offset,
      undefined,
      agencyUUID,
      type,
      undefined,
      customProductUUIDs,
      jurisdictionUUIDs,
      productUUIDs
    );
    successCB(response.data);
    response.data.type = type;
    return response.data;
  }
);

export const getCarrier = createAsyncThunk(
  'carriers / getCarrier',
  async ({ uuid, successCB }: { uuid: string; successCB: Function }) => {
    const response = await fetchCarrier(uuid, successCB);
    successCB(response.data);
    return response.data;
  }
);

export const onUpdateCarrierData = createAsyncThunk(
  'carriers/onUpdateCarrierData',
  async ({
    uuid,
    data,
    successCB,
  }: {
    uuid: string;
    data: any;
    successCB: Function;
  }) => {
    const response = await updateCarrierData(uuid, data);
    successCB(response.data);
    response.data.type = data.type;
    if (data.type === 'SYSTEM_CARRIER') {
      response.data.uuid = uuid;
    }
    return response.data;
  }
);

export const onUpdateCarrier = createAsyncThunk(
  'carriers/onUpdateCarrier',
  async ({
    uuid,
    agencyUUID,
    status,
  }: {
    uuid: string;
    agencyUUID: string;
    status: boolean;
  }) => {
    const response = await UpdateCarrier(uuid, agencyUUID, status);
    return response.data;
  }
);

export const createCarrier = createAsyncThunk(
  'carriers/createCarrier',
  async ({ data, successCB }: { data: any; successCB: Function }) => {
    const response = await addCarrier(data, successCB);
    response.data.actionType = data.actionType;
    return response.data;
  }
);

export const onDeleteCarrier = createAsyncThunk(
  'carriers/onDeleteCarrier',
  async ({
    uuid,
    successCB,
    data,
  }: {
    uuid: string;
    successCB: Function;
    data: any;
  }) => {
    const response = await deleteCarrier(uuid, data);
    successCB();
    return response.data;
  }
);

const carrierSclice = createSlice({
  name: 'contacts',
  initialState,
  reducers: {
    updateStatus: (state) => {
      state.status = 'idle';
    },
    clearCarrier: (state) => {
      state.carriers = [];
      state.status = 'idle';
      state.count = 0;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(getCarriers.pending, (state, action) => {
        state.status = 'loading';
      })
      .addCase(getCarriers.fulfilled, (state, action) => {
        state.status = 'succeeded';
        if (action.payload.type === 'CUSTOM_CARRIER') {
          state.carriers = action.payload.customCarriers;
        } else {
          state.carriers = action.payload.carriers;
        }
        state.count = action.payload.count;
      })
      .addCase(getCarriers.rejected, (state, action) => {
        state.status = 'failed';
      })
      .addCase(getBatchCarriers.pending, (state, action) => {
        state.status = 'loading';
      })
      .addCase(getBatchCarriers.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.batchCarriers = action.payload.carriers;
        state.batchCount = action.payload.count;
      })
      .addCase(getBatchCarriers.rejected, (state, action) => {
        state.status = 'failed';
      })
      .addCase(onUpdateCarrier.fulfilled, (state, action) => {
        state.status = 'succeeded';
        const index = state.carriers.findIndex(
          (item) => item.uuid === action.payload.uuid
        );
        state.carriers[index] = action.payload;
      })
      .addCase(onUpdateCarrierData.fulfilled, (state, action) => {
        state.status = 'succeeded';
        if (action.payload.type === 'SYSTEM_CARRIER') {
          const index = state.carriers.findIndex(
            (item) => item.uuid === action.payload.uuid
          );
          const carrierIndex = state.carriers[index].Agencies.findIndex(
            (item: any) => item.CarrierId === action.payload.CarrierId
          );
          state.carriers[index].Agencies[carrierIndex].isActive =
            action.payload.isActive;
        } else {
          const index = state.carriers.findIndex(
            (item) => item.uuid === action.payload.uuid
          );
          state.carriers[index] = action.payload;
        }
      })
      .addCase(createCarrier.pending, (state, action) => {
        state.status = 'loading';
      })
      .addCase(createCarrier.fulfilled, (state, action) => {
        state.status = 'succeeded';
        if (action.payload.actionType === 'ADD_BATCH') {
          state.carriers.push(...action.payload.carriers);
          state.count = state.count + action.payload.carriers?.length;
        } else {
          state.carriers.unshift(action.payload);
          state.count = state.count + 1;
        }
      })
      .addCase(createCarrier.rejected, (state, action) => {
        state.status = 'failed';
      })
      .addCase(onDeleteCarrier.pending, (state, action) => {
        state.status = 'loading';
      })
      .addCase(onDeleteCarrier.fulfilled, (state, action) => {
        state.status = 'succeeded';
        const index = state.carriers.findIndex(
          (item) => item.uuid === action.payload.uuid
        );
        state.carriers.splice(index, 1);
        state.count = state.count - 1;
      })
      .addCase(onDeleteCarrier.rejected, (state, action) => {
        state.status = 'failed';
      });
  },
});
export const { updateStatus, clearCarrier } = carrierSclice.actions;
export default carrierSclice.reducer;

export const selectAllCarriers = (state: { carriers: CarrierSliceState }) =>
  state.carriers.carriers;
export const selectAllBatchCarriers = (state: {
  carriers: CarrierSliceState;
}) => state.carriers.batchCarriers;
