import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';
import cross from '../../../../assets/logo/cross.svg';
import tick from '../../../../assets/logo/tick.svg';
import SelectField from '../../../../components/SelectField';
import SuccessCard from '../../../../components/SuccessCard';
import InputField from '../../../../components/InputField';
import PhoneNumberInput from '../../../../components/PhoneNumberFormat';
import { StyledModal } from '../../elements/styles';
import { SuccessAlign } from '../../../../styles/SuccessAlign';
import Modal from '../../../../components/Modal';
import {
  addNewAgency,
  AgenciesSliceState,
} from '../../../../store/agencies/agenciesSlice';
import { AppDispatch } from '../../../../store';
import { getUserGroups } from '../../../../api/admin';
import { IUserGroup } from '../../../../interfaces/Agency';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import warning from '../../../../assets/logo/warning.svg';

const phoneRegExp = /^(?:\(\d{3}\)|\d{3})[- ]?\d{3}[- ]?\d{4}$/;
const SignupSchema = Yup.object().shape({
  name: Yup.string()
    .required('Agency Name is required')
    .max(20, 'The Agency name cannot exceed 20 characters'),
  email: Yup.string()
    .email('Invalid email. Please try again')
    .required('Please enter your email '),
  primaryContactFirstName: Yup.string().max(
    20,
    'First name cannot exceed 20 characters'
  ),
  primaryContactMiddleName: Yup.string().max(
    20,
    'First name cannot exceed 20 characters'
  ),
  primaryContactLastName: Yup.string().max(
    20,
    'Last name cannot exceed 20 characters'
  ),
  phoneNumber: Yup.string()
    .matches(phoneRegExp, 'Entered phone number is not valid.')
    .required('Please enter your phone number'),
});

const AgencyModal = ({
  show,
  closeModal,
}: {
  show: string;
  closeModal: Function;
}) => {
  const dispatch = useDispatch<AppDispatch>();

  const [showSuccess, setshowSuccess] = useState(false);
  const [userGroups, setGroups] = useState<IUserGroup[]>([]);
  const [emailerror, setEmailError] = useState('');
  const [disabled, setDisabled] = useState(false);
  const {
    register,
    handleSubmit,
    control,
    reset,
    getValues,
    watch,
    setValue,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(SignupSchema),
  });

  const watchAllFields = watch();
  const watchGroup = watch('userGroup');
  const watchEmail = watch('email');

  useEffect(() => {
    if (watchGroup) {
      setValue('subGroup', '');
    } // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [watchGroup]);

  useEffect(() => {
    getUserGroups((data: IUserGroup[]) => setGroups(data));
  }, []);

  const onCloseModal = () => {
    closeModal('closing');
    setTimeout(() => {
      closeModal('closed');
    }, 300);
  };

  const loading = useSelector(
    (state: { agencies: AgenciesSliceState }) => state.agencies.isLoading
  );

  useEffect(() => {
    if (watchEmail) {
      setEmailError('');
    } // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [watchEmail]);

  const onSubmit = (data: any) => {
    if (data.subGroup) {
      data.userSubGroup = data.subGroup.value;
    }

    dispatch(
      addNewAgency({
        data,
        successCB: () => {
          setDisabled(true);
          closeModal('closing');
          setTimeout(() => {
            setshowSuccess(true);
          }, 300);
          setTimeout(() => {
            reset();
            setshowSuccess(false);
            setTimeout(() => {
              closeModal('closed');
            }, 300);
          }, 4000);
        },
        errorCB: (error: any) => {
          setDisabled(false);
          setEmailError(error?.data?.error);
        },
      })
    );
  };

  return (
    <>
      <SuccessAlign>
        <div className={`success-container ${showSuccess && 'open-drawer'} `}>
          <SuccessCard
            title={'Success'}
            message={`Agency <b>${getValues(
              'name'
            )}</b> has been created and invitation link has been sent to <b>${getValues(
              'email'
            )}</b>.`}
          />
          <button
            onClick={() => {
              reset();
              setshowSuccess(false);
            }}
            className='crossbutton'
          >
            <img src={cross} className='cross' alt='cross-icon' />
          </button>
        </div>
      </SuccessAlign>

      <Modal open={show} align='right'>
        <StyledModal>
          <button className='crossbutton' onClick={() => onCloseModal()}>
            <img src={cross} className='cross' alt='cross-icon' />
          </button>
          <div>
            <h2>Add an Agency</h2>
          </div>
          <div>
            <p className='subhead'>
              After adding an agency, you will be able to add users and invite
              them to the team.
            </p>
            <div>
              {show && !showSuccess && (
                <form onSubmit={handleSubmit(onSubmit)}>
                  <div className='field-section'>
                    <div className='agency-fields'>
                      <InputField
                        name='name'
                        type='text'
                        placeholder='Agency Name'
                        register={register}
                        required
                        className={`${errors.name && 'error-field'} ${
                          watchAllFields.name && 'filled'
                        }`}
                      />
                      {errors.name ? (
                        <div className='errorVal'>
                          <img
                            src={warning}
                            alt='warning'
                            className='warning'
                          />
                          {errors.name?.message}
                        </div>
                      ) : null}
                    </div>
                    <div className='agency-fields'>
                      <SelectField
                        options={userGroups.map((item) => {
                          return { label: item.name, value: item.uuid };
                        })}
                        name='userGroup'
                        placeholder='User Group'
                        control={control}
                      />
                    </div>
                    <div className='agency-fields'>
                      <SelectField
                        options={userGroups
                          .find(
                            (item) =>
                              item.uuid === watchAllFields.userGroup?.value
                          )
                          ?.AgencySubGroups.map((item) => {
                            return { label: item.name, value: item.uuid };
                          })}
                        name='subGroup'
                        placeholder='Sub Group'
                        control={control}
                      />
                    </div>
                    <div>
                      <p className='contact-para'>Primary Contact Details</p>
                    </div>
                    <div className='agency-fields'>
                      <InputField
                        name='primaryContactFirstName'
                        type='text'
                        placeholder='First Name'
                        register={register}
                        className={`${
                          errors.primaryContactFirstName && 'error-field'
                        } ${
                          watchAllFields.primaryContactFirstName && 'filled'
                        }`}
                      />
                      {errors.primaryContactFirstName ? (
                        <div className='errorVal'>
                          <img
                            src={warning}
                            alt='warning'
                            className='warning'
                          />
                          {errors.primaryContactFirstName?.message}
                        </div>
                      ) : null}
                    </div>
                    <div className='agency-fields'>
                      <InputField
                        name='primaryContactMiddleName'
                        type='text'
                        placeholder='Middle Name'
                        register={register}
                        className={`${
                          errors.primaryContactMiddleName && 'error-field'
                        } ${
                          watchAllFields.primaryContactMiddleName && 'filled'
                        }`}
                      />
                      {errors.primaryContactMiddleName ? (
                        <div className='errorVal'>
                          <img
                            src={warning}
                            alt='warning'
                            className='warning'
                          />
                          {errors.primaryContactMiddleName?.message}
                        </div>
                      ) : null}
                    </div>
                    <div className='agency-fields'>
                      <InputField
                        name='primaryContactLastName'
                        type='text'
                        placeholder='Last Name'
                        register={register}
                        className={`${
                          errors.primaryContactLastName && 'error-field'
                        } ${watchAllFields.primaryContactLastName && 'filled'}`}
                      />
                      {errors.primaryContactLastName ? (
                        <div className='errorVal'>
                          <img
                            src={warning}
                            alt='warning'
                            className='warning'
                          />
                          {errors.primaryContactLastName?.message}
                        </div>
                      ) : null}
                    </div>
                    <div className='agency-fields'>
                      <InputField
                        name='email'
                        type='email'
                        placeholder='Email'
                        required
                        register={register}
                        className={`${errors.email && 'error-field'} ${
                          watchAllFields.email && 'filled'
                        }`}
                      />
                      {errors.email || emailerror ? (
                        <div className='errorVal'>
                          <img
                            src={warning}
                            alt='warning'
                            className='warning'
                          />
                          {errors.email?.message || emailerror}
                        </div>
                      ) : null}
                    </div>
                    <div className='agency-fields'>
                      <PhoneNumberInput
                        name='phoneNumber'
                        required
                        type='tel'
                        placeholder='Phone Number'
                        control={control}
                        className={watchAllFields.phoneNumber && 'filled'}
                      />{' '}
                      {errors.phoneNumber ? (
                        <div className='error-div'>
                          <img
                            src={warning}
                            alt='warning'
                            className='warning'
                          />
                          {errors.phoneNumber?.message}
                        </div>
                      ) : null}
                    </div>
                  </div>
                  <div className='buttonsDiv d-flex align-items-center '>
                    <button
                      onClick={() => {
                        reset();
                        onCloseModal();
                      }}
                      className='cancel-button'
                      type='button'
                    >
                      Cancel
                    </button>
                    <button
                      type='submit'
                      className='agentButton'
                      disabled={loading || show === 'closing' || disabled}
                    >
                      Save <img className='tick' src={tick} alt='tick-sign' />
                    </button>
                  </div>
                </form>
              )}
            </div>
          </div>
        </StyledModal>
      </Modal>
    </>
  );
};

export default AgencyModal;
