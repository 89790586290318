import {
  Link as RouterLink,
  LinkProps,
  useLocation,
  useResolvedPath,
} from 'react-router-dom';

function Link({ children, to, ...props }: LinkProps) {
  const location = useLocation();
  let resolved = useResolvedPath(to);
  // let match = useMatch({ path: resolved.pathname, end: true });
  let match =
    location.pathname.includes(resolved.pathname) ||
    (resolved.pathname === '/mybook' && location.pathname.includes('/account'));

  return (
    <RouterLink
      className={`navlink ${match ? 'active' : ''}`}
      to={to}
      {...props}
    >
      {children}
    </RouterLink>
  );
}

export default Link;
