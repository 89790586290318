import { useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import Moment from 'react-moment';
import Skeleton from 'react-loading-skeleton';
import { AppDispatch } from '../../../store';
import {
  getAgencies,
  selectAllAgencies,
  AgenciesSliceState,
} from '../../../store/agencies/agenciesSlice';
import useDebounce from '../../../hooks/useDebounce';
import AgencyCard from './components/AgencyMobileCard';
import AgencyModal from './components/AgencyModal';
import MobileNav from '../../../components/MobileTabNav';
import TopBar from '../../../components/TopBar';
import AgencyTable from './components/AgencyTable';
import EmptyResults from '../../MyBook/components/Empty';
import PaginationComponent from '../../../components/PaginationComponent';
import AgencyFilter from './components/FilterDrawer';
import Table from '../../../components/Table';
import { IAgency } from '../../../interfaces/Agency';
import { FilterContainer } from '../../MyBook/elements/styles';
import search from '../../../assets/logo/search.svg';
import plus from '../../../assets/logo/plus.svg';
import filter from '../../../assets/logo/filter.svg';
import arrow from '../../../assets/logo/right-arrow.svg';
import sort from '../../../assets/logo/sort.svg';
import sortup from '../../../assets/logo/sortup.svg';
import sortdown from '../../../assets/logo/sortdown.svg';
import vector from '../../../assets/logo/dropdown.svg';
import edit from '../../../assets/logo/edit.svg';
import bin from '../../../assets/logo/bin.svg';

function AgencyList() {
  const dispatch = useDispatch<AppDispatch>();
  const agencies = useSelector(selectAllAgencies);
  const loadingStatus = useSelector(
    (state: { agencies: AgenciesSliceState }) => state.agencies.status
  );
  const agencyCount = useSelector(
    (state: { agencies: AgenciesSliceState }) => state.agencies.count
  );

  const [adduser, setAddUser] = useState('closed');
  const [searchClicked, isSearchClicked] = useState(false);
  const [sortBy, setSortBy] = useState('createdAt');
  const [orderBy, setOrderBy] = useState('desc');
  const [searchBy, setSearchBy] = useState('');
  const [limit, setLimit] = useState(25);
  const [page, setPage] = useState(0);

  const navigate = useNavigate();
  const addUserFunction = () => {
    setAddUser('open');
  };

  const debouncedSearchTerm = useDebounce(searchBy, 500);

  useEffect(() => {
    if (loadingStatus === 'idle' || loadingStatus === 'succeeded') {
      dispatch(
        getAgencies({
          sortBy,
          orderBy,
          searchBy: debouncedSearchTerm,
          limit,
          offset: page * limit,
        })
      );
    } // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, sortBy, orderBy, debouncedSearchTerm, limit, page]);

  const handleSort = (sort: string) => {
    if (sort === sortBy) {
      if (orderBy === 'asc') {
        setOrderBy('desc');
      } else {
        setOrderBy('asc');
      }
    } else {
      setSortBy(sort);
    }
  };
  const [showFilter, setShowFilter] = useState('closed');

  const [userGroup, setUserGroup] = useState<any>([]);

  const filterAgency = () => {
    if (loadingStatus === 'idle' || loadingStatus === 'succeeded') {
      dispatch(
        getAgencies({
          sortBy,
          orderBy,
          searchBy: debouncedSearchTerm,
          limit,
          offset: page * limit,
          userGroup: userGroup?.map((item: any) => item.value),
        })
      );
    }
  };

  return (
    <>
      <div className='content-div'>
        <TopBar>
          <div className='d-flex align-items-center'>
            <img
              src={arrow}
              className='leftarrow  '
              alt='leftarrow'
              onClick={() => navigate(`/admin`)}
            />
            <h2>Manage Agencies</h2>
          </div>
        </TopBar>

        <div className='topButtonsDiv d-flex justify-content-between'>
          <button
            className='addButton d-none d-sm-flex align-items-center justify-content-between'
            onClick={addUserFunction}
          >
            Add Agency
            <img className='plus' src={plus} alt='add' />
          </button>
          <div className='d-flex align-items-center filter-area'>
            <div
              className={searchClicked ? 'search-div' : 'shrinked-div'}
              onClick={() => isSearchClicked(true)}
            >
              {searchClicked ? (
                <>
                  <img src={search} className='search-icon' alt='search' />
                  <input
                    type='text'
                    placeholder='Search agencies'
                    onChange={(e) => {
                      setPage(0);
                      setSearchBy(e.target.value);
                    }}
                    value={searchBy}
                  />
                </>
              ) : (
                <img src={search} className='search-icon' alt='search' />
              )}
            </div>

            <div
              className={`position-relative ${
                searchClicked ? 'shrinked-div' : 'filter-div'
              }`}
              onClick={() => {
                isSearchClicked(false);
                setShowFilter('open');
              }}
              role='button'
            >
              {searchClicked ? (
                <>
                  <img src={filter} alt='filter' />
                  {userGroup.length > 0 && (
                    <span className='filter-counter'>{userGroup.length}</span>
                  )}
                </>
              ) : (
                `Filter by : ${
                  userGroup.length > 0 ? userGroup.length : 'No'
                } filter`
              )}
            </div>
          </div>
        </div>

        <button onClick={addUserFunction} className='addButton d-sm-none'>
          <img src={plus} alt='add' />
        </button>

        <div className='d-sm-none'>
          {loadingStatus === 'loading' ? (
            [1, 2, 3].map((item) => <Skeleton height={138} key={item} />)
          ) : loadingStatus === 'succeeded' && agencies.length > 0 ? (
            <>
              {agencies?.map((el: IAgency) => (
                <AgencyCard agency={el} key={el.uuid} />
              ))}
              <PaginationComponent
                limit={limit}
                setLimit={setLimit}
                page={page}
                setPage={setPage}
                count={agencyCount}
              />
            </>
          ) : (
            <EmptyResults name='agency' />
          )}
        </div>

        <div className='d-none d-sm-block'>
          {loadingStatus === 'loading' || agencyCount === 0 ? (
            <AgencyTable
              agencies={agencies}
              setSortBy={setSortBy}
              sortBy={sortBy}
              setOrderBy={setOrderBy}
              orderBy={orderBy}
            />
          ) : (
            <Table
              limit={limit}
              setLimit={setLimit}
              page={page}
              setPage={setPage}
              count={agencyCount}
              className='mybook-table'
              columns={[
                {
                  accessor: 'name',
                  resizable: true,
                  sortable: true,
                  minWidth: 150,
                  Header: () => (
                    <div
                      onClick={() => handleSort('name')}
                      style={{ cursor: 'pointer' }}
                      className='d-flex justify-content-between w-100'
                    >
                      <span>Agency Name</span>
                      <img
                        className='sort'
                        src={
                          sortBy !== 'name'
                            ? sort
                            : orderBy === 'asc'
                            ? sortdown
                            : sortup
                        }
                        alt='sort'
                      />
                    </div>
                  ),
                  Cell: ({ row }: { row: any }) => (
                    <div className='row-name d-flex align-items-center text-overflow-container'>
                      <b
                        onClick={() => {
                          navigate(`/admin/agency/${row.original.uuid}`);
                        }}
                      >
                        {row.original.name ? row.original.name : '--'}
                      </b>
                      <span className='tooltiptext'>
                        <span className='tooltipInnertext'>
                          {row.original.name ? row.original.name : '--'}
                        </span>
                      </span>
                    </div>
                  ),
                },
                {
                  accessor: 'status',
                  Header: 'Status',
                  resizable: true,
                  width: 150,
                  Cell: ({ row }: { row: any }) => (
                    <div className='d-flex align-items-center h-100'>
                      <div
                        className={`status status${
                          row.original.AgencyStatus
                            ? row.original.AgencyStatus.name.charAt(0) +
                              row.original.AgencyStatus.name
                                .slice(1)
                                .toLowerCase()
                            : 'Invited'
                        }`}
                      >
                        {row.original.AgencyStatus
                          ? row.original.AgencyStatus.name.charAt(0) +
                            row.original.AgencyStatus.name
                              .slice(1)
                              .toLowerCase()
                          : 'Invited'}
                      </div>
                    </div>
                  ),
                },
                {
                  accessor: 'createdAt',
                  resizable: true,
                  sortable: true,
                  minWidth: 130,
                  Header: () => (
                    <div
                      onClick={() => handleSort('createdAt')}
                      style={{ cursor: 'pointer' }}
                      className='d-flex justify-content-between w-100'
                    >
                      <span>Created date</span>
                      <img
                        className='sort'
                        src={
                          sortBy !== 'createdAt'
                            ? sort
                            : orderBy === 'asc'
                            ? sortdown
                            : sortup
                        }
                        alt='sort'
                      />
                    </div>
                  ),
                  Cell: ({ row }: { row: any }) => (
                    <Moment format='MM/DD/YYYY'>
                      {row.original.createdAt}
                    </Moment>
                  ),
                },
                {
                  accessor: 'group',
                  Header: 'User Group',
                  resizable: true,
                  Cell: ({ row }: { row: any }) => (
                    <div className='d-flex flex-column justify-content-center h-100'>
                      <div className='userGroup'>
                        {row.original.AgencySubGroups?.AgencyGroup?.name}
                      </div>
                      <div className='subGroup'>
                        {row.original.AgencySubGroups?.name}
                      </div>
                    </div>
                  ),
                },
                {
                  accessor: 'users',
                  Header: 'Users',
                  resizable: true,
                  minWidth: 130,
                  Cell: ({ row }: { row: any }) => (
                    <div className='d-flex align-items-center h-100'>
                      {row.original.Agents?.length > 0 && (
                        <div className='d-flex align-items-center'>
                          {row.original.Agents[0]?.User.avatar ? (
                            <img
                              src={row.original.Agents[0]?.User.avatar}
                              alt=''
                              className='user-pic'
                            />
                          ) : (
                            <div className='user-pic'>
                              {row.original.Agents[0]?.User?.firstName
                                ?.substring(0, 1)
                                ?.toUpperCase()}
                              {row.original.Agents[0]?.User?.lastName
                                ?.substring(0, 1)
                                .toUpperCase()}
                            </div>
                          )}
                          {row.original.Agents.length > 1 && (
                            <div className='count-div'>
                              +{row.original.Agents.length - 1}
                            </div>
                          )}
                        </div>
                      )}
                    </div>
                  ),
                },

                {
                  accessor: 'actions',
                  Header: 'Actions',
                  sticky: 'right',
                  resizable: false,
                  width: window.innerWidth > 1900 ? 150 : 180,
                  Cell: ({ row }: { row: any }) => (
                    <div className='d-flex align-items-center justify-content-between h-100'>
                      <div
                        className='actionButton'
                        onClick={() => {
                          navigate(`/admin/agency/${row.original.uuid}/user`);
                        }}
                      >
                        View users
                      </div>
                    </div>
                  ),
                },
              ]}
              data={agencies}
            />
          )}
        </div>
      </div>
      <div className='d-xl-none'>
        <MobileNav />
      </div>
      {adduser !== 'closed' && (
        <AgencyModal show={adduser} closeModal={setAddUser} />
      )}
      <FilterContainer>
        {showFilter !== 'closed' && (
          <AgencyFilter
            show={showFilter}
            closeModal={setShowFilter}
            filterFunction={filterAgency}
            userGroup={userGroup}
            setUserGroup={setUserGroup}
          />
        )}
      </FilterContainer>
    </>
  );
}

export default AgencyList;

const ActionsDropdown = ({
  uuid,
  setShowEditModal,
  setUUID,
}: {
  uuid: string;
  setShowEditModal: Function;
  setUUID: Function;
}) => {
  const [showActionsDropdown, setShowActionsDropdown] = useState(false);
  const dropRef = useRef<HTMLButtonElement>(null);

  return (
    <button
      className='vector d-flex align-items-center justify-content-center position-relative'
      onClick={() => {
        setShowActionsDropdown(!showActionsDropdown);
        if (dropRef.current) {
          dropRef.current.focus();
        }
      }}
      onBlur={() => setShowActionsDropdown(false)}
      ref={dropRef}
    >
      <img src={vector} alt='vector' />
      {showActionsDropdown && (
        <div className='dropdown-div'>
          <div
            className='edit-div'
            onClick={() => {
              setShowEditModal('open');
              setUUID(uuid);
              setShowActionsDropdown(false);
            }}
          >
            Edit <img src={edit} alt='bin' />
          </div>
          <div className='delete-div'>
            Delete <img src={bin} alt='bin' />
          </div>
        </div>
      )}
    </button>
  );
};
