import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import Skeleton from 'react-loading-skeleton';
import { AppDispatch } from '../../../store';
import {
  getActivties,
  selectAllActivities,
  TaskSliceState,
  clearActivities,
  getAllActivtiesCount,
  currentTab,
} from '../../../store/tasks/tasksSlice';
import EventCard from './EventCard';
import AddTaskEventModal from './AddTaskEventModal';
import EventDetailsModal from './EventDetailsModal';
import EventFilter from './FilterDrawer';
import ErrorCard from '../../Pipeline/components/ErrorCard';
import useDebounce from '../../../hooks/useDebounce';
import { FilterContainer } from '../../MyBook/elements/styles';
import { AllActivityContentDiv } from '../elements/styles';
import plus from '../../../assets/logo/blackplus.svg';
import vector from '../../../assets/logo/dropdown.svg';
import filter from '../../../assets/logo/filter.svg';
import search from '../../../assets/logo/search.svg';
import whitePlus from '../../../assets/logo/plus.svg';
import close from '../../../assets/logo/cross.svg';
import Table from '../../../assets/logo/Table.svg';

const Events = ({
  count,
  showAddTaskEvent,
  setShowAddTaskEvent,
  setAction,
}: {
  count: any;
  showAddTaskEvent: string;
  setShowAddTaskEvent: Function;
  setAction: Function;
}) => {
  const [tableView, setTableView] = useState(true);
  const [overdew, showOverdew] = useState(false);
  const [thisWeek, setThisWeek] = useState(false);
  const [nextWeek, setNextWeek] = useState(false);
  const [dew, showDew] = useState(false);
  const [closeAllTabs, setCloseAllTabs] = useState(false);
  const [showToday, setShowToday] = useState(false);
  const [eventDrawer, setEventDrawer] = useState('closed');
  const [UUID, setUUID] = useState('');
  const sortBy = 'dueDate';
  const orderBy = 'asc';
  const limit = 8;
  const allActivities = useSelector(selectAllActivities);
  const allEvents = allActivities?.filter((item: any) => item.type === 'EVENT');

  const tasksOffset = useSelector(
    (state: { tasks: TaskSliceState }) => state.tasks.tasksOffset
  );
  const eventsOffset = useSelector(
    (state: { tasks: TaskSliceState }) => state.tasks.eventsOffset
  );

  const dispatch = useDispatch<AppDispatch>();
  const loadingStatus = useSelector(
    (state: { tasks: TaskSliceState }) => state.tasks?.status
  );
  const location: any = useLocation();
  const navigate = useNavigate();
  useEffect(() => {
    if (location?.state?.uuid) {
      setUUID(location?.state?.uuid);
      setEventDrawer('open');

      navigate(location.pathname);
    }
  }, [location]);
  const [showFilter, setShowFilter] = useState('closed');

  const [eventType, setEventType] = useState<any>([]);

  const gerEvents = (type: string) => {
    if (loadingStatus === 'idle' || loadingStatus === 'succeeded') {
      dispatch(clearActivities());
      dispatch(
        getActivties({
          sortBy,
          orderBy,
          type,
          limit,
          tasksOffset: 0,
          eventsOffset: 0,
          eventType: eventType?.map((item: any) => item.value),
          searchBy,
        })
      );
    }
  };

  const sortedEvent = (type: string) => {
    if (loadingStatus === 'idle' || loadingStatus === 'succeeded') {
      dispatch(
        getActivties({
          sortBy,
          orderBy,
          type,
          limit,
          tasksOffset,
          eventsOffset,
          eventType: eventType?.map((item: any) => item.value),
          searchBy,
        })
      );
    }
  };

  const filterEvent = () => {
    showDew(false);
    setShowToday(false);
    setThisWeek(false);
    setNextWeek(false);
    showOverdew(false);
    dispatch(clearActivities());
    dispatch(
      getAllActivtiesCount({
        eventType: eventType?.map((item: any) => item.value),
        searchBy: debouncedSearchTerm,
      })
    );
  };

  useEffect(() => {
    showDew(false);
    setShowToday(false);
    setThisWeek(false);
    setNextWeek(false);
    showOverdew(false);
    dispatch(clearActivities());
    setCloseAllTabs(false);
  }, [closeAllTabs]);

  useEffect(() => {
    dispatch(currentTab({ currentTab: 'Events' }));
  }, []);

  const [isSearchClicked, setIsSearchClicked] = useState(false);
  const [searchBy, setSearchBy] = useState('');
  const debouncedSearchTerm = useDebounce(searchBy, 500);
  const [onDelete, setDelete] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false);
  useEffect(() => {
    filterEvent();
  }, [debouncedSearchTerm]);

  return (
    <AllActivityContentDiv>
      <div className='content-div'>
        {showSuccess && onDelete && (
          <ErrorCard
            message={`<b>${'sdfsdf'}'s </b>Task  has  <b>Deleted</b>`}
            show='open'
          />
        )}
        <div className='d-flex'>
          <div className='d-flex toggleContainer'>
            <div className='d-none d-sm-flex'>
              <button
                onClick={() => {
                  setTableView(true);
                }}
                className={`d-flex align-items-center justify-content-center toggleImage m-0 ${
                  tableView && 'active'
                }`}
              >
                <img src={Table} alt='' />
              </button>
            </div>
            <div className='d-flex d-sm-none'>
              {!isSearchClicked && !searchBy && (
                <>
                  <button
                    onClick={() => {
                      setTableView(true);
                    }}
                    className={`d-flex align-items-center justify-content-center toggleImage m-0 ${
                      tableView && 'active'
                    }`}
                  >
                    <img src={Table} alt='' />
                  </button>
                </>
              )}
            </div>
          </div>
          <div className='d-none d-sm-block'>
            <button
              onClick={() => setShowAddTaskEvent('open')}
              className='tertiaryButton  addButton  d-flex align-items-center justify-content-between '
              type='submit'
            >
              Add Event <img src={plus} alt='' />
            </button>
          </div>
          <button
            onClick={() => setShowAddTaskEvent('open')}
            className='addbutton-mob d-sm-none'
          >
            <img src={whitePlus} alt='add' />
          </button>
          <div className='d-flex  justify-content-end  w-100'>
            {isSearchClicked || searchBy ? (
              <div className='book-search d-flex align-items-center m-0'>
                <img src={search} alt='' className='search-button' />
                <input
                  onChange={(e) => {
                    setSearchBy(e.target.value);
                  }}
                  autoFocus
                  value={searchBy}
                />
                <img
                  src={close}
                  alt=''
                  className='close-button'
                  onClick={() => {
                    setSearchBy('');
                    setIsSearchClicked(false);
                  }}
                />
              </div>
            ) : (
              <div
                className='book-shrinked'
                onClick={() => setIsSearchClicked(true)}
              >
                <img src={search} alt='' />
              </div>
            )}
            <div className='d-none d-sm-flex'>
              <span className={`divider `} />
              <div
                className='d-flex align-items-center position-relative'
                onClick={() => setShowFilter('open')}
                role='button'
              >
                <img src={filter} alt='' className='filter' />
                {(eventType?.length || 0) > 0 && (
                  <span className='filter-counter'>
                    {eventType?.length || 0}
                  </span>
                )}
              </div>
            </div>
            <div className='d-flex d-sm-none'>
              {!isSearchClicked && !searchBy && (
                <>
                  <span className={`divider `} />
                  <div
                    className='d-flex align-items-center position-relative'
                    onClick={() => setShowFilter('open')}
                  >
                    <img src={filter} alt='' className='filter' />
                    {(eventType?.length || 0) > 0 && (
                      <span className='filter-counter'>
                        {eventType?.length || 0}
                      </span>
                    )}
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
        <div className='activitiesContent'>
          <div
            className='DueDiv d-flex justify-content-between'
            onClick={() => {
              showDew(!dew);
              setShowToday(false);
              setThisWeek(false);
              setNextWeek(false);
              showOverdew(false);
              gerEvents('overdue');
            }}
          >
            <div className='d-flex'>
              <div className='countDanger d-flex justify-content-center align-items-center '>
                {count?.overdueEventCount ? count?.overdueEventCount : 0}
              </div>
              <span className='DueName'>Overdue</span>
            </div>
            <img src={vector} alt='' className={`vector ${dew && 'invert'}`} />
          </div>
          <div className='ContentsDiv'>
            {dew &&
              allEvents.length > 0 &&
              allEvents.map((item: any, index) => (
                <EventCard
                  key={index}
                  Data={item?.data}
                  setUUID={setUUID}
                  setEventDrawer={setEventDrawer}
                  setShowSuccess={setShowSuccess}
                  setEventDelete={setDelete}
                  setAction={setAction}
                />
              ))}
            {dew &&
              count?.overdueEventCount > 0 &&
              loadingStatus === 'loading' &&
              [1, 2, 3].map((item) => (
                <div key={item}>
                  {' '}
                  <Skeleton height={84} key={item} />
                </div>
              ))}
            {dew && allEvents.length !== count?.overdueEventCount && (
              <button
                className='Card showMoreButton d-flex justify-content-center'
                onClick={() => sortedEvent('overdue')}
              >
                Show more
              </button>
            )}
          </div>
          <div
            className='DueDiv d-flex justify-content-between'
            onClick={() => {
              showDew(false);
              setShowToday(!showToday);
              setThisWeek(false);
              setNextWeek(false);
              showOverdew(false);
              gerEvents('duetoday');
            }}
          >
            <div className='d-flex'>
              <div className='count d-flex justify-content-center align-items-center '>
                {count?.duetodayEventCount ? count?.duetodayEventCount : 0}
              </div>
              <span className='DueName'>Due today</span>
            </div>
            <img
              src={vector}
              alt=''
              className={`vector ${showToday && 'invert'}`}
            />
          </div>
          <div className='ContentsDiv'>
            {showToday &&
              allEvents.length > 0 &&
              allEvents.map((item: any, index) => (
                <EventCard
                  key={index}
                  Data={item?.data}
                  setUUID={setUUID}
                  setEventDrawer={setEventDrawer}
                  setShowSuccess={setShowSuccess}
                  setEventDelete={setDelete}
                  setAction={setAction}
                />
              ))}
            {showToday &&
              count?.duetodayEventCount > 0 &&
              loadingStatus === 'loading' &&
              [1, 2, 3].map((item, index) => (
                <div key={index}>
                  {' '}
                  <Skeleton height={84} key={item} />
                </div>
              ))}
            {showToday && allEvents.length !== count?.duetodayEventCount && (
              <button
                className='Card showMoreButton d-flex justify-content-center'
                onClick={() => sortedEvent('duetoday')}
              >
                Show more
              </button>
            )}
          </div>
          <div
            className='DueDiv d-flex justify-content-between'
            onClick={() => {
              showDew(false);
              setShowToday(false);
              setThisWeek(!thisWeek);
              setNextWeek(false);
              showOverdew(false);
              gerEvents('duethisWeek');
            }}
          >
            <div className='d-flex'>
              <div className='count d-flex justify-content-center align-items-center '>
                {count?.duethisWeekEventCount
                  ? count?.duethisWeekEventCount
                  : 0}
              </div>
              <span className='DueName'>Due This week</span>
            </div>
            <img
              src={vector}
              alt=''
              className={`vector ${thisWeek && 'invert'}`}
            />
          </div>
          <div className='ContentsDiv'>
            {thisWeek &&
              allEvents.length > 0 &&
              allEvents.map((item: any, index) => (
                <EventCard
                  key={index}
                  Data={item?.data}
                  setUUID={setUUID}
                  setEventDrawer={setEventDrawer}
                  setShowSuccess={setShowSuccess}
                  setEventDelete={setDelete}
                  setAction={setAction}
                />
              ))}
            {thisWeek &&
              count?.duethisWeekEventCount > 0 &&
              loadingStatus === 'loading' &&
              [1, 2, 3].map((item, index) => (
                <div key={index}>
                  {' '}
                  <Skeleton height={84} key={item} />
                </div>
              ))}
            {thisWeek && allEvents.length !== count?.duethisWeekEventCount && (
              <button
                className='Card showMoreButton d-flex justify-content-center'
                onClick={() => sortedEvent('duethisWeek')}
              >
                Show more
              </button>
            )}
          </div>
          <div
            className='DueDiv d-flex justify-content-between'
            onClick={() => {
              showDew(false);
              setShowToday(false);
              setThisWeek(false);
              setNextWeek(!nextWeek);
              showOverdew(false);
              gerEvents('dueNextWeek');
            }}
          >
            <div className='d-flex'>
              <div className='count d-flex justify-content-center align-items-center '>
                {count?.dueNextWeekEventCount
                  ? count?.dueNextWeekEventCount
                  : 0}
              </div>
              <span className='DueName'>Due Next week</span>
            </div>
            <img
              src={vector}
              alt=''
              className={`vector ${nextWeek && 'invert'}`}
            />
          </div>
          <div className='ContentsDiv'>
            {nextWeek &&
              allEvents.length > 0 &&
              allEvents.map((item: any, index) => (
                <EventCard
                  key={index}
                  Data={item?.data}
                  setUUID={setUUID}
                  setEventDrawer={setEventDrawer}
                  setShowSuccess={setShowSuccess}
                  setEventDelete={setDelete}
                  setAction={setAction}
                />
              ))}
            {nextWeek &&
              count?.dueNextWeekEventCount > 0 &&
              loadingStatus === 'loading' &&
              [1, 2, 3].map((item, index) => (
                <div key={index}>
                  {' '}
                  <Skeleton height={84} key={item} />
                </div>
              ))}
            {nextWeek && allEvents.length !== count?.dueNextWeekEventCount && (
              <button
                className='Card showMoreButton d-flex justify-content-center'
                onClick={() => sortedEvent('dueNextWeek')}
              >
                Show more
              </button>
            )}
          </div>
          <div
            className='DueDiv d-flex justify-content-between'
            onClick={() => {
              showDew(false);
              setShowToday(false);
              setThisWeek(false);
              setNextWeek(false);
              showOverdew(!overdew);
              gerEvents('upcomingWeek');
            }}
          >
            <div className='d-flex'>
              <div className='count d-flex justify-content-center align-items-center '>
                {count?.upcomingWeekEventCount
                  ? count?.upcomingWeekEventCount
                  : 0}
              </div>
              <span className='DueName'>Up coming</span>
            </div>
            <img
              src={vector}
              alt=''
              className={`vector ${overdew && 'invert'}`}
            />
          </div>
          <div className='ContentsDiv'>
            {overdew &&
              allEvents.length > 0 &&
              allEvents.map((item: any, index) => (
                <EventCard
                  key={index}
                  Data={item?.data}
                  setUUID={setUUID}
                  setEventDrawer={setEventDrawer}
                  setShowSuccess={setShowSuccess}
                  setEventDelete={setDelete}
                  setAction={setAction}
                />
              ))}
            {overdew &&
              count?.upcomingWeekEventCount > 0 &&
              loadingStatus === 'loading' &&
              [1, 2, 3].map((item) => (
                <div key={item}>
                  {' '}
                  <Skeleton height={84} key={item} />
                </div>
              ))}
            {overdew && allEvents.length !== count?.upcomingWeekEventCount && (
              <button
                className='Card showMoreButton d-flex justify-content-center'
                onClick={() => sortedEvent('upcomingWeek')}
              >
                Show more
              </button>
            )}
          </div>
        </div>
        {showAddTaskEvent !== 'closed' && (
          <AddTaskEventModal
            show={showAddTaskEvent}
            closeModal={setShowAddTaskEvent}
            activityName={'Events'}
            setAction={setAction}
          />
        )}
        {eventDrawer !== 'closed' && (
          <EventDetailsModal
            show={eventDrawer}
            closeModal={setEventDrawer}
            uuid={UUID}
            setAction={setAction}
            setCloseAllTabs={setCloseAllTabs}
          />
        )}
      </div>
      <FilterContainer>
        {showFilter !== 'closed' && (
          <EventFilter
            show={showFilter}
            closeModal={setShowFilter}
            filterFunction={filterEvent}
            eventType={eventType}
            setEventType={setEventType}
            type='event'
          />
        )}
      </FilterContainer>
    </AllActivityContentDiv>
  );
};

export default Events;
