import { useRef, useState } from 'react';
import { useOutsideClick } from '../../../hooks/useOutsideClick';
import bin from '../../../assets/logo/bin.svg';
import edit from '../../../assets/logo/edit.svg';
import vector from '../../../assets/logo/dropdown.svg';

const ActionsDropdown = ({
  uuid,
  handleDelete,
  handleEdit,
  index,
  item,
}: {
  uuid: string;
  item?: any;
  index?: number;
  handleEdit: Function;
  handleDelete: Function;
}) => {
  const [showActionsDropdown, setShowActionsDropdown] = useState(false);

  const dropRef = useRef<HTMLButtonElement>(null);
  useOutsideClick(dropRef, setShowActionsDropdown);
  return (
    <button
      className='task-type-icon-div task-drop'
      onClick={() => {
        setShowActionsDropdown(!showActionsDropdown);
        if (dropRef.current) {
          dropRef.current.focus();
        }
      }}
      ref={dropRef}
      onBlur={() => setShowActionsDropdown(false)}
    >
      <img src={vector} alt='vector' />
      {showActionsDropdown && (
        <div className='edit-delete-drop'>
          <div
            className='editdiv'
            onClick={() => {
              handleEdit(uuid, item, index);
              setShowActionsDropdown(false);
            }}
          >
            Edit <img src={edit} alt='bin' />
          </div>
          <div
            className='deletediv '
            onClick={() => {
              handleDelete(uuid);
              setShowActionsDropdown(false);
            }}
          >
            Delete <img src={bin} alt='bin' />
          </div>
        </div>
      )}
    </button>
  );
};

export default ActionsDropdown;
