import { useState, useRef } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { selectContactCount } from '../../../store/contacts/contactsSlice';
import Modal from '../../../components/Modal';
import Link from '../../../components/Link';
import { IContact } from '../../../interfaces/Contact';
import DetailsDrawer from './ContactDetailsDrawer';
import DetailsPageTab from '../../../components/DetailsPageTab';
import DetailsPageTabMobile from '../../../components/DetailsPageTabMobile';
import { StyledTab } from '../../../styles/StyledTab';
import { MobileDetailsDiv } from '../../../styles/DetailsDiv';
import ham from '../../../assets/logo/hamburger.png';
import cross from '../../../assets/logo/cross.svg';
import dropdown from '../../../assets/logo/dropdown.svg';
import contact from '../../../assets/logo/contact.png';
import phone from '../../../assets/logo/phone.svg';
import message from '../../../assets/logo/message-action.svg';
import mail from '../../../assets/logo/mail.svg';
import plus from '../../../assets/logo/blackplus.svg';
import { useOutsideClick } from '../../../hooks/useOutsideClick';
import edit from '../../../assets/logo/edit.svg';
import bin from '../../../assets/logo/bin.svg';

const MobileContactDetails = ({
  contactDetails,
  setShowEditModal,
  setAddQuickAccount,
  setShowDeleteModal,
  setAddQuickOpportunity,
  setAddQuickPolicy,
  setAddQuickQuotes,
  setAddQuickDocument,
}: {
  contactDetails?: IContact;
  setShowEditModal: Function;
  setAddQuickAccount: Function;
  setShowDeleteModal: Function;
  setAddQuickOpportunity: Function;
  setAddQuickPolicy: Function;
  setAddQuickQuotes: Function;
  setAddQuickDocument: Function;
}) => {
  const [showDetailsDrawer, setShowDetailsDrawer] = useState('closed');
  const [showOpportunities, setShowOpportunities] = useState(false);
  const [showDropdown, setShowDropdown] = useState(false);
  const [addModalDropDown, setAddModalDropDown] = useState(false);
  const [showTooltip, setShowToolTip] = useState(false);
  const [quickDrop, setQuickDrop] = useState(false);
  const navigate = useNavigate();
  const dropRef = useRef(null);

  const [activity, setActivity] = useState('activities');
  const { uuid } = useParams();
  useOutsideClick(dropRef, setShowOpportunities);
  const contactRef = useRef(null);
  useOutsideClick(contactRef, setShowDropdown);
  const editRef = useRef(null);
  useOutsideClick(editRef, setQuickDrop);
  const addRef = useRef(null);
  useOutsideClick(addRef, setAddModalDropDown);

  const contactDetailsCount = useSelector(selectContactCount);

  return (
    <MobileDetailsDiv>
      <div className='mobile-topbar d-flex align-items-center justify-content-between'>
        <div className='d-flex align-items-center'>
          <button
            onClick={() => setShowDetailsDrawer('open')}
            className='secondaryButton'
          >
            <img alt='' src={ham} />
          </button>
          <div className='profile-pic d-none d-sm-flex align-items-center justify-content-center'>
            {contactDetails?.firstName?.charAt(0)}
            {contactDetails?.lastName?.charAt(0)}
          </div>
          <h2>
            {contactDetails?.firstName} {contactDetails?.lastName}
          </h2>
        </div>
        <div className='d-flex align-items-center'>
          <div
            className='action-item d-none d-sm-flex justify-content-center align-items-center me-2'
            onClick={() => {
              if (contactDetails) {
                navigator.clipboard.writeText(
                  contactDetails.ContactDetails.find(
                    (item: any) => item.type === 'PHONENUMBER'
                  )?.value || ''
                );
                setShowToolTip(true);
                setTimeout(() => {
                  setShowToolTip(false);
                }, 500);
              }
            }}
          >
            <img src={phone} alt='phone' />
          </div>
          <div
            className='action-item d-none d-sm-flex justify-content-center align-items-center me-2'
            onClick={() => {
              if (contactDetails) {
                navigator.clipboard.writeText(
                  contactDetails.ContactDetails.find(
                    (item: any) => item.type === 'EMAIL'
                  )?.value || ''
                );
                setShowToolTip(true);
                setTimeout(() => {
                  setShowToolTip(false);
                }, 500);
              }
            }}
          >
            <img src={mail} alt='mail' />
          </div>
          <div
            className='action-item d-none d-sm-flex justify-content-center align-items-center me-2'
            onClick={() => {
              if (contactDetails) {
                navigator.clipboard.writeText(
                  contactDetails.ContactDetails.find(
                    (item: any) => item.type === 'PHONENUMBER'
                  )?.value || ''
                );
                setShowToolTip(true);
                setTimeout(() => {
                  setShowToolTip(false);
                }, 500);
              }
            }}
          >
            <img src={message} alt='message' />
            {showTooltip && (
              <span className='tooltiptext'>
                {' '}
                <b>copied to clipboard</b>
              </span>
            )}
          </div>

          <button
            className='primaryButton w-75 d-none d-sm-flex align-items-center justify-content-between'
            onClick={() => setAddModalDropDown(!addModalDropDown)}
            ref={addRef}
          >
            Add
            <img src={dropdown} alt='' />
            {addModalDropDown && (
              <div className='addDropdown-div'>
                <div
                  className='Add-div'
                  onClick={() => {
                    setAddQuickOpportunity('open');
                    setAddModalDropDown(false);
                  }}
                >
                  Add Opportunity <img src={plus} alt='bin' />
                </div>
                <div
                  className='Add-div'
                  onClick={() => {
                    setAddQuickQuotes('open');
                    setAddModalDropDown(false);
                  }}
                >
                  Add Quote
                  <img src={plus} alt='bin' />
                </div>
                <div
                  className='Add-div'
                  onClick={() => {
                    setAddQuickPolicy('open');
                    setAddModalDropDown(false);
                  }}
                >
                  Add Policy <img src={plus} alt='bin' />
                </div>
                <div
                  className='Add-div'
                  onClick={() => {
                    setAddQuickDocument('open');
                    setAddModalDropDown(false);
                  }}
                >
                  Add Document <img src={plus} alt='bin' />
                </div>
                <div
                  className='Add-div'
                  onClick={() => {
                    setAddQuickAccount('open');
                    setAddModalDropDown(false);
                  }}
                >
                  Add Account <img src={plus} alt='bin' />
                </div>
              </div>
            )}
          </button>
          <button
            className='dropDownButton d-none d-sm-block'
            onClick={() => setQuickDrop(!quickDrop)}
            ref={editRef}
          >
            <img src={dropdown} alt='' />
            {quickDrop && (
              <div className='dropdown-div'>
                <div
                  className='edit-div'
                  onClick={() => {
                    setShowEditModal('open');
                    setQuickDrop(false);
                  }}
                >
                  Edit <img src={edit} alt='bin' />
                </div>
                <div
                  className='edit-div'
                  onClick={() => {
                    setShowDeleteModal('open');
                    setQuickDrop(false);
                  }}
                >
                  Delete <img src={bin} alt='bin' />
                </div>
              </div>
            )}
          </button>
          <button
            onClick={() => {
              navigate('/mybook/contacts');
            }}
            className='secondaryButton'
          >
            <img src={cross} className='cross' alt='cross-icon' />
          </button>
        </div>
      </div>
      <StyledTab className='d-sm-none'>
        <div className='ActivityName' ref={contactRef}>
          <Link
            to={`/contacts/${uuid}/${activity}`}
            onClick={(e) => {
              setShowDropdown(!showDropdown);
            }}
          >
            {activity.toLowerCase()}
            {activity !== 'histories' && (
              <span className='count'>{contactDetailsCount[activity]}</span>
            )}
            <img src={dropdown} alt='' />
          </Link>
        </div>
        <div className='d-sm-none'>
          {showDropdown && (
            <DetailsPageTabMobile
              name='Contact'
              setActivity={setActivity}
              setShowDropdown={setShowDropdown}
            />
          )}
        </div>
      </StyledTab>

      <DetailsPageTab name='Contact' isMobile={true} />

      {/* <button className='addButton d-sm-none'>
        <img src={plus} alt='add' />
      </button> */}
      <div className='mobile-bottombar d-flex d-sm-none align-items-center justify-content-between'>
        <button className='dropDownButton'>
          <img src={dropdown} alt='' />
        </button>
        <div className='action-item'>
          <img src={phone} alt='phone' />
        </div>
        <div className='action-item'>
          <img src={mail} alt='mail' />
        </div>
        <div className='action-item'>
          <img src={message} alt='message' />
        </div>

        <button className='primaryButton  d-flex justify-content-between align-items-center'>
          Add
          <img src={dropdown} alt='' style={{ transform: 'rotate(180deg)' }} />
        </button>
      </div>

      {showDetailsDrawer !== 'closed' && (
        <Modal open={showDetailsDrawer} align='left'>
          <button
            onClick={() => {
              setShowDetailsDrawer('closing');
              setTimeout(() => {
                setShowDetailsDrawer('closed');
              }, 300);
            }}
            className='crossbutton secondaryButton'
          >
            <img src={cross} className='cross' alt='cross-icon' />
          </button>
          <div className='modal-content-div'>
            <div className='header-div d-flex align-items-center'>
              <div className='profile-pic d-flex align-items-center justify-content-center'>
                {contactDetails?.firstName?.charAt(0)}
                {contactDetails?.lastName?.charAt(0)}
              </div>
              <div>
                <h2 className='mb-1'>
                  {contactDetails?.firstName} {contactDetails?.lastName}
                </h2>
                <div className='header-info'>
                  {contactDetails &&
                    contactDetails?.AccountContacts.length > 0 && (
                      <span className='d-flex align-items-center text-overflow-container'>
                        <img src={contact} alt='' className='contact-img' />

                        <span
                          className='nav-decor'
                          onClick={() =>
                            navigate(
                              `/account/${contactDetails.AccountContacts[0].Account.uuid}`
                            )
                          }
                        >
                          {contactDetails?.AccountContacts[0]?.Account.name}
                        </span>
                        <span className='tooltiptext'>
                          <span className='tooltipInnertext'>
                            {contactDetails?.AccountContacts[0]?.Account?.name}
                          </span>
                        </span>
                        {contactDetails?.AccountContacts.length > 1 && (
                          <span className='count-div'>
                            + {contactDetails?.AccountContacts.length - 1}
                          </span>
                        )}
                      </span>
                    )}
                  {contactDetails &&
                    contactDetails?.AccountContacts?.length <= 0 && (
                      <span className='d-flex align-items-center text-overflow-container'>
                        <img src={contact} alt='' className='contact-img' />

                        <span
                          className='nav-decor'
                          onClick={() => navigate(`/contacts/${uuid}/accounts`)}
                        >
                          {'No account connected'}
                        </span>
                        <span className='tooltiptext'>
                          <span className='tooltipInnertext'>
                            {'Click here to add account'}
                          </span>
                        </span>
                      </span>
                    )}
                </div>
              </div>
            </div>
            {contactDetails &&
            contactDetails?.AccountContacts[0]?.Account?.Opportunities?.length >
              0 ? (
              <div
                className='details-section d-flex justify-content-between position-relative'
                ref={dropRef}
                onClick={() => setShowOpportunities(!showOpportunities)}
              >
                <h5 className='section-title '>Opportunities</h5>
                <span className='count-div'>
                  {
                    contactDetails?.AccountContacts[0]?.Account?.Opportunities
                      ?.length
                  }
                </span>
                <img
                  src={dropdown}
                  alt=''
                  className={showOpportunities ? 'rotate' : ''}
                />
                {showOpportunities && (
                  <div className='opportunity-list'>
                    {contactDetails?.AccountContacts[0]?.Account?.Opportunities.map(
                      (item) => (
                        <div
                          key={item.uuid}
                          className='opportunity-item'
                          onClick={() =>
                            navigate(`/opportunities/${item.uuid}`)
                          }
                        >
                          {item.name.trim() ? item.name : '---'}
                        </div>
                      )
                    )}
                  </div>
                )}
              </div>
            ) : (
              <div
                className='details-section d-flex justify-content-between position-relative'
                ref={dropRef}
                onClick={() => setShowOpportunities(!showOpportunities)}
              >
                <h5 className='section-title '>Opportunities</h5>
                <span className='count-div'>{0}</span>
                <img
                  src={dropdown}
                  alt=''
                  className={showOpportunities ? 'rotate' : ''}
                />
              </div>
            )}

            <DetailsDrawer
              contactDetails={contactDetails}
              setShowEditModal={setShowEditModal}
            />
          </div>
        </Modal>
      )}
    </MobileDetailsDiv>
  );
};

export default MobileContactDetails;
