import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import {
  addCustomFields,
  deleteCustomFields,
  fetchCustomFields,
  fetchCustomFieldTypes,
  updateCustomFields,
} from '../../api/customFields';

export type CustomFieldsSliceState = {
  status: string;
  customFieldsType: any[];
  customFields: any[];
};

const initialState: CustomFieldsSliceState = {
  status: 'idle',
  customFieldsType: [],
  customFields: [],
};
export const getCustomFieldsType = createAsyncThunk(
  'customField/types',
  async () => {
    const response = await fetchCustomFieldTypes();
    return response.data;
  }
);

export const getCustomFields = createAsyncThunk(
  'customFields/getCustomFields',
  async ({
    uuid,
    resourceName,
    searchBy,
    isAddForm,
    fieldType,
    sortBy,
    orderBy,
    successCB,
  }: {
    uuid: string;
    resourceName: string;
    searchBy?: string;
    isAddForm?: string;
    fieldType?: any;
    sortBy?: string;
    orderBy?: string;
    successCB?: Function;
  }) => {
    const response = await fetchCustomFields(
      uuid,
      resourceName,
      searchBy,
      isAddForm,
      fieldType,
      sortBy,
      orderBy
    );
    successCB && successCB(response.data);
    return response.data;
  }
);

export const createCustomFields = createAsyncThunk(
  'customFields/createCustomFields',
  async ({ data, successCB }: { data: any; successCB: Function }) => {
    const response = await addCustomFields(data, successCB);
    return response.data;
  }
);

export const onUpdateCustomFields = createAsyncThunk(
  'customFields/onUpdateCustomFields',
  async ({
    data,
    successCB,
    uuid,
  }: {
    data: any;
    successCB: Function;
    uuid: string;
  }) => {
    const response = await updateCustomFields(uuid, data, successCB);
    successCB(response.data);
    return response.data;
  }
);

export const onUpdateOrder = createAsyncThunk(
  'customFields/onUpdateOrder',
  async ({
    data,
    successCB,
    uuid,
  }: {
    data: any;
    successCB: Function;
    uuid: string;
  }) => {
    const response = await updateCustomFields(uuid, data, successCB);
    successCB(response.data);
    return data;
  }
);

export const onDeleteCustomFields = createAsyncThunk(
  'customFields/onDeleteCustomFields',
  async ({
    uuid,
    data,
    successCB,
  }: {
    uuid: string;
    data: any;
    successCB: Function;
  }) => {
    const response = await deleteCustomFields(uuid, data);
    successCB(response.data);
    return response.data;
  }
);

const customFieldsSlice = createSlice({
  name: 'customFields',
  initialState,
  reducers: {
    updateStatus: (state) => {
      state.status = 'idle';
    },
    clearFields: (state) => {
      state.customFields = [];
    },
  },
  extraReducers(builder) {
    builder
      .addCase(getCustomFieldsType.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.customFieldsType = action.payload;
      })
      .addCase(getCustomFields.fulfilled, (state, action) => {
        state.customFields = action.payload;
        state.status = 'succeeded';
      })
      .addCase(getCustomFields.pending, (state, action) => {
        state.status = 'loading';
      })
      .addCase(getCustomFields.rejected, (state, action) => {
        state.status = 'failed';
      })
      .addCase(createCustomFields.fulfilled, (state, action) => {
        state.customFields.push(action.payload);
        state.status = 'succeeded';
      })
      .addCase(createCustomFields.pending, (state, action) => {
        state.status = 'loading';
      })
      .addCase(createCustomFields.rejected, (state, action) => {
        state.status = 'failed';
      })
      .addCase(onUpdateCustomFields.fulfilled, (state, action) => {
        state.status = 'succeeded';
        const index = state.customFields.findIndex(
          (item: any) => item.uuid === action.payload.uuid
        );
        state.customFields[index] = action.payload;
      })
      .addCase(onUpdateCustomFields.pending, (state, action) => {
        state.status = 'loading';
      })
      .addCase(onUpdateCustomFields.rejected, (state, action) => {
        state.status = 'failed';
      })
      .addCase(onDeleteCustomFields.fulfilled, (state, action) => {
        const index = state.customFields.findIndex(
          (item) => item.uuid === action.payload.uuid
        );
        state.customFields.splice(index, 1);
      })
      .addCase(onUpdateOrder.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.customFields = action.payload.orderArray;
      })
      .addCase(onUpdateOrder.pending, (state, action) => {
        state.status = 'loading';
      })
      .addCase(onUpdateOrder.rejected, (state, action) => {
        state.status = 'failed';
      });
  },
});

export const { updateStatus, clearFields } = customFieldsSlice.actions;
export default customFieldsSlice.reducer;

export const selectAllCustomFieldsType = (state: {
  customFields: CustomFieldsSliceState;
}) => state.customFields.customFieldsType;

export const selectAllCustomFields = (state: {
  customFields: CustomFieldsSliceState;
}) => state.customFields.customFields;
