import styled from '@emotion/styled';

export const AllActivityContentDiv = styled.div`
  padding: 0px 20px 40px 20px;
  background: #fafafa;
  .toggleImage {
    margin-left: 4px;
    padding: 6px;
    border: none;
    background: none;
    border-radius: 8px;
    width: 32px;
    height: 32px;
  }
  .active {
    background: #c1dbeb;
  }
  .book-shrinked {
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 8px;
    cursor: pointer;
    &:hover {
      background: #e6e9f0;
    }
  }
  .filter-counter {
    background: #cc0000;
    color: #fff;
    font-size: 12px;
    line-height: 12px;
    border: 1px solid #fff;
    border-radius: 50px;
    position: absolute;
    top: 2px;
    right: 0px;
    display: flex;
    justify-content: center;
    width: 17px;
    height: 17px;
    align-items: center;
  }
  .book-filter {
    max-width: calc(100% - 10px);
  }
  .book-search {
    width: 100%;
    padding-left: 0;
    justify-content: end;
    margin-right: 16px;
    input {
      background: #e6e9f0;
      border: 1px solid #262758;
      outline: none;
      border-radius: 8px;
      width: 384px;
      font-size: 14px;
      height: 100%;
      padding: 10px 48px;
    }

    .search-button {
      margin-right: -32px;
      position: relative;
      z-index: 1;
      filter: invert(0.3);
    }

    .close-button {
      margin: 0 20px 0 -40px;
      cursor: pointer;
    }
  }
  .addButton {
    height: 40px;
    margin-left: 20px;
    width: 164px;
    padding: 10px;
    position: relative;
    .dropDown {
      text-align: start;
      position: absolute;
      width: 164px;
      height: 96px;
      top: 40px;
      z-index: 5;
      padding: 0px 5px;
      left: 0;
      background: #ffffff;
      box-shadow: 0px 2px 8px rgba(35, 42, 64, 0.2);
      border-radius: 8px;
    }
    .tasks {
      padding: 10px 10px 0px 10px;
      text-align: start;
      padding-bottom: 8px;
      border-bottom: 1px solid #e6e9f0;
    }
    .events {
      padding: 0px 10px 0px 10px;
    }
  }

  .activitiesContent {
    width: 100%;
    padding: 0px;
    overflow-y: auto;
    height: 70vh;
    .DueName {
      font-size: #262758;
      font-weight: 600;
      margin-left: 10px;
    }
    &::-webkit-scrollbar {
      width: 6px;
      background: #e6e9f0;
    }
    &::-webkit-scrollbar-track {
      border-radius: 8px;
    }

    .countDanger {
      color: #9a0a0d;
      width: 40px;
      background: #f9e4d2;
      border-radius: 16px;
    }
    .count {
      color: #262758;
      width: 40px;
      background: #f5f7fc;
      border-radius: 16px;
    }
    .countGreen {
      color: #262758;
      width: 40px;
      background: #f5f7fc;
      border-radius: 16px;
    }
  }

  .vector {
    width: 15px;
  }
  .DueDiv {
    cursor: pointer;
    height: 56px;
    padding: 15px 32px 15px 16px;
    margin-bottom: 10px;
    background: #ffffff;
    position: relative;
    .cardContainer {
      padding: 10px;
      background: #fff;
    }
  }
  .DueDiv:hover {
    box-shadow: rgba(0, 0, 0, 0.1) 0px 5px 10px 0px;
  }
  .divider {
    margin: 5px 19px 6px 19px;
    border: 1px solid #d1d6e5;
  }
  .search {
    width: 25px;
  }
  .filter {
    width: 30px;
  }
  .invert {
    transform: rotate(180deg);
  }
  .ContentsDiv {
    // removed overflow hidden to make actions dropdown visible
    ::-webkit-scrollbar {
      width: 0em;
      height: 0.5em;
    }
  }
  .addbutton-mob {
    position: fixed;
    bottom: 80px;
    width: 48px;
    height: 48px;
    right: 20px;
    border: none;
    background: #262758;
    border-radius: 50%;
    z-index: 1;
    .dropUp {
      text-align: start;
      position: absolute;
      width: 164px;
      height: 94px;
      bottom: 45px;
      z-index: 5;
      padding: 0px 5px;
      right: 10px;
      background: #ffffff;
      box-shadow: 0px 2px 8px rgba(35, 42, 64, 0.2);
      border-radius: 8px;
    }

    .tasks {
      padding: 10px 10px 0px 10px;
      text-align: start;
      padding-bottom: 8px;
      border-bottom: 1px solid #e6e9f0;
    }
    .events {
      padding: 0px 10px 0px 10px;
    }
  }
  .toggleContainer {
    background: #f5f7fc;
    border-radius: 8px;
    padding: 4px;
  }
  .Card {
    padding: 10px 30px 10px 10px;
    border-radius: 8px;
    width: 100%;
    margin-bottom: 10px;
    height: 40px;
    background: #fff;
  }
  .showMoreButton {
    border: none;
    color: #262758;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
  }
  .iconsButton {
    padding-right: 13px;
  }
  @media screen and (min-width: 576px) {
    padding: 0px 28px 40px 32px;
    .divider {
      margin: 5px 20px 6px 23px;
    }
    .iconsButton {
      padding-right: 16x;
    }
    .DueDiv {
      padding: 15px 36px 15px 16px;
    }
    .activitiesContent {
      height: 100%;
      padding: 25px 0px;
      max-height: 700px;
    }
    .book-search {
      input {
        width: 320px;
      }
    }
  }
  @media screen and (min-width: 1200px) {
    padding: 0px 28px 40px 135px;
    .divider {
      margin: 5px 25px 6px 35px;
    }
    .DueDiv {
      padding: 15px 36px 15px 16px;
    }
    .book-search {
      input {
        width: 384px;
      }
    }
  }
  @media screen and (min-width: 1900px) {
    padding: 0px 100px 40px 208px;
    .DueDiv {
      padding: 15px 32px 15px 16px;
    }
    .activitiesContent {
      padding: 32px 0px;
    }
  }
`;

export const TaskCardStyle = styled.div`
  padding: 10px;
  border-radius: 8px;
  width: 100%;
  margin-bottom: 10px;
  cursor: pointer;
  height: 184px;
  background: #fff;
  .task-Card {
    padding: 0;
    position: relative;
    .taskIndicator {
      position: absolute;
      height: 20px;
      width: 4px;
      border-radius: 25px;
      background: #ecf4f9;
      top: 6px;
    }
    .eventIndicator {
      position: absolute;
      height: 20px;
      width: 4px;
      border-radius: 25px;
      background: #f04d46;
      top: 6px;
    }
  }

  .typeImageContainer {
    width: 28px;
    height: 28px;
    background: #fafafa;
    margin-left: 20px;
    border-radius: 8px;
  }
  .title {
    color: #262758;
    font-size: 16px;
    font-weight: 600;
    margin-right: 8px;
  }
  .textContainer {
    cursor: pointer;
    padding-left: 20px;
    padding-top: 8px;
  }
  .valueContainerMobile {
    padding-top: 10px;
  }
  .valueTextMobile {
    margin-left: 8px;
    padding-top: 0px;
    color: #262758;
    font-size: 14px;
    font-weight: 600;
  }
  .extraNumber {
    background: #e6e9f0;
    margin-left: 10px;
    border-radius: 50%;
    margin-top: 3px;
    height: 30px;
    padding: 4px 10px;
  }
  .profileText {
    border-right: 1px solid #e6e9f0;
  }
  .titleSubMobile {
    padding-top: 4px;
    color: #262758;
    font-size: 14px;
    font-weight: 400;
  }
  .valueContainerM0bile {
    margin-top: 10px;
    width: 200px;
  }
  .valueEventContainerM0bile {
    padding-top: 30px;
  }
  .valueContainer {
    padding-left: 20px;
    white-space: nowrap;
    overflow: hidden;
    width: 250px;
    text-overflow: ellipsis;
  }
  .profile-pic {
    background: #262758;
    height: 40px;
    width: 40px;
    letter-spacing: -1px;
    border-radius: 46px;
    border: 1px solid #fff;
    text-transform: uppercase;
    color: #fff;
    font-weight: 600;
    font-size: 14px;
    flex: 0 0 40px;
    margin-left: -13px;
  }
  .ProfileList:first-of-type {
    .profile-pic {
      margin-left: 0px;
    }
  }
  .priorityDiv {
    text-transform: capitalize;

    padding: 4px 18px;
    font-size: 12px;
    border-radius: 16px;
    height: 20px;
  }
  .lowPriority {
    background: #d4fad4;
    color: #3d8a68;
  }
  .highPriority {
    background: #f9e4d2;
    color: #9a0a0d;
  }
  .mediumPriority {
    background: #fcf2cf;
    color: #914123;
  }
  .PhoneContainer {
    background: #ecf4f9;
    width: 40px;
    margin-right: 8px;
    height: 40px;
    border-radius: 8px;
  }
  .CompleteContainer {
    margin-right: 8px;
    background: #e6e9f0;
    width: 40px;
    height: 40px;
    border-radius: 8px;
    :hover {
      background: #f5f7fc;
    }
  }
  .vectorColor {
    background: #f5f7fc;
    :hover {
      background: #e6e9f0;
    }
  }
  .EventName {
    margin-bottom: 12px;
  }
  .spaceINnane {
    white-space: nowrap;
  }
  .taskTitle {
    width: 150px;
    display: inline-block;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .Guestprofile-pic {
    display: flex;
    align-items: center;
    justify-content: center;
    background: #e6e9f0;
    color: #000;
    height: 40px;
    width: 40px;
    letter-spacing: -1px;
    border-radius: 46px;
    margin-left: -6px;
    border: 1.5px solid #fff;
    text-transform: uppercase;
    font-weight: 600;
    font-size: 14px;
    flex: 0 0 40px;
  }
  .dropdown-div {
    position: absolute;
    background: #fff;
    box-shadow: 0px 1px 4px 0px #26275833;
    border-radius: 8px;
    padding: 4px 14px;
    right: 0px;
    top: 45px;
    font-weight: 400;
    font-size: 14px;
    z-index: 10;
    width: 130px;
    cursor: pointer;

    .borderDiv {
      margin: 2px 0;
      border-top: 1px solid #e6e9f0;
    }

    .delete-div {
      color: #9a0a0d;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding-top: 4px;
      :hover {
        border-radius: 8px;
        background: #f5f7fc;
      }
    }

    .edit-div {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding-bottom: 4px;
      :hover {
        border-radius: 8px;
        background: #f5f7fc;
      }
    }
  }

  @media screen and (min-width: 576px) {
    padding: 10px;
    height: 84px;
    background: #fff;
    .task-Card {
      padding: 5px;
      .taskIndicator {
        height: 60px;
        width: 4px;
        top: 0;
      }
      .eventIndicator {
        height: 60px;
      }
    }
    .EventName {
      margin-bottom: 12px;
    }

    .typeImageContainer {
      height: 48px;
      width: 48px;
    }
    .descriptionSpan {
      width: 78px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      display: inline-block;
    }
    .descriptionSpanTask {
      width: 180px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      display: inline-block;
    }
    .text-overflow-container {
      position: relative;
      .tooltiptext {
        visibility: hidden;
        min-width: 176px;
        max-width: 100%;
        background: #191919;
        color: #d1d6e5;
        padding: 16px 24px;
        border-radius: 8px;
        font-size: 14px;
        text-align: left;
        position: absolute;
        z-index: 1;
        top: 25px;
        left: 20px;
        white-space: normal;
        overflow-wrap: anywhere;
        line-height: 24px;

        ::after {
          content: ' ';
          position: absolute;
          top: -10px;
          left: 20px;
          margin-left: -5px;
          border-width: 5px;
          border-style: solid;
          border-color: transparent transparent #191919 transparent;
        }
      }

      :hover {
        .tooltiptext {
          visibility: visible;
        }
      }
    }
    .titleSub {
      color: #262758;
      font-size: 14px;
      font-weight: 400;
    }
    .valueText {
      letter-spacing: 0px;
      color: #262758;
      font-size: 14px;
      font-weight: 600;
    }
    .valueContainerIcons {
      padding-left: 20px;
      white-space: nowrap;
      overflow: hidden;
      width: 180px;
      text-overflow: ellipsis;
    }
    .valueContainer {
      white-space: nowrap;
      overflow: hidden;
      width: 250px;
      text-overflow: ellipsis;
      padding-left: 100px;
    }
    .priorityDiv {
      margin-left: 3px;
    }
    .title {
      width: unset;
    }
    .valueContainerIcons {
      width: 280px;
    }
  }
  @media screen and (min-width: 1200px) {
    .valueContainer {
      padding-left: 20px;
    }
    .priorityDiv {
      margin-left: 15px;
    }
    .valueContainerIcons {
      width: 250px;
    }
    .eventTextContainer {
      width: 305px;
    }
  }
  @media screen and (min-width: 1900px) {
    .valueContainer {
      width: 380px;
    }
    .descriptionSpan {
      width: 350px;
    }
    .descriptionSpanTask {
      width: 380px;
    }
  }
`;
export const ActivityContentDiv = styled.div`
  padding: 30px;
  background: #fafafa;
  .Activities {
    position: relative;
    margin-top: 20px;
    .Activecount {
      background: #c1dbeb;
      font-size: 12px;
      padding: 2px 8px;
      margin-left: 4px;
      border-radius: 8px;
    }
    .dropDown {
      position: absolute;
      width: 124px;
      height: 106px;
      top: 40px;
      z-index: 5;
      padding: 0px 5px;
      left: 0;
      background: #ffffff;
      box-shadow: 0px 2px 8px rgba(35, 42, 64, 0.2);
      border-radius: 8px;
      .navMobile {
        padding-top: 8px;
        border: none;
      }
    }
  }
  @media screen and (min-width: 576px) {
    padding: 104px 32px 24px 32px;
  }
  @media screen and (min-width: 1200px) {
    padding: 104px 32px 26px 135px;
  }
  @media screen and (min-width: 1900px) {
    padding: 104px 32px 34px 208px;
  }
`;
export const StyledAddModal = styled.div`
  .drawer-content {
    width: 100%;
  }
  .NavButtons {
    font-weight: 600;
    font-size: 14px;
    border-bottom: 1px solid #f5f5f5;
    padding: 10px 0;
  }
  .textarea {
    padding-top: 20px;
    width: 100%;
  }
  .textarea {
    margin-top: 0px;
  }
  .notesTextarea {
    padding: 14px 16px;
    margin-left: 2px;
    margin-top: 10px;
    font-size: 14px;
    font-weight: 400;
    width: 99%;
    box-shadow: 0px 1px 4px rgba(35, 42, 64, 0.2);
    border-radius: 8px;
  }
  span {
    cursor: pointer;
  }
  .navButtons {
    margin-left: 24px;
  }
  .active {
    padding: 0 0 12px 0;
    background: none;
    border-bottom: 3px solid #262758;
  }
  .cross-btn {
    background: #ffffff;
    border: none;
  }
  .buttonsDiv {
    border-top: 1px solid #e6e9f0;
    position: fixed;
    bottom: 0px;
    width: 90%;
    left: 4%;
    background: #fff;
    z-index: 1000;
    padding: 16px 0px;
  }
  .cancel-button {
    height: 24px;
    background: none;
    color: #262758;
    font-weight: 600;
    font-size: 16px;
    margin-left: auto;
    margin-right: 30px;
    border: none;
  }
  .saveButton {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: #262758;
    color: #f5f7fc;
    border-radius: 8px;
    width: 182px;
    height: 48px;
    padding: 0 20px;
    font-weight: 600;
    font-size: 16px;
    &:disabled {
      background: #d1d6e5;
      border: none;
    }
  }
  .taskModalContent {
    overflow-y: auto;
    overflow-x: hidden;
    padding: 0;
    height: 85vh;
    ::-webkit-scrollbar {
      width: 1px;
      height: 2em;
    }
  }
  .taskContainer {
    padding: 32px 0px;
    border-bottom: 1px solid #e6e9f0;
  }
  .titleLabel {
    font-size: 14px;
    padding-top: 5px;
    margin-bottom: 10px;
    font-weight: 400;
    color: #5a5f70;
  }
  .priorityLabel {
    text-transform: capitalize;
    font-size: 14px;
    margin-bottom: 0;
    font-weight: 600;
  }
  .taskTypeDiv {
    margin-bottom: 26px;
  }
  .imagesContainer {
    padding: 4px;
    width: 155px;
    background: #f5f7fc;
    border-radius: 8px;
  }
  .imagesContainerEvent {
    padding: 4px;
    width: 120px;
    background: #f5f7fc;
    border-radius: 8px;
  }
  .taskImage {
    padding: 8px;
    cursor: pointer;
    border-radius: 8px;
    :hover {
      background: #262758;
      filter: grayscale(100%);
    }
  }
  .activeType {
    background: #262758;
    // filter: contrast(175%) brightness(3%);
  }
  .piorityDiv {
    width: 107px;
  }
  .userAssigneeDiv {
    padding: 10px 16px;
    width: 166px;
    border: none;
    height: 40px;
    border-radius: 20px;
    position: relative;
    background: #f5f7fc;
  }
  .linkDiv {
    padding: 10px 16px;
    width: 40%;
    height: 48px;
    border-radius: 8px;
    border: none;
    position: relative;
    background: #f5f7fc;
  }
  .userpriorityDiv {
    padding: 6px 8px;
    width: 152px;
    border-radius: 20px;
    position: relative;
    background: #f5f7fc;
  }
  .bookLabel {
    padding: 0;
    margin: 0;
    font-size: 14px;
  }
  .assigneeDropDown {
    position: absolute;
    top: 50px;
    left: 10px;
    z-index: 10;
    width: 280px;
    max-height: 175px;
    border-radius: 8px;
    background: #fff;
    text-align: start;
    padding: 8px;
    overflow-y: auto;
    overflow-x: hidden;
    box-shadow: 0px 1px 4px rgba(35, 42, 64, 0.2);
    ::-webkit-scrollbar {
      width: 0em;
      height: 0.5em;
    }
    ::-webkit-scrollbar-thumb {
      background: #eee;
    }
    .AssigneeCard {
      margin: 4px 0px;
      border-bottom: 1px solid #e6e9f0;
      :hover {
        background: #f5f7fc;
        border-radius: 8px;
      }
    }
  }
  .TopContainer {
    .GuestDropDown {
      position: absolute;
      top: 50px;
      left: 10px;
      z-index: 10000;
      width: 280px;
      max-height: 256px;
      border-radius: 8px;
      background: #fff;
      text-align: start;
      padding: 8px 14px;
      overflow-y: auto;
      overflow-x: hidden;
      box-shadow: 0px 1px 4px rgba(35, 42, 64, 0.2);
      ::-webkit-scrollbar {
        width: 0em;
        height: 0.5em;
      }
      ::-webkit-scrollbar-thumb {
        background: #eee;
      }
      .searchGuest {
        position: relative;
        .inageSrc {
          position: absolute;
          top: 8px;
          width: 16px;
          left: 10px;
        }
      }
    }

    &::after {
      content: ' ';
      position: absolute;
      top: 40px;
      width: 10px;
      height: 10px;
      left: 30px;
      transform: rotate(45deg);
      box-shadow: 0px 1px 4px rgba(35, 42, 64, 0.2);
    }
  }
  .linkToDropDown {
    width: 100%;
    left: 4px;
  }
  .priorityDropdown {
    position: absolute;
    top: 24px;
    left: 0;
    z-index: 10;
    width: 152px;
    border-radius: 8px;
    background: #fff;
    overflow: hidden;
    padding: 4px;
    box-shadow: 0px 1px 4px rgba(35, 42, 64, 0.2);
  }
  .ProfileList {
    display: flex;
    height: 40px;
    align-items: center;
  }
  .addguestbutton {
    margin-right: 18px;
  }

  .dropList {
    .usersList {
      border-radius: 8px;
      padding: 6px;
      border-bottom: 1px solid #e6e9f0;
      margin: 2px 0;
      :hover {
        font-weight: 600;
        background: #f5f7fc;
      }
    }
  }

  .activeLink {
    font-weight: 600;
    background: #c1dbeb;
  }
  .priorityList {
    font-size: 14px;
    font-weight: 600;
    margin-bottom: 4px;
    text-transform: capitalize;
    padding: 2px 14px;
    border-radius: 8px;
    :last-of-type: {
      margin: 0;
    }
  }
  .lowPriority {
    background: #d4fad4;
    color: #3d8a68;
    margin: 0;
  }
  .highPriority {
    background: #f9e4d2;
    color: #9a0a0d;
  }
  .mediumPriority {
    background: #fcf2cf;
    color: #914123;
  }

  .taskStageDiv {
    padding: 10px;
    height: 24px;
    width: 152px;
    font-size: 12px;
    background: #f9e4d2;
    color: #9a0a0d;
    border-radius: 8px;
  }
  .Linkedfields {
    margin-left: 0px;
    width: 60%;
    padding: 0px 10px;
  }
  .descriptionField {
    position: relative;
    .editDesc {
      padding-right: 2px;
      top: 13px;
      right: 0px;
      position: absolute;
      display: none;
    }
    .dropdown-div {
      position: absolute;
      background: #fff;
      box-shadow: 0px 1px 4px 0px #26275833;
      border-radius: 8px;
      padding: 4px 16px;
      right: 10px;
      top: 60px;
      font-size: 14px;
      z-index: 4;
      width: 120px;
      cursor: pointer;

      div {
        padding: 4px 0;
      }

      .delete-div {
        color: #9a0a0d;
        display: flex;
        justify-content: space-between;
        align-items: center;
        border-top: 1px solid #e6e9f0;
        :hover {
          background: #f5f7fc;
          border-radius: 8px;
        }
      }
      .edit-div {
        display: flex;
        justify-content: space-between;
        align-items: center;
        :hover {
          background: #f5f7fc;
          border-radius: 8px;
        }
      }
    }
  }
  .descriptionField:hover {
    .editDesc {
      display: block;
    }
  }
  .descriptionField:focus {
    .editDesc {
      display: none;
    }
  }
  .error-field {
    margin-top: 4px;
    padding-left: 8px;
    font-size: 14px;
    font-weight: 600;
    color: #cc0000;
    display: flex;
    align-items: center;
  }
  .notesDiv {
    margin-top: 10px;
  }
  .notes {
    width: 100%;
    min-height: 100px;
    border: 1px solid #cad9f3;
    padding: 15px 15px;
  }

  .notes:focus-within {
    border: #cad9f3;
  }
  .smallDateField {
    padding: 8px 5px;
    width: 130px;
  }
  .smallDateFielddateOnly {
    padding: 8px 5px;
    width: 130px;
    color: #262758;
    font-weight: 600;
    font-size: 12px;
    cursor: pointer;
    border-radius: 8px;
    :hover {
      background: #f5f7fc !important;
      box-shadow: 0px 1px 4px rgba(35, 42, 64, 0.2);
    }
  }
  .deleteButton {
    cursor: pointer;
  }
  .timeDiv {
    padding: 10px 16px;
    width: 100px;
    height: 32px;
    border-radius: 8px;
    position: relative;
    background: #fff;
  }
  .datePeriodDiv {
    position: relative;
    margin-left: 25px;
    .dropIndicator {
      position: absolute;
      height: 24%;
      width: 2px;
      top: 35px;
      left: -21.6px;
      bottom: 70%;
      background: repeating-linear-gradient(
        180deg,
        #d1d6e5,
        #d1d6e5 2px,
        #fff 5px,
        #fff 5px
      );
    }
    .PointerDiv {
      position: absolute;
      top: 24px;
      left: -24.8px;
      border-radius: 50%;
      width: 8px;
      height: 8px;
      background: #d9d9d9;
    }
    .secondPointerDiv {
      position: absolute;
      top: 66px;
      left: -25px;
      border-radius: 50%;
      width: 8px;
      height: 8px;
      background: #d9d9d9;
    }
    .arrow-down {
      position: absolute;
      border: solid #d9d9d9;
      border-width: 0 2.5px 2.5px 0;
      display: inline-block;
      padding: 2px;
      top: 56px;
      left: -24.5px;
      transform: rotate(45deg);
      -webkit-transform: rotate(45deg);
    }
  }
  .GuestDiv {
    padding: 10px 10px;
    width: 40px;
    height: 40px;
    border: none;
    border-radius: 20px;
    position: relative;
    background: #ecf4f9;
  }
  .guestsContainer {
    padding-left: 0px;
    white-space: nowrap;
    width: 200px;
    text-overflow: ellipsis;
    .Guestprofile-pic {
      background: #262758;
      height: 40px;
      width: 40px;
      letter-spacing: -1px;
      border-radius: 46px;
      margin-left: -12px;
      border: 1.5px solid #fff;
      text-transform: uppercase;
      color: #fff;
      font-weight: 600;
      font-size: 14px;
      flex: 0 0 40px;
    }
    .GuestprofileNumber {
      background: #e6e9f0;
      color: #000;
    }
  }
  .Assigneeprofile-pic {
    background: #262758;
    height: 40px;
    width: 40px;
    letter-spacing: -1px;
    border-radius: 46px;
    margin-left: -10px;
    margin-right: 5px;
    border: 1.5px solid #fff;
    text-transform: uppercase;
    color: #fff;
    font-weight: 600;
    font-size: 14px;
    flex: 0 0 40px;
  }
  .doneButton {
    background: #e6e9f0;
    height: 40px;
    border: none;
    border-radius: 8px;
  }
  .doneButton:hover {
    background: #f5f7fc;
  }

  .PhoneContainer {
    margin-right: 16px;
    background: #ecf4f9;
    width: 40px;
    height: 40px;
    border-radius: 8px;
  }
  .DocumentsDiv {
    margin-top: 10px;
    padding-bottom: 200px;
  }
  .addNotesButton {
    border: none;
    background: #fff;
  }
  .smallAddbutton {
    width: 15px;
    margin-right: 8px;
  }
  .LinktoDiv {
    padding-top: 12px;
  }
  .searchValuesCard {
    overflow-y: auto;
    width: 320px;
    margin-top: 4px;
    border-bottom: 1px solid #e6e9f0;
    right: 0;
    h6 {
      overflow: hidden;
      width: 120px;
      text-overflow: ellipsis;
      white-space: nowrap;
      margin: 0;
    }
  }
  .guestlist {
    min-height: 56px;
    height: auto;
    margin-top: 3px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    border-radius: 8px;

    &.account-details {
      font-size: 14px;
    }
    h6 {
      overflow: hidden;
      width: 120px;
      text-overflow: ellipsis;
      white-space: nowrap;
      margin: 0;
    }
  }
  .underLineDiv {
    border-bottom: 1px solid #e6e9f0;
    border-radius: 8px;
    :hover {
      background: #f5f7fc;
    }
  }
  .profile-pic {
    background: #262758;
    height: 40px;
    width: 40px;
    border-radius: 46px;
    margin-right: 16px;
    text-transform: uppercase;
    color: #fff;
    font-weight: 600;
    font-size: 14px;
    flex: 0 0 40px;
  }
  img.profile-pic {
    background: none;
  }
  .user-details {
    display: block;
    margin: 0;
    padding: 0;
    h6 {
      margin-bottom: 0;
    }
    li {
      font-size: 12px;
      line-height: 16px;
      display: inline-block;
      width: 100px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;

      &:first-of-type {
        margin-right: 5px;
        border-right: 1px solid #262758;
        padding-right: 5px;
      }
      &:last-of-type {
        width: 50px;
      }
    }
  }
  .documentDetails {
    margin: 24px 2px 140px 2px;
    box-shadow: 0px 1px 4px rgba(35, 42, 64, 0.2);
    border-radius: 8px;
    padding: 12px 24px;
    .docName {
      margin-left: 12px;
      width: 200px;
      display: inline-block;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
  .documentDropDown {
    position: relative;
    color: #262758;
    font-weight: 600;
    font-size: 14px;
    border-radius: 8px;
    padding: 16px;
    border: none;
    width: 316px;
    height: 32px;
  }
  .documentTypeDrop {
    position: absolute;
    top: 38px;
    left: 0;
    background: #fff;
    width: 280px;
    border-radius: 8px;
    padding: 4px;
    box-shadow: 0px 2px 8px rgba(35, 42, 64, 0.2);
    .listType {
      padding-top: 10px;
      border-bottom: 1px solid #e6e9f0;
      text-align: start;
    }
    .listType:last-child {
      border-bottom: none;
    }
  }
  .errorDIv {
    margin-top: 10px;
  }
  .errorDIvDesc {
    margin-top: 3px;
    margin-left: 140px;
  }
  .errorDivEvent {
    margin-top: 3px;
    margin-left: 107px;
  }
  .errorNoteDiv {
    margin-top: 3px;
    margin-left: 9px;
  }
  .erricon {
    margin-right: 5px;
  }
  .errorVal {
    margin-top: 10px;
    color: #cc0000;
  }
  .warning {
    margin: 0 10px;
  }
  .dotarea {
    margin: 0 8px;
  }
  .textContetnt {
    margin-bottom: 10px;
  }
  .vectorContainer {
    border: none;
    background: #fff;
    padding-left: 19px;
    border-left: 1px solid #e6e9f0;
  }

  .backgroundDiv {
    position: absolute;
    height: 600px;
    right: 0px;
    top: 0px;
  }
  .ManageGuestHead {
    color: #262758;
    border: none;
    background: #fff;
    font-size: 14px;
    font-weight: 600;
    position: relative;
    padding: 7px;
    cursor: pointer;
    :hover {
      text-decoration: underline;
      text-decoration-color: #3c498a;
      text-decoration-thickness: 2px;
    }
    .GuestDropDown {
      top: 45px;
    }
  }

  .guestSearchInput {
    width: 100%;
    background: #e6e9f0 !important;
    padding: 5px 12px 5px 30px;
    outline: none;
    border: none;
    border-radius: 8px;
    font-size: 14px;
    font-weight: 400;
    :placeholder {
      color: #5a5f70;
    }
  }
  .descripionDiv {
    margin-top: 50px;
    margin-bottom: 0px;
  }
  .assigneeName {
    width: 82px;
    display: inline-block;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .disabledButon {
    background: #e6e9f0;
    img {
      filter: invert(92%) sepia(5%) saturate(662%) hue-rotate(191deg)
        brightness(94%) contrast(90%);
    }
  }
  @media screen and (min-width: 576px) {
    .drawer-content {
      width: 420px;
    }
    .descriptionField {
      .editDesc {
        padding-right: 5px;
        top: 12px;
      }
    }
    .TopContainer {
      .GuestDropDown {
        width: 310px;
        left: -50px;
        top: 45px;
      }
    }
    .datePeriodDiv {
      margin-left: 0;
    }
    .linkDiv {
      width: 156px;
    }
    .titleLabel {
      color: #262758;
      font-size: 14px;
      padding-top: 5px;
      margin-bottom: 0;
      font-weight: 600;
    }
    .userpriorityDiv {
      padding: 4px 8px;
      width: 87px;
    }

    .priorityDropdown {
      width: 87px;
    }
    .smallDateField {
      width: 100px;
    }
    .smallDateFielddateOnly {
      width: 100px;
      .dash {
        position: absolute;
        top: 50px;
        z-index: 50;
      }
      .doore {
        position: absolute;
        top: 200px;
        z-index: 50;
      }
      .mooore {
        position: absolute;
        top: 150px;
        left: 0;
        z-index: 50;
      }
      :hover {
        background: #f5f7fc !important;
      }
    }
    .taskStageDiv {
      width: 90px;
    }

    .Linkedfields {
      margin-left: 0px;
      width: 156px;
      padding: 10px 0px;
    }
    .buttonsDiv {
      width: 86%;
      left: 5%;
    }
    .assigneeDropDown {
      left: -50px;
      width: 320px;
    }
    .linkToDropDown {
      width: 156px;
      left: 0px;
    }
    .documentTypeDrop {
      width: 316px;
    }
    .documentDetails {
      margin: 24px 2px 160px 2px;
    }
    .user-details {
      li {
        &:last-of-type {
          width: 80px;
        }
      }
    }
  }
  @media screen and (min-width: 1900px) {
    .drawer-content {
      width: 576px;
    }
    .descriptionField {
      .editDesc {
        padding-right: 11px;
        top: 20px;
      }
    }

    .TopContainer {
      .GuestDropDown {
        width: 320px;
        left: 30px;
        top: 45px;
      }
      &::after {
        left: 50px;
      }
    }
    .Linkedfields {
      width: 180px;
    }
    .linkDiv {
      width: 180px;
    }
    .userpriorityDiv {
      width: 108px;
    }
    .priorityDropdown {
      width: 108px;
      top: 28px;
    }
    .assigneeDropDown {
      left: 0px;
    }
    .documentDetails {
      margin: 24px 2px 100px 2px;
      .docName {
        width: 300px;
      }
    }
    .linkToDropDown {
      width: 180px;
    }
    .DocumentsDiv {
      padding-bottom: 220px;
    }
  }
`;
