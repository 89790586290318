import { useEffect, useState } from 'react';
import SideBar from '../../components/SideBar';
import TopBar from '../../components/TopBar';
import { useNavigate } from 'react-router';
import vector from '../../assets/logo/dropdown.svg';
import arrow from '../../assets/logo/back.svg';
import tick from '../../assets/logo/blacktick.svg';
import settings from '../../assets/logo/settings.svg';
import CONTACT from '../../assets/logo/contact.svg';
import LEAD from '../../assets/logo/lead.svg';
import POLICY from '../../assets/logo/policy.svg';
import OPPORTUNITY from '../../assets/logo/policy.svg';
import TASK from '../../assets/logo/TaskTab.svg';
import PIPELINE from '../../assets/logo/manage.svg';
import ACCOUNT from '../../assets/logo/account.svg';
import { Notifications } from './styles';
import MobileNav from '../../components/MobileTabNav';
import { useDispatch, useSelector } from 'react-redux';
import {
  fetchNotifications,
  selectAllNotifications,
  updatingNotification,
  NotificationSliceState,
  clearNotification,
} from '../../store/notifications/notificationsSlice';
import moment from 'moment';
import { AppDispatch } from '../../store';
import Skeleton from 'react-loading-skeleton';

const NotificationsPage = () => {
  const navigate = useNavigate();
  const [showNotificationAllPage, setShowNotificationAllPage] = useState(false);
  const [showDropDown, setShowDropDown] = useState(false);
  const [showReadDropDown, setShowReadDropDown] = useState(false);
  const [showReadDropDownUUID, setShowReadDropDownUUID] = useState('');
  const notifications = useSelector(selectAllNotifications);
  const [showNotificationCount, setShowNotificationCount] = useState();
  const [onScroll, setOnScroll] = useState(false);
  const [history, setHistory] = useState<any>({ keys: [] });
  const dispatch = useDispatch<AppDispatch>();
  useEffect(() => {
    if (!showNotificationAllPage) {
      let unread = notifications.filter((item) => item?.isRead === false);
      formatHistory(unread);
    } else {
      formatHistory(notifications);
    }
  }, [notifications, showNotificationAllPage]);

  useEffect(() => {
    dispatch(clearNotification());
    dispatch(
      fetchNotifications({
        limit: 20,
        offset: 0,
        unread: showNotificationAllPage ? undefined : true,
        successCB: (data: any) => {
          setShowNotificationCount(data.count);
        },
      })
    );
  }, [showNotificationAllPage]);

  const onScrollNotification = (event: any) => {
    setOnScroll(true);
    const { scrollTop, clientHeight, scrollHeight } = event.currentTarget;
    if (
      Math.round(scrollHeight - scrollTop) <= Math.round(clientHeight) &&
      loadingStatus !== 'loading' &&
      notifications
    ) {
      dispatch(
        fetchNotifications({
          offset: notifications.length,
          limit: 3,
          unread: showNotificationAllPage ? undefined : true,
          successCB: () => {},
        })
      );
    }
  };

  const loadingStatus = useSelector(
    (state: { notifications: NotificationSliceState }) =>
      state.notifications.status
  );
  const notificationsCount = useSelector(
    (state: { notifications: NotificationSliceState }) =>
      state.notifications.count
  );

  const formatHistory = (showAllUnreadNotification: any) => {
    const temp = showAllUnreadNotification.reduce(
      (data: any, currentData: any) => {
        let x: string | Date = new Date(currentData.createdAt);
        let currentDate = new Date();
        if (
          currentDate.getDate() === x.getDate() &&
          currentDate.getMonth() === x.getMonth() &&
          currentDate.getFullYear() === x.getFullYear()
        ) {
          x = `Today`;
        } else if (
          currentDate.getDate() - x.getDate() === 1 &&
          currentDate.getMonth() === x.getMonth() &&
          currentDate.getFullYear() === x.getFullYear()
        ) {
          x = `Yesterday`;
        } else {
          x = x.toLocaleString('default', {
            month: 'long',
            day: '2-digit',
            year: 'numeric',
          });
        }

        if (data[x]) {
          const currentHistory = data[x];
          currentHistory.push(currentData);
          data[x] = currentHistory;
        } else {
          data[x] = [currentData];
        }
        return data;
      },
      {}
    );
    setHistory({ history: temp, keys: Object.keys(temp) });
  };

  const NotificationUpdate = (action: string, uuid?: string) => {
    let data = { action: '', notificationUUID: '' };
    if (uuid && action === 'READONE') {
      data.action = action;
      data.notificationUUID = uuid;
    } else {
      data.action = action;
      data.notificationUUID = '';
    }
    dispatch(
      updatingNotification({
        data,
        successCB: (value: any) => {},
      })
    );
  };
  return (
    <Notifications>
      <div>
        <div className='d-none d-xl-block'>
          <SideBar />
        </div>
        <TopBar>
          <div
            className=' goackButton d-none d-xl-flex '
            onClick={() => navigate(-1)}
          >
            <img src={arrow} alt='' className='d-none d-xl-block backArrow' />
            <h2 className='d-none d-xl-block'>Go back</h2>
          </div>
          <div className=' goackButtonMobile  d-flex d-xl-none  justify-content-between align-items-center w-100'>
            <h2 className='notificationMobileHead'>Notifications</h2>
            <button
              className='vector d-flex align-items-center justify-content-center position-relative'
              onClick={() => setShowDropDown(!showDropDown)}
            >
              <img src={vector} alt='vector' />
              {showDropDown && (
                <div className='NotificationDrop '>
                  <div
                    className='dropWord marginDiv d-flex justify-content-between align-items-center'
                    onClick={() => NotificationUpdate('READALL')}
                  >
                    <h6>Mark all as read</h6>
                    <img src={tick} alt='' className='ticklogo' />
                  </div>
                  <div
                    className='dropWord d-flex justify-content-between align-items-center'
                    onClick={() => navigate('/myprofile/notificationsettings')}
                  >
                    <h6>Notification settings</h6>
                    <img src={settings} alt='' className='settinglogo' />
                  </div>
                </div>
              )}
            </button>
          </div>
        </TopBar>
      </div>
      <div className='paddingDiv'>
        <div className='NotificationContainer'>
          <div className=' NoticationContent'>
            <div className=''>
              <div className='d-none d-xl-flex align-items-center justify-content-between'>
                <h2>Notifications</h2>
                <button
                  className='vector d-flex align-items-center justify-content-center position-relative'
                  onClick={() => setShowDropDown(!showDropDown)}
                >
                  <img src={vector} alt='vector' />
                  {showDropDown && (
                    <div className='NotificationDrop '>
                      <div
                        className='dropWord marginDiv d-flex justify-content-between align-items-center'
                        onClick={() => NotificationUpdate('READALL')}
                      >
                        <h6>Mark all as read</h6>
                        <img src={tick} alt='' className='ticklogo' />
                      </div>
                      <div
                        className='dropWord d-flex justify-content-between align-items-center'
                        onClick={() =>
                          navigate('/myprofile/notificationsettings')
                        }
                      >
                        <h6>Notification settings</h6>
                        <img src={settings} alt='' className='settinglogo' />
                      </div>
                    </div>
                  )}
                </button>
              </div>
              <div className='togglebuttons'>
                <button
                  className={`naVigatorbuttons
                ${!showNotificationAllPage && 'naVigatorbuttonsActive'}`}
                  type='button'
                  onClick={() => setShowNotificationAllPage(false)}
                >
                  Unread
                  {!showNotificationAllPage && (
                    <span className='count'>
                      {loadingStatus === 'loading'
                        ? 0
                        : notificationsCount
                        ? notificationsCount
                        : showNotificationCount}
                    </span>
                  )}
                </button>
                <button
                  className={` ${
                    showNotificationAllPage && 'naVigatorbuttonsActive'
                  } naVigatorbuttons  secondButton`}
                  type='button'
                  onClick={() => setShowNotificationAllPage(true)}
                >
                  All
                  {showNotificationAllPage && (
                    <span className='count'>
                      {loadingStatus === 'loading'
                        ? 0
                        : notificationsCount
                        ? notificationsCount
                        : showNotificationCount}
                    </span>
                  )}
                </button>
              </div>
              <div
                className='pageScrollContainer'
                onScroll={onScrollNotification}
              >
                {loadingStatus === 'loading' && !onScroll ? (
                  [1, 2, 3, 4, 5].map((item) => (
                    <div className='Skeleton'>
                      {' '}
                      <Skeleton height={74} key={item} />
                    </div>
                  ))
                ) : history.keys.length > 0 ? (
                  history.keys.map((item: any) => {
                    return (
                      <div key={item}>
                        <div>
                          <h6 className='dateField'>{item}</h6>
                        </div>
                        {history.history[item].map(
                          (value: any, index: number) => (
                            <div
                              className={`${
                                index === 0 && 'fistchild'
                              } container d-flex align-items-center  justify-content-between`}
                              onClick={() => {
                                if (value?.isRead === false) {
                                  NotificationUpdate('READONE', value.uuid);
                                }
                                navigate(value?.payload.url, {
                                  state: value?.payload,
                                });
                              }}
                            >
                              <div className='d-flex align-items-center'>
                                <div className='image-container d-flex align-items-center justify-content-center'>
                                  <img
                                    src={
                                      value?.ResourceType?.name === 'CONTACT'
                                        ? CONTACT
                                        : value?.ResourceType?.name === 'LEAD'
                                        ? LEAD
                                        : value?.ResourceType?.name === 'POLICY'
                                        ? POLICY
                                        : value?.ResourceType?.name ===
                                          'OPPORTUNITY'
                                        ? OPPORTUNITY
                                        : value?.ResourceType?.name ===
                                          'ACCOUNT'
                                        ? ACCOUNT
                                        : value?.ResourceType?.name === 'TASK'
                                        ? TASK
                                        : value?.ResourceType?.name === 'EVENT'
                                        ? TASK
                                        : value?.ResourceType?.name ===
                                          'PIPELINE'
                                        ? PIPELINE
                                        : ''
                                    }
                                    alt=''
                                    className='Iconimage'
                                  />
                                </div>
                                <div>
                                  <div className='d-flex align-items-center'>
                                    <h4 className='notificationTitle'>
                                      {value?.title}
                                    </h4>
                                    <span className='d-none d-sm-block notificationTime'>
                                      {item !== 'Today'
                                        ? new Date(
                                            value.createdAt
                                          ).toLocaleString('en-US', {
                                            hour: 'numeric',
                                            hour12: true,
                                            minute: 'numeric',
                                          })
                                        : moment(value.createdAt).fromNow()}
                                    </span>
                                  </div>
                                  <span
                                    className=' d-block  notificationSub'
                                    dangerouslySetInnerHTML={{
                                      __html: value?.description,
                                    }}
                                  />
                                  <span className='d-sm-none m-0  notificationTime'>
                                    {item !== 'Today'
                                      ? new Date(
                                          value.createdAt
                                        ).toLocaleString('en-US', {
                                          hour: 'numeric',
                                          hour12: true,
                                          minute: 'numeric',
                                        })
                                      : moment(value.createdAt).fromNow()}
                                  </span>
                                </div>
                              </div>

                              {value?.isRead === false && (
                                <div
                                  className='unreadDot dropDown'
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    setShowReadDropDownUUID(value?.uuid);
                                    setShowReadDropDown(!showReadDropDown);
                                  }}
                                >
                                  <button
                                    className='vectorInside d-flex align-items-center justify-content-center position-relative'
                                    type='button'
                                  >
                                    <img src={vector} alt='vector' />
                                  </button>
                                  {showReadDropDown &&
                                    showReadDropDownUUID === value?.uuid && (
                                      <div
                                        className='smallDropDown'
                                        onClick={(e) => {
                                          e.stopPropagation();
                                          setShowReadDropDown(false);
                                          NotificationUpdate(
                                            'READONE',
                                            value?.uuid
                                          );
                                        }}
                                      >
                                        Mark as Read
                                      </div>
                                    )}
                                </div>
                              )}
                            </div>
                          )
                        )}
                      </div>
                    );
                  })
                ) : (
                  <div
                    className={`pt-5  mt-5 d-flex align-items-center justify-content-center`}
                    style={{ fontWeight: '600', fontSize: 18, opacity: 0.7 }}
                  >
                    No Notification Available
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className='d-block d-xl-none'>
        <MobileNav />
      </div>
    </Notifications>
  );
};

export default NotificationsPage;
