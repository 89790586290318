import { useEffect, useState, useRef } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { FilterContainer, MyBookDiv } from '../../../MyBook/elements/styles';
import Table from '../../../../components/TableMovable';
import MobileTab from '../components/MobileTab';
import search from '../../../../assets/logo/search.svg';
import filter from '../../../../assets/logo/filter.svg';
import filtermobile from '../../../../assets/logo/filter-mobile.svg';
import { AppDispatch } from '../../../../store';
import {
  CustomFieldsSliceState,
  getCustomFields,
  getCustomFieldsType,
  onDeleteCustomFields,
  onUpdateOrder,
  selectAllCustomFields,
} from '../../../../store/customFields/customFieldsSlice';
import sort from '../../../../assets/logo/sort.svg';
import sortup from '../../../../assets/logo/sortup.svg';
import sortdown from '../../../../assets/logo/sortdown.svg';
import AddCustomField from '../components/AddCustomField';
import EditCustomField from '../components/EditCustomField';
import { useOutsideClick } from '../../../../hooks/useOutsideClick';
import vector from '../../../../assets/logo/dropdown.svg';
import bin from '../../../../assets/logo/bin.svg';
import move from '../../../../assets/logo/move.svg';
import plus from '../../../../assets/logo/blackplus.svg';
import TableSkeleton from '../components/TableSkeleton';
import Skeleton from 'react-loading-skeleton';
import EmptyResults from '../../../MyBook/components/Empty';
import MobileTabNav from '../../../../components/MobileTabNav';
import MobileCard from '../components/MobileCard';
import CustomFieldsFilter from '../components/FilterDrawer';
import close from '../../../../assets/logo/cross.svg';
import useDebounce from '../../../../hooks/useDebounce';
import ErrorCard from '../../../Pipeline/components/ErrorCard';
import DeleteModal from '../../../../components/DeleteModal';
import {
  DragDropContext,
  Draggable,
  Droppable,
  DropResult,
} from 'react-beautiful-dnd';

const Leads = () => {
  const dispatch = useDispatch<AppDispatch>();
  const searchRef = useRef(null);

  const loadingStatus = useSelector(
    (state: { customFields: CustomFieldsSliceState }) =>
      state.customFields?.status
  );

  const [addCustomField, setAddCustomField] = useState('closed');
  const [sortBy, setSortBy] = useState('order');
  const [orderBy, setOrderBy] = useState('asc');
  const [searchBy, setSearchBy] = useState('');
  const [isSearchClicked, setIsSearchClicked] = useState(false);
  const [showEditModal, setShowEditModal] = useState('closed');
  const [data, setData] = useState<any>({});
  const [showError, setShowError] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState('closed');

  const handleSort = (sort: string) => {
    if (sort === sortBy) {
      if (orderBy === 'asc') {
        setOrderBy('desc');
      } else {
        setOrderBy('asc');
      }
    } else {
      setSortBy(sort);
    }
  };
  const { uuid } = useParams();

  useEffect(() => {
    dispatch(getCustomFieldsType());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const customFields: any = useSelector(selectAllCustomFields);

  const onDelete = (itemuuid: string) => {
    dispatch(
      onDeleteCustomFields({
        uuid: itemuuid,
        data: { agencyUUID: uuid || '' },
        successCB: () => {
          setShowDeleteModal('closing');
          setTimeout(() => {
            setShowError(true);
          }, 100);
          setTimeout(() => {
            setShowDeleteModal('closed');
            setShowError(false);
          }, 2000);
        },
      })
    );
  };

  const updateTable = (rowUUID: string, data: any) => {
    const values: any = [...data];
    values.map((val: any, index: number) => {
      const item = Object.assign({}, val);
      item.order = index;
      return item;
    });
    dispatch(
      onUpdateOrder({
        uuid: rowUUID,
        data: {
          agencyUUID: uuid || '',
          actionType: 'UPDATEORDER',
          orderArray: values,
        },
        successCB: () => {},
      })
    );
  };

  const [showFilter, setShowFilter] = useState('closed');
  const [fieldType, setType] = useState([]);
  const [appear, setAppear] = useState<any>('');

  const filterCustomFields = () => {
    dispatch(
      getCustomFields({
        uuid: uuid || '',
        resourceName: 'LEAD',
        searchBy,
        isAddForm: appear?.value,
        fieldType: fieldType?.map((item: any) => item.value),
      })
    );
  };

  const debouncedSearchTerm = useDebounce(searchBy, 500);
  useEffect(() => {
    dispatch(
      getCustomFields({
        uuid: uuid || '',
        resourceName: 'LEAD',
        searchBy: debouncedSearchTerm,
        sortBy,
        orderBy,
      })
    ); // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedSearchTerm, sortBy, orderBy]);

  const [UUID, setUUID] = useState('');
  const [name, setName] = useState('');

  const setDelete = (uuid: string, name: string) => {
    setUUID(uuid);
    setName(name);
    setShowDeleteModal('open');
  };

  const onDragEnd = ({ destination, source }: DropResult) => {
    if (!destination) {
      return;
    } else {
      const newData = [...customFields];
      const [movedRow] = newData.splice(source.index, 1);
      newData.splice(destination.index, 0, movedRow);
      newData.map((el: any, ind: number) => {
        let singleData = { ...el };
        singleData.order = ind;
        newData[ind] = singleData;
      });
      updateTable(movedRow.uuid, newData);
      return;
    }
  };

  return (
    <MyBookDiv>
      {showError && (
        <ErrorCard message={`<b>${name}</b> is <b> deleted</b> `} show='open' />
      )}
      <button
        onClick={() => setAddCustomField('open')}
        className='addButton d-sm-none add-field-mobile'
      >
        <img src={plus} alt='add' />
      </button>
      <div className='HeadDiv d-sm-none mb-2'>
        <MobileTab uuid={uuid || ''} dropText='Leads' />
      </div>

      <div className='d-flex justify-content-between'>
        <button
          className='primaryButton addButton d-none d-sm-flex add-customField-button'
          onClick={() => setAddCustomField('open')}
        >
          Add custom field
          <img src={plus} className='' alt='cross-icon' />
        </button>
        <div className='book-filter d-none d-sm-flex ms-auto' ref={searchRef}>
          {isSearchClicked || searchBy ? (
            <div className='book-search d-flex align-items-center'>
              <img src={search} alt='' className='search-button' />
              <input
                onChange={(e) => {
                  setSearchBy(e.target.value);
                }}
                autoFocus
                value={searchBy}
              />
              <img
                src={close}
                alt=''
                className='close-button'
                onClick={() => {
                  setSearchBy('');
                  setIsSearchClicked(false);
                }}
              />
            </div>
          ) : (
            <div
              className='book-shrinked'
              onClick={() => setIsSearchClicked(true)}
            >
              <img src={search} alt='' />
            </div>
          )}

          <span className='divider d-none d-sm-block' />
          <div
            className='book-shrinked d-none d-sm-flex'
            onClick={() => setShowFilter('open')}
          >
            <img src={filter} alt='' />
            {fieldType.length + (appear ? 1 : 0) > 0 && (
              <span className='filter-counter'>
                {fieldType.length + (appear ? 1 : 0)}
              </span>
            )}
          </div>
          <span
            className={`divider d-sm-none ${isSearchClicked && 'd-none'}`}
          />
          <div
            className={`book-shrinked d-sm-none ${isSearchClicked && 'd-none'}`}
            onClick={() => setShowFilter('open')}
          >
            <img src={filtermobile} alt='' />
            {fieldType.length + (appear ? 1 : 0) > 0 && (
              <span className='filter-counter'>
                {fieldType.length + (appear ? 1 : 0)}
              </span>
            )}
          </div>
        </div>
        <div className='d-flex d-sm-none'>
          <div className='d-flex search-Field  w-100'>
            <img className='mx-2' src={search} alt='' />
            <input
              className='inputSearchField mx-2'
              type='search'
              placeholder='Search'
              onChange={(e) => {
                setSearchBy(e.target.value);
              }}
              value={searchBy}
            />
          </div>
          <div
            className='d-flex search-Field filterField position-relative'
            onClick={() => setShowFilter('open')}
          >
            <img src={filtermobile} alt='' />
            {fieldType.length + (appear ? 1 : 0) > 0 && (
              <span className='filter-counter'>
                {fieldType.length + (appear ? 1 : 0)}
              </span>
            )}
          </div>
        </div>
      </div>

      <div className='mybook-content'>
        <div className=' d-sm-none mobilecardHeadDiv'>
          {loadingStatus === 'loading' ? (
            [1, 2, 3].map((item) => <Skeleton height={138} key={item} />)
          ) : loadingStatus === 'succeeded' && customFields?.length > 0 ? (
            <>
              <DragDropContext onDragEnd={(result) => onDragEnd(result)}>
                <Droppable droppableId='customFields'>
                  {(provided) => (
                    <div {...provided.droppableProps} ref={provided.innerRef}>
                      {customFields.map((item: any, index: number) => (
                        <Draggable
                          key={item.uuid}
                          draggableId={item.uuid}
                          index={index}
                        >
                          {(provided) => (
                            <div
                              ref={provided.innerRef}
                              {...provided.draggableProps}
                              {...provided.dragHandleProps}
                            >
                              <MobileCard
                                customField={item}
                                key={item.uuid}
                                setShowEditModal={setShowEditModal}
                                setData={setData}
                              />
                            </div>
                          )}
                        </Draggable>
                      ))}
                      {provided.placeholder}
                    </div>
                  )}
                </Droppable>
              </DragDropContext>
            </>
          ) : (
            <EmptyResults name='custom field' />
          )}
          <MobileTabNav />
        </div>
        <div className='d-none d-sm-block'>
          {loadingStatus === 'loading' || customFields?.length === 0 ? (
            <TableSkeleton />
          ) : (
            <Table
              updateTable={updateTable}
              columns={[
                {
                  accessor: 'fieldname',
                  resizable: true,
                  sortable: true,
                  minWidth: 180,
                  Header: () => (
                    <div
                      onClick={() => handleSort('order')}
                      style={{ cursor: 'pointer' }}
                      className='d-flex justify-content-between w-100'
                    >
                      <span>Field Name</span>
                      <img
                        className='sort'
                        src={
                          sortBy !== 'fieldname'
                            ? sort
                            : orderBy === 'asc'
                            ? sortdown
                            : sortup
                        }
                        alt='sort'
                      />
                    </div>
                  ),
                  Cell: ({ row }: { row: any }) => (
                    <div className='row-name d-flex align-items-center'>
                      <div className='drag-icon'>
                        <img src={move} alt='' />
                      </div>
                      <b>{row.original.name ? row.original.name : '--'}</b>
                    </div>
                  ),
                },
                {
                  accessor: 'type',
                  Header: 'Type',
                  resizable: true,
                  Cell: ({ row }: { row: any }) => (
                    <div className='text-capitalize'>
                      {row.original.FieldType?.name
                        ?.split('_')
                        .join(' ')
                        .toLowerCase()}
                    </div>
                  ),
                },
                {
                  accessor: 'choices',
                  resizable: true,
                  sortable: true,
                  minWidth: 140,
                  Header: 'Choices',
                  Cell: ({ row }: { row: any }) =>
                    row.original.data?.options?.map(
                      (item: any, index: number) =>
                        `${item?.value !== undefined ? item?.value : ''}${
                          index !== row.original.data?.options.length - 1
                            ? ', '
                            : ''
                        }`
                    ),
                },
                {
                  accessor: 'appearInForm',
                  Header: 'Appear in form',
                  resizable: true,
                  minWidth: 150,
                  Cell: ({ row }: { row: any }) =>
                    row.original.addToForm ? 'yes' : 'no',
                },
                {
                  accessor: 'actions',
                  Header: 'Actions',
                  sticky: 'right',
                  resizable: false,
                  width: 180,
                  minWidth: 180,
                  maxWidth: 60,
                  Cell: ({ row }: { row: any }) => (
                    <div className='d-flex align-items-center justify-content-between h-100'>
                      <button
                        className='editButton'
                        onClick={() => {
                          setShowEditModal('open');
                          setData(row.original);
                        }}
                      >
                        Edit
                      </button>
                      <div
                        className='d-flex align-items-center justify-content-end h-100 actionsButton'
                        style={{ background: 'none' }}
                      >
                        <div className='d-flex align-items-center  h-100'>
                          <ActionsDropdown
                            uuid={row.original.uuid}
                            onDelete={setDelete}
                            name={row.original.name}
                          />
                        </div>
                      </div>
                    </div>
                  ),
                },
              ]}
              data={customFields}
            />
          )}
        </div>
      </div>
      {addCustomField !== 'closed' && (
        <AddCustomField
          addCustomField={addCustomField}
          closeModal={setAddCustomField}
          loadingStatus={loadingStatus}
          resourceName='LEAD'
        />
      )}

      {showEditModal !== 'closed' && (
        <EditCustomField
          addCustomField={showEditModal}
          closeModal={setShowEditModal}
          loadingStatus={loadingStatus}
          fields={data}
        />
      )}
      {showDeleteModal !== 'closed' && (
        <DeleteModal
          showModal={showDeleteModal}
          closeModal={setShowDeleteModal}
          deleteFunction={onDelete}
          uuid={UUID}
          loading={loadingStatus}
          resource='custom field'
          name={name}
        />
      )}
      <FilterContainer>
        {showFilter !== 'closed' && (
          <CustomFieldsFilter
            show={showFilter}
            closeModal={setShowFilter}
            filterFunction={filterCustomFields}
            appear={appear}
            setAppear={setAppear}
            fieldType={fieldType}
            setType={setType}
          />
        )}
      </FilterContainer>
    </MyBookDiv>
  );
};

export default Leads;

const ActionsDropdown = ({
  uuid,
  onDelete,
  name,
}: {
  uuid: string;
  onDelete: Function;
  name: string;
}) => {
  const [showActionsDropdown, setShowActionsDropdown] = useState(false);
  const dropRef = useRef<HTMLButtonElement>(null);
  useOutsideClick(dropRef, setShowActionsDropdown);

  return (
    <button
      className='vector d-flex align-items-center justify-content-center position-relative'
      onClick={() => {
        setShowActionsDropdown(!showActionsDropdown);
        if (dropRef.current) {
          dropRef.current.focus();
        }
      }}
      onBlur={() => setShowActionsDropdown(false)}
      ref={dropRef}
    >
      <img src={vector} alt='vector' />
      {showActionsDropdown && (
        <div className='dropdown-div'>
          <div className='delete-div' onClick={() => onDelete(uuid, name)}>
            Delete <img src={bin} alt='bin' />
          </div>
        </div>
      )}
    </button>
  );
};
