import { useParams, useNavigate } from 'react-router-dom';
import { Navigate, Route, Routes, useLocation } from 'react-router-dom';
import TopBar from '../../../components/TopBar';
import SideBar from '../../../components/SideBar';
import { AdminDiv } from '../../../styles/AdminManage';
import MobileNav from '../../../components/MobileTabNav';
import Tab from './components/Tab';
import 'react-loading-skeleton/dist/skeleton.css';
import Leads from '../CustomFieldSettings/Leads';
import arrow from '../../../assets/logo/right-arrow.svg';
import LocalStorageService from '../../../utils/LocalStorageService';
import { CustomFieldsContent } from './styles';
import OpportunityCustomFields from './components/OpportunityCustomFields';
import AccountsCustomFields from './components/AccountsCustomFields';
import ContactsCustomFields from './components/ContactsCustomFields';
import PoliciesCustomFields from './components/PoliciesCustomFields';

function CustomFields() {
  const location = useLocation();
  const { uuid } = useParams();
  const navigate = useNavigate();
  const user = LocalStorageService.getUser();
  return (
    <AdminDiv>
      <div className='d-none d-xl-block'>
        <SideBar />
      </div>

      <CustomFieldsContent className='content-div'>
        <TopBar>
          <div
            className='d-flex align-items-center'
            onClick={() =>
              navigate(
                user.UserRole.name === 'ADMIN'
                  ? `/admin/agency/${uuid}`
                  : `/settings`
              )
            }
            role='button'
          >
            <img
              src={arrow}
              className='leftArrow back-button-head'
              alt='arrow'
            />
            <h2>Custom field settings</h2>
          </div>
        </TopBar>
        <div className='tab-div d-none d-sm-block'>
          <Tab />
        </div>
        <Routes>
          <Route path='/leads' element={<Leads />} />
          <Route path='/opportunities' element={<OpportunityCustomFields />} />
          <Route path='/accounts' element={<AccountsCustomFields />} />
          <Route path='/contacts' element={<ContactsCustomFields />} />

          <Route path='/policies' element={<PoliciesCustomFields />} />

          <Route
            path='*'
            element={
              user.UserRole.name === 'ADMIN' ? (
                <Navigate
                  to={`${location.pathname
                    .split('/')
                    .slice(0, 3)
                    .join('/')}/${uuid}/customfield/leads`}
                  replace
                />
              ) : (
                <Navigate
                  to={`${location.pathname
                    .split('/')
                    .slice(0, 3)
                    .join('/')}/leads`}
                  replace
                />
              )
            }
          />
        </Routes>
      </CustomFieldsContent>
      <div className='d-block d-xl-none'>
        <MobileNav />
      </div>
    </AdminDiv>
  );
}

export default CustomFields;
