import { useEffect, useState } from 'react';
import Skeleton from 'react-loading-skeleton';
import { fetchJurisdiction } from '../../../../api/jurisdiction';
import { fetchAgents } from '../../../../api/agents';
import Modal from '../../../../components/Modal';
import SearchDropdown from '../../../../components/SearchDropdown';
import LocalStorageService from '../../../../utils/LocalStorageService';
import { IProducer } from '../../../../interfaces/Agent';
import { IJurisdiction } from '../../../../interfaces/Jurisdiction';
import { StyledModal } from '../../../../components/FilterDrawer/styles';
import { MobileFilterNavDiv } from '../../../../components/FilterMobileDrawer/styles';
import cross from '../../../../assets/logo/cross.svg';

const ContactsFilter = ({
  show,
  closeModal,
  filterFunction,
  jurisdictionsUUID,
  setJurisdictionsUUID,
  producer,
  setProducer,
  accountType,
  setAccountType,
  producerSearch,
  setProducerSearch,
  licenseSearch,
  setLicenseSearch,
}: {
  show: string;
  closeModal: Function;
  filterFunction: Function;
  jurisdictionsUUID: string;
  setJurisdictionsUUID: Function;
  producer: string;
  setProducer: Function;
  accountType: any;
  setAccountType: Function;
  producerSearch: string;
  setProducerSearch: Function;
  licenseSearch: string;
  setLicenseSearch: Function;
}) => {
  const onCloseModal = () => {
    closeModal('closing');
    setTimeout(() => {
      closeModal('closed');
    }, 300);
  };

  const [producers, setProducers] = useState<IProducer[]>([]);

  const [loadingStatus, setLoadingStatus] = useState('idle');

  const [licensedState, setLicensedState] = useState<IJurisdiction[]>([]);

  const user = LocalStorageService.getUser();

  const loadProducer = (value: string) => {
    if (
      loadingStatus === 'idle' ||
      loadingStatus === 'succeeded' ||
      user?.UserRole?.name === 'PRINCIPAL_AGENT'
    ) {
      // setLoadingStatus('loading');
      fetchAgents(
        (data: IProducer[]) => {
          setProducers(data);
          // setLoadingStatus('succeeded');
        },
        'id',
        'asc',
        value,
        'True'
      );
    }
  };

  const loadLicensedState = (value: string) => {
    if (loadingStatus === 'idle' || loadingStatus === 'succeeded') {
      fetchJurisdiction('name', 'asc', value, (data: IJurisdiction[]) => {
        setLicensedState(data);
      });
    }
  };

  useEffect(() => {
    if (loadingStatus === 'idle' || loadingStatus === 'succeeded') {
      // setLoadingStatus('loading');
      filterFunction(
        accountType.map((item: any) => item.value),
        jurisdictionsUUID,
        producer
      );
    }
  }, [jurisdictionsUUID, accountType, producer]);

  const filterContent = () => {
    return (
      <>
        <div>
          <h6 className='head text-uppercase'>Licensed state</h6>
        </div>
        {loadingStatus === 'loading' ? (
          <Skeleton height={48} />
        ) : (
          <div className='agency-fields'>
            <SearchDropdown
              name='driverLicensedState'
              placeholder='Select'
              isEdit={true}
              values={licenseSearch}
              searchValue={licensedState}
              setSearch={setLicenseSearch}
              status={loadingStatus}
              className={`${licenseSearch && 'filled'}`}
              loadFunction={loadLicensedState}
              setUUID={setJurisdictionsUUID}
              content={licensedState?.map((item) => (
                <div
                  className='searchValuesCard d-flex align-items-center'
                  onClick={() => {
                    setLicenseSearch(item?.name);
                    setJurisdictionsUUID(item?.uuid);
                  }}
                >
                  <div>
                    <h6>{item.name}</h6>
                  </div>
                </div>
              ))}
            />
          </div>
        )}

        {user.UserRole.name === 'PRINCIPAL_AGENT' ? (
          <>
            <hr className='filter-hr' />
            <div>
              <h6 className='head text-uppercase'>Producer</h6>
            </div>
            {loadingStatus === 'loading' ? (
              <Skeleton height={48} />
            ) : (
              <div className='agency-fields'>
                <SearchDropdown
                  name='producer'
                  placeholder='Select'
                  isEdit={true}
                  addName={true}
                  values={producerSearch}
                  searchValue={producers}
                  setSearch={setProducerSearch}
                  status={loadingStatus}
                  className={`${producerSearch && 'filled'}`}
                  loadFunction={loadProducer}
                  setUUID={setProducer}
                  content={producers?.map((item) => (
                    <div
                      className='searchValuesCard d-flex align-items-center'
                      onClick={() => {
                        setProducerSearch(
                          `${item.User.firstName} ${
                            item.User.lastName ? item.User.lastName : ''
                          }`
                        );
                        setProducer(item?.uuid);
                      }}
                    >
                      <div className='profile-pic d-flex align-items-center justify-content-center'>
                        {item?.User?.firstName?.charAt(0)}
                        {item?.User?.lastName?.charAt(0)}
                      </div>
                      <div>
                        <h6>
                          {`${item.User.firstName} ${
                            item.User.lastName ? item.User.lastName : ''
                          }`}
                        </h6>
                        <ul className='user-details'>
                          <li>{item.User?.email}</li>
                          <li>{item?.User?.phoneNumber}</li>
                        </ul>
                      </div>
                    </div>
                  ))}
                />
              </div>
            )}
          </>
        ) : null}
      </>
    );
  };

  return (
    <>
      <div className='d-none d-sm-block'>
        <Modal open={show} align='right'>
          <StyledModal>
            <button className='crossbutton' onClick={() => onCloseModal()}>
              <img src={cross} className='cross' alt='cross-icon' />
            </button>
            <div>
              <h2>Filters</h2>
            </div>
            <div>
              <hr className='filter-hr' />
            </div>
            <div>
              <div>
                {show && (
                  <div>
                    <div className='field-section'>{filterContent()}</div>
                    <div className='buttonsDiv filterButtonContainer d-flex align-items-center justify-content-between'>
                      <div className='filter-count'>
                        {(jurisdictionsUUID ? 1 : 0) + (producer ? 1 : 0)}{' '}
                        filters added
                      </div>
                      <button
                        type='submit'
                        className='agentButton justify-content-end'
                        onClick={() => {
                          setProducer('');
                          setAccountType([]);
                          setProducerSearch('');
                          setLicenseSearch('');
                          setJurisdictionsUUID('');
                        }}
                        disabled={
                          (jurisdictionsUUID ? 1 : 0) + (producer ? 1 : 0) === 0
                        }
                      >
                        Clear all filters
                      </button>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </StyledModal>
        </Modal>
      </div>
      <div className='d-block d-sm-none'>
        <MobileFilterNavDiv>
          <div className='backgroundQuickAdd'>
            <div className={`quickFormsDropDown closed`}>
              <div className='d-flex  drawerHandle'></div>
              <h2>Filters</h2>
              <button className='crossbutton' onClick={() => onCloseModal()}>
                <img src={cross} className='cross' alt='cross-icon' />
              </button>
              <div className='scroll-filter-mob'>
                {filterContent()}
                <div className='buttonsDiv d-flex align-items-center justify-content-between'>
                  <div className='filter-count'>
                    {(jurisdictionsUUID ? 1 : 0) + (producer ? 1 : 0)} filters
                    added
                  </div>
                  <button
                    type='submit'
                    className='agentButton justify-content-end'
                    onClick={() => {
                      setProducer('');
                      setAccountType([]);
                      setProducerSearch('');
                      setLicenseSearch('');
                      setJurisdictionsUUID('');
                    }}
                    disabled={
                      (jurisdictionsUUID ? 1 : 0) + (producer ? 1 : 0) === 0
                    }
                  >
                    Clear all filters
                  </button>
                </div>
              </div>
            </div>
          </div>
        </MobileFilterNavDiv>
      </div>
    </>
  );
};

export default ContactsFilter;
