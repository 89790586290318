import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import moment from 'moment';
import { AppDispatch } from '../../store';
import { getCount } from '../../store/book/bookSlice';
import {
  createOpportunity,
  OpportunitiesSliceState,
} from '../../store/opportunities/opportunitiesSlice';
import { getPipelines } from '../../store/pipelines/pipelineSlice';
import { getCustomFields } from '../../store/customFields/customFieldsSlice';
import { fetchAgents } from '../../api/agents';
import { fetchIndustries } from '../../api/industry';
import { fetchContacts } from '../../api/contact';
import { fetchJurisdiction } from '../../api/jurisdiction';
import { fetchProducts } from '../../api/product';
import { getLeadSourceTypes } from '../../api/lead';
import SelectField from '../SelectField';
import InputField from '../InputField';
import DateField from '../DateField';
import PhoneNumberInput from '../PhoneNumberFormat';
import SuccessCard from '../SuccessCard';
import SearchDropdown from '../SearchDropdown';
import GoogleSearchInput from '../GoogleSearchInput';
import MarkdownEditor from '../MarkdownEditor';
import CustomFieldInput from '../CustomfieldsInput';
import { IProducer } from '../../interfaces/Agent';
import { IPipelineStage, IPipeline } from '../../interfaces/Opportunity';
import { IJurisdiction } from '../../interfaces/Jurisdiction';
import { IProduct } from '../../interfaces/Product';
import { ILeadSourceType } from '../../interfaces/Lead';
import { StyledOpportunityModal } from './styles';
import { SuccessAlign } from '../../styles/SuccessAlign';
import { FormModal } from '../../styles/FormModal';
import cross from '../../assets/logo/cross.svg';
import tick from '../../assets/logo/tick.svg';
import warning from '../../assets/logo/warning.svg';

const OpportinitySchema = Yup.object().shape({
  lastName: Yup.string().required('Last name is required'),
  opportunityName: Yup.string().required('Please enter the name.'),
  email: Yup.string()
    .email('Invalid email. Please try again')
    .required('Please enter the email '),
  phoneNumber: Yup.string()
    .required('Phone Number is required')
    .matches(
      /[(][0-9]{3}[)][-\s][0-9]{3}[-][0-9]{4}$/,
      'Phone number is not valid'
    ),
  pipelineStage: Yup.object().shape({
    value: Yup.string().required('Please select a pipeline stage'),
  }),
  pipeline: Yup.object().shape({
    value: Yup.string().required('Please select a pipeline.'),
  }),
});

const AddOpportunityDrawer = ({
  show,
  closeModal,
}: {
  show: string;
  closeModal: Function;
}) => {
  const dispatch = useDispatch<AppDispatch>();
  const {
    register,
    handleSubmit,
    control,
    reset,
    watch,
    getValues,
    setValue,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(OpportinitySchema),
  });

  const navigate = useNavigate();

  const loading = useSelector(
    (state: { opportunities: OpportunitiesSliceState }) =>
      state.opportunities.isLoading
  );

  const marital = [
    { label: 'Married', value: 'married', disabled: false },
    { label: 'Unmarried', value: 'unmarried', disabled: false },
  ];

  const watchAllFields = watch();
  const watchPipeline = watch('pipeline');

  const [isCommercial, setCommercial] = useState(false);
  const [errorMessage, setError] = useState('');
  const [showSuccess, setshowSuccess] = useState(false);
  const [producers, setProducers] = useState<IProducer[]>([]);
  const [productCategory, setProductCategory] = useState<IProduct[]>([]);
  const [addressObject, setAddressObject] = useState<any>({});

  const [policySearch, setPolicySearch] = useState('');
  const [policy, setPolicy] = useState('');

  const [producerSearch, setProducerSearch] = useState('');
  const [producer, setProducer] = useState('');
  const [productType, setProductType] = useState('');
  const [productUUID, setProductUUID] = useState('');

  const [accountSearch, setAccountSearch] = useState('');
  const [account, setAccount] = useState('');

  const [industrySearch, setIndustrySearch] = useState('');
  const [industryUuid, setIndustryUuid] = useState('');
  const [industry, setIndustry] = useState<any>([]);
  const [sourceTypes, setSourceTypes] = useState<ILeadSourceType[]>([]);

  const onCloseModal = () => {
    closeModal('closing');
    setTimeout(() => {
      closeModal('closed');
    }, 300);
  };

  const [emailAccounts, setEmailAccounts] = useState<any[]>([]);
  const [emailSearch, setEmailSearch] = useState('');
  const [emailError, setEmailError] = useState(false);
  const [emailData, setEmailData] = useState<any>([]);
  const [submit, setSubmit] = useState(false);
  const [loadingStatus, setLoadingStatus] = useState('idle');
  const [licensedState, setLicensedState] = useState<IJurisdiction[]>([]);
  const [jurisdictionsUUID, setJurisdictionsUUID] = useState('');
  const [licenseSearch, setLicenseSearch] = useState('');
  const [pipelines, setPipelines] = useState<any[]>([]);
  const [accountCustomFields, setAccountCustomFields] = useState<any>();
  const [contactCustomFields, setContactCustomFields] = useState<any>();
  const [opportunityCustomFields, setOpportunityCustomFields] = useState<any>();
  const watchLastName = watch('lastName');
  const [disabled, setDisabled] = useState(false);
  const [textCheck, setTextCheck] = useState('');
  const [noteError, setNoteError] = useState('');

  const loadEmail = (value: string) => {
    errors.email = null;
    setEmailError(false);
    if (loadingStatus === 'idle' || loadingStatus === 'succeeded') {
      fetchContacts('id', 'asc', emailSearch, 25, 0, 'True', (data: any) => {
        setEmailAccounts(data);
        setLoadingStatus('succeeded');
      });
    }
  };

  const onSetPipelineValue = (item?: IPipeline) => {
    if (item) {
      const temp = item.PipelineStages.reduce((t: any, o) => {
        if (o.nextStageId) {
          t[o.nextStageId] = o;
        } else {
          t['last'] = o;
        }
        return t;
      }, {});

      let result: IPipelineStage[] = [];

      let p: any = 'last';

      while (temp[p]) {
        result.unshift(temp[p]);
        p = temp[p].id;
      }

      return result
        .filter((item, index) => index < result.length - 2)
        .map((el: any) => {
          return { label: el.name, value: el.uuid };
        });
    }
    return [];
  };
  const onSubmit = (value: any) => {
    value.accountCustomFields = {};
    value.contactCustomFields = {};
    value.opportunityCustomFields = {};
    opportunityCustomFields.map((item: any) => {
      if (item.uuid in value) {
        value.opportunityCustomFields[item.uuid] = value[item.uuid];
      }
    });
    contactCustomFields.map((item: any) => {
      if (item.uuid in value) {
        value.contactCustomFields[item.uuid] = value[item.uuid];
      }
    });
    accountCustomFields.map((item: any) => {
      if (item.uuid in value) {
        value.accountCustomFields[item.uuid] = value[item.uuid];
      }
    });
    var canSubmit = true;
    const emailExist = emailAccounts.find(
      (item) =>
        item?.ContactDetails?.find((value: any) => value.type === 'EMAIL')
          ?.value === emailSearch
    );
    let errorLastName = true;
    if (!isCommercial && !watchLastName) {
      errorLastName = false;
    }
    if (emailExist) {
      setEmailError(true);
      setEmailData([
        `${emailExist?.firstName} ${emailExist?.lastName}`,
        emailExist?.uuid,
      ]);
      canSubmit = false;
    }
    if (producerSearch && !producer && producerSearch !== 'Me') {
      canSubmit = false;
    }
    if (industrySearch && !industryUuid) {
      canSubmit = false;
    }
    if (accountSearch && !account) {
      canSubmit = false;
    }
    if (!emailError && emailSearch && policy && canSubmit) {
      value.accountType = isCommercial ? 'COMMERCIAL' : 'PERSONAL';
      value.pipelineStage = value.pipelineStage?.value;
      value.agentUUID = producer;
      value.accountUUID = account;
      value.productCategoryUUID = policy;
      if (productType === 'PRODUCT') {
        value.productUUID = productUUID;
      } else if (productType === 'CUSTOM_PRODUCT') {
        value.customProductUUID = productUUID;
      }
      value.maritalStatus = value.maritalStatus?.value;
      value.jurisdictionUUID = jurisdictionsUUID;
      value.sourceName = value.sourceName?.label;
      value.pipeline = value.pipeline?.value;
      value.account = { label: accountSearch, value: account };
      value.industry = industryUuid;
      let noteSubmit = true;
      if (notes) {
        if (textCheck && !textCheck.trim() && textCheck.length > 1) {
          noteSubmit = false;
          setNoteError('Note cannot be blank space');
        }
      }
      value.notes = notes;
      value.isManuallyAdded = isManual;
      // To add addressObject with the form
      if (!isManual) {
        value = Object.assign(value, addressObject);
      }
      if (errorLastName === true && noteSubmit) {
        dispatch(
          createOpportunity({
            data: value,
            successCB: () => {
              dispatch(getCount());
              closeModal('closing');
              setTimeout(() => {
                setshowSuccess(true);
                setDisabled(true);
              }, 300);
              setTimeout(() => {
                reset();
                setshowSuccess(false);
                setTimeout(() => {
                  closeModal('closed');
                }, 300);
              }, 2000);
            },
            errorCB: (error: string) => {
              setDisabled(false);
              if (typeof error === 'string') setError(error);
            },
          })
        );
      }
    }
  };

  useEffect(() => {
    if (errorMessage) {
      setError('');
    }
  }, [watchAllFields.phoneNumber]);

  const loadPolicy = (value: string) => {
    if (loadingStatus === 'idle' || loadingStatus === 'succeeded') {
      setLoadingStatus('loading');
      fetchProducts(
        (data: IProduct[]) => {
          setProductCategory(data);
          setLoadingStatus('succeeded');
        },
        'name',
        'asc',
        value,
        '',
        true,
        isCommercial ? 'COMMERCIAL' : 'PERSONAL'
      );
    }
  };

  const loadLicensedState = (value: string) => {
    if (loadingStatus === 'idle' || loadingStatus === 'succeeded') {
      setLoadingStatus('loading');
      fetchJurisdiction('name', 'asc', value, (data: IJurisdiction[]) => {
        setLicensedState(data);
        setLoadingStatus('succeeded');
      });
    }
  };

  const loadProducer = (value: string) => {
    if (
      loadingStatus === 'idle' ||
      loadingStatus === 'succeeded' ||
      user?.UserRole?.name === 'PRINCIPAL_AGENT'
    ) {
      setLoadingStatus('loading');
      fetchAgents(
        (data: IProducer[]) => {
          setProducers(data);
          setLoadingStatus('succeeded');
        },
        'id',
        'asc',
        value,
        'True'
      );
    }
  };

  const loadIndustry = (value: string) => {
    if (loadingStatus === 'idle' || loadingStatus === 'succeeded') {
      setLoadingStatus('loading');
      fetchIndustries(
        (data: any) => {
          setIndustry(data);
          setLoadingStatus('succeeded');
        },
        'id',
        'asc',
        value,
        'True'
      );
    }
  };

  const [user, setUser] = useState<any>({});

  useEffect(() => {
    const user = JSON.parse(localStorage.getItem('user') || '{}');
    if (user) {
      setUser(user);
      if (user?.UserRole?.name === 'AGENT') {
        setProducerSearch('Me');
      }
      dispatch(
        getCustomFields({
          uuid: user.UserRole.name === 'ADMIN' ? user.uuid : '',
          resourceName: 'OPPORTUNITY',
          isAddForm: 'true',
          successCB: (data: any) => {
            setOpportunityCustomFields(data);
          },
        })
      );
      dispatch(
        getCustomFields({
          uuid: user.UserRole.name === 'ADMIN' ? user.uuid : '',
          resourceName: 'ACCOUNT',
          isAddForm: 'true',
          successCB: (data: any) => {
            setAccountCustomFields(data);
          },
        })
      );
      dispatch(
        getCustomFields({
          uuid: user.UserRole.name === 'ADMIN' ? user.uuid : '',
          resourceName: 'CONTACT',
          isAddForm: 'true',
          successCB: (data: any) => {
            setContactCustomFields(data);
          },
        })
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    accountCustomFields?.map((item: any) => {
      if (item.FieldType?.name === 'DATEPICKER' && item.data?.defaultValue) {
        setValue(item.uuid, moment(item.data?.defaultValue).toDate());
      }
      if (item.FieldType?.name === 'MULTIPLE_CHOICE') {
        const filteredData: any = item.data.options.filter(
          (it: any) => it.default === true
        );
        setValue(
          item.uuid,
          filteredData.map((el: any) => {
            return { value: el.order, label: el.value };
          })
        );
      }
      if (item.FieldType?.name === 'SINGLE_CHOICE') {
        if (item.data?.defaultValue) {
          setValue(item.uuid, {
            label:
              item.data.options[parseInt(item.data?.defaultValue, 10)]?.value,
            value:
              item.data.options[parseInt(item.data?.defaultValue, 10)]?.order,
          });
        }
      }
    });
    contactCustomFields?.map((item: any) => {
      if (item.FieldType?.name === 'DATEPICKER' && item.data?.defaultValue) {
        setValue(item.uuid, moment(item.data?.defaultValue).toDate());
      }
      if (item.FieldType?.name === 'MULTIPLE_CHOICE') {
        const filteredData: any = item.data.options.filter(
          (it: any) => it.default === true
        );
        setValue(
          item.uuid,
          filteredData.map((el: any) => {
            return { value: el.order, label: el.value };
          })
        );
      }
      if (item.FieldType?.name === 'SINGLE_CHOICE') {
        if (item.data?.defaultValue) {
          setValue(item.uuid, {
            label:
              item.data.options[parseInt(item.data?.defaultValue, 10)]?.value,
            value:
              item.data.options[parseInt(item.data?.defaultValue, 10)]?.order,
          });
        }
      }
    });
    opportunityCustomFields?.map((item: any) => {
      if (item.FieldType?.name === 'DATEPICKER' && item.data?.defaultValue) {
        setValue(item.uuid, moment(item.data?.defaultValue).toDate());
      }
      if (item.FieldType?.name === 'MULTIPLE_CHOICE') {
        const filteredData: any = item.data.options.filter(
          (it: any) => it.default === true
        );
        setValue(
          item.uuid,
          filteredData.map((el: any) => {
            return { value: el.order, label: el.value };
          })
        );
      }
      if (item.FieldType?.name === 'SINGLE_CHOICE') {
        if (item.data?.defaultValue) {
          setValue(item.uuid, {
            label:
              item.data.options[parseInt(item.data?.defaultValue, 10)]?.value,
            value:
              item.data.options[parseInt(item.data?.defaultValue, 10)]?.order,
          });
        }
      }
    });
  }, [opportunityCustomFields, contactCustomFields, accountCustomFields]);

  useEffect(() => {
    if (policy != '') {
      dispatch(
        getPipelines({
          sortBy: '',
          orderBy: '',
          searchBy: '',
          successCB: (data: any) => {
            setPipelines(data);
          },
          agencyUUID: '',
          productUUID: productType === 'PRODUCT' ? policy : '',
          customProductUUID: productType === 'CUSTOM_PRODUCT' ? policy : '',
        })
      );
    } else {
      setPipelines([]);
    }
    setValue('pipeline', '');
    setValue('pipelineStage', '');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [policy]);

  const onSetNotesValue = (value: any, index: number) => {
    let updatedNotes = [...notes];
    updatedNotes[index] = value;
    setNotes(updatedNotes);
  };

  useEffect(() => {
    // setAccounts([]);
    setAccountSearch('');
    setAccount('');
    setAddressObject({});
  }, [isCommercial]);

  useEffect(() => {
    if (!watchPipeline) {
      setValue('pipelineStage', '');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [watchPipeline]);

  const resetEmail = (value: string) => {
    setEmailData([]);
    setEmailError(false);
  };

  const [notes, setNotes] = useState<any>(['']);
  const [isManual, setIsManual] = useState(false);

  useEffect(() => {
    setValue('pipeline', '');
  }, []);

  useEffect(() => {
    getLeadSourceTypes((data: ILeadSourceType[]) => {
      setSourceTypes(data);
    });
  }, []);

  useEffect(() => {
    if (notes) {
      if (textCheck) {
        setNoteError('');
      }
    }
  }, [notes]);

  return (
    <>
      <SuccessAlign>
        <div className={`success-container ${showSuccess && 'open-drawer'} `}>
          <SuccessCard
            title={'Success adding Opportunity'}
            message={`<b>${getValues(
              'opportunityName'
            )}</b> has been added as opportunity<b>`}
          />
          <button
            onClick={() => {
              reset();
              setshowSuccess(false);
            }}
            className='crossbutton'
          >
            <img src={cross} className='cross' alt='cross-icon' />
          </button>
        </div>
      </SuccessAlign>
      <StyledOpportunityModal>
        <FormModal
          open={show}
          align='right'
          containerClassName='fullwidth-container'
        >
          <div className='entire-div'>
            <div className='topdiv'>
              <div className='form-title'>
                <h2>Add opportunity</h2>
              </div>
              <div>
                <button className='cross-btn'>
                  <img
                    className='cross'
                    src={cross}
                    alt='cross-icon'
                    onClick={() => {
                      reset();
                      onCloseModal();
                    }}
                  />
                </button>
              </div>
              <div className='d-flex'>
                <p
                  onClick={() => {
                    setCommercial(false);
                    setProductCategory([]);
                    setPolicySearch('');
                    setPolicy('');
                    setProductUUID('');
                    setProductType('');
                  }}
                  className={
                    !isCommercial
                      ? 'naVigatorbuttonsActive'
                      : 'naVigatorbuttons'
                  }
                >
                  Personal
                </p>
                <div className='commercial-text'>
                  <p
                    onClick={() => {
                      setCommercial(true);
                      setProductCategory([]);
                      setPolicySearch('');
                      setPolicy('');
                      setProductUUID('');
                      setProductType('');
                    }}
                    className={
                      isCommercial
                        ? 'naVigatorbuttonsActive'
                        : 'naVigatorbuttons'
                    }
                  >
                    Commercial
                  </p>
                </div>
              </div>
            </div>
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className='scroll-bar  '>
                <div>
                  <h2 className='contact-info'>Contact Info</h2>
                </div>

                <div className='top-field d-sm-flex justify-content-between'>
                  <div className='fields'>
                    <InputField
                      name='firstName'
                      type='text'
                      placeholder='First name'
                      register={register}
                      className={`${errors.firstName && 'error-field'} ${
                        watchAllFields.firstName && 'filled'
                      }`}
                    />
                    {errors.firstName ? (
                      <div className='errorVal'>
                        <img src={warning} alt='warning' className='warning' />
                        {errors.firstName?.message}
                      </div>
                    ) : null}
                  </div>
                  <div className='small-div d-sm-flex justify-content-between'>
                    <div className='fields-small'>
                      <InputField
                        name='middleName'
                        type='text'
                        placeholder='Middle name'
                        register={register}
                        className={`${errors.middleName && 'error-field'} ${
                          watchAllFields.middleName && 'filled'
                        }`}
                      />
                      {errors.middleName ? (
                        <div className='errorVal'>
                          <img
                            src={warning}
                            alt='warning'
                            className='warning'
                          />
                          {errors.middleName?.message}
                        </div>
                      ) : null}
                    </div>
                    <div className='fields-small'>
                      <InputField
                        name='lastName'
                        type='text'
                        placeholder='Last name'
                        register={register}
                        required={true}
                        className={`${errors.lastName && 'error-field'} ${
                          watchAllFields.lastName && 'filled'
                        }`}
                      />
                      {errors.lastName ||
                      (!isCommercial && !watchLastName && submit) ? (
                        <div className='errorVal'>
                          <img
                            src={warning}
                            alt='warning'
                            className='warning'
                          />
                          {errors.lastName?.message || 'Last name is required'}
                        </div>
                      ) : null}
                    </div>
                  </div>
                </div>

                <div className='d-sm-flex justify-content-between'>
                  <div className='fields'>
                    <DateField
                      name='dateOfBirth'
                      placeholder='Date of birth'
                      control={control}
                      maxDate={new Date()}
                      dateOfBirth
                      value={watchAllFields.dateOfBirth}
                    />
                  </div>
                  <div className='fields'>
                    <PhoneNumberInput
                      name='phoneNumber'
                      type='tel'
                      placeholder='Phone number'
                      control={control}
                      required
                      className={`${
                        (errors.phoneNumber || errorMessage) && 'error-field'
                      } ${watchAllFields.phoneNumber && 'filled'}`}
                    />
                    {errors.phoneNumber || errorMessage ? (
                      <div className='errorVal'>
                        <img src={warning} alt='warning' className='warning' />
                        {errors.phoneNumber?.message || errorMessage}
                      </div>
                    ) : null}
                  </div>
                </div>
                <div className='d-sm-flex justify-content-between'>
                  <div className='fields'>
                    <SearchDropdown
                      name='email'
                      addName={true}
                      placeholder='Email'
                      isEdit={true}
                      values={emailSearch}
                      searchValue={emailAccounts}
                      setSearch={setEmailSearch}
                      status={loadingStatus}
                      className={`${emailSearch && 'filled'}`}
                      register={register}
                      type='email'
                      setUUID={resetEmail}
                      required={true}
                      loadFunction={loadEmail}
                      content={emailAccounts?.map((item) => (
                        <div
                          className='searchValuesCard d-flex align-items-center'
                          onClick={() => {
                            setEmailSearch(
                              item?.ContactDetails?.find(
                                (value: any) => value.type === 'EMAIL'
                              )?.value
                            );
                            setEmailError(true);
                            setEmailData([
                              `${item?.firstName} ${item?.lastName}`,
                              item?.uuid,
                            ]);
                          }}
                        >
                          <div className='profile-pic d-flex align-items-center justify-content-center'>
                            {item?.firstName?.charAt(0)}
                          </div>
                          <div>
                            <h6>
                              {item?.firstName} {item?.lastName}
                            </h6>
                            <ul className='user-details'>
                              <li>
                                {
                                  item?.ContactDetails?.find(
                                    (value: any) => value.type === 'EMAIL'
                                  )?.value
                                }
                              </li>
                              <li>
                                {
                                  item?.ContactDetails?.find(
                                    (value: any) => value.type === 'PHONENUMBER'
                                  )?.value
                                }
                              </li>
                            </ul>
                          </div>
                        </div>
                      ))}
                    />
                    {errors.email ? (
                      <div className='errorVal'>
                        <img src={warning} alt='warning' className='warning' />
                        {errors.email?.message}
                      </div>
                    ) : emailError ? (
                      <div className='errorVal emailError'>
                        <img src={warning} alt='warning' className='warning' />
                        <div className='email-exist-error'>
                          This email is already associated with another contact{' '}
                          <span
                            onClick={() =>
                              navigate(`/contacts/${emailData[1]}`)
                            }
                          >
                            {emailData[0]}
                          </span>{' '}
                          Please use a different email.
                        </div>
                      </div>
                    ) : (
                      !emailSearch &&
                      submit && (
                        <div className='errorVal'>
                          <img
                            src={warning}
                            alt='warning'
                            className='warning'
                          />
                          Please enter your email
                        </div>
                      )
                    )}
                  </div>

                  <div className='fields'>
                    <SelectField
                      options={marital}
                      name='maritalStatus'
                      placeholder='Marital status'
                      control={control}
                      isEdit={true}
                    />
                  </div>
                </div>
                <div className='d-sm-flex justify-content-between'>
                  <div className='fields'>
                    <SearchDropdown
                      name='driverLicensedState'
                      placeholder='Licensed state'
                      isEdit={true}
                      values={licenseSearch}
                      searchValue={licensedState}
                      setSearch={setLicenseSearch}
                      status={loadingStatus}
                      className={`${licenseSearch && 'filled'}`}
                      register={register}
                      control={control}
                      loadFunction={loadLicensedState}
                      setUUID={setJurisdictionsUUID}
                      content={licensedState?.map((item) => (
                        <div
                          className='searchValuesCard d-flex align-items-center'
                          onClick={() => {
                            setLicenseSearch(item?.name);
                            setJurisdictionsUUID(item?.uuid);
                          }}
                        >
                          <div>
                            <h6>{item.name}</h6>
                          </div>
                        </div>
                      ))}
                    />
                  </div>
                  <div className='fields'>
                    <InputField
                      name='driverlicenseNumber'
                      type='text'
                      placeholder='License number'
                      register={register}
                      className={`${
                        errors.driverlicenseNumber && 'error-field'
                      } ${watchAllFields.driverlicenseNumber && 'filled'}`}
                    />
                  </div>
                </div>
                <div>
                  {!isCommercial ? (
                    !isManual ? (
                      <div className=' d-sm-flex justify-content-between'>
                        <div className='fields'>
                          <GoogleSearchInput
                            placeholder='Mailing Address'
                            name='address'
                            setAddressObject={setAddressObject}
                            addressObject={addressObject}
                            inputType='PERSONAL'
                            className={`${errors.address && 'error-field'} ${
                              Object.keys(addressObject).length > 0 && 'filled'
                            }`}
                            setIsManual={setIsManual}
                          ></GoogleSearchInput>
                        </div>
                        <div className='fields'>
                          <InputField
                            name='apartment'
                            type='text'
                            placeholder='Apt.'
                            register={register}
                            className={`${errors.apartment && 'error-field'} ${
                              watchAllFields.apartment && 'filled'
                            }`}
                          />
                        </div>
                      </div>
                    ) : (
                      <>
                        <h2 className='business-info'>Mailing address</h2>
                        <div className=' d-sm-flex justify-content-between'>
                          <div className='fields'>
                            <InputField
                              name='address'
                              type='text'
                              placeholder='Address'
                              register={register}
                              className={`${errors.address && 'error-field'} ${
                                watchAllFields.address && 'filled'
                              }`}
                            />
                          </div>
                          <div className='fields'>
                            <InputField
                              name='zipCode'
                              type='number'
                              placeholder='Zipcode'
                              register={register}
                              className={`${errors.zipCode && 'error-field'} ${
                                watchAllFields.zipCode && 'filled'
                              }`}
                            />
                          </div>
                        </div>
                        <div className=' d-sm-flex justify-content-between'>
                          <div className='fields'>
                            <InputField
                              name='city'
                              type='text'
                              placeholder='City'
                              register={register}
                              className={`${errors.city && 'error-field'} ${
                                watchAllFields.city && 'filled'
                              }`}
                            />
                          </div>
                          <div className='fields'>
                            <InputField
                              name='state'
                              type='text'
                              placeholder='State'
                              register={register}
                              className={`${errors.state && 'error-field'} ${
                                watchAllFields.state && 'filled'
                              }`}
                            />
                          </div>
                        </div>
                        <div className=' d-sm-flex justify-content-between'>
                          <div className='fields'>
                            <InputField
                              name='country'
                              type='text'
                              placeholder='County'
                              register={register}
                              className={`${errors.country && 'error-field'} ${
                                watchAllFields.country && 'filled'
                              }`}
                            />
                          </div>
                          <div className='fields'>
                            <InputField
                              name='apartment'
                              type='text'
                              placeholder='Apt'
                              register={register}
                              className={`${
                                errors.apartment && 'error-field'
                              } ${watchAllFields.apartment && 'filled'}`}
                            />
                          </div>
                        </div>
                      </>
                    )
                  ) : null}
                  {contactCustomFields?.length > 0
                    ? contactCustomFields?.map((item: any, index: number) =>
                        index % 2 === 0 ? (
                          <div className='d-sm-flex justify-content-between'>
                            <div className='fields'>
                              <CustomFieldInput
                                item={item}
                                register={register}
                                control={control}
                                value={watchAllFields[item.uuid]}
                              />
                            </div>
                            {contactCustomFields?.length > index + 1 ? (
                              <div className='fields'>
                                <CustomFieldInput
                                  item={contactCustomFields[index + 1]}
                                  register={register}
                                  control={control}
                                  value={
                                    watchAllFields[
                                      contactCustomFields[index + 1].uuid
                                    ]
                                  }
                                />
                              </div>
                            ) : null}
                          </div>
                        ) : null
                      )
                    : null}
                  {!isCommercial && accountCustomFields?.length > 0 ? (
                    <>
                      <h2 className='contact-info'>Account info</h2>

                      {accountCustomFields?.map((item: any, index: number) =>
                        index % 2 === 0 ? (
                          <div className='d-sm-flex justify-content-between'>
                            <div className='fields'>
                              <CustomFieldInput
                                item={item}
                                register={register}
                                control={control}
                                value={watchAllFields[item.uuid]}
                              />
                            </div>
                            {accountCustomFields?.length > index + 1 ? (
                              <div className='fields'>
                                <CustomFieldInput
                                  item={accountCustomFields[index + 1]}
                                  register={register}
                                  control={control}
                                  value={
                                    watchAllFields[
                                      accountCustomFields[index + 1].uuid
                                    ]
                                  }
                                />
                              </div>
                            ) : null}
                          </div>
                        ) : null
                      )}
                    </>
                  ) : null}
                </div>
                {isCommercial ? (
                  <div>
                    <div>
                      <h2 className='business-info'>Business Info</h2>
                    </div>
                    <div className='top-field d-sm-flex justify-content-between'>
                      <div className='fields'>
                        <InputField
                          name='business'
                          type='text'
                          placeholder='Business name'
                          register={register}
                          required
                          className={`${errors.business && 'error-field'} ${
                            watchAllFields.business && 'filled'
                          }`}
                        />
                        {!watchAllFields.business && submit ? (
                          <div className='errorVal'>
                            <img
                              src={warning}
                              alt='warning'
                              className='warning'
                            />
                            Please enter Business Name
                          </div>
                        ) : null}
                      </div>
                      <div className='fields'>
                        <SearchDropdown
                          name='industry'
                          placeholder='Industry (Optional)'
                          isEdit={true}
                          values={industrySearch}
                          searchValue={industry}
                          setSearch={setIndustrySearch}
                          status={loadingStatus}
                          className={`${industrySearch && 'filled'}`}
                          register={register}
                          control={control}
                          loadFunction={loadIndustry}
                          setUUID={setIndustryUuid}
                          content={industry?.map((item: any) => (
                            <div
                              className='searchValuesCard d-flex align-items-center'
                              onClick={() => {
                                setIndustrySearch(
                                  `${item.naicsSixDigitCode} - ${item.naicsSixDigitCodeDescription}`
                                );
                                setIndustryUuid(item?.uuid);
                              }}
                            >
                              <div>
                                <h6>
                                  {item.naicsSixDigitCode} -{' '}
                                  {item.naicsSixDigitCodeDescription}
                                </h6>
                                <p className='description'>
                                  {item.naicsFourDigitCodeDescription}
                                </p>
                              </div>
                            </div>
                          ))}
                        />
                        {industrySearch && !industryUuid && submit ? (
                          <div className='errorVal'>
                            <img
                              src={warning}
                              alt='warning'
                              className='warning'
                            />
                            Industry not found
                          </div>
                        ) : (
                          ''
                        )}
                      </div>
                    </div>
                    <div className='d-sm-flex justify-content-between'>
                      <div className='fields'>
                        <PhoneNumberInput
                          name='businessPhoneNumber'
                          type='tel'
                          placeholder='Business Phone Number'
                          required
                          className={`${
                            (errors.businessPhoneNumber || errorMessage) &&
                            'error-field'
                          } ${watchAllFields.businessPhoneNumber && 'filled'}`}
                          control={control}
                        />
                        {(!watchAllFields.businessPhoneNumber ||
                          !new RegExp(
                            /[(][0-9]{3}[)][-\s][0-9]{3}[-][0-9]{4}$/
                          ).test(watchAllFields.businessPhoneNumber)) &&
                        submit ? (
                          <div className='errorVal'>
                            <img
                              src={warning}
                              alt='warning'
                              className='warning'
                            />
                            Please enter Business Phone Number
                          </div>
                        ) : null}
                      </div>
                      <div className='fields'>
                        <InputField
                          name='businessEmail'
                          type='email'
                          placeholder='Business email'
                          register={register}
                          className={`${
                            errors.businessEmail && 'error-field'
                          } ${watchAllFields.businessEmail && 'filled'}`}
                        />
                      </div>
                    </div>
                    {!isManual ? (
                      <div className='  d-sm-flex justify-content-between'>
                        <div className='fields'>
                          <GoogleSearchInput
                            placeholder='Business address'
                            name='businessaddress'
                            setAddressObject={setAddressObject}
                            addressObject={addressObject}
                            inputType='PERSONAL'
                            className={`${errors.address && 'error-field'} ${
                              Object.keys(addressObject).length > 0 && 'filled'
                            }`}
                            setIsManual={setIsManual}
                          ></GoogleSearchInput>
                        </div>
                        <div className='fields'>
                          <InputField
                            name='businessapt'
                            type='text'
                            placeholder='Apt'
                            register={register}
                            className={`${
                              errors.businessapt && 'error-field'
                            } ${watchAllFields.businessapt && 'filled'}`}
                          />
                        </div>
                      </div>
                    ) : (
                      <>
                        <h2 className='business-info'>Business address</h2>
                        <div className=' d-sm-flex justify-content-between'>
                          <div className='fields'>
                            <InputField
                              name='address'
                              type='text'
                              placeholder='Address'
                              register={register}
                              className={`${errors.address && 'error-field'} ${
                                watchAllFields.address && 'filled'
                              }`}
                            />
                          </div>
                          <div className='fields'>
                            <InputField
                              name='zipCode'
                              type='number'
                              placeholder='Zipcode'
                              register={register}
                              className={`${errors.zipCode && 'error-field'} ${
                                watchAllFields.zipCode && 'filled'
                              }`}
                            />
                          </div>
                        </div>
                        <div className=' d-sm-flex justify-content-between'>
                          <div className='fields'>
                            <InputField
                              name='city'
                              type='text'
                              placeholder='City'
                              register={register}
                              className={`${errors.city && 'error-field'} ${
                                watchAllFields.city && 'filled'
                              }`}
                            />
                          </div>
                          <div className='fields'>
                            <InputField
                              name='state'
                              type='text'
                              placeholder='State'
                              register={register}
                              className={`${errors.state && 'error-field'} ${
                                watchAllFields.state && 'filled'
                              }`}
                            />
                          </div>
                        </div>
                        <div className=' d-sm-flex justify-content-between'>
                          <div className='fields'>
                            <InputField
                              name='country'
                              type='text'
                              placeholder='County'
                              register={register}
                              className={`${errors.country && 'error-field'} ${
                                watchAllFields.country && 'filled'
                              }`}
                            />
                          </div>
                          <div className='fields'>
                            <InputField
                              name='businessapt'
                              type='text'
                              placeholder='Apt'
                              register={register}
                              className={`${
                                errors.businessapt && 'error-field'
                              } ${watchAllFields.businessapt && 'filled'}`}
                            />
                          </div>
                        </div>
                      </>
                    )}

                    <div className='d-sm-flex justify-content-between'>
                      <div className='fields'>
                        <InputField
                          name='employees'
                          type='number'
                          placeholder='Number of employees'
                          register={register}
                          className={`${errors.employees && 'error-field'} ${
                            watchAllFields.employees && 'filled'
                          }`}
                        />
                        {errors.employees ? (
                          <div className='errorVal'>
                            <img
                              src={warning}
                              alt='warning'
                              className='warning'
                            />
                            {errors.employees?.message}
                          </div>
                        ) : null}
                      </div>
                      <div className='fields'>
                        <InputField
                          name='businessStartedYear'
                          type='number'
                          placeholder='Year business started'
                          register={register}
                          className={`${
                            errors.businessStartedYear && 'error-field'
                          } ${watchAllFields.businessStartedYear && 'filled'}`}
                        />
                      </div>
                    </div>
                    <div className='d-sm-flex justify-content-between'>
                      <div className='fields'>
                        <InputField
                          name='payroll'
                          type='number'
                          min={0}
                          placeholder='Total payroll'
                          register={register}
                          prefix='$'
                          required
                          className={`${errors.payroll && 'error-field'} ${
                            watchAllFields.payroll && 'filled'
                          }`}
                        />
                        {!watchAllFields.payroll && submit ? (
                          <div className='errorVal'>
                            <img
                              src={warning}
                              alt='warning'
                              className='warning'
                            />
                            Please enter valid Amount
                          </div>
                        ) : null}
                      </div>
                      <div className='fields'>
                        <InputField
                          name='revenue'
                          type='number'
                          placeholder='Annual revenue'
                          register={register}
                          prefix='$ '
                          className={`${errors.revenue && 'error-field'} ${
                            watchAllFields.revenue && 'filled'
                          }`}
                        />
                      </div>
                    </div>
                    {accountCustomFields?.length > 0
                      ? accountCustomFields?.map((item: any, index: number) =>
                          index % 2 === 0 ? (
                            <div className='d-sm-flex justify-content-between'>
                              <div className='fields'>
                                <CustomFieldInput
                                  item={item}
                                  register={register}
                                  control={control}
                                  value={watchAllFields[item.uuid]}
                                />
                              </div>
                              {accountCustomFields?.length > index + 1 ? (
                                <div className='fields'>
                                  <CustomFieldInput
                                    item={accountCustomFields[index + 1]}
                                    register={register}
                                    control={control}
                                    value={
                                      watchAllFields[
                                        accountCustomFields[index + 1].uuid
                                      ]
                                    }
                                  />
                                </div>
                              ) : null}
                            </div>
                          ) : null
                        )
                      : null}
                  </div>
                ) : null}
                <div>
                  <div>
                    <h2 className='business-info'>Opportunity Info</h2>
                  </div>
                  <div className=' d-sm-flex justify-content-between'>
                    <div className='fields'>
                      <SelectField
                        options={sourceTypes.map((item) => {
                          return {
                            label: item.name,
                            options: item.LeadSources.map((source) => {
                              return {
                                label: source.name,
                                value: source.uuid,
                              };
                            }),
                          };
                        })}
                        name='sourceName'
                        placeholder='Opportunity source'
                        control={control}
                      />
                    </div>

                    {/* <div className='fields'>
                      <SearchDropdown
                        name='account'
                        placeholder='Account associated to'
                        isEdit={true}
                        values={accountSearch}
                        searchValue={accounts}
                        setSearch={setAccountSearch}
                        status={loadingStatus}
                        className={`${accountSearch && 'filled'}`}
                        register={register}
                        control={control}
                        loadFunction={loadAccount}
                        setUUID={setAccount}
                        content={accounts?.map((item) => (
                          <div
                            className='searchValuesCard d-flex align-items-center'
                            onClick={() => {
                              setAccountSearch(item?.name);
                              setAccount(item?.uuid);
                            }}
                          >
                            <div className='profile-pic d-flex align-items-center justify-content-center'>
                              {item?.name?.charAt(0)}
                            </div>
                            <div>
                              <h6>{item.name}</h6>
                              <ul className='user-details'>
                                <li>
                                  {isCommercial
                                    ? item?.businessEmail
                                    : item?.AccountContacts[0]?.Contact?.ContactDetails?.find(
                                        (value: any) => value.type === 'EMAIL'
                                      )?.value}
                                </li>
                                <li>
                                  {isCommercial
                                    ? item?.businessPhoneNumber
                                    : item?.AccountContacts[0]?.Contact?.ContactDetails?.find(
                                        (value: any) =>
                                          value.type === 'PHONENUMBER'
                                      )?.value}
                                </li>
                              </ul>
                            </div>
                          </div>
                        ))}
                      />
                      {accountSearch && !account && submit ? (
                        <div className='errorVal'>
                          <img
                            src={warning}
                            alt='warning'
                            className='warning'
                          />
                          Account not found
                        </div>
                      ) : (
                        ''
                      )}
                    </div> */}
                    <div className='fields'>
                      <SearchDropdown
                        name='productCategory'
                        placeholder='Select Policy'
                        isEdit={true}
                        values={policySearch}
                        searchValue={productCategory}
                        setSearch={setPolicySearch}
                        status={loadingStatus}
                        className={`${!policy && submit && 'error-field'} ${
                          policySearch && 'filled'
                        } text-capitalize`}
                        register={register}
                        addName={true}
                        control={control}
                        loadFunction={loadPolicy}
                        setUUID={setPolicy}
                        required
                        setProductUUID={setProductUUID}
                        content={productCategory?.map((item) => (
                          <div
                            className='searchValuesCard d-flex align-items-center'
                            onClick={() => {
                              setPolicySearch(
                                item?.name.toLowerCase().split('_').join(' ')
                              );
                              setPolicy(item?.uuid);
                              setProductUUID(item?.uuid);
                              setProductType(item?.type);
                            }}
                          >
                            <div>
                              <h6 className='text-capitalize'>
                                {item.name.toLowerCase().split('_').join(' ')}
                              </h6>
                            </div>
                          </div>
                        ))}
                      />
                      {!policy && submit ? (
                        <div className='errorVal'>
                          <img
                            src={warning}
                            alt='warning'
                            className='warning'
                          />
                          Policy type required
                        </div>
                      ) : null}
                    </div>
                  </div>
                  <div className=' d-sm-flex justify-content-between'>
                    <div className='fields'>
                      <SelectField
                        options={pipelines.map((item) => {
                          return { label: item.name, value: item.uuid };
                        })}
                        name='pipeline'
                        placeholder='Pipeline'
                        control={control}
                        isEdit={true}
                        required
                        noOptionsMessage={() => 'Please select a policy first'}
                        className={`${errors.pipeline && 'error-field'} ${
                          watchAllFields.pipeline && 'filled'
                        }`}
                      />
                      {errors.pipeline ? (
                        <div className='errorVal'>
                          <img
                            src={warning}
                            alt='warning'
                            className='warning'
                          />
                          {errors.pipeline?.message
                            ? 'Please select a Pipeline'
                            : errors.pipeline?.value?.message}
                        </div>
                      ) : null}
                    </div>
                    <div className='fields'>
                      {pipelines && (
                        <SelectField
                          options={onSetPipelineValue(
                            pipelines.find(
                              (item) =>
                                item.uuid === watchAllFields.pipeline?.value
                            )
                          )}
                          name='pipelineStage'
                          placeholder='Pipeline stage'
                          required
                          control={control}
                          noOptionsMessage={() =>
                            'Please select a pipeline first'
                          }
                          className={`${
                            errors.pipelineStage && 'error-field'
                          } ${watchAllFields.pipelineStage && 'filled'}`}
                        />
                      )}
                      {errors.pipelineStage ? (
                        <div className='errorVal'>
                          <img
                            src={warning}
                            alt='warning'
                            className='warning'
                          />
                          {errors.pipelineStage?.message
                            ? 'Please select a Stage'
                            : errors.pipelineStage?.value?.message}
                        </div>
                      ) : null}
                    </div>
                  </div>
                  <div className='  d-sm-flex justify-content-between'>
                    <div className='fields'>
                      <SearchDropdown
                        name='producer'
                        placeholder='Select producer'
                        isEdit={true}
                        addName={true}
                        values={producerSearch}
                        searchValue={producers}
                        setSearch={setProducerSearch}
                        status={loadingStatus}
                        className={`${producerSearch && 'filled'}`}
                        register={register}
                        control={control}
                        loadFunction={loadProducer}
                        setUUID={setProducer}
                        disabled={user?.UserRole?.name === 'AGENT'}
                        content={producers?.map((item) => (
                          <div
                            className='searchValuesCard d-flex align-items-center'
                            onClick={() => {
                              setProducerSearch(
                                `${item.User.firstName} ${
                                  item.User.lastName ? item.User.lastName : ''
                                }`
                              );
                              setProducer(item?.uuid);
                            }}
                          >
                            <div className='profile-pic d-flex align-items-center justify-content-center'>
                              {item?.User?.firstName?.charAt(0)}
                              {item?.User?.lastName?.charAt(0)}
                            </div>
                            <div>
                              <h6>
                                {`${item.User.firstName} ${
                                  item.User.lastName ? item.User.lastName : ''
                                }`}
                              </h6>
                              <ul className='user-details'>
                                <li>{item.User?.email}</li>
                                <li>{item?.User?.phoneNumber}</li>
                              </ul>
                            </div>
                          </div>
                        ))}
                      />
                      {producerSearch &&
                      producerSearch !== 'Me' &&
                      !producer &&
                      submit ? (
                        <div className='errorVal'>
                          <img
                            src={warning}
                            alt='warning'
                            className='warning'
                          />
                          Producer not found
                        </div>
                      ) : (
                        ''
                      )}
                    </div>
                    <div className='fields'>
                      <InputField
                        name='opportunityName'
                        type='text'
                        placeholder='Opportunity name'
                        register={register}
                        required={true}
                        className={`${
                          errors.opportunityName && 'error-field'
                        } ${watchAllFields.opportunityName && 'filled'}`}
                      />
                      {errors.opportunityName ? (
                        <div className='errorVal'>
                          <img
                            src={warning}
                            alt='warning'
                            className='warning'
                          />
                          {errors.opportunityName?.message}
                        </div>
                      ) : null}
                    </div>
                  </div>
                  <div className='  d-sm-flex justify-content-between'>
                    <div className='fields'>
                      <InputField
                        name='premium'
                        type='number'
                        placeholder='Opportunity value'
                        register={register}
                        prefix='$'
                        className={`${errors.premium && 'error-field'} ${
                          watchAllFields.premium && 'filled'
                        }`}
                      />
                    </div>
                    <div className='fields'>
                      <DateField
                        name='effectiveDate'
                        placeholder='Effective date'
                        control={control}
                        value={watchAllFields.effectiveDate}
                      />
                    </div>
                  </div>
                  <div className='  d-sm-flex justify-content-between'>
                    <div className='fields'>
                      <DateField
                        name='expectedCloseDate'
                        placeholder='Estimated close date'
                        control={control}
                        value={watchAllFields.expectedCloseDate}
                      />
                    </div>
                  </div>

                  {opportunityCustomFields?.length > 0
                    ? opportunityCustomFields?.map((item: any, index: number) =>
                        index % 2 === 0 ? (
                          <div className='d-sm-flex justify-content-between'>
                            <div className='fields'>
                              <CustomFieldInput
                                item={item}
                                register={register}
                                control={control}
                                value={watchAllFields[item.uuid]}
                              />
                            </div>
                            {opportunityCustomFields?.length > index + 1 ? (
                              <div className='fields'>
                                <CustomFieldInput
                                  item={opportunityCustomFields[index + 1]}
                                  register={register}
                                  control={control}
                                  value={
                                    watchAllFields[
                                      opportunityCustomFields[index + 1].uuid
                                    ]
                                  }
                                />
                              </div>
                            ) : null}
                          </div>
                        ) : null
                      )
                    : null}
                  <div>
                    {notes &&
                      notes?.map((item: any, index: number) => {
                        return (
                          <div className='d-flex align-items-center descriptionField  col'>
                            <div className='textarea'>
                              <MarkdownEditor
                                placeholder='Add notes here'
                                note={item}
                                multi={true}
                                index={index}
                                setNote={onSetNotesValue}
                                textCheckFunction={setTextCheck}
                              />
                            </div>
                          </div>
                        );
                      })}
                  </div>
                  {noteError ? (
                    <div className='errorVal'>
                      <img src={warning} alt='warning' className='warning' />
                      {noteError}
                    </div>
                  ) : null}
                </div>
              </div>
              <div className='buttonsDiv d-flex align-items-center '>
                <button
                  onClick={() => {
                    reset();
                    onCloseModal();
                  }}
                  className='cancel-button'
                  type='button'
                >
                  Cancel
                </button>
                <button
                  type='submit'
                  className='saveButton'
                  onClick={() => {
                    setSubmit(true);
                  }}
                  disabled={loading || show === 'closing' || disabled}
                >
                  Save <img className='tick' src={tick} alt='tick-sign' />
                </button>
              </div>
            </form>
          </div>
        </FormModal>
      </StyledOpportunityModal>
    </>
  );
};

export default AddOpportunityDrawer;
