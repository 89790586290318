import { useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import vector from '../../../../assets/logo/dropdown.svg';
import { useDispatch } from 'react-redux';
import { AppDispatch } from '../../../../store';
import { CarriersMobileCard } from '../../Carrier/styles';
import { useOutsideClick } from '../../../../hooks/useOutsideClick';
import { onDeleteCustomFields } from '../../../../store/customFields/customFieldsSlice';
import bin from '../../../../assets/logo/bin.svg';
import { CustomMobileCard } from '../styles';
import move from '../../../../assets/logo/move.svg';
import DeleteModal from '../../../../components/DeleteModal';
import ErrorCard from '../../../Pipeline/components/ErrorCard';
const MobileCard = ({
  customField,
  setShowEditModal,
  setData,
}: {
  customField: any;
  setShowEditModal: Function;
  setData: Function;
}) => {
  const { uuid } = useParams();
  const dispatch = useDispatch<AppDispatch>();
  const [showError, setShowError] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState('closed');
  const [UUID, setUUID] = useState('');
  const [name, setName] = useState('');

  const setDelete = (uuid: string, name: string) => {
    setUUID(uuid);
    setName(name);
    setShowDeleteModal('open');
  };
  const onDelete = (itemuuid: string) => {
    dispatch(
      onDeleteCustomFields({
        uuid: itemuuid,
        data: { agencyUUID: uuid || '' },
        successCB: () => {
          setShowDeleteModal('closing');
          setTimeout(() => {
            setShowError(true);
          }, 100);
          setTimeout(() => {
            setShowDeleteModal('closed');
            setShowError(false);
          }, 2000);
        },
      })
    );
  };

  return (
    <CustomMobileCard>
      {showError && (
        <ErrorCard message={`<b>${name}</b> is <b> deleted</b> `} show='open' />
      )}
      <CarriersMobileCard className='mobile-card-customField'>
        <div className='d-flex align-items-center'>
          <div className='drag-icon'>
            <img src={move} alt='' />
          </div>
          <span className='CarrierName d-flex'>
            {customField.name ? customField.name : '-'}
          </span>
        </div>
        <div className='d-flex align-items-center text-capitalize'>
          {customField.FieldType?.name?.split('_').join(' ').toLowerCase()}
        </div>
        {customField.FieldType?.name === 'MULTIPLE_CHOICE' ||
        customField.FieldType?.name === 'SINGLE_CHOICE' ? (
          <div className='d-block'>
            <div className='stateandnames '>
              Choices :{' '}
              {customField.data?.options?.map(
                (item: any, index: number) =>
                  `${item?.value !== undefined ? item?.value : ''}${
                    index !== customField.data?.options.length - 1 ? ', ' : ''
                  }`
              )}
            </div>
          </div>
        ) : null}

        <div className='d-flex justify-content-between buttonsDiv align-items-center'>
          <div className='appear-form-container'>
            Appear in form:
            <div className='text-capitalize appear-choice'>
              {customField.addToForm ? 'yes' : 'no'}
            </div>
          </div>
          <div className='d-flex'>
            <button
              className='editButton'
              onClick={() => {
                setShowEditModal('open');
                setData(customField);
              }}
            >
              Edit
            </button>
            <div
              className='d-flex align-items-center justify-content-end h-100 actionsButton'
              style={{ background: 'none' }}
            >
              <div className='d-flex align-items-center  h-100'>
                <ActionsDropdown
                  uuid={customField.uuid}
                  onDelete={setDelete}
                  name={customField.name}
                />
              </div>
            </div>
          </div>
        </div>
      </CarriersMobileCard>
      {showDeleteModal !== 'closed' && (
        <DeleteModal
          showModal={showDeleteModal}
          closeModal={setShowDeleteModal}
          deleteFunction={onDelete}
          uuid={UUID}
          loading='succeeded'
          resource='custom field'
          name={name}
        />
      )}
    </CustomMobileCard>
  );
};

export default MobileCard;

const ActionsDropdown = ({
  uuid,
  onDelete,
  name,
}: {
  uuid: string;
  onDelete: Function;
  name: string;
}) => {
  const [showActionsDropdown, setShowActionsDropdown] = useState(false);
  const dropRef = useRef(null);
  useOutsideClick(dropRef, setShowActionsDropdown);

  return (
    <button
      className='vector d-flex align-items-center justify-content-center position-relative'
      onClick={() => setShowActionsDropdown(!showActionsDropdown)}
      ref={dropRef}
    >
      <img src={vector} alt='vector' />
      {showActionsDropdown && (
        <div className='dropdown-div'>
          <div className='delete-div' onClick={() => onDelete(uuid, name)}>
            Delete <img src={bin} alt='bin' />
          </div>
        </div>
      )}
    </button>
  );
};
