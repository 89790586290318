import { Navigate, Route, Routes } from 'react-router-dom';
import ErrorContainer from './components/ErrorContainer';
import { ErrorDiv } from './styles';
import clouds from '../../assets/illustrations/clouds.svg';
import error403 from '../../assets/illustrations/error403.svg';
import error404 from '../../assets/illustrations/error404.svg';
import error504 from '../../assets/illustrations/error504.svg';
import error500 from '../../assets/illustrations/error500.svg';
import error502 from '../../assets/illustrations/error502.svg';
import error503 from '../../assets/illustrations/error503.svg';
import expired from '../../assets/illustrations/expired.svg';

const ErrorPage = () => {
  return (
    <ErrorDiv>
      <Routes>
        <Route
          path='/401'
          element={
            <ErrorContainer
              code='401'
              description='Sorry! Security is important to us. Please log in to your account to view this page.'
              title='Access denied!'
              navigateTo='home'
              illustration={clouds}
            />
          }
        />
        <Route
          path='/403'
          element={
            <ErrorContainer
              code='403'
              description='Sorry! Security is important to us. You don’t have access to this page.'
              title='Access forbidden!'
              navigateTo='home'
              illustration={error403}
            />
          }
        />
        <Route
          path='/404'
          element={
            <ErrorContainer
              code='404'
              description='Sorry! It seems this page can’t be found. Please check the URL.  If that doesn’t work, the home button will help navigate where you need to go.'
              title='Page not found!'
              navigateTo='home'
              illustration={error404}
            />
          }
        />
        <Route
          path='/expired'
          element={
            <ErrorContainer
              description='Sorry! Security is important to us. Your session has expired. Please login again.'
              title='Session timeout!'
              navigateTo='home'
              illustration={error504}
            />
          }
        />
        <Route
          path='/link-expired'
          element={
            <ErrorContainer
              description='Sorry! It seems like your verification link has been expired.'
              // TODO : Change to below description after resend functionality is implemented.
              // description='Sorry! It seems like your verification link has been expired. Please try resending the verification link.'
              title='Verification link has expired!'
              navigateTo='reload'
              illustration={expired}
            />
          }
        />
        <Route
          path='/500'
          element={
            <ErrorContainer
              code='500'
              description='Sorry! It seems there’s an internal server error.  Please know that our IT department is working to fix this. Check back soon!'
              title='Internal server error!'
              navigateTo='reload'
              illustration={error500}
            />
          }
        />
        <Route
          path='/502'
          element={
            <ErrorContainer
              code='502'
              description='Sorry! It seems there’s a Bad Gateway. Please try refreshing the page. If that doesn’t work, please check back shortly!'
              title='Bad gateway!'
              navigateTo='reload'
              illustration={error502}
            />
          }
        />
        <Route
          path='/503'
          element={
            <ErrorContainer
              code='503'
              description='Sorry! Service is currently unavailable. We’re either doing maintenance or we are over capacity. Please check back shortly!'
              title='Service unavailable!'
              navigateTo='reload'
              illustration={error503}
            />
          }
        />
        <Route
          path='/504'
          element={
            <ErrorContainer
              code='504'
              description='Sorry! It seems this page is taking too long to load. Please try refreshing the page and contact us if the problem persists.'
              title='Gateway timeout!'
              navigateTo='home'
              illustration={error504}
            />
          }
        />
        <Route path='*' element={<Navigate to='/error/404' replace />} />
      </Routes>
    </ErrorDiv>
  );
};

export default ErrorPage;
