import { useNavigate } from 'react-router-dom';
import moment from 'moment';
import LocalStorageService from '../../../utils/LocalStorageService';
import { IOpportunity } from '../../../interfaces/Opportunity';
import edit from '../../../assets/logo/edit.svg';
import contact from '../../../assets/logo/contact.png';
import plus from '../../../assets/logo/blackplus.svg';
import chain from '../../../assets/logo/chain.svg';

const OpportunityDetailsDrawer = ({
  opportunityDetails,
  setShowEditModal,
  setShowLinkContactModal,
  setShowAddContactModal,
}: {
  opportunityDetails: IOpportunity;
  setShowEditModal: Function;
  setShowLinkContactModal: Function;
  setShowAddContactModal: Function;
}) => {
  const navigate = useNavigate();

  const user = LocalStorageService.getUser();

  return (
    <div className='info-div'>
      <div className='details-div'>
        <div className='d-flex align-items-center'>
          <h5 className='section-title'>Details</h5>
          <img
            className='editIcon'
            src={edit}
            alt='edit'
            onClick={() => setShowEditModal('open')}
          />
        </div>
        <div className='sub-section'>
          <div className='subsection-title'>Associated Account</div>
          <div className='text-overflow-container'>
            <div className='name-text-overflow'>
              {opportunityDetails.Account?.name}
            </div>
            <span className='tooltiptext tooltip-producer'>
              <span className='tooltipInnertext'>
                {opportunityDetails.Account?.name}
              </span>
            </span>
          </div>
          <div className='subsection-title'>Opportunity source</div>
          <div>
            {opportunityDetails?.sourceName
              ? opportunityDetails?.sourceName
              : '--'}
          </div>
          <div className='subsection-title'>Policy type</div>
          <div>
            {opportunityDetails?.Product
              ? opportunityDetails?.Product?.name
              : opportunityDetails?.CustomProduct
              ? opportunityDetails?.CustomProduct?.name
              : '--'}
          </div>
          <div className='subsection-title'>Pipeline stage</div>
          <div>{opportunityDetails?.PipelineStage?.name}</div>
          <div className='subsection-title'>Pipeline</div>
          <div>{opportunityDetails?.PipelineStage?.Pipeline?.name}</div>
          <div className='subsection-title'>Effective Date</div>
          <div>
            {opportunityDetails.effectiveDate
              ? moment(opportunityDetails.effectiveDate).format('MM/DD/YYYY')
              : '--'}
          </div>
          <div className='subsection-title'>Expected Closing Date</div>
          <div>
            {opportunityDetails.expectedCloseDate
              ? moment(opportunityDetails.expectedCloseDate).format(
                  'MM/DD/YYYY'
                )
              : '--'}
          </div>
          <div className='subsection-title'>
            {opportunityDetails?.Account?.AccountType?.name === 'PERSONAL'
              ? 'Home'
              : 'Business'}{' '}
            Address
          </div>
          {opportunityDetails.Account?.Address?.isManuallyAdded ? (
            <>
              {opportunityDetails?.Account.Address?.street
                ? `${opportunityDetails?.Account.Address?.street}, `
                : ''}
              {opportunityDetails?.Account.Address?.name
                ? `${opportunityDetails?.Account.Address?.name}, `
                : ''}
              {opportunityDetails?.Account.Address?.city
                ? `${opportunityDetails?.Account.Address?.city}, `
                : ''}{' '}
              {opportunityDetails?.Account.Address?.state
                ? `${opportunityDetails?.Account.Address?.state}, `
                : ''}{' '}
              {/* {opportunityDetails?.Account.Address?.country
                ? `${opportunityDetails.Account?.Address?.country}, `
                : ''}{' '} */}
              {opportunityDetails.Account?.Address?.zipCode}
            </>
          ) : !opportunityDetails.Account?.Address?.isManuallyAdded ? (
            <div>
              {opportunityDetails?.Account.Address?.name
                ? `${opportunityDetails?.Account.Address?.name}, `
                : '--'}
              {opportunityDetails.Account?.Address?.zipCode}
            </div>
          ) : (
            <div>Address not specified</div>
          )}
        </div>
      </div>
      <div className='contacts-div'>
        <h5 className='section-title d-flex align-items-center'>
          Contacts
          {opportunityDetails.Account?.AccountContacts?.length > 0 && (
            <span className='count-div'>
              {opportunityDetails?.Account.AccountContacts.length}
            </span>
          )}
          <button
            className='plus-button'
            type='button'
            onClick={() => setShowAddContactModal('open')}
          >
            <img src={plus} alt='plus-icon' />
          </button>
        </h5>
        <div className='sub-section'>
          {opportunityDetails.OpportunityAccountContacts?.map((item) => (
            <div className='text-overflow-container'>
              <div
                key={item?.uuid}
                className={`nav-decor ${
                  user.Agent.id !== opportunityDetails?.Account.AgentId &&
                  'disabled-decor'
                }`}
                onClick={() =>
                  navigate(`/contacts/${item?.AccountContact?.Contact?.uuid}`)
                }
              >
                <img src={contact} alt='' className='contact-img' />
                {item?.AccountContact?.Contact.firstName}{' '}
                {item?.AccountContact?.Contact.lastName}
              </div>
              <span className='tooltiptext'>
                <span className='tooltipInnertext'>
                  {opportunityDetails?.Account?.AccountContacts &&
                    opportunityDetails?.Account?.AccountContacts[0]?.Contact
                      .firstName}{' '}
                  {opportunityDetails?.Account?.AccountContacts &&
                    opportunityDetails?.Account?.AccountContacts[0]?.Contact
                      .lastName}
                </span>
              </span>
            </div>
          ))}
          <span
            className='LinkContactDiv'
            onClick={() => setShowLinkContactModal('open')}
          >
            <img className='iconImage' src={chain} alt='' /> Link contact
          </span>
        </div>
      </div>
      <div className='contacts-div'>
        <h5 className='section-title'>Team</h5>
        <div className='sub-section'>
          <div>
            <img src={contact} alt='' className='contact-img' />
            Producer: {opportunityDetails?.Agent?.User?.firstName}{' '}
            {opportunityDetails?.Agent?.User?.lastName}
          </div>
        </div>
      </div>
    </div>
  );
};

export default OpportunityDetailsDrawer;
