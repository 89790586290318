import { useState } from 'react';
import { useSelector } from 'react-redux';
import Skeleton from 'react-loading-skeleton';
import Select from 'react-select';
import { ProgressBar } from 'react-bootstrap';
import { CircularProgressbarWithChildren } from 'react-circular-progressbar';
import { DashboardSliceState } from '../../../store/dashboard/dashboardSlice';
import { Goal } from '../style';
import goalIcon from '../../../assets/logo/goal.svg';
import premium from '../../../assets/logo/premium.svg';
import progressIcon from '../../../assets/logo/progress.png';

const Goals = ({
  options,
  handleSelectGoal,
  customSelectStyles,
  isTab,
  percentage,
  handleGoalType,
  selectedGoalType,
  goal,
}: {
  options: any;
  handleSelectGoal: Function;
  customSelectStyles: any;
  isTab: boolean;
  handleGoalType: Function;
  percentage: number;
  selectedGoalType?: string;
  goal: any;
}) => {
  const loadingStatus = useSelector(
    (state: { dashboard: DashboardSliceState }) => state.dashboard.status
  );
  const goalType = [
    { label: 'Premium', value: 'PREMIUM' },
    { label: 'Policy', value: 'POLICY' },
  ];

  const [type, setType] = useState({ label: 'Premium', value: 'PREMIUM' });
  return (
    <div className='goal-div'>
      <div className='d-flex justify-content-between head-div'>
        <h4>Goal</h4>
        <Select
          className='dashboard-select'
          defaultValue={options[0]}
          options={options}
          onChange={(e: any) => handleSelectGoal(e)}
          styles={customSelectStyles}
          isSearchable={false}
        />
      </div>
      {loadingStatus === 'loading' ? (
        <Skeleton height={isTab ? 50 : 150} />
      ) : (
        loadingStatus === 'succeeded' && (
          <div
            className={`premium-value-container ${
              isTab ? 'd-flex tab-view align-items-center' : null
            }`}
          >
            <h5 className='goal-head'>
              <Select
                className='dashboard-select goalType'
                value={type}
                options={goalType}
                onChange={(e: any) => {
                  setType(e);
                  handleGoalType(e);
                }}
                styles={customSelectStyles}
                isSearchable={false}
              />
            </h5>
            <h6 className='goal-stat'>
              {type.value === 'PREMIUM' && '$'}
              {goal?.policyValue}
              <img src={progressIcon} alt='' />
            </h6>
          </div>
        )
      )}
      <Goal className='circle-wrap'>
        {!isTab ? (
          loadingStatus === 'loading' ? (
            <Skeleton
              height={200}
              width={200}
              style={{ borderRadius: '100px' }}
            />
          ) : (
            loadingStatus === 'succeeded' && (
              <CircularProgressbarWithChildren value={percentage}>
                <div className='goal-percentageText'>
                  <strong>{percentage.toFixed(2)}%</strong>
                  <span className='achieved'>Achieved</span>
                </div>
              </CircularProgressbarWithChildren>
            )
          )
        ) : loadingStatus === 'loading' ? (
          <Skeleton height={24} />
        ) : (
          loadingStatus === 'succeeded' && (
            <>
              <ProgressBar
                className='goal-progress-bar'
                now={percentage}
                label={`${percentage}%`}
                visuallyHidden
              />
              <label>
                <strong>{percentage.toFixed(2)}%</strong> Achieved
              </label>
            </>
          )
        )}
      </Goal>
      {loadingStatus === 'loading' ? (
        <>
          <Skeleton height={56} />
          <Skeleton height={56} />
        </>
      ) : (
        loadingStatus === 'succeeded' && (
          <div>
            <div className='goal-values'>
              <div className='goal-text'>
                <div className='goal-icon'>
                  <img src={premium} alt='' />
                </div>
                Still needed
              </div>
              <span className='font-weight-bold'>
                {' '}
                {selectedGoalType === 'PREMIUM' ? '$' : null}
                {goal?.goalValue
                  ? (goal?.goalValue - goal?.policyValue).toFixed(2)
                  : 0}
              </span>
            </div>
            <div className='goal-values'>
              <div className='goal-text'>
                <div className='goal-icon'>
                  <img src={goalIcon} alt='' />
                </div>
                Goal this month
              </div>
              <span className='font-weight-bold'>
                {' '}
                {selectedGoalType === 'PREMIUM' ? '$' : null}
                {goal?.goalValue}
              </span>
            </div>
          </div>
        )
      )}
    </div>
  );
};

export default Goals;
