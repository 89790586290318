import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch } from '../../store';
import {
  AccountsSliceState,
  clearAccountPolicies,
  getAccountPolicies,
  selectAccount,
  selectAccountPolicies,
  selectCount,
  updateAccountPoliciesList,
} from '../../store/accounts/accountsSlice';
import {
  selectContact,
  getContactPolicies,
  selectContactPolicies,
  clearContactPolicies,
  ContactsSliceState,
  selectContactCount,
  updateContactPoliciesList,
} from '../../store/contacts/contactsSlice';
import { InnerFieldStyle } from '../../styles/InnerFieldStyles';
import empty from '../../assets/logo/empty.svg';
import plus from '../../assets/logo/blackplus.svg';
import { FilterContainer } from '../../pages/MyBook/elements/styles';
import PolicyFilter from '../../pages/MyBook/Policies/components/FilterDrawer';
import { IOption } from '../../interfaces/Opportunity';
import PolicyModal from '../../pages/MyBook/Policies/components/AddPolicyModal';
import whitePlus from '../../assets/logo/plus.svg';
import DeleteModal from '../DeleteModal';
import ErrorCard from '../../pages/Pipeline/components/ErrorCard';
import {
  onDeletePolicy,
  PoliciesSliceState,
} from '../../store/policies/policiesSlice';
import Skeleton from 'react-loading-skeleton';
import DetailsInnerCard from './components/DetailsInnerCard';
import DetailsInnerMobileCard from './components/DetailsInnerMobileCard';

const Policies = ({
  name,
  isMobile,
  getCount,
}: {
  name: string;
  isMobile?: boolean;
  getCount: Function;
}) => {
  const { uuid } = useParams();

  const dispatch = useDispatch<AppDispatch>();
  const [quickAddPolicy, setQuickAddPolicy] = useState('closed');
  const accountPolicies = useSelector(selectAccountPolicies);
  const contactPolicies = useSelector(selectContactPolicies);
  const contactDetailsCount = useSelector(selectContactCount);
  const accountDetailsCount = useSelector(selectCount);
  const accountDetails = useSelector(selectAccount);
  const contactDetails = useSelector(selectContact);
  const loadingStatus = useSelector(
    (state: { accounts: AccountsSliceState }) => state.accounts.policiesStatus
  );
  const loadingContactStatus = useSelector(
    (state: { contacts: ContactsSliceState }) => state.contacts.policyStatus
  );
  useEffect(() => {
    if (!isMobile) {
      dispatch(clearAccountPolicies());
      dispatch(clearContactPolicies());
      if (uuid && name === 'Account')
        dispatch(
          getAccountPolicies({
            uuid: uuid,
            offset: 0,
          })
        );
      else if (uuid && name === 'Contact') {
        dispatch(getContactPolicies({ uuid, offset: 0 }));
      }
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [uuid]);

  const scrollAccount = (event: any) => {
    const { scrollTop, clientHeight, scrollHeight } = event.currentTarget;
    if (
      Math.round(scrollHeight - scrollTop) <= Math.round(clientHeight) &&
      loadingStatus !== 'loading' &&
      uuid
    ) {
      if (accountDetailsCount.policies !== accountPolicies.length) {
        dispatch(
          getAccountPolicies({
            uuid,
            offset: accountPolicies.length,
          })
        );
      }
    }
  };

  const scrollContact = (event: any) => {
    const { scrollTop, clientHeight, scrollHeight } = event.currentTarget;
    if (
      Math.round(scrollHeight - scrollTop) <= Math.round(clientHeight) &&
      loadingContactStatus !== 'loading' &&
      uuid
    ) {
      if (contactDetailsCount.policies !== contactPolicies.length) {
        dispatch(
          getContactPolicies({
            uuid,
            offset: contactPolicies.length,
          })
        );
      }
    }
  };

  const [showFilter, setShowFilter] = useState('closed');
  const [producer, setProducer] = useState('');
  const [producerSearch, setProducerSearch] = useState('');
  const [selectedPolicySoldThrough, setSelectedPolicySoldThrough] =
    useState<any>([]);
  const [carrier, setCarrier] = useState('');
  const [carrierSearchText, setCarrierSearchText] = useState('');
  const [accountSearch, setAccountSearch] = useState('');
  const [selectedProducts, setProducts] = useState<IOption[]>([]);
  const [carrierType, setCarrierType] = useState('');

  const filterPolicy = () => {
    dispatch(clearAccountPolicies());
    if (uuid) {
      dispatch(
        getAccountPolicies({
          uuid: uuid,
          offset: 0,
          producerUUID: producer,
          productCategoryUUID: '',
          hasMGA: selectedPolicySoldThrough?.value
            ? selectedPolicySoldThrough?.value === 'MGA'
              ? true
              : false
            : undefined,
          carrierUUID: carrierType === 'CARRIER' ? carrier : '',
          customCarrierUUID: carrierType === 'CUSTOM_CARRIER' ? carrier : '',
          productUUID: selectedProducts
            ?.filter((item: any) => item.type === 'PRODUCT')
            .map((item: any) => item.value),
          customProductUUID: selectedProducts
            ?.filter((item: any) => item.type === 'CUSTOM_PRODUCT')
            .map((item: any) => item.value),
        })
      );
    }
  };
  const [showDeleteModal, setShowDeleteModal] = useState('closed');
  const [showSuccess, setShowSuccess] = useState(false);
  const [detailName, setDetailName] = useState('');
  const [UUID, setUUID] = useState('');
  const loadingStatuspolicy = useSelector(
    (state: { policies: PoliciesSliceState }) => state.policies?.status
  );
  const setDelete = (uuid: string, name: string) => {
    setUUID(uuid);
    setDetailName(name);
  };
  const onDelete = () => {
    dispatch(clearAccountPolicies());
    dispatch(
      onDeletePolicy({
        uuid: UUID || '',
        successCB: () => {
          getCount();
          filterPolicy();
          if (name === 'Contact') {
            dispatch(updateContactPoliciesList({ uuid: UUID }));
          }
          if (name === 'Account') {
            dispatch(updateAccountPoliciesList({ uuid: UUID }));
          }
          setShowDeleteModal('closing');
          setTimeout(() => {
            setShowSuccess(true);
          }, 100);
          setTimeout(() => {
            setShowDeleteModal('closed');
            setShowSuccess(false);
          }, 2000);
        },
      })
    );
  };

  return (
    <InnerFieldStyle>
      {showSuccess && (
        <ErrorCard
          message={`<b>${detailName}</b> is <b> deleted</b> `}
          show='open'
        />
      )}
      <div className='taskDiv'>
        <div className='taskHead d-flex justify-content-between'>
          <button
            className='tertiaryButton d-none d-sm-block'
            type='button'
            onClick={() => setQuickAddPolicy('open')}
          >
            Add policy <img className='addIcon' src={plus} alt='' />
          </button>
          <button
            className='tertiaryButton filterbutton'
            onClick={() => setShowFilter('open')}
          >
            Filter by:{' '}
            <b>
              {(producer ? 1 : 0) +
                (selectedPolicySoldThrough?.value ? 1 : 0) +
                selectedProducts.length +
                (carrier ? 1 : 0) ===
              0
                ? 'No'
                : (producer ? 1 : 0) +
                  (selectedPolicySoldThrough?.value ? 1 : 0) +
                  selectedProducts.length +
                  (carrier ? 1 : 0)}{' '}
              filter
            </b>
          </button>
        </div>
        <div
          className='opportunityDiv d-none d-sm-block'
          onScroll={name === 'Contact' ? scrollContact : scrollAccount}
        >
          {name === 'Contact' && contactPolicies.length > 0
            ? contactPolicies?.map((item: any, index: number) => (
                <DetailsInnerCard
                  name={'Policies'}
                  details={item}
                  key={index}
                  onDelete={setDelete}
                  loadingStatus={loadingStatus}
                  showDeleteModal={showDeleteModal}
                  setShowDeleteModal={setShowDeleteModal}
                />
              ))
            : name === 'Contact' &&
              loadingContactStatus !== 'loading' && (
                <>
                  <div className='d-flex justify-content-center'>
                    <img src={empty} alt='' />
                  </div>
                  <div className='d-flex justify-content-center'>
                    <span>No Record Found</span>
                  </div>
                </>
              )}
          {name === 'Contact' && loadingContactStatus === 'loading'
            ? [1, 2].map((item) => <Skeleton height={200} key={item} />)
            : null}
          {name === 'Account' && accountPolicies.length > 0
            ? accountPolicies?.map((item: any, index: number) => (
                <DetailsInnerCard
                  name={'Policies'}
                  details={item}
                  key={index}
                  onDelete={setDelete}
                  loadingStatus={loadingStatus}
                  showDeleteModal={showDeleteModal}
                  setShowDeleteModal={setShowDeleteModal}
                />
              ))
            : name === 'Account' &&
              loadingStatus !== 'loading' && (
                <>
                  <div className='d-flex justify-content-center'>
                    <img src={empty} alt='' />
                  </div>
                  <div className='d-flex justify-content-center'>
                    <span>No Record Found</span>
                  </div>
                </>
              )}
          {name === 'Account' && loadingStatus === 'loading'
            ? [1, 2].map((item) => <Skeleton height={200} key={item} />)
            : null}
        </div>
        <div
          className='opportunityDiv d-sm-none'
          onScroll={name === 'Contact' ? scrollContact : scrollAccount}
        >
          {name === 'Account' && accountPolicies.length > 0
            ? accountPolicies?.map((item: any, index: number) => (
                <DetailsInnerMobileCard
                  name={'Policies'}
                  details={item}
                  key={index}
                  onDelete={setDelete}
                  loadingStatus={loadingStatus}
                  showDeleteModal={showDeleteModal}
                  setShowDeleteModal={setShowDeleteModal}
                />
              ))
            : name === 'Account' &&
              loadingStatus === 'loading' && (
                <>
                  <div className='d-flex justify-content-center'>
                    <img src={empty} alt='' />
                  </div>
                  <div className='d-flex justify-content-center'>
                    <span>No Record Found</span>
                  </div>
                </>
              )}
          {name === 'Account' && loadingStatus === 'loading'
            ? [1, 2].map((item) => <Skeleton height={200} key={item} />)
            : null}
          {name === 'Contact' && contactPolicies.length > 0
            ? contactPolicies?.map((item: any, index: number) => (
                <DetailsInnerMobileCard
                  name={'Policies'}
                  details={item}
                  key={index}
                  onDelete={setDelete}
                  loadingStatus={loadingStatus}
                  showDeleteModal={showDeleteModal}
                  setShowDeleteModal={setShowDeleteModal}
                />
              ))
            : name === 'Contact' &&
              loadingContactStatus !== 'loading' && (
                <>
                  <div className='d-flex justify-content-center'>
                    <img src={empty} alt='' />
                  </div>
                  <div className='d-flex justify-content-center'>
                    <span>No Record Found</span>
                  </div>
                </>
              )}
          {name === 'Contact' && loadingContactStatus === 'loading'
            ? [1, 2].map((item) => <Skeleton height={200} key={item} />)
            : null}
        </div>
        <button
          onClick={() => setQuickAddPolicy('open')}
          className='addbutton-mob d-sm-none'
        >
          <img src={whitePlus} alt='add' />
        </button>
        {quickAddPolicy !== 'closed' &&
          accountDetails &&
          name === 'Account' && (
            <PolicyModal
              show={quickAddPolicy}
              closeModal={setQuickAddPolicy}
              account={accountDetails}
              name={accountDetails?.name}
              accountType={accountDetails?.AccountType?.name}
              innerTabName={name}
              primaryContact={
                accountDetails?.AccountContacts.filter(
                  (item) => item?.primaryContact
                )[0]
              }
            />
          )}
        {quickAddPolicy !== 'closed' &&
          contactDetails &&
          name === 'Contact' && (
            <PolicyModal
              show={quickAddPolicy}
              closeModal={setQuickAddPolicy}
              innerTabName={name}
              name={contactDetails?.firstName + ' ' + contactDetails?.lastName}
              contactDetails={contactDetails}
            />
          )}
      </div>
      <FilterContainer>
        {showFilter !== 'closed' && (
          <PolicyFilter
            show={showFilter}
            closeModal={setShowFilter}
            setProducts={setProducts}
            selectedPolicySoldThrough={selectedPolicySoldThrough}
            setSelectedPolicySoldThrough={setSelectedPolicySoldThrough}
            selectedProducts={selectedProducts}
            producer={producer}
            setProducer={setProducer}
            producerSearch={producerSearch}
            setProducerSearch={setProducerSearch}
            filterFunction={filterPolicy}
            setCarrier={setCarrier}
            carrier={carrier}
            carrierSearchText={carrierSearchText}
            setCarrierSearchText={setCarrierSearchText}
            accountSearch={accountSearch}
            setAccountSearch={setAccountSearch}
            isInnerTab={true}
            setType={setCarrierType}
          />
        )}
      </FilterContainer>
      {showDeleteModal !== 'closed' && (
        <DeleteModal
          showModal={showDeleteModal}
          closeModal={setShowDeleteModal}
          deleteFunction={onDelete}
          uuid=''
          loading={loadingStatuspolicy || 'idle'}
          resource='policy'
          name={detailName}
        />
      )}
    </InnerFieldStyle>
  );
};

export default Policies;
