import { useParams, useNavigate } from 'react-router-dom';
import { Navigate, Route, Routes, useLocation } from 'react-router-dom';
import TopBar from '../../../components/TopBar';
import { CarrierInfoStyles } from './styles';
import arrow from '../../../assets/logo/right-arrow.svg';
import SystemDefinedCarriers from './components/SystemDefinedCarriers';
import { StyledTab } from '../../../styles/StyledTab';
import CustomCarriers from './components/CustomCarriers';
import Link from '../../../components/Link';
import LocalStorageService from '../../../utils/LocalStorageService';

const Carriers = () => {
  const location = useLocation();
  const { uuid } = useParams();
  const navigate = useNavigate();
  const user = LocalStorageService.getUser();

  return (
    <CarrierInfoStyles className='carrier-styledtab'>
      <TopBar>
        <div
          className='d-none d-sm-flex align-items-center justify-content-between back-button-div'
          onClick={() =>
            navigate(
              user.UserRole.name === 'ADMIN'
                ? `/admin/agency/${uuid}`
                : `/settings`
            )
          }
        >
          <img src={arrow} className='leftArrow back-button-head' alt='arrow' />
          <h2>Carrier Settings</h2>
        </div>
      </TopBar>
      <StyledTab className='d-none d-sm-block'>
        <Link
          to={
            user.UserRole.name === 'ADMIN'
              ? `/admin/agency/${uuid}/carrier/system-defined-carrier`
              : `/settings/carrier/system-defined-carrier`
          }
        >
          System Defined Carriers
        </Link>
        <Link
          to={
            user.UserRole.name === 'ADMIN'
              ? `/admin/agency/${uuid}/carrier/custom-carrier`
              : `/settings/carrier/custom-carrier`
          }
        >
          Custom Carriers
        </Link>
      </StyledTab>

      <Routes>
        <Route
          path={`/system-defined-carrier`}
          element={<SystemDefinedCarriers />}
        />
        <Route path={`/custom-carrier`} element={<CustomCarriers />} />

        <Route
          path='*'
          element={
            user.UserRole.name === 'ADMIN' ? (
              <Navigate
                to={`${location.pathname
                  .split('/')
                  .slice(0, 3)
                  .join('/')}/${uuid}/carrier/system-defined-carrier`}
                replace
              />
            ) : (
              <Navigate
                to={`${location.pathname
                  .split('/')
                  .slice(0, 3)
                  .join('/')}/system-defined-carrier`}
                replace
              />
            )
          }
        />
      </Routes>
    </CarrierInfoStyles>
  );
};

export default Carriers;
