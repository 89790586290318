import React from 'react';
import { StyledCard } from '../elements/styles';
import warning from '../../../assets/logo/warning.svg';

interface IProps {
  message: string;
  show: string;
}

const ErrorCard = ({ message, show }: IProps) => {
  return (
    <StyledCard>
      <div className='border'></div>
      <div className='message d-flex align-items-start'>
        <img src={warning} alt='' className='error-icon' />
        <div dangerouslySetInnerHTML={{ __html: message }} />
      </div>
    </StyledCard>
  );
};

export default ErrorCard;
