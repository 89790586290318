import { useSelector } from 'react-redux';
import EmptyResults from '../../../MyBook/components/Empty';
import LoadingSkeleton from '../../../MyBook/components/Loading';
import { AgenciesSliceState } from '../../../../store/agencies/agenciesSlice';
import AgencyCard from './AgencyCard';
import sort from '../../../../assets/logo/sort.svg';
import filter from '../../../../assets/logo/filter.svg';
import { TableDiv } from '../../../../styles/TableDiv';
import { IAgency } from '../../../../interfaces/Agency';

interface IProps {
  agencies: IAgency[];
  setSortBy: Function;
  setOrderBy: Function;
  sortBy: string;
  orderBy: string;
}

const AgencyTable = ({
  agencies,
  setSortBy,
  setOrderBy,
  sortBy,
  orderBy,
}: IProps) => {
  const loadingStatus = useSelector(
    (state: { agencies: AgenciesSliceState }) => state.agencies.status
  );

  const handleSort = (sort: string) => {
    if (sort === sortBy) {
      if (orderBy === 'asc') {
        setOrderBy('desc');
      } else {
        setOrderBy('asc');
      }
    } else {
      setSortBy(sort);
    }
  };

  return (
    <TableDiv>
      <thead>
        <tr>
          <th className='agencyNameHead'>
            <div className='d-flex'>
              <input type='checkbox' className='form-check-input' />

              <div className='w-75 d-flex justify-content-between'>
                <div>Agency Name</div>
                <div>
                  <img
                    className='sort'
                    src={sort}
                    alt='sort'
                    onClick={() => handleSort('name')}
                  />
                </div>
              </div>
            </div>
          </th>
          <th className='statusContainerHead'>
            <div className='d-flex justify-content-between'>
              <div className='status'>Status</div>
            </div>
          </th>
          <th className='dateFieldHead '>
            <div className='d-flex justify-content-between'>
              <div>Created date</div>
              <div>
                <img
                  className='sort'
                  src={sort}
                  alt='sort'
                  onClick={() => handleSort('createdAt')}
                />
              </div>
            </div>
          </th>
          <th className='userGroupFieldHead'>
            <div className='userGroup'>User Group</div>
          </th>
          <th className='usersFieldHead d-none d-xl-table-cell'>Users</th>
          <th className='actionFieldHead'>
            <div className='action'>Actions</div>
          </th>
        </tr>
      </thead>
      <tbody>
        {loadingStatus === 'loading' ? (
          <LoadingSkeleton columns={6} />
        ) : loadingStatus === 'succeeded' && agencies.length > 0 ? (
          agencies.map((el) => <AgencyCard agency={el} key={el.uuid} />)
        ) : (
          <tr className='empty-div'>
            <td colSpan={5} className='d-xl-none'>
              <EmptyResults name='agency' />
            </td>
            <td colSpan={7} className='d-none d-xl-table-cell'>
              <EmptyResults name='agency' />
            </td>
          </tr>
        )}
      </tbody>
    </TableDiv>
  );
};

export default AgencyTable;
