import sort from '../../../../assets/logo/sort.svg';
import sortup from '../../../../assets/logo/sortup.svg';
import sortdown from '../../../../assets/logo/sortdown.svg';
import { TableDiv } from '../../../../styles/TableDiv';
import LoadingSkeleton from '../../../../pages/MyBook/components/Loading';
import EmptyResults from '../../../../pages/MyBook/components/Empty';
import { useSelector } from 'react-redux';
import { DocumentSliceState } from '../../../../store/documents/documentsSlice';
const DocumentTable = ({
  Docuements,
  setSortBy,
  setOrderBy,
  sortBy,
  orderBy,
}: {
  Docuements: any[];
  setSortBy: Function;
  setOrderBy: Function;
  sortBy: string;
  orderBy: string;
}) => {
  const loadingStatus = useSelector(
    (state: { documents: DocumentSliceState }) => state.documents?.status
  );

  const handleSort = (sort: string) => {
    if (sort === sortBy) {
      if (orderBy === 'asc') {
        setOrderBy('desc');
      } else {
        setOrderBy('asc');
      }
    } else {
      setSortBy(sort);
    }
  };

  return (
    <TableDiv>
      <thead>
        <tr>
          <th className='agencyNameHead'>
            <div className='d-flex'>
              <div className='w-75 d-flex justify-content-between'>
                name
                <img
                  className='sort'
                  src={
                    sortBy !== 'name'
                      ? sort
                      : orderBy === 'asc'
                      ? sortdown
                      : sortup
                  }
                  alt='sort'
                  onClick={() => handleSort('name')}
                />
              </div>
            </div>
          </th>
          <th className='statusContainerHead'>Document type</th>
          <th className='statusContainerHead'>Doc.name</th>
          <th className='dateFieldHead'>Added by</th>
          <th className='dateFieldHead'>Updated on</th>

          <th className='leadActionsFieldHead'>Actions</th>
        </tr>
      </thead>
      <tbody>
        {loadingStatus === 'loading' ? (
          <LoadingSkeleton columns={7} />
        ) : (
          <tr className='empty-div'>
            <td colSpan={5} className='d-xl-none'>
              <EmptyResults name='document' />
            </td>
            <td colSpan={7} className='d-none d-xl-table-cell'>
              <EmptyResults name='document' />
            </td>
          </tr>
        )}
      </tbody>
    </TableDiv>
  );
};

export default DocumentTable;
