import styled from '@emotion/styled';
import checkAll from '../../../assets/logo/checkAll.svg';
export const CarrierInfoStyles = styled.div`
  .mobilecardHeadDiv {
    height: 90%;
    padding: 20px 20px 0;
    .form-check-input[type='checkbox'] {
      width: 16px;
      height: 16px;
    }
    .select-all-mobile-container {
      padding-left: 20px;
    }
  }
  .batchCariier-container .mobilecardHeadDiv {
    padding: 0;
  }
  .batch-drawer-table {
    .thead .th:last-child,
    .table .td:last-child {
      box-shadow: none;
    }
  }
  .topdiv .close-btn {
    float: right;
  }
  .document-content {
    height: 100%;
  }
  .leftArrow.back-button-head {
    margin-left: 0;
  }
  .navbar-button {
    height: 100%;
  }
  .mobileTab-text {
    font-weight: 600;
    padding-bottom: 0;
    position: absolute;
    top: 80px;
    width: 100%;
  }
  .custom-carrier-action-drop.dropdown-div {
    bottom: -95px;
    & > div {
      border-top: 1px solid #e6e9f0;
      justify-content: space-between;
      padding: 4px 0;
      display: flex;
    }
    & > div:first-child {
      border-top: none;
    }
  }
  .link-div-container > div {
    width: 49%;
    display: flex;
    margin-top: 8px;
    margin-bottom: 8px;
    img {
      margin-right: 8px;
      width: 13px;
    }
  }
  .check-all.form-check-input:checked[type='checkbox'] {
    background-image: url(${checkAll});
  }
  .link-div-container > div:first-child {
    border-right: 1px solid #e6e9f0;
    margin-right: 8px;
    padding-right: 8px;
  }
  .self-link-div {
    margin-bottom: 12px;
    display: flex;
    img {
      margin-right: 8px;
    }
  }
  .mobile-actions::before {
    content: '';
    height: 26px;
    width: 1px;
    background: #e6e9f0;
  }
  .carrier-card-head {
    margin-bottom: 12px;
  }
  .carrierHead-div.HeadDiv {
    padding-left: 0;
    padding-right: 0;
    z-index: 2;
    & > div,
    & > h2 {
      padding-left: 20px;
      padding-right: 20px;
    }
  }
  .activate-div {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-top: 1px solid #e6e9f0;
  }
  .entire-div {
    max-width: 100%;
  }
  .HeadDiv .addButton img {
    filter: invert(1);
  }
  .button-container .move-delete {
    width: 200px;
  }
  .system-drop-actions.dropdown-div {
    bottom: -39px;
    .delete-div {
      border-top: none;
    }
  }
  .vector:hover,
  .vector:focus {
    background: #e6e9f0;
  }
  .back-button-div {
    cursor: pointer;
  }
  .mobileTab-text .mobile-tab {
    position: absolute;
    display: flex;
    z-index: 3;
    flex-direction: column;
    background: #fff;
    top: 35px;
    left: 20px;
    box-shadow: rgb(35 42 64 / 20%) 0px 2px 8px 0px;
    border-radius: 8px;
    width: 227px;
    padding: 4px;
    .navlink {
      padding: 10px 12px;
      width: 100%;
    }

    .active {
      background: #c1dbeb;
      border-radius: 8px;
      border-bottom: 0;
    }
  }
  .inputSearchField {
    outline: none;
    border: none;
    background: #e6e9f0;
    width: 390px;
  }
  .activeCarrierTab {
    font-weight: 600;
    border-bottom: 3px solid rgb(35, 42, 64);
    padding-bottom: 7px;
    width: max-content;
    margin-bottom: -2px;
  }
  .actions-header,
  .carrier-table .actions {
    max-width: 276px;
    @media screen and (max-width: 1200px) {
      max-width: 220px;
    }
  }
  .searchField {
    padding: 6px;
    margin: 10px 10px;
    border-radius: 8px;
    background: #e6e9f0;
    margin-left: 0;
    .searchIcon {
      width: 16px;
    }
  }
  .filterField {
    padding: 8px;
    width: 245px;
    font-size: 16px;
    padding-left: 20px;
  }
  .row-name {
    :hover {
      cursor: initial;
      text-decoration: none;
    }
  }
  .table-tab-view .table .td {
    height: 78px;
    .row-name {
      line-height: 24px;
      font-size: 14px;
      :hover {
        cursor: initial;
        text-decoration: none;
      }
    }
    .item-status {
      height: 20px;
      font-size: 12px;
      .dot {
        margin-right: 8px;
      }
    }
  }
  .add-carrier-heading {
    margin-bottom: 22px;
  }
  .mybook-content.carrier-content {
    margin-top: 2px;
  }
  h2.carrier-batch-heading {
    font-weight: 600;
    font-size: 36px;
    line-height: 52px;
  }
  .close-btn.cross-btn {
    right: 0;
    padding: 0;
    top: calc(50% - 13px);
    img {
      width: 26px;
    }
  }
  .batchCariier-container .buttonsDiv {
    padding-right: 0;
    .saveButton {
      width: 269px;
      border: none;
    }
    @media screen and (max-width: 576px) {
      width: calc(100% - 40px);
      .saveButton {
        width: 200px;
      }
    }
  }
  .topdiv {
    position: relative;
  }
  .batch-topdiv.topdiv {
    height: auto;
    .close-btn {
      top: 3px;
      @media screen and (max-width: 576px) {
        top: 0;
      }
    }
  }
  .searchDivCarrier {
    background: #fff;
    margin-top: 26px;
    margin-bottom: 22px;
  }
  .addCarrierButton {
    background: #262758;
    padding: 12px 20px;
    border: none;
    height: 48px;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    color: #f5f7fc;
    border-radius: 8px;
    width: 268px;
    justify-content: space-between;
    align-items: center;
    img {
      filter: invert(1);
      width: 16px;
    }
  }
  .HeadDiv {
    width: 100%;
    position: fixed;
    background: #fff;
    padding: 20px;
    top: 0px;
    height: 120px;
  }
  .HeadNavigator {
    background: #fff;
    top: 30px;
    width: 100%;
  }
  .searchBackground {
    background: #fff;
    padding-top: 100px;
  }
  .leftArrow {
    transform: rotate(180deg);
    filter: invert(0%) sepia(14%) saturate(2240%) hue-rotate(295deg)
      brightness(0%) contrast(103%);
    margin-right: 15px;
    cursor: pointer;
    margin-left: 12px;
  }
  .carrier-content .actionButton {
    padding: 5px 0px;
  }
  @media screen and (max-width: 1200px) {
    &.carrier-styledtab {
      padding-top: 80px;
      padding-bottom: 50px;
    }
  }
  @media screen and (max-width: 576px) {
    &.carrier-styledtab {
      padding-top: 140px;
    }
    .drawer-container .field-section {
      height: calc(100vh - 160px);
    }
    .searchBackground .searchField {
      width: 100%;
      margin-left: 0;
    }
    .filterField.searchField {
      width: 36px;
      padding-left: 5px;
      padding-right: 5px;
      margin-right: 0;
      img {
        margin: auto;
      }
    }
    .inputSearchField {
      width: unset;
    }
    .button-container .activate-submit-btn {
      width: 170px;
    }
  }
  @media screen and (min-width: 576px) {
    padding: 108px 32px 0 32px;
    height: 100%;
    .navbar-button {
      margin-right: 16px;
      background: #e6e9f0;
      border-radius: 8px;
      border: none;
      color: #262758;
      font-size: 16px;
      font-weight: 600;
      min-width: 40px;
      height: 40px;
    }
    .drawer-container .field-section {
      height: calc(100vh - 160px);
    }
    .topbarButtons {
      position: fixed;
      right: 10%;
    }
    .filter-button {
      background: #e6e9f0;
      border-radius: 8px;
      width: 212px;
    }
    .leftArrow {
      transform: rotate(180deg);
      filter: invert(0%) sepia(14%) saturate(2240%) hue-rotate(295deg)
        brightness(0%) contrast(103%);
      margin-right: 15px;
      cursor: pointer;
    }
    .mybook-content {
      margin: 0;
    }
    .book-filter {
      margin-bottom: 32px;
    }
    .activeStage {
      color: #3d8a68;
    }
    .inactiveStage {
      color: #9a0a0d;
    }
    .dot {
      width: 8px;
      height: 8px;

      border-radius: 50%;
    }
    .avtive {
      background: #58c070;
    }
    .inactive {
      background: #cc0000;
    }

    .actionsButton {
      background: #f5f7fc;
      width: 32px;
      height: 32px;
      padding: 0px 8px;
      margin-left: 20px;
    }
    .logoImage {
      width: 48px;
      height: 48px;
      background: #f5f7fc;
      margin-right: 8px;
      padding: 2px;
      line-height: 44px;
      text-align: center;
      font-weight: 600;
    }
    .counter {
      padding-left: 5px;
    }
  }

  @media screen and (min-width: 1200px) {
    padding: 108px 48px 0 135px;
    height: 100%;
    .navbar-button {
      margin-right: 16px;
      background: #e6e9f0;
      border-radius: 8px;
      border: none;
      color: #262758;
      font-size: 16px;
      font-weight: 600;
      min-width: 40px;
      height: 40px;
    }
    .topbarButtons {
      position: fixed;
      right: 10%;
    }
    .filter-button {
      background: #e6e9f0;
      border-radius: 8px;
      width: 212px;
    }
    .leftArrow {
      transform: rotate(180deg);
      filter: invert(0%) sepia(14%) saturate(2240%) hue-rotate(295deg)
        brightness(0%) contrast(103%);
      margin-right: 15px;
      cursor: pointer;
    }
    .mybook-content {
      margin: 0;
    }
    .book-filter {
      margin-bottom: 32px;
    }
    .activeStage {
      color: #3d8a68;
    }
    .inactiveStage {
      color: #9a0a0d;
    }
    .dot {
      width: 8px;
      height: 8px;

      border-radius: 50%;
    }
    .avtive {
      background: #58c070;
    }
    .inactive {
      background: #cc0000;
    }

    .actionsButton {
      background: #f5f7fc;
      width: 32px;
      height: 32px;
      padding: 0px 8px;
      margin-left: 20px;
    }
    .logoImage {
      width: 48px;
      height: 48px;
      background: #f5f7fc;
      margin-right: 8px;
      padding: 2px;
    }
  }
  @media (min-width: 1900px) {
    padding: 108px 120px 0 208px;
    .thead .th {
      height: 56px;
      line-height: 30px;
    }
  }
`;
export const CarriersMobileCard = styled.div`
  box-shadow: 0px 1px 4px rgba(35, 42, 64, 0.2);
  padding: 18px 20px;
  margin-bottom: 8px;
  border-radius: 16px;
  font-size: 12px;

  .custom-link {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .CarrierName {
    font-size: 14px;
    font-weight: 600;
  }
  .activeStage {
    color: #3d8a68;
  }
  .inactiveStage {
    color: #9a0a0d;
  }
  .dot {
    width: 8px;
    height: 8px;

    border-radius: 50%;
  }
  .avtive {
    background: #58c070;
  }
  .inactive {
    background: #cc0000;
  }
  .Activatebtn {
    width: 100%;
    height: 32px;
    border: none;
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }
  .actionButton {
    background: #ecf4f9;
    text-align: center;
    border-radius: 8px;
    padding: 5px 0px;
    width: 100%;
    font-size: 14px;
    font-weight: 600;
    cursor: pointer;
    line-height: 21px;
  }
  .vector {
    width: 32px;
    height: 32px;
    margin-left: 20px;
    border-radius: 8px;
    background: #f5f7fc;
    cursor: pointer;
    position: relative;
    border: none;

    &:hover {
      background: #e6e9f0;
    }
  }
  .vector .dropdown-div {
    position: absolute;
    background: #fff;
    box-shadow: 0px 1px 4px 0px #26275833;
    border-radius: 8px;
    padding: 4px 16px;
    right: 0;
    top: 35px;
    font-size: 14px;
    z-index: 4;
    width: 144px;
    cursor: pointer;

    div {
      padding: 4px 0;
    }

    .delete-div {
      color: #9a0a0d;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
  }
  .Inactivebtn {
    background: #e6e9f0;
  }
  .Activebtn {
    background: #ecf4f9;
  }
  .stateandnames {
    color: #5a5f70;
    font-size: 12px;
    margin-bottom: 3px;
  }
  .buttonsDiv {
    margin: 18px 0 16px 0;
  }
`;
