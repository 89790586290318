import styled from '@emotion/styled';

export const LeadStyledModal = styled.div`
  h2 {
    font-weight: 600;
  }
  .name-error {
    margin-bottom: 25px;
  }
  .errorVal {
    margin-top: 8px;
    font-size: 14px;
    color: #cc0000;
    display: flex;
    align-items: center;

    &.emailError {
      background: #f8e3df;
      color: #000;
      border-radius: 8px;
      border-left: solid 8px #cc0000;
      padding: 10px;
      span {
        font-weight: bold;
        text-decoration: underline;
        cursor: pointer;
        max-width: 100%;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
        display: inline-block;
        vertical-align: bottom;
      }
    }
    .email-exist-error {
      max-width: calc(100% - 25px);
    }
  }
  .errorVal {
    font-size: 16px;
    margin-top: 3px;
  }
  .warning {
    margin-right: 14px;
  }
  .personaldetailas {
    // margin-top: 25px;
  }
  .scroll-bar {
    height: 92vh;
    overflow-y: auto;
    overflow-x: hidden;
    padding-bottom: 160px;
    padding-right: 20px;

    &::-webkit-scrollbar {
      width: 4px;
      height: 159px;
    }

    &::-webkit-scrollbar-track {
      border-radius: 8px;
    }

    &::-webkit-scrollbar-thumb {
      background: #e6e9f0;
    }
  }

  .entireModal {
    width: 93vw;
  }
  .leadHead {
    top: 30px;
    margin-left: 5px;
    position: fixed;
  }
  .Successcard {
    position: fixed;
    width: 528px;
    height: 92px;
    top: 100px;
    right: 0;
    transition: all 0.3s ease-in-out;
  }
  .topHead {
    width: 92%;
    height: 103px;
    background: #ffffff;
    border-bottom: 1px solid #e6e9f0;
    position: fixed;
    top: 0;
    z-index: 1;
    padding: 32px 0px;
  }
  .opportunityModal {
    position: relative;
    overflow-y: auto;
    overflow-x: hidden;
    max-height: 80vh;
    z-index: 2;
    width: 100%;
    padding-right: 4px;
    height: calc(100vh - 155px);

    &::-webkit-scrollbar {
      width: 4px;
      height: 159px;
    }

    &::-webkit-scrollbar-track {
      border-radius: 8px;
    }

    &::-webkit-scrollbar-thumb {
      background: #e6e9f0;
    }
  }
  .prior {
    margin-top: 34px;
    margin-bottom: 24px;
    align-items: center;
  }

  .form-check-input {
    height: 26px;
    width: 26px;
    margin-right: 12px;
  }

  .topmodOp {
    margin-bottom: 16px;
  }
  .fields-Xsmall {
    width: 90vw;
    padding-top: 14px;
  }
  .buttonsDiv {
    border-top: 1px solid #e6e9f0;
    position: fixed;
    width: 100%;
    bottom: 0;
    right: 0;
    background: #fff;
    z-index: 1;
    padding: 10px;
  }
  .OpportunityHead {
    height: 104px;
  }

  .agentButton {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: #262758;
    color: #f5f7fc;
    border-radius: 8px;
    width: 200px;
    height: 48px;
    border: none;
    padding: 0 20px;
    &:disabled {
      background: #d1d6e5;
      border: none;
    }
  }
  .addopportunity {
    width: 150px;
    align-items: center;
  }
  .cancel-button {
    height: 48px;
    background: none;
    border: none;
    color: #262758;
    font-weight: 600;
    font-size: 16px;
    margin-left: auto;
    margin-right: 30px;
  }
  .navbuttonDiv {
    position: fixed;
    top: 75px;
  }
  .Datefields {
    margin: 16px 0px;
    max-height: 50px;
    width: 90vw;
  }
  .naVigatorbuttons {
    colour: #262758;
    background: none;
    border: none;
    margin: 0 1px;
  }
  .naVigatorbuttonsActive {
    background: none;
    font-weight: 600;
    border: none;
    margin: 0 1px;
    border-bottom: 3px solid #262758;
  }
  .naVigatorbuttons:hover {
    border-bottom: 3px solid #262758;
  }
  .naVigatorbuttons:disabled {
    border: none;
  }
  .crossbutton {
    border: none;
    background: none;
    position: fixed;
    top: 35px;
    right: 30px;
    width: 20px;
  }
  h5 {
    font-size: 14px;
  }
  .form-check-label {
    font-size: 14px;
  }
  .opertunityDiv {
    width: 100%;
    margin: 0 auto;
  }
  .opertunity-field {
    width: 90vw;
    margin-bottom: 12px;
    .search-group {
      .input-placeholder {
        left: 15px;
      }
      .input-field {
        padding: 18px 25px 10px 15px;
      }
    }
  }
  .DatefieldsSmall {
    width: 90vw;
    margin-bottom: 12px;
  }
  .cross {
    width: 25px;
    height: 25px;
  }
  .SelectField {
    color: #262758;
    width: 90vw;
    margin: 12px 0px;
    height: 48px;
    .search-group {
      .input-placeholder {
        left: 18px;
      }
      .input-field {
        padding: 18px 25px 10px 18px;
      }
    }
  }
  .inputField::placeholder {
    color: #000000;
  }
  .subText {
    font-size: 14px;
    font-weight: 600;
    padding-top: 44px;
  }
  .HeadText {
    font-size: 14px;
    font-weight: 600;
    padding-top: 94px;
  }
  .inputField {
    width: 90vw;
  }
  .fields-medium {
    width: 90vw;
  }
  .fields-small {
    padding-top: 10px;
    width: 90vw;
  }
  .smallfieldabove {
    padding-top: 0px;
  }
  .fields {
    color: #262758;
    width: 90vw;
    margin: 12px 0px;
    .search-group {
      .input-placeholder {
        left: 16px;
      }
      .input-field {
        padding: 18px 25px 10px 16px;
      }
    }
  }

  h2 {
    font-weight: 600;
    line-height: 28px;
  }
  .fields::placeholder {
    color: #262758;
  }
  .leadModal {
    width: 100%;
    padding: 0 0 50px 2px;
    height: 90vh;
    overflow: auto;

    ::-webkit-scrollbar {
      width: 0 !important;
      display: none;
    }
  }

  .leftarrow {
    display: flex;
    transform: rotate(360deg);
    width: 22px;
    height: 22px;
  }
  .moredetailsOP {
    cursor: pointer;
    align-items: center;
    width: 140px;
  }
  .bigfields {
    width: 90vw;
    height: 108px;
    max-height: 198px;
    margin-bottom: 50px;
    border: 1px solid #e6e9f0;
    &:hover {
      border: 1px solid #cad9f3;
      box-shadow: 0px 1px 4px rgba(35, 42, 64, 0.2);
    }
    &:focus,
    &:active {
      border: 1px solid #3c498a;
      outline-offset: 0px;
      outline: none;
    }
  }
  .moredetails {
    color: #262758;
    margin-top: 20px;
    cursor: pointer;
    align-items: center;
  }
  .blackplus {
    margin-right: 20px;
  }
  .addbutton {
    font-size: 14px;
    margin-bottom: 0px;
  }
  .addOp {
    margin-top: 10px;
  }
  .addOpportunity {
    margin: 15px 0px;
    cursor: pointer;
    padding-bottom: 20px;
  }

  .opportunity {
    padding-top: 30px;
    width: 90vw;
  }
  .topText {
    padding-top: 0px;
  }
  .editLeadError {
    margin-top: 90px;
    font-size: 14px;
    color: #cc0000;
    display: flex;
    align-items: center;

    &.emailError {
      background: #f8e3df;
      border-radius: 8px;
      border-left: solid 8px #cc0000;
      padding: 10px;
      span {
        font-weight: bold;
        text-decoration: underline;
        cursor: pointer;
        max-width: 100%;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
        display: inline-block;
        vertical-align: bottom;
      }
    }
    .email-exist-error {
      max-width: calc(100% - 25px);
    }
  }
  .warning {
    width: 20px;
    margin-right: 10px;
  }
  @media screen and (min-width: 576px) {
    padding: 40px 48px;

    .fields-medium {
      width: 240px;
      margin-top: 20px;
    }
    .leadModal {
      padding-bottom: 50px;
      padding-right: 20px;
    }
    .scroll-bar {
      &::-webkit-scrollbar {
        height: 150px;
      }
    }

    .opportunity {
      padding-top: 40px;
      width: 848px;
    }

    .Prior {
      margin-top: 38px;
      margin-bottom: 18px;
    }
    .fields-small {
      width: 171px;
      padding: 0px 0px;
    }
    .fields {
      color: #262758;
      width: 360px;
      margin: 12px 0px;
      // height: 48px;
    }
    .fields-Xsmall {
      padding-top: 20px;
      width: 104px;
    }
    .scroll-bar {
      height: 65vh;
      padding-bottom: 60px;
    }
    .SelectField {
      color: #262758;
      width: 90vw;
      margin: 12px 0px;
      height: 48px;
    }
    .opertunityDiv {
      width: 100%;
      flex-wrap: wrap;
      margin: 0 auto;
    }
    .opertunity-field {
      width: 160px;
      margin-bottom: 12px;
      margin-top: 20px;
    }
    .DatefieldsSmall {
      width: 160px;
      margin-bottom: 12px;
      margin-top: 20px;
    }

    .moredetailsOP {
      margin-top: 24px;
      width: 140px;
    }
    .leftarrow {
      width: 25px;
      margin-top: 0;
    }
    .entireModal {
      width: 848px;
      margin-top: 100px;
    }
    .subText {
      font-weight: 600;
      font-size: 16px;
    }
    .SelectField {
      color: #000000;
      width: 360px;
      margin: 12px 0px;
    }
    .inputField {
      width: 100%;
    }
    .smallField {
      margin-top: 12px;
      width: 360px;
    }
    .subsmallField {
      width: 360px;
    }
    .bigfields {
      height: 108px;
      max-height: 198px;
      width: 100%;
      margin-bottom: 80px;
      border: 1px solid #e6e9f0;
    }

    .Datefields {
      margin: 0;
      margin-top: 20px;
      width: 360px;
    }
    .opportunityModal {
      max-height: 65vh;
    }
    .editLeadError {
      margin-top: 0px;
    }
    .topText {
      padding-top: 0px;
    }
    .HeadText {
      font-size: 16px;
      font-weight: 600;
      padding-top: 30px;
    }
    .opportunityComponent {
      padding-bottom: 280px;
    }
  }

  @media screen and (min-width: 1200px) {
    padding: 20px 40px;

    .opportunity {
      padding-top: 30px;
      width: 883px;
    }
    h2 {
      font-size: 24px;
    }
    .scroll-bar {
      height: 73vh;
    }
    .leadModal {
      padding-bottom: 50px;
    }
    .subText {
      font-size: 16px;
      font-weight: 600;
    }

    .Datefields {
      margin: 0;
      margin-top: 10px;
      margin-bottom: 10px;
      width: 420px;
    }

    .firstDateField {
      margin: 0;
      margin-top: 20px;
    }
    .fields-medium {
      width: 298px;
      margin-top: 20px;
    }
    .fields-small {
      width: 200px;
      padding: 0px 0px;
    }
    .leadHead {
      top: 5px;
      margin-left: 5px;
    }
    .fields {
      width: 420px;
    }
    .SelectField {
      width: 420px;
      margin: 12px 0px;
    }
    .smallField {
      margin-top: 12px;
      width: 420px;
    }
    .subsmallField {
      width: 420px;
    }
    .entireModal {
      width: 883px;
      margin-top: 60px;
    }
    h5 {
      font-weight: 600;
      font-size: 16px;
    }
    .form-check-label {
      font-weight: 600;
      font-size: 16px;
    }
    .bigfields {
      height: 150px;
      max-height: 198px;
      width: 100%;
      margin-bottom: 40px;
      border: 1px solid #e6e9f0;
    }
    .buttonsDiv {
      padding: 20px;
      bottom: 0;
      right: 0;
      height: 80px;
    }

    .opportunityModal {
      max-height: 80vh;
    }
    .editLeadError {
      margin-top: 30px;
    }
  }
  @media screen and (min-width: 1900px) {
    padding: 30px 40px;

    .entireModal {
      width: 1320px;
    }

    .opportunity {
      padding-top: 30px;
      width: 1320px;
    }
    .leadModal {
      height: 85vh;
    }

    .leadHead {
      top: 20px;
      margin-left: 5px;
      position: fixed;
    }
    .scroll-bar {
      height: 75vh;
    }
    .Datefields {
      margin: 0;
      margin-top: 20px;
      margin-bottom: 20px;
      width: 576px;
    }
    .Prior {
      margin-top: 50px;
      margin-bottom: 18px;
    }
    .cross {
      width: 30px;
      height: 30px;
    }
    .crossbutton {
      top: 30px;
      right: 50px;
    }
    h2 {
      font-weight: 600;
      font-size: 24px;
      line-height: 28px;
    }
    .SelectField {
      color: #000000;
      width: 576px;
      margin: 12px 0px;
    }
    .fields {
      margin: 16px 0px;
      width: 576px;
    }
    .fields-small {
      width: 278px;
      padding: 0px 0px;
    }
    .inputField {
      width: 100%;
    }
    .smallField {
      width: 576px;
      margin-top: 15px;
    }
    .subsmallField {
      width: 576px;
    }
    .OpsubsmallField {
      padding-top: 5px;
    }
    .fields-medium {
      width: 416px;
    }
    .fields-Xsmall {
      padding-top: 20px;
      width: 140px;
    }
    .modelBody {
      margin-top: 50px;
      width: 100%;
    }

    .subText {
      margin-top: 60px;
      font-size: 20px;
    }
    .moredetails {
      margin-top: 24px;
    }
    .moredetailsOP {
      margin-top: 24px;
      width: 140px;
    }
    .addbutton {
      font-size: 14px;
    }
    .addOp {
      margin-top: 12px;
    }
    .blackplus {
      margin-right: 15px;
    }
    .opertunity-field {
      width: 220px;
      margin-bottom: 12px;
      margin-top: 20px;
    }
    .DatefieldsSmall {
      width: 220px;
      margin-bottom: 12px;
      margin-top: 20px;
    }
    .leftarrow {
      width: 25px;
    }

    .addmoreOPDiv {
      cursor: pointer;
      margin-top: 8px;
      width: 145px;
    }
    .buttonsDiv {
      height: 80px;
      padding: 20px;

      bottom: 0;
      right: 0;
      height: 80px;
    }
    .agentButton {
      width: 160px;
      height: 48px;
      padding: 0 20px;
    }
    .cancel-button {
      height: 48px;
      background: none;
      border: none;
      color: #262758;
      font-weight: 600;
      font-size: 16px;
      margin-left: auto;
      margin-right: 30px;
    }
    .bigfields {
      height: 198px;
      max-height: 198px;
      width: 100%;
      margin-bottom: 20px;
      border: 1px solid #e6e9f0;
    }
    .addOpportunity {
      margin: 25px 0px;
    }
    .RawLead {
      margin-bottom: 16px;
    }
    .errorsubsmallField {
      margin-top: 20px;
    }
    .CSROpportunityField {
      margin-top: 20px;
    }
    .opportunityModal {
      max-height: 76vh;
      ::-webkit-scrollbar {
        width: 4px;
        height: 159px;
      }
    }
  }
`;
