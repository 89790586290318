import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Moment from 'react-moment';
import { useParams, useNavigate } from 'react-router-dom';
import Skeleton from 'react-loading-skeleton';
import { AppDispatch } from '../../../store';
import {
  getAgents,
  selectAllAgents,
  AgentsSliceState,
  updateAgentDetail,
  removeAgent,
} from '../../../store/agents/agentsSlice';
import useDebounce from '../../../hooks/useDebounce';
import AgentModal from './components/AgentModal';
import AgentCardMob from './components/AgentCardMobile';
import MobileNav from '../../../components/MobileTabNav';
import TopBar from '../../../components/TopBar';
import AgentTable from './components/AgentTable';
import EmptyResults from '../../MyBook/components/Empty';
import Table from '../../../components/Table';
import PaginationComponent from '../../../components/PaginationComponent';
import { IAgent } from '../../../interfaces/User';
import DeactivateUser from './components/DeactivateUser';
import search from '../../../assets/logo/search.svg';
import plus from '../../../assets/logo/plus.svg';
import filter from '../../../assets/logo/filter.svg';
import arrow from '../../../assets/logo/right-arrow.svg';
import sort from '../../../assets/logo/sort.svg';
import sortup from '../../../assets/logo/sortup.svg';
import sortdown from '../../../assets/logo/sortdown.svg';
import vector from '../../../assets/logo/dropdown.svg';
import edit from '../../../assets/logo/edit.svg';
import bin from '../../../assets/logo/bin.svg';
import LocalStorageService from '../../../utils/LocalStorageService';
import close from '../../../assets/logo/cross.svg';
import filtermobile from '../../../assets/logo/filter-mobile.svg';
import { FilterContainer } from '../../MyBook/elements/styles';
import UserFilter from './components/FilterDrawer';
import DeleteUserModal from './components/DeleteUser';
import EditUser from '../../../components/EditUser';

function AgencyDetails() {
  const dispatch = useDispatch<AppDispatch>();
  const agents = useSelector(selectAllAgents);
  const loadingStatus = useSelector(
    (state: { agents: AgentsSliceState }) => state.agents.status
  );
  const agentsCount = useSelector(
    (state: { agents: AgentsSliceState }) => state.agents.count
  );
  const { uuid } = useParams();
  const navigate = useNavigate();

  const user = LocalStorageService.getUser();
  const [adduser, setAddUser] = useState('closed');
  const [edituser, setEditUser] = useState('closed');
  const [sortBy, setSortBy] = useState('createdAt');
  const [orderBy, setOrderBy] = useState('desc');
  const [searchBy, setSearchBy] = useState('');
  const [isSearchClicked, setIsSearchClicked] = useState(false);
  const [limit, setLimit] = useState(25);
  const [page, setPage] = useState(0);
  const [deActivate, setDeActivate] = useState(false);
  const [deactivatingUser, setDeActivatingUser] = useState<IAgent>();
  const addUserFunction = () => {
    setAddUser('open');
  };

  const debouncedSearchTerm = useDebounce(searchBy, 500);

  useEffect(() => {
    if (loadingStatus === 'idle' || loadingStatus === 'succeeded') {
      if (user?.UserRole.name === 'ADMIN') {
        dispatch(
          getAgents({
            agencyUUID: uuid,
            sortBy,
            orderBy,
            searchBy: debouncedSearchTerm,
            limit,
            offset: page * limit,
          })
        );
      } else if (user?.UserRole.name === 'PRINCIPAL_AGENT') {
        dispatch(
          getAgents({
            agencyUUID: user?.Agent?.Agencies?.uuid,
            sortBy,
            orderBy,
            searchBy: debouncedSearchTerm,
            limit,
            offset: page * limit,
            agentStatus: userStatus?.value,
            userRole: userRole?.value,
          })
        );
      }
    } // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, sortBy, orderBy, debouncedSearchTerm, uuid, limit, page]);
  const handleSort = (sort: string) => {
    if (sort === sortBy) {
      if (orderBy === 'asc') {
        setOrderBy('desc');
      } else {
        setOrderBy('asc');
      }
    } else {
      setSortBy(sort);
    }
  };
  const updateAgentStatus = (
    uuid: string,
    data: { action: string; status: string }
  ) => {
    if ((loadingStatus === 'idle' || loadingStatus === 'succeeded') && uuid) {
      dispatch(updateAgentDetail({ uuid, data }));
    }
  };

  const searchRef = useRef(null);

  const [showFilter, setShowFilter] = useState('closed');

  const [userRole, setUserRole] = useState<any>('');
  const [userStatus, setUserStatus] = useState<any>('');

  const filterUser = () => {
    dispatch(
      getAgents({
        agencyUUID:
          user?.UserRole.name === 'ADMIN' ? uuid : user?.Agent?.Agencies?.uuid,
        sortBy,
        orderBy,
        searchBy: debouncedSearchTerm,
        limit,
        offset: page * limit,
        agentStatus: userStatus?.value,
        userRole: userRole?.value,
      })
    );
  };

  const [showDeleteModal, setShowDeleteModal] = useState('closed');
  const [showSuccess, setShowSuccess] = useState(false);
  const [deletingUser, setDeletingUser] = useState<any>();
  const [deleteErrorMessage, setDeleteErrorMessage] = useState('');

  const onDelete = (uuid: string, deleteUUID: string) => {
    dispatch(
      removeAgent({
        uuid,
        deleteUUID,
        successCB: () => {
          setShowDeleteModal('closing');
          setTimeout(() => {
            setShowSuccess(true);
          }, 100);
          setTimeout(() => {
            setShowDeleteModal('closed');
            setShowSuccess(false);
          }, 2000);
        },
        errorCB: (data: any) => {
          setDeleteErrorMessage(
            data ? data : 'Something went wrong Please refresh'
          );
        },
      })
    );
  };

  const [editData, setEditData] = useState<any>({});

  return (
    <>
      <div className='content-div'>
        <TopBar>
          <div
            className='d-flex align-items-center'
            style={{ cursor: 'pointer' }}
            onClick={() =>
              user?.UserRole?.name === 'ADMIN'
                ? navigate(`/admin/agency/${uuid}`)
                : navigate('/settings')
            }
          >
            <img src={arrow} className='leftarrow  ' alt='leftarrow' />
            <h2> User Settings</h2>
          </div>
        </TopBar>

        <div className='topButtonsDiv d-flex justify-content-between w-100 '>
          <button
            className='addButton userButton d-none d-sm-flex align-items-center justify-content-between'
            onClick={addUserFunction}
          >
            Add User
            <img className='plus' src={plus} alt='add' />
          </button>
          <div className='book-filter d-flex ms-auto' ref={searchRef}>
            {isSearchClicked || searchBy ? (
              <div className='book-search d-flex align-items-center'>
                <img src={search} alt='' className='search-button' />
                <input
                  onChange={(e) => {
                    setPage(0);
                    setSearchBy(e.target.value);
                  }}
                  autoFocus
                  value={searchBy}
                />
                <img
                  src={close}
                  alt=''
                  className='close-button'
                  onClick={() => {
                    setPage(0);
                    setSearchBy('');
                    setIsSearchClicked(false);
                  }}
                />
              </div>
            ) : (
              <div
                className='book-shrinked'
                onClick={() => setIsSearchClicked(true)}
              >
                <img src={search} alt='' />
              </div>
            )}

            <span className='divider d-none d-sm-block' />
            <div
              className='book-shrinked d-none d-sm-flex'
              onClick={() => setShowFilter('open')}
            >
              <img src={filter} alt='' />
              {(userStatus?.value ? 1 : 0) + (userRole ? 1 : 0) > 0 && (
                <span className='filter-counter'>
                  {(userStatus?.value ? 1 : 0) + (userRole ? 1 : 0)}
                </span>
              )}
            </div>
            <span
              className={`divider d-sm-none ${isSearchClicked && 'd-none'}`}
            />
            <div
              className={`book-shrinked d-sm-none ${
                isSearchClicked && 'd-none'
              }`}
              onClick={() => setShowFilter('open')}
            >
              <img src={filtermobile} alt='' />
              {(userStatus?.value ? 1 : 0) + (userRole ? 1 : 0) > 0 && (
                <span className='filter-counter'>
                  {(userStatus?.value ? 1 : 0) + (userRole ? 1 : 0)}
                </span>
              )}
            </div>
          </div>
        </div>

        <button onClick={addUserFunction} className='addButton d-sm-none'>
          <img src={plus} alt='add' />
        </button>
        <div className='d-sm-none'>
          {loadingStatus === 'loading' ? (
            [1, 2, 3].map((item) => <Skeleton height={138} key={item} />)
          ) : loadingStatus === 'succeeded' && agents.length > 0 ? (
            <>
              {agents.map((el: IAgent) => (
                <AgentCardMob
                  firstName={el.User?.firstName}
                  lastName={el.User?.lastName}
                  Agent={el}
                  email={el.User?.email}
                  phoneNumber={el.User?.phoneNumber}
                  UserRole={el.User?.UserRole}
                  createdAt={el?.createdAt}
                  uuid={el.uuid}
                  key={el.uuid}
                  updateAgentStatus={updateAgentStatus}
                  setDeActivatingUser={setDeActivatingUser}
                  setDeActivate={setDeActivate}
                  setDeletingUser={setDeletingUser}
                  setShowDeleteModal={setShowDeleteModal}
                />
              ))}
              <PaginationComponent
                limit={limit}
                setLimit={setLimit}
                page={page}
                setPage={setPage}
                count={agentsCount}
              />
            </>
          ) : (
            <EmptyResults name='agent' />
          )}
        </div>
        <div className='d-none d-sm-block'>
          {loadingStatus === 'loading' || agentsCount === 0 ? (
            <AgentTable
              agents={agents}
              setSortBy={setSortBy}
              sortBy={sortBy}
              setOrderBy={setOrderBy}
              orderBy={orderBy}
            />
          ) : (
            <Table
              limit={limit}
              setLimit={setLimit}
              page={page}
              setPage={setPage}
              count={agentsCount}
              className='mybook-table'
              columns={[
                {
                  accessor: 'firstName',
                  resizable: true,
                  sortable: true,
                  minWidth: 150,
                  Header: () => (
                    <div
                      onClick={() => handleSort('firstName')}
                      style={{ cursor: 'pointer' }}
                      className='d-flex justify-content-between w-100'
                    >
                      <span>User</span>
                      <img
                        className='sort'
                        src={
                          sortBy !== 'firstName'
                            ? sort
                            : orderBy === 'asc'
                            ? sortdown
                            : sortup
                        }
                        alt='sort'
                      />
                    </div>
                  ),
                  Cell: ({ row }: { row: any }) => (
                    <div className='user-nameText row-name d-flex align-items-center text-overflow-container'>
                      <b
                        className={`${
                          row.original?.AgentStatus?.name === 'INACTIVE' &&
                          'InactiveHead'
                        }`}
                      >
                        {row.original.User?.firstName +
                          ' ' +
                          row.original.User?.lastName}
                      </b>
                      <span className='tooltiptext'>
                        <span className='tooltipInnertext'>
                          {row.original.User?.firstName ||
                          row.original.User?.lastName
                            ? row.original.User?.firstName +
                              ' ' +
                              row.original.User?.lastName
                            : '--'}
                        </span>
                      </span>
                    </div>
                  ),
                },
                {
                  accessor: 'role',
                  Header: 'Role',
                  resizable: true,
                  width: 150,
                  Cell: ({ row }: { row: any }) => (
                    <div className='d-flex align-items-center h-100'>
                      <div
                        className={
                          row.original?.User?.UserRole?.name ===
                          'PRINCIPAL_AGENT'
                            ? row.original?.AgentStatus?.name === 'ACTIVE'
                              ? 'status statusActive'
                              : 'status statusInactive'
                            : row.original?.AgentStatus?.name === 'ACTIVE'
                            ? 'status statusInvited'
                            : 'status statusInactive'
                        }
                      >
                        {row.original?.User?.UserRole?.name
                          .toLowerCase()
                          .split('_')
                          .map(
                            (word: string) =>
                              word[0].toUpperCase() + word.substring(1)
                          )
                          .join(' ')}
                      </div>
                    </div>
                  ),
                },
                {
                  accessor: 'createdAt',
                  resizable: true,
                  sortable: true,
                  minWidth: 130,
                  Header: () => (
                    <div
                      onClick={() => handleSort('createdAt')}
                      style={{ cursor: 'pointer' }}
                      className='d-flex justify-content-between w-100'
                    >
                      <span>Created date</span>
                      <img
                        className='sort'
                        src={
                          sortBy !== 'createdAt'
                            ? sort
                            : orderBy === 'asc'
                            ? sortdown
                            : sortup
                        }
                        alt='sort'
                      />
                    </div>
                  ),
                  Cell: ({ row }: { row: any }) => (
                    <Moment
                      className={`${
                        row.original?.AgentStatus?.name === 'INACTIVE' &&
                        'InactiveHead'
                      }`}
                      format='MM/DD/YYYY'
                    >
                      {row.original.createdAt}
                    </Moment>
                  ),
                },
                {
                  accessor: 'email',
                  Header: 'Email',
                  resizable: true,
                  minWidth: 150,
                  Cell: ({ row }: { row: any }) => (
                    <span
                      className={`${
                        row.original?.AgentStatus?.name === 'INACTIVE' &&
                        'InactiveHead'
                      }`}
                    >
                      {row.original?.User?.email}
                    </span>
                  ),
                },
                {
                  accessor: 'phone',
                  Header: 'Phone Number',
                  resizable: true,
                  minWidth: 130,
                  Cell: ({ row }: { row: any }) => (
                    <span
                      className={`${
                        row.original?.AgentStatus?.name === 'INACTIVE' &&
                        'InactiveHead'
                      }`}
                    >
                      {row.original?.User?.phoneNumber}
                    </span>
                  ),
                },
                {
                  accessor: 'actions',
                  Header: 'Actions',
                  sticky: 'right',
                  resizable: false,
                  width: window.innerWidth > 1200 ? 155 : 228,
                  maxWidth: 206,
                  Cell: ({ row }: { row: any }) => (
                    <div className='d-flex align-items-center justify-content-between h-100'>
                      <button
                        type='button'
                        disabled={user?.uuid === row?.original?.User?.uuid}
                        onClick={() => {
                          if (row.original?.AgentStatus?.name === 'ACTIVE') {
                            setDeActivate(true);
                            setDeActivatingUser(row.original);
                          } else if (
                            row.original?.AgentStatus?.name === 'INACTIVE'
                          ) {
                            updateAgentStatus(row.original.uuid, {
                              action: 'updateStatus',
                              status: 'ACTIVE',
                            });
                          }
                        }}
                        className={
                          row.original?.AgentStatus?.name === 'ACTIVE'
                            ? 'activeActionButton'
                            : 'disabledActionButton'
                        }
                      >
                        {row.original?.AgentStatus?.name === 'ACTIVE'
                          ? 'Deactivate'
                          : '  Activate'}
                      </button>
                      <ActionsDropdown
                        data={row.original}
                        setData={setEditData}
                        setShowEditModal={setEditUser}
                        deletingUser={row.original}
                        setDeletingUser={setDeletingUser}
                        setShowDeleteModal={setShowDeleteModal}
                      />
                    </div>
                  ),
                },
              ]}
              data={agents}
            />
          )}
          {/* <AgentTable
            agents={agents}
            setSortBy={(option: string) => {
              dispatch(updateStatus());
              setSortBy(option);
            }}
            setOrderBy={(option: string) => {
              dispatch(updateStatus());
              setOrderBy(option);
            }}
            sortBy={sortBy}
            orderBy={orderBy}
          /> */}
        </div>
      </div>
      <div className='d-xl-none'>
        <MobileNav />
      </div>
      {adduser !== 'closed' && (
        <AgentModal show={adduser} closeModal={setAddUser} />
      )}
      {edituser !== 'closed' && (
        <EditUser show={edituser} closeModal={setEditUser} data={editData} />
      )}
      {deActivate && deactivatingUser && (
        <DeactivateUser
          showModal={deActivate}
          closeModal={setDeActivate}
          desablingAgent={deactivatingUser}
        />
      )}
      {showDeleteModal !== 'closed' && (
        <DeleteUserModal
          showModal={showDeleteModal}
          closeModal={setShowDeleteModal}
          agents={agents}
          deletingUser={deletingUser}
          onDelete={onDelete}
          showSuccess={showSuccess}
          setShowSuccess={setShowSuccess}
          deleteErrorMessage={deleteErrorMessage}
          setDeleteErrorMessage={setDeleteErrorMessage}
        />
      )}

      <FilterContainer>
        {showFilter !== 'closed' && (
          <UserFilter
            show={showFilter}
            closeModal={setShowFilter}
            filterFunction={filterUser}
            userRole={userRole}
            setUserRole={setUserRole}
            setUserStatus={setUserStatus}
            userStatus={userStatus}
          />
        )}
      </FilterContainer>
    </>
  );
}

export default AgencyDetails;

const ActionsDropdown = ({
  data,
  setShowEditModal,
  setData,
  deletingUser,
  setDeletingUser,
  setShowDeleteModal,
}: {
  data: any;
  setShowEditModal: Function;
  setData: Function;
  deletingUser: any;
  setDeletingUser: Function;
  setShowDeleteModal: Function;
}) => {
  const [showActionsDropdown, setShowActionsDropdown] = useState(false);
  const dropRef = useRef<HTMLButtonElement>(null);

  return (
    <button
      className='vector d-flex align-items-center justify-content-center position-relative'
      onClick={() => {
        setShowActionsDropdown(!showActionsDropdown);
        if (dropRef.current) {
          dropRef.current.focus();
        }
      }}
      onBlur={() => setShowActionsDropdown(false)}
      ref={dropRef}
    >
      <img src={vector} alt='vector' />
      {showActionsDropdown && (
        <div className='dropdown-div'>
          <div
            className='edit-div'
            onClick={() => {
              setShowEditModal('open');
              setData(data);
              setShowActionsDropdown(false);
            }}
          >
            Edit <img src={edit} alt='bin' />
          </div>
          <div className='borderDiv'></div>
          <div
            className='delete-div'
            onClick={() => {
              setShowDeleteModal(true);
              setDeletingUser(deletingUser);
              setShowActionsDropdown(false);
            }}
          >
            Delete <img src={bin} alt='bin' />
          </div>
        </div>
      )}
    </button>
  );
};
