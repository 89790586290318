import DatePicker from 'react-datepicker';
import moment from 'moment';
import { PickerDiv } from './styles';
import calender from '../../assets/logo/calendar.svg';
import 'react-datepicker/dist/react-datepicker.css';
import { useEffect, useRef, useState } from 'react';
import TimePicker from '../TimePicker';
import { useOutsideClick } from '../../hooks/useOutsideClick';
import { YearMonthPicker } from '../DateField';

export interface IProps {
  name: string;
  placeholder: string;
  values: any;
  onSetItem: Function;
  multiselect?: boolean;
  setStartDate: Function;
  setEndDate: Function;
  endDate: any;
  startDate: any;
  [x: string]: any;
}
const DateField = ({
  name,
  placeholder,
  values,
  onSetItem,
  multiselect,
  setStartDate,
  setEndDate,
  endDate,
  startDate,
  ...props
}: IProps) => {
  const onChange = (dates: any) => {
    const [start, end] = dates;
    setStartDate(start);
    setEndDate(end);
  };

  const [showDateTimeModal, setShowDateTimeModal] = useState(false);
  const [TimeValue, setDateTimeValue] = useState('');
  const dropRef = useRef(null);
  useOutsideClick(dropRef, setShowDateTimeModal);
  useEffect(() => {
    if (TimeValue && !isNaN(values[name])) {
      let TimeChange = moment(
        moment(values[name]).format('YYYY-MM-DD') +
          moment(`${TimeValue}`).format('hh:mm aa'),
        'YYYY-MM-DDLT'
      ).toDate();
      onSetItem(name, TimeChange);
    } // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [TimeValue]);
  return (
    <div ref={dropRef}>
      <PickerDiv>
        <div
          className={`d-flex flex-column w-100 input-container position-relative ${
            (values[name] || (multiselect && startDate)) && 'filled'
          } `}
          onClick={() => setShowDateTimeModal(true)}
        >
          {multiselect ? (
            <DatePicker
              className='date-input'
              onChangeRaw={(e) => e.preventDefault()}
              selected={startDate}
              onChange={(date) => {
                onChange(date);
                onSetItem(name, date);
              }}
              startDate={startDate}
              endDate={endDate}
              {...props}
              selectsRange
            />
          ) : (
            <>
              <DatePicker
                className='date-input'
                onChangeRaw={(e) => e.preventDefault()}
                onChange={(date) => {
                  onSetItem(
                    name,
                    moment(
                      moment(date).format('YYYY-MM-DD') +
                        moment(`${values[name]}`).format('hh:mm aa'),
                      'YYYY-MM-DDLT'
                    ).toDate()
                  );
                }}
                selected={values[name] ? moment(values[name]).toDate() : null}
                // dateFormat={'MM/dd/yyyy'}
                {...props}
                showTimeSelect
                open={showDateTimeModal}
                timeFormat='hh:mm'
                timeIntervals={10}
                timeCaption='time'
                dateFormat='MMMM d  yyyy, hh:mm aa'
                renderCustomHeader={({
                  date,
                  prevYearButtonDisabled,
                  nextYearButtonDisabled,
                  decreaseYear,
                  increaseYear,
                  decreaseMonth,
                  increaseMonth,
                  prevMonthButtonDisabled,
                  nextMonthButtonDisabled,
                }) => (
                  <>
                    <YearMonthPicker
                      date={date}
                      prevMonthButtonDisabled={prevMonthButtonDisabled}
                      prevYearButtonDisabled={prevYearButtonDisabled}
                      nextMonthButtonDisabled={nextMonthButtonDisabled}
                      nextYearButtonDisabled={nextYearButtonDisabled}
                      decreaseMonth={decreaseMonth}
                      decreaseYear={decreaseYear}
                      increaseMonth={increaseMonth}
                      increaseYear={increaseYear}
                    />
                    <div className='Time-SelectorDiv'>
                      {showDateTimeModal && (
                        <TimePicker
                          innerTab={true}
                          setTaskDueTime={setShowDateTimeModal}
                          setDueTime={setDateTimeValue}
                          dueTime={TimeValue}
                        />
                      )}
                    </div>
                  </>
                )}
              />
            </>
          )}
          <label htmlFor={name} className='date-placeholder '>
            {placeholder}
          </label>

          <div className='CallenderImg'>
            <img src={calender} alt='Calender' />
          </div>
        </div>
      </PickerDiv>
    </div>
  );
};

export default DateField;
