import { useEffect, useRef, useState } from 'react';
import vector from '../../../assets/logo/dropdown.svg';
import arrow from '../../../assets/logo/arrow.svg';
import tick from '../../../assets/logo/blacktick.svg';
import settings from '../../../assets/logo/settings.svg';
import CONTACT from '../../../assets/logo/contact.svg';
import LEAD from '../../../assets/logo/lead.svg';
import TASK from '../../../assets/logo/TaskTab.svg';
import PIPELINE from '../../../assets/logo/manage.svg';
import POLICY from '../../../assets/logo/policy.svg';
import OPPORTUNITY from '../../../assets/logo/policy.svg';
import ACCOUNT from '../../../assets/logo/account.svg';
import { useNavigate } from 'react-router';
import { Notifications } from '../../../pages/Notifications/styles';
import { useOutsideClick } from '../../../hooks/useOutsideClick';
import {
  fetchNotifications,
  selectAllNotifications,
  NotificationSliceState,
  updatingNotification,
  clearNotification,
} from '../../../store/notifications/notificationsSlice';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch } from '../../../store';
import moment from 'moment';
import Skeleton from 'react-loading-skeleton';

const NotificationsDropdown = ({
  setShowNotificationDrop,
}: {
  setShowNotificationDrop: Function;
}) => {
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();
  const notificationRef = useRef(null);
  const [showNotificationAll, setShowNotificationAll] = useState(false);
  const [showDropDown, setShowDropDown] = useState(false);
  const [showReadDropDown, setShowReadDropDown] = useState(false);
  const [showReadDropDownUUID, setShowReadDropDownUUID] = useState('');
  const [showNotificationCount, setShowNotificationCount] = useState();

  const loadingStatus = useSelector(
    (state: { notifications: NotificationSliceState }) =>
      state.notifications.status
  );
  const notificationsCount = useSelector(
    (state: { notifications: NotificationSliceState }) =>
      state.notifications.count
  );
  const notificationssummaryCount = useSelector(
    (state: { notifications: NotificationSliceState }) =>
      state.notifications.summaryCount
  );

  useEffect(() => {
    dispatch(clearNotification());
    dispatch(
      fetchNotifications({
        limit: 12,
        offset: 0,
        unread: showNotificationAll ? undefined : true,
        successCB: (data: any) => {
          setShowNotificationCount(data.count);
        },
      })
    );
  }, [showNotificationAll]);

  const notifications = useSelector(selectAllNotifications);

  useEffect(() => {
    if (!showNotificationAll) {
      let unread = notifications?.filter((item: any) => item?.isRead === false);
      formatHistory(unread);
    } else {
      formatHistory(notifications);
    }
  }, [notifications, showNotificationAll]);

  const formatHistory = (notifications: any) => {
    const temp = notifications.reduce((data: any, currentData: any) => {
      let x: string | Date = new Date(currentData.createdAt);
      let currentDate = new Date();
      if (
        currentDate.getDate() === x.getDate() &&
        currentDate.getMonth() === x.getMonth() &&
        currentDate.getFullYear() === x.getFullYear()
      ) {
        x = `Today`;
      } else if (
        currentDate.getDate() - x.getDate() === 1 &&
        currentDate.getMonth() === x.getMonth() &&
        currentDate.getFullYear() === x.getFullYear()
      ) {
        x = `Yesterday`;
      } else {
        x = x.toLocaleString('default', {
          month: 'long',
          day: '2-digit',
          year: 'numeric',
        });
      }

      if (data[x]) {
        const currentHistory = data[x];
        currentHistory.push(currentData);
        data[x] = currentHistory;
      } else {
        data[x] = [currentData];
      }
      return data;
    }, {});
    setHistory({ history: temp, keys: Object.keys(temp) });
  };

  useOutsideClick(notificationRef, setShowNotificationDrop);

  const [history, setHistory] = useState<any>({ keys: [] });

  const NotificationUpdate = (action: string, uuid?: string) => {
    let data = { action: '', notificationUUID: '' };
    if (uuid && action === 'READONE') {
      data.action = action;
      data.notificationUUID = uuid;
    } else {
      data.action = action;
      data.notificationUUID = '';
    }
    dispatch(
      updatingNotification({
        data,
        successCB: (value: any) => {},
      })
    );
  };

  return (
    <div ref={notificationRef}>
      <Notifications>
        <div className='NotificationDropDown '>
          <div className='topNotificationContainer'>
            <div className='d-flex align-items-center justify-content-between'>
              <h2>Notifications</h2>
              <button
                className='vector d-flex align-items-center justify-content-center position-relative'
                onClick={() => setShowDropDown(!showDropDown)}
              >
                <img src={vector} alt='vector' />
                {showDropDown && (
                  <div className='NotificationDrop '>
                    <div
                      className='dropWord marginDiv d-flex justify-content-between align-items-center'
                      onClick={() => {
                        NotificationUpdate('READALL');
                      }}
                    >
                      <h6>Mark all as read</h6>
                      <img src={tick} alt='' className='ticklogo' />
                    </div>
                    <div
                      className='dropWord d-flex justify-content-between align-items-center'
                      onClick={() =>
                        navigate('/myprofile/notificationsettings')
                      }
                    >
                      <h6>Notification settings</h6>
                      <img src={settings} alt='' className='settinglogo' />
                    </div>
                  </div>
                )}
              </button>
            </div>
            <div className='togglebuttons d-flex align-items-center'>
              <button
                className={`naVigatorbuttons
                ${!showNotificationAll && 'naVigatorbuttonsActive'}`}
                type='button'
                onClick={() => setShowNotificationAll(false)}
              >
                Unread
                {!showNotificationAll && (
                  <span className='count'>
                    {loadingStatus === 'loading'
                      ? 0
                      : notificationssummaryCount}
                  </span>
                )}
              </button>
              <button
                className={` ${
                  showNotificationAll && 'naVigatorbuttonsActive'
                } naVigatorbuttons  secondButton`}
                type='button'
                onClick={() => setShowNotificationAll(true)}
              >
                All
                {showNotificationAll && (
                  <span className='count'>
                    {loadingStatus === 'loading'
                      ? 0
                      : notificationsCount
                      ? notificationsCount
                      : showNotificationCount}
                  </span>
                )}
              </button>
            </div>
            <div className='scrollContainer'>
              {loadingStatus === 'loading' ? (
                [1, 2, 3, 4, 5].map((item) => (
                  <div className='Skeleton'>
                    {' '}
                    <Skeleton height={74} key={item} />
                  </div>
                ))
              ) : history.keys.length > 0 ? (
                history.keys.map((item: any) => {
                  return (
                    <div key={item}>
                      <div>
                        <h6 className='dateField'>{item}</h6>
                      </div>
                      {history.history[item].map(
                        (value: any, index: number) => (
                          <div
                            className={`${
                              index === 0 && 'fistchild'
                            } container d-flex align-items-center  justify-content-between`}
                            onClick={() => {
                              if (value?.isRead === false) {
                                NotificationUpdate('READONE', value?.uuid);
                              }
                              navigate(value?.payload.url, {
                                state: value?.payload,
                              });
                            }}
                          >
                            <div className='d-flex align-items-center'>
                              <div className='image-container d-flex align-items-center justify-content-center'>
                                <img
                                  src={
                                    value?.ResourceType?.name === 'CONTACT'
                                      ? CONTACT
                                      : value?.ResourceType?.name === 'LEAD'
                                      ? LEAD
                                      : value?.ResourceType?.name === 'POLICY'
                                      ? POLICY
                                      : value?.ResourceType?.name ===
                                        'OPPORTUNITY'
                                      ? OPPORTUNITY
                                      : value?.ResourceType?.name === 'ACCOUNT'
                                      ? ACCOUNT
                                      : value?.ResourceType?.name === 'TASK'
                                      ? TASK
                                      : value?.ResourceType?.name === 'EVENT'
                                      ? TASK
                                      : value?.ResourceType?.name === 'PIPELINE'
                                      ? PIPELINE
                                      : ''
                                  }
                                  alt=''
                                  className='Iconimage'
                                />
                              </div>
                              <div>
                                <div className='d-flex align-items-center'>
                                  <h4 className='notificationTitle'>
                                    {value?.title}
                                  </h4>
                                  <span className='notificationTime'>
                                    {item !== 'Today'
                                      ? new Date(
                                          value.createdAt
                                        ).toLocaleString('en-US', {
                                          hour: 'numeric',
                                          hour12: true,
                                          minute: 'numeric',
                                        })
                                      : moment(value.createdAt).fromNow()}
                                  </span>
                                </div>
                                <span
                                  className='notificationSub'
                                  dangerouslySetInnerHTML={{
                                    __html: value?.description,
                                  }}
                                />
                              </div>
                            </div>
                            {value?.isRead === false && (
                              <div
                                className='unreadDot dropDown'
                                onClick={(e) => {
                                  e.stopPropagation();
                                  setShowReadDropDownUUID(value?.uuid);
                                  setShowReadDropDown(!showReadDropDown);
                                }}
                              >
                                <button
                                  className='vectorInside d-flex align-items-center justify-content-center position-relative'
                                  type='button'
                                >
                                  <img src={vector} alt='vector' />
                                </button>
                                {showReadDropDown &&
                                  showReadDropDownUUID === value?.uuid && (
                                    <div
                                      className='smallDropDown'
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        setShowReadDropDown(false);
                                        NotificationUpdate(
                                          'READONE',
                                          value?.uuid
                                        );
                                      }}
                                    >
                                      Mark as Read
                                    </div>
                                  )}
                              </div>
                            )}
                          </div>
                        )
                      )}
                    </div>
                  );
                })
              ) : (
                <div
                  className={`pt-5  mt-5 d-flex align-items-center justify-content-center`}
                  style={{ fontWeight: '600', fontSize: 18, opacity: 0.7 }}
                >
                  No Notification Available
                </div>
              )}
            </div>
          </div>
          <div className='shwBtnDiv d-flex justify-content-center'>
            <button
              className='showMoreButton  '
              onClick={() => navigate('/notification')}
            >
              Show all <img src={arrow} alt='' className='arowimg' />
            </button>
          </div>
        </div>
      </Notifications>
    </div>
  );
};

export default NotificationsDropdown;
