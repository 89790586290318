import { useEffect, useState } from 'react';
import { getOpportunityStatus } from '../../api/opportunities';
import FilterSelectField from '../FilterSelectField';
import { IOpportunityStatus, IPipeline } from '../../interfaces/Opportunity';
import { MobileFilterNavDiv } from './styles';
import cross from '../../assets/logo/cross.svg';
import { fetchProducts } from '../../api/product';
import { IProduct } from '../../interfaces/Product';

const FilterMobileModal = ({
  show,
  closeModal,
  selectedOppStatus,
  setProducts,
  setOppStatus,
  selectedProducts,
  setActive,
  selectedActive,
  pipelineManage,
  pipelineStages,
}: {
  show: boolean;
  closeModal: Function;
  setProducts: Function;
  setOppStatus: Function;
  selectedProducts: any;
  selectedOppStatus: any;
  setActive: Function;
  selectedActive: string;
  pipelineManage?: boolean;
  pipelineStages?: IPipeline;
}) => {
  const [productCategory, setProductCategory] = useState<IProduct[]>([]);
  const [status, setStatus] = useState<IOpportunityStatus[]>([]);

  const onCloseModal = () => {
    closeModal(false);
    setTimeout(() => {
      closeModal(false);
    }, 300);
  };
  useEffect(() => {
    getOpportunityStatus((data: IOpportunityStatus[]) => setStatus(data));
  }, []);

  useEffect(() => {
    fetchProducts(
      (data: IProduct[]) => setProductCategory(data),
      '',
      '',
      '',
      '',
      true
    );
  }, []);

  const active = [
    {
      label: 'Active',
      value: 'active',
    },
    {
      label: 'Inactive',
      value: 'inactive',
    },
  ];

  return (
    <MobileFilterNavDiv>
      <div className='backgroundQuickAdd'>
        <div className={`quickFormsDropDown closed`}>
          <div className='d-flex  drawerHandle'></div>
          <h2>Filters</h2>
          <button className='crossbutton' onClick={() => onCloseModal()}>
            <img src={cross} className='cross' alt='cross-icon' />
          </button>
          {!pipelineManage ? (
            <>
              <div>
                <h6 className='head'>STAGES</h6>
              </div>
              {pipelineStages && (
                <div className='agency-fields'>
                  <FilterSelectField
                    options={pipelineStages?.PipelineStages?.map((item) => {
                      return { label: item.name, value: item.uuid };
                    })}
                    placeholder='Select'
                    onChange={(value: any) => {
                      setOppStatus(value);
                    }}
                    isMulti={true}
                    value={selectedOppStatus}
                  />
                </div>
              )}
              <hr className='filter-hr' />
            </>
          ) : null}

          <div>
            <h6 className='head'>POLICY</h6>
          </div>
          <div className='agency-fields'>
            <FilterSelectField
              options={productCategory.map((item) => {
                return { label: item.name, value: item.uuid };
              })}
              placeholder='Select'
              isMulti={true}
              onChange={(value: any) => {
                setProducts(value);
              }}
              value={selectedProducts}
            />
          </div>
          {pipelineManage ? (
            <>
              <hr className='filter-hr' />
              <div>
                <h6 className='head'>Active</h6>
              </div>
              <div className='agency-fields'>
                <FilterSelectField
                  options={active}
                  name='active'
                  placeholder='Select'
                  onChange={(value: any) => {
                    setActive(value);
                  }}
                  value={selectedActive}
                />
              </div>
            </>
          ) : null}
          <hr className='filter-hr' />
          <div className='buttonsDiv d-flex align-items-center justify-content-between'>
            <div className='filter-count'>
              {selectedOppStatus.length +
                selectedProducts.length +
                (selectedActive ? 1 : 0)}{' '}
              filters added
            </div>
            <button
              type='submit'
              className='agentButton justify-content-end'
              onClick={() => {
                setOppStatus([]);
                setProducts([]);
                setActive('');
              }}
            >
              Clear all filters
            </button>
          </div>
        </div>
      </div>
    </MobileFilterNavDiv>
  );
};

export default FilterMobileModal;
