import { useState } from 'react';
import Moment from 'react-moment';
import { useNavigate } from 'react-router-dom';
import { CardDiv } from '../../../../styles/CardDiv';
import vector from '../../../../assets/logo/dropdown.svg';
import edit from '../../../../assets/logo/edit.svg';
import phone from '../../../../assets/logo/phone.svg';
import message from '../../../../assets/logo/message-action.svg';
import mail from '../../../../assets/logo/mail.svg';
import bin from '../../../../assets/logo/bin.svg';
import { IContact } from '../../../../interfaces/Contact';
import { SuccessAlign } from '../../../../styles/SuccessAlign';
import SuccessCard from '../../../../components/SuccessCard';
import cross from '../../../../assets/logo/cross.svg';
import EditContactDetails from '../../../ContactDetails/EditContactDetails';

const ContactCard = ({ contact }: { contact: IContact }) => {
  const [showDropdown, setShowDropdown] = useState(false);
  const [showEditModal, setShowEditModal] = useState('closed');
  const [emailCopied, isEmailCopied] = useState(false);

  const navigate = useNavigate();

  const handleCopy = (e: any, value: string) => {
    e.preventDefault();
    navigator.clipboard.writeText(value);
    isEmailCopied(true);
    setTimeout(() => {
      isEmailCopied(false);
    }, 2000);
  };

  return (
    <CardDiv>
      <td className='row-name name-td'>
        <input type='checkbox' name='remember' className='form-check-input' />
        <b
          onClick={() => {
            navigate(`/contacts/${contact.uuid}`);
          }}
        >
          {contact.firstName ? contact.firstName + ' ' + contact.lastName : '-'}
        </b>
      </td>
      <td>
        {contact?.AccountContacts[0]?.Account?.AccountType?.name.charAt(0) +
          contact?.AccountContacts[0]?.Account?.AccountType?.name
            .slice(1)
            .toLowerCase()}
      </td>
      <td>
        <Moment format='MM/DD/YYYY'>{contact.createdAt}</Moment>
      </td>
      <td
        className='txt'
        style={{ cursor: 'pointer' }}
        onClick={(event) => {
          handleCopy(
            event,
            `${
              contact?.ContactDetails?.find((item) => item.type === 'EMAIL')
                ?.value
            }`
          );
        }}
      >
        {contact?.ContactDetails?.find((item) => item.type === 'EMAIL')?.value}
      </td>
      <td
        style={{ cursor: 'pointer' }}
        onClick={(event) =>
          handleCopy(
            event,
            `${
              contact?.ContactDetails?.find(
                (item) => item.type === 'PHONENUMBER'
              )?.value
            }`
          )
        }
        className='d-none d-xl-table-cell'
      >
        {
          contact?.ContactDetails?.find((item) => item.type === 'PHONENUMBER')
            ?.value
        }
      </td>
      <td className='actionField'>
        <div className='d-flex align-items-center justify-content-between'>
          <div className='action-item'>
            <img src={phone} alt='phone' />
          </div>
          <div className='action-item'>
            <img src={mail} alt='mail' />
          </div>
          <div className='action-item'>
            <img src={message} alt='message' />
          </div>
          <div
            className='vector'
            onClick={() => setShowDropdown(!showDropdown)}
          >
            <img src={vector} alt='vector' />
          </div>
          {showDropdown && (
            <div className='dropdown-div'>
              <div
                className='edit-div'
                onClick={() => {
                  setShowEditModal('open');
                  setShowDropdown(false);
                }}
              >
                Edit <img src={edit} alt='bin' />
              </div>
              <div className='delete-div'>
                Delete <img src={bin} alt='bin' />
              </div>
            </div>
          )}
        </div>
        {showEditModal !== 'closed' && (
          <EditContactDetails
            show={showEditModal}
            closeModal={setShowEditModal}
            uuid={contact.uuid}
          />
        )}
        {emailCopied && (
          <SuccessAlign>
            <div
              className={`success-container ${emailCopied && 'open-drawer'} `}
            >
              <SuccessCard
                title={'Success Copying Email'}
                message={`Email has been added to clipboard<b>`}
              />
              <button
                onClick={() => {
                  isEmailCopied(false);
                }}
                className='crossbutton'
              >
                <img src={cross} className='cross' alt='cross-icon' />
              </button>
            </div>
          </SuccessAlign>
        )}
      </td>
    </CardDiv>
  );
};

export default ContactCard;
