import sort from '../../../assets/logo/sort.svg';
import sortup from '../../../assets/logo/sortup.svg';
import sortdown from '../../../assets/logo/sortdown.svg';
import { useSelector } from 'react-redux';
import { TableDiv } from '../../../styles/TableDiv';
import EmptyResults from '../../MyBook/components/Empty';
import LoadingSkeleton from '../../MyBook/components/Loading';
import { AgentsSliceState } from '../../../store/agents/agentsSlice';
import { IAgent } from '../../../interfaces/User';

const TeamTableSkeleton = ({
  agents,
  setSortBy,
  setOrderBy,
  sortBy,
  orderBy,
}: {
  agents: IAgent[];
  setSortBy: Function;
  setOrderBy: Function;
  sortBy: string;
  orderBy: string;
}) => {
  const handleSort = (sort: string) => {
    if (sort === sortBy) {
      if (orderBy === 'asc') {
        setOrderBy('desc');
      } else {
        setOrderBy('asc');
      }
    } else {
      setSortBy(sort);
    }
  };
  const loadingStatus = useSelector(
    (state: { agents: AgentsSliceState }) => state.agents?.status
  );
  return (
    <TableDiv>
      <thead>
        <tr>
          <th className='agencyNameHead'>
            <div className='d-flex'>
              <div className='w-75 d-flex justify-content-between'>
                User Name
                <img
                  className='sort'
                  src={
                    sortBy !== 'firstName'
                      ? sort
                      : orderBy === 'asc'
                      ? sortdown
                      : sortup
                  }
                  alt='sort'
                  onClick={() => handleSort('firstName')}
                />
              </div>
            </div>
          </th>
          <th className='statusContainerHead'>Status</th>
          <th className='statusContainerHead'> No.of referral partners</th>

          <th className='dateFieldHead'>
            <div className=' d-flex justify-content-between '>
              <span>Pl.premium</span>
              <img
                className='sort'
                src={
                  sortBy !== 'createdAt'
                    ? sort
                    : orderBy === 'asc'
                    ? sortdown
                    : sortup
                }
                alt='sort'
                onClick={() => handleSort('createdAt')}
              />
            </div>
          </th>
          <th className='leadsEmailFieldHead d-none d-xl-table-cell'>
            Policy goal
          </th>
          <th className='leadsPhoneFieldHead d-none d-xl-table-cell'>
            Premium goal{' '}
          </th>
          <th className='leadActionsFieldHead'>Actions</th>
        </tr>
      </thead>
      <tbody>
        {loadingStatus === 'loading' && <LoadingSkeleton columns={7} />}
        {loadingStatus === 'succeeded' && agents.length === 0 && (
          <tr className='empty-div'>
            <td colSpan={5} className='d-xl-none'>
              <EmptyResults name='agent' />
            </td>
            <td colSpan={7} className='d-none d-xl-table-cell'>
              <EmptyResults name='agent' />
            </td>
          </tr>
        )}
        {loadingStatus === 'failed' && (
          <tr className='empty-div'>
            <td colSpan={5} className='d-xl-none'>
              <EmptyResults name='agent' />
            </td>
            <td colSpan={7} className='d-none d-xl-table-cell'>
              <EmptyResults name='agent' />
            </td>
          </tr>
        )}
      </tbody>
    </TableDiv>
  );
};

export default TeamTableSkeleton;
