import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import {
  addQuote,
  getQuotes,
  updateQuoteDetails,
  getQuoteDetails,
  deleteQuote,
} from '../../api/quote';
import { IQuote } from '../../interfaces/Quote';

export type QuoteSliceState = {
  status: string;
  quotes: IQuote[];
  quoteDetails?: IQuote;
  isLoading: boolean;
};

const initialState: QuoteSliceState = {
  status: 'idle',
  quotes: [],
  isLoading: false,
};

export const createQuote = createAsyncThunk(
  'quotes/createQuote',
  async ({
    data,
    successCB,
    errorCB,
  }: {
    data: any;
    successCB: Function;
    errorCB: Function;
  }) => {
    const response = await addQuote(data, successCB, errorCB);
    return response.data;
  }
);

export const fetchQuotes = createAsyncThunk(
  'quotes/fetchQuotes',
  async ({
    opportunityUUID,
    successCB,
    sortBy,
    orderBy,
    searchBy,
    limit,
    offset,
    getQuote,
    quoteTypeUUID,
    quoteStageUUID,
    quoteStatusUUID,
    coverageTypeUUID,
  }: {
    opportunityUUID: string;
    successCB: Function;
    sortBy: string;
    orderBy: string;
    searchBy: string;
    limit: number;
    offset: number;
    getQuote?: string;
    quoteTypeUUID?: string;
    quoteStageUUID?: string[];
    quoteStatusUUID?: string;
    coverageTypeUUID?: string[];
  }) => {
    const response = await getQuotes(
      opportunityUUID,
      successCB,
      sortBy,
      orderBy,
      searchBy,
      limit,
      offset,
      getQuote,
      quoteTypeUUID,
      quoteStageUUID,
      quoteStatusUUID,
      coverageTypeUUID
    );
    if (successCB) {
      successCB(response);
    }
    return response.data;
  }
);

export const onUpdateQuotes = createAsyncThunk(
  'quotes/onUpdateQuotes',
  async ({
    data,
    successCB,
    errorCB,
    uuid,
  }: {
    data: IQuote;
    successCB: Function;
    errorCB: Function;
    uuid: string;
  }) => {
    const response = await updateQuoteDetails(data, uuid, successCB, errorCB);
    successCB(response.data);
    return response.data;
  }
);

export const onUpdateQuotesStage = createAsyncThunk(
  'quotes/onUpdateQuotesStage',
  async ({
    data,
    successCB,
    errorCB,
    uuid,
  }: {
    data: any;
    successCB: Function;
    errorCB: Function;
    uuid: string;
  }) => {
    const response = await updateQuoteDetails(data, uuid, successCB, errorCB);
    successCB(response.data);
    return response.data;
  }
);

export const fetchQuoteDetails = createAsyncThunk(
  'quotes/fetchQuoteDetails',
  async ({ uuid, successCB }: { uuid?: string; successCB?: Function }) => {
    const response = await getQuoteDetails(uuid);
    if (successCB) {
      successCB(response.data);
    }
    return response.data;
  }
);

export const onDeleteQuote = createAsyncThunk(
  'quotes/onDeleteQuote',
  async ({ uuid, successCB }: { uuid: string; successCB: Function }) => {
    const response = await deleteQuote(uuid);
    successCB();
    return response.data;
  }
);

const quotesSlice = createSlice({
  name: 'quotes',
  initialState,
  reducers: {
    updateStatus: (state) => {
      state.status = 'idle';
      state.isLoading = false;
    },
    clearQuotes: (state) => {
      state.quotes = [];
    },
  },
  extraReducers(builder) {
    builder
      .addCase(fetchQuotes.pending, (state, action) => {
        state.status = 'loading';
      })
      .addCase(fetchQuotes.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.quotes = action.payload.quotes;
      })
      .addCase(fetchQuotes.rejected, (state, action) => {
        state.status = 'failed';
      })
      .addCase(fetchQuoteDetails.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(fetchQuoteDetails.fulfilled, (state, action) => {
        state.isLoading = false;
        state.quoteDetails = action.payload;
      })
      .addCase(createQuote.fulfilled, (state, action) => {
        state.isLoading = false;
        state.quotes.unshift(action.payload);
      })
      .addCase(createQuote.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(createQuote.rejected, (state, action) => {
        state.isLoading = false;
      })
      .addCase(onUpdateQuotes.fulfilled, (state, action) => {
        const index = state.quotes.findIndex(
          (item: IQuote) => item.uuid === action.payload.uuid
        );
        state.quotes[index] = action.payload;
        state.quoteDetails = action.payload;
        state.isLoading = false;
      })
      .addCase(onUpdateQuotesStage.fulfilled, (state, action) => {
        const index = state.quotes.findIndex(
          (item: IQuote) => item.uuid === action.payload.uuid
        );
        state.quotes[index].QuoteStage = action.payload.QuoteStage;
        state.isLoading = false;
      })
      .addCase(onDeleteQuote.pending, (state, action) => {
        state.status = 'loading';
      })
      .addCase(onDeleteQuote.fulfilled, (state, action) => {
        state.status = 'succeeded';
        const index = state.quotes.findIndex(
          (item) => item.uuid === action.payload.uuid
        );
        state.quotes.splice(index, 1);
      })
      .addCase(onDeleteQuote.rejected, (state, action) => {
        state.status = 'failed';
      });
  },
});

export const { updateStatus, clearQuotes } = quotesSlice.actions;

export default quotesSlice.reducer;

export const selectAllQuotes = (state: { quotes: QuoteSliceState }) =>
  state.quotes?.quotes;

export const selectQuote = (state: { quotes: QuoteSliceState }) =>
  state.quotes?.quoteDetails;
