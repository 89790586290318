import { useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { CardDiv } from '../../../../styles/CardDiv';
import vector from '../../../../assets/logo/dropdown.svg';
import bin from '../../../../assets/logo/bin.svg';
import edit from '../../../../assets/logo/edit.svg';
import { useOutsideClick } from '../../../../hooks/useOutsideClick';
import { IOpportunity } from '../../../../interfaces/Opportunity';
import EditOpportunityDetails from './../../../OpportunityDetails/EditOpportunityDetails';

const OpportunityCard = ({ opportunity }: { opportunity: IOpportunity }) => {
  const [showDropdown, setShowDropdown] = useState(false);
  const [showEditModal, setShowEditModal] = useState('closed');
  const navigate = useNavigate();

  const dropRef = useRef(null);

  useOutsideClick(dropRef, setShowDropdown);

  return (
    <CardDiv>
      <td className='row-name name-td'>
        <input type='checkbox' name='remember' className='form-check-input' />
        <b
          onClick={() => {
            navigate(`/opportunities/${opportunity.uuid}`);
          }}
        >
          {opportunity.name ? opportunity.name : '-'}
        </b>
      </td>
      <td>{opportunity.Account.name}</td>
      <td>
        {opportunity?.Account.AccountType.name.charAt(0) +
          opportunity?.Account.AccountType.name.slice(1).toLowerCase()}
      </td>

      <td className='d-none d-xl-table-cell'>
        <div>
          {opportunity.OpportunityStatus.name.charAt(0).toUpperCase() +
            opportunity.OpportunityStatus.name.slice(1)}
        </div>
      </td>
      <td className='d-none d-xl-table-cell'>
        {opportunity.Agent?.User.avatar ? (
          <img
            alt='profilepic'
            className='user-pic'
            src={opportunity.Agent?.User.avatar}
          />
        ) : (
          <div className='user-pic'>
            {opportunity.Agent.User.firstName?.substring(0, 1).toUpperCase()}
            {opportunity.Agent.User.lastName?.substring(0, 1).toUpperCase()}
          </div>
        )}
      </td>

      <td style={{ fontWeight: 600 }}>
        ${opportunity.premium ? opportunity.premium : 0}
      </td>
      <td className='actionField'>
        <div className='d-flex align-items-center justify-content-between'>
          <div className='actionButton'>Start Quoting</div>
          <div
            className='vector'
            onClick={() => setShowDropdown(!showDropdown)}
            ref={dropRef}
          >
            <img src={vector} alt='vector' />
          </div>

          {showDropdown && (
            <div className='dropdown-div'>
              <div
                className='edit-div'
                onClick={() => {
                  setShowEditModal('open');
                  setShowDropdown(false);
                }}
              >
                Edit <img src={edit} alt='bin' />
              </div>
              <div className='delete-div'>
                Delete <img src={bin} alt='bin' />
              </div>
            </div>
          )}
        </div>
        {showEditModal !== 'closed' && (
          <EditOpportunityDetails
            show={showEditModal}
            closeModal={setShowEditModal}
            UUID={opportunity.uuid}
          />
        )}
      </td>
    </CardDiv>
  );
};

export default OpportunityCard;
