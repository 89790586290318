import React from 'react';
import Moment from 'react-moment';
import { useNavigate } from 'react-router-dom';
import { CardDiv } from '../../../../styles/CardDiv';
import vector from '../../../../assets/logo/dropdown.svg';
import { IAgency } from '../../../../interfaces/Agency';

function AgencyCard({ agency }: { agency: IAgency }) {
  const navigate = useNavigate();

  return (
    <CardDiv>
      <td className='agencyName name-td'>
        <input type='checkbox' className='form-check-input' />
        <b>{agency.name}</b>
      </td>
      <td className='statusContainer'>
        <div
          className={`status status${
            agency.status ? agency.status : 'Invited'
          }`}
        >
          {agency.status ? agency.status : 'Invited'}
        </div>
      </td>
      <td className='dateField'>
        <Moment format='MM/DD/YYYY'>{agency.createdAt}</Moment>
      </td>
      <td className='userGroupField'>
        <div className='userGroup'>
          {agency.AgencySubGroups?.AgencyGroup?.name}
        </div>
        <div className='subGroup'>{agency.AgencySubGroups?.name}</div>
      </td>
      <td className='usersField d-none d-xl-table-cell'>
        {agency.Agents?.length > 0 && (
          <div className='d-flex align-items-center'>
            {agency.Agents[0]?.User.avatar ? (
              <img
                src={agency.Agents[0]?.User.avatar}
                alt=''
                className='user-pic'
              />
            ) : (
              <div className='user-pic'>
                {agency.Agents[0]?.User.firstName.substring(0, 1).toUpperCase()}
                {agency.Agents[0]?.User.lastName.substring(0, 1).toUpperCase()}
              </div>
            )}
            {agency.Agents.length > 1 && (
              <div className='count-div'>+{agency.Agents.length - 1}</div>
            )}
          </div>
        )}
      </td>
      <td className='actionField'>
        <div className='d-flex align-items-center justify-content-between'>
          <div
            className='actionButton'
            onClick={(e) => navigate(`/admin/agency/${agency.uuid}`)}
          >
            View Users
          </div>
          <div className='vector'>
            <img src={vector} alt='vector' />
          </div>
        </div>
      </td>
    </CardDiv>
  );
}

export default AgencyCard;
