import { useMemo } from 'react';
import { useTable, useResizeColumns, useFlexLayout } from 'react-table';
import { useSticky } from 'react-table-sticky';
import { StyledTable } from './styles';
import {
  DragDropContext,
  Draggable,
  Droppable,
  DropResult,
} from 'react-beautiful-dnd';

function TableMovable({
  columns,
  data,
  className,
  updateTable,
}: {
  columns: any;
  data: any;
  className?: string;
  updateTable: Function;
}) {
  const defaultColumn = useMemo(
    () => ({
      minWidth: 80,
      width: 150,
      maxWidth: 400,
    }),
    []
  );

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable(
      {
        columns,
        data,
        defaultColumn,
      },
      useFlexLayout,
      useResizeColumns,
      useSticky
    );

  const headerProps = (props: any, { column }: { column: any }) =>
    getStyles(props, column.align);

  const getStyles = (props: any, align = 'left') => [
    props,
    {
      style: {
        justifyContent: align === 'right' ? 'flex-end' : 'flex-start',
        alignItems: 'flex-start',
        display: 'flex',
      },
    },
  ];

  const onDragEnd = ({ destination, source }: DropResult) => {
    if (!destination) {
      return;
    } else {
      const newData = [...data];
      const [movedRow] = newData.splice(source.index, 1);
      newData.splice(destination.index, 0, movedRow);
      newData.map((el: any, ind: number) => {
        let singleData = { ...el };
        singleData.order = ind;
        newData[ind] = singleData;
      });
      updateTable(movedRow.uuid, newData);
      return;
    }
  };

  return (
    <StyledTable>
      <div
        {...getTableProps()}
        className={`table sticky ${className}`}
        style={{ minWidth: 576 }}
      >
        <div className='thead header'>
          {headerGroups.map((headerGroup) => (
            <div {...headerGroup.getHeaderGroupProps()} className='tr'>
              {headerGroup.headers.map((column: any) => (
                <div
                  {...column.getHeaderProps(headerProps)}
                  className={`th ${column.headerCellClass}`}
                >
                  {column.render('Header')}
                  {column.canResize && column.Header !== 'Acions' && (
                    <div
                      {...column.getResizerProps()}
                      className={`resizer ${
                        column.isResizing ? 'isResizing' : ''
                      }`}
                    />
                  )}
                </div>
              ))}
            </div>
          ))}
        </div>
        <DragDropContext onDragEnd={(result) => onDragEnd(result)}>
          <Droppable droppableId='table-body'>
            {(provided, snapshot) => (
              <div {...getTableBodyProps()} className='body tbody'>
                <>
                  <div ref={provided.innerRef} {...provided.droppableProps}>
                    {rows.map((row: any, i: number) => {
                      prepareRow(row);
                      return (
                        <Draggable
                          draggableId={row.original.uuid}
                          key={row.original.uuid}
                          index={row.index}
                        >
                          {(provided, snapshot) => {
                            return (
                              <div
                                {...row.getRowProps()}
                                className='tr d-flex'
                                {...provided.draggableProps}
                                {...provided.dragHandleProps}
                                ref={provided.innerRef}
                                // isdragging={snapshot.isDragging}
                              >
                                {row.cells.map((cell: any) => {
                                  return (
                                    <div
                                      {...cell.getCellProps()}
                                      className={
                                        cell.column.Header === 'Actions'
                                          ? 'td actions'
                                          : `td ${cell.column.cellClass}`
                                      }
                                    >
                                      {cell.render('Cell')}
                                    </div>
                                  );
                                })}
                              </div>
                            );
                          }}
                        </Draggable>
                      );
                    })}
                  </div>
                  {provided.placeholder}
                </>
              </div>
            )}
          </Droppable>
        </DragDropContext>
      </div>
    </StyledTable>
  );
}

export default TableMovable;
