import { useRef, useState } from 'react';
import { TaskCardStyle } from '../elements/styles';
import phone from '../../../assets/logo/phone.svg';
import blacktick from '../../../assets/logo/blacktick.svg';
import vector from '../../../assets/logo/dropdown.svg';
import { AppDispatch } from '../../../store';
import moment from 'moment';
import {
  deleteATask,
  getAllActivtiesCount,
  updateTaskDetails,
} from '../../../store/tasks/tasksSlice';
import { useDispatch } from 'react-redux';
import edit from '../../../assets/logo/edit.svg';
import bin from '../../../assets/logo/bin.svg';
import { useOutsideClick } from '../../../hooks/useOutsideClick';
const TaskCard = ({
  Data,
  setUUID,
  setTaskDrawer,
  setShowSuccess,
  setMarkAsDone,
  setDelete,
  setAction,
}: {
  Data: any;
  setUUID: Function;
  setTaskDrawer: Function;
  setShowSuccess: Function;
  setMarkAsDone: Function;
  setDelete: Function;
  setAction: Function;
}) => {
  const dispatch = useDispatch<AppDispatch>();

  const [showActionsDropdown, setShowActionsDropdown] = useState(false);
  const [showActionsMobileDropdown, setShowActionsMobileDropdown] =
    useState(false);
  const dropRef = useRef(null);
  useOutsideClick(dropRef, setShowActionsDropdown);
  const dropMobileRef = useRef(null);
  useOutsideClick(dropMobileRef, setShowActionsMobileDropdown);
  const deleteTask = (uuid: string) => {
    dispatch(
      deleteATask({
        successCB: () => {
          setAction(true);
          dispatch(getAllActivtiesCount({}));
          setDelete(true);
          setShowSuccess(true);
          setTimeout(() => {
            setShowSuccess(false);
            setDelete(false);
          }, 2000);
        },
        uuid,
      })
    );
  };
  const markingAsDone = () => {
    dispatch(
      updateTaskDetails({
        uuid: Data.uuid,
        data: { action: 'MARK_AS_DONE' },
        successCB: () => {
          setAction(true);
          dispatch(getAllActivtiesCount({}));
          setMarkAsDone(true);
          setShowSuccess(true);
          setTimeout(() => {
            setShowSuccess(false);
            setMarkAsDone(false);
          }, 2000);
        },
      })
    );
  };

  return (
    <TaskCardStyle>
      <div className='task-Card d-block d-sm-flex justify-content-between'>
        <div className='taskIndicator'></div>
        <div className='d-flex col-4 align-items-center '>
          <div className='typeImageContainer d-flex align-items-center justify-content-center'>
            <img src={phone} alt='' />
          </div>
          <div
            className='textContainer '
            onClick={() => {
              setTaskDrawer('open');
              setUUID(Data?.uuid);
            }}
          >
            <div className='d-flex align-items-center justify-content-between justify-content-sm-start  '>
              <h2 className='title taskTitle'>
                Task &nbsp;- &nbsp;{Data?.TaskType?.name}
              </h2>
              <h2
                className={` priorityDiv ${
                  Data?.TaskPriority?.name === 'LOW'
                    ? 'lowPriority'
                    : Data?.TaskPriority?.name === 'MEDIUM'
                    ? 'mediumPriority'
                    : 'highPriority'
                }`}
              >
                {Data?.TaskPriority?.name.toLowerCase()}
              </h2>
            </div>
            <div className='text-overflow-container'>
              <span className='d-none d-sm-flex descriptionSpanTask '>
                {Data?.text}
              </span>
              <span className='tooltiptext'>
                <span className='tooltipInnertext'>
                  {Data?.text ? Data?.text : '--'}
                </span>
              </span>
            </div>
          </div>
        </div>
        <span className='d-sm-none  '>{Data?.text}</span>
        <div className='valueContainer d-none d-xl-block'>
          <h2 className='titleSub'>Linked to</h2>
          <span className='valueText '>
            {Data?.ContactId
              ? Data?.Contact?.firstName + ' ' + Data?.Contact?.lastName
              : Data?.AccountId
              ? Data?.Account?.name
              : Data?.OpportunityId
              ? Data?.Opportunity?.name
              : Data?.PolicyId
              ? Data.Policy?.Accounts?.name
              : Data.Lead?.data?.contact?.name}
          </span>
        </div>
        <div className='d-none d-sm-block valueContainer'>
          <h2 className='titleSub'>Due on</h2>
          <span className='valueText'>
            {moment(Data?.dueDate).format('h:mm A')}
          </span>
        </div>
        <div className='d-flex d-sm-none valueContainerMobile'>
          <h2 className='titleSubMobile'>Due on</h2>
          <span className='valueTextMobile'>
            {moment(Data?.dueDate).format('h:mm A')}
          </span>
        </div>
        <div className='d-none d-sm-block valueContainer prifleContainer'>
          <span className='valueText'>
            <div className='profile-pic d-flex align-items-center justify-content-center'>
              {Data?.Assignee?.firstName?.charAt(0)}{' '}
              {Data?.Assignee?.lastName?.charAt(0)}
            </div>
          </span>
        </div>
        <div className='d-none d-sm-block valueContainerIcons  '>
          <span className='valueText d-flex align-items-center justify-content-end'>
            {/* <div className='PhoneContainer  d-flex align-items-center justify-content-center '>
              <img src={phone} alt='' />
            </div> */}
            <div
              className='CompleteContainer  d-flex align-items-center justify-content-center ms-3'
              onClick={() => markingAsDone()}
            >
              <img src={blacktick} alt='' />
            </div>
            <div
              className='CompleteContainer  vectorColor  d-flex align-items-center justify-content-center ms-3 '
              onClick={() => setShowActionsDropdown(!showActionsDropdown)}
              ref={dropRef}
            >
              <img src={vector} alt='' />
              {showActionsDropdown && (
                <div className='dropdown-div'>
                  <div
                    className='edit-div'
                    onClick={() => {
                      setTaskDrawer('open');
                      setUUID(Data?.uuid);
                    }}
                  >
                    Edit <img src={edit} alt='bin' />
                  </div>
                  <div className='borderDiv'></div>
                  <div
                    className='delete-div'
                    onClick={() => {
                      deleteTask(Data.uuid);
                    }}
                  >
                    Delete <img src={bin} alt='bin' />
                  </div>
                </div>
              )}
            </div>
          </span>
        </div>
        <div className='d-flex d-sm-none valueContainerM0bile pb-2 justify-content-between w-100  '>
          <div className=' d-sm-none valueContainer prifleContainer'>
            <span className='d-flex profileText'>
              <div className='profile-pic d-flex align-items-center justify-content-center'>
                {Data?.Assignee?.firstName?.charAt(0)}{' '}
                {Data?.Assignee?.lastName?.charAt(0)}
              </div>
            </span>
          </div>
          <span className='valueTextMobile d-flex align-items-center'>
            {/* <div className='PhoneContainer  d-flex align-items-center justify-content-center '>
              <img src={phone} alt='' />
            </div> */}
            <div
              className='CompleteContainer  d-flex align-items-center justify-content-center ms-3'
              onClick={() => markingAsDone()}
            >
              <img src={blacktick} alt='' />
            </div>
            <div
              className='CompleteContainer vectorColor position-relative  d-flex align-items-center justify-content-center ms-3'
              onClick={() =>
                setShowActionsMobileDropdown(!showActionsMobileDropdown)
              }
              ref={dropMobileRef}
            >
              <img src={vector} alt='' />
              {showActionsMobileDropdown && (
                <div className='dropdown-div'>
                  <div
                    className='edit-div'
                    onClick={() => {
                      setTaskDrawer('open');
                      setUUID(Data?.uuid);
                    }}
                  >
                    Edit <img src={edit} alt='bin' />
                  </div>
                  <div className='borderDiv'></div>
                  <div
                    className='delete-div'
                    onClick={() => {
                      deleteTask(Data.uuid);
                    }}
                  >
                    Delete <img src={bin} alt='bin' />
                  </div>
                </div>
              )}
            </div>
          </span>
        </div>
      </div>
    </TaskCardStyle>
  );
};

export default TaskCard;
