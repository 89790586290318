import styled from '@emotion/styled';

export const Goal = styled.div`
  width: 200px;
  height: 200px;
  margin: 0 auto 15px;

  // custom.css
  .CircularProgressbar-path {
    stroke: #58c070;
  }
  .CircularProgressbar-trail {
    stroke: #f5f7fc;
  }
  .CircularProgressbar-text {
    fill: #262758;
  }
  .CircularProgressbar-background {
    fill: green;
  }

  .goal-percentageText {
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    strong {
      font-weight: 600;
      font-size: 28px;
      line-height: 40px;
      display: block;
    }
    .achieved {
      color: #5a5f70;
    }
  }
  .goal-progress-bar {
    height: 12px;
    border-radius: 50px;
    margin-bottom: 8px;
  }

  @media screen and (max-width: 1200px) {
    .circle-wrap label {
      font-size: 12px;
    }
  }
`;

export const Dashboard = styled.div`
  .task-event-container {
    margin-top: 32px;
  }
  .premium-value-container {
    background: #fafafa;
    border-radius: 12px;
    padding: 28px 0;
    margin-bottom: 20px;
  }
  .actionField .dropdown-div {
    left: -70px;
    right: unset;
  }
  .center-div-status {
    border-left: 1px solid #fff;
    border-right: 1px solid #fff;
  }
  .status-row {
    font-size: 16px;
    color: #5a5f70;
    margin-top: 32px;

    span {
      font-weight: 600;
    }
  }
  h4 {
    font-weight: 600;
    font-size: 20px;
    line-height: 39px;
    margin-bottom: 3px;
    &.activity .activity-select {
      &::after {
        content: '';
        border: 1px solid #262758;
        position: absolute;
        width: 94%;
        left: 3px;
        bottom: 1px;
      }
    }
  }
  .end-task-tab {
    padding-right: 0;
  }
  .leadfunnel-text {
    margin-bottom: 10px;
  }

  .goal-progress-bar {
    width: 100%;
    background-color: #e6e9f0;
    .progress-bar {
      background-color: #58c070;
      border-radius: 8px;
    }
  }
  tr .leadActionsFieldHead,
  tr .statusContainerHead {
    width: 2%;
  }
  .event-list {
    height: 100%;
  }
  .leader-progress-bar {
    width: 100%;
    background-color: #e6e9f0;
    height: 4px;
    margin-bottom: 8px;

    .progress-bar {
      background-color: #58c070;
      border-radius: 8px;
    }
  }
  tr.empty-div td div {
    height: auto;
  }
  .goal-head {
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    width: calc(100% - 55px);
  }
  .goal-stat {
    font-weight: 600;
    font-size: 44px;
    line-height: 64px;
    text-align: center;
    img {
      width: 55px;
      vertical-align: top;
      @media all and (max-width: 1200px) {
        width: 22px;
      }
    }
  }
  .pipeline-select {
    padding-right: 8px;
    max-width: 272px;
  }
  .list-container .nav-link {
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #262758;
    &.active {
      font-weight: 600;
      border-bottom: 3px solid #262758;
    }
  }
  .task-list-card {
    white-space: nowrap;
    box-shadow: inset 0px -1px 0px #f5f5f5;
    width: calc(100% - 28px);
    .task-name {
      padding: 0 10px;
      font-weight: 600;
      text-overflow: ellipsis;
      overflow: hidden;
    }
    .task-content {
      text-overflow: ellipsis;
      overflow: hidden;
    }
  }
  .event-list-card {
    background: #f5f7fc;
    padding: 12px;
    margin: 20px 0;
    border-radius: 8px;
    width: calc(100% - 60px);
    cursor: pointer;
    .taskIndicator {
      border-radius: 25px;
      background: #ecf4f9;
      top: 0px;
      width: 4px;
      margin-right: 2px;
      height: 100%;
      flex-grow: 0;
      flex-shrink: 0;
    }
    .eventIndicator {
      border-radius: 25px;
      background: #f04d46;
      top: 0px;
      width: 4px;
      margin-right: 12px;
      height: 100%;
      flex-grow: 0;
      flex-shrink: 0;
    }
  }
  .event-name {
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
  }
  .event-content {
    color: #5a5f70;
    text-overflow: ellipsis;
    overflow: hidden;
  }
  .list-container {
    background: #fff;
    margin-top: 32px;
    font-size: 16px;
    line-height: 24px;
    padding: 32px;
    border-radius: 8px;
    @media screen and (max-width: 576px) {
      padding: 24px 20px;
    }
  }
  .leaderboard-card {
    padding: 12px 0;
    box-shadow: inset 0px -1px 0px #f5f5f5;
    & > div {
      padding-top: 5px;
    }
  }
  .show-all {
    color: #262758;
    cursor: pointer;
    opacity: 0.5;
    margin-top: 35px;
    display: inline-block;
    &.event-showAll {
      margin-top: 11px;
    }
  }
  .leader-index {
    width: 30px;
    // font-weight: 600;
  }
  .earned {
    margin-left: auto;
  }
  .avathar {
    width: 32px;
    height: 32px;
    color: #fff;
    border-radius: 50px;
    background: #000;
    font-weight: 600;
    text-align: center;
    margin-right: 10px;
  }
  .dots-showLess {
    text-align: center;
    padding: 2px 0 10px;
    font-weight: 600;
  }
  .lead-funnel-container {
    padding: 32px;
    background: #fff;
    margin-top: 32px;
    .duration-select {
      display: inline-block;
      padding-left: 40px;
    }
    @media screen and (max-width: 1200px) {
      margin-top: 32px !important;
    }
    @media screen and (max-width: 576px) {
      padding: 24px 20px;
    }
  }
  .lead-select::after {
    content: '';
    border: 1px solid #262758;
    position: absolute;
    width: 94%;
    left: 3px;
    bottom: 1px;
  }
  .lead-select {
    margin-left: 5px;
  }
  .event-time {
    border-right: 1px solid #e6e9f0;
    margin-right: 10px;
    padding-right: 10px;
    font-size: 12px;
    line-height: 14px;
    color: #8990a3;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    text-align: right;
    white-space: nowrap;
    width: 50px;
    flex-grow: 0;
    flex-shrink: 0;
  }
  .event-list .head-div {
    margin-bottom: 29px;
  }
  .leaders-list .head-div {
    margin-bottom: 10px;
  }

  .status-container {
    width: 75%;
    .status-section {
      display: flex;
      & > div {
        width: 50%;
        margin-right: 20px;
      }
      & > div:last-child {
        margin-right: 0;
      }
    }
  }
  .goal-div {
    width: 25%;
    margin-right: 20px;
    background: #ffffff;
    border-radius: 8px;
    padding: 24px 32px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  .goal-values {
    background: #f5f7fc;
    padding: 12px 20px;
    display: flex;
    justify-content: space-between;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    align-items: center;
    margin: 8px 0;
  }
  .goal-text {
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    display: flex;
    align-items: center;
    .goal-icon {
      width: 32px;
      height: 32px;
      background: #e6e9f0;
      border-radius: 50px;
      align-items: center;
      margin-right: 12px;
      img {
        margin: 6px;
        display: block;
        margin-right: 12px;
      }
    }
    @media screen and (max-width: 1200px) {
      .goal-icon {
        width: 28px;
        height: 28px;
        margin-right: 6px;
        img {
          margin: 4px;
          display: block;
          margin-right: 6px;
        }
      }
    }
  }
  .total-count {
    padding: 5px 10px;
    background: #e6e9f0;
    border-radius: 8px;
    width: auto;
    display: inline-block;
  }
  .leader-scroll {
    overflow-y: auto;
    max-height: 255px;
    padding-right: 5px;
    overflow-x: hidden;
    margin-right: -5px;
    &::-webkit-scrollbar {
      width: 4px;
      height: 159px;
    }

    &::-webkit-scrollbar-track {
      border-radius: 8px;
    }

    &::-webkit-scrollbar-thumb {
      background: #e6e9f0;
    }
  }
  .event-scroll {
    overflow-y: auto;
    max-height: 245px;
    padding-right: 5px;
    overflow-x: hidden;
    margin-right: -5px;

    &::-webkit-scrollbar {
      width: 4px;
      height: 159px;
    }

    &::-webkit-scrollbar-track {
      border-radius: 8px;
    }

    &::-webkit-scrollbar-thumb {
      background: #e6e9f0;
    }
  }
  .sales-graph-container {
    margin-bottom: 30px;
  }
  .view-as {
    margin: auto 0;
    width: max-content;
  }
  @media screen and (max-width: 1900px) {
    h4 {
      font-size: 16px;
    }
    .event-name {
      font-size: 12px;
      line-height: 14px;
    }
    .task-list-card {
      font-size: 14px;
      line-height: 20px;
    }
    .event-img {
      width: 12px;
    }
    .event-content {
      font-size: 12px;
      line-height: 14px;
      margin-top: 5px;
    }

    .list-container .nav-link {
      font-size: 14px;
    }
    .dashboard-select {
      font-size: 14px;
    }
    .goalType {
      margin-right: -100px;
    }
    .event-list-card {
      margin: 18px 0;
    }
    .dots-showLess {
      padding: 0;
    }
    .show-all {
      font-size: 14px;
    }
    #task-tabpane-today .show-all,
    #task-tabpane-upcoming .show-all {
      margin-top: 50px;
    }
    .status-row {
      font-size: 14px;
    }
    .goal-stat {
      font-size: 36px;
      line-height: 52px;
    }
    .goal-text {
      font-size: 12px;
    }
    .goal-values {
      padding: 8px;
      font-size: 14px;
    }
  }

  @media screen and (max-width: 1200px) {
    .status-container.tab-view-contents {
      height: calc(100vh - 230px);
      overflow-y: auto;
      overflow-x: hidden;
      padding-right: 10px;
    }
    .premium-value-container {
      margin-bottom: 0;
      padding: 18px 15px;
    }
    .tab-view {
      justify-content: space-between;
      .goal-head {
        font-size: 14px;
        line-height: 20px;
        font-weight: 400;
        margin-top: 0;
        width: unset;
      }
      .goal-stat {
        font-weight: 600;
        margin-bottom: 0;
        font-size: 16px;
        line-height: 24px;
      }
    }
    .status-row {
      font-size: 14px;
    }
    tr .leadActionsFieldHead,
    tr .statusContainerHead {
      width: 2%;
    }
    .goal-values {
      padding: 8px;
      font-size: 12px;
      line-height: 16px;
    }
    .goal-div {
      padding: 15px 20px;
      min-width: 236px;
      justify-content: normal;
    }
    .goal-text {
      font-size: 12px;
      line-height: 16px;
    }
    .goal-div {
      .circle-wrap {
        height: auto;
        margin-top: 27px;
        margin-bottom: 20px;
        width: 100%;
      }
    }
    .page-title {
      margin: 0;
      color: #000000;
    }
    .dashbord-header-div {
      margin-bottom: 20px;
      margin-top: 10px;
      h1 {
        font-size: 24px;
        line-height: 24px;
      }
    }
    &.content-div {
      padding-bottom: 0;
    }
  }
  @media screen and (max-width: 576px) {
    .total-opp {
      background: #e6e9f0;
      padding: 1px 6px;
      height: 25px;
      border-radius: 8px;
      margin-left: -55px;
      margin-top: 6px;
    }
    .goalType {
      margin-right: 0px;
    }
    .select-pipeline-lead__indicators {
      margin-left: 35px;
    }
    .status-hgt {
      height: 100%;
    }
    .carousel.carousel-slider {
      padding-bottom: 40px;
      margin-left: -5px;
      // margin-right: -15px;
      width: calc(100% + 5px) !important;
      li.slide.selected {
        min-width: calc(100% - 10px);
        & ~ .previous {
          margin-right: 10px;
        }
      }
    }
    .total-count {
      margin-top: 10px;
    }
    .carousel .slide {
      padding-left: 5px;
      padding-right: 5px;
    }
    .carousel .control-dots .dot {
      box-shadow: none;
      background: #d9d9d9;
    }
    .pipeline-select > div:nth-of-type(2) {
      left: 0;
      right: unset;
    }
    .carousel .control-dots .dot.selected,
    .carousel .control-dots .dot:hover {
      background: #5c698c;
      box-shadow: none;
    }
    .task-event-container {
      margin-top: 16px;
    }
    .dashbord-header-div {
      margin-bottom: 28px;
      margin-top: 28px;
      h1 {
        font-size: 20px;
      }
    }
    .goal-div {
      width: 100%;
      margin-bottom: 23px;
      .circle-wrap {
        margin-top: 28px;
      }
    }
    .premium-value-container {
      margin-bottom: 0;
    }
    .sales-graph-container {
      margin-bottom: 60px;
    }
    &.content-div {
      padding-left: 28px;
      padding-right: 28px;
    }
    .premium-value-container {
      padding: 18px 20px;
    }
    .view-as {
      width: max-content;
    }
  }
`;

export const Status = styled.div`
  color: #262758;
  .stat-div {
    padding: 16px 30px;
    border-radius: 8px;
    position: relative;
    min-height: 156px;
    height: 100%;
    &::before {
      content: '';
      position: absolute;
      top: 0;
      height: 100%;
      width: 80px;
      right: 55px;
      -ms-transform: skewX(-10deg);
      transform: skewX(-10deg);
    }
  }
  .producer {
    background: linear-gradient(to right, #3c498a 80%, #ac8d61 80%);
    color: #fff;
    .details {
      color: #fff;
    }
    &::before {
      background: #ac8d61;
    }
  }
  .account {
    background: #f9e4d2;
    &::before {
      background: #000000;
      opacity: 0.01;
    }
  }
  .policy {
    background: #cad9f3;
    &::before {
      background: #000000;
      opacity: 0.01;
    }
  }
  .premium {
    background: linear-gradient(to right, #d4fad4 80%, #cef3ce 80%);
    &::before {
      background: #cef3ce;
    }
  }
  .stat-head {
    display: flex;
    justify-content: space-between;
    h6 {
      font-weight: 600;
      font-size: 20px;
      line-height: 39px;
      margin-bottom: 0;
    }
  }
  h5 {
    font-weight: 600;
    font-size: 36px;
    line-height: 52px;
    margin-bottom: 0;
    margin-top: 8px;
    text-align: center;
  }
  .details {
    color: #5a5f70;
    span {
      font-weight: 700;
    }
    font-size: 14px;
    line-height: 20px;
  }
  .dashboard-select {
    margin-right: -4px;
  }
  @media screen and (max-width: 1900px) {
    .stat-div {
      padding: 16px 24px;
    }
    .stat-head h6 {
      font-size: 16px;
    }
    .dashboard-select {
      font-size: 14px;
    }
    h5 {
      font-size: 28px;
      line-height: 40px;
    }
    .details {
      font-size: 14px;
      line-height: 20px;
    }
    .goalType {
      margin-right: 0px;
    }
  }

  @media screen and (max-width: 1200px) {
    width: 100%;
    overflow-x: auto;
    height: calc(100vh - 205px);
    .first-div-status {
      border-right: 1px solid #fff;
      text-align: left;
      span {
        margin-right: 5px;
      }
      p {
        display: inline-block;
        margin-bottom: 2px;
      }
    }
    .center-div-status {
      border-left: none !important;
      text-align: left !important;
      span {
        margin-right: 5px;
      }
      p {
        display: inline-block;
        margin-bottom: 2px;
      }
    }
    .status-row {
      margin-top: 10px;
    }
    .status-row > div:last-child {
      text-align: right !important;
      p {
        margin-bottom: 0;
      }
    }
    .stat-div {
      &::before {
        width: 110px;
        right: 30px;
      }
    }
  }
  @media screen and (max-width: 576px) {
    height: unset;
    overflow: hidden;
    .status-row {
      font-size: 12px;
    }
    .row {
      min-width: 0px;
    }
    .stat-div {
      text-align: left;

      &::before {
        width: 110px;
        right: 30px;
      }
    }
  }
`;

export const LeadsFunnel = styled.div`
  .funnel-container {
    min-width: 680px;
    overflow: hidden;
  }
  overflow-x: auto;
  overflow-y: hidden;

  &::-webkit-scrollbar {
    height: 4px;
    width: 159px;
  }

  &::-webkit-scrollbar-track {
    border-radius: 8px;
  }

  &::-webkit-scrollbar-thumb {
    background: #e6e9f0;
  }
  .leads {
    text-align: center;
  }

  .svg-funnel-js {
    display: inline-block;
    position: relative;
  }
  .svg-funnel-js svg {
    display: block;
  }
  .svg-funnel-js .svg-funnel-js__labels {
    position: absolute;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
  }
  .svg-funnel-js.svg-funnel-js--vertical .svg-funnel-js__labels {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
  }

  .svg-funnel-js .svg-funnel-js__container {
    width: 100%;
    height: 100%;
    display: none;
  }
  .svg-funnel-js .svg-funnel-js__labels {
    width: 100%;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
  }
  .svg-funnel-js .svg-funnel-js__labels .svg-funnel-js__label {
    -webkit-box-flex: 1;
    -ms-flex: 1 1 0px;
    flex: 1 1 0;
    position: relative;
    display: flex;
    flex-direction: column;
    text-align: left;
    padding-left: 15px;
  }
  .svg-funnel-js .svg-funnel-js__labels .svg-funnel-js__label .label__value {
    font-weight: 600;
    font-size: 18px;
    line-height: 20px;
    margin-bottom: 5px;
  }
  .svg-funnel-js .svg-funnel-js__labels .svg-funnel-js__label .label__title {
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    margin-bottom: 6px;
    // margin-top: 51px;
  }
  .svg-funnel-js .svg-funnel-js__container:first-child {
    margin-top: 83px;
    display: block;
  }
  .svg-funnel-js
    .svg-funnel-js__labels
    .svg-funnel-js__label
    .label__percentage {
    font-size: 14px;
    font-weight: 600;
    // position: absolute;
    width: 100%;
    // left: -50%;
    // top: -30px;
    &::after {
      content: ' Conversion';
      padding-left: 3px;
      font-weight: 400;
    }
    @media screen and (max-width: 576px) {
      &::after {
        font-size: 14px;
      }
    }
  }
  .label-1 .label__percentage {
    display: none;
  }
  .svg-funnel-js
    .svg-funnel-js__labels
    .svg-funnel-js__label
    .label__segment-percentages {
    position: absolute;
    top: 50%;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    width: 100%;
    left: 0;
    padding: 8px 24px;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    background-color: rgba(8, 7, 48, 0.8);
    margin-top: 24px;
    opacity: 0;
    -webkit-transition: opacity 0.1s ease;
    transition: opacity 0.1s ease;
    cursor: default;
  }
  .svg-funnel-js
    .svg-funnel-js__labels
    .svg-funnel-js__label
    .label__segment-percentages
    ul {
    margin: 0;
    padding: 0;
    list-style-type: none;
  }
  .svg-funnel-js
    .svg-funnel-js__labels
    .svg-funnel-js__label
    .label__segment-percentages
    ul
    li {
    font-size: 13px;
    line-height: 16px;
    margin: 18px 0;
  }
  .svg-funnel-js
    .svg-funnel-js__labels
    .svg-funnel-js__label
    .label__segment-percentages
    ul
    li
    .percentage__list-label {
    font-weight: 700;
  }
  .svg-funnel-js
    .svg-funnel-js__labels
    .svg-funnel-js__label:hover
    .label__segment-percentages {
    opacity: 1;
  }
  .svg-funnel-js:not(.svg-funnel-js--vertical) {
    padding-top: 64px;
    padding-bottom: 16px;
    margin-top: 28px;
    width: 100%;
  }

  .svg-funnel-js:not(.svg-funnel-js--vertical)
    .svg-funnel-js__label:not(:first-child) {
    border-left: 1px dashed #cad9f3;
  }
  .svg-funnel-js.svg-funnel-js--vertical {
    padding-left: 120px;
    padding-right: 16px;
  }
  .svg-funnel-js.svg-funnel-js--vertical .svg-funnel-js__label {
    padding-top: 24px;
  }
  .svg-funnel-js.svg-funnel-js--vertical
    .svg-funnel-js__label:not(:first-child) {
    border-top: 1px solid #fff;
  }
  .svg-funnel-js.svg-funnel-js--vertical
    .svg-funnel-js__label
    .label__segment-percentages {
    margin-top: 0;
    margin-left: 106px;
    width: calc(100% - 106px);
  }
  .svg-funnel-js.svg-funnel-js--vertical
    .svg-funnel-js__label
    .label__segment-percentages
    .segment-percentage__list {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-pack: distribute;
    justify-content: space-around;
  }

  .segment-percentage__list::before {
    content: 'Projection';
    display: block;
    font-weight: 600;
    font-size: 16px;
    margin-bottom: 8px;
  }
  .svg-funnel-js .svg-funnel-js__subLabels {
    display: none;
  }
  .svg-funnel-js .svg-funnel-js__subLabels .svg-funnel-js__subLabel {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    font-size: 12px;
    line-height: 16px;
  }
  .svg-funnel-js
    .svg-funnel-js__subLabels
    .svg-funnel-js__subLabel:not(:first-child) {
    margin-left: 16px;
  }
  .svg-funnel-js
    .svg-funnel-js__subLabels
    .svg-funnel-js__subLabel
    .svg-funnel-js__subLabel--color {
    width: 12px;
    height: 12px;
    border-radius: 50%;
    margin: 2px 8px 2px 0;
  }
  .svg-funnel-js
    .svg-funnel-js__labels
    .svg-funnel-js__label
    .label__segment-percentages {
    background: #e6e9f0;
    margin: 5px;
    border-radius: 8px;
    padding: 16px;
    width: calc(100% - 10px);
    &::after {
      background: #e6e9f0;
      transform: rotate(45deg);
      content: '';
      position: absolute;
      bottom: -7px;
      width: 12px;
      height: 12px;
      left: calc(50% - 6px);
    }
    ul li {
      margin: 0;
      display: inline-block;
      padding-right: 2px;
      max-width: 100%;
    }
    ul li:last-child span {
      visibility: hidden;
    }
    ul li:first-child {
      margin-left: -5px;
    }
    .percentage__list-label {
      position: relative;
      &::before {
        content: '';
        background: #e6e9f0;
        width: 3px;
        height: 15px;
        position: absolute;
        left: -5px;
      }
    }
  }
  @media screen and (max-width: 1900px) {
    .svg-funnel-js
      .svg-funnel-js__labels
      .svg-funnel-js__label
      .label__percentage {
      font-size: 14px;
    }
  }
  @media screen and (min-width: 1200px) {
    .svg-funnel-js .svg-funnel-js__labels .svg-funnel-js__label {
      padding-left: 10px;
      padding-right: 7px;
    }
  }
`;

export const LeaderBoardMobile = styled.div``;

export const MobileCard = styled.div`
  .vector {
    background: #f5f7fc;
    border-radius: 8px;
    width: 32px;
    height: 32px;
    text-align: center;
    cursor: pointer;
    border: 0;
  }
  box-shadow: inset 0px -1px 0px #f5f5f5;
  padding: 15px 0;
  .name-container {
    margin-bottom: 15px;
    position: relative;
  }
  .premium-goal-text {
    color: #5a5f70;
  }
  .leader-progress-bar {
    margin-bottom: 8px;
  }
  .text-overflow-container {
    position: relative;
    .tooltiptext {
      visibility: hidden;
      min-width: 176px;
      max-width: 100%;
      background: #191919;
      color: #d1d6e5;
      padding: 16px 24px;
      border-radius: 8px;
      font-size: 14px;
      text-align: left;
      position: absolute;
      z-index: 1;
      top: 40px;
      left: 0;
      white-space: normal;
      overflow-wrap: anywhere;
      line-height: 24px;

      ::after {
        content: ' ';
        position: absolute;
        top: -10px;
        left: 20px;
        margin-left: -5px;
        border-width: 5px;
        border-style: solid;
        border-color: transparent transparent #191919 transparent;
      }
    }

    :hover {
      .tooltiptext {
        visibility: visible;
      }
    }
  }
  .name-text {
    font-weight: 600;
    font-size: 16px;
    width: calc(100% - 20px);
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    display: inline-block;
    vertical-align: bottom;
  }
  .leader-text {
    font-weight: 600;
    font-size: 16px;
  }
  .dropdown-div {
    position: absolute;
    background: #fff;
    box-shadow: 0px 1px 4px 0px #26275833;
    border-radius: 8px;
    padding: 4px 16px;
    right: 0;
    bottom: -55px;
    font-size: 14px;
    z-index: 1;
    width: 144px;
    cursor: pointer;
  }
  .edit-div {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .delete-div {
    color: #9a0a0d;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-top: 1px solid #e6e9f0;
  }
  .mobile-leader-action::before {
    content: '';
    width: 2px;
    height: 12px;
    background: #e6e9f0;
    display: block;
    position: absolute;
    top: 6px;
    right: 30px;
  }
`;
