import React, { useEffect, useState } from 'react';
import { fetchIndustries } from '../../../../api/industry';
import SearchDropdown from '../../../../components/SearchDropdown';
import GoogleSearchInput from '../../../../components/GoogleSearchInput';
import InputField from '../../../../components/CustomInputField';
import cross from '../../../../assets/logo/cross.svg';
import warning from '../../../../assets/logo/warning.svg';
import moment from 'moment';
import CustomFieldInputCustom from '../../../../components/CustomfieldsInputCustom';

const NewBusinessLongForm = ({
  business,
  index,
  businesses,
  setBusiness,

  businessPhoneNumberError,
  businessNameError,
  customFields,
  amountError,
}: {
  business: any;
  index: number;
  businesses: any[];
  setBusiness: Function;
  customFields?: any;
  businessPhoneNumberError?: string;

  businessNameError?: string;
  amountError?: number;
}) => {
  const [addressObject, setAddressObject] = useState<any>({});

  const onItem = (
    name: string,
    value: string | { label: string; value: string },
    address: boolean = false
  ) => {
    if (address) {
      Object.assign(business, value);
      let newBussiness = business;
      let updatedBusiness = [...businesses];
      updatedBusiness[index] = newBussiness;
      setBusiness(updatedBusiness);
    } else {
      let newBussiness = business;
      let updatedBusiness = [...businesses];
      newBussiness[name] = value;
      updatedBusiness[index] = newBussiness;
      setBusiness(updatedBusiness);
    }
  };
  const onCustomItem = (
    name: string,
    value: string | { label: string; value: string }
  ) => {
    let newBussiness = business;
    let updatedBusiness = [...businesses];
    newBussiness.customFields[name] = value;
    updatedBusiness[index] = newBussiness;
    setBusiness(updatedBusiness);
  };

  const formatNum = (num: string) => {
    const number = num.replace(/[^\d]/g, '').slice(0, 10);
    if (number.length < 3) {
      return `${number}`;
    }
    if (number.length < 4 && num.includes('(')) {
      return `${number}`;
    }
    if (number.length < 4) {
      return `(${number.slice(0, 3)})`;
    }
    if (number.length > 3 && number.length < 7) {
      return `(${number.slice(0, 3)}) ${number.slice(3)}`;
    }
    return `(${number.slice(0, 3)}) ${number.slice(3, 6)}-${number.slice(
      6,
      10
    )}`;
  };
  useEffect(() => {
    if (customFields?.length > 0) {
      customFields?.map((item: any) => {
        if (item.FieldType?.name === 'DATEPICKER' && item.data?.defaultValue) {
          onItem(
            item.uuid,
            item.data?.defaultValue && moment(item.data?.defaultValue).toDate()
          );
        }
        if (item.FieldType?.name === 'MULTIPLE_CHOICE') {
          const filteredData: any = item.data.options.filter(
            (it: any) => it.default === true
          );
          onItem(
            item.uuid,
            filteredData.map((el: any) => {
              return { value: el.order, label: el.value };
            })
          );
        }
        if (item.FieldType?.name === 'SINGLE_CHOICE') {
          if (item.data?.defaultValue) {
            onItem(item.uuid, {
              label:
                item.data.options[parseInt(item.data?.defaultValue, 10)]?.value,
              value:
                item.data.options[parseInt(item.data?.defaultValue, 10)]?.order,
            });
          }
        }
      });
    }
  }, [customFields]);
  const onbusinessItem = (name: string, value: string) => {
    let businessphonenumberValue: string;
    if (!value) businessphonenumberValue = value;
    businessphonenumberValue = formatNum(value);
    onItem('businessPhoneNumber', businessphonenumberValue, false);
  };
  const [industrySearch, setIndustrySearch] = useState('');
  const [industry, setIndustry] = useState<any>([]);
  const [loadingStatus, setLoadingStatus] = useState('idle');
  const [isManual, setIsManual] = useState(false);

  const loadIndustry = (value: string) => {
    if (loadingStatus === 'idle' || loadingStatus === 'succeeded') {
      setLoadingStatus('loading');
      fetchIndustries(
        (data: any) => {
          setIndustry(data);
          setLoadingStatus('succeeded');
        },
        'id',
        'asc',
        value,
        'True'
      );
    }
  };

  const resetIndustry = (value: string) => {
    onItem('industry', value);
  };

  useEffect(() => {
    onItem('isManuallyAdded', String(isManual));
  }, [isManual]);

  return (
    <div className='business-container'>
      <div>
        <div className='d-flex justify-content-between align-items-center '>
          <h2 className='business-info bussinessInfoHead'>New Business</h2>
          {index > 0 ? (
            <button
              type='button'
              className='d-flex secondaryButton removeButton align-items-center'
              onClick={() => {
                const updatedBusinesses = [...businesses];
                updatedBusinesses.splice(index, 1);
                setBusiness(updatedBusinesses);
              }}
            >
              Remove
              <img className='mx-1 removeIcon' src={cross} alt='remove' />
            </button>
          ) : null}
        </div>
        <div className='top-field d-sm-flex justify-content-between'>
          <div className='fields'>
            <InputField
              name='name'
              type='text'
              placeholder='Business name'
              onSetItem={onItem}
              required
              values={business}
            />
            {businessNameError ? (
              <>
                <div className='errorVal'>
                  <img src={warning} alt='warning' className='warning' />
                  {businessNameError}
                </div>
              </>
            ) : null}
          </div>
          <div className='fields'>
            {/* <SelectField
              options={industry}
              name='industry'
              placeholder='Industry(Optional)'
              values={business}
              onSetItem={onItem}
            /> */}
            <SearchDropdown
              name='industry'
              placeholder='Industry (Optional)'
              isEdit={true}
              values={industrySearch}
              searchValue={industry}
              setSearch={setIndustrySearch}
              status={loadingStatus}
              className={`${industrySearch && 'filled'}`}
              loadFunction={loadIndustry}
              setUUID={resetIndustry}
              content={industry?.map((item: any) => (
                <div
                  className='searchValuesCard d-flex align-items-center'
                  onClick={() => {
                    setIndustrySearch(
                      `${item.naicsSixDigitCode} - ${item.naicsSixDigitCodeDescription}`
                    );
                    onItem('industry', item?.uuid);
                  }}
                >
                  <div>
                    <h6>
                      {item.naicsSixDigitCode} -{' '}
                      {item.naicsSixDigitCodeDescription}
                    </h6>
                    <p className='description'>
                      {item.naicsFourDigitCodeDescription}
                    </p>
                  </div>
                </div>
              ))}
            />
          </div>
        </div>
        <div className='d-sm-flex justify-content-between'>
          <div className='fields'>
            <InputField
              name='businessPhoneNumber'
              type='text'
              placeholder='Business phone number'
              onSetItem={onbusinessItem}
              values={business}
              required
            />
            {businessPhoneNumberError ? (
              <>
                <div className='errorVal'>
                  <img src={warning} alt='warning' className='warning' />
                  {businessPhoneNumberError}
                </div>
              </>
            ) : null}
          </div>
          <div className='fields'>
            <InputField
              name='businessEmail'
              type='email'
              placeholder='Business email'
              onSetItem={onItem}
              values={business}
            />
          </div>
        </div>
        <div>
          {!isManual ? (
            <div className='d-sm-flex justify-content-between'>
              <div className='fields'>
                <GoogleSearchInput
                  placeholder='Business address'
                  name='businessAddress'
                  setAddressObject={setAddressObject}
                  addressObject={addressObject}
                  inputType='COMMERCIAL'
                  onSetItem={onItem}
                  index={index}
                  className={`${businesses[index]?.address && 'filled'}`}
                  setIsManual={setIsManual}
                ></GoogleSearchInput>
              </div>
              <div className='fields'>
                <InputField
                  name='businessApt'
                  type='text'
                  placeholder='Apt'
                  onSetItem={onItem}
                  values={business}
                />
              </div>
            </div>
          ) : (
            <>
              <h2 className='business-info-address'>Business address</h2>
              <div className=' d-sm-flex justify-content-between'>
                <div className='fields'>
                  <InputField
                    name='address'
                    type='text'
                    placeholder='Address'
                    onSetItem={onItem}
                    values={business}
                  />
                </div>
                <div className='fields'>
                  <InputField
                    name='zipCode'
                    type='number'
                    placeholder='Zipcode'
                    onSetItem={onItem}
                    values={business}
                  />
                </div>
              </div>
              <div className=' d-sm-flex justify-content-between'>
                <div className='fields'>
                  <InputField
                    name='city'
                    type='text'
                    placeholder='City'
                    onSetItem={onItem}
                    values={business}
                  />
                </div>
                <div className='fields'>
                  <InputField
                    name='state'
                    type='text'
                    placeholder='State'
                    onSetItem={onItem}
                    values={business}
                  />
                </div>
              </div>
              <div className=' d-sm-flex justify-content-between'>
                <div className='fields'>
                  <InputField
                    name='country'
                    type='text'
                    placeholder='County'
                    onSetItem={onItem}
                    values={business}
                  />
                </div>
                <div className='fields'>
                  <InputField
                    name='businessApt'
                    type='text'
                    placeholder='Apt'
                    onSetItem={onItem}
                    values={business}
                  />
                </div>
              </div>
            </>
          )}
        </div>
        <div className=' d-sm-flex justify-content-between'>
          <div className='fields'>
            <InputField
              name='employeeCount'
              type='number'
              placeholder='Number of emplyees'
              onSetItem={onItem}
              values={business}
            />
          </div>
          <div className='fields'>
            <InputField
              name='businessStartedYear'
              type='number'
              placeholder='Year bussiness started'
              onSetItem={onItem}
              values={business}
            />
          </div>
        </div>
        <div className=' d-sm-flex justify-content-between'>
          <div className='fields'>
            <InputField
              name='payroll'
              type='number'
              placeholder='Total payroll'
              onSetItem={onItem}
              values={business}
              prefix='$'
              required
            />
            {amountError ? (
              <>
                <div className='errorVal'>
                  <img src={warning} alt='warning' className='warning' />
                  {amountError}
                </div>
              </>
            ) : null}
          </div>
          <div className='fields'>
            <InputField
              name='annualRevenue'
              type='number'
              placeholder='Annual revenue'
              onSetItem={onItem}
              values={business}
              prefix='$'
            />
          </div>
        </div>
        {customFields?.length > 0
          ? customFields?.map((item: any, index: number) => {
              return index % 2 === 0 ? (
                <div className='d-sm-flex justify-content-between'>
                  <div className='fields'>
                    <CustomFieldInputCustom
                      item={item}
                      onSetItem={onCustomItem}
                      values={business?.customFields}
                    />
                  </div>
                  {customFields?.length > index + 1 ? (
                    <div className='fields'>
                      <CustomFieldInputCustom
                        item={customFields[index + 1]}
                        onSetItem={onCustomItem}
                        values={business?.customFields}
                      />
                    </div>
                  ) : null}
                </div>
              ) : null;
            })
          : null}
      </div>
    </div>
  );
};

export default NewBusinessLongForm;
