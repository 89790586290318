import Moment from 'react-moment';
import { IUser } from '../../../../interfaces/User';
import vector from '../../../../assets/logo/dropdown.svg';
import call from '../../../../assets/logo/call.svg';
import message from '../../../../assets/logo/message.svg';
import { CardDiv } from '../../../../styles/CardDiv';

const AgentCard = ({
  firstName,
  lastName,
  email,
  phoneNumber,
  UserRole,
  createdAt,
}: IUser) => {
  return (
    <CardDiv>
      <td className='name-td'>
        <input type='checkbox' name='remember' className='form-check-input' />
        <b>
          {firstName} {lastName}
        </b>
      </td>
      <td>
        <div
          className={
            UserRole.name === 'PRINCIPAL_AGENT'
              ? 'status statusActive'
              : 'status statusInvited'
          }
        >
          {UserRole.name
            .toLowerCase()
            .split('_')
            .map((word) => word[0].toUpperCase() + word.substring(1))
            .join(' ')}
        </div>
      </td>
      <td>
        <Moment format='MM/DD/YYYY'>{createdAt}</Moment>
      </td>
      <td className='d-none d-xl-table-cell'>
        <div>{email}</div>
      </td>
      <td className='d-xl-none contact'>
        <div>
          <img src={call} className='' alt={phoneNumber} />
          <img src={message} alt={email} className='mx-3' />
        </div>
      </td>
      <td className='d-none d-xl-table-cell'>
        <div>{phoneNumber}</div>
      </td>
      <td className='actionField'>
        <div className='d-flex align-items-center justify-content-between'>
          <div className='actionButton'>Send Invite</div>
          <div className='vector'>
            <img src={vector} alt='vector' />
          </div>
        </div>
      </td>
    </CardDiv>
  );
};
export default AgentCard;
