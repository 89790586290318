import styled from '@emotion/styled';
export const AdminDiv = styled.div`
  @keyframes pageTransit {
    from {
      transform: translate(100%);
    }
    to {
      transform: translate(0);
    }
  }

  .dashbord-header-div {
    margin-bottom: 32px;
    h1 {
      font-weight: 600;
      font-size: 36px;
      line-height: 52px;
      margin: auto 0;
    }
  }

  .content-div {
    padding: 18px 20px 60px 20px;
    animation-name: pageTransit;
    animation-duration: 0.3s;
  }
  @media (max-width: 576px) {
    .content-div {
      background: #fff;
    }
  }

  .page-title {
    margin: 30px 0 20px;
  }

  .manage-card {
    padding: 16px 28px 16px 20px;
    box-shadow: 0px 1px 4px rgba(35, 42, 64, 0.2);
    border-radius: 8px;
    font-size: 12px;
    cursor: pointer;

    h6 {
      font-size: 14px;
      color: #262758;
      font-weight: 600;
    }
  }

  .roundfig {
    border-radius: 50%;
    width: 44px;
    height: 44px;
    background: #e6fbfe;
    margin-right: 16px;
    padding: 5px;
  }

  .arrow {
    transform: rotate(270deg);
    width: 15px;
  }

  .leftarrow {
    transform: rotate(180deg);
    filter: brightness(0) saturate(100%) invert(13%) sepia(13%) saturate(1912%)
      hue-rotate(187deg) brightness(96%) contrast(90%);
    margin-right: 12px;
  }

  .topButtonsDiv {
    margin: 20px 0 24px 0;
  }

  .addButton {
    position: fixed;
    bottom: 80px;
    width: 48px;
    height: 48px;
    right: 20px;
    border: none;
    background: #262758;
    border-radius: 50%;
    z-index: 1;
  }

  .filter-area {
    width: 100%;
    justify-content: space-between;
  }

  .search-div {
    background: #e6e9f0;
    padding: 4px 15px;
    border-radius: 8px;
    align-items: center;
    height: 32px;
    width: 280px;
    display: inline-flex;
    max-width: calc(100% - 33px);

    img {
      width: 16px;
    }

    input {
      background: #e6e9f0;
      border: 0;
      outline: none;
      font-size: 14px;
      padding-left: 17px;
      max-width: 99%;
    }
  }

  .filter-div {
    background: #e6e9f0;
    padding: 5px 15px;
    border-radius: 8px;
    font-size: 14px;
    height: 32px;
    width: 268px;
  }

  .shrinked-div {
    background: #e6e9f0;
    padding: 4px 3px 5px 6px;
    width: 32px;
    height: 32px;
    border-radius: 8px;
  }
  .shrinkedsearch {
    background: none;
    padding: 4px 40px 5px 6px;
    width: 32px;
    height: 32px;
    border-radius: 8px;
  }
  .error-div {
    display: flex;
    align-items: center;
    font-size: 14px;
    color: #cc0000;
    margin-top: 6px;
    img {
      margin-right: 10px;
    }
  }
  .shrinkedFilter-div {
    background: #e6e9f0;
    padding: 4px 3px 5px 6px;
    width: 32px;
    height: 32px;
    border-radius: 8px;
  }
  .book-shrinked {
    width: 32px;
    height: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 8px;
    cursor: pointer;
    position: relative;

    :hover {
      background: #e6e9f0;
    }
  }
  .filter-counter {
    background: #cc0000;
    color: #fff;
    font-size: 12px;
    line-height: 12px;
    border: 1px solid #fff;
    border-radius: 50px;
    position: absolute;
    top: 2px;
    right: 0px;
    display: flex;
    justify-content: center;
    width: 17px;
    height: 17px;
    align-items: center;
  }
  .book-filter {
    max-width: calc(100% - 10px);
  }

  .book-search {
    width: 100%;
    padding-left: 0;
    justify-content: end;
    input {
      background: #e6e9f0;
      border: 1px solid #262758;
      outline: none;
      border-radius: 8px;
      width: 384px;
      font-size: 14px;
      height: 100%;
      padding: 10px 48px;
    }

    .search-button {
      margin-right: -32px;
      position: relative;
      z-index: 1;
      filter: invert(0.3);
      width: 19px;
    }

    .close-button {
      margin: 0 20px 0 -40px;
      cursor: pointer;
    }
  }

  .divider {
    border-right: 1.6px solid #d1d6e5;
    margin: 0 12px;
  }

  @media (min-width: 576px) {
    .content-div {
      padding: 76px 32px 60px 32px;
    }
    .locationTableDiv {
      padding: 20px 32px 25px 32px;
    }
    .page-title {
      margin: 0px 0 18px;
    }

    .addButton {
      color: #f5f7fc;
      position: relative;
      border: 0;
      bottom: 0;
      right: 0;
      border-radius: 8px;
      width: 196px;
      height: 32px;
      padding: 8px 20px;
      font-size: 14px;

      .plus {
        width: 12px;
      }
    }
    .userButton {
      width: 166px;
      height: 32px;
    }
    .filterContainer {
      padding: 6px 8px 0px 20px;
      border-left: 1px solid #e5e5e5;
    }
    .filter-area {
      justify-content: flex-end;
    }

    .filter-div {
      margin-left: 20px;
    }

    .search-div {
      margin-right: 20px;
    }

    .book-shrinked {
      width: 40px;
      height: 40px;
    }
    .filter-counter {
      top: 3px;
      right: 2px;
    }

    .filterIcon {
      width: 24px;
    }
    .book-search {
      .search-button {
        width: 20px;
      }
      input {
        width: 320px;
      }
    }
  }
  @media screen and (min-width: 1200px) {
    .content-div {
      padding: 80px 48px 0 130px;
    }
    .locationTableDiv {
      padding: 20px 48px 0 130px;
    }
    .round-fig {
      width: 52px;
      height: 52px;
    }
    .book-search {
      .search-button {
        width: 20px;
      }
      input {
        width: 384px;
      }
    }

    .manage-card {
      padding: 20px 48px 20px 23px;
      margin-top: 24px;
      font-size: 14px;

      h6 {
        font-size: 16px;
      }
    }

    .page-title {
      margin: 0px;
      font-size: 24px;
    }

    .arrow {
      width: 12px;
    }

    .search-div {
      padding: 6px 15px;
      height: 40px;
      width: 388px;

      img {
        width: 20px;
      }

      input {
        font-size: 16px;
      }
    }

    .filter-div {
      padding: 8px 20px;
      font-size: 16px;
      height: 40px;
      width: 212px;
    }

    .shrinked-div {
      padding: 7px 10px;
      width: 40px;
      height: 40px;
    }
    .shrinkedFilter-div {
      padding: 10px;
      width: 40px;
      height: 40px;
    }

    .addButton {
      height: 40px;
      font-size: 16px;

      .plus {
        width: 16px;
      }
    }
    .userButton {
      width: 168px;
      height: 40px;
    }
  }

  @media screen and (min-width: 1900px) {
    .content-div {
      padding: 116px 120px 0px 208px;
    }
    .locationTableDiv {
      padding: 32px 120px 0px 208px;
    }
    .page-title {
      font-size: 28px;
    }
    .manage-card {
      padding: 22px 46px 22px 32px;
      font-size: 16px;

      h6 {
        font-size: 20px;
        margin-bottom: 12px;
      }
    }

    .roundfig {
      width: 60px;
      height: 60px;
      margin-right: 24px;
      padding: 14px;
    }

    .topButtonsDiv {
      margin: 0px 0 32px 0;
    }

    .addButton {
      height: 48px;
    }
    .userButton {
      width: 227px;
      height: 48px;
    }

    .search-div {
      padding: 10px 20px;
      height: 48px;
      width: 508px;
    }

    .filter-div {
      padding: 12px 20px;
      height: 48px;
    }

    .shrinked-div {
      width: 48px;
      height: 48px;
      padding: 10px 14px;
    }
    .shrinkedFilter-div {
      padding: 10px 14px;
      width: 48px;
      height: 48px;
    }
  }

  @media screen and (min-width: 2800px) {
    .content-div {
      padding: 232px 240px 0px 416px;
    }
    .page-title {
      font-size: 56px;
    }
    .manage-card {
      padding: 52px 96px 52px 64px;
      font-size: 32px;
      border-radius: 16px;

      h6 {
        font-size: 40px;
        margin-bottom: 24px;
      }
    }

    .arrow {
      width: 24px;
    }

    .roundfig {
      width: 120px;
      height: 120px;
      margin-right: 48px;
    }

    .leftarrow {
      width: 40px;
      margin-right: 24px;
    }

    .topButtonsDiv {
      margin: 0px 0 64px 0;
    }

    .addButton {
      height: 96px;
      width: 392px;
      font-size: 32px;

      .plus {
        width: 32px;
      }
    }

    .search-div {
      width: 600px;
      padding: 20px 40px;
      height: 96px;
      display: flex;
      align-items: center;
      img {
        width: 40px;
      }
      input {
        font-size: 32px;
      }
    }

    .filter-div {
      padding: 24px 40px;
      height: 96px;
      font-size: 32px;
      width: 414px;
    }

    .shrinked-div {
      width: 96px;
      height: 96px;
      padding: 20px 24px;

      img {
        width: 40px;
      }
    }
    .shrinkedFilter-div {
      width: 96px;
      height: 96px;
      padding: 20px 24px;
    }
  }
  @media screen and (max-width: 576px) {
    background: #fafafa;
  }
`;
