import { useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import Skeleton from 'react-loading-skeleton';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { AppDispatch } from '../../../store';
import {
  getPolicyDetails,
  onUpdatePolicyDetails,
  selectPolicy,
  PoliciesSliceState,
} from '../../../store/policies/policiesSlice';
import {
  getCustomFields,
  selectAllCustomFields,
} from '../../../store/customFields/customFieldsSlice';
import { fetchAgents } from '../../../api/agents';
import { fetchCarriers } from '../../../api/carriers';
import { fetchContacts } from '../../../api/contact';
import { getIssuer, fetchMGA } from '../../../api/policy';
import { fetchIndustries } from '../../../api/industry';
import EditInput from '../../../components/DetailsEditFormInput';
import EditSelect from '../../../components/SelectFieldEdit';
import EditPhoneNumber from '../../../components/PhoneNumberFormatInEditForms';
import EditDate from '../../../components/DateFieldEdit';
import Modal from '../../../components/EditDetailsModal';
import SuccessCard from '../../../components/SuccessCard';
import SearchDropdown from '../../../components/SearchDropdown';
import GoogleSearchInput from '../../../components/GoogleSearchInput';
import SelectField from '../../../components/SelectFieldEdit';
import CustomFieldInput from '../../../components/CustomfieldsInput';
import { IProducer } from '../../../interfaces/Agent';
import { IPolicy } from '../../../interfaces/Policy';
import { StyledEditDetailsPage } from './styles';
import { SuccessAlign } from '../../../styles/SuccessAlign';
import tick from '../../../assets/logo/tick.svg';
import warning from '../../../assets/logo/warning.svg';
import bar from '../../../assets/logo/bar.svg';
import cross from '../../../assets/logo/cross.svg';

const digitsOnly = (value: any) => /^\d*[\.{1}\d*]\d*$/.test(value);

const SignupSchema = Yup.object().shape({
  phoneNumber: Yup.string()
    .required('Phone Number is required')
    .matches(
      /[(][0-9]{3}[)][-\s][0-9]{3}[-][0-9]{4}$/,
      'Phone number is not valid'
    ),
  policyTerm: Yup.object().shape({
    value: Yup.string().required('Policy term is required'),
  }),
  effectiveDate: Yup.string().required('effectiveDate is required'),
  expiryDate: Yup.string().required('expiryDate is required'),
  policyNumber: Yup.string().required('Policy number is required'),
  premium: Yup.string()
    .required('Please enter premuim amount')
    .test('premium', digitsOnly),
  totalAmount: Yup.string().required('total Amount is required'),

  lastName: Yup.string().required('Last name is required'),
  businessPhoneNumber: Yup.string().matches(
    /[(][0-9]{3}[)][-\s][0-9]{3}[-][0-9]{4}$/,
    { message: 'Phone number is not valid', excludeEmptyString: true }
  ),
  payroll: Yup.number().positive('please enter  valid amount').nullable(),
  mgaFees: Yup.number().positive('please enter  valid amount').nullable(),
  esTax: Yup.number().positive('please enter  valid amount').nullable(),
  agencyFees: Yup.number().positive('please enter  valid amount').nullable(),
  otherFees: Yup.number().positive('please enter  valid amount').nullable(),
});

const EditPolicyDetails = ({
  policy,
  show,
  closeModal,
  uuid,
  isListing,
  ...props
}: {
  show: string;
  closeModal: Function;
  uuid: string;
  policy?: IPolicy;
  isListing?: boolean;
}) => {
  const dispatch = useDispatch<AppDispatch>();
  const {
    register,
    handleSubmit,
    control,
    reset,
    setValue,
    formState: { errors },
    watch,
  } = useForm({
    resolver: yupResolver(SignupSchema),
  });
  const watchAllFields = watch();
  const [commercial, isCommercial] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false);
  const [addressObject, setAddressObject] = useState<any>({});
  const [address, setAddress] = useState('');

  const [industrySearch, setIndustrySearch] = useState('');
  const [industryUuid, setIndustryUuid] = useState('');
  const [industry, setIndustry] = useState<any>([]);

  const [submit, setSubmit] = useState(false);

  const [emailAccounts, setEmailAccounts] = useState<any[]>([]);
  const [emailSearch, setEmailSearch] = useState('');
  const [emailError, setEmailError] = useState(false);
  const [emailData, setEmailData] = useState<any>([]);
  const [loadingStatus, setLoadingStatus] = useState('idle');
  const [carriers, setCarriers] = useState<any>([]);
  const [carrierSearchText, setCarrierSearchText] = useState('');

  const [producerSearch, setProducerSearch] = useState('');
  const [producer, setProducer] = useState('');
  const [producers, setProducers] = useState<IProducer[]>([]);
  const [issuingCompany, setIssuingCompany] = useState([]);
  const [MGA, setMGA] = useState<any>([]);
  const [mgaUUID, setMgaUUID] = useState('');
  const [mgaSearchText, setMgaSearchText] = useState('');
  const [carrierUUID, setCarrierUUID] = useState('');
  const [carrierType, setCarrierType] = useState('');
  const [user, setUser] = useState<any>({});
  const [isManual, setIsManual] = useState(false);
  const [businessError, setBusinessError] = useState('');
  const watchLastName = watch('lastName');
  const [contactUuid, setContactUuid] = useState('');

  const MaritalStatus = [
    { label: 'Married', value: 'Married' },
    { label: 'Single', value: 'Single' },
  ];

  const policySoldThrough = [
    { label: 'Directly appointed carrier', value: 'other' },
  ];

  const billType = [
    { label: 'Direct Bill', value: 'Direct Bill' },
    { label: 'Agency', value: 'Agency' },
  ];

  const paymentType = [
    { label: 'Auto Pay', value: 'Auto Pay' },
    { label: 'Manual Pay', value: 'Manual Pay' },
  ];

  const policyTerm = [
    { label: '6 months', value: '6 months' },
    { label: '12 months', value: '12 months' },
    { label: 'Custom', value: 'custom' },
  ];

  const premiumFinance = [
    { label: 'Yes', value: 'true' },
    { label: 'No', value: 'false' },
  ];

  const watchPremium = watch('premium');
  const watchAgency = watch('agencyFees');
  const watchMga = watch('mgaFees');
  const watchTax = watch('esTax');
  const watchPremiumFinance = watch('premiumFinance');
  const watchPremiumFinanceAmount = watch('premiumFinanceAmount');
  // const watchCarrier = watch('opportunityCarrierName');

  const watchBill = watch('billType');

  const watchPremiumDownPaymentAmount = watch('premiumDownPaymentAmount');
  const watchPolicyTerm = watch('policyTerm');
  const watchPolicyTermMonth = watch('policyTermMonth');

  const onCloseModal = () => {
    closeModal('closing');
    setTimeout(() => {
      closeModal('closed');
    }, 300);
  };

  const deailsStatus = useSelector(
    (state: { policies: PoliciesSliceState }) => state.policies.deailsStatus
  );

  useEffect(() => {
    if (isListing) {
      if (loadingStatus === 'idle' || loadingStatus === 'succeeded') {
        dispatch(getPolicyDetails({ uuid }));
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [uuid]);

  const policyDetails = useSelector(selectPolicy);

  useEffect(() => {
    dispatch(
      getCustomFields({
        uuid: '',
        resourceName: 'POLICY',
        successCB: () => {},
      })
    ); // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const customFields: any = useSelector(selectAllCustomFields);
  useEffect(() => {
    customFields.map((item: any) => {
      if (item.FieldType?.name === 'DATEPICKER' && item.data?.defaultValue) {
        setValue(item.uuid, moment(item.data?.defaultValue).toDate());
      }
      if (item.FieldType?.name === 'MULTIPLE_CHOICE') {
        const filteredData: any = item.data.options.filter(
          (it: any) => it.default === true
        );
        setValue(
          item.uuid,
          filteredData.map((el: any) => {
            return { value: el.order, label: el.value };
          })
        );
      }
      if (item.FieldType?.name === 'SINGLE_CHOICE') {
        setValue(item.uuid, {
          label:
            item.data.options[parseInt(item.data?.defaultValue, 10)]?.value,
          value:
            item.data.options[parseInt(item.data?.defaultValue, 10)]?.order,
        });
      }
      return true;
    }); // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customFields]);
  const onSubmit = (data: any) => {
    setSubmit(true);
    data.customFields = {};
    customFields.map((item: any) => {
      if (item.uuid in data) {
        data.customFields[item.uuid] = data[item.uuid];
      }
    });

    var canSubmit = true;
    if (industrySearch && !industryUuid) {
      canSubmit = false;
    }
    const emailExist = emailAccounts.find(
      (item) =>
        item?.ContactDetails?.find((value: any) => value.type === 'EMAIL')
          ?.value === emailSearch
    );
    if (emailExist && emailExist?.uuid !== contactUuid) {
      setEmailError(true);
      setEmailData([
        `${emailExist?.firstName} ${emailExist?.lastName}`,
        emailExist?.uuid,
      ]);
    }
    if (
      data.policySoldThrough.value === 'MGA' &&
      (!data.UWContactName || !data.UWEmail || !data.UWPhone || !mgaUUID)
    ) {
      canSubmit = false;
    }
    if (producerSearch && !producer && producerSearch !== 'Me') {
      canSubmit = false;
    }
    if (!watchLastName) {
      canSubmit = false;
    }
    if (
      !emailError &&
      emailSearch &&
      canSubmit &&
      !(emailExist && emailExist?.uuid !== contactUuid)
    ) {
      data.accountType = commercial ? 'COMMERCIAL' : 'PERSONAL';

      if (data.billType?.value === 'Agency') {
        data.agencyBill = 'true';
        data.directBill = 'false';
      } else {
        data.agencyBill = 'false';
        data.directBill = 'true';
      }
      const risk = data.admittedRisk?.value;
      const COI = data.requireCOI?.value;
      const premiumFin = data.premiumFinance?.value;
      delete data.requireCOI;
      data.requireCOI = String(COI);
      delete data.admittedRisk;
      data.admittedRisk = String(risk);
      delete data.premiumFinance;
      data.premiumFinance = String(premiumFin);
      if (carrierType === 'CARRIER') {
        data.carrierUUID = carrierUUID;
      } else if (carrierType === 'CUSTOM_CARRIER') {
        data.customCarrierUUID = carrierUUID;
      }
      data.issuerUUID = data.issuerUUID?.value;
      data.maritalStatus = data.maritalStatus?.value;
      data.paymentCollectedBy = data.paymentCollectedBy?.value;
      data.transactionType = data.transactionType.value;
      data.paymentType = data.paymentType?.value;
      if (watchPolicyTerm?.value === 'custom') {
        data.policyTerm = data.policyTermMonth + ' months';
      } else {
        data.policyTerm = data.policyTerm.value;
      }
      if (
        watchPolicyTerm?.value === 'custom' &&
        (!watchPolicyTermMonth || Number(watchPolicyTermMonth) > 99)
      ) {
        return;
      }
      data.statusUUID = data.status?.value;
      data.email = emailSearch;
      data.industry = industryUuid;
      data.mgaUUID = mgaUUID;
      data.paymentCollectionMethod = data.paymentCollectionMethod?.value;

      data.isManuallyAdded = isManual;

      //ToDo
      // // To add addressObject with the form
      if (!isManual) {
        const addObj = {
          address: addressObject.address,
          city: watchAllFields?.city,
          state: watchAllFields?.state,
          country: watchAllFields.country,
          zipCode: watchAllFields?.zipCode,
        };
        data = Object.assign(data, addObj);
      }
      if (commercial && !watchAllFields.businessName) {
        return setBusinessError('Please enter  Business Name');
      }

      dispatch(
        onUpdatePolicyDetails({
          data,
          uuid,
          successCB: () => {
            closeModal('closing');
            setTimeout(() => {
              setShowSuccess(true);
            }, 300);
            setTimeout(() => {
              reset();
              setShowSuccess(false);
              setTimeout(() => {
                closeModal('closed');
              }, 300);
            }, 2000);
          },
          errorCB: () => {},
        })
      );
    }
  };

  const [policyDetailLoading, setPolicyDetailLoading] = useState(false);

  useEffect(() => {
    if (show === 'open') {
      setPolicyDetailLoading(true);
      dispatch(
        getPolicyDetails({
          uuid,
          successCB: (policy: any) => {
            if (policy.agencyBill) {
              setValue('billType', {
                value: 'Agency',
                label: 'Agency',
              });
            } else {
              setValue('billType', {
                value: 'Direct Bill',
                label: 'Direct Bill',
              });
            }
            setValue('paymentCollectedBy', {
              value: policy.paymentCollectedBy,
              label: policy.paymentCollectedBy,
            });
            if (policy.Contacts[0].ContactDetails[0]?.type === 'EMAIL') {
              setEmailSearch(policy.Contacts[0].ContactDetails[0]?.value);
              setValue(
                'phoneNumber',
                policy.Contacts[0].ContactDetails[1]?.value
              );
            } else {
              setEmailSearch(policy.Contacts[0].ContactDetails[1]?.value);
              setValue(
                'phoneNumber',
                policy.Contacts[0].ContactDetails[0]?.value
              );
            }
            setContactUuid(policy.Contacts[0]?.uuid);
            if (policy?.Issuer) {
              setCarrierType('CARRIER');
              setCarrierUUID(policy?.Issuer?.Carrier.uuid);
              setCarrierSearchText(policy?.Issuer?.Carrier.name);
              setValue('issuerUUID', {
                value: policy?.Issuer?.uuid,
                label: policy?.Issuer?.name,
              });
            } else if (policy.CustomCarrier) {
              setCarrierType('CUSTOM_CARRIER');

              setCarrierUUID(policy?.CustomCarrier?.uuid);
              setCarrierSearchText(policy?.CustomCarrier?.name);
              setValue('issuerUUID', {
                value: policy?.Issuer?.uuid,
                label: policy?.Issuer?.name,
              });
            }
            setProducerSearch(
              `${policy.Agents?.User?.firstName} ${
                policy.Agents?.User.lastName
                  ? policy.Agents?.User?.lastName
                  : ''
              }`
            );
            setProducer(policy?.Agents?.uuid);
            setValue('policySoldThrough', {
              label: 'Directly appointed carrier',
              value: 'other',
            });

            if (policy.Accounts.AccountType.name === 'COMMERCIAL') {
              isCommercial(true);
            }
            if (policy.Contacts[0]?.dateOfBirth) {
              setValue(
                'dateOfBirth',
                moment(policy.Contacts[0]?.dateOfBirth).toDate()
              );
            }
            if (policy?.expiryDate) {
              setValue('expiryDate', moment(policy.expiryDate).toDate());
            }
            if (policy?.effectiveDate) {
              setValue('effectiveDate', moment(policy.effectiveDate).toDate());
            }
            if (policy?.quotePresentedDate) {
              setValue(
                'quotePresentedDate',
                moment(policy.quotePresentedDate).toDate()
              );
            }

            if (policy?.quoteWonDate) {
              setValue('quoteWonDate', moment(policy.quoteWonDate).toDate());
            }
            setValue('firstName', policy.Contacts[0].firstName);
            setValue('middleName', policy.Contacts[0]?.middleName);
            setValue('lastName', policy.Contacts[0]?.lastName);
            setAddress(policy.Accounts.Address?.name);

            if (policy.Accounts.Address?.isManuallyAdded) {
              setIsManual(true);
              setValue('address', policy.Accounts.Address?.name);
            } else {
              setAddressObject({
                address: policy.Accounts.Address?.name,
                city: policy.Accounts.Address?.city,
                state: policy.Accounts.Address?.state,
                country: policy.Accounts.Address?.country,
                zipCode: policy.Accounts.Address?.zipCode,
              });
            }
            setValue('zipCode', policy.Accounts.Address?.zipCode);
            setValue('city', policy.Accounts.Address?.city);
            setValue('state', policy.Accounts.Address?.state);
            setValue('country', policy.Accounts.Address?.country);
            setValue('apartment', policy.Accounts.Address?.street);
            setValue(
              'UWContactName',
              policy.UWContactName ? policy.UWContactName : ''
            );
            setValue('UWEmail', policy.UWEmail);
            setValue('UWPhone', policy.UWPhone);
            setValue('policyNumber', policy.policyNumber);
            setValue('mgaFees', policy.mgaFees);
            setValue('esTax', policy.esTax);
            setValue('agencyFees', policy.agencyFees);
            setValue('otherFees', policy.otherFees);
            setValue('totalAmount', policy.totalAmount);
            setValue('amountCollected', policy.amountCollected);

            setValue('paymentAmount', policy.paymentAmount);
            setValue('paymentType', policy.paymentType);
            setValue('paymentCollectionMethod', {
              value: policy.paymentCollectionMethod,
              label: policy.paymentCollectionMethod,
            });
            if (policy.premiumFinance) {
              setValue('premiumFinance', {
                value: 'true',
                label: 'Yes',
              });
            } else {
              setValue('premiumFinance', {
                value: 'false',
                label: 'No',
              });
            }
            if (policy.requireCOI) {
              setValue('requireCOI', {
                value: policy.requireCOI,
                label: policy.requireCOI,
              });
            }
            setValue('premiumFinanceAmount', policy.premiumFinanceAmount);
            setValue(
              'premiumDownPaymentAmount',
              policy.premiumDownPaymentAmount
            );

            let policyTermValue = policyTerm.filter(
              (item) => item.value === policy.policyTerm
            );
            if (policyTermValue.length > 0) {
              setValue('policyTerm', {
                label: policy.policyTerm,
                value: policy.policyTerm,
              });
            } else {
              setValue('policyTermMonth', policy.policyTerm.split(' ')[0]);
              setValue('policyTerm', { label: 'Custom', value: 'custom' });
            }
            setValue('paymentType', {
              value: policy.paymentType,
              label: policy.paymentType,
            });
            setValue('transactionType', {
              value: policy.transactionType,
              label: policy.transactionType,
            });
            setValue('premium', policy.premium);
            setValue('maritalStatus', {
              value: policy.Contacts[0]?.maritalStatus,
              label: policy.Contacts[0]?.maritalStatus,
            });

            setValue(
              'premiumFinanceCompanyName',
              policy.premiumFinanceCompanyName
            );
            setValue('paymentAmount', policy.paymentAmount);

            setValue(
              'driverlicenseNumber',
              policy.Contacts[0].driverlicenseNumber
            );

            if (policy.Accounts.AccountType?.name === 'COMMERCIAL') {
              setValue('businessName', policy.Accounts.name);

              setValue('businessEmail', policy.Accounts.businessEmail);

              setValue(
                'businessPhoneNumber',
                policy.Accounts.businessPhoneNumber
              );
              setValue('payroll', policy.Accounts.payroll);
              setValue('employeeCount', policy.Accounts.employeeCount);
              setValue('annualRevenue', policy.Accounts.annualRevenue);
              setValue(
                'businessStartedYear',
                policy.Accounts.businessStartedYear
              );

              setValue('businessApt', policy.Accounts.Address?.street);
              if (
                policy?.Accounts?.Industry !== '' &&
                policy?.Accounts?.Industry !== null
              ) {
                setIndustrySearch(
                  `${policy?.Accounts?.Industry?.naicsSixDigitCode} - ${policy?.Accounts?.Industry?.naicsSixDigitCodeDescription}`
                );
                setIndustryUuid(policy?.Accounts?.Industry?.uuid);
              }
            }

            setValue('accountType', policy.Accounts.AccountType?.name);
            setTimeout(() => {
              if (policy.PolicyCustomField?.length > 0) {
                policy.PolicyCustomField.map((item: any) => {
                  if (
                    item.CustomField.FieldType?.name === 'DATEPICKER' ||
                    item.CustomField.FieldType?.name === 'TIME'
                  ) {
                    setValue(
                      item.CustomField.uuid,
                      moment(item.value).toDate()
                    );
                  } else {
                    setValue(item.CustomField.uuid, item.value);
                  }
                });
              }
            }, 500);
            setPolicyDetailLoading(false);
          },
        })
      );
    } // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const navigate = useNavigate();

  const loadEmail = (value: string) => {
    setSubmit(false);
    setEmailError(false);
    if (loadingStatus === 'idle' || loadingStatus === 'succeeded') {
      fetchContacts('id', 'asc', emailSearch, 25, 0, 'True', (data: any) => {
        setEmailAccounts(data);
        setLoadingStatus('succeeded');
      });
    }
  };

  useEffect(() => {
    if (user?.UserRole) {
      if (user?.UserRole?.name === 'AGENT') {
        setProducerSearch('Me');
      }
    }
  }, [user]);

  const resetMGA = (value: string) => {
    setMgaUUID(value);
  };

  useEffect(() => {
    if (watchBill?.value === 'Direct Bill') {
      setValue('premiumFinance', '-');
      setValue('amountCollected', null);
      setValue('premiumFinanceAmount', null);
      setValue('premiumFinanceCompanyName', '-');
      setValue('premiumDownPaymentAmount', null);
      setValue('paymentCollectionMethod', null);
    } // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [watchBill?.value]);

  useEffect(() => {
    if (carrierUUID) {
      if (carrierSearchText) {
        getIssuer(carrierSearchText, carrierUUID, (data: any) => {
          setIssuingCompany(data);
          setLoadingStatus('succeeded');
        });
      }
    } else {
      setIssuingCompany([]);
    }
    if (!carrierSearchText) {
      setIssuingCompany([]);
      setValue('issuerUUID', {
        value: '',
        label: '',
      });
    }
  }, [carrierUUID, carrierSearchText]);

  const resetEmail = (value: string) => {
    setEmailData([]);
    setEmailError(false);
  };

  useEffect(() => {
    const user = JSON.parse(localStorage.getItem('user') || '{}');
    if (user) {
      setUser(user);
    }
  }, []);

  const loadIndustry = (value: string) => {
    if (loadingStatus === 'idle' || loadingStatus === 'succeeded') {
      setLoadingStatus('loading');
      fetchIndustries(
        (data: any) => {
          setIndustry(data);
          setLoadingStatus('succeeded');
        },
        'id',
        'asc',
        value,
        'True'
      );
    }
  };
  useEffect(() => {
    setValue(
      'totalAmount',
      String(
        parseFloat(watchPremium) +
          parseFloat(watchAgency || 0) +
          parseFloat(watchMga || 0) +
          parseFloat(watchTax || 0)
      )
    ); // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [watchPremium, watchAgency, watchMga, watchTax]);

  useEffect(() => {
    if (watchPremiumFinance?.value === 'true') {
      setValue(
        'premiumDownPaymentAmount',
        String(
          parseFloat(watchPremium) - parseFloat(watchPremiumFinanceAmount || 0)
        )
      );
    } // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [watchPremium, watchPremiumFinanceAmount]);

  useEffect(() => {
    if (
      watchBill?.value === 'Agency' &&
      watchPremiumFinance?.value === 'false' &&
      watchPremiumDownPaymentAmount === ''
    ) {
      setValue(
        'amountCollected',
        String(
          parseFloat(watchPremium) +
            parseFloat(watchAgency || 0) +
            parseFloat(watchMga || 0) +
            parseFloat(watchTax || 0)
        )
      );
    } else if (
      watchBill?.value === 'Agency' &&
      watchPremiumFinance?.value === 'true'
    ) {
      if (watchPremiumDownPaymentAmount !== '') {
        setValue(
          'amountCollected',
          String(
            parseFloat(watchPremiumDownPaymentAmount) +
              parseFloat(watchAgency || 0) +
              parseFloat(watchMga || 0) +
              parseFloat(watchTax || 0)
          )
        );
      }
    } // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    watchPremiumDownPaymentAmount,
    watchPremium,
    watchAgency,
    watchMga,
    watchTax,
    watchPremiumFinance,
  ]);

  const loadProducer = (value: string) => {
    if (
      loadingStatus === 'idle' ||
      loadingStatus === 'succeeded' ||
      user?.UserRole?.name === 'PRINCIPAL_AGENT'
    ) {
      setLoadingStatus('loading');
      fetchAgents(
        (data: IProducer[]) => {
          setProducers(data);
          setLoadingStatus('succeeded');
        },
        'id',
        'asc',
        value,
        'True'
      );
    }
  };

  const loadCarrier = (value: string) => {
    if (loadingStatus === 'idle' || loadingStatus === 'succeeded') {
      setLoadingStatus('loading');
      fetchCarriers('id', 'asc', value, 'True', (data: any) => {
        setCarriers(data);
        setLoadingStatus('succeeded');
      });
    }
  };

  const loadMGA = (value: string) => {
    if (loadingStatus === 'idle' || loadingStatus === 'succeeded') {
      setLoadingStatus('loading');
      fetchMGA(value, (data: any) => {
        setMGA(data);
        setLoadingStatus('succeeded');
      });
    }
  };

  useEffect(() => {
    setValue('zipCode', addressObject.zipCode);
    setValue('city', addressObject.address);
    setValue('state', addressObject.state);
    setValue('country', addressObject.country);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [addressObject]);

  useEffect(() => {
    if (
      watchAllFields.effectiveDate &&
      watchAllFields.policyTerm?.value === 'custom' &&
      Number(watchAllFields.policyTermMonth) < 100
    ) {
      let dateEffective = new Date(watchAllFields.effectiveDate);

      setValue(
        'expiryDate',
        moment(
          new Date(
            dateEffective.setDate(
              dateEffective.getDate() +
                Number(watchAllFields.policyTermMonth) * 30
            )
          )
        ).toDate()
      );
    } else if (
      watchAllFields.policyTerm?.value !== 'custom' &&
      watchAllFields.policyTerm?.value &&
      watchAllFields.effectiveDate
    ) {
      const month = watchAllFields.policyTerm?.value.split(' ')[0];
      let dateEffective = new Date(watchAllFields.effectiveDate);
      setValue(
        'expiryDate',
        moment(
          new Date(
            dateEffective.setDate(dateEffective.getDate() + Number(month) * 30)
          )
        ).toDate()
      );
    }
  }, [
    watchAllFields.policyTerm,
    watchAllFields.effectiveDate,
    watchAllFields.policyTermMonth,
  ]);

  return (
    <>
      <SuccessAlign>
        <div className={`success-container ${showSuccess && 'open-drawer'} `}>
          <SuccessCard
            title={`<b>Success Updating Policy!</b>`}
            message={`</b> Policy has been updated`}
          />
          <button
            onClick={() => {
              reset();
              setShowSuccess(false);
            }}
            className='crossbutton'
          >
            <img src={cross} className='cross' alt='cross-icon' />
          </button>
        </div>
      </SuccessAlign>
      <StyledEditDetailsPage>
        <Modal open={show}>
          <div className='entire-div'>
            <div className='topdiv'>
              <h2 className='d-none d-sm-none d-xl-block'>Edit Policy</h2>
              <div className='d-flex align-items-center d-md-none d-sm-block d-none d-xl-none'>
                <h2 className=''>Edit Policy</h2>
              </div>
              <div className='d-block d-sm-flex d-sm-none d-lg-none d-xl-none'>
                <h2 className=''>Edit Policy</h2>
                <p className=''>Click on the details to edit</p>
              </div>
              <div className='d-none d-md-block d-md-flex d-sm-none d-lg-none d-xl-none align-items-center'>
                <h2 className=''>Edit Policy</h2>
                <img className='barImg' src={bar} alt='bar-icon' />
                <p className='tab-head'>Click on the details to edit</p>
              </div>

              <div>
                <button className='cross-btn'>
                  <img
                    className='cross'
                    src={cross}
                    alt='cross-icon'
                    onClick={() => {
                      reset();
                      onCloseModal();
                    }}
                  />
                </button>
              </div>
            </div>
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className='scroll-bar  '>
                <div className='d-flex align-items-center'>
                  {/* <h2 className='contact-info'>Contact info</h2> */}
                </div>
                {commercial && (
                  <>
                    <div className='CommercialDiv'>
                      <div className='d-flex align-items-center'>
                        <h2 className='contact-info'>
                          {policy
                            ? policy.Accounts?.name
                            : policyDetails && policyDetails.Accounts?.name}
                        </h2>
                      </div>
                      <div className='d-flex flex-wrap'>
                        <div className='fields '>
                          {deailsStatus === 'loading' ? (
                            <Skeleton height={55} />
                          ) : (
                            <>
                              <EditInput
                                name='businessName'
                                type='text'
                                placeholder='Business name'
                                register={register}
                              />
                              {!watchAllFields.businessName && businessError ? (
                                <div className='errorVal'>
                                  <img
                                    src={warning}
                                    alt='warning'
                                    className='warning'
                                  />
                                  {businessError}
                                </div>
                              ) : null}
                            </>
                          )}
                        </div>

                        <div className='fields '>
                          {deailsStatus === 'loading' ? (
                            <Skeleton height={55} />
                          ) : (
                            <>
                              <SearchDropdown
                                name='industry'
                                placeholder='Industry (Optional)'
                                isEdit={true}
                                isEditDetail={true}
                                values={industrySearch}
                                searchValue={industry}
                                setSearch={setIndustrySearch}
                                status={loadingStatus}
                                className={`${industrySearch && 'filled'}`}
                                register={register}
                                control={control}
                                loadFunction={loadIndustry}
                                setUUID={setIndustryUuid}
                                content={industry?.map((item: any) => (
                                  <div
                                    className='searchValuesCard d-flex align-items-center account-details'
                                    onClick={() => {
                                      setIndustrySearch(
                                        `${item.naicsSixDigitCode} - ${item.naicsSixDigitCodeDescription}`
                                      );
                                      setIndustryUuid(item?.uuid);
                                    }}
                                  >
                                    <div>
                                      <h6>
                                        {item.naicsSixDigitCode} -{' '}
                                        {item.naicsSixDigitCodeDescription}
                                      </h6>
                                      <p className='description'>
                                        {item.naicsFourDigitCodeDescription}
                                      </p>
                                    </div>
                                  </div>
                                ))}
                              />
                              {industrySearch && !industryUuid && submit ? (
                                <div className='errorVal'>
                                  <img
                                    src={warning}
                                    alt='warning'
                                    className='warning'
                                  />
                                  Industry not found
                                </div>
                              ) : (
                                ''
                              )}
                            </>
                          )}
                        </div>

                        <div className='fields '>
                          {deailsStatus === 'loading' ? (
                            <Skeleton height={55} />
                          ) : (
                            <>
                              <EditPhoneNumber
                                name='businessPhoneNumber'
                                type='tel'
                                placeholder='Business Phone number'
                                control={control}
                                className={`${
                                  errors.businessPhoneNumber && 'error-field'
                                } ${
                                  watchAllFields.businessPhoneNumber && 'filled'
                                }`}
                              />
                              {errors.businessPhoneNumber ? (
                                <div className='errorVal'>
                                  <img
                                    src={warning}
                                    alt='warning'
                                    className='warning warning-small'
                                  />
                                  Please enter Business Phone Number
                                </div>
                              ) : null}
                            </>
                          )}
                        </div>
                        <div className='fields '>
                          {deailsStatus === 'loading' ? (
                            <Skeleton height={55} />
                          ) : (
                            <EditInput
                              name='businessEmail'
                              type='email'
                              placeholder='Business email address'
                              register={register}
                            />
                          )}
                        </div>

                        <div className='fields '>
                          {deailsStatus === 'loading' ? (
                            <Skeleton height={55} />
                          ) : (
                            <EditInput
                              name='employeeCount'
                              type='number'
                              placeholder='Number of employees'
                              register={register}
                            />
                          )}
                        </div>

                        <div className='fields '>
                          {deailsStatus === 'loading' ? (
                            <Skeleton height={55} />
                          ) : (
                            <EditInput
                              name='businessStartedYear'
                              type='number'
                              placeholder='Year business started'
                              register={register}
                            />
                          )}
                        </div>
                        <div className='fields '>
                          {deailsStatus === 'loading' ? (
                            <Skeleton height={55} />
                          ) : (
                            <>
                              <EditInput
                                name='payroll'
                                type='number'
                                placeholder='Total payroll'
                                register={register}
                              />
                              {errors.payroll && watchAllFields.payroll < 0 ? (
                                <div className='errorVal'>
                                  <img
                                    src={warning}
                                    alt='warning'
                                    className='warning'
                                  />
                                  {errors.payroll.message}
                                </div>
                              ) : null}
                            </>
                          )}
                        </div>
                        <div className='fields '>
                          {deailsStatus === 'loading' ? (
                            <Skeleton height={55} />
                          ) : (
                            <EditInput
                              name='annualRevenue'
                              type='number'
                              placeholder='Annual revenue'
                              register={register}
                            />
                          )}
                        </div>
                      </div>
                    </div>
                    <h6 className='mailingAdressHead'>BUSINESS ADDRESS</h6>
                    <div className='  d-sm-flex flex-wrap'>
                      <div className='fields '>
                        {deailsStatus === 'loading' ? (
                          <Skeleton height={55} />
                        ) : (
                          <>
                            {isManual ? (
                              <EditInput
                                name='address'
                                type='text'
                                placeholder='Address'
                                register={register}
                              />
                            ) : (
                              <GoogleSearchInput
                                placeholder='Address'
                                name='businessaddress'
                                inputType='EDIT'
                                initialValue={address}
                                setAddressObject={setAddressObject}
                                addressObject={addressObject}
                                setIsManual={setIsManual}
                              ></GoogleSearchInput>
                            )}
                          </>
                        )}
                      </div>

                      <div className='fields '>
                        {deailsStatus === 'loading' ? (
                          <Skeleton height={55} />
                        ) : (
                          <EditInput
                            name='zipCode'
                            type='number'
                            placeholder='Zipcode'
                            register={register}
                          />
                        )}
                      </div>
                      <div className='fields '>
                        {deailsStatus === 'loading' ? (
                          <Skeleton height={55} />
                        ) : (
                          <EditInput
                            name='city'
                            type='text'
                            placeholder='City'
                            register={register}
                          />
                        )}
                      </div>
                      <div className='fields '>
                        {deailsStatus === 'loading' ? (
                          <Skeleton height={55} />
                        ) : (
                          <EditInput
                            name='state'
                            type='text'
                            placeholder='State'
                            register={register}
                          />
                        )}
                      </div>

                      <div className='fields '>
                        {deailsStatus === 'loading' ? (
                          <Skeleton height={55} />
                        ) : (
                          <EditInput
                            name='country'
                            type='text'
                            placeholder='County'
                            register={register}
                          />
                        )}
                      </div>
                      <div className='fields '>
                        {deailsStatus === 'loading' ? (
                          <Skeleton height={55} />
                        ) : (
                          <EditInput
                            name='businessApt'
                            type='text'
                            placeholder='Apt'
                            register={register}
                          />
                        )}
                      </div>
                    </div>
                    <hr />
                  </>
                )}
                <div className='editHeading'>
                  <h2 className='contact-info '>Contact info</h2>
                </div>
                <div className=' d-flex flex-wrap'>
                  <div className='fields '>
                    {deailsStatus === 'loading' ? (
                      <Skeleton height={55} />
                    ) : (
                      <>
                        <EditInput
                          name='firstName'
                          type='text'
                          placeholder='First name'
                          register={register}
                        />
                        {errors.firstName ? (
                          <div className='errorVal'>
                            <img
                              src={warning}
                              alt='warning'
                              className='warning'
                            />
                            {errors.firstName?.message}
                          </div>
                        ) : null}
                      </>
                    )}
                  </div>

                  <div className='fields '>
                    {deailsStatus === 'loading' ? (
                      <Skeleton height={55} />
                    ) : (
                      <>
                        <EditInput
                          name='middleName'
                          type='text'
                          placeholder='Middle name'
                          register={register}
                        />
                        {errors.middleName ? (
                          <div className='errorVal'>
                            <img
                              src={warning}
                              alt='warning'
                              className='warning'
                            />
                            {errors.middleName?.message}
                          </div>
                        ) : null}
                      </>
                    )}
                  </div>
                  <div className='fields '>
                    {deailsStatus === 'loading' ? (
                      <Skeleton height={55} />
                    ) : (
                      <>
                        <EditInput
                          name='lastName'
                          type='text'
                          placeholder='Last name'
                          register={register}
                          required
                        />
                        {errors.lastName || (!watchLastName && submit) ? (
                          <div className='errorVal'>
                            <img
                              src={warning}
                              alt='warning'
                              className='warning'
                            />
                            {errors.lastName?.message || 'Last name required'}
                          </div>
                        ) : null}
                      </>
                    )}
                  </div>
                  <div className='fields '>
                    {deailsStatus === 'loading' ? (
                      <Skeleton height={55} />
                    ) : (
                      <>
                        <EditDate
                          name='dateOfBirth'
                          placeholder='Date of birth'
                          maxDate={new Date()}
                          dateOfBirth
                          control={control}
                        />
                      </>
                    )}
                  </div>

                  <div className='fields '>
                    {deailsStatus === 'loading' ? (
                      <Skeleton height={55} />
                    ) : (
                      <>
                        <EditPhoneNumber
                          name='phoneNumber'
                          type='tel'
                          placeholder='Phone number'
                          control={control}
                          required
                        />
                      </>
                    )}
                  </div>
                  <div className='fields '>
                    {deailsStatus === 'loading' ? (
                      <Skeleton height={55} />
                    ) : (
                      <>
                        <SearchDropdown
                          name='email'
                          addName={true}
                          placeholder='Email'
                          isEdit={true}
                          isEditDetail={true}
                          values={emailSearch}
                          searchValue={emailAccounts}
                          setSearch={setEmailSearch}
                          status={loadingStatus}
                          required
                          className={`${emailSearch && 'filled'}`}
                          register={register}
                          control={control}
                          type='email'
                          setUUID={resetEmail}
                          loadFunction={loadEmail}
                          content={emailAccounts?.map((item) => (
                            <div
                              className='searchValuesCard d-flex align-items-center'
                              onClick={() => {
                                setEmailSearch(
                                  item?.ContactDetails?.find(
                                    (value: any) => value.type === 'EMAIL'
                                  )?.value
                                );
                                setEmailError(true);
                                setEmailData([
                                  `${item?.firstName} ${item?.lastName}`,
                                  item?.uuid,
                                ]);
                              }}
                            >
                              <div className='profile-pic d-flex align-items-center justify-content-center'>
                                {item?.firstName?.charAt(0)}
                              </div>
                              <div>
                                <h6>
                                  {item?.firstName} {item?.lastName}
                                </h6>
                                <ul className='user-details'>
                                  <li>
                                    {
                                      item?.ContactDetails?.find(
                                        (value: any) => value.type === 'EMAIL'
                                      )?.value
                                    }
                                  </li>
                                  <li>
                                    {
                                      item?.ContactDetails?.find(
                                        (value: any) =>
                                          value.type === 'PHONENUMBER'
                                      )?.value
                                    }
                                  </li>
                                </ul>
                              </div>
                            </div>
                          ))}
                        />
                        {emailError ? (
                          <div className='errorVal emailError'>
                            <img
                              src={warning}
                              alt='warning'
                              className='warning'
                            />
                            <div>
                              This email is already associated with another
                              contact{' '}
                              <span
                                onClick={() =>
                                  navigate(`/contacts/${emailData[1]}`)
                                }
                              >
                                {emailData[0]}
                              </span>{' '}
                              Please use a different email.
                            </div>
                          </div>
                        ) : (
                          [
                            !emailSearch && submit ? (
                              <div className='errorVal'>
                                <img
                                  src={warning}
                                  alt='warning'
                                  className='warning'
                                />
                                Please enter your email
                              </div>
                            ) : (
                              errors.email?.message && (
                                <div className='errorVal'>
                                  <img
                                    src={warning}
                                    alt='warning'
                                    className='warning'
                                  />
                                  {errors.email?.message}
                                </div>
                              )
                            ),
                          ]
                        )}
                      </>
                    )}
                  </div>
                  <div className='fields '>
                    {deailsStatus === 'loading' ? (
                      <Skeleton height={55} />
                    ) : (
                      <>
                        <EditSelect
                          control={control}
                          type='text'
                          name='maritalStatus'
                          placeholder='Marital status'
                          options={MaritalStatus}
                        />
                      </>
                    )}
                  </div>
                </div>
                {!commercial ? (
                  <div>
                    <h6 className='mailingAdressHead'>MAILING ADDRESS</h6>
                    <div className='d-flex flex-wrap'>
                      <div className='fields '>
                        {deailsStatus === 'loading' ? (
                          <Skeleton height={55} />
                        ) : (
                          <>
                            {isManual ? (
                              <EditInput
                                name='address'
                                type='text'
                                placeholder='Address'
                                register={register}
                              />
                            ) : (
                              <GoogleSearchInput
                                placeholder='Address'
                                name='address'
                                inputType='EDIT'
                                initialValue={address}
                                setAddressObject={setAddressObject}
                                addressObject={addressObject}
                                setIsManual={setIsManual}
                              ></GoogleSearchInput>
                            )}
                          </>
                        )}
                      </div>
                      <div className='fields '>
                        {deailsStatus === 'loading' ? (
                          <Skeleton height={55} />
                        ) : (
                          <EditInput
                            name='zipCode'
                            type='number'
                            placeholder='ZipCode'
                            register={register}
                          />
                        )}
                      </div>
                      <div className='fields '>
                        {deailsStatus === 'loading' ? (
                          <Skeleton height={55} />
                        ) : (
                          <EditInput
                            name='city'
                            type='text'
                            placeholder='City'
                            register={register}
                          />
                        )}
                      </div>
                      <div className='fields '>
                        {deailsStatus === 'loading' ? (
                          <Skeleton height={55} />
                        ) : (
                          <EditInput
                            name='state'
                            type='text'
                            placeholder='State'
                            register={register}
                          />
                        )}
                      </div>

                      <div className='fields '>
                        {deailsStatus === 'loading' ? (
                          <Skeleton height={55} />
                        ) : (
                          <EditInput
                            name='country'
                            type='text'
                            placeholder='County'
                            register={register}
                          />
                        )}
                      </div>
                      <div className='fields '>
                        {deailsStatus === 'loading' ? (
                          <Skeleton height={55} />
                        ) : (
                          <EditInput
                            name='apartment'
                            type='text'
                            placeholder='Apt'
                            register={register}
                          />
                        )}
                      </div>
                    </div>
                  </div>
                ) : null}
                <hr />
                <div className='d-flex align-items-center'>
                  <h2 className='contact-info'>
                    {policy
                      ? policy.ProductCategory?.name
                      : policyDetails && policyDetails.ProductCategory?.name}
                  </h2>
                </div>
                <div className='d-flex flex-wrap'>
                  <div className='fields '>
                    <SearchDropdown
                      name='producer'
                      placeholder='Select producer'
                      isEdit={true}
                      isEditDetail={true}
                      addName={true}
                      values={producerSearch}
                      searchValue={producers}
                      setSearch={setProducerSearch}
                      status={loadingStatus}
                      className={`${producerSearch && 'filled'}`}
                      register={register}
                      control={control}
                      loadFunction={loadProducer}
                      setUUID={setProducer}
                      disabled={user?.UserRole?.name === 'AGENT'}
                      content={producers?.map((item) => (
                        <div
                          className='searchValuesCard d-flex align-items-center'
                          onClick={() => {
                            setProducerSearch(
                              `${item.User.firstName} ${
                                item.User.lastName ? item.User.lastName : ''
                              }`
                            );
                            setProducer(item?.uuid);
                          }}
                        >
                          <div className='profile-pic d-flex align-items-center justify-content-center'>
                            {item?.User?.firstName?.charAt(0)}
                            {item?.User?.lastName?.charAt(0)}
                          </div>
                          <div>
                            <h6>
                              {`${item.User.firstName} ${
                                item.User.lastName ? item.User.lastName : ''
                              }`}
                            </h6>
                            <ul className='user-details'>
                              <li>{item.User?.email}</li>
                              <li>{item?.User?.phoneNumber}</li>
                            </ul>
                          </div>
                        </div>
                      ))}
                    />
                    {producerSearch &&
                    producerSearch !== 'Me' &&
                    !producer &&
                    submit ? (
                      <div className='errorVal'>
                        <img src={warning} alt='warning' className='warning' />
                        Producer not found
                      </div>
                    ) : (
                      ''
                    )}
                  </div>
                  <div className='fields '>
                    <SelectField
                      options={policySoldThrough}
                      name='policySoldThrough'
                      type='text'
                      disabled
                      placeholder='Policy sold through'
                      register={register}
                      control={control}
                    />
                  </div>
                </div>
                <div>
                  <h6 className='mailingAdressHead'>CARRIER DETAILS</h6>
                </div>
                <div className='d-flex flex-wrap'>
                  <div className='fields'>
                    <SearchDropdown
                      name='opportunityCarrierName'
                      placeholder='Carrier name'
                      isEdit={true}
                      isEditDetail={true}
                      values={carrierSearchText}
                      searchValue={carriers}
                      setSearch={setCarrierSearchText}
                      status={loadingStatus}
                      className={`${carrierSearchText && 'filled'}`}
                      loadFunction={loadCarrier}
                      setUUID={setCarrierUUID}
                      required
                      setProductUUID={setCarrierUUID}
                      content={carriers?.map((item: any) => (
                        <div
                          className='searchValuesCard d-flex align-items-center'
                          onClick={() => {
                            setCarrierSearchText(item?.name);
                            setCarrierUUID(item?.uuid);
                            setCarrierType(item.type);
                          }}
                        >
                          {item.logo ? (
                            <img
                              src={item.logo}
                              alt=''
                              className='profile-pic'
                            />
                          ) : (
                            <div className='profile-pic d-flex align-items-center justify-content-center'>
                              {item?.name?.charAt(0)}
                              {item?.name?.charAt(0)}
                            </div>
                          )}
                          <div>
                            <h6>{item.name}</h6>
                          </div>
                        </div>
                      ))}
                    />
                    {!carrierUUID && submit ? (
                      <div className='errorVal'>
                        <img src={warning} alt='warning' className='warning' />
                        Carrier is required
                      </div>
                    ) : null}
                  </div>
                  <div className='fields '>
                    <SelectField
                      options={issuingCompany.map((item: any) => {
                        return { label: item.name, value: item.uuid };
                      })}
                      disabled={carrierType === 'CUSTOM_CARRIER'}
                      name='issuerUUID'
                      placeholder='Issuing company'
                      control={control}
                      required
                    />
                  </div>
                </div>
                {watchAllFields.policySoldThrough?.value === 'MGA' ? (
                  <>
                    <div>
                      <h6 className='mailingAdressHead'>MGA DETAILS</h6>
                    </div>
                    <div className='d-flex flex-wrap'>
                      <div className='fields '>
                        <SearchDropdown
                          name='mgaUUID'
                          placeholder='MGA’s name'
                          isEdit={true}
                          isEditDetail={true}
                          values={mgaSearchText}
                          searchValue={MGA}
                          setSearch={setMgaSearchText}
                          status={loadingStatus}
                          className={`${mgaSearchText && 'filled'}`}
                          loadFunction={loadMGA}
                          setUUID={resetMGA}
                          content={MGA?.map((item: any) => (
                            <div
                              className='searchValuesCard d-flex align-items-center'
                              onClick={() => {
                                setMgaSearchText(item?.name);
                                setMgaUUID(item?.uuid);
                              }}
                            >
                              <div>
                                <h6>{item.name}</h6>
                              </div>
                            </div>
                          ))}
                        />
                        {watchAllFields.policySoldThrough?.value === 'MGA' &&
                        submit &&
                        !mgaUUID ? (
                          <div className='errorVal'>
                            <img
                              src={warning}
                              alt='warning'
                              className='warning'
                            />
                            MGA's name reuired
                          </div>
                        ) : (
                          ''
                        )}
                      </div>
                      <div className='fields '>
                        <EditInput
                          name='UWContactName'
                          type='text'
                          placeholder='UW contact name'
                          register={register}
                        />
                        {/* {errors.UWContactName ? (
                          <div className='errorVal'>
                            <img
                              src={warning}
                              alt='warning'
                              className='warning'
                            />
                            {errors.UWContactName?.message}
                          </div>
                        ) : null} */}
                        {watchAllFields.policySoldThrough?.value === 'MGA' &&
                        submit &&
                        !watchAllFields?.UWContactName ? (
                          <div className='errorVal'>
                            <img
                              src={warning}
                              alt='warning'
                              className='warning'
                            />
                            UW phone number required
                          </div>
                        ) : (
                          ''
                        )}
                      </div>

                      <div className='fields '>
                        <EditPhoneNumber
                          name='UWPhone'
                          type='tel'
                          placeholder='UW phone number'
                          control={control}
                        />
                        {watchAllFields.UWPhone &&
                        !/[(][0-9]{3}[)][-\s][0-9]{3}[-][0-9]{4}$/.test(
                          watchAllFields?.UWPhone
                        ) ? (
                          <div className='errorVal'>
                            <img
                              src={warning}
                              alt='warning'
                              className='warning'
                            />
                            Please enter valid Phone number
                          </div>
                        ) : null}
                        {watchAllFields.policySoldThrough?.value === 'MGA' &&
                        submit &&
                        !watchAllFields?.UWPhone ? (
                          <div className='errorVal'>
                            <img
                              src={warning}
                              alt='warning'
                              className='warning'
                            />
                            UW phone number required
                          </div>
                        ) : (
                          ''
                        )}
                      </div>
                      <div className='fields '>
                        <EditInput
                          name='UWEmail'
                          type='text'
                          placeholder='UW email address '
                          register={register}
                        />
                        {watchAllFields.policySoldThrough?.value === 'MGA' &&
                        submit &&
                        !watchAllFields?.UWEmail ? (
                          <div className='errorVal'>
                            <img
                              src={warning}
                              alt='warning'
                              className='warning'
                            />
                            UW Email required
                          </div>
                        ) : (
                          ''
                        )}
                      </div>
                    </div>
                  </>
                ) : null}

                <div>
                  <h6 className='mailingAdressHead'>POLICY SPECIFIC DETAILS</h6>
                </div>
                <div className='d-flex flex-wrap'>
                  <div className='fields'>
                    <SelectField
                      options={policyTerm}
                      name='policyTerm'
                      type='text'
                      placeholder='Policy term'
                      register={register}
                      control={control}
                      isEdit={true}
                      required
                    />
                    {errors.policyTerm ? (
                      <div className='errorVal'>
                        <img src={warning} alt='warning' className='warning' />
                        {errors.policyTerm?.message
                          ? 'Policy term required'
                          : errors.policyTerm?.value?.message}
                      </div>
                    ) : null}
                  </div>
                  {watchPolicyTerm?.value === 'custom' ? (
                    <div className='fields '>
                      <EditInput
                        name='policyTermMonth'
                        type='number'
                        placeholder='Policy term in months'
                        register={register}
                        month='months'
                        required
                      />
                      {watchPolicyTerm?.value === 'custom' &&
                      (!watchPolicyTermMonth ||
                        Number(watchPolicyTermMonth) > 99) ? (
                        <div className='errorVal'>
                          <img
                            src={warning}
                            alt='warning'
                            className='warning'
                          />
                          Please enter a valid month
                        </div>
                      ) : null}
                      {errors.policyTermMonth?.message && (
                        <div className='errorVal'>
                          <img
                            src={warning}
                            alt='warning'
                            className='warning'
                          />
                          {errors.policyTermMonth?.message}
                        </div>
                      )}
                    </div>
                  ) : null}
                  <div className='fields '>
                    <EditDate
                      name='effectiveDate'
                      placeholder='Effective Date'
                      control={control}
                      required
                    />
                    {errors.effectiveDate?.message && (
                      <div className='errorVal'>
                        <img src={warning} alt='warning' className='warning' />
                        {errors.effectiveDate?.message}
                      </div>
                    )}
                  </div>
                  <div className='fields '>
                    <EditDate
                      name='expiryDate'
                      placeholder='Expiry date'
                      readOnly={true}
                      control={control}
                      required
                    />
                    {errors.expiryDate?.message && (
                      <div className='errorVal'>
                        <img src={warning} alt='warning' className='warning' />
                        {errors.expiryDate?.message}
                      </div>
                    )}
                  </div>
                  <div className='fields '>
                    <EditDate
                      name='quoteWonDate'
                      placeholder='Quote won date'
                      control={control}
                    />
                  </div>
                  <div className='fields '>
                    <EditDate
                      name='quotePresentedDate'
                      placeholder='Quote presented date'
                      control={control}
                    />
                  </div>
                  <div className='fields '>
                    <EditInput
                      name='policyNumber'
                      type='number'
                      placeholder='Policy no. '
                      register={register}
                      required
                    />
                    {errors.policyNumber?.message && (
                      <div className='errorVal'>
                        <img src={warning} alt='warning' className='warning' />
                        {errors.policyNumber?.message}
                      </div>
                    )}
                  </div>
                </div>
                <div className='d-flex flex-wrap'>
                  <div className='fields'>
                    <SelectField
                      options={billType}
                      name='billType'
                      type='text'
                      placeholder='Bill type'
                      register={register}
                      control={control}
                      isEdit={true}
                      required
                    />
                  </div>

                  <div className='fields '>
                    <SelectField
                      options={paymentType}
                      name='paymentType'
                      type='text'
                      placeholder='Payment type'
                      register={register}
                      control={control}
                    />
                  </div>
                </div>
                <div className='d-flex flex-wrap'>
                  <div className='fields '>
                    <SelectField
                      options={premiumFinance}
                      name='premiumFinance'
                      type='text'
                      placeholder='Premium finance'
                      register={register}
                      disabled={watchBill?.value === 'Direct Bill'}
                      control={control}
                    />
                  </div>
                  <div className='fields '>
                    <EditInput
                      name='premiumFinanceCompanyName'
                      type='text'
                      placeholder='Premium finance company'
                      register={register}
                      disabled={
                        watchPremiumFinance?.value === 'false' ||
                        watchBill?.value === 'Direct Bill'
                      }
                    />
                  </div>
                </div>
                <div>
                  <h6 className='mailingAdressHead'>AMOUNT DETAILS</h6>
                </div>
                <div className='d-flex flex-wrap'>
                  <div className='fields '>
                    <EditInput
                      name='premium'
                      type='text'
                      placeholder='Premium amount'
                      register={register}
                      required
                    />
                    {errors.premium && watchAllFields.premium < 0 ? (
                      <div className='errorVal'>
                        <img src={warning} alt='warning' className='warning' />
                        {errors.premium.message}
                      </div>
                    ) : null}
                  </div>

                  <div className='fields '>
                    <EditInput
                      name='premiumDownPaymentAmount'
                      type='text'
                      placeholder='Premium: Down payment...'
                      register={register}
                      disabled={
                        watchPremiumFinance?.value === 'false' ||
                        watchBill?.value === 'Direct Bill'
                      }
                    />
                  </div>
                  <div className='fields '>
                    <EditInput
                      name='premiumFinanceAmount'
                      type='text'
                      placeholder='Premium financed amou...'
                      register={register}
                      disabled={
                        watchBill?.value === 'Direct Bill' ||
                        watchPremiumFinance?.value === 'false'
                      }
                    />
                  </div>

                  <div className='fields '>
                    <EditInput
                      name='mgaFees'
                      type='text'
                      placeholder='MGA fees'
                      register={register}
                    />
                    {errors.mgaFees && watchAllFields.mgaFees < 0 ? (
                      <div className='errorVal'>
                        <img src={warning} alt='warning' className='warning' />
                        {errors.mgaFees.message}
                      </div>
                    ) : null}
                  </div>
                  <div className='fields '>
                    <EditInput
                      name='esTax'
                      type='text'
                      placeholder='E&S tax'
                      register={register}
                    />
                    {errors.esTax && watchAllFields.esTax < 0 ? (
                      <div className='errorVal'>
                        <img src={warning} alt='warning' className='warning' />
                        {errors.esTax.message}
                      </div>
                    ) : null}
                  </div>

                  <div className='fields '>
                    <EditInput
                      name='agencyFees'
                      type='text'
                      placeholder='Agency fees'
                      register={register}
                    />
                    {errors.agencyFees && watchAllFields.agencyFees < 0 ? (
                      <div className='errorVal'>
                        <img src={warning} alt='warning' className='warning' />
                        {errors.agencyFees.message}
                      </div>
                    ) : null}
                  </div>

                  <div className='fields '>
                    <EditInput
                      name='otherFees'
                      type='number'
                      placeholder='Other fees / charges'
                      register={register}
                    />
                    {errors.otherFees && watchAllFields.otherFees < 0 ? (
                      <div className='errorVal'>
                        <img src={warning} alt='warning' className='warning' />
                        {errors.otherFees.message}
                      </div>
                    ) : null}
                  </div>
                  <div className='fields '>
                    <EditInput
                      name='totalAmount'
                      type='text'
                      placeholder='Total amount'
                      register={register}
                      readOnly
                    />
                    {errors.totalAmount?.message && (
                      <div className='errorVal'>
                        <img src={warning} alt='warning' className='warning' />
                        {errors.totalAmount?.message}
                      </div>
                    )}
                  </div>
                  <div className='fields '>
                    <EditInput
                      name='amountCollected'
                      type='text'
                      placeholder='Amount collected'
                      register={register}
                      readOnly
                      disabled={watchBill?.value !== 'Agency'}
                    />
                  </div>
                </div>

                <div className='d-flex flex-wrap'>
                  {customFields?.length > 0
                    ? customFields?.map((item: any, index: number) => (
                        <div className='fields'>
                          <CustomFieldInput
                            item={item}
                            register={register}
                            control={control}
                            value={watchAllFields[item.uuid]}
                            isEdit={true}
                          />
                        </div>
                      ))
                    : null}
                </div>
                <div className='buttonsDiv d-flex align-items-center '>
                  <button
                    onClick={() => {
                      reset();
                      onCloseModal();
                    }}
                    className='cancel-button'
                    type='button'
                  >
                    Cancel
                  </button>
                  <button
                    type='submit'
                    className='saveButton'
                    disabled={
                      show === 'closing' ||
                      (watchAllFields?.firstName ===
                        policyDetails?.Contacts[0]?.firstName &&
                        watchAllFields?.middleName ===
                          policyDetails?.Contacts[0]?.middleName &&
                        watchAllFields?.lastName ===
                          policyDetails?.Contacts[0]?.lastName &&
                        watchAllFields?.phoneNumber ===
                          policyDetails?.Contacts[0]?.ContactDetails.find(
                            (item: any) => item.type === 'PHONENUMBER'
                          )?.value &&
                        emailSearch ===
                          policyDetails?.Contacts[0]?.ContactDetails.find(
                            (item: any) => item.type === 'EMAIL'
                          )?.value &&
                        ((isManual &&
                          (watchAllFields?.address
                            ? watchAllFields?.address ===
                              policyDetails?.Accounts?.Address?.name
                            : true)) ||
                          (!isManual &&
                            (addressObject?.address
                              ? addressObject?.address ===
                                policyDetails?.Accounts?.Address?.name
                              : true))) &&
                        (commercial
                          ? watchAllFields?.businessName ===
                              policyDetails?.Accounts?.name &&
                            (industryUuid
                              ? industryUuid ===
                                policyDetails?.Accounts?.Industry?.uuid
                              : true) &&
                            watchAllFields?.businessPhoneNumber ===
                              policyDetails?.Accounts?.businessPhoneNumber &&
                            watchAllFields?.businessEmail ===
                              policyDetails?.Accounts?.businessEmail &&
                            watchAllFields?.businessStartedYear ===
                              policyDetails?.Accounts?.businessStartedYear &&
                            watchAllFields?.annualRevenue ===
                              policyDetails?.Accounts?.annualRevenue &&
                            watchAllFields?.employeeCount ===
                              policyDetails?.Accounts?.employeeCount &&
                            watchAllFields?.payroll ===
                              policyDetails?.Accounts?.payroll
                          : true) &&
                        (watchAllFields?.zipCode
                          ? watchAllFields?.zipCode ===
                            policyDetails?.Accounts?.Address?.zipCode
                          : true) &&
                        (watchAllFields?.state
                          ? watchAllFields?.state ===
                            policyDetails?.Accounts?.Address?.state
                          : true) &&
                        (watchAllFields?.city
                          ? watchAllFields?.city ===
                            policyDetails?.Accounts?.Address?.city
                          : true) &&
                        (watchAllFields?.country
                          ? watchAllFields?.country ===
                            policyDetails?.Accounts?.Address?.country
                          : true) &&
                        watchAllFields?.maritalStatus?.value ===
                          policyDetails?.Contacts[0]?.maritalStatus &&
                        watchAllFields?.policyTerm?.value ===
                          policyDetails?.policyTerm &&
                        watchAllFields?.policyNumber ===
                          policyDetails?.policyNumber &&
                        watchAllFields?.UWEmail === policyDetails?.UWEmail &&
                        watchAllFields?.UWPhone === policyDetails?.UWPhone &&
                        watchAllFields?.mgaFees === policyDetails?.mgaFees &&
                        watchAllFields?.esTax === policyDetails?.esTax &&
                        watchAllFields?.agencyFees ===
                          policyDetails?.agencyFees &&
                        watchAllFields?.otherFees ===
                          policyDetails?.otherFees &&
                        watchAllFields?.premium === policyDetails?.premium &&
                        watchAllFields?.paymentType?.value ===
                          policyDetails?.paymentType &&
                        watchAllFields?.transactionType?.value ===
                          policyDetails?.transactionType &&
                        ((!commercial &&
                          (watchAllFields?.apartment
                            ? watchAllFields?.apartment ===
                              policyDetails?.Accounts?.Address?.street
                            : true)) ||
                          (commercial &&
                            (watchAllFields?.businessApt
                              ? watchAllFields?.businessApt ===
                                policyDetails?.Accounts?.Address?.street
                              : true))) &&
                        watchAllFields?.issuerUUID.value ===
                          policyDetails?.Issuer?.uuid &&
                        carrierUUID === policyDetails?.Issuer?.Carrier.uuid &&
                        producer === policyDetails?.Agents?.uuid &&
                        moment(watchAllFields.effectiveDate)?.toISOString() ===
                          policyDetails?.effectiveDate &&
                        (policyDetails?.quoteWonDate === null &&
                        watchAllFields.quoteWonDate === undefined
                          ? true
                          : moment(
                              watchAllFields.quoteWonDate
                            )?.toISOString() === policyDetails?.quoteWonDate) &&
                        (policyDetails?.quotePresentedDate === null &&
                        watchAllFields.quotePresentedDate === undefined
                          ? true
                          : moment(
                              watchAllFields.quotePresentedDate
                            )?.toISOString() ===
                            policyDetails?.quotePresentedDate) &&
                        (policyDetails?.Contacts[0]?.dateOfBirth === null &&
                        watchAllFields.dateOfBirth === undefined
                          ? true
                          : moment(
                              watchAllFields?.dateOfBirth
                            ).toISOString() ===
                            policyDetails?.Contacts[0]?.dateOfBirth) &&
                        (policyDetails?.agencyBill
                          ? watchAllFields.billType?.value === 'Agency'
                          : watchAllFields.billType?.value === 'Direct Bill') &&
                        (customFields?.length > 0
                          ? customFields?.every((item: any) => {
                              const opportunityCustom =
                                policyDetails?.PolicyCustomField?.find(
                                  (el: any) => el.CustomField.uuid === item.uuid
                                );
                              if (item.FieldType.name === 'SINGLE_CHOICE') {
                                if (opportunityCustom) {
                                  return (
                                    watchAllFields[item.uuid]?.label ===
                                    opportunityCustom.value?.label
                                  );
                                } else if (
                                  opportunityCustom === undefined &&
                                  watchAllFields[item.uuid]?.label !==
                                    undefined &&
                                  watchAllFields[item.uuid]?.value !== undefined
                                ) {
                                  return false;
                                }
                                return true;
                              } else if (
                                item.FieldType.name === 'MULTIPLE_CHOICE'
                              ) {
                                if (opportunityCustom) {
                                  return (
                                    watchAllFields[item.uuid].every(
                                      (watchEl: any) => {
                                        return opportunityCustom.value.find(
                                          (opEl: any) =>
                                            opEl.label === watchEl.label
                                        );
                                      }
                                    ) &&
                                    opportunityCustom.value.every(
                                      (watchEl: any) => {
                                        return watchAllFields[item.uuid].find(
                                          (opEl: any) =>
                                            opEl.label === watchEl.label
                                        );
                                      }
                                    )
                                  );
                                } else if (
                                  opportunityCustom === undefined &&
                                  watchAllFields[item.uuid]?.length > 0
                                ) {
                                  return false;
                                }
                                return true;
                              } else {
                                if (opportunityCustom) {
                                  return (
                                    watchAllFields[item.uuid] ===
                                    opportunityCustom.value
                                  );
                                } else if (
                                  opportunityCustom === undefined &&
                                  watchAllFields[item.uuid] !== '' &&
                                  watchAllFields[item.uuid] !== undefined &&
                                  watchAllFields[item.uuid] !== false
                                ) {
                                  return false;
                                }
                                return true;
                              }
                            })
                          : true)) ||
                      policyDetailLoading
                    }
                    onClick={() => setSubmit(true)}
                  >
                    Save <img className='tick' src={tick} alt='tick-sign' />
                  </button>
                </div>
              </div>
            </form>
          </div>
        </Modal>
      </StyledEditDetailsPage>
    </>
  );
};

export default EditPolicyDetails;
