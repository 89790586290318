import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch } from '../../store';
import {
  selectContactOpportunities,
  getContactOpportunities,
  clearOpportunities,
  ContactsSliceState,
  selectContact,
  selectContactCount,
  updateContactDetailOpportunity,
} from '../../store/contacts/contactsSlice';
import {
  selectAccountOpportunities,
  getAccountOpportunities,
  clearAccountOpportunities,
  AccountsSliceState,
  selectAccount,
  selectCount,
  updateAccountDetailOpportunity,
} from '../../store/accounts/accountsSlice';
import { InnerFieldStyle } from '../../styles/InnerFieldStyles';
import plus from '../../assets/logo/blackplus.svg';
import empty from '../../assets/logo/empty.svg';
import { FilterContainer } from '../../pages/MyBook/elements/styles';
import OpportunityFilter from '../../pages/MyBook/Opportunities/components/FilterDrawer';
import { IOpportunity, IOption } from '../../interfaces/Opportunity';
import QuickAddOpportunityModal from '../QuickAddOpportunity';
import whitePlus from '../../assets/logo/plus.svg';
import ErrorCard from '../../pages/Pipeline/components/ErrorCard';
import {
  onDeleteOpportunity,
  OpportunitiesSliceState,
} from '../../store/opportunities/opportunitiesSlice';
import DeleteModal from '../DeleteModal';
import Skeleton from 'react-loading-skeleton';
import MobileInnerCard from './components/MobileInnerCard';
import InnerCard from './components/InnerCard';
import AddQuoteModal from '../AddQuoteModal';
import AddOpportunityDrawer from '../AddOpportunityDrawer';

const OpportunityInnerTab = ({
  name,
  isMobile,
  getCount,
}: {
  name: string;
  isMobile?: boolean;
  getCount: Function;
}) => {
  const dispatch = useDispatch<AppDispatch>();
  const contactOpportunities = useSelector(selectContactOpportunities);
  const accountOpportunities = useSelector(selectAccountOpportunities);
  const accountDetailsCount = useSelector(selectCount);
  const contactDetailsCount = useSelector(selectContactCount);
  const [addQuickOpportunity, setAddQuickOpportunity] = useState('closed');
  const accountDetails = useSelector(selectAccount);
  const contactDetails = useSelector(selectContact);
  const [startQuoting, setStartQuoting] = useState('closed');
  const [opportunity, setOpportunity] = useState<IOpportunity>();
  const [longOpportunityForm, setLongOpportunityForm] = useState('closed');

  const accountsStatus = useSelector(
    (state: { accounts: AccountsSliceState }) =>
      state.accounts.oppportunityStatus
  );
  const contactsStatus = useSelector(
    (state: { contacts: ContactsSliceState }) =>
      state.contacts.opportunitiesStatus
  );
  const { uuid } = useParams();
  useEffect(() => {
    if (uuid && !isMobile) {
      dispatch(clearOpportunities());
      dispatch(clearAccountOpportunities());
      name === 'Contact' &&
        dispatch(
          getContactOpportunities({
            uuid: uuid,
            offset: 0,
          })
        );

      name === 'Account' &&
        dispatch(
          getAccountOpportunities({
            uuid: uuid,
            offset: 0,
          })
        );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [uuid]);

  const scrollAccount = (event: any) => {
    const { scrollTop, clientHeight, scrollHeight } = event.currentTarget;
    if (
      Math.round(scrollHeight - scrollTop) <= Math.round(clientHeight) &&
      accountsStatus !== 'loading' &&
      uuid
    ) {
      if (accountDetailsCount.opportunities !== accountOpportunities.length) {
        dispatch(
          getAccountOpportunities({
            uuid,
            offset: accountOpportunities.length,
          })
        );
      }
    }
  };

  const scrollContact = (event: any) => {
    const { scrollTop, clientHeight, scrollHeight } = event.currentTarget;
    if (
      Math.round(scrollHeight - scrollTop) <= Math.round(clientHeight) &&
      contactsStatus !== 'loading' &&
      uuid
    ) {
      if (contactDetailsCount.opportunities !== contactOpportunities.length) {
        dispatch(
          getContactOpportunities({
            uuid,
            offset: contactOpportunities.length,
          })
        );
      }
    }
  };
  const [producer, setProducer] = useState('');
  const [producerSearch, setProducerSearch] = useState('');
  const [pipeline, setPipeline] = useState([]);
  const [account, setAccount] = useState('');
  const [accountSearch, setAccountSearch] = useState('');
  const [showFilter, setShowFilter] = useState('closed');
  const [selectedOppStatus, setOppStatus] = useState<IOption[]>([]);
  const [selectedProducts, setProducts] = useState<IOption[]>([]);

  const filterOpportunity = () => {
    dispatch(clearOpportunities());
    dispatch(clearAccountOpportunities());
    if (uuid && !isMobile) {
      name === 'Contact' &&
        dispatch(
          getContactOpportunities({
            uuid: uuid,
            offset: 0,
            pipelineUUID: pipeline?.map((item: any) => item.value),
            opportunityStatusUUID: selectedOppStatus?.map(
              (item: any) => item.value
            ),
            productCategoryUUID: selectedProducts?.map(
              (item: any) => item.value
            ),
            accountUUID: account,
            producerUUID: producer,
            productUUID: selectedProducts
              ?.filter((item: any) => item.type === 'PRODUCT')
              .map((item: any) => item.value),
            customProductUUID: selectedProducts
              ?.filter((item: any) => item.type === 'CUSTOM_PRODUCT')
              .map((item: any) => item.value),
          })
        );

      name === 'Account' &&
        dispatch(
          getAccountOpportunities({
            uuid: uuid,
            offset: 0,
            pipelineUUID: pipeline?.map((item: any) => item.value),
            opportunityStatusUUID: selectedOppStatus?.map(
              (item: any) => item.value
            ),
            productCategoryUUID: selectedProducts?.map(
              (item: any) => item.value
            ),
            producerUUID: producer,
            productUUID: selectedProducts
              ?.filter((item: any) => item.type === 'PRODUCT')
              .map((item: any) => item.value),
            customProductUUID: selectedProducts
              ?.filter((item: any) => item.type === 'CUSTOM_PRODUCT')
              .map((item: any) => item.value),
          })
        );
    }
  };

  const [showDeleteModal, setShowDeleteModal] = useState('closed');
  const [showSuccess, setShowSuccess] = useState(false);
  const [detailName, setDetailName] = useState('');
  const [UUID, setUUID] = useState('');
  const loadingStatus = useSelector(
    (state: { opportunities: OpportunitiesSliceState }) =>
      state.opportunities?.status
  );
  const setDelete = (uuid: string, name: string) => {
    setUUID(uuid);
    setDetailName(name);
  };
  const onDelete = () => {
    dispatch(
      onDeleteOpportunity({
        uuid: UUID || '',
        successCB: () => {
          getCount();
          filterOpportunity();
          if (name === 'Contact') {
            dispatch(
              updateContactDetailOpportunity({ uuid: UUID, type: 'delete' })
            );
          }
          if (name === 'Account') {
            dispatch(
              updateAccountDetailOpportunity({ uuid: UUID, type: 'delete' })
            );
          }
          setShowDeleteModal('closing');
          setTimeout(() => {
            setShowSuccess(true);
          }, 100);
          setTimeout(() => {
            setShowDeleteModal('closed');
            setShowSuccess(false);
          }, 2000);
        },
      })
    );
  };

  return (
    <InnerFieldStyle>
      {showSuccess && (
        <ErrorCard
          message={`<b>${detailName}</b> is <b> deleted</b> `}
          show='open'
        />
      )}
      <div className='taskDiv'>
        <div className='taskHead d-flex justify-content-between'>
          <button
            className='tertiaryButton d-none d-sm-block'
            onClick={() => {
              setAddQuickOpportunity('open');
            }}
          >
            Add Opportunity <img className='addIcon' src={plus} alt='' />
          </button>
          <button
            className='tertiaryButton filterbutton'
            onClick={() => setShowFilter('open')}
          >
            Filter by:{' '}
            <b>
              {(producer ? 1 : 0) +
                pipeline.length +
                selectedProducts.length +
                selectedOppStatus.length +
                (account ? 1 : 0) ===
              0
                ? 'No'
                : (producer ? 1 : 0) +
                  pipeline.length +
                  selectedProducts.length +
                  selectedOppStatus.length +
                  (account ? 1 : 0)}{' '}
              filter
            </b>
          </button>
        </div>
        {name === 'Contact' && (
          <div
            className='opportunityDiv d-none d-sm-block'
            onScroll={scrollContact}
          >
            {contactOpportunities.length > 0
              ? contactOpportunities?.map((item: any, index: number) => (
                  <InnerCard
                    name={'Opportunity'}
                    details={item}
                    key={index}
                    onDelete={setDelete}
                    loadingStatus={loadingStatus}
                    showDeleteModal={showDeleteModal}
                    setShowDeleteModal={setShowDeleteModal}
                    setStartQuoting={setStartQuoting}
                    setOpportunity={setOpportunity}
                  />
                ))
              : contactsStatus !== 'loading' && (
                  <>
                    <div className='d-flex justify-content-center'>
                      <img src={empty} alt='' />
                    </div>
                    <div className='d-flex justify-content-center'>
                      <span>No Record Found</span>
                    </div>
                  </>
                )}
            {contactsStatus === 'loading'
              ? [1, 2].map((item) => <Skeleton height={200} key={item} />)
              : null}
          </div>
        )}
        {name === 'Account' && (
          <div
            className='opportunityDiv d-none d-sm-block'
            onScroll={scrollAccount}
          >
            {accountOpportunities.length > 0
              ? accountOpportunities?.map((item: any, index: number) => (
                  <InnerCard
                    name={'Opportunity'}
                    details={item}
                    key={index}
                    onDelete={setDelete}
                    loadingStatus={loadingStatus}
                    showDeleteModal={showDeleteModal}
                    setShowDeleteModal={setShowDeleteModal}
                    setStartQuoting={setStartQuoting}
                    setOpportunity={setOpportunity}
                  />
                ))
              : accountsStatus !== 'loading' && (
                  <>
                    <div className='d-flex justify-content-center'>
                      <img src={empty} alt='' />
                    </div>
                    <div className='d-flex justify-content-center'>
                      <span>No Record Found</span>
                    </div>
                  </>
                )}
            {accountsStatus === 'loading'
              ? [1, 2].map((item) => <Skeleton height={200} key={item} />)
              : null}
          </div>
        )}
        {name === 'Contact' && (
          <div className='opportunityDiv d-sm-none' onScroll={scrollContact}>
            {contactOpportunities.length > 0
              ? contactOpportunities?.map((item: any, index: number) => (
                  <MobileInnerCard
                    details={item}
                    key={index}
                    onDelete={setDelete}
                    loadingStatus={loadingStatus}
                    showDeleteModal={showDeleteModal}
                    setShowDeleteModal={setShowDeleteModal}
                    setStartQuoting={setStartQuoting}
                    setOpportunity={setOpportunity}
                  />
                ))
              : contactsStatus !== 'loading' && (
                  <>
                    <div className='d-flex justify-content-center'>
                      <img src={empty} alt='' />
                    </div>
                    <div className='d-flex justify-content-center'>
                      <span>No Record Found</span>
                    </div>
                  </>
                )}
            {contactsStatus === 'loading'
              ? [1, 2].map((item) => <Skeleton height={200} key={item} />)
              : null}
          </div>
        )}
        {name === 'Account' && (
          <div className='opportunityDiv d-sm-none' onScroll={scrollAccount}>
            {accountOpportunities.length > 0
              ? accountOpportunities?.map((item: any, index: number) => (
                  <MobileInnerCard
                    details={item}
                    key={index}
                    onDelete={setDelete}
                    loadingStatus={loadingStatus}
                    showDeleteModal={showDeleteModal}
                    setShowDeleteModal={setShowDeleteModal}
                    setStartQuoting={setStartQuoting}
                    setOpportunity={setOpportunity}
                  />
                ))
              : accountsStatus !== 'loading' && (
                  <>
                    <div className='d-flex justify-content-center'>
                      <img src={empty} alt='' />
                    </div>
                    <div className='d-flex justify-content-center'>
                      <span>No Record Found</span>
                    </div>
                  </>
                )}
            {accountsStatus === 'loading'
              ? [1, 2].map((item) => <Skeleton height={200} key={item} />)
              : null}
          </div>
        )}
        <button
          onClick={() => setAddQuickOpportunity('open')}
          className='addbutton-mob d-sm-none'
        >
          <img src={whitePlus} alt='add' />
        </button>
        {showDeleteModal !== 'closed' && (
          <DeleteModal
            showModal={showDeleteModal}
            closeModal={setShowDeleteModal}
            deleteFunction={onDelete}
            uuid=''
            loading={loadingStatus || 'idle'}
            resource='opportunity'
            name={detailName}
          />
        )}
        {startQuoting !== 'closed' && (
          <AddQuoteModal
            show={startQuoting}
            closeModal={setStartQuoting}
            type={name}
            opportunityInfo={opportunity}
          />
        )}
        <FilterContainer>
          {showFilter !== 'closed' && (
            <OpportunityFilter
              show={showFilter}
              closeModal={setShowFilter}
              selectedOppStatus={selectedOppStatus}
              setProducts={setProducts}
              setOppStatus={setOppStatus}
              selectedProducts={selectedProducts}
              producer={producer}
              setProducer={setProducer}
              producerSearch={producerSearch}
              setProducerSearch={setProducerSearch}
              filterFunction={filterOpportunity}
              setPipeline={setPipeline}
              pipeline={pipeline}
              account={account}
              setAccount={setAccount}
              accountSearch={accountSearch}
              setAccountSearch={setAccountSearch}
              isAccountInnertab={true}
            />
          )}
        </FilterContainer>
      </div>
      {addQuickOpportunity !== 'closed' && (
        <QuickAddOpportunityModal
          show={addQuickOpportunity}
          closeModal={setAddQuickOpportunity}
          isInnerTab={true}
          name={name}
          accountType={accountDetails?.AccountType?.name}
          assosiated={accountDetails}
          contactDetails={contactDetails}
          setLongOpportunityForm={setLongOpportunityForm}
        />
      )}
      {longOpportunityForm !== 'closed' && (
        <AddOpportunityDrawer
          show={longOpportunityForm}
          closeModal={setLongOpportunityForm}
        />
      )}
    </InnerFieldStyle>
  );
};
export default OpportunityInnerTab;
