import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { getNotifications, UpdateNotification } from '../../api/notifications';
import { INotification } from '../../interfaces/Notifications';

export type NotificationSliceState = {
  status: string;
  updatingStatus: string;
  notifications: INotification[];
  isLoading: boolean;
  count: number;
  limit: number;
  offset: number;
  summaryCount: number;
  settingsRes: any;
};
const initialState: NotificationSliceState = {
  status: 'idle',
  isLoading: false,
  updatingStatus: 'idle',
  count: 0,
  limit: 0,
  offset: 0,
  summaryCount: 0,
  settingsRes: {},
  notifications: [],
};

export const fetchNotifications = createAsyncThunk(
  'notification/fetchNotifications',
  async ({
    offset,
    limit,
    unread,
    summary,
    successCB,
  }: {
    offset: number;
    limit?: number;
    unread?: boolean;
    summary?: boolean;
    successCB: Function;
  }) => {
    const response = await getNotifications(offset, limit, unread, summary);
    successCB(response.data);
    return response.data;
  }
);

export const updatingNotification = createAsyncThunk(
  'notification/updateNotification',
  async ({
    data,
    successCB,
  }: {
    data: { notificationUUID?: string; action: string };
    successCB: Function;
  }) => {
    const response = await UpdateNotification(data);
    successCB(response.data);
    return response.data;
  }
);

const notificationSlice = createSlice({
  name: 'notifications',
  initialState,
  reducers: {
    updateStatus: (state) => {
      state.status = 'idle';
      state.isLoading = false;
    },
    clearNotification: (state) => {
      state.notifications = [];
    },
  },
  extraReducers(builder) {
    builder
      .addCase(fetchNotifications.pending, (state, action) => {
        state.status = 'loading';
      })
      .addCase(fetchNotifications.fulfilled, (state, action) => {
        state.status = 'succeeded';

        if (!action.payload.notifications) {
          state.summaryCount = action.payload.count;
        } else {
          state.notifications = [
            ...state.notifications,
            ...action.payload.notifications,
          ];
          state.count = action.payload.count;
        }
      })
      .addCase(fetchNotifications.rejected, (state, action) => {
        state.status = 'failed';
      })
      .addCase(updatingNotification.pending, (state, action) => {
        state.updatingStatus = 'loading';
      })
      .addCase(updatingNotification.fulfilled, (state, action) => {
        state.updatingStatus = 'succeeded';
        if (action.payload.notificationUUID) {
          const index = state.notifications.findIndex(
            (item: any) => item.uuid === action.payload.notificationUUID
          );
          state.notifications[index].isRead = true;
          state.summaryCount -= 1;
        } else {
          state.notifications = action.payload.notifications;
          state.count = 0;
          state.summaryCount = 0;
        }
      })
      .addCase(updatingNotification.rejected, (state, action) => {
        state.updatingStatus = 'failed';
      });
  },
});

export const { updateStatus, clearNotification } = notificationSlice.actions;

export default notificationSlice.reducer;

export const selectAllNotifications = (state: {
  notifications: NotificationSliceState;
}) => state.notifications?.notifications;
