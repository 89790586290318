import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Moment from 'react-moment';
import { CardDiv } from '../../../../styles/CardDiv';
import vector from '../../../../assets/logo/dropdown.svg';
import edit from '../../../../assets/logo/edit.svg';
import bin from '../../../../assets/logo/bin.svg';
import { IAccount } from '../../../../interfaces/Account';
import EditAccountDetails from '../../../AccountDetails/EditAccountDetails';

const AccountCard = ({ account }: { account: IAccount }) => {
  const [showDropdown, setShowDropdown] = useState(false);
  const [showEditAccountForm, setShowEditAccountForm] = useState('closed');
  const navigate = useNavigate();

  return (
    <CardDiv>
      <td
        className='row-name name-td'
        onClick={() => {
          navigate(`/account/${account.uuid}`);
        }}
      >
        <input type='checkbox' name='remember' className='form-check-input' />
        <b>{account.name ? account.name : '-'}</b>
      </td>
      <td>
        <div
          className={`status ${
            account.Policies?.length > 0 ? 'statusActive' : 'statusInvited'
          }`}
        >
          {account.Policies?.length > 0 ? 'Customer' : 'Prospect'}
        </div>
      </td>
      <td>
        <Moment format='MM/DD/YYYY'>{account.createdAt}</Moment>
      </td>
      <td className='opportunity-div'>
        <span className='opp-num'>{account.Opportunities?.length}</span>
        {account.Opportunities.map((opportunity) =>
          opportunity?.Product
            ? opportunity?.Product.name
            : opportunity?.CustomProduct && opportunity?.CustomProduct?.name
        ).join(', ')}
      </td>
      <td className='d-none d-xl-table-cell'>
        <div>
          {account.Agent?.User?.avatar ? (
            <img
              src={account.Agent?.User?.avatar}
              alt=''
              className='user-pic'
            />
          ) : (
            <div className='user-pic'>
              {account.Agent?.User?.firstName?.substring(0, 1).toUpperCase()}
              {account.Agent?.User?.lastName?.substring(0, 1).toUpperCase()}
            </div>
          )}
        </div>
      </td>
      <td className='d-none d-xl-table-cell'>
        <div style={{ fontWeight: 600 }}>
          ${account.premium ? account.premium : 0}
        </div>
      </td>
      <td className='actionField'>
        <div className='d-flex align-items-center justify-content-between'>
          <div
            className='actionButton'
            onClick={() => {
              navigate(`/account/${account.uuid}`);
            }}
          >
            View details
          </div>
          <div
            className='vector'
            onClick={() => setShowDropdown(!showDropdown)}
          >
            <img src={vector} alt='vector' />
          </div>
          {showDropdown && (
            <div className='dropdown-div'>
              <div
                className='edit-div'
                onClick={() => {
                  setShowEditAccountForm('open');
                  setShowDropdown(false);
                }}
              >
                Edit <img src={edit} alt='bin' />
              </div>
              <div className='delete-div'>
                Delete <img src={bin} alt='bin' />
              </div>
            </div>
          )}
        </div>
        {showEditAccountForm !== 'closed' && (
          <EditAccountDetails
            show={showEditAccountForm}
            closeModal={setShowEditAccountForm}
            uuid={account.uuid}
          />
        )}
      </td>
    </CardDiv>
  );
};

export default AccountCard;
