import { useState, useRef, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { SideBarDiv } from './styles';
import logoIIR from '../../assets/logo/logo.svg';
import { useNavigate } from 'react-router-dom';
import plus from '../../assets/logo/plus.svg';
import dashboard from '../../assets/logo/dashboard.svg';
import mybook from '../../assets/logo/Mybook.svg';
import manage from '../../assets/logo/manage.svg';
import Link from '../Link';
import { useOutsideClick } from '../../hooks/useOutsideClick';
import { selectUser } from '../../store/user/userSlice';
import QuickAccountDrawer from '../../pages/MyBook/Accounts/components/QuickAddDrawer';
import QuickContactFrom from '../../pages/MyBook/Contacts/components/QuickAddForm';
import QuickAddOpportunityModal from '../QuickAddOpportunity';
import QuickAddAgency from '../../pages/Admin/AgencyList/components/AgencyModal';
import AddLeadDrawer from '../AddLeadDrawer';
import myteam from '../../assets/logo/myteam.svg';
import TaskTab from '../../assets/logo/TaskTab.svg';
import vector from '../../assets/logo/dropdown.svg';
import contact from '../../assets/logo/contact.svg';
import account from '../../assets/logo/account.svg';
import policy from '../../assets/logo/policy.svg';
import settings from '../../assets/logo/settings.svg';
import PolicyModal from '../../pages/MyBook/Policies/components/AddPolicyModal';
import AddQuoteModal from '../AddQuoteModal';
import AddOpportunityDrawer from '../AddOpportunityDrawer';

function SideBar() {
  const user = useSelector(selectUser);
  const [showQuickAddDropDown, setShowQuickAddDropDown] = useState(false);
  const [addQuickContact, setAddQuickContact] = useState('closed');
  const [longOpportunityForm, setLongOpportunityForm] = useState('closed');
  const [addQuickAccount, setAddQuickAccount] = useState('closed');
  const [addQuickOpportunity, setAddQuickOpportunity] = useState('closed');
  const [addQuickAddAgency, setAddQuickAddAgency] = useState('closed');
  const [addQuickAddLead, setAddQuickAddLead] = useState('closed');
  const [showDropdown, setShowDropdown] = useState(false);
  const [isMyBook, setMyBook] = useState(false);
  const [isContact, setContact] = useState(false);
  const [isAccount, setAccount] = useState(false);
  const [isLead, setLead] = useState(false);
  const [isOpportunity, setOpportunity] = useState(false);
  const [isPolicy, setPolicy] = useState(false);
  const [quickAddPolicy, setQuickAddPolicy] = useState('closed');
  const [showQuoteModal, setShowQuoteModal] = useState('closed');

  const dropRef = useRef(null);
  const navigate = useNavigate();
  const dropDownRef = useRef(null);
  useOutsideClick(dropDownRef, setShowDropdown);
  useOutsideClick(dropRef, setShowQuickAddDropDown);

  return (
    <SideBarDiv>
      <div className='LogoDiv'>
        <img src={logoIIR} className='logo w-100' alt='logo' />
      </div>
      <div className='d-sm-flex justify-content-between'>
        <button
          className='quickAddButton'
          onClick={() => setShowQuickAddDropDown(!showQuickAddDropDown)}
          ref={dropRef}
        >
          <img src={plus} alt='' />

          {showQuickAddDropDown
            ? [
                user?.UserRole.name === 'ADMIN' ? (
                  <div className='quickFormsDropDown admin-quickAdd'>
                    <div
                      className='quickFormBtn'
                      onClick={() => {
                        setAddQuickAddAgency('open');
                      }}
                    >
                      Add Agency
                    </div>
                  </div>
                ) : (
                  <div className='quickFormsDropDown'>
                    <div
                      className='quickFormBtn'
                      onClick={() => {
                        setAddQuickAddLead('open');
                      }}
                    >
                      <img className='imageIcon' src={contact} alt='icon' />
                      Add Lead
                    </div>
                    <div
                      className='quickFormBtn'
                      onClick={() => {
                        setAddQuickContact('open');
                      }}
                    >
                      <img className='imageIcon' src={contact} alt='icon' />
                      Add Contact
                    </div>
                    <div
                      className='quickFormBtn'
                      onClick={() => {
                        setAddQuickAccount('open');
                      }}
                    >
                      <img className='imageIc' src={account} alt='icon' />
                      Add Account
                    </div>
                    <div
                      className='quickFormBtn'
                      onClick={() => {
                        setAddQuickOpportunity('open');
                      }}
                    >
                      <img className='imageIcon' src={policy} alt='icon' />
                      Add Opportunity
                    </div>
                    <div
                      className='quickFormBtn'
                      onClick={() => {
                        setShowQuoteModal('open');
                      }}
                    >
                      <img className='imageIc' src={account} alt='icon' />
                      Add Quote
                    </div>
                    <div
                      className='quickFormBtn lastQuickFormBtn '
                      onClick={() => {
                        setQuickAddPolicy('open');
                      }}
                    >
                      <img className='imageIcon' src={policy} alt='icon' />
                      Add Policy
                    </div>
                  </div>
                ),
              ]
            : null}
        </button>
        <div className='quickadd-text'>
          <h5 className='nav-text'>Quick add</h5>
        </div>
      </div>

      <Link to='/dashboard'>
        <div className='d-sm-flex justify-content-between'>
          <img src={dashboard} className='nav-logo d-block' alt='logo' />
          <div className='nav-textdiv'>
            <h5 className='nav-text'>Dashboard</h5>
          </div>
        </div>
      </Link>

      {user?.UserRole.name === 'ADMIN' ? (
        <>
          <Link to='/admin'>
            <div className='d-sm-flex justify-content-between'>
              <img src={manage} alt='logo' className='nav-logo d-block' />
              <div className='nav-textdiv'>
                <h5 className='nav-text'>Management</h5>
              </div>
            </div>
          </Link>
        </>
      ) : (
        <>
          <Link to='/activities'>
            <div className='d-sm-flex justify-content-between'>
              <img src={TaskTab} alt='logo' className='nav-logo d-block' />
              <div className='nav-textdiv'>
                <h5 className='nav-text'>Tasks</h5>
              </div>
            </div>
          </Link>
          <div
            onClick={() => setMyBook(true)}
            className={`mybook-navdiv ${isMyBook ? 'active-div' : ''}`}
          >
            <div
              onClick={() => {
                setMyBook(true);
                setShowDropdown(!showDropdown);
              }}
              className=' vector-nav d-sm-flex justify-content-between'
              ref={dropDownRef}
            >
              <img src={mybook} alt='logo' className='nav-logo d-block' />
              <div className='nav-textdiv justify-content-between'>
                <h5 className='nav-text'>My Book</h5>
                <img
                  className={`vector-img ${
                    showDropdown ? 'vector-rotate' : ''
                  }`}
                  src={vector}
                  alt='vector'
                />
              </div>
              {showDropdown && (
                <div className='drop-Div'>
                  <div
                    onClick={() => {
                      navigate('/mybook/contacts');
                      setShowDropdown(false);
                      setContact(true);
                      setAccount(false);
                      setLead(false);
                      setOpportunity(false);
                      setPolicy(false);
                    }}
                    className={`mybook-drop ${isContact ? 'active-div' : ''}`}
                  >
                    <img
                      className='image-drop'
                      src={contact}
                      alt='contact-icon'
                    />
                    Contacts
                  </div>
                  {/* <Link to='/mybook/contacts'>
                    <div className='d-sm-flex justify-content-between'>
                      <img
                        src={contact}
                        alt='logo'
                        className='image-drop d-block'
                      />
                      <div className='nav-textdiv'>
                        <h5 className='nav-text'>Contacts</h5>
                      </div>
                    </div>
                  </Link> */}

                  <div
                    onClick={() => {
                      navigate('/mybook/accounts');
                      setShowDropdown(false);
                      setAccount(true);
                      setMyBook(true);
                      setContact(false);
                      setLead(false);
                      setOpportunity(false);
                      setPolicy(false);
                    }}
                    className={`mybook-drop ${isAccount ? 'active-div' : ''}`}
                  >
                    <img
                      className='image-account'
                      src={account}
                      alt='account-icon'
                    />
                    Accounts
                  </div>
                  <div
                    onClick={() => {
                      navigate('/mybook/policies');
                      setShowDropdown(false);
                      setPolicy(true);
                      setMyBook(true);
                      setAccount(false);
                      setContact(false);
                      setLead(false);
                      setOpportunity(false);
                    }}
                    className={`mybook-drop ${isPolicy ? 'active-div' : ''}`}
                  >
                    <img
                      className='image-drop'
                      src={policy}
                      alt='policy-icon'
                    />
                    Policies
                  </div>
                  <div
                    onClick={() => {
                      navigate('/mybook/leads');
                      setShowDropdown(false);
                      setLead(true);
                      setMyBook(true);
                      setPolicy(false);
                      setAccount(false);
                      setContact(false);
                      setOpportunity(false);
                    }}
                    className={`mybook-drop ${isLead ? 'active-div' : ''}`}
                  >
                    <img
                      className='image-drop'
                      src={contact}
                      alt='contact-icon'
                    />
                    Leads
                  </div>
                  <div
                    onClick={() => {
                      navigate('/mybook/opportunities');
                      setShowDropdown(false);
                      setOpportunity(true);
                      setMyBook(true);
                      setLead(false);
                      setPolicy(false);
                      setAccount(false);
                      setContact(false);
                    }}
                    className={`mybook-drop ${
                      isOpportunity ? 'active-div' : ''
                    }`}
                  >
                    <img
                      className='image-drop'
                      src={policy}
                      alt='policy-icon'
                    />
                    Opportunities
                  </div>
                </div>
              )}
            </div>
          </div>
          <Link to='/pipeline'>
            <div className='d-sm-flex justify-content-between'>
              <img src={manage} alt='logo' className='nav-logo d-block' />
              <div className='nav-textdiv'>
                <h5 className='nav-text'>Sales pipeline</h5>
              </div>
            </div>
          </Link>
        </>
      )}
      {user?.UserRole.name === 'PRINCIPAL_AGENT' ? (
        <>
          <Link to='/myteam'>
            <div className='d-sm-flex justify-content-between'>
              <img src={myteam} alt='logo' className='nav-logo d-block' />
              <div className='nav-textdiv'>
                <h5 className='nav-text'>My Team</h5>
              </div>
            </div>
          </Link>
          <Link to='/settings'>
            <div className='d-sm-flex justify-content-between'>
              <img src={settings} alt='logo' className='nav-logo d-block' />
              <div className='nav-textdiv'>
                <h5 className='nav-text'>Settings</h5>
              </div>
            </div>
          </Link>
        </>
      ) : null}
      {addQuickAccount !== 'closed' && (
        <QuickAccountDrawer
          show={addQuickAccount}
          closeModal={setAddQuickAccount}
        />
      )}
      {addQuickContact !== 'closed' && (
        <QuickContactFrom
          show={addQuickContact}
          closeModal={setAddQuickContact}
        />
      )}
      {addQuickOpportunity !== 'closed' && (
        <QuickAddOpportunityModal
          show={addQuickOpportunity}
          setLongOpportunityForm={setLongOpportunityForm}
          closeModal={setAddQuickOpportunity}
        />
      )}

      {addQuickAddAgency !== 'closed' && (
        <QuickAddAgency
          show={addQuickAddAgency}
          closeModal={setAddQuickAddAgency}
        />
      )}
      {longOpportunityForm !== 'closed' && (
        <AddOpportunityDrawer
          show={longOpportunityForm}
          closeModal={setLongOpportunityForm}
        />
      )}
      {addQuickAddLead !== 'closed' && (
        <AddLeadDrawer show={addQuickAddLead} closeModal={setAddQuickAddLead} />
      )}
      {quickAddPolicy !== 'closed' && (
        <PolicyModal show={quickAddPolicy} closeModal={setQuickAddPolicy} />
      )}
      {showQuoteModal !== 'closed' && (
        <AddQuoteModal
          show={showQuoteModal}
          closeModal={setShowQuoteModal}
          type='Contact'
          page='quickQuote'
        />
      )}
    </SideBarDiv>
  );
}

export default SideBar;
