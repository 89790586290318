import Moment from 'react-moment';
import { useNavigate } from 'react-router-dom';
import vector from '../../../assets/logo/dropdown.svg';
import arrow from '../../../assets/logo/right-arrow.svg';
import edit from '../../../assets/logo/edit.svg';
import bin from '../../../assets/logo/bin.svg';
import { useRef, useState } from 'react';
import { useOutsideClick } from '../../../hooks/useOutsideClick';
import { IOpportunity } from '../../../interfaces/Opportunity';
import { CardDiv } from '../../../styles/InnerTabsCardStyles';
import EditAccountDetails from '../../../pages/AccountDetails/EditAccountDetails';

const DetailsCard = ({
  name,
  details,
  onDelete,
  loadingStatus,
  showDeleteModal,
  setShowDeleteModal,
}: {
  name: string;
  details?: any;
  onDelete?: Function;
  loadingStatus?: string;
  showDeleteModal?: string;
  setShowDeleteModal?: Function;
}) => {
  const navigate = useNavigate();

  const [showEditModal, setShowEditModal] = useState('closed');
  const deleteFun = (uuid: string, name: string) => {
    if (onDelete) {
      onDelete(uuid, name);
    }
  };
  return (
    <CardDiv>
      {' '}
      <div className='opportunity'>
        <div className='topContent d-flex justify-content-between'>
          <div className='topDetails d-flex align-items-center'>
            <div className='profile-pic d-flex align-items-center justify-content-center'>
              {details?.name?.charAt(0) + details?.name?.charAt(5)}
            </div>

            <span className='oppName-head text-overflow-container'>
              <span
                className='oppName opp-width'
                onClick={() => navigate(`/account/${details.uuid}`)}
              >
                {details?.name}
              </span>
              <span className='tooltiptext'>
                <span className='tooltipInnertext'>{details?.name}</span>
              </span>
            </span>
            <div
              className={`oppstatus ${
                details?.AccountType?.name === 'PERSONAL' ? 'greenC' : 'yellowC'
              }`}
            >
              <h6>{details?.AccountType?.name.toLowerCase()}</h6>
            </div>
          </div>
          <div className='profile-pic d-flex align-items-center justify-content-center'>
            {details?.Agent?.User?.avatar
              ? details?.Agent?.User?.avatar
              : details?.Agent?.User?.firstName?.charAt(0) +
                details?.Agent?.User?.lastName?.charAt(0)}
          </div>
        </div>
        <div className='lowContent'>
          <div className='oppDetaisl d-flex justify-content-between'>
            <div className='oppDetaislfields d-flex justify-content-between '>
              <>
                <div>
                  <span className='valHead '>Created date</span>
                  <span className='valueField'>
                    <Moment format='MM/DD/YYYY'>{details?.createdAt}</Moment>
                  </span>
                </div>
                <div>
                  <span className='valHead'>No. of Opportunities</span>
                  <span className='valueField'>
                    <span className='number'>
                      {details?.Opportunities.length}
                      {details?.Opportunities.length > 2 ? (
                        <span className={`tooltiptext`}>
                          {details?.Opportunities.map((item: any) =>
                            item.Product ? (
                              <li>{item.Product.name} </li>
                            ) : item?.CustomProduct ? (
                              <li> {item?.CustomProduct?.name}</li>
                            ) : (
                              ''
                            )
                          )}
                        </span>
                      ) : null}
                    </span>
                    <span className='opportunityList'>
                      {details?.Opportunities.slice(0, 3)
                        ?.map((opportunity: IOpportunity) =>
                          opportunity?.Product
                            ? opportunity?.Product?.name.slice(0, 4)
                            : opportunity?.CustomProduct &&
                              opportunity?.CustomProduct?.name.slice(0, 4)
                        )
                        .join(', ')}
                    </span>
                  </span>
                </div>
                <div>
                  <span className='valHead'>Est. premium</span>
                  <span className='valueField'>
                    <b>$</b>
                    {details?.premium
                      ? details?.premium.toLocaleString('en-US')
                      : 0}
                  </span>
                </div>
              </>
            </div>
            <div className=' d-flex align-items-center justify-content-center position-relative'>
              <button
                className='tertiaryButton quotingbtn '
                onClick={() => navigate(`/account/${details.uuid}`)}
              >
                View details <img className='addIcon' src={arrow} alt='' />
              </button>

              <ActionsDropdown
                uuid={details.uuid}
                setShowEditModal={setShowEditModal}
                setShowDeleteModal={setShowDeleteModal}
                name={details.name || details?.Accounts?.name}
                deleteFun={deleteFun}
              />
            </div>
          </div>
        </div>
        {details && showEditModal !== 'closed' && (
          <EditAccountDetails
            show={showEditModal}
            closeModal={setShowEditModal}
            uuid={details.uuid}
          />
        )}
      </div>
    </CardDiv>
  );
};

export default DetailsCard;

const ActionsDropdown = ({
  uuid,
  setShowEditModal,
  setShowDeleteModal,
  name,
  deleteFun,
}: {
  uuid: string;
  setShowEditModal: Function;
  setShowDeleteModal?: Function;
  name: string;
  deleteFun: Function;
}) => {
  const [showActionsDropdown, setShowActionsDropdown] = useState(false);

  const dropRef = useRef<HTMLButtonElement>(null);
  useOutsideClick(dropRef, setShowActionsDropdown);

  return (
    <button
      className='vector d-flex align-items-center justify-content-center position-relative'
      onClick={() => {
        setShowActionsDropdown(!showActionsDropdown);
        if (dropRef.current) {
          dropRef.current.focus();
        }
      }}
      onBlur={() => setShowActionsDropdown(false)}
      ref={dropRef}
    >
      <img src={vector} alt='vector' />
      {showActionsDropdown && (
        <div className='dropdown-div'>
          <div
            className='edit-div'
            onClick={() => {
              setShowEditModal('open');
              setShowActionsDropdown(false);
            }}
          >
            Edit <img src={edit} alt='bin' />
          </div>
          {setShowDeleteModal && (
            <div
              className='delete-div'
              onClick={() => {
                setShowDeleteModal('open');
                setShowActionsDropdown(false);
                deleteFun(uuid, name);
              }}
            >
              Delete <img src={bin} alt='bin' />
            </div>
          )}
        </div>
      )}
    </button>
  );
};
