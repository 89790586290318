import styled from '@emotion/styled';
export const StyledMobileTabSidebar = styled.div`
  position: relative;
  z-index: 1;
  width: 100%;
  max-width: 100vw;
  max-height: 100vh;
  bottom: 0 !important;
  height: 100%;
  padding: 0px 16px 0 16px;
  .cross {
    width: 15.94px;
    height: 15.74px;
  }
  .cross-button {
    position: absolute;
    background: #ffffff;
    right: 0;
    top: 0;
    border: none;
    padding: 20.15px 28px;
  }
  .navlink {
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    color: #262758;
    text-decoration: none;
    background: #fff;
    width: 100%;
    display: flex;
    border-radius: 8px;
    padding: 5px 16px 0 16px;
  }
  .pipeline-img {
    width: 26px;
  }
  .pipeline {
    margin-top: 4px;
  }
  .vector-img {
    margin-left: 161px;
    width: 14px;
  }
  .vector-nav {
    position: relative;
    cursor: pointer;
    .drop-Div {
      margin-left: 4px;
      position: absolute;
      background: #fff;
      box-shadow: 0px 2px 8px rgba(35, 42, 64, 0.2);
      border-radius: 8px;
      padding: 4px 16px;
      z-index: 1;
      width: 100%;
      bottom: -300px;
      cursor: pointer;
      line-height: 22px;
    }
  }
  .logo-div {
    margin-bottom: 50px;
    img {
      height: 28px;
    }
  }
  .active {
    background: #f5f7fc;
    .content-link-div {
      margin-top: 10px;
    }
  }
  .content-link-div {
    margin-bottom: 25px;
  }
  .mybook-div {
    .navlink {
      font-weight: 400;
      font-size: 16px;
      line-height: 19px;
      color: #262758;
      text-decoration: none;
      background: #fff;
      width: 100%;
      display: flex;
      border-radius: 8px;
      padding: 15px 16px 19px 16px;
    }
  }
  .mybook-div {
    .active {
      background: #f5f7fc;
    }
  }
  .img {
    margin-right: 21px;
  }
  .mybook-img {
    margin-right: 8px;
    margin-top: -2px;
  }
  .tab-div {
    max-height: 100vh;
    height: 100vh;
    padding-bottom: 100px;
    overflow-y: auto;
    &::-webkit-scrollbar {
      width: 4px;
      background: transparent;
    }
    &::-webkit-scrollbar-track {
      border-radius: 8px;
    }

    &::-webkit-scrollbar-thumb {
      background: #e6e9f0;
    }
  }

  @media screen and (min-width: 576px) {
    .tab-div {
      width: 360px;
    }
    .vector-nav {
      .drop-Div {
        bottom: -192px;
      }
    }

    .mybook-div {
      .contacts {
        .navlink {
          padding: 8px 16px 4px 16px;
        }
      }
    }
  }
`;
