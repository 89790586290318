import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { fetchPipelines } from '../../api/opportunities';
import {
  addPipeline,
  editPipeline,
  deletePipeline,
  updatePipelineStatus,
  fetchPipelineTemplate,
} from '../../api/pipeline';
import { IPipeline } from '../../interfaces/Opportunity';
import { IAddPipeline, ITemplateStage } from '../../interfaces/Pipeline';

export type PipelinesSliceState = {
  status: string;
  pipelines: IPipeline[];
  isLoading: boolean;
  pipeLineTemplateStatus: string;
  pipelineTemplate: ITemplateStage[];
};

interface IProps {
  sortBy: string;
  searchBy: string;
  orderBy: string;
  successCB: Function;
  agencyUUID?: string;
  productCategoryUUID?: any;
  showAll?: boolean;
  active?: string;
  productUUID?: any;
  customProductUUID?: any;
}

interface IData {
  data: IAddPipeline;
  successCB: Function;
}

interface IEditData extends IData {
  uuid: string;
}

const initialState: PipelinesSliceState = {
  status: 'idle',
  pipelineTemplate: [],
  pipeLineTemplateStatus: 'idle',
  pipelines: [],
  isLoading: false,
};

export const getPipelines = createAsyncThunk(
  'pipelines/getPipelines',
  async ({
    sortBy,
    orderBy,
    searchBy,
    successCB,
    agencyUUID,
    productCategoryUUID,
    showAll,
    active,
    productUUID,
    customProductUUID,
  }: IProps) => {
    const response = await fetchPipelines(
      sortBy,
      orderBy,
      searchBy,
      agencyUUID,
      productCategoryUUID,
      showAll,
      active,
      productUUID,
      customProductUUID
    );
    if (response.data) {
      successCB(response.data);
    }
    return response.data;
  }
);

export const createPipeline = createAsyncThunk(
  'pipelines/createPipeline',
  async ({ data, successCB }: IData) => {
    const response = await addPipeline(data);
    successCB();
    return response.data;
  }
);

export const removePipeline = createAsyncThunk(
  'pipelines/removePipeline',
  async ({
    uuid,
    agencyUUID,
    successCB,
  }: {
    uuid: string;
    agencyUUID?: string;
    successCB: Function;
  }) => {
    const response = await deletePipeline(uuid, agencyUUID);
    successCB();
    return response.data;
  }
);

export const updatePipeline = createAsyncThunk(
  'pipelines/updatePipeline',
  async ({ data, uuid, successCB }: IEditData) => {
    const response = await editPipeline(data, uuid);
    successCB(response.data);
    return response.data;
  }
);

export const getPipelineTemplate = createAsyncThunk(
  'pipelines/getPipelineTemplate',
  async ({ successCB }: { successCB: Function }) => {
    const response = await fetchPipelineTemplate(successCB);

    return response.data;
  }
);

export const pipelineStatusUpdate = createAsyncThunk(
  'pipelines/pipelineStatusUpdate',
  async ({ uuid, data }: { uuid: string; data: any }) => {
    const response = await updatePipelineStatus(uuid, data);
    return response.data;
  }
);

const pipelinesSlice = createSlice({
  name: 'pipelines',
  initialState,
  reducers: {
    updateStatus: (state) => {
      state.status = 'idle';
      state.isLoading = false;
    },
    clearPipelines: (state) => {
      state.pipelines = [];
    },
  },
  extraReducers(builder) {
    builder
      .addCase(getPipelines.pending, (state, action) => {
        state.status = 'loading';
      })
      .addCase(getPipelines.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.pipelines = action.payload;
      })
      .addCase(getPipelines.rejected, (state, action) => {
        state.status = 'failed';
      })
      .addCase(createPipeline.fulfilled, (state, action) => {
        state.pipelines.push(action.payload);
        state.isLoading = false;
      })
      .addCase(createPipeline.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(createPipeline.rejected, (state, action) => {
        state.isLoading = false;
      })
      .addCase(updatePipeline.fulfilled, (state, action) => {
        const index = state.pipelines.findIndex(
          (item) => item.uuid === action.payload.uuid
        );
        state.pipelines[index] = action.payload;
      })
      .addCase(removePipeline.fulfilled, (state, action) => {
        const index = state.pipelines.findIndex(
          (item) => item.uuid === action.payload.uuid
        );
        state.pipelines.splice(index, 1);
      })
      .addCase(pipelineStatusUpdate.fulfilled, (state, action) => {
        const index = state.pipelines.findIndex(
          (item) => item.uuid === action.payload.uuid
        );
        state.pipelines[index] = action.payload;
      })
      .addCase(getPipelineTemplate.pending, (state, action) => {
        state.pipeLineTemplateStatus = 'loading';
        state.isLoading = true;
      })
      .addCase(getPipelineTemplate.rejected, (state, action) => {
        state.pipeLineTemplateStatus = 'failed';
        state.isLoading = false;
      })
      .addCase(getPipelineTemplate.fulfilled, (state, action) => {
        state.pipeLineTemplateStatus = 'succeeded';
        state.pipelineTemplate = action.payload;
      });
  },
});

export const { updateStatus, clearPipelines } = pipelinesSlice.actions;

export default pipelinesSlice.reducer;

export const selectAllPipelines = (state: { pipelines: PipelinesSliceState }) =>
  state.pipelines?.pipelines;
