import { useState, ChangeEvent, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate, useSearchParams } from 'react-router-dom';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { changePassword } from '../../api';
import { getValidateUser } from '../../api/user';
import SuccessCard from '../../components/SuccessCard';
import PasswordValidation from '../../components/PasswordValidation';
import PasswordField, {
  ConfirmPasswordField,
} from '../../components/PasswordField';
import { LoginDiv } from '../../styles/LoginFlow';
import logo from '../../assets/logo/logo.svg';
import arrow from '../../assets/logo/right-arrow.svg';
import eye from '../../assets/logo/eye.svg';
import warning from '../../assets/logo/warning.svg';
import setPass from '../../assets/illustrations/Set-password.svg';

function ChangePassword() {
  const navigate = useNavigate();
  let [searchParams] = useSearchParams();

  const [changePasswordSent, setChangePasswordSent] = useState(false);
  const [password, setPassword] = useState('');
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [errorMessage, setError] = useState('');
  const [isFilled, setFilled] = useState(false);
  const [kid, setKid] = useState('');
  const [email, setEmail] = useState('');

  const passwordChange = (e: ChangeEvent<HTMLInputElement>) => {
    setPassword(e.target.value);
  };

  const SignupSchema = Yup.object().shape({
    password: Yup.string()
      .required('Please enter a password. ')
      .test('Passwords-match', 'Passwords must match', function (value) {
        return password === value;
      }),
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
  } = useForm({
    resolver: yupResolver(SignupSchema),
    defaultValues: {
      code: searchParams.get('code'),
      password: '',
    },
  });

  useEffect(() => {
    let code: any = searchParams.get('code');
    getValidateUser(
      code,
      (userData: any) => {
        setKid(userData.keycloakId);
        setEmail(userData.email);
      },
      (msg: string) => {
        setError(msg);
        navigate('/error/link-expired');
      }
    ); // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onSubmit = (values: any) => {
    values.email = email;
    values.kid = kid;
    if (password === values.password) {
      changePassword(
        values,
        () => setChangePasswordSent(true),
        (msg: string) => setError(msg)
      );
    }
  };

  const watchAllFields = watch();

  const toggleVisibilityConfirm = () => {
    setShowConfirmPassword(!showConfirmPassword);
  };

  const toggleVisibility = () => {
    setShowPassword(!showPassword);
  };

  const checks = [
    {
      check: (val: string) => val.length >= 8,
      message: 'atleast 8 characters',
    },
    {
      check: (val: string) => /(?=.*[A-Z])/.test(val),
      message: 'atleast one uppercase letter',
    },
    {
      check: (val: string) => /(?=.*[a-z])/.test(val),
      message: 'atleast one lowercase letter',
    },
    {
      check: (val: string) => /(?=.*\d)/.test(val),
      message: 'atleast one number',
    },
    {
      check: (val: string) => /(?=.*[@$!%*?&#])/.test(val),
      message: 'atleast one special character',
    },
  ];

  return (
    <LoginDiv>
      <div>
        <img className='logo-svg d-none d-xl-block' src={logo} alt='logo' />
      </div>

      {changePasswordSent ? (
        <>
          <div>
            <img
              className='logo-svg d-block d-sm-block d-xl-none'
              src={logo}
              alt='logo'
            />
          </div>
          <div className='content-div reset-password-div'>
            <div className='center-div'>
              <h2 className='welcome-heading'>Done!</h2>
              <div className='success-div'>
                <SuccessCard
                  title={'Success'}
                  message={'Your password has been updated.'}
                />
              </div>
              <button
                className='backButton  submit-button'
                onClick={() => navigate('/')}
              >
                <img
                  src={arrow}
                  className='left-arrow d-none d-sm-block'
                  alt='arrow'
                />
                Back to Log In
              </button>
            </div>
            <div className='d-none d-sm-block'>
              <img className='setpass-img' src={setPass} alt='pass' />
            </div>
          </div>
        </>
      ) : (
        <>
          <button
            className='d-block d-xl-none primaryBackButton'
            onClick={() => navigate('/')}
          >
            <img src={arrow} className='left-arrow' alt='' />
            Back to Log In
          </button>
          <div className='content-div reset-password-div'>
            <div className='center-div'>
              <div>
                <button
                  className='d-none d-xl-block primaryBackButton'
                  onClick={() => navigate('/')}
                >
                  <img src={arrow} className='left-arrow' alt='' />
                  Back to Log In
                </button>
              </div>
              <h2 className='welcome-heading'>
                Create a <br className='d-block d-sm-none' />
                new password
              </h2>
              <p className='sub'>Enter your new password below.</p>

              <div>
                <form onSubmit={handleSubmit(onSubmit)}>
                  <div className='fields'>
                    <div className='password-div'>
                      <PasswordField
                        name='original-password'
                        type={showPassword ? 'text' : 'password'}
                        passwordValidationProps={{
                          validationRequired: true,
                          value: password,
                          onPasswordChange: passwordChange,
                        }}
                        isFilled={isFilled}
                        setFilled={setFilled}
                        placeholder='Create Password'
                      />
                      {password && (
                        <div
                          className='toggleButton d-flex align-items-center'
                          onClick={toggleVisibility}
                        >
                          <img className='eye' alt='eye-icon' src={eye} />
                          Show
                        </div>
                      )}
                    </div>
                    {password ? (
                      <PasswordValidation checks={checks} password={password} />
                    ) : null}
                  </div>

                  <div className='fields'>
                    <div className='password-div'>
                      <ConfirmPasswordField
                        name='password'
                        type={showConfirmPassword ? 'text' : 'password'}
                        placeholder='Confirm Password'
                        register={register}
                        className={`${
                          errors.password || errorMessage ? 'error-field' : ''
                        }
                          ${watchAllFields.password && 'filled'}`}
                      />
                      {watchAllFields.password && (
                        <div
                          className='toggleButton d-flex align-items-center'
                          onClick={toggleVisibilityConfirm}
                        >
                          <img className='eye' alt='eye-icon' src={eye} />
                          Show
                        </div>
                      )}
                    </div>
                    {errors.password || errorMessage ? (
                      <div className='errorVal'>
                        <img src={warning} alt='warning' className='warning' />
                        {errors.password?.message || errorMessage}
                      </div>
                    ) : null}
                  </div>
                  <button
                    type='submit'
                    className='submit-button primaryButton'
                    disabled={
                      !checks.every(
                        (val: { check: Function; message: string }) =>
                          val.check(password)
                      )
                    }
                  >
                    Submit
                    <img className='arrowl' src={arrow} alt='arrow-function' />
                  </button>
                </form>
              </div>
            </div>
            <div className='d-none d-sm-block'>
              <img className='setpass-img' src={setPass} alt='pass' />
            </div>
          </div>
        </>
      )}
    </LoginDiv>
  );
}

export default ChangePassword;
