import { useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams, useNavigate } from 'react-router-dom';
import SideBar from '../../components/SideBar';
import TopBar from '../../components/TopBar';
import { AppDispatch } from '../../store';
import { DetailsDiv } from '../../styles/DetailsDiv';
import {
  getPolicyDetails,
  selectPolicy,
  PoliciesSliceState,
  getCount,
  onDeletePolicy,
} from '../../store/policies/policiesSlice';
import DetailsPageTab from '../../components/DetailsPageTab';
import dropdown from '../../assets/logo/dropdown.svg';
import home from '../../assets/logo/home.svg';
import bin from '../../assets/logo/bin.svg';
import edit from '../../assets/logo/edit.svg';
import contact from '../../assets/logo/contact.png';
import PolicyDetailsDrawer from './components/PolicyDetailsDrawer';
import MobilePolicyDetails from './components/MobilePolicyDetails';
import EditPolicyDetails from './EditPolicyDetails';
import DeleteModal from '../../components/DeleteModal';
import ErrorCard from '../Pipeline/components/ErrorCard';
import { useOutsideClick } from '../../hooks/useOutsideClick';
import OpportunityContactModal from '../../components/OppoortunityContactModal';
import ContactForm from '../../components/ContactModalDetailsPage/ContactModal';
import moment from 'moment';

const PolicyDetails = () => {
  const editRef = useRef(null);
  const dispatch = useDispatch<AppDispatch>();
  const [showEditModal, setShowEditModal] = useState('closed');
  const [showDeleteModal, setShowDeleteModal] = useState('closed');
  const [quickDrop, setQuickDrop] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false);
  const loadingStatus = useSelector(
    (state: { policies: PoliciesSliceState }) => state.policies.status
  );
  const [showLinkcontactModal, setShowLinkContactModal] = useState('closed');
  const [showAddcontactModal, setShowAddContactModal] = useState('closed');
  const policyDetails = useSelector(selectPolicy);
  const { uuid } = useParams();
  const navigate = useNavigate();
  useOutsideClick(editRef, setQuickDrop);
  useEffect(() => {
    if (loadingStatus === 'idle' || loadingStatus === 'succeeded') {
      dispatch(getPolicyDetails({ uuid }));
      uuid && dispatch(getCount({ uuid }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [uuid]);

  const onDelete = (uuid: string) => {
    dispatch(
      onDeletePolicy({
        uuid,
        successCB: () => {
          setShowDeleteModal('closing');
          setTimeout(() => {
            setShowSuccess(true);
          }, 100);
          setTimeout(() => {
            setShowDeleteModal('closed');
            setShowSuccess(false);
            navigate('/mybook/policies');
          }, 2000);
        },
      })
    );
  };

  return (
    <DetailsDiv>
      <div className='d-none d-xl-block'>
        <SideBar />
      </div>

      <div className='content-section'>
        <div className='content-div d-none d-xl-block'>
          <TopBar>
            <div className='breadcrumb-div'>
              <span
                onClick={() => navigate('/mybook/policies')}
                className='breadcrumb-item'
              >
                Policies /
              </span>{' '}
              #
              {policyDetails?.policyNumber ? policyDetails?.policyNumber : '--'}{' '}
              |{' '}
              {policyDetails?.Product?.name
                ? policyDetails?.Product?.name
                : policyDetails?.CustomProduct?.name
                ? policyDetails?.CustomProduct?.name
                : '--'}
            </div>
          </TopBar>

          <div className='header-div d-flex justify-content-between'>
            {showSuccess && (
              <ErrorCard
                message={`<b>${
                  policyDetails?.Product?.name
                    ? policyDetails?.Product?.name
                    : policyDetails?.CustomProduct?.name
                    ? policyDetails?.CustomProduct?.name
                    : '--'
                }</b> is <b> deleted</b> `}
                show='open'
              />
            )}
            <div className='d-flex align-items-center'>
              <div className='profile-picdiv d-flex align-items-center justify-content-center'>
                <img src={home} alt='' />
                {/* { TODO : assets} */}
              </div>
              <div>
                <div className=' d-flex align-items-center mb-2'>
                  <span>
                    <h2>
                      {' '}
                      #
                      {policyDetails?.policyNumber
                        ? policyDetails?.policyNumber
                        : '--'}{' '}
                      |{' '}
                      {policyDetails?.Product?.name
                        ? policyDetails?.Product?.name
                        : policyDetails?.CustomProduct?.name
                        ? policyDetails?.CustomProduct?.name
                        : '--'}
                    </h2>
                  </span>
                  {moment().isBefore(
                    moment(policyDetails?.effectiveDate).format('YYYY-MM-DD'),
                    'day'
                  ) && (
                    <span className='status statusNotInvited'>
                      {'Inactive'}
                    </span>
                  )}
                  {moment().isBetween(
                    moment(policyDetails?.effectiveDate).format('YYYY-MM-DD'),
                    moment(policyDetails?.expiryDate).format('YYYY-MM-DD')
                  ) && <span className='status statusPolicy'>{'Active'}</span>}
                  {moment(
                    moment(policyDetails?.expiryDate).format('YYYY-MM-DD')
                  ).isBefore(moment().format('YYYY-MM-DD'), 'day') && (
                    <span className='status statusInactive'>{'Expired'}</span>
                  )}
                </div>

                <div className='header-info d-flex'>
                  <span
                    className='opportunities-div'
                    style={{ textDecoration: 'none' }}
                  >
                    {/* TODO : Change to Address */}
                    {policyDetails?.Accounts?.Address?.street ? (
                      policyDetails?.Accounts?.Address?.street
                    ) : (
                      <span style={{ fontWeight: 400 }}>
                        No specified address
                      </span>
                    )}
                  </span>
                  <span className='amount'>
                    $
                    {policyDetails?.premium
                      ? policyDetails?.premium.toLocaleString('en-US')
                      : 0}
                  </span>{' '}
                  <span
                    className='opportunities-div d-flex align-items-center text-overflow-container'
                    style={{ textDecoration: 'none' }}
                  >
                    <img src={contact} alt='' className='contact-img' />
                    Account:{' '}
                    <span
                      className='nav-decor'
                      onClick={() =>
                        navigate(`/account/${policyDetails?.Accounts?.uuid}`)
                      }
                    >
                      {policyDetails?.Accounts?.name
                        ? policyDetails?.Accounts.name
                        : '--'}{' '}
                    </span>
                    <span className='tooltiptext'>
                      <span className='tooltipInnertext'>
                        {policyDetails?.Accounts?.name
                          ? policyDetails?.Accounts.name
                          : '--'}{' '}
                      </span>
                    </span>
                  </span>
                  {policyDetails?.Contacts && (
                    <span className='d-flex align-items-center text-overflow-container'>
                      <img src={contact} alt='' className='contact-img' />
                      Contact:{' '}
                      <span
                        className='nav-decor '
                        onClick={() =>
                          navigate(
                            `/contacts/${policyDetails?.Contacts[0].uuid}`
                          )
                        }
                      >
                        {policyDetails?.Contacts[0]?.firstName}{' '}
                        {policyDetails?.Contacts[0]?.lastName}{' '}
                      </span>
                      <span className='tooltiptext'>
                        <span className='tooltipInnertext'>
                          {policyDetails?.Contacts[0]?.firstName}{' '}
                          {policyDetails?.Contacts[0]?.lastName}{' '}
                        </span>
                      </span>
                      {policyDetails?.Contacts.length > 1 && (
                        <span className='count-div'>
                          +{policyDetails?.Contacts.length - 1}
                        </span>
                      )}
                    </span>
                  )}
                </div>
              </div>
            </div>

            <div>
              <button
                onClick={() => navigate(`/policy/${uuid}/documents`)}
                className='policy-button'
              >
                View signed document
              </button>

              <button
                className='dropDownButton'
                onClick={() => setQuickDrop(!quickDrop)}
                ref={editRef}
              >
                <img src={dropdown} alt='' />
                {quickDrop && (
                  <div className='dropdown-div'>
                    <div
                      className='edit-div'
                      onClick={() => {
                        setShowEditModal('open');
                        setQuickDrop(false);
                      }}
                    >
                      Edit <img src={edit} alt='bin' />
                    </div>
                    <div className='borderDiv'></div>
                    <div
                      className='edit-div'
                      onClick={() => {
                        setShowDeleteModal('open');
                        setQuickDrop(false);
                      }}
                    >
                      Delete <img src={bin} alt='bin' />
                    </div>
                  </div>
                )}
              </button>
            </div>
          </div>
          <div className='d-flex' style={{ borderBottom: '1px solid #E6E9F0' }}>
            {policyDetails && (
              <PolicyDetailsDrawer
                policyDetails={policyDetails}
                setShowEditModal={setShowEditModal}
                setShowLinkContactModal={setShowLinkContactModal}
                setShowAddContactModal={setShowAddContactModal}
              />
            )}
            <div className='options-div'>
              <DetailsPageTab name={'Policy'} />
            </div>
          </div>
        </div>
        <div className='d-xl-none'>
          {policyDetails && (
            <MobilePolicyDetails
              policyDetails={policyDetails}
              setShowEditModal={setShowEditModal}
              setShowLinkContactModal={setShowLinkContactModal}
              setShowAddContactModal={setShowAddContactModal}
            />
          )}
        </div>
        {policyDetails && showEditModal !== 'closed' && (
          <EditPolicyDetails
            show={showEditModal}
            closeModal={setShowEditModal}
            uuid={policyDetails.uuid}
            policy={policyDetails}
          />
        )}
        {showDeleteModal !== 'closed' && policyDetails && (
          <DeleteModal
            showModal={showDeleteModal}
            closeModal={setShowDeleteModal}
            deleteFunction={onDelete}
            uuid={policyDetails.uuid}
            loading={loadingStatus}
            resource='contact'
            name={
              policyDetails?.Product?.name
                ? policyDetails?.Product?.name
                : policyDetails?.CustomProduct?.name
                ? policyDetails?.CustomProduct?.name
                : 'unknown'
            }
          />
        )}
        {showLinkcontactModal !== 'closed' && policyDetails && (
          <OpportunityContactModal
            name='policy'
            uuid={policyDetails?.uuid}
            accountUUID={policyDetails?.Accounts?.uuid}
            opportunityAccountContact={policyDetails?.Contacts.map(
              (item) => item?.uuid
            )}
            show={showLinkcontactModal}
            closeModal={setShowLinkContactModal}
          />
        )}

        {showAddcontactModal !== 'closed' && policyDetails && (
          <ContactForm
            uuid={policyDetails?.uuid}
            resource='Policy'
            show={showAddcontactModal}
            closeModal={setShowAddContactModal}
          />
        )}
      </div>
    </DetailsDiv>
  );
};

export default PolicyDetails;
