import { useState, useEffect, useRef } from 'react';
import { useForm } from 'react-hook-form';
import SearchDropdown from '../../../components/SearchDropdown';
import WarningCard from '../../Pipeline/components/WarningCard';
import cross from '../../../assets/logo/cross.svg';
import { StyledAddModal } from '../elements/styles';
import { IContact } from '../../../interfaces/Contact';
import { IAccount } from '../../../interfaces/Account';
import { fetchContacts } from '../../../api/contact';
import { fetchAccounts } from '../../../api/account';
import { fetchOpportunities } from '../../../api/opportunities';
import document from '../../../assets/logo/document.svg';
import vector from '../../../assets/logo/dropdown.svg';
import CustomInputField from './CustomInputField';
import CustomDateField from './CustomDateField';
import blackplus from '../../../assets/logo/blackplus.svg';
import warning from '../../../assets/logo/warning.svg';
import plus from '../../../assets/logo/blackplus.svg';
import tick from '../../../assets/logo/tick.svg';
import phone from '../../../assets/logo/Dent/Phonesmall.svg';
import mail from '../../../assets/logo/Dent/Mailsmall.svg';
import calender from '../../../assets/logo/Dent/calendarSmall.svg';
import edit from '../../../assets/logo/edit.svg';
import moment from 'moment';
import bin from '../../../assets/logo/bin.svg';
import DocumentsField from '../../../components/DocumentsField';
import MarkdownEditor from '../../../components/MarkdownEditor';
import LocalStorageService from '../../../utils/LocalStorageService';
import { fetchEventType } from '../../../api/events';
import { getDocumentType } from '../../../api/documents';
import { addDocument } from '../../../store/documents/documentsSlice';
import {
  deleteAnEvent,
  updateEventDetails,
  TaskSliceState,
  getEventDetails,
  deleteNote,
  getAllActivtiesCount,
} from '../../../store/tasks/tasksSlice';
import {
  updateDocumentDetails,
  reaploadDocumentDetails,
} from '../../../store/documents/documentsSlice';
import { AppDispatch } from '../../../store';
import { useDispatch, useSelector } from 'react-redux';
import NotesContainer from '../../../components/NotesContainer';
import Modal from '../../../components/Modal';
import ErrorCard from '../../Pipeline/components/ErrorCard';
import phonevector from '../../../assets/logo/Dent/DentCallSmall.svg';
import calendarvector from '../../../assets/logo/Dent/DentCalendersmall.svg';
import emailvector from '../../../assets/logo/Dent/DentMailSmall.svg';
import TimePicker from '../../../components/TimePicker';
import { useOutsideClick } from '../../../hooks/useOutsideClick';
import GuestListDropDown from './GuestListDropDown';
import TextAreaField from './TextAreaField';
import LinkToField from './LinkToField';
import { fetchPolicies } from '../../../api/policy';
import { fetchLeads } from '../../../api/lead';
const EventDetailsModal = ({
  show,
  closeModal,
  uuid,
  setAction,
  setCloseAllTabs,
}: {
  show: string;
  closeModal: Function;
  uuid: string;
  setAction: Function;
  setCloseAllTabs: Function;
}) => {
  const [accountList, setAccounts] = useState<any[]>([]);
  const [accountUUID, setAccountUUID] = useState('');
  const [accountSearch, setAccountSearch] = useState('');

  ///////////////////

  const [contactList, setContacts] = useState<any[]>([]);
  const [contactUUID, setContactUUID] = useState('');
  const [contactSearch, setContactSearch] = useState('');

  /////////////

  const [opportunityList, setOpportunity] = useState<any[]>([]);
  const [opportunityUUID, setOpportunityUUID] = useState('');
  const [opportunitySearch, setOpportunitySearch] = useState('');

  /////////////

  const [leadList, setLeads] = useState<any[]>([]);
  const [leadUUID, setLeadUUID] = useState('');
  const [leadSearch, setLeadSearch] = useState('');

  /////////////
  const [policyList, setPolicies] = useState<any[]>([]);
  const [policyUUID, setPolicyUUID] = useState('');
  const [policySearch, setPolicySearch] = useState('');

  ///////////
  const [DueTimeModal, setDueTimeModal] = useState(false);
  const [EventSheduleTime, setEventSheduleTime] = useState(false);
  const [duetimeVal, setDueTimeVal] = useState('');
  const [scheduledTimeVal, scheduledTimeTimeVal] = useState('');

  const [showSuccess, setShowSuccess] = useState(false);
  const [linkedTo, setLinkedTo] = useState('Contact');
  const [linkToDropDown, setLinkToDropDown] = useState(false);
  const [description, setDescription] = useState<any>({});
  const [dueDate, setDueDate] = useState<any>({});
  const [eventTypeUUID, setEventTypeUUID] = useState('');
  const [eventTypeName, setEventTypeName] = useState('');
  const [loadingStatus, setLoadingStatus] = useState('idle');
  const [notes, setNotes] = useState<any>([{}]);
  const [guestList, showGuestList] = useState(false);
  const [guest, setGuest] = useState<any>([]);
  const [guestUUID, setGuestUUID] = useState<any>([]);
  const [eventTitle, setEventTitle] = useState<any>({});
  const [scheduledDate, setScheduledDate] = useState<any>({});
  const [eventTypes, setEventType] = useState([{}]);
  const [eventDetails, setEventDetails] = useState<any>({});
  const [onDelete, setDelete] = useState(false);
  const [owner, setOwner] = useState(false);
  const [file, setFile] = useState<any>();
  const [DocumentTypes, setDocumentTypes] = useState<any>([]);
  const [DoctypeUUID, setDocTypeUUID] = useState('');
  const [docType, setDocType] = useState('');
  const [error, setError] = useState('');
  const [dropDown, setDropDown] = useState(false);
  const [docUUID, setDocUUID] = useState('');
  const [reupload, setReupload] = useState(false);
  const [HoverUUID, setHoverUUID] = useState('');
  const [fileError, setFileError] = useState('');
  const [guestManageDropDown, setGuestManageDropDown] = useState(false);

  const [linkToMsg, setLinktoMsg] = useState('');
  const [dueDateMsg, setDueDateMsg] = useState('');
  const [eventtitleMsg, setEventTitleMsg] = useState('');
  const [eventTypeMsg, setEventTypeMsg] = useState('');
  const [eventScheduledDateMsg, setEventScheduledDateMsg] = useState('');
  const [notesError, setNotesError] = useState('');
  const [eventGuestMsg, setEventGuestMsg] = useState('');
  const [isdeleted, setIsdeleted] = useState(false);
  const [fileExist, setFileExist] = useState(false);
  const [makeEditNote, setMakeEditNote] = useState(false);
  const [oldNotes, setOldNotes] = useState<any>([{}]);
  const [noteIndex, setNoteIndex] = useState(0);
  const [noteEditDropdown, setNoteEditDropdown] = useState(false);
  const [linkClicked, setLinkedClicked] = useState(true);
  const [descriptionError, setdescriptionError] = useState('');

  const dropRef = useRef(null);
  useOutsideClick(dropRef, setDueTimeModal);
  const dropEventRef = useRef(null);
  useOutsideClick(dropEventRef, setEventSheduleTime);
  const GuestList = useRef(null);
  useOutsideClick(GuestList, showGuestList);
  const dropManageGuestRef = useRef(null);
  useOutsideClick(dropManageGuestRef, setGuestManageDropDown);
  const dispatch = useDispatch<AppDispatch>();
  var x = 15;
  let Times = [];
  var tt = 0;
  var ap = ['AM', 'PM'];

  for (var i = 0; tt < 24 * 60; i++) {
    var hh = Math.floor(tt / 60);
    var mm = tt % 60;
    Times[i] =
      ('' + (hh === 12 ? 12 : hh % 12)).slice(-2) +
      ':' +
      ('0' + mm).slice(-2) +
      ap[Math.floor(hh / 12)];
    tt = tt + x;
  }

  const addsNotes = () => {
    let settingNotes = [...notes];
    setNotes([]);
    setNotesError('');
    setMakeEditNote(true);
    setNoteIndex(0);
    settingNotes.unshift({ text: '' });
    setTimeout(() => {
      setNotes(settingNotes);
    });
  };

  const taskDetailsStatus = useSelector(
    (state: { tasks: TaskSliceState }) => state.tasks?.status
  );

  const { handleSubmit, register, control } = useForm({
    //     resolver: yupResolver(PipelineSchema),
  });
  useEffect(() => {
    fetchContacts(
      'firstName',
      'asc',
      '',
      25,
      0,
      'True',
      (data: IContact[]) => {
        setContacts(data);
        setLoadingStatus('succeeded');
      },
      'PERSONAL'
    );
    fetchEventType((data: any) => {
      setEventType(data);
    });
  }, [eventTypeUUID]);
  const loadOpportunity = (value: string) => {
    if (loadingStatus === 'idle' || loadingStatus === 'succeeded')
      fetchOpportunities(
        'name',
        'asc',
        value,

        '',
        '',
        (data: any) => {
          setOpportunity(data?.opportunities);
          setLoadingStatus('succeeded');
        },
        '',
        '',
        25,
        0
      );
    // setOpportunity(useSelector(selectAllOpportunities));
  };
  useEffect(() => {
    getDocumentType((data: any) => {
      setDocumentTypes(data);
    });
  }, [show]);
  const onSet = (name: string, value: string) => {
    name === 'eventTitle' ? setEventTitle(value) : setDescription(value);
  };

  const onSetDate = (name: string, value: string) => {
    name === 'scheduledDate'
      ? setScheduledDate({ scheduledDate: value })
      : setDueDate({ dueDate: value });
  };

  const loadAccount = (value: string) => {
    if (loadingStatus === 'idle' || loadingStatus === 'succeeded') {
      setLoadingStatus('loading');
      fetchAccounts(
        'name',
        'asc',
        value,
        25,
        0,
        '',
        (data: IAccount[]) => {
          setAccounts(data);
          setLoadingStatus('succeeded');
        },
        'True'
      );
    }
  };

  const onCloseModal = () => {
    closeModal('closing');
    setTimeout(() => {
      closeModal('closed');
    }, 300);
  };

  const loadPolicy = (value: string) => {
    if (loadingStatus === 'idle' || loadingStatus === 'succeeded') {
      setLoadingStatus('loading');
      fetchPolicies(
        'name',
        'asc',
        value,
        25,
        0,
        '',
        [],
        false,
        '',
        '',
        '',
        '',
        '',
        (data: any) => {
          setPolicies(data?.policies);
          setLoadingStatus('succeeded');
        }
      );
      setLinktoMsg('');
    }
  };

  const loadLead = (value: string) => {
    if (loadingStatus === 'idle' || loadingStatus === 'succeeded') {
      setLoadingStatus('loading');
      fetchLeads('name', 'asc', value, 25, 0, '', '', '', '', (data: any) => {
        setLeads(data.leads);
        setLoadingStatus('succeeded');
      });
      setLinktoMsg('');
    }
  };

  const loadContact = (value: string) => {
    if (loadingStatus === 'idle' || loadingStatus === 'succeeded') {
      setLoadingStatus('loading');
      fetchContacts(
        'firstName',
        'asc',
        value,
        25,
        0,
        'True',
        (data: IContact[]) => {
          setContacts(data);
          setLoadingStatus('succeeded');
        },
        'PERSONAL'
      );
    }
  };

  useEffect(() => {
    dispatch(
      getEventDetails({
        uuid,
        successCB: (data: any) => {
          setEventDetails(data);
          setLinkedTo(
            data?.ContactId
              ? 'Contact'
              : data?.AccountId
              ? 'Account'
              : data?.OpportunityId
              ? 'Opportunity'
              : data?.LeadId
              ? 'Lead'
              : data?.PolicyId
              ? 'Policy'
              : ''
          );
          setDueTimeVal(data?.dueDate);
          scheduledTimeTimeVal(data?.scheduledDate);
          setDueDate({ dueDate: data?.dueDate });
          setScheduledDate({ scheduledDate: data?.scheduledDate });
          setEventTypeName(data?.EventType?.name);
          setEventTypeUUID(data?.EventType?.uuid);
          setEventTitle({ eventTitle: data?.name });

          setFile(data?.Documents[0]);

          setDocUUID(data?.Documents[0]?.uuid);

          setDocType(data?.Documents[0]?.DocumentType?.name);
          setDocTypeUUID(data?.Documents[0]?.DocumentType?.uuid);
          if (data?.Documents[0]) {
            setFileExist(true);
          }

          setNotes(
            data?.Notes.map((item: any, key: number) => {
              return {
                text: item?.text,
                noteUUID: item?.uuid,
                updatedAt: item?.updatedAt,
              };
            })
          );
          setOldNotes(
            data?.Notes.map((item: any, key: number) => {
              return {
                text: item?.text,
                noteUUID: item?.uuid,
                updatedAt: item?.updatedAt,
              };
            })
          );

          setDescription({ description: data?.description });
          if (data?.OpportunityId) {
            setOpportunityUUID(data?.Opportunity?.uuid);
            setOpportunitySearch(data?.Opportunity?.name);
          } else if (data?.ContactId) {
            setContactSearch(
              data?.Contact?.firstName + ' ' + data?.Contact?.lastName
            );
            setContactUUID(data?.Contact?.uuid);
          } else if (data?.AccountId) {
            setAccountSearch(data?.Account?.name);
            setAccountUUID(data?.Account?.uuid);
          } else if (data?.LeadId) {
            setLeadSearch(data?.Lead?.data?.contact?.name);
            setLeadUUID(data?.Lead?.uuid);
          } else if (data?.PolicyId) {
            setPolicySearch(data?.Policy?.Accounts?.name);
            setPolicyUUID(data?.Policy?.uuid);
          }
          if (data?.EmailEvents?.length > 0) {
            setGuest(data?.EmailEvents.map((e: any) => e.Contact));
            setGuestUUID(data?.EmailEvents.map((e: any) => e.Contact?.uuid));
          }
          if (data?.CalendarEvents?.length > 0) {
            setGuest(data?.CalendarEvents.map((e: any) => e?.Contact));
            setGuestUUID(data?.CalendarEvents.map((e: any) => e.Contact?.uuid));
          }
          if (data?.PhoneEvents?.length > 0) {
            setGuest(data?.PhoneEvents.map((e: any) => e?.Contact));
            setGuestUUID(data?.PhoneEvents.map((e: any) => e.Contact?.uuid));
          }
        },
      })
    ); // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [uuid, dispatch, isdeleted]);
  useEffect(() => {
    if (eventDetails?.Owner?.uuid === LocalStorageService.getUser()?.uuid)
      setOwner(true);
  }, [eventDetails]);

  const dueDateTime = moment(
    moment(dueDate.dueDate).format('YYYY-MM-DD') +
      moment(duetimeVal).format('hh:mm A'),
    'YYYY-MM-DDLT'
  ).toDate();

  const sheduleDateTime = moment(
    moment(scheduledDate.scheduledDate).format('YYYY-MM-DD') +
      moment(scheduledTimeVal).format('hh:mm A'),
    'YYYY-MM-DDLT'
  ).toDate();

  useEffect(() => {
    if (eventDetails) {
      if (eventDetails?.OpportunityId || opportunityUUID) {
        if (
          opportunityUUID &&
          eventDetails?.Opportunity?.uuid !== opportunityUUID
        ) {
          setLinkedClicked(false);
        }
      }
      if (eventDetails?.ContactId || contactUUID) {
        if (contactUUID && eventDetails?.Contact?.uuid !== contactUUID) {
          setLinkedClicked(false);
        }
      }
      if (eventDetails?.AccountId || accountUUID) {
        if (accountUUID && eventDetails?.Account?.uuid !== accountUUID) {
          setLinkedClicked(false);
        }
      }
      if (eventDetails?.LeadId || leadUUID) {
        if (leadUUID && eventDetails?.Lead?.uuid !== leadUUID) {
          setLinkedClicked(false);
        }
      }
      if (eventDetails?.PolicyId || policyUUID) {
        if (policyUUID && eventDetails?.Policy?.uuid !== policyUUID) {
          setLinkedClicked(false);
        }
      }
      if (eventDetails?.EmailEvents?.length > 0 && guest.length) {
        if (eventDetails?.EmailEvents?.length !== guest.length) {
          setLinkedClicked(false);
        }
      }
      if (eventDetails?.CalendarEvents?.length > 0 && guest.length) {
        if (eventDetails?.CalendarEvents?.length !== guest.length) {
          setLinkedClicked(false);
        }
      }
      if (eventDetails?.PhoneEvents?.length > 0 && guest.length) {
        if (eventDetails?.PhoneEvents?.length !== guest.length) {
          setLinkedClicked(false);
        }
      }
    }
  }, [
    opportunityUUID,
    contactUUID,
    accountUUID,
    eventDetails,
    guest,
    policyUUID,
    leadUUID,
  ]);
  useEffect(() => {
    if (noteEditDropdown) {
      setLinkedClicked(false);
    }
  }, [noteEditDropdown]);

  const onSubmit = (data: any) => {
    if (linkedTo === 'Contact' && !contactUUID) {
      setLinktoMsg(`Please select a ${linkedTo}`);
      return;
    } else if (linkedTo === 'Account' && !accountUUID) {
      setLinktoMsg(`Please select a ${linkedTo}`);
      return;
    } else if (linkedTo === 'Opportunity' && !opportunityUUID) {
      setLinktoMsg(`Please select a ${linkedTo}`);
      return;
    } else if (linkedTo === 'Policy' && !policyUUID) {
      setLinktoMsg(`Please select a ${linkedTo}`);
      return;
    } else if (linkedTo === 'Lead' && !leadUUID) {
      setLinktoMsg(`Please select a ${linkedTo}`);
      return;
    } else if (!eventTitle) {
      setEventTitleMsg(`Please enter a title `);
      return;
    } else if (!eventTypeUUID) {
      setEventTypeMsg(`Please select a  Type `);
      return;
    } else if (!scheduledDate.scheduledDate) {
      setEventScheduledDateMsg(`Please select scheduledDate`);
      return;
    } else if (!dueDate.dueDate) {
      setDueDateMsg(`Please select a end date `);
      return;
    } else if (guest.length < 1) {
      setEventGuestMsg(`Please  add Guest `);
      return;
    }
    if (file && !DoctypeUUID) {
      setError('Please provide a document type');
      return;
    }
    if (sheduleDateTime < new Date()) {
      setEventScheduledDateMsg('Please Select valid time');
      return;
    }
    if (dueDateTime < sheduleDateTime) {
      setDueDateMsg('Invalid Time.');
      return;
    }
    if (linkedTo === 'Opportunity') {
      data.accountUUID = null;
      data.contactUUID = null;
      data.opportunityUUID = opportunityUUID;
      data.leadUUID = null;
      data.policyUUID = null;
    }
    if (linkedTo === 'Account') {
      data.accountUUID = accountUUID;
      data.contactUUID = null;
      data.opportunityUUID = null;
      data.leadUUID = null;
      data.policyUUID = null;
    }
    if (linkedTo === 'Contact') {
      data.contactUUID = contactUUID;
      data.accountUUID = null;
      data.opportunityUUID = null;
      data.leadUUID = null;
      data.policyUUID = null;
    }
    if (linkedTo === 'Lead') {
      data.contactUUID = null;
      data.accountUUID = null;
      data.opportunityUUID = null;
      data.leadUUID = leadUUID;
      data.policyUUID = null;
    }
    if (linkedTo === 'Policy') {
      data.contactUUID = null;
      data.accountUUID = null;
      data.opportunityUUID = null;
      data.leadUUID = null;
      data.policyUUID = policyUUID;
    }
    for (let i = 0; i < notes?.length; i++) {
      if (!notes[i].text.trim()) {
        setNotesError(`Notes shouldn't be empty`);
        return notesError;
      }
    }

    let updatedNotes = [...notes];
    for (let i = 0; i <= updatedNotes.length; i++) {
      for (let j = 0; j <= oldNotes.length; j++) {
        if (updatedNotes[i]?.text === oldNotes[j]?.text) {
          updatedNotes.splice(i, 1);
        }
      }
    }

    data.guests = guestUUID.map((item: any) => ({ guestUUID: item }));
    data.name = eventTitle.eventTitle ? eventTitle.eventTitle : eventTitle;
    data.scheduledDate = sheduleDateTime;
    data.dueDate = dueDateTime;
    data.eventTypeUUID = eventTypeUUID;
    data.eventTypeName = eventTypeName;
    let canSubmit = true;
    if (description) {
      if (!description.description.trim()) {
        canSubmit = false;
        setdescriptionError('Description cannot be blank space');
      }
    }

    data.description = description.description;
    data.notes = updatedNotes;
    if (canSubmit) {
      dispatch(
        updateEventDetails({
          uuid,
          data: data,
          successCB: () => {
            setTimeout(() => {
              setShowSuccess(true);
            }, 300);
            if (
              dueDateTime.getDate() !== new Date(eventDetails.dueDate).getDate()
            ) {
              setAction(true);
              setCloseAllTabs(true);
            }
            closeModal('closing');
            setTimeout(() => {
              setShowSuccess(false);
              setTimeout(() => {
                closeModal('closed');
              }, 300);
            }, 2000);
          },
        })
      );
    }

    if (reupload && uuid && fileExist) {
      let formData = new FormData();
      formData.append('docFile', file);
      formData.append('resource', 'event');
      formData.append('resourceUUID', uuid);
      formData.append('documentTypeUUID', DoctypeUUID);
      formData.append('action', 'updateDocument');
      dispatch(
        reaploadDocumentDetails({
          uuid: docUUID,
          data: formData,
          successCB: () => {
            setTimeout(() => {
              setShowSuccess(true);
            }, 300);
            closeModal('closing');
            setTimeout(() => {
              setShowSuccess(false);
              setTimeout(() => {
                closeModal('closed');
              }, 300);
            }, 2000);
          },
        })
      );
    } else if (uuid && !reupload && fileExist) {
      data.documentTypeUUID = DoctypeUUID;
      data.action = 'documentTypeUpdate';
      dispatch(
        updateDocumentDetails({
          uuid: docUUID,
          data: data,
          successCB: () => {
            setTimeout(() => {
              setShowSuccess(true);
            }, 300);
            closeModal('closing');
            setTimeout(() => {
              setShowSuccess(false);
              setTimeout(() => {
                closeModal('closed');
              }, 300);
            }, 2000);
          },
        })
      );
    } else if (!reupload && !fileExist) {
      let formData = new FormData();
      formData.append('docFile', file);
      formData.append('resource', 'event');
      formData.append('resourceUUID', uuid);
      formData.append('documentTypeUUID', DoctypeUUID);
      dispatch(
        addDocument({
          formData,
          successCB: () => {
            setTimeout(() => {
              setShowSuccess(true);
            }, 300);
            closeModal('closing');
            setTimeout(() => {
              setShowSuccess(false);
              setTimeout(() => {
                closeModal('closed');
              }, 300);
            }, 2000);
          },
          errorCB: () => {},
        })
      );
    }
  };

  const onSetNotesValue = (value: any, index: number, uuid?: string) => {
    let updatedNotes = [...notes];
    updatedNotes[index] = { text: value, noteUUID: uuid };
    setNotes(updatedNotes);
  };
  let PhoneUUID: any = eventTypes.find((item: any) => item.name === 'PHONE');
  let EmailUUID: any = eventTypes.find((item: any) => item.name === 'EMAIL');
  let CalendarUUID: any = eventTypes.find(
    (item: any) => item.name === 'CALENDAR'
  );
  const onDeleteEvent = (uuid: string) => {
    dispatch(
      deleteAnEvent({
        successCB: () => {
          setAction(true);
          dispatch(getAllActivtiesCount({}));
          setDelete(true);
          setTimeout(() => {
            setShowSuccess(true);
          }, 300);
          closeModal('closing');
          setTimeout(() => {
            setShowSuccess(false);
            setTimeout(() => {
              closeModal('closed');
            }, 300);
          }, 2000);
        },
        uuid,
      })
    );
  };

  const deleteANote = (uuid: string) => {
    setIsdeleted(!isdeleted);
    dispatch(
      deleteNote({
        successCB: () => {},
        uuid,
      })
    );
  };

  useEffect(() => {
    if (description) {
      setdescriptionError('');
    }
  }, [description]);

  return (
    <>
      {showSuccess && !onDelete && (
        <WarningCard
          message={`<b>${
            eventTitle.eventTitle ? eventTitle.eventTitle : eventTitle
          } </b> has  <b> Updated.</b>`}
          show='open'
        />
      )}
      {showSuccess && onDelete && (
        <ErrorCard
          message={`<b>${
            eventTitle.eventTitle ? eventTitle.eventTitle : eventTitle
          } </b> has  <b>Deleted<b>`}
          show='open'
        />
      )}
      <StyledAddModal>
        <Modal open={show} align='right'>
          <div className='drawer-content position-relative h-100'>
            <div className='top-div d-flex justify-content-between align-items-center'>
              <h2>Event Details</h2>
              <button className='cross-btn'>
                <img
                  className='cross'
                  src={cross}
                  alt=''
                  onClick={() => {
                    onCloseModal();
                  }}
                />
              </button>
            </div>
            <div className='NavButtons'></div>
            <div className='NavButtons d-flex w-100 justify-content-between'>
              <div className='d-flex'>
                <div className='PhoneContainer disabledButon  d-flex align-items-center justify-content-center '>
                  <img src={phone} alt='' />
                </div>
                {/* <button className='doneButton'>Send reminder</button> */}
              </div>
              {owner && (
                <img
                  className='deleteButton'
                  src={bin}
                  alt=''
                  onClick={() => onDeleteEvent(eventDetails?.uuid)}
                />
              )}
            </div>
            <form className='Event-form' onSubmit={handleSubmit(onSubmit)}>
              <div className='taskModalContent'>
                <div className='taskContainer '>
                  <div className='d-sm-flex align-items-center taskTypeDiv'>
                    <h2 className='titleLabel col-3'>Event title</h2>
                    <div className='d-flex align-items-center w-100'>
                      <div className='w-100'>
                        <div className='d-flex align-items-center taskTypeDiv  descriptionField mb-0  w-100'>
                          <CustomInputField
                            type='text'
                            name='eventTitle'
                            values={eventTitle}
                            placeholder='Add title'
                            onSetItem={onSet}
                          />
                          <img src={edit} alt='' className='editDesc' />
                        </div>
                        {eventtitleMsg && !eventTitle ? (
                          <span className='error-field'>{eventtitleMsg} </span>
                        ) : null}
                      </div>
                    </div>
                  </div>

                  <div className='d-sm-flex align-items-center taskTypeDiv'>
                    <h2 className='titleLabel col-3'>Type</h2>
                    <div>
                      <div className='d-flex align-items-center imagesContainerEvent col-5.5'>
                        <img
                          className={`taskImage ${
                            eventTypeUUID === PhoneUUID?.uuid && 'activeType'
                          }`}
                          onMouseEnter={() => {
                            setHoverUUID(PhoneUUID.uuid);
                          }}
                          onMouseLeave={() => {
                            setHoverUUID('');
                          }}
                          src={
                            eventTypeUUID === PhoneUUID?.uuid ||
                            HoverUUID === PhoneUUID?.uuid
                              ? phonevector
                              : phone
                          }
                          alt=''
                          onClick={() => {
                            if (PhoneUUID) {
                              setEventTypeUUID(PhoneUUID.uuid);
                              setEventTypeName(PhoneUUID.name);
                            }
                          }}
                        />
                        <img
                          className={`taskImage ${
                            eventTypeUUID === EmailUUID?.uuid && 'activeType'
                          }`}
                          onMouseEnter={() => {
                            setHoverUUID(EmailUUID.uuid);
                          }}
                          onMouseLeave={() => {
                            setHoverUUID('');
                          }}
                          src={
                            eventTypeUUID === EmailUUID?.uuid ||
                            HoverUUID === EmailUUID?.uuid
                              ? emailvector
                              : mail
                          }
                          alt=''
                          onClick={() => {
                            if (EmailUUID) {
                              setEventTypeUUID(EmailUUID.uuid);
                              setEventTypeName(EmailUUID.name);
                            }
                          }}
                        />
                        <img
                          className={`taskImage ${
                            eventTypeUUID === CalendarUUID?.uuid && 'activeType'
                          }`}
                          onMouseEnter={() => {
                            setHoverUUID(CalendarUUID.uuid);
                          }}
                          onMouseLeave={() => {
                            setHoverUUID('');
                          }}
                          src={
                            eventTypeUUID === CalendarUUID?.uuid ||
                            HoverUUID === CalendarUUID?.uuid
                              ? calendarvector
                              : calender
                          }
                          alt=''
                          onClick={() => {
                            if (CalendarUUID) {
                              setEventTypeUUID(CalendarUUID.uuid);
                              setEventTypeName(CalendarUUID.name);
                            }
                          }}
                        />
                      </div>
                      {eventTypeMsg && !eventTypeUUID ? (
                        <span className='error-field'>{eventTypeMsg} </span>
                      ) : null}
                    </div>
                  </div>

                  <div className='d-sm-flex align-items-center taskTypeDiv'>
                    <h2 className='titleLabel col-3'>Period</h2>
                    <div className='d-flex flex-column datePeriodDiv'>
                      <div className='dropIndicator'> </div>
                      <div className='PointerDiv'></div>
                      <div className='d-flex align-items-center  col'>
                        <div className='smallDateField'>
                          <CustomDateField
                            name='scheduledDate'
                            onSetItem={onSetDate}
                            values={scheduledDate}
                            placeholder='Start'
                            minDate={new Date()}
                          />
                        </div>
                        <div
                          className='smallDateFielddateOnly dateOnly'
                          onClick={() => setEventSheduleTime(true)}
                          ref={dropEventRef}
                        >
                          <span>
                            {scheduledTimeVal
                              ? moment(scheduledTimeVal.toString()).format(
                                  'hh:mm A'
                                )
                              : 'Time'}
                          </span>
                          {EventSheduleTime && (
                            <TimePicker
                              setTaskDueTime={setEventSheduleTime}
                              setDueTime={scheduledTimeTimeVal}
                              dueTime={scheduledTimeVal}
                            />
                          )}
                        </div>
                      </div>
                      {eventScheduledDateMsg &&
                      (!scheduledDate.scheduledDate ||
                        sheduleDateTime < new Date()) ? (
                        <span className='error-field'>
                          {eventScheduledDateMsg}{' '}
                        </span>
                      ) : null}

                      <div className='d-flex align-items-center  col'>
                        <div className='secondPointerDiv'></div>
                        <div className='smallDateField'>
                          <CustomDateField
                            name='dueDate'
                            onSetItem={onSetDate}
                            values={dueDate}
                            placeholder='End'
                            minDate={scheduledDate.scheduledDate}
                          />
                        </div>
                        <div
                          className='smallDateFielddateOnly  position-relative '
                          onClick={() => setDueTimeModal(true)}
                          ref={dropRef}
                        >
                          <span>
                            {duetimeVal
                              ? moment(duetimeVal.toString()).format('hh:mm A')
                              : 'Time'}
                          </span>
                          {DueTimeModal && (
                            <TimePicker
                              setTaskDueTime={setDueTimeModal}
                              setDueTime={setDueTimeVal}
                              dueTime={duetimeVal}
                            />
                          )}
                        </div>
                      </div>
                      {(!dueDate.dueDate || dueDateTime < sheduleDateTime) &&
                      dueDateMsg ? (
                        <span className='error-field'>{dueDateMsg} </span>
                      ) : null}
                    </div>
                  </div>
                  <div className='d-sm-flex taskTypeDiv'>
                    <h2 className='titleLabel LinktoDiv  col-3'>Link to</h2>
                    <div className='d-flex d-sm-block col'>
                      <button
                        type='button'
                        className='linkDiv d-flex justify-content-between align-items-center'
                        onClick={() => setLinkToDropDown(!linkToDropDown)}
                        onBlur={() => setLinkToDropDown(false)}
                      >
                        <h6 className='bookLabel '>{linkedTo}</h6>
                        {linkToDropDown && (
                          <div className='assigneeDropDown linkToDropDown d-flex flex-column justify-content-center'>
                            {[
                              'Contact',
                              'Account',
                              'Opportunity',
                              'Lead',
                              'Policy',
                            ]?.map((item: string, index: number) => (
                              <div className='dropList'>
                                <ul
                                  className={`usersList ${
                                    linkedTo === item && 'activeLink'
                                  }`}
                                  onClick={() => {
                                    setLinkedTo(item);
                                  }}
                                >
                                  {item}
                                </ul>
                              </div>
                            ))}
                          </div>
                        )}
                        <img src={vector} alt='' />
                      </button>
                      <div className='Linkedfields'>
                        {linkedTo === 'Contact' && (
                          <LinkToField
                            resource={linkedTo}
                            resourceSearch={contactSearch}
                            resourceList={contactList}
                            setResourceSearch={setContactSearch}
                            loadingStatus={loadingStatus}
                            register={register}
                            control={control}
                            loadResource={loadContact}
                            setResourceUUID={setContactUUID}
                          />
                        )}
                        {linkedTo === 'Opportunity' && (
                          <LinkToField
                            resource={linkedTo}
                            resourceSearch={opportunitySearch}
                            resourceList={opportunityList}
                            setResourceSearch={setOpportunitySearch}
                            loadingStatus={loadingStatus}
                            register={register}
                            control={control}
                            loadResource={loadOpportunity}
                            setResourceUUID={setOpportunityUUID}
                          />
                        )}
                        {linkedTo === 'Account' && (
                          <LinkToField
                            resource={linkedTo}
                            resourceSearch={accountSearch}
                            resourceList={accountList}
                            setResourceSearch={setAccountSearch}
                            loadingStatus={loadingStatus}
                            register={register}
                            control={control}
                            loadResource={loadAccount}
                            setResourceUUID={setAccountUUID}
                          />
                        )}
                        {linkedTo === 'Lead' && (
                          <LinkToField
                            resource={linkedTo}
                            resourceSearch={leadSearch}
                            resourceList={leadList}
                            setResourceSearch={setLeadSearch}
                            loadingStatus={loadingStatus}
                            register={register}
                            control={control}
                            loadResource={loadLead}
                            setResourceUUID={setLeadUUID}
                          />
                        )}
                        {linkedTo === 'Policy' && (
                          <LinkToField
                            resource={linkedTo}
                            resourceSearch={policySearch}
                            resourceList={policyList}
                            setResourceSearch={setPolicySearch}
                            loadingStatus={loadingStatus}
                            register={register}
                            control={control}
                            loadResource={loadPolicy}
                            setResourceUUID={setPolicyUUID}
                          />
                        )}
                      </div>
                      {linkedTo === 'Contact' && !contactUUID && linkToMsg ? (
                        <span className='error-field'>{linkToMsg} </span>
                      ) : null}
                      {linkedTo === 'Account' && !contactUUID && linkToMsg ? (
                        <span className='error-field'>{linkToMsg} </span>
                      ) : null}
                      {linkedTo === 'Opportunity' &&
                      !contactUUID &&
                      linkToMsg ? (
                        <span className='error-field'>{linkToMsg} </span>
                      ) : null}
                      {linkedTo === 'Lead' && !leadUUID && linkToMsg ? (
                        <span className='error-field'>{linkToMsg} </span>
                      ) : null}
                      {linkedTo === 'Policy' && !policyUUID && linkToMsg ? (
                        <span className='error-field'>{linkToMsg} </span>
                      ) : null}
                    </div>
                  </div>
                  <div className='d-sm-flex align-items-center taskTypeDiv'>
                    <h2 className='titleLabel col-3'>Guest</h2>
                    <div className=' guestsContainer  col'>
                      <button
                        type='button'
                        className='GuestDiv d-flex justify-content-between align-items-center'
                        onClick={() => showGuestList(true)}
                        ref={GuestList}
                      >
                        {guestList && (
                          <GuestListDropDown
                            contactList={contactList}
                            guestUUID={guestUUID}
                            setGuestUUID={setGuestUUID}
                            setGuests={setGuest}
                            guests={guest}
                          />
                        )}
                        <img
                          className='addguestbutton'
                          src={blackplus}
                          alt=''
                        />
                        {guest.length > 0 ? (
                          guest.map(
                            (item: any, index: number) =>
                              guest &&
                              index < 4 && (
                                <li
                                  key={index}
                                  className='ProfileList'
                                  style={{ zIndex: guest.length - index }}
                                >
                                  {' '}
                                  <div className='Guestprofile-pic d-flex align-items-center justify-content-center'>
                                    <div className='profile'>
                                      {item?.firstName?.charAt(0)}{' '}
                                      {item?.lastName?.charAt(0)}
                                    </div>
                                  </div>
                                </li>
                              )
                          )
                        ) : (
                          <li className='ProfileList'>Add guest/s</li>
                        )}
                        {guest.length > 4 && (
                          <span className='Guestprofile-pic GuestprofileNumber d-flex align-items-center justify-content-center'>
                            + {guest.length - 4}
                          </span>
                        )}
                      </button>
                      {guest.length > 0 && (
                        <>
                          <button
                            type='button'
                            className='ManageGuestHead'
                            onClick={() => setGuestManageDropDown(true)}
                            ref={dropManageGuestRef}
                          >
                            Manage Guests
                            {guestManageDropDown && (
                              <GuestListDropDown
                                contactList={contactList}
                                guestUUID={guestUUID}
                                setGuestUUID={setGuestUUID}
                                setGuests={setGuest}
                                guests={guest}
                                Manage={true}
                              />
                            )}
                          </button>
                        </>
                      )}
                      {guestUUID.length < 1 && eventGuestMsg ? (
                        <span className='error-field'>{eventGuestMsg} </span>
                      ) : null}
                    </div>
                  </div>
                  <div className='d-sm-flex taskTypeDiv descripionDiv '>
                    <h2 className='titleLabel col-3'>Description</h2>
                    <div className='d-flex align-items-center descriptionField w-100'>
                      <TextAreaField
                        name='description'
                        placeholder='Add description'
                        values={description}
                        onSetItem={onSet}
                      />
                      <img src={edit} alt='' className='editDesc' />
                    </div>
                  </div>
                  {descriptionError && (
                    <div className='errorDivEvent d-flex align-items-center'>
                      <span className='errorVal'>
                        <img className='erricon' src={warning} alt='' />
                        {descriptionError}
                      </span>
                    </div>
                  )}
                </div>
                <div className=' notesDiv d-flex justify-content-between w-100'>
                  <h2 className='titleLabel'>Notes</h2>
                  <button
                    type='button'
                    className='addNotesButton'
                    onClick={() => {
                      if (notes.length > 0) {
                        notes.slice(0, 1).pop()?.text.trim() && addsNotes();
                      } else {
                        addsNotes();
                      }
                    }}
                  >
                    <img src={plus} alt='' className='smallAddbutton' /> Add
                    note
                  </button>
                </div>
                <div>
                  {taskDetailsStatus === 'succeeded' &&
                    notes?.map((item: any, index: number) =>
                      makeEditNote && index === noteIndex ? (
                        <div
                          key={index}
                          className='d-flex align-items-center descriptionField  col'
                        >
                          <div className='textarea'>
                            <MarkdownEditor
                              placeholder='Add notes here'
                              note={item.text ? item.text : ''}
                              uuid={item.noteUUID ? item.noteUUID : ''}
                              multi={true}
                              index={index}
                              setNote={onSetNotesValue}
                            />
                          </div>
                        </div>
                      ) : (
                        <div className='d-flex align-items-center descriptionField col'>
                          <div className='notesTextarea'>
                            <div className='textContetnt d-flex justify-content-between'>
                              <div>
                                <span className='namefield'>
                                  {eventDetails?.Owner?.firstName +
                                    ' ' +
                                    eventDetails?.Owner?.lastName}
                                </span>{' '}
                                <span className='dotarea'>•</span>
                                <span>
                                  {moment(item?.updatedAt).format(
                                    'MMM D YYYY | h:mm A'
                                  )}
                                </span>
                              </div>
                              <button
                                className='vectorContainer'
                                onClick={() => {
                                  setNoteEditDropdown(!noteEditDropdown);
                                  setNoteIndex(index);
                                  setMakeEditNote(false);
                                }}
                                type='button'
                              >
                                <img src={vector} alt='vec' />
                              </button>
                            </div>

                            {item?.text && <NotesContainer text={item.text} />}
                          </div>
                          {noteEditDropdown && noteIndex === index && (
                            <div className='dropdown-div'>
                              <div
                                className='edit-div d-flex justify-content-between'
                                onClick={() => {
                                  setMakeEditNote(true);
                                  setNoteEditDropdown(false);
                                  setNoteIndex(index);
                                }}
                              >
                                Edit <img src={edit} alt='bin' />
                              </div>
                              <div
                                className='delete-div '
                                onClick={() => {
                                  deleteANote(item?.noteUUID);
                                  setNoteEditDropdown(false);
                                }}
                              >
                                Delete <img src={bin} alt='bin' />
                              </div>
                            </div>
                          )}
                        </div>
                      )
                    )}
                  {notesError ? (
                    <div className='errorVal'>
                      <img src={warning} alt='warning' className='warning' />
                      {notesError}
                    </div>
                  ) : null}
                </div>

                <div className=' DocumentsDiv'>
                  <h2 className='titleLabel'>Documents (optional)</h2>
                  <DocumentsField
                    file={file}
                    setFile={setFile}
                    setFileError={setFileError}
                    colorless={true}
                    disabled={file?.uuid && DoctypeUUID}
                  />
                  {file && (
                    <div className='documentDetails'>
                      <img src={document} alt='' />
                      <span className='docName'>{file?.name}</span>
                      <div className='itemsDiv d-flex justify-content-between align-items-center'>
                        <button
                          className='documentDropDown d-flex justify-content-between align-items-center'
                          onClick={() => setDropDown(!dropDown)}
                          type='button'
                        >
                          {docType ? docType : 'Document type'}
                          <img src={vector} alt='' />
                          {dropDown && (
                            <div className='documentTypeDrop'>
                              {' '}
                              {DocumentTypes &&
                                DocumentTypes?.map((item: any, key: number) => (
                                  <div
                                    key={key}
                                    className='listType d-flex align-items-center '
                                    onClick={() => {
                                      setDocType(item?.name);
                                      setDocTypeUUID(item.uuid);
                                      setDropDown(false);
                                    }}
                                  >
                                    <ul>{item?.name}</ul>
                                  </div>
                                ))}
                            </div>
                          )}
                        </button>
                        <img
                          className='binImage'
                          src={bin}
                          alt=''
                          onClick={() => {
                            setFile('');
                            setDocTypeUUID('');
                            setDocType('');
                            setReupload(true);
                          }}
                        />
                      </div>
                      {error && !DoctypeUUID && file && (
                        <div className='errorDIv d-flex align-items-center'>
                          <span className='errorVal'>
                            <img className='erricon' src={warning} alt='' />
                            {error}
                          </span>
                        </div>
                      )}
                    </div>
                  )}
                  {fileError && (
                    <div className='errorDIv d-flex align-items-center'>
                      <span className='errorVal'>
                        <img className='erricon' src={warning} alt='' />
                        {fileError}
                      </span>
                    </div>
                  )}
                </div>
              </div>
              {
                <div className='buttonsDiv d-flex align-items-center '>
                  <button
                    onClick={() => {
                      onCloseModal();
                    }}
                    className='cancel-button'
                    type='button'
                  >
                    Discard
                  </button>
                  <button
                    disabled={
                      eventDetails?.description === description.description &&
                      eventDetails?.EventType?.uuid === eventTypeUUID &&
                      eventDetails?.Documents[0]?.name === file?.name &&
                      sheduleDateTime.getDate() ===
                        new Date(eventDetails?.scheduledDate).getDate() &&
                      sheduleDateTime.getTime() ===
                        new Date(eventDetails?.scheduledDate).getTime() &&
                      dueDateTime.getDate() ===
                        new Date(eventDetails?.dueDate).getDate() &&
                      dueDateTime.getTime() ===
                        new Date(eventDetails?.dueDate).getTime() &&
                      eventDetails?.name === eventTitle?.eventTitle &&
                      eventDetails?.Documents[0]?.DocumentType?.uuid ===
                        DoctypeUUID &&
                      linkClicked &&
                      !notesError
                    }
                    type='submit'
                    className='saveButton'
                  >
                    Save
                    <img className='tick' src={tick} alt='tick-sign' />
                  </button>
                </div>
              }
            </form>
          </div>
        </Modal>
      </StyledAddModal>
    </>
  );
};

export default EventDetailsModal;
