import React from 'react';
import { useState, useRef } from 'react';
import { useSelector } from 'react-redux';
import Modal from '../Modal';
import { StyledMobileTabSidebar } from './styles';
import cross from '../../assets/logo/blackcross.svg';
import logo from '../../assets/logo/logo.svg';
import myteam from '../../assets/logo/myteam.svg';
import TaskTab from '../../assets/logo/TaskTab.svg';
import mybook from '../../assets/logo/Mybook.svg';
import DashboardMob from '../../assets/logo/dashboard.svg';
import Link from '../Link';
import { selectUser } from '../../store/user/userSlice';
import manage from '../../assets/logo/manage.svg';
import vector from '../../assets/logo/dropdown.svg';
import { useOutsideClick } from '../../hooks/useOutsideClick';
import contact from '../../assets/logo/contact.svg';
import account from '../../assets/logo/account.svg';
import policy from '../../assets/logo/policy.svg';
import settings from '../../assets/logo/settings.svg';

function MobileTabSidebarDrawer({
  show,
  closeModal,
}: {
  show: string;
  closeModal: Function;
}) {
  const [showDropdown, setShowDropdown] = useState(false);
  const user = useSelector(selectUser);

  const dropDownRef = useRef(null);
  useOutsideClick(dropDownRef, setShowDropdown);

  const onCloseModal = () => {
    closeModal('closing');
    setTimeout(() => {
      closeModal('closed');
    }, 300);
  };
  return (
    <StyledMobileTabSidebar>
      <Modal open={show} align='left'>
        <div className='tab-div'>
          <div>
            <button
              className='cross-button'
              onClick={() => {
                onCloseModal();
              }}
            >
              <img src={cross} alt='cross' />
            </button>
          </div>
          <div className='logo-div'>
            <img src={logo} alt='' />
          </div>
          <Link to='/dashboard'>
            <div className='content-link-div'>
              <img src={DashboardMob} alt='dashboard' className='img' />
              Dashboard
            </div>
          </Link>

          {user?.UserRole.name === 'ADMIN' ? (
            <>
              <Link to='/admin'>
                <div className='content-link-div'>
                  <img src={manage} alt='logo' className='img' />
                  Management
                </div>
              </Link>
            </>
          ) : (
            <>
              <Link to='/activities'>
                <div className='content-link-div'>
                  <img src={TaskTab} alt='Task-logo' className='img' />
                  Tasks
                </div>
              </Link>
              <Link to='/pipeline'>
                <div className='content-link-div pipeline'>
                  <img src={manage} alt='logo' className='img' />
                  Sales pipeline
                </div>
              </Link>
              <div className='mybook-div'>
                <Link to='/mybook'>
                  <div
                    className='vector-nav'
                    onClick={(e) => {
                      setShowDropdown(!showDropdown);
                      e.preventDefault();
                    }}
                    ref={dropDownRef}
                  >
                    <img src={mybook} alt='logo' className='img' />
                    My book
                    <img className='vector-img' src={vector} alt='vector' />
                    {showDropdown && (
                      <div className='drop-Div'>
                        <div className='contacts'>
                          <Link to='/mybook/contacts'>
                            <div
                              className='mybook-link-div'
                              onClick={() => {
                                setShowDropdown(false);
                                onCloseModal();
                              }}
                            >
                              <img
                                src={contact}
                                alt='logo'
                                className='mybook-img'
                              />
                              Contacts
                            </div>
                          </Link>
                        </div>
                        <div className='contacts'>
                          <Link to='/mybook/accounts'>
                            <div
                              className='mybook-link-div'
                              onClick={() => {
                                setShowDropdown(false);
                                onCloseModal();
                              }}
                            >
                              <img
                                src={account}
                                alt='logo'
                                className='mybook-img'
                              />
                              Accounts
                            </div>
                          </Link>
                        </div>
                        <div className='contacts'>
                          <Link to='/mybook/policies'>
                            <div
                              className='mybook-link-div'
                              onClick={() => {
                                setShowDropdown(false);
                                onCloseModal();
                              }}
                            >
                              <img
                                src={policy}
                                alt='logo'
                                className='mybook-img'
                              />
                              Policies
                            </div>
                          </Link>
                        </div>
                        <div className='contacts'>
                          <Link to='/mybook/leads'>
                            <div
                              className='mybook-link-div'
                              onClick={() => {
                                setShowDropdown(false);
                                onCloseModal();
                              }}
                            >
                              <img
                                src={contact}
                                alt='logo'
                                className='mybook-img'
                              />
                              Leads
                            </div>
                          </Link>
                        </div>
                        <div className='contacts'>
                          <Link to='/mybook/opportunities'>
                            <div
                              className='mybook-link-div'
                              onClick={() => {
                                setShowDropdown(false);
                                onCloseModal();
                              }}
                            >
                              <img
                                src={policy}
                                alt='logo'
                                className='mybook-img'
                              />
                              Opportunities
                            </div>
                          </Link>
                        </div>
                      </div>
                    )}
                  </div>
                </Link>
              </div>
              {user?.UserRole.name === 'PRINCIPAL_AGENT' ? (
                <div>
                  <Link to='/myteam'>
                    <div className='content-link-div pipeline'>
                      <img src={myteam} alt='logo' className='img' />
                      My Team
                    </div>
                  </Link>
                  <Link to='/settings'>
                    <div className='content-link-div pipeline'>
                      <img src={settings} alt='logo' className='img' />
                      Settings
                    </div>
                  </Link>
                </div>
              ) : null}
            </>
          )}
        </div>
      </Modal>
    </StyledMobileTabSidebar>
  );
}

export default MobileTabSidebarDrawer;
