import axios from './axios';
import { IContact } from '../interfaces/Contact';

const addContact = (data: IContact, successCB: Function, errorCB: Function) => {
  return axios.post('/contact', data).then(
    (res) => {
      successCB(res);
      return res;
    },
    (error) => {
      errorCB(error?.response?.data.error);
      return error.res;
    }
  );
};

const addAssosiatedContact = (
  data: IContact,
  successCB: Function,
  errorCB: Function
) => {
  return axios.post('/contact/assosiatedContact', data).then(
    (res) => {
      successCB(res);
      return res;
    },
    (error) => {
      errorCB(error?.response?.data.error);
      return error.res;
    }
  );
};

const fetchContacts = (
  sortBy: string,
  orderBy: string,
  searchBy: string,
  limit: number,
  offset: number,
  minimal?: string,
  successCB?: Function,
  type?: string,
  accountUUID?: string,
  opportunityUUID?: string,
  jurisdictionUUID?: string,
  producerUUID?: string
) => {
  return axios
    .get('/contact', {
      params: {
        sortBy,
        searchBy,
        orderBy,
        minimal,
        type,
        limit,
        offset,
        accountUUID,
        opportunityUUID,
        jurisdictionUUID,
        producerUUID,
      },
    })
    .then((res) => {
      if (successCB) {
        successCB(res.data);
      }
      return res;
    });
};

const updateContactDetails = (
  data: IContact,
  uuid: string,
  successCB: Function,
  errorCB: Function
) => {
  return axios.patch(`/contact/${uuid}`, data).then(
    (res) => {
      successCB(res);
      return res;
    },
    (error) => {
      errorCB(error?.response?.data.error);
      return error.res;
    }
  );
};

const fetchContact = (uuid: string, successCB: Function) => {
  return axios.get(`/contact/${uuid}`).then((res) => {
    successCB(res);
    return res;
  });
};

const fetchContactOpportunity = (
  contactUUID: string,
  offset: number,
  getQuote?: string,
  pipelineUUID?: string,
  opportunityStatusUUID?: string,
  productCategoryUUID?: string,
  accountUUID?: string,
  producerUUID?: string,
  productUUID?: string,
  customProductUUID?: string
) => {
  return axios
    .get(`/opportunities/`, {
      params: {
        contactUUID,
        limit: 4,
        offset,
        getQuote,
        pipelineUUID,
        opportunityStatusUUID,
        productCategoryUUID,
        accountUUID,
        producerUUID,
        productUUID,
        customProductUUID,
      },
    })
    .then((res) => {
      return res;
    });
};

const fetchContactPolicies = (contactUUID: string, offset: number) => {
  return axios
    .get(`/policy/`, { params: { contactUUID, offset, limit: 4 } })
    .then((res) => {
      return res;
    });
};

const fetchContactAccount = (
  contactUUID: string,
  accountStatusUUID?: string,
  hasPolicy?: string,
  producerUUID?: string,
  type?: string,
  limit?: number,
  offset?: number
) => {
  return axios
    .get(`/account/`, {
      params: {
        contactUUID,
        accountStatusUUID,
        hasPolicy,
        producerUUID,
        type,
        limit,
        offset,
      },
    })
    .then((res) => {
      return res;
    });
};

const ContactDetailsCount = (contactUUID: string) => {
  return axios
    .get(`/user/mybook/count`, { params: { contactUUID } })
    .then((res) => {
      return res;
    });
};

const deleteContact = (uuid: String) => {
  return axios.delete(`/contact/${uuid}`);
};

const validatePhoneNumber = (
  phoneNumber: string,
  successCB: Function,
  errorCB: Function
) => {
  return axios
    .get('/contact/validate/phoneNumber', {
      params: {
        phoneNumber,
      },
    })
    .then(
      (res) => {
        successCB(res);
        return res;
      },
      (error) => {
        errorCB(error?.response?.data.error);
        return error.res;
      }
    );
};
export {
  addContact,
  fetchContacts,
  updateContactDetails,
  fetchContact,
  fetchContactOpportunity,
  fetchContactAccount,
  ContactDetailsCount,
  fetchContactPolicies,
  deleteContact,
  validatePhoneNumber,
  addAssosiatedContact,
};
