import { useEffect, useState } from 'react';
import { getXDateReasons } from '../../../api/reason';
import SelectField from '../../../components/CustomSelectField';
import DateField from '../../../components/CustomDateField';
import { IXDateReason } from '../../../interfaces/Lead';
import { StyledModal } from '../elements/styles';
import cross from '../../../assets/logo/cross.svg';
import arrow from '../../../assets/logo/right-arrow.svg';
import warning from '../../../assets/logo/warning.svg';

interface IValue {
  reason: string;
  Xdate: string;
}

const XDateModal = ({
  showModal,
  onCloseModal,
  action,

  onLoseOpp,
}: {
  showModal: boolean;
  onCloseModal: Function;
  action: string;
  setAction: Function;
  onLoseOpp: Function;
}) => {
  const [values, setValues] = useState<IValue>({ reason: '', Xdate: '' });
  const [errorMsge, setErrorMsge] = useState(false);
  const [errorReason, setErrorReason] = useState(false);

  const [reasons, setReasons] = useState<IXDateReason[]>([]);
  const onSetItem = (name: string, value: string) => {
    const newValue = { ...values };
    name === 'Xdate' ? (newValue.Xdate = value) : (newValue.reason = value);
    setValues(newValue);
    if (name === 'Xdate' && newValue?.Xdate) {
      setErrorMsge(false);
    }
    if (name === 'reason' && newValue?.reason) {
      setErrorReason(false);
    }
  };

  useEffect(() => {
    getXDateReasons((data: IXDateReason[]) => {
      setReasons(data);
    }); // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <StyledModal show={showModal} centered onHide={() => onCloseModal(false)}>
      <img
        alt=''
        src={cross}
        className='closeButton'
        onClick={() => onCloseModal(false)}
      />
      <h2>Move back by X-Date</h2>
      <div className='sub-heading'>Please provide a reason.</div>

      <SelectField
        options={reasons.map((item) => {
          return { value: item.name, label: item.name };
        })}
        placeholder={
          action ? 'Reason to move back to pipeline' : 'Reason for losing'
        }
        name='reason'
        values={values}
        onSetItem={onSetItem}
      />
      {errorReason ? (
        <div className='errorVal d-flex align-items-center mt-1 '>
          <img src={warning} alt='warning' className='warning me-2' />
          Please select a Reason
        </div>
      ) : null}
      <div className='Xdate-fields'>
        <DateField
          name='Xdate'
          placeholder='Date to move back to pipeline'
          values={values}
          onSetItem={onSetItem}
          minDate={new Date().setDate(new Date().getDate() + 1)}
          xdate={true}
        />
        {errorMsge ? (
          <div className='errorVal d-flex align-items-center mt-1 '>
            <img src={warning} alt='warning' className='warning me-2' />
            Please select a Date
          </div>
        ) : null}
      </div>

      <div>
        <textarea
          className='textarea'
          placeholder='Add notes here'
          autoComplete='off'
        />
      </div>

      <div className='buttonsDiv d-flex align-items-center justify-content-end'>
        <button
          onClick={() => {
            onCloseModal();
          }}
          className='secondaryButton'
          type='button'
        >
          Cancel
        </button>
        <button
          className='primaryButton'
          onClick={() => {
            if (!values?.Xdate || !values.reason) {
              if (!values?.Xdate) setErrorMsge(true);
              if (!values?.reason) setErrorReason(true);
            } else {
              onLoseOpp(values.reason, values.Xdate);
            }
          }}
        >
          Submit <img className='arrowl' src={arrow} alt='arrow-function' />
        </button>
      </div>
    </StyledModal>
  );
};

export default XDateModal;
