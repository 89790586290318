import { useSelector } from 'react-redux';
import sort from '../../../../assets/logo/sort.svg';
import sortup from '../../../../assets/logo/sortup.svg';
import sortdown from '../../../../assets/logo/sortdown.svg';
import ContactCard from './ContactCard';
import { IContact } from '../../../../interfaces/Contact';
import { ContactsSliceState } from '../../../../store/contacts/contactsSlice';
import { TableDiv } from '../../../../styles/TableDiv';
import EmptyResults from '../../components/Empty';
import LoadingSkeleton from '../../components/Loading';

const ContactsTable = ({
  contacts,
  setSortBy,
  setOrderBy,
  sortBy,
  orderBy,
}: {
  contacts: IContact[];
  setSortBy: Function;
  setOrderBy: Function;
  sortBy: string;
  orderBy: string;
}) => {
  const handleSort = (sort: string) => {
    if (sort === sortBy) {
      if (orderBy === 'asc') {
        setOrderBy('desc');
      } else {
        setOrderBy('asc');
      }
    } else {
      setSortBy(sort);
    }
  };

  const loadingStatus = useSelector(
    (state: { contacts: ContactsSliceState }) => state.contacts.status
  );

  return (
    <TableDiv>
      <thead>
        <tr>
          <th className='agencyNameHead'>
            <div className='d-flex'>
              <div className='w-75 d-flex justify-content-between'>
                Contact name
                <img
                  className='sort'
                  src={
                    sortBy !== 'firstName'
                      ? sort
                      : orderBy === 'asc'
                      ? sortdown
                      : sortup
                  }
                  alt='sort'
                  onClick={() => handleSort('firstName')}
                />
              </div>
            </div>
          </th>
          <th className='statusContainerHead'>Type</th>
          <th className='dateFieldHead'>
            <div className=' d-flex justify-content-between '>
              Created date
              <img
                className='sort'
                src={
                  sortBy !== 'createdAt'
                    ? sort
                    : orderBy === 'asc'
                    ? sortdown
                    : sortup
                }
                alt='sort'
                onClick={() => handleSort('createdAt')}
              />
            </div>
          </th>
          <th className='opportunityFieldHead'>Email</th>
          <th className='leadsPhoneFieldHead d-none d-xl-table-cell'>
            Phone number
          </th>
          <th className='actionFieldHead'>Actions</th>
        </tr>
      </thead>
      <tbody>
        {loadingStatus === 'loading' ? (
          <LoadingSkeleton columns={6} />
        ) : loadingStatus === 'succeeded' && contacts.length > 0 ? (
          contacts.map((el) => <ContactCard contact={el} key={el.uuid} />)
        ) : (
          <tr className='empty-div'>
            <td colSpan={5} className='d-xl-none'>
              <EmptyResults name='contact' />
            </td>
            <td colSpan={7} className='d-none d-xl-table-cell'>
              <EmptyResults name='contact' />
            </td>
          </tr>
        )}
      </tbody>
    </TableDiv>
  );
};

export default ContactsTable;
