import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import {
  getLocations,
  addLocation,
  fetchLocation,
  UpdateLocation,
  deleteLocation,
  getPrimaryLocations,
} from '../../api/locations';
import { ILocation } from '../../interfaces/Location';

export type LocationSliceState = {
  status: string;
  addStattus: string;
  locations: ILocation[];
  location?: ILocation;
  isLoading: boolean;
  count: number;
};
const initialState: LocationSliceState = {
  status: 'idle',
  addStattus: 'idle',
  isLoading: false,
  count: 0,
  locations: [],
};

export const getAllLocations = createAsyncThunk(
  'location/getAllLocations',
  async ({
    sortBy,
    orderBy,
    searchBy,
    limit,
    offset,
    minimal,
    agencyUUID,
    successCB,
  }: {
    sortBy?: string;
    orderBy?: string;
    searchBy?: string;
    limit?: number;
    offset?: number;
    minimal?: string;
    agencyUUID?: string;
    successCB: Function;
  }) => {
    const response = await getLocations(
      sortBy,
      orderBy,
      searchBy,
      limit,
      offset,
      minimal,
      agencyUUID
    );
    successCB(response);
    return response.data;
  }
);
export const PrimaryLocations = createAsyncThunk(
  'location/PrimaryLocations',
  async ({
    agencyUUID,
    successCB,
  }: {
    agencyUUID?: string;
    successCB: Function;
  }) => {
    const response = await getPrimaryLocations(agencyUUID);
    successCB(response);
    return response.data;
  }
);

export const getLocation = createAsyncThunk(
  'location/getLocation',
  async ({
    uuid,
    agencyUUID,
    successCB,
  }: {
    uuid: string;
    agencyUUID?: string;
    successCB: Function;
  }) => {
    const response = await fetchLocation(uuid, agencyUUID);
    successCB(response.data);
    return response.data;
  }
);

export const createLocation = createAsyncThunk(
  'location/createLocation',
  async ({
    data,
    successCB,
    errorCB,
  }: {
    data: any;
    successCB: Function;
    errorCB: Function;
  }) => {
    const response = await addLocation(data, successCB, errorCB);
    return response.data;
  }
);

export const updateLocationDetails = createAsyncThunk(
  'location/updateLocationDetails',
  async ({
    data,
    successCB,
    errorCB,
    uuid,
  }: {
    data: ILocation;
    successCB: Function;
    errorCB: Function;
    uuid: string;
  }) => {
    const response = await UpdateLocation(data, uuid, successCB, errorCB);
    successCB(response.data);
    return response.data;
  }
);

export const deleteALocation = createAsyncThunk(
  'location/deleteALocation',
  async ({
    uuid,
    locationUUID,
    successCB,
    agencyUUID,
    agencyUpdate,
  }: {
    uuid: string;
    locationUUID: string;
    agencyUUID?: string;
    successCB: Function;
    agencyUpdate?: boolean;
  }) => {
    const response = await deleteLocation(
      uuid,
      locationUUID,
      agencyUUID,
      agencyUpdate
    );
    successCB(response.data);
    return response.data;
  }
);

const locationSlice = createSlice({
  name: 'locations',
  initialState,
  reducers: {
    updateStatus: (state) => {
      state.status = 'idle';
      state.isLoading = false;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(getAllLocations.pending, (state, action) => {
        state.status = 'loading';
      })
      .addCase(getAllLocations.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.locations = action.payload.location;
        state.count = action.payload.count;
      })
      .addCase(getAllLocations.rejected, (state, action) => {
        state.status = 'failed';
      })
      .addCase(createLocation.pending, (state, action) => {
        state.addStattus = 'loading';
      })
      .addCase(createLocation.fulfilled, (state, action) => {
        state.addStattus = 'succeeded';
        state.isLoading = false;
        const primaryIndex = state.locations.findIndex(
          (item: any) => item.isPrimary === true
        );
        state.locations[primaryIndex] = action.payload.primaryLocation;
        state.locations.unshift(action.payload.location);
      })
      .addCase(createLocation.rejected, (state, action) => {
        state.addStattus = 'failed';
      })
      .addCase(getLocation.pending, (state, action) => {
        state.status = 'loading';
      })
      .addCase(getLocation.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.location = action.payload;
      })
      .addCase(getLocation.rejected, (state, action) => {
        state.status = 'failed';
      })
      .addCase(updateLocationDetails.fulfilled, (state, action) => {
        state.addStattus = 'succeeded';
        state.isLoading = false;
        const index = state.locations.findIndex(
          (item: any) => item.uuid === action.payload.location.uuid
        );
        const primaryIndex = state.locations.findIndex(
          (item: any) => item.isPrimary === true
        );
        if (
          action.payload.location.uuid === action.payload.primaryLocation.uuid
        ) {
          state.locations[index] = action.payload.location;
        } else {
          state.locations[index] = action.payload.location;
          state.locations[primaryIndex] = action.payload.primaryLocation;
        }
      })
      .addCase(deleteALocation.fulfilled, (state, action) => {
        state.addStattus = 'succeeded';
        const index = state.locations.findIndex(
          (item: any) => item?.uuid === action.payload.uuid
        );
        state.locations.splice(index, 1);
      })
      .addCase(PrimaryLocations.fulfilled, (state, action) => {
        state.addStattus = 'succeeded';
        state.location = action.payload;
      });
  },
});

export const { updateStatus } = locationSlice.actions;

export default locationSlice.reducer;

export const selectAllLocations = (state: { locations: LocationSliceState }) =>
  state.locations?.locations;
