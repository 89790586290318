import { useState, useEffect, useRef } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import Skeleton from 'react-loading-skeleton';
import { useDispatch, useSelector } from 'react-redux';
import useDebounce from '../../../../hooks/useDebounce';
import { AppDispatch } from '../../../../store';
import {
  getCarriers,
  CarrierSliceState,
  selectAllCarriers,
  onUpdateCarrier,
  clearCarrier,
  onUpdateCarrierData,
  onDeleteCarrier,
} from '../../../../store/carriers/carriersSlice';
import CarrierMobileCard from './CarriersMobileCard';
import EmptyResults from '../../../../pages/MyBook/components/Empty';
import PaginationComponent from '../../../../components/PaginationComponent';
import MobileTabNav from '../../../../components/MobileTabNav';
import Table from '../../../../components/Table';
import { ICarrier } from '../../../../interfaces/Carriers';
import { IAgency } from '../../../../interfaces/Agency';
import {
  FilterContainer,
  MyBookDiv,
} from '../../../../pages/MyBook/elements/styles';
import sort from '../../../../assets/logo/sort.svg';
import sortup from '../../../../assets/logo/sortup.svg';
import sortdown from '../../../../assets/logo/sortdown.svg';
import vector from '../../../../assets/logo/dropdown.svg';
import filter from '../../../../assets/logo/filter-mobile.svg';
import arrow from '../../../../assets/logo/right-arrow.svg';
import search from '../../../../assets/logo/search.svg';
import filterIcon from '../../../../assets/logo/filter.svg';
import closeIcon from '../../../../assets/logo/cross.svg';
import plus from '../../../../assets/logo/blackplus.svg';
import CarrierModal from './CarrierModal';
import { useOutsideClick } from '../../../../hooks/useOutsideClick';
import edit from '../../../../assets/logo/edit.svg';
import bin from '../../../../assets/logo/bin.svg';
import MobileTab from './MobileTab';
import EditCarrierModal from './EditCarrierModal';
import DeleteModal from '../../../../components/DeleteModal';
import ErrorCard from '../../../Pipeline/components/ErrorCard';
import SelectField from '../../../../components/CustomSelectField';
import CarrierFilter from './CarrierFilter';
import { TableDiv } from '../../../../styles/TableDiv';
import { CardDiv } from '../../../../styles/CardDiv';
import ActivateModal from './ActivateModal';
import { SuccessAlign } from '../../../../styles/SuccessAlign';
import SuccessCard from '../../../../components/SuccessCard';
import cross from '../../../../assets/logo/cross.svg';
import tick from '../../../../assets/logo/tickCircle.svg';
import minus from '../../../../assets/logo/minus-circle.svg';
import { CarriersMobileCard } from '../styles';
import apple from '../../../../assets/logo/apple.svg';
import android from '../../../../assets/logo/android.svg';
import self from '../../../../assets/logo/self.svg';
import LocalStorageService from '../../../../utils/LocalStorageService';

const CustomCarriers = () => {
  const dispatch = useDispatch<AppDispatch>();
  const loadingStatus = useSelector(
    (state: { carriers: CarrierSliceState }) => state.carriers?.status
  );
  const carrierCount = useSelector(
    (state: { carriers: CarrierSliceState }) => state.carriers?.count
  );

  const [searchBy, setSearchBy] = useState('');
  const debouncedSearchTerm = useDebounce(searchBy, 500);
  const { uuid } = useParams();
  const navigate = useNavigate();
  const [sortBy, setSortBy] = useState('name');
  const [orderBy, setOrderBy] = useState('desc');
  const [limit, setLimit] = useState(10);
  const [page, setPage] = useState(0);
  useEffect(() => {
    dispatch(clearCarrier());
    dispatch(
      getCarriers({
        sortBy,
        searchBy: debouncedSearchTerm,
        orderBy,
        minimal: '',
        agencyUUID: uuid,
        successCB: () => {},
        limit,
        offset: page * limit,
        type: 'CUSTOM_CARRIER',
      })
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, sortBy, orderBy, debouncedSearchTerm, page, limit]);

  const UpdatesCarriers = (carrier: ICarrier) => {
    if (uuid) {
      dispatch(
        onUpdateCarrier({
          uuid: carrier.uuid,
          status:
            carrier.Agencies.findIndex((item) => item.uuid === uuid) >= 0
              ? false
              : true,
          agencyUUID: uuid,
        })
      );
    }
  };

  const handleSort = (sort: string) => {
    if (sort === sortBy) {
      if (orderBy === 'asc') {
        setOrderBy('desc');
      } else {
        setOrderBy('asc');
      }
    } else {
      setSortBy(sort);
    }
  };
  const searchRef = useRef(null);

  const [isSearchClicked, setIsSearchClicked] = useState(false);
  const [addCarrier, setAddCarrier] = useState('closed');

  const onCloseModal = () => {
    setAddCarrier('closing');
    setTimeout(() => {
      setAddCarrier('closed');
    }, 300);
  };

  const onCloseEditModal = () => {
    setEditCarrier('closing');
    setTimeout(() => {
      setEditCarrier('closed');
    }, 300);
  };

  const carriers = useSelector(selectAllCarriers);

  const [UUID, setUUID] = useState('');
  const [editCarrier, setEditCarrier] = useState('closed');

  const [showSuccessActivate, setShowSuccessActivate] = useState(false);

  const activateAction = () => {
    let actionType = 'ACTIVATE';
    if (isActive) {
      actionType = 'DEACTIVATE';
    }
    dispatch(
      onUpdateCarrierData({
        uuid: UUID,
        data: { actionType, type: 'CUSTOM_CARRIER', agencyUUID: uuid || '' },
        successCB: () => {
          setShowActivate('closed');
          setTimeout(() => {
            setShowSuccessActivate(true);
          }, 100);
          setTimeout(() => {
            setShowSuccessActivate(false);
          }, 2000);
        },
      })
    );
  };

  const setActivateAction = (
    uuid: string,
    isActiveState: boolean,
    name: string
  ) => {
    setUUID(uuid);
    setIsActive(isActiveState);
    setName(name);
    setShowActivate('open');
  };

  const [carrierUUID, setCarrierUUID] = useState<any>({ carrierUUID: '' });

  const onSetItem = (name: string, value: string) => {
    setCarrierUUID({ carrierUUID: value });
  };

  let carrierName = {
    deleteCarrier: UUID,
  };

  const [name, setName] = useState('');
  const [showSuccess, setShowSuccess] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState('closed');

  const setDelete = (uuid: string, name: string) => {
    setUUID(uuid);
    setName(name);
  };

  const onDelete = () => {
    dispatch(
      onDeleteCarrier({
        uuid: UUID,
        successCB: () => {
          setShowDeleteModal('closing');
          setTimeout(() => {
            setShowSuccess(true);
          }, 100);
          setTimeout(() => {
            setShowDeleteModal('closed');
            setShowSuccess(false);
          }, 2000);
        },
        data: {
          type: 'CUSTOM_CARRIER',
          agencyUUID: uuid,
          destinationUUID: carrierUUID.carrierUUID,
        },
      })
    );
  };
  const [carrier, setCarrier] = useState<any>();
  const [showFilter, setShowFilter] = useState('closed');

  const user = LocalStorageService.getUser();

  const [selectedPolicyTypes, setSelectedPolicyTypes] = useState<any>([]);
  const [status, setStatus] = useState<any>();

  const filterCustomCarrier = () => {
    dispatch(clearCarrier());
    dispatch(
      getCarriers({
        sortBy,
        searchBy: debouncedSearchTerm,
        orderBy,
        minimal: '',
        agencyUUID: uuid,
        successCB: () => {},
        limit,
        offset: page * limit,
        type: 'CUSTOM_CARRIER',
        isActive: status?.value,
        customProductUUIDs: selectedPolicyTypes?.map((item: any) => item.value),
      })
    );
  };

  const [showActivate, setShowActivate] = useState('closed');
  const [isActive, setIsActive] = useState(false);

  return (
    <>
      {showSuccess && (
        <ErrorCard message={`<b>${name}</b> is <b> deleted</b> `} show='open' />
      )}
      <SuccessAlign>
        <div
          className={`success-container ${
            showSuccessActivate && 'open-drawer'
          } `}
        >
          <SuccessCard
            title={`Success ${
              !isActive ? 'Activating' : 'De-activating'
            } carrier`}
            message={`<b>${name} carrier has been ${
              !isActive ? 'Activated' : 'De-activated'
            }<b>`}
          />
          <button
            onClick={() => {
              setShowSuccessActivate(false);
            }}
            className='crossbutton'
          >
            <img src={cross} className='cross' alt='cross-icon' />
          </button>
        </div>
      </SuccessAlign>
      <MyBookDiv>
        <div className='mybook-content carrier-content'>
          <div className='d-flex justify-content-between'>
            <button
              className='addCarrierButton d-none d-sm-flex'
              onClick={() => setAddCarrier('open')}
            >
              Add single carrier
              <img src={plus} className='' alt='cross-icon' />
            </button>
            <div
              className='book-filter d-none d-sm-flex ms-auto'
              ref={searchRef}
            >
              {isSearchClicked || searchBy ? (
                <div className='book-search d-flex align-items-center'>
                  <img src={search} alt='' className='search-button' />
                  <input
                    onChange={(e) => {
                      setPage(0);
                      setSearchBy(e.target.value);
                    }}
                    autoFocus
                    value={searchBy}
                  />
                  <img
                    src={closeIcon}
                    alt=''
                    className='close-button'
                    onClick={() => {
                      setPage(0);
                      setSearchBy('');
                      setIsSearchClicked(false);
                    }}
                  />
                </div>
              ) : (
                <div
                  className='book-shrinked'
                  onClick={() => setIsSearchClicked(true)}
                >
                  <img src={search} alt='' />
                </div>
              )}

              <span className='divider d-none d-sm-block' />
              <div
                className='book-shrinked d-none d-sm-flex'
                onClick={() => setShowFilter('open')}
              >
                <img src={filterIcon} alt='' />
                {(status?.value ? 1 : 0) + selectedPolicyTypes.length > 0 && (
                  <span className='filter-counter'>
                    {(status?.value ? 1 : 0) + selectedPolicyTypes.length}
                  </span>
                )}
              </div>
              <span
                className={`divider d-sm-none ${isSearchClicked && 'd-none'}`}
              />
              <div
                className={`book-shrinked d-sm-none ${
                  isSearchClicked && 'd-none'
                }`}
                onClick={() => setShowFilter('open')}
              >
                <img src={filter} alt='' />
                {(status?.value ? 1 : 0) + selectedPolicyTypes.length > 0 && (
                  <span className='filter-counter'>
                    {(status?.value ? 1 : 0) + selectedPolicyTypes.length}
                  </span>
                )}
              </div>
            </div>
          </div>
          <div className='HeadDiv d-sm-none carrierHead-div'>
            <h2
              className='HeadNavigator'
              onClick={() =>
                navigate(
                  user.UserRole.name === 'ADMIN'
                    ? `/admin/agency/${uuid}`
                    : `/settings`
                )
              }
            >
              <img src={arrow} className='leftArrow back-button-head' alt='' />
              Carrier Settings
            </h2>
            <button
              onClick={() => setAddCarrier('open')}
              className='addButton d-sm-none'
            >
              <img src={plus} alt='add' />
            </button>
            <MobileTab uuid={uuid || ''} dropText='Custom Carriers' />

            <div className='d-flex justify-content-between searchBackground'>
              <div className='d-flex searchField '>
                <img className='mx-2' src={search} alt='' />
                <input
                  className='inputSearchField '
                  type='search'
                  placeholder='Search carriers'
                  onChange={(e) => {
                    setPage(0);
                    setSearchBy(e.target.value);
                  }}
                  value={searchBy}
                />
              </div>
              <div
                className='d-flex searchField filterField position-relative'
                onClick={() => setShowFilter('open')}
              >
                <img src={filter} alt='' />
                {(status?.value ? 1 : 0) + selectedPolicyTypes.length > 0 && (
                  <span className='filter-counter'>
                    {(status?.value ? 1 : 0) + selectedPolicyTypes.length}
                  </span>
                )}
              </div>
            </div>
          </div>
          <div className=' d-sm-none mobilecardHeadDiv'>
            {loadingStatus === 'loading' ? (
              [1, 2, 3].map((item) => <Skeleton height={138} key={item} />)
            ) : loadingStatus === 'succeeded' &&
              carrierCount > 0 &&
              carriers.length > 0 ? (
              <>
                {carriers.map((el: any) => (
                  <CarriersMobileCard>
                    <div className='d-flex justify-content-between'>
                      <span className='CarrierName'>
                        {el.name ? el.name : '-'}
                      </span>

                      <div
                        className={`d-flex align-items-baseline
                                      ${
                                        el.isActive
                                          ? 'activeStage'
                                          : 'inactiveStage'
                                      }`}
                      >
                        <div
                          className={`dot  mx-2 ${
                            el.isActive ? 'avtive' : 'inactive'
                          }`}
                        ></div>
                        {el.isActive ? 'Active' : 'Inactive'}
                      </div>
                    </div>
                    <div className='d-flex link-div-container'>
                      <div>
                        <img src={apple} alt='' />{' '}
                        <span className='custom-link'>{el.iPhoneAppLink}</span>
                      </div>
                      <div>
                        <img src={android} alt='' />{' '}
                        <span className='custom-link'>{el.androidAppLink}</span>
                      </div>
                    </div>
                    <div className='self-link-div'>
                      <img src={self} alt='' />{' '}
                      <span className='custom-link'>{el.selfServiceLink}</span>
                    </div>
                    <div className='d-flex align-items-center h-100 justify-content-between'>
                      <div className='stateandnames '>
                        Policies :{' '}
                        <b>
                          {el.CustomProduct?.map((item: any, index: number) => {
                            if (index < 3) {
                              return `${item.name}${
                                index !== el.CustomProduct?.length - 1
                                  ? ', '
                                  : ''
                              }`;
                            } else if (index === el.CustomProduct?.length - 1) {
                              return ` and ${
                                el?.CustomProduct?.length - 3
                              } others`;
                            }
                          })}
                          {el.CustomProduct?.length < 1 && '-'}
                        </b>
                      </div>
                      <div className='d-flex align-items-center mobile-actions h-100'>
                        <ActionsDropdown
                          uuid={el.uuid}
                          setShowEditModal={setEditCarrier}
                          setUUID={setUUID}
                          activateAction={setActivateAction}
                          isActive={el.isActive}
                          onDelete={setDelete}
                          setShowDeleteModal={setShowDeleteModal}
                          name={el.name}
                          setCarrier={setCarrier}
                          carrier={el}
                        />
                      </div>
                    </div>
                  </CarriersMobileCard>
                ))}
                <PaginationComponent
                  limit={limit}
                  setLimit={setLimit}
                  page={page}
                  setPage={setPage}
                  count={carrierCount}
                />
              </>
            ) : (
              <EmptyResults name='Carriers' />
            )}
            <MobileTabNav />
          </div>

          <div className='d-none d-sm-block'>
            {loadingStatus === 'loading' || carrierCount === 0 ? (
              <TableDiv>
                <thead>
                  <tr>
                    <th className='agencyNameHead'>
                      <div className='d-flex'>
                        <div className='w-75 d-flex justify-content-between'>
                          Carrier
                          <img
                            className='sort'
                            src={
                              sortBy !== 'name'
                                ? sort
                                : orderBy === 'asc'
                                ? sortdown
                                : sortup
                            }
                            alt='sort'
                            onClick={() => handleSort('name')}
                          />
                        </div>
                      </div>
                    </th>
                    <th className='statusContainerHead'>iPhone app link</th>
                    <th className='statusContainerHead'>Android app link</th>
                    <th className='statusContainerHead'>Self-service link</th>
                    <th className='statusContainerHead'>Policies offered</th>

                    <th className='leadActionsFieldHead'>Status</th>
                    <th className='leadActionsFieldHead'>Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {loadingStatus === 'loading' ? (
                    <>
                      {[1, 2, 3].map((index) => (
                        <CardDiv key={index}>
                          {[1, 2, 3, 4, 5, 6, 7].map((item) => (
                            <td key={item}>
                              <Skeleton height={24} />
                            </td>
                          ))}
                        </CardDiv>
                      ))}
                    </>
                  ) : (
                    <tr className='empty-div'>
                      <td colSpan={5} className='d-xl-none'>
                        <EmptyResults name='System defined carriers' />
                      </td>
                      <td colSpan={7} className='d-none d-xl-table-cell'>
                        <EmptyResults name='System defined carriers' />
                      </td>
                    </tr>
                  )}
                </tbody>
              </TableDiv>
            ) : (
              <>
                <div className='d-none d-xl-block'>
                  <Table
                    limit={limit}
                    setLimit={setLimit}
                    page={page}
                    setPage={setPage}
                    count={carrierCount}
                    columns={[
                      {
                        accessor: 'name',
                        resizable: true,
                        minWidth: 300,
                        sortable: true,
                        cellClass: 'carrierName',
                        headerCellClass: 'carrierName',
                        Header: () => (
                          <div
                            onClick={() => handleSort('name')}
                            style={{ cursor: 'pointer' }}
                            className='d-flex justify-content-between w-100'
                          >
                            <span>Carrier</span>
                            <img
                              className='sort'
                              src={
                                sortBy !== 'carrierName'
                                  ? sort
                                  : orderBy === 'asc'
                                  ? sortdown
                                  : sortup
                              }
                              alt='sort'
                            />
                          </div>
                        ),
                        Cell: ({ row }: { row: any }) => (
                          <div className='row-name d-flex align-items-center'>
                            <b
                              onClick={() => {
                                // setShowDetailModal('open');
                                // setUUID(row.original.uuid);
                              }}
                            >
                              {row.original.name ? row.original.name : '-'}
                            </b>
                          </div>
                        ),
                      },
                      {
                        accessor: 'iPhone app link',
                        Header: 'iPhone app link',
                        resizable: true,
                        Cell: ({ row }: { row: any }) => (
                          <div className='d-flex'>
                            {row.original.iPhoneAppLink}
                          </div>
                        ),
                      },
                      {
                        accessor: 'androidAppLink',
                        Header: 'Android app link',
                        resizable: true,
                        sortable: true,

                        Cell: ({ row }: { row: any }) => (
                          <>
                            <div>{row.original.androidAppLink}</div>
                          </>
                        ),
                      },
                      {
                        accessor: 'self-serviceLink',
                        Header: 'Self-service link',
                        resizable: true,
                        sortable: true,

                        Cell: ({ row }: { row: any }) => (
                          <>
                            <div>{row.original.selfServiceLink}</div>
                          </>
                        ),
                      },
                      {
                        accessor: 'productCategories',
                        Header: 'Policies offered',
                        resizable: true,
                        sortable: true,

                        Cell: ({ row }: { row: any }) => (
                          <>
                            <div className='d-flex h-100 align-items-center'>
                              {row.original.CustomProduct?.map(
                                (item: any, index: number) => {
                                  if (index < 3) {
                                    return `${item.name}${
                                      index !==
                                      row.original.CustomProduct?.length - 1
                                        ? ', '
                                        : ''
                                    }`;
                                  } else if (
                                    index ===
                                    row.original.CustomProduct?.length - 1
                                  ) {
                                    return ` and ${
                                      row.original?.CustomProduct?.length - 3
                                    } others`;
                                  }
                                }
                              )}
                            </div>
                            {row.original.CustomProduct?.length < 1 && '-'}
                          </>
                        ),
                      },
                      {
                        accessor: 'Status',
                        Header: 'Status',
                        resizable: true,
                        Cell: ({ row }: { row: any }) => (
                          <div className='row-status '>
                            <div
                              className={`d-flex align-items-center
                          ${
                            row.original.isActive
                              ? 'activeStage'
                              : 'inactiveStage'
                          }`}
                            >
                              <div
                                className={`dot  mx-2 ${
                                  row.original.isActive ? 'avtive' : 'inactive'
                                }`}
                              ></div>
                              {row.original.isActive ? 'Active' : 'Inactive'}
                            </div>
                          </div>
                        ),
                      },
                      {
                        accessor: 'actions',
                        Header: 'Actions',
                        sticky: 'right',
                        resizable: false,
                        width: window.innerWidth > 1900 ? 80 : 80,
                        Cell: ({ row }: { row: any }) => (
                          <div className='d-flex align-items-center h-100'>
                            <div className='d-flex align-items-center  h-100'>
                              <ActionsDropdown
                                uuid={row.original.uuid}
                                setShowEditModal={setEditCarrier}
                                setUUID={setUUID}
                                activateAction={setActivateAction}
                                isActive={row.original.isActive}
                                onDelete={setDelete}
                                setShowDeleteModal={setShowDeleteModal}
                                name={row.original.name}
                                setCarrier={setCarrier}
                                carrier={row.original}
                              />
                            </div>
                          </div>
                        ),
                      },
                    ]}
                    data={carriers}
                  />
                </div>
                <div className='d-none d-sm-block d-xl-none table-tab-view'>
                  <Table
                    limit={limit}
                    setLimit={setLimit}
                    page={page}
                    setPage={setPage}
                    count={carrierCount}
                    columns={[
                      {
                        accessor: 'name',
                        resizable: true,
                        minWidth: 300,
                        sortable: true,
                        cellClass: 'carrierName',
                        headerCellClass: 'carrierName',
                        Header: () => (
                          <div
                            onClick={() => handleSort('name')}
                            style={{ cursor: 'pointer' }}
                            className='d-flex justify-content-between w-100'
                          >
                            <span>Carrier</span>
                            <img
                              className='sort'
                              src={
                                sortBy !== 'carrierName'
                                  ? sort
                                  : orderBy === 'asc'
                                  ? sortdown
                                  : sortup
                              }
                              alt='sort'
                            />
                          </div>
                        ),
                        Cell: ({ row }: { row: any }) => (
                          <div className='row-name d-flex align-items-center h-100'>
                            <b
                              onClick={() => {
                                // setShowDetailModal('open');
                                // setUUID(row.original.uuid);
                              }}
                              className='d-flex'
                            >
                              <img
                                className='logoImage'
                                src={row.original.logo}
                                alt=''
                              />{' '}
                              <div>
                                {row.original.name ? row.original.name : '-'}
                                <div
                                  className={`d-flex align-items-center item-status
                          ${
                            row.original.isActive === true
                              ? 'activeStage'
                              : 'inactiveStage'
                          }`}
                                >
                                  <div
                                    className={`dot ${
                                      row.original.isActive === true
                                        ? 'avtive'
                                        : 'inactive'
                                    }`}
                                  ></div>
                                  {row.original.isActive === true
                                    ? 'Active'
                                    : 'Inactive'}
                                </div>
                              </div>
                            </b>
                          </div>
                        ),
                      },
                      {
                        accessor: 'iPhone app link',
                        Header: 'iPhone app link',
                        resizable: true,
                        Cell: ({ row }: { row: any }) => (
                          <div className='d-flex h-100 align-items-center'>
                            {row.original.iPhoneAppLink}
                          </div>
                        ),
                      },
                      {
                        accessor: 'androidAppLink',
                        Header: 'Android app link',
                        resizable: true,
                        sortable: true,

                        Cell: ({ row }: { row: any }) => (
                          <>
                            <div className='d-flex h-100 align-items-center'>
                              {row.original.androidAppLink}
                            </div>
                          </>
                        ),
                      },
                      {
                        accessor: 'self-serviceLink',
                        Header: 'Self-service link',
                        resizable: true,
                        sortable: true,

                        Cell: ({ row }: { row: any }) => (
                          <>
                            <div className='d-flex h-100 align-items-center'>
                              {row.original.selfServiceLink}
                            </div>
                          </>
                        ),
                      },
                      {
                        accessor: 'productCategories',
                        Header: 'Policies offered',
                        resizable: true,
                        sortable: true,

                        Cell: ({ row }: { row: any }) => (
                          <>
                            <div className='d-flex h-100 align-items-center'>
                              {row.original.CustomProduct?.map(
                                (item: any, index: number) => {
                                  if (index < 3) {
                                    return `${item.name}${
                                      index !==
                                      row.original.CustomProduct?.length - 1
                                        ? ', '
                                        : ''
                                    }`;
                                  } else if (
                                    index ===
                                    row.original.CustomProduct?.length - 1
                                  ) {
                                    return ` and ${
                                      row.original?.CustomProduct?.length - 3
                                    } others`;
                                  }
                                }
                              )}
                            </div>
                            {row.original.CustomProduct?.length < 1 && '-'}
                          </>
                        ),
                      },
                      {
                        accessor: 'actions',
                        Header: 'Actions',
                        sticky: 'right',
                        resizable: false,
                        width: window.innerWidth > 1900 ? 80 : 80,
                        Cell: ({ row }: { row: any }) => (
                          <div className='d-flex align-items-center h-100'>
                            <div className='d-flex align-items-center  h-100'>
                              <ActionsDropdown
                                uuid={row.original.uuid}
                                setShowEditModal={setEditCarrier}
                                setUUID={setUUID}
                                activateAction={setActivateAction}
                                isActive={row.original.isActive}
                                onDelete={setDelete}
                                setShowDeleteModal={setShowDeleteModal}
                                name={row.original.name}
                                setCarrier={setCarrier}
                                carrier={row.original}
                              />
                            </div>
                          </div>
                        ),
                      },
                    ]}
                    data={carriers}
                  />
                </div>
              </>
            )}
          </div>
          <div className='d-xl-none'>
            <MobileTabNav />
          </div>
        </div>
      </MyBookDiv>
      {addCarrier !== 'closed' && (
        <CarrierModal
          loadingStatus={loadingStatus}
          addCarrier={addCarrier}
          onCloseModal={onCloseModal}
          setAddCarrier={setAddCarrier}
        />
      )}
      {editCarrier !== 'closed' && (
        <EditCarrierModal
          loadingStatus={loadingStatus}
          editCarrier={editCarrier}
          onCloseModal={onCloseEditModal}
          uuid={UUID}
          setEditCarrier={setEditCarrier}
          carrier={carrier}
        />
      )}
      {showDeleteModal !== 'closed' && (
        <DeleteModal
          showModal={showDeleteModal}
          closeModal={setShowDeleteModal}
          deleteFunction={onDelete}
          uuid={UUID}
          loading={loadingStatus}
          resource='system carrier'
          name={name}
          deleteText={carrierUUID.carrierUUID ? 'Move and Delete' : ''}
          content={
            <>
              <div className='sub-heading'>
                There are some policies associated to this carrier. You can move
                these entities to be associated to a different carrier.
              </div>
              <div className='fields my-2'>
                <SelectField
                  options={carriers.map((item: any) => {
                    return {
                      value: item?.uuid,
                      label: item?.name,
                    };
                  })}
                  placeholder='From Carrier'
                  name='deleteCarrier'
                  disabled
                  values={carrierName}
                  onSetItem={onSetItem}
                />
              </div>

              <div className='fields'>
                <SelectField
                  options={carriers
                    .filter((item: any) => item?.uuid !== UUID)
                    .map((item: any) => {
                      return {
                        value: item?.uuid,
                        label: item?.name,
                      };
                    })}
                  placeholder='To Carrier'
                  name='carrierUUID'
                  values={carrierUUID}
                  onSetItem={onSetItem}
                />
              </div>
            </>
          }
        />
      )}

      <FilterContainer>
        {showFilter !== 'closed' && (
          <CarrierFilter
            show={showFilter}
            closeModal={setShowFilter}
            filterFunction={filterCustomCarrier}
            setStatus={setStatus}
            status={status}
            setSelectedPolicyTypes={setSelectedPolicyTypes}
            selectedPolicyTypes={selectedPolicyTypes}
          />
        )}
      </FilterContainer>
      {showActivate !== 'closed' && (
        <ActivateModal
          showModal={showActivate}
          closeModal={setShowActivate}
          submitFunction={activateAction}
          uuid={UUID}
          loading={loadingStatus}
          resource='custom carrier'
          name={name}
          isActive={isActive}
        />
      )}
    </>
  );
};
export default CustomCarriers;

const ActionsDropdown = ({
  uuid,
  setShowEditModal,
  setUUID,
  activateAction,
  isActive,
  onDelete,
  setShowDeleteModal,
  name,
  carrier,
  setCarrier,
}: {
  uuid: string;
  setShowEditModal: Function;
  setUUID: Function;
  activateAction: Function;
  isActive: boolean;
  onDelete: Function;
  name: string;
  setShowDeleteModal: Function;
  carrier: any;
  setCarrier: Function;
}) => {
  const [showActionsDropdown, setShowActionsDropdown] = useState(false);
  const dropRef = useRef<HTMLButtonElement>(null);
  useOutsideClick(dropRef, setShowActionsDropdown);

  return (
    <button
      className='vector d-flex align-items-center justify-content-center position-relative'
      onClick={() => {
        setShowActionsDropdown(!showActionsDropdown);
        if (dropRef.current) {
          dropRef.current.focus();
        }
      }}
      onBlur={() => setShowActionsDropdown(false)}
      ref={dropRef}
    >
      <img src={vector} alt='vector' />
      {showActionsDropdown && (
        <div className='dropdown-div custom-carrier-action-drop'>
          <div
            className='edit-div'
            onClick={() => {
              setShowEditModal('open');
              setShowActionsDropdown(false);
              setUUID(uuid);
              setCarrier(carrier);
            }}
          >
            Edit <img src={edit} alt='bin' />
          </div>
          <div
            className='activate-div'
            onClick={() => {
              activateAction(uuid, isActive, name);
            }}
          >
            {isActive ? 'Deactivate' : 'Activate'}
            <img src={isActive ? minus : tick} alt='activate' />
          </div>
          <div
            className='delete-div'
            onClick={() => {
              setShowActionsDropdown(!showActionsDropdown);
              onDelete(uuid, name);
              setShowDeleteModal('open');
            }}
          >
            Delete <img src={bin} alt='bin' />
          </div>
        </div>
      )}
    </button>
  );
};
