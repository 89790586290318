import { useEffect, useState } from 'react';
import { fetchProductCategory } from '../../api/productCategory';
import { getOpportunityStatus } from '../../api/opportunities';
import Modal from '../Modal';
import FilterSelectField from '../FilterSelectField';
import { IProductCategory } from '../../interfaces/ProductCategory';
import { IOpportunityStatus, IPipeline } from '../../interfaces/Opportunity';
import { StyledModal } from './styles';
import cross from '../../assets/logo/cross.svg';
import { fetchProducts } from '../../api/product';
import { IProduct } from '../../interfaces/Product';
import { useParams } from 'react-router';
import LocalStorageService from '../../utils/LocalStorageService';

const FilterModal = ({
  show,
  closeModal,
  selectedOppStatus,
  setProducts,
  setOppStatus,
  selectedProducts,
  setActive,
  selectedActive,
  pipelineManage,
  pipelineStages,
}: {
  show: string;
  closeModal: Function;
  setProducts: Function;
  setOppStatus: Function;
  selectedProducts: any;
  selectedOppStatus: any;
  setActive: Function;
  selectedActive: string;
  pipelineManage?: boolean;
  pipelineStages?: IPipeline;
}) => {
  const [product, setProduct] = useState<IProduct[]>([]);

  const [status, setStatus] = useState<IOpportunityStatus[]>([]);

  const { uuid } = useParams();

  const user = LocalStorageService.getUser();

  const onCloseModal = () => {
    closeModal('closing');
    setTimeout(() => {
      closeModal('closed');
    }, 300);
  };
  useEffect(() => {
    getOpportunityStatus(
      (data: IOpportunityStatus[]) => setStatus(data),
      '',
      '',
      '',
      user?.UserRole.name === 'ADMIN' ? uuid : ''
    );
  }, []);

  useEffect(() => {
    fetchProducts(
      (data: IProduct[]) => setProduct(data),
      '',
      '',
      '',
      user?.UserRole.name === 'ADMIN' ? uuid : '',
      true
    );
  }, []);

  const active = [
    {
      label: 'Active',
      value: 'active',
    },
    {
      label: 'Inactive',
      value: 'inactive',
    },
  ];

  return (
    <>
      <Modal open={show} align='right'>
        <StyledModal>
          <button className='crossbutton' onClick={() => onCloseModal()}>
            <img src={cross} className='cross' alt='cross-icon' />
          </button>
          <div>
            <h2>Filters</h2>
          </div>
          <div>
            <hr />
          </div>
          <div>
            <div>
              {show && (
                <div>
                  <div className='field-section'>
                    {!pipelineManage ? (
                      <>
                        <div>
                          <h6 className='head'>STAGES</h6>
                        </div>
                        {pipelineStages && (
                          <div className='agency-fields'>
                            <FilterSelectField
                              options={pipelineStages?.PipelineStages.map(
                                (item) => {
                                  return { label: item.name, value: item.uuid };
                                }
                              )}
                              placeholder='Select'
                              onChange={(value: any) => {
                                setOppStatus(value);
                              }}
                              isMulti={true}
                              value={selectedOppStatus}
                            />
                          </div>
                        )}
                        <hr className='filter-hr' />
                      </>
                    ) : null}

                    <div>
                      <h6 className='head'>POLICY</h6>
                    </div>
                    <div className='agency-fields'>
                      <FilterSelectField
                        options={product.map((item) => {
                          return { label: item.name, value: item.uuid };
                        })}
                        name='policy'
                        placeholder='Select'
                        isMulti={true}
                        onChange={(value: any) => {
                          setProducts(value);
                        }}
                        value={selectedProducts}
                      />
                    </div>
                    {pipelineManage ? (
                      <>
                        <hr className='filter-hr' />
                        <div>
                          <h6 className='head'>STATUS</h6>
                        </div>
                        <div className='agency-fields'>
                          <FilterSelectField
                            options={active}
                            name='active'
                            placeholder='Select'
                            onChange={(value: any) => {
                              setActive(value);
                            }}
                            value={selectedActive}
                          />
                        </div>
                      </>
                    ) : null}
                    <hr className='filter-hr' />
                  </div>
                  <div className='buttonsDiv filterButtonContainer d-flex align-items-center justify-content-between'>
                    <div className='filter-count'>
                      {selectedOppStatus.length +
                        selectedProducts.length +
                        (selectedActive ? 1 : 0)}{' '}
                      filters added
                    </div>
                    <button
                      type='submit'
                      className='agentButton justify-content-end'
                      onClick={() => {
                        setOppStatus([]);
                        setProducts([]);
                        setActive('');
                      }}
                      disabled={
                        selectedOppStatus.length +
                          selectedProducts.length +
                          (selectedActive ? 1 : 0) ===
                        0
                      }
                    >
                      Clear all filters
                    </button>
                  </div>
                </div>
              )}
            </div>
          </div>
        </StyledModal>
      </Modal>
    </>
  );
};

export default FilterModal;
