import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { AppDispatch } from '../../../../store';
import {
  createAccount,
  AccountsSliceState,
} from '../../../../store/accounts/accountsSlice';
import { getCount } from '../../../../store/book/bookSlice';
import {
  clearContactAccount,
  getContactAccount,
  getCount as getContactCount,
  updateContactDetailAccount,
} from '../../../../store/contacts/contactsSlice';
import { fetchContacts } from '../../../../api/contact';
import InputField from '../../../../components/InputField';
import PhoneNumberInput from '../../../../components/PhoneNumberFormat';
import SuccessCard from '../../../../components/SuccessCard';
import SearchDropdown from '../../../../components/SearchDropdown';
import GoogleSearchInput from '../../../../components/GoogleSearchInput';
import MarkdownEditor from '../../../../components/MarkdownEditor';
import NewBusinessQuickForm from './NewBusinessQuickForm';
import { IContact } from '../../../../interfaces/Contact';
import { StyledAddModal } from '../../elements/styles';
import { SuccessAlign } from '../../../../styles/SuccessAlign';
import { FormModal } from '../../../../styles/FormModal';
import cross from '../../../../assets/logo/cross.svg';
import warning from '../../../../assets/logo/warning.svg';
import tick from '../../../../assets/logo/tick.svg';
import blackplus from '../../../../assets/logo/blackplus.svg';

const SignupSchema = Yup.object().shape({
  lastName: Yup.string().required('Last name is required'),
  phoneNumber: Yup.string()
    .required('Phone Number is required')
    .matches(
      /[(][0-9]{3}[)][-\s][0-9]{3}[-][0-9]{4}$/,
      'Phone number is not valid'
    ),
  email: Yup.string()
    .email('Invalid email. Please try again')
    .required('Please enter your email '),
});

const QuickAccountDrawer = ({
  show,
  closeModal,
  InnerTab,
  contactDetails,
  primaryExist,
}: {
  show: string;
  closeModal: Function;
  InnerTab?: boolean;
  contactDetails?: IContact;
  primaryExist?: boolean;
}) => {
  const dispatch = useDispatch<AppDispatch>();
  const {
    register,
    handleSubmit,
    reset,
    watch,
    control,
    formState: { errors },
    setValue,
    getValues,
  } = useForm({
    resolver: yupResolver(SignupSchema),
  });
  const [showSuccess, setshowSuccess] = useState(false);
  const watchAllFields = watch();
  const [errorMessage, setError] = useState('');
  const [commercial, isCommercial] = useState(false);
  const [business, setBusiness] = useState<any>([{}]);
  const [addressObject, setAddressObject] = useState<any>({});
  const [isErrorOccured, setIsErrorOccured] = useState<any>([]);
  const [submit, setSubmit] = useState(false);
  const watchLastName = watch('lastName');
  const [disabled, setDisabled] = useState(false);
  const [textCheck, setTextCheck] = useState('');
  const [noteError, setNoteError] = useState('');

  const addsNewBussiness = () => {
    business ? setBusiness([...business, {}]) : setBusiness([{}]);
  };

  const onCloseModal = () => {
    closeModal('closing');
    setTimeout(() => {
      closeModal('closed');
    }, 300);
  };

  const loading = useSelector(
    (state: { accounts: AccountsSliceState }) => state.accounts.isLoading
  );

  const navigate = useNavigate();

  const [emailAccounts, setEmailAccounts] = useState<any[]>([]);
  const [emailSearch, setEmailSearch] = useState('');
  const [emailError, setEmailError] = useState(false);
  const [emailData, setEmailData] = useState<any>([]);

  const [loadingStatus, setLoadingStatus] = useState('idle');

  const loadEmail = (value: string) => {
    errors.email = null;
    setEmailError(false);
    if (loadingStatus === 'idle' || loadingStatus === 'succeeded') {
      fetchContacts('id', 'asc', emailSearch, 25, 0, 'True', (data: any) => {
        setEmailAccounts(data);
        setLoadingStatus('succeeded');
      });
    }
  };
  let errorexist = false;
  const onSubmit = (value: any) => {
    const businessWithErrorValidation = business.map((bus: any) => {
      let errorobj: any = {};

      if (!bus.name) {
        errorobj.businessNameError = 'Please enter Business Name';
        errorexist = true;
      }

      if (!bus.businessPhoneNumber || bus.businessPhoneNumber?.length < 14) {
        errorobj.businessPhoneNumberError =
          'Please enter  Business Phone Number';
        errorexist = true;
      }

      return errorobj;
    });
    setIsErrorOccured(businessWithErrorValidation);
    let errorLastName = true;
    if (!commercial && !watchLastName) {
      errorLastName = false;
    }
    const emailExist = emailAccounts.find(
      (item) =>
        item?.ContactDetails?.find((value: any) => value.type === 'EMAIL')
          ?.value === emailSearch
    );
    if (errorexist && commercial) {
      return errorexist;
    } else if (emailExist) {
      setEmailError(true);
      setEmailData([
        `${emailExist?.firstName} ${emailExist?.lastName}`,
        emailExist?.uuid,
      ]);
    } else {
      let noteSubmit = true;
      if (notes) {
        if (textCheck && !textCheck.trim() && textCheck.length > 1) {
          noteSubmit = false;
          setNoteError('Note cannot be blank space');
        }
      }
      value.notes = notes;
      value.accountType = commercial ? 'COMMERCIAL' : 'PERSONAL';
      value.businesses = business;
      // To add addressObject with the form
      if (!isManual) {
        value = Object.assign(value, addressObject);
      }
      value.isManuallyAdded = isManual;
      if (InnerTab && contactDetails?.uuid) {
        value.contactUUID = contactDetails?.uuid;
      }
      if (errorLastName === true && noteSubmit) {
        dispatch(
          createAccount({
            data: value,
            successCB: (data: any) => {
              dispatch(getCount());

              closeModal('closing');
              setTimeout(() => {
                setDisabled(true);
                setshowSuccess(true);
              }, 300);
              setTimeout(() => {
                reset();
                setshowSuccess(false);
                setTimeout(() => {
                  closeModal('closed');
                }, 300);
              }, 2000);
              if (InnerTab && contactDetails?.uuid) {
                dispatch(clearContactAccount());
                dispatch(getContactCount({ uuid: contactDetails?.uuid }));
                dispatch(getContactAccount({ uuid: contactDetails?.uuid }));
                dispatch(updateContactDetailAccount({ account: data.data }));
              }
            },
            errorCB: (error: string) => {
              setDisabled(false);
              if (typeof error === 'string') {
                setError(error);
              }
            },
          })
        );
      }
    }
  };

  const resetEmail = (value: string) => {
    setEmailData([]);
    setEmailError(false);
  };

  useEffect(() => {
    setAddressObject({});
  }, [commercial]);

  const [notes, setNotes] = useState<any>(['']);
  const [isManual, setIsManual] = useState(false);

  const onSetNotesValue = (value: any, index: number) => {
    let updatedNotes = [...notes];
    updatedNotes[index] = value;
    setNotes(updatedNotes);
  };
  useEffect(() => {
    if (primaryExist && InnerTab) {
      isCommercial(true);
      setValue(
        'apartment',
        contactDetails?.AccountContacts[0]?.Account?.Address?.street
      );
    }
    if (InnerTab && contactDetails) {
      setValue('firstName', contactDetails?.firstName);
      setValue('middleName', contactDetails?.middleName);
      setValue('lastName', contactDetails?.lastName);
      if (contactDetails.ContactDetails[0]?.type === 'EMAIL') {
        setEmailSearch(contactDetails.ContactDetails[0]?.value);
        setValue('email', contactDetails.ContactDetails[0]?.value);
        setValue('phoneNumber', contactDetails.ContactDetails[1]?.value);
      } else {
        setEmailSearch(contactDetails.ContactDetails[1]?.value);
        setValue('email', contactDetails.ContactDetails[1]?.value);
        setValue('phoneNumber', contactDetails.ContactDetails[0]?.value);
      }
      if (
        contactDetails?.AccountContacts[0]?.Account?.Address?.isManuallyAdded
      ) {
        setIsManual(true);
        setValue(
          'businessApt',
          contactDetails?.AccountContacts[0].Account?.Address?.street
        );
        setValue(
          'zipCode',
          contactDetails?.AccountContacts[0].Account?.Address?.zipCode
        );
        setValue(
          'city',
          contactDetails?.AccountContacts[0].Account?.Address?.city
        );
        setValue(
          'country',
          contactDetails?.AccountContacts[0].Account?.Address?.country
        );
        setValue(
          'state',
          contactDetails?.AccountContacts[0].Account?.Address?.state
        );
        setValue(
          'address',
          contactDetails?.AccountContacts[0].Account?.Address?.name
        );
      } else {
        if (contactDetails?.AccountContacts[0]?.Account?.Address) {
          setAddressObject(contactDetails?.AccountContacts[0].Account?.Address);
        } else {
          setAddressObject({});
        }
      }
    } //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [commercial]);

  useEffect(() => {
    if (notes) {
      if (textCheck) {
        setNoteError('');
      }
    }
  }, [notes]);

  return (
    <>
      <SuccessAlign>
        <div className={`success-container ${showSuccess && 'open-drawer'} `}>
          <SuccessCard
            title={'Success adding contact'}
            message={`<b class='name-text-overflow'>${getValues(
              'firstName'
            )} ${getValues('lastName')}</b> has been added as contact<b>`}
          />
          <button
            onClick={() => {
              reset();
              setshowSuccess(false);
            }}
            className='crossbutton'
          >
            <img src={cross} className='cross' alt='cross-icon' />
          </button>
        </div>
      </SuccessAlign>

      <FormModal
        open={show}
        align='right'
        containerClassName='fullwidth-container'
      >
        <StyledAddModal>
          <div className='entire-div'>
            <div className='form-title'>
              <h2>Add account</h2>
            </div>
            <button className='cross-btn'>
              <img
                className='cross'
                src={cross}
                alt='cross-icon'
                onClick={() => {
                  reset();
                  onCloseModal();
                }}
              />
            </button>
            <div className='d-flex navButtons'>
              <button
                onClick={() => isCommercial(false)}
                className={
                  !commercial
                    ? 'naVigatorbuttonsActive secondaryButton'
                    : 'naVigatorbuttons secondaryButton'
                }
                disabled={primaryExist}
              >
                Personal
              </button>
              <div className='commercial-text'>
                <button
                  onClick={() => isCommercial(true)}
                  className={
                    commercial
                      ? 'naVigatorbuttonsActive secondaryButton'
                      : 'naVigatorbuttons secondaryButton'
                  }
                >
                  Commercial
                </button>
              </div>
            </div>
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className='scroll-bar  '>
                <div>
                  <h2 className='contact-info'>Contact Info</h2>
                </div>
                <div className='top-field d-sm-flex justify-content-between'>
                  <div className='fields'>
                    <InputField
                      name='firstName'
                      type='text'
                      placeholder='First name'
                      disabled={InnerTab}
                      register={register}
                      className={`${
                        (errors.firstName || errorMessage) && 'error-field'
                      } ${watchAllFields.firstName && 'filled'}`}
                    />
                    {errors.firstName ? (
                      <div className='errorVal'>
                        <img src={warning} alt='warning' className='warning' />
                        {errors.firstName?.message}
                      </div>
                    ) : null}
                  </div>
                  <div className='small-div d-sm-flex justify-content-between'>
                    <div className='fields-small'>
                      <InputField
                        name='middleName'
                        type='text'
                        placeholder='Middle name'
                        disabled={InnerTab}
                        register={register}
                        className={`${
                          (errors.middleName || errorMessage) && 'error-field'
                        } ${watchAllFields.middleName && 'filled'}`}
                      />
                      {errors.middleName ? (
                        <div className='errorVal'>
                          <img
                            src={warning}
                            alt='warning'
                            className='warning'
                          />
                          {errors.middleName?.message}
                        </div>
                      ) : null}
                    </div>
                    <div className='fields-small'>
                      <InputField
                        name='lastName'
                        type='text'
                        placeholder='Last name'
                        disabled={InnerTab}
                        register={register}
                        required
                        className={`${
                          (errors.lastName || errorMessage) && 'error-field'
                        } ${watchAllFields.lastName && 'filled'}`}
                      />
                      {errors.lastName ||
                      (!commercial && !watchLastName && submit) ? (
                        <div className='errorVal'>
                          <img
                            src={warning}
                            alt='warning'
                            className='warning'
                          />
                          {errors.lastName?.message || 'Last name is required'}
                        </div>
                      ) : null}
                    </div>
                  </div>
                </div>
                <div className='d-sm-flex justify-content-between'>
                  <div className='fields'>
                    {/* <InputField
                      name='email'
                      type='email'
                      placeholder='Email'
                      register={register}
                      className={`${
                        (errors.email || errorMessage) && 'error-field'
                      } ${watchAllFields.email && 'filled'}`}
                    />
                    {errors.email || errorMessage ? (
                      <div className='errorVal'>
                        <img src={warning} alt='warning' className='warning' />
                        {errors.email?.message || errorMessage}
                      </div>
                    ) : null} */}
                    <SearchDropdown
                      name='email'
                      addName={true}
                      placeholder='Email'
                      isEdit={true}
                      values={emailSearch}
                      searchValue={emailAccounts}
                      setSearch={setEmailSearch}
                      status={loadingStatus}
                      className={`${emailSearch && 'filled'}`}
                      register={register}
                      disabled={InnerTab}
                      // control={control}
                      required
                      type='email'
                      setUUID={resetEmail}
                      loadFunction={loadEmail}
                      content={emailAccounts?.map((item) => (
                        <div
                          className='searchValuesCard d-flex align-items-center'
                          onClick={() => {
                            setEmailSearch(
                              item?.ContactDetails?.find(
                                (value: any) => value.type === 'EMAIL'
                              )?.value
                            );
                            setEmailError(true);
                            setEmailData([
                              `${item?.firstName} ${item?.lastName}`,
                              item?.uuid,
                            ]);
                          }}
                        >
                          <div className='profile-pic d-flex align-items-center justify-content-center'>
                            {item?.firstName?.charAt(0)}
                          </div>
                          <div>
                            <h6>
                              {item?.firstName} {item?.lastName}
                            </h6>
                            <ul className='user-details'>
                              <li>
                                {
                                  item?.ContactDetails?.find(
                                    (value: any) => value.type === 'EMAIL'
                                  )?.value
                                }
                              </li>
                              <li>
                                {
                                  item?.ContactDetails?.find(
                                    (value: any) => value.type === 'PHONENUMBER'
                                  )?.value
                                }
                              </li>
                            </ul>
                          </div>
                        </div>
                      ))}
                    />
                    {emailError ? (
                      <div className='errorVal emailError'>
                        <img src={warning} alt='warning' className='warning' />
                        <div className='email-exist-error'>
                          This email is already associated with another contact{' '}
                          <span
                            onClick={() =>
                              navigate(`/contacts/${emailData[1]}`)
                            }
                          >
                            {emailData[0]}
                          </span>{' '}
                          Please use a different email.
                        </div>
                      </div>
                    ) : (
                      errors.email?.message && (
                        <div className='errorVal'>
                          <img
                            src={warning}
                            alt='warning'
                            className='warning'
                          />
                          {errors.email?.message}
                        </div>
                      )
                    )}
                  </div>

                  <div className='fields'>
                    <PhoneNumberInput
                      name='phoneNumber'
                      type='tel'
                      placeholder='Phone number'
                      disabled={InnerTab}
                      control={control}
                      required
                      className={`${
                        (errors.phoneNumber || errorMessage) && 'error-field'
                      } ${watchAllFields.phoneNumber && 'filled'}`}
                    />
                    {errors.phoneNumber || errorMessage ? (
                      <div className='errorVal'>
                        <img src={warning} alt='warning' className='warning' />
                        {errors.phoneNumber?.message || errorMessage}
                      </div>
                    ) : null}
                  </div>
                </div>
                {!commercial ? (
                  <div>
                    {!isManual ? (
                      <div className=' d-sm-flex justify-content-between'>
                        <div className='fields'>
                          <GoogleSearchInput
                            placeholder='Mailing Address'
                            name='address'
                            inputType='PERSONAL'
                            disabled={InnerTab}
                            setAddressObject={setAddressObject}
                            addressObject={addressObject}
                            className={`${errors.address && 'error-field'} ${
                              Object.keys(addressObject).length > 0 && 'filled'
                            }`}
                            setIsManual={setIsManual}
                          ></GoogleSearchInput>
                        </div>
                        <div className='fields'>
                          <InputField
                            name='apartment'
                            type='text'
                            placeholder='Apt'
                            disabled={InnerTab}
                            register={register}
                            className={`${errors.apartment && 'error-field'}
                               ${watchAllFields.apartment && 'filled'}`}
                          />
                        </div>
                      </div>
                    ) : (
                      <>
                        <h2 className='contact-info'>Mailing address</h2>
                        <div className=' d-sm-flex justify-content-between'>
                          <div className='fields'>
                            <InputField
                              name='address'
                              type='text'
                              placeholder='Address'
                              disabled={InnerTab}
                              register={register}
                              className={`${errors.address && 'error-field'} ${
                                watchAllFields.address && 'filled'
                              }`}
                            />
                          </div>
                          <div className='fields'>
                            <InputField
                              name='zipCode'
                              type='number'
                              placeholder='Zipcode'
                              disabled={InnerTab}
                              register={register}
                              className={`${errors.zipCode && 'error-field'} ${
                                watchAllFields.zipCode && 'filled'
                              }`}
                            />
                          </div>
                        </div>
                        <div className=' d-sm-flex justify-content-between'>
                          <div className='fields'>
                            <InputField
                              name='city'
                              type='text'
                              placeholder='City'
                              register={register}
                              disabled={InnerTab}
                              className={`${errors.city && 'error-field'} ${
                                watchAllFields.city && 'filled'
                              }`}
                            />
                          </div>
                          <div className='fields'>
                            <InputField
                              name='state'
                              type='text'
                              placeholder='State'
                              disabled={InnerTab}
                              register={register}
                              className={`${errors.state && 'error-field'} ${
                                watchAllFields.state && 'filled'
                              }`}
                            />
                          </div>
                        </div>
                        <div className=' d-sm-flex justify-content-between'>
                          <div className='fields'>
                            <InputField
                              name='country'
                              type='text'
                              placeholder='County'
                              disabled={InnerTab}
                              register={register}
                              className={`${errors.country && 'error-field'} ${
                                watchAllFields.country && 'filled'
                              }`}
                            />
                          </div>
                          <div className='fields'>
                            <InputField
                              name='apartment'
                              type='text'
                              placeholder='Apt'
                              disabled={InnerTab}
                              register={register}
                              className={`${
                                errors.businessApt && 'error-field'
                              } ${watchAllFields.businessApt && 'filled'}`}
                            />
                          </div>
                        </div>
                        {InnerTab && (
                          <>
                            <h2 className='business-info bussinessInfoHead'>
                              Business info{' '}
                            </h2>
                            <div className='fields'>
                              <InputField
                                name='name'
                                type='text'
                                placeholder='Account name'
                                register={register}
                                className={`${
                                  (errors.name || errorMessage) && 'error-field'
                                } ${watchAllFields.name && 'filled'}`}
                              />
                            </div>
                          </>
                        )}
                      </>
                    )}
                  </div>
                ) : null}
                {commercial ? (
                  <div className='mt-5'>
                    {business?.map((item: any, index: any) => (
                      <NewBusinessQuickForm
                        key={index}
                        business={item}
                        index={index}
                        businesses={business}
                        setBusiness={setBusiness}
                        businessPhoneNumberError={
                          isErrorOccured[index]?.businessPhoneNumberError
                        }
                        businessNameError={
                          isErrorOccured[index]?.businessNameError
                        }
                        // amountError={isErrorOccured[index]?.amountError}
                      />
                    ))}
                    <button
                      className='mt-4 align-items-center secondaryButton'
                      type='button'
                      onClick={() => addsNewBussiness()}
                    >
                      <img
                        className='addAnotherBusiness'
                        src={blackplus}
                        alt='add'
                      />
                      Add another business
                    </button>
                  </div>
                ) : null}
                <div>
                  {notes &&
                    notes?.map((item: any, index: number) => {
                      return (
                        <div className='d-flex align-items-center descriptionField  col'>
                          <div className='textarea'>
                            <MarkdownEditor
                              placeholder='Add notes here'
                              note={item}
                              multi={true}
                              index={index}
                              setNote={onSetNotesValue}
                              textCheckFunction={setTextCheck}
                            />
                          </div>
                        </div>
                      );
                    })}
                </div>
                {noteError ? (
                  <div className='errorVal'>
                    <img src={warning} alt='warning' className='warning' />
                    {noteError}
                  </div>
                ) : null}
              </div>

              <div className='buttonsDiv d-flex align-items-center '>
                <button
                  onClick={() => {
                    reset();
                    onCloseModal();
                  }}
                  className='cancel-button'
                  type='button'
                >
                  Cancel
                </button>
                <button
                  type='submit'
                  className='saveButton'
                  disabled={loading || show === 'closing' || disabled}
                  onClick={() => {
                    setSubmit(true);
                  }}
                >
                  Save <img className='tick' src={tick} alt='tick-sign' />
                </button>
              </div>
            </form>
          </div>
        </StyledAddModal>
      </FormModal>
    </>
  );
};

export default QuickAccountDrawer;
