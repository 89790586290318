import { useState, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { IContact } from '../../../../interfaces/Contact';
import vector from '../../../../assets/logo/dropdown.svg';
import edit from '../../../../assets/logo/edit.svg';
import phone from '../../../../assets/logo/phone.svg';
import message from '../../../../assets/logo/message-action.svg';
import mail from '../../../../assets/logo/mail.svg';
import bin from '../../../../assets/logo/bin.svg';
import blackplus from '../../../../assets/logo/blackplus.svg';

const ActionsCard = ({
  contact,
  onShowEditModal,
  setShowDeleteModal,
  setUUID,
  setName,
}: {
  contact: IContact;
  onShowEditModal: Function;
  setShowDeleteModal: Function;
  setUUID: Function;
  setName: Function;
}) => {
  const [showDropdown, setShowDropdown] = useState(false);
  const navigate = useNavigate();

  const dropRef = useRef<HTMLButtonElement>(null);

  return (
    <>
      <div className='d-flex align-items-center justify-content-between h-100'>
        <div className='action-item'>
          <img src={phone} alt='phone' />
        </div>
        <div className='action-item'>
          <img src={mail} alt='mail' />
        </div>
        <div className='action-item'>
          <img src={message} alt='message' />
        </div>
        <button
          className='vector d-flex align-items-center justify-content-center position-relative'
          onClick={() => {
            setShowDropdown(!showDropdown);
            if (dropRef.current) {
              dropRef.current.focus();
            }
          }}
          onBlur={() => setShowDropdown(false)}
          ref={dropRef}
        >
          <img src={vector} alt='vector' />
          {showDropdown && (
            <div className='dropdown-div'>
              <div
                className='edit-div'
                onClick={() => {
                  onShowEditModal(contact.uuid);
                  setShowDropdown(false);
                }}
              >
                Edit <img src={edit} alt='bin' />
              </div>
              <div className='borderDiv'></div>
              <div
                className='edit-div'
                style={{ paddingTop: '10px' }}
                onClick={() => navigate(`/contacts/${contact.uuid}/activities`)}
              >
                Add activity <img src={blackplus} alt='plusIcon' />
              </div>
              <div className='borderDiv'></div>
              <div
                className='delete-div'
                onClick={() => {
                  setShowDeleteModal('open');
                  setUUID(contact.uuid);
                  setShowDropdown(false);
                  setName(`${contact.firstName} ${contact.lastName}`);
                }}
              >
                Delete <img src={bin} alt='bin' />
              </div>
            </div>
          )}
        </button>
      </div>
    </>
  );
};

export default ActionsCard;
