import React from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import LocalStorageService from './LocalStorageService';

function AdminOutlet() {
  const user = LocalStorageService.getUser();

  const isAdmin = () => {
    return user?.UserRole.name === 'ADMIN';
  };
  return isAdmin() ? <Outlet /> : <Navigate to='/error/403' />;
}

export default AdminOutlet;
