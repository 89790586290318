import styled from '@emotion/styled';

export const LoginDiv = styled.div`
  padding: 24px 20px 32px 20px;
  height: 100vh;
  background: #fff;

  .input-label input:-webkit-autofill ~ label {
    font-size: 12px;
    transform: translate(0, 2px);
    font-weight: 400;
  }

  .logo-svg {
    height: 28px;
  }

  .content-div {
    position: relative;
    height: 100%;
  }

  .center-div {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 80%;
    overflow-y: auto;
  }

  .welcome-heading {
    color: #191919;
    max-width: 197px;
    margin-bottom: 40px;
    font-size: 28px;
    line-height: 40px;
  }

  .fields {
    position: relative;
    margin-bottom: 16px;
  }

  .password-div {
    position: relative;
    height: 48px;
  }

  .form-check-input {
    height: 24px;
    width: 24px;
  }

  .subtext {
    margin-left: 12px;
    color: #5a5f70;
    margin-bottom: 0;
    font-size: 14px;
  }

  .toggleButton {
    cursor: pointer;
    position: absolute;
    font-weight: 600;
    font-size: 14px;
    right: 5%;
    top: 24%;
  }

  .eye {
    width: 20px;
    margin-right: 10px;
  }
  .closedeye {
    width: 20px;
    margin-right: 10px;
  }

  .errorVal {
    margin-top: 12px;
    font-size: 14px;
    color: #cc0000;
    display: flex;
    align-items: center;
  }

  .warning {
    width: 20px;
    margin-right: 10px;
  }

  .submit-button {
    width: 100%;
    display: flex;
    align-items: center;
    font-size: 16px;
    line-height: 24px;
    padding: 12px 20px;
    position: absolute;
    bottom: 24px;
    &:disabled {
      background: #d1d6e5;
    }
  }
  .arrowl {
    margin-left: auto;
  }

  .sub {
    margin-top: -32px;
    font-size: 14px;
    color: #5a5f70;
    line-height: 20px;
    margin-bottom: 40px;
  }

  .terms {
    font-size: 14px;
    color: #5a5f70;
    position: absolute;
    bottom: 92px;
    .terms-click {
      cursor: pointer;
      :hover {
        border-bottom: 1px solid #3c498a;
      }
    }

    span {
      color: #262758;
      font-weight: 600;
    }
  }

  .success-div {
    margin-top: 16px;
  }

  .primaryBackButton {
    background: transparent;
    border: 0;
    font-size: 16px;
    font-weight: 600;
    color: #262758;
  }

  .left-arrow {
    filter: brightness(0) saturate(100%) invert(13%) sepia(13%) saturate(1912%)
      hue-rotate(187deg) brightness(96%) contrast(90%);
    transform: rotate(180deg);
    margin-right: 12px;
  }

  .reset-password-div {
    .terms {
      margin-top: 24px;
      position: relative;
      bottom: 0;
      margin-bottom: 0;
    }
    .success-div {
      margin-top: -24px;
    }
    .backButton {
      border: 0;
      border-radius: 8px;
      background: #e6e9f0;
      justify-content: center;
      color: #262758;
      font-weight: 600;
    }
  }

  @media screen and (min-width: 576px) {
    padding: 32px 80px 44px 80px;

    .logo-svg {
      height: 44px;
    }

    .content-div {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    .center-div {
      height: 90%;
    }

    .welcome-heading {
      max-width: 450px;
      margin-bottom: 32px;
      font-size: 24px;
      line-height: 36px;
    }

    .fields {
      width: 408px;
      margin-bottom: 12px;
    }

    .subtext {
      font-size: 16px;
    }

    .submit-button {
      width: 180px;
      position: relative;
      margin-top: 32px;
      bottom: 0;
    }

    .forgotButton {
      position: relative;
      margin-top: 32px;
      bottom: 0;
    }

    .password-div {
      width: 408px;
    }

    .sub {
      margin: -28px 0 32px;
    }

    .terms {
      position: relative;
      bottom: 0;
      margin-top: 16px;
      max-width: 408px;
    }

    .success-div {
      margin-top: 12px;
      max-width: 408px;
    }

    .reset-password-div {
      .backButton {
        width: 200px;
        justify-content: space-between;
      }
      .success-div {
        margin-top: -16px;
      }
      .resetButton {
        width: 236px;
      }
      .input-container {
        .filled,
        .prefilled {
          label {
            font-size: 12px;
            transform: translate(0, 6px);
            font-weight: 400;
          }
        }
      }
    }

    .brand-img {
      width: 273px;
    }

    .reset-img {
      width: 365px;
    }

    .sent-img {
      width: 245px;
    }

    .setpass-img {
      width: 313px;
    }
  }

  @media screen and (min-width: 1200px) {
    padding: 40px 123px 44px 123px;

    .center-div {
      height: 90%;
    }

    .welcome-heading {
      margin-bottom: 40px;
      font-weight: 600;
      font-size: 28px;
      line-height: 40px;
    }

    .fields {
      width: 488px;
      margin-bottom: 16px;
    }

    .submit-button {
      width: 168px;
      margin-top: 40px;
    }

    .forgotButton {
      margin-top: 40px;
    }

    .password-div {
      width: 488px;
    }

    .sub {
      margin: -35px 0 40px;
    }

    .terms {
      max-width: 488px;
    }

    .success-div {
      margin-top: 16px;
      max-width: 488px;
    }

    .primaryBackButton {
      margin-bottom: 40px;
    }

    .brand-img {
      width: 400px;
    }

    .reset-img {
      width: 536px;
    }

    .sent-img {
      width: 360px;
    }

    .setpass-img {
      width: 460px;
    }
  }
  @media screen and (min-width: 1900px) {
    padding: 52px 224px 69px 224px;
    h2 {
      font-size: 36px;
    }
    .success-div {
      margin-top: 16px;
      max-width: 602px;
    }
    .sub {
      font-size: 20px;
    }
    .fields {
      width: 602px;
      margin-bottom: 20px;
    }
    .welcome-heading {
      font-size: 36px;
      line-height: 52px;
    }
    .submit-button {
      width: 229px;
      height: 64px;
      margin-top: 60px;
    }
    .logo-svg {
      height: 69px;
    }
    .terms {
      font-size: 16px;
    }
    .password-div {
      height: 64px;
      width: 602px;
    }

    .toggleButton {
      font-size: 16px;
      top: 30%;
      right: 5%;
    }
    .eye {
      width: 25px;
      margin-right: 10px;
    }

    .brand-img {
      width: 563px;
    }

    .reset-img {
      width: 754px;
    }

    .sent-img {
      width: 506px;
    }

    .setpass-img {
      width: 647px;
    }
  }

  @media screen and (max-width: 850px) {
    .brand-img,
    .sent-img {
      display: none;
    }
  }

  @media screen and (max-width: 944px) {
    .reset-img,
    .setpass-img {
      display: none;
    }
  }
`;
