import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { AppDispatch } from '../../store';
import {
  createAssoasiatedContact,
  ContactsSliceState,
} from '../../store/contacts/contactsSlice';
import { addAccountDetails } from '../../store/accounts/accountsSlice';
import { addOpportunityDetails } from '../../store/opportunities/opportunitiesSlice';
import { addPolicyDetails } from '../../store/policies/policiesSlice';
import InputField from '../../components/InputField';
import PhoneNumberInput from '../../components/PhoneNumberFormat';
import SuccessCard from '../../components/SuccessCard';
import { StyledAddModal } from '../../pages/MyBook/elements/styles';
import { SuccessAlign } from '../../styles/SuccessAlign';
import { fetchJurisdiction } from '../../api/jurisdiction';
import cross from '../../assets/logo/cross.svg';
import warning from '../../assets/logo/warning.svg';
import tick from '../../assets/logo/tick.svg';
import SearchDropdown from '../SearchDropdown';
import { fetchContacts } from '../../api/contact';
import { useNavigate } from 'react-router-dom';
import MarkdownEditor from '../MarkdownEditor';
import { FormModal } from '../../styles/FormModal';
import DateField from '../DateField';
import SelectField from '../SelectField';
import { IJurisdiction } from '../../interfaces/Jurisdiction';

const SignupSchema = Yup.object().shape({
  lastName: Yup.string().required('Last name is required'),
  phoneNumber: Yup.string()
    .required('Phone Number is required')
    .matches(
      /[(][0-9]{3}[)][-\s][0-9]{3}[-][0-9]{4}$/,
      'Phone number is not valid'
    ),
  email: Yup.string()
    .email('Invalid email. Please try again')
    .required('Please enter your email '),
});

const ContactForm = ({
  uuid,
  show,
  closeModal,
  resource,
}: {
  show: string;
  uuid: string;
  resource: string;
  closeModal: Function;
}) => {
  const dispatch = useDispatch<AppDispatch>();

  const {
    register,
    handleSubmit,
    reset,
    watch,
    control,
    formState: { errors },
    getValues,
  } = useForm({
    resolver: yupResolver(SignupSchema),
  });

  const [showSuccess, setshowSuccess] = useState(false);
  const watchAllFields = watch();
  const [errorMessage, setError] = useState('');
  const [jurisdictionSearch, setJurisdictionSearch] = useState('');
  const [jurisdictionsUUID, setJurisdictionsUUID] = useState('');
  const navigate = useNavigate();

  const [emailAccounts, setEmailAccounts] = useState<any[]>([]);
  const [emailSearch, setEmailSearch] = useState('');
  const [emailError, setEmailError] = useState(false);
  const [emailData, setEmailData] = useState<any>([]);
  const [licensedState, setLicensedState] = useState<IJurisdiction[]>([]);
  const [loadingStatus, setLoadingStatus] = useState('idle');
  const [submit, setSubmit] = useState(false);
  const watchLastName = watch('lastName');
  const [disabled, setDisabled] = useState(false);
  const MaritalStatus = [
    { label: 'Married', value: 'Married' },
    { label: 'Single', value: 'Single' },
  ];
  const loadEmail = (value: string) => {
    setEmailError(false);
    if (loadingStatus === 'idle' || loadingStatus === 'succeeded') {
      fetchContacts('id', 'asc', emailSearch, 25, 0, 'True', (data: any) => {
        setEmailAccounts(data);
        setLoadingStatus('succeeded');
      });
    }
  };

  const onCloseModal = () => {
    closeModal('closing');
    setTimeout(() => {
      closeModal('closed');
    }, 300);
  };
  const loading = useSelector(
    (state: { contacts: ContactsSliceState }) => state.contacts.isLoading
  );

  const onSubmit = (value: any) => {
    const emailExist = emailAccounts.find(
      (item) =>
        item?.ContactDetails?.find((value: any) => value.type === 'EMAIL')
          ?.value === emailSearch
    );
    let errorLastName = true;
    if (emailExist) {
      setEmailError(true);
      setEmailData([
        `${emailExist?.firstName} ${emailExist?.lastName}`,
        emailExist?.uuid,
      ]);
    } else {
      value.notes = notes;
      value.maritalStatus = watchAllFields.maritalStatus
        ? watchAllFields.maritalStatus.value
        : '';
      value.jurisdictionUUID = jurisdictionsUUID;
      if (resource === 'Account') {
        value.accountUUID = uuid;
      } else if (resource === 'Opportunity') {
        value.opportunityUUID = uuid;
      } else if (resource === 'Policy') {
        value.policyUUID = uuid;
      }
      if (errorLastName === true) {
        dispatch(
          createAssoasiatedContact({
            data: value,
            successCB: (response: any) => {
              if (resource === 'Account') {
                dispatch(addAccountDetails({ response: response.data }));
              } else if (resource === 'Opportunity') {
                dispatch(addOpportunityDetails(response.data));
              } else if (resource === 'Policy') {
                dispatch(addPolicyDetails(response.data));
              }
              closeModal('closing');
              setTimeout(() => {
                setshowSuccess(true);
              }, 300);
              setTimeout(() => {
                reset();
                setshowSuccess(false);

                setTimeout(() => {
                  closeModal('closed');
                }, 300);
              }, 2000);
            },
            errorCB: (error: string) => {
              setDisabled(false);
              if (typeof error === 'string') setError(error);
            },
          })
        );
      }
    }
  };
  const resetEmail = (value: string) => {
    setEmailData([]);
    setEmailError(false);
  };

  const [notes, setNotes] = useState<any>(['']);
  const [isManual, setIsManual] = useState(false);

  const onSetNotesValue = (value: any, index: number) => {
    let updatedNotes = [...notes];
    updatedNotes[index] = value;
    setNotes(updatedNotes);
  };
  const loadLicensedState = (value: string) => {
    if (loadingStatus === 'idle' || loadingStatus === 'succeeded') {
      setLoadingStatus('loading');
      fetchJurisdiction('name', 'asc', value, (data: IJurisdiction[]) => {
        setLicensedState(data);
        setLoadingStatus('succeeded');
      });
    }
  };

  useEffect(() => {
    setError('');
  }, [watchAllFields.phoneNumber]);

  return (
    <>
      <SuccessAlign>
        <div className={`success-container ${showSuccess && 'open-drawer'} `}>
          <SuccessCard
            title={'Success adding contact'}
            message={`<b>${getValues('firstName')} ${getValues(
              'lastName'
            )}</b> has been added as contact<b>`}
          />
          <button
            onClick={() => {
              reset();
              setshowSuccess(false);
            }}
            className='crossbutton'
          >
            <img src={cross} className='cross' alt='cross-icon' />
          </button>
        </div>
      </SuccessAlign>

      <FormModal
        open={show}
        align='right'
        containerClassName='fullwidth-container'
      >
        <StyledAddModal>
          <div className='entire-div'>
            <div className='form-title'>
              <h2>Add contact</h2>
            </div>
            <button className='cross-btn'>
              <img
                className='cross'
                src={cross}
                alt='cross-icon'
                onClick={() => {
                  reset();
                  onCloseModal();
                }}
              />
            </button>

            <form onSubmit={handleSubmit(onSubmit)}>
              <div className='scroll-bar  '>
                <div></div>
                <div>
                  <h2 className='contact-info'>Contact Info</h2>
                </div>
                <div className='top-field d-sm-flex justify-content-between'>
                  <div className='fields'>
                    <InputField
                      name='firstName'
                      type='text'
                      placeholder='First name'
                      register={register}
                      className={`${errors.firstName && 'error-field'} ${
                        watchAllFields.firstName && 'filled'
                      }`}
                    />
                    {errors.firstName ? (
                      <div className='errorVal'>
                        <img src={warning} alt='warning' className='warning' />
                        {errors.firstName?.message}
                      </div>
                    ) : null}
                  </div>
                  <div className='small-div d-sm-flex justify-content-between'>
                    <div className='fields-small'>
                      <InputField
                        name='middleName'
                        type='text'
                        placeholder='Middle name'
                        register={register}
                        className={`${errors.middleName && 'error-field'} ${
                          watchAllFields.middleName && 'filled'
                        }`}
                      />
                      {errors.middleName ? (
                        <div className='errorVal'>
                          <img
                            src={warning}
                            alt='warning'
                            className='warning'
                          />
                          {errors.middleName?.message}
                        </div>
                      ) : null}
                    </div>
                    <div className='fields-small'>
                      <InputField
                        name='lastName'
                        type='text'
                        placeholder='Last name'
                        register={register}
                        required
                        className={`${errors.lastName && 'error-field'} ${
                          watchAllFields.lastName && 'filled'
                        }`}
                      />
                      {errors.lastName || (!watchLastName && submit) ? (
                        <div className='errorVal'>
                          <img
                            src={warning}
                            alt='warning'
                            className='warning'
                          />
                          {errors.lastName?.message || 'Last name is required'}
                        </div>
                      ) : null}
                    </div>
                  </div>
                </div>
                <div className='d-sm-flex justify-content-between'>
                  <div className='fields'>
                    <SearchDropdown
                      name='email'
                      addName={true}
                      placeholder='Email'
                      isEdit={true}
                      values={emailSearch}
                      searchValue={emailAccounts}
                      setSearch={setEmailSearch}
                      status={loadingStatus}
                      className={`${emailSearch && 'filled'}`}
                      register={register}
                      control={control}
                      type='email'
                      required
                      setUUID={resetEmail}
                      loadFunction={loadEmail}
                      content={emailAccounts?.map((item) => (
                        <div
                          className='searchValuesCard d-flex align-items-center'
                          onClick={() => {
                            setEmailSearch(
                              item?.ContactDetails?.find(
                                (value: any) => value.type === 'EMAIL'
                              )?.value
                            );
                            setEmailError(true);
                            setEmailData([
                              `${item?.firstName} ${item?.lastName}`,
                              item?.uuid,
                            ]);
                          }}
                        >
                          <div className='profile-pic d-flex align-items-center justify-content-center'>
                            {item?.firstName?.charAt(0)}
                          </div>
                          <div>
                            <h6>
                              {item?.firstName} {item?.lastName}
                            </h6>
                            <ul className='user-details'>
                              <li>
                                {
                                  item?.ContactDetails?.find(
                                    (value: any) => value.type === 'EMAIL'
                                  )?.value
                                }
                              </li>
                              <li>
                                {
                                  item?.ContactDetails?.find(
                                    (value: any) => value.type === 'PHONENUMBER'
                                  )?.value
                                }
                              </li>
                            </ul>
                          </div>
                        </div>
                      ))}
                    />
                    {errors.email && !emailSearch ? (
                      <div className='errorVal'>
                        <img src={warning} alt='warning' className='warning' />
                        {errors.email?.message}
                      </div>
                    ) : emailError ? (
                      <div className='errorVal emailError'>
                        <img src={warning} alt='warning' className='warning' />
                        <div className='email-exist-error'>
                          This email is already associated with another contact{' '}
                          <span
                            onClick={() =>
                              navigate(`/contacts/${emailData[1]}`)
                            }
                          >
                            {emailData[0]}
                          </span>{' '}
                        </div>
                      </div>
                    ) : (
                      !emailSearch &&
                      submit && (
                        <div className='errorVal'>
                          <img
                            src={warning}
                            alt='warning'
                            className='warning'
                          />
                          Please enter your email
                        </div>
                      )
                    )}
                  </div>
                  <div className='fields'>
                    <PhoneNumberInput
                      name='phoneNumber'
                      type='tel'
                      placeholder='Phone number'
                      control={control}
                      required
                      className={`${
                        (errors.phoneNumber || errorMessage) && 'error-field'
                      } ${watchAllFields.phoneNumber && 'filled'}`}
                    />
                    {errors.phoneNumber || errorMessage ? (
                      <div className='errorVal'>
                        <img src={warning} alt='warning' className='warning' />
                        {errors.phoneNumber?.message || errorMessage}
                      </div>
                    ) : null}
                  </div>
                </div>
                <div className='d-sm-flex justify-content-between'>
                  <div className='fields dateField '>
                    <DateField
                      name='dateOfBirth'
                      placeholder='Date Of Birth'
                      maxDate={new Date()}
                      dateOfBirth
                      control={control}
                      value={watchAllFields.dateOfBirth}
                    />
                  </div>
                  <div className='fields'>
                    <SelectField
                      options={MaritalStatus}
                      name='maritalStatus'
                      placeholder='Marital status'
                      control={control}
                    />
                  </div>
                </div>
                <div className='d-sm-flex justify-content-between'>
                  <div className='fields'>
                    <SearchDropdown
                      name='jurisdictionUUID'
                      placeholder='Licensed state'
                      isEdit={true}
                      values={jurisdictionSearch}
                      searchValue={licensedState}
                      setSearch={setJurisdictionSearch}
                      status={loadingStatus}
                      className={`${jurisdictionSearch && 'filled'}`}
                      register={register}
                      control={control}
                      loadFunction={loadLicensedState}
                      setUUID={setJurisdictionsUUID}
                      content={
                        licensedState.length > 0
                          ? licensedState?.map((item) => (
                              <div
                                className='searchValuesCard d-flex align-items-center'
                                onClick={() => {
                                  setJurisdictionSearch(item?.name);
                                  setJurisdictionsUUID(item?.uuid);
                                }}
                              >
                                <div>
                                  <h6>{item.name}</h6>
                                </div>
                              </div>
                            ))
                          : null
                      }
                    />
                  </div>
                  <div className='fields'>
                    <InputField
                      name='driverlicenseNumber'
                      type='text'
                      placeholder='License number'
                      register={register}
                      className={`${
                        errors.driverlicenseNumber && 'error-field'
                      } ${watchAllFields.driverlicenseNumber && 'filled'}`}
                    />
                  </div>
                </div>

                <div>
                  {notes &&
                    notes?.map((item: any, index: number) => {
                      return (
                        <div className='d-flex align-items-center descriptionField  col'>
                          <div className='textarea'>
                            <MarkdownEditor
                              placeholder='Add notes here'
                              note={item}
                              multi={true}
                              index={index}
                              setNote={onSetNotesValue}
                            />
                          </div>
                        </div>
                      );
                    })}
                </div>
              </div>

              <div className='buttonsDiv d-flex align-items-center '>
                <button
                  onClick={() => {
                    reset();
                    onCloseModal();
                  }}
                  className='cancel-button'
                  type='button'
                >
                  Cancel
                </button>
                <button
                  type='submit'
                  className='saveButton'
                  //    disabled={loading || show === 'closing'}
                  onClick={() => {
                    setSubmit(true);
                  }}
                >
                  Save <img className='tick' src={tick} alt='tick-sign' />
                </button>
              </div>
            </form>
          </div>
        </StyledAddModal>
      </FormModal>
    </>
  );
};

export default ContactForm;
