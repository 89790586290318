import { useState, useRef } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { selectPolicyCount } from '../../../store/policies/policiesSlice';
import Modal from '../../../components/Modal';
import Link from '../../../components/Link';
import AddQuoteModal from '../../../components/AddQuoteModal';
import PolicyDetailsDrawer from './PolicyDetailsDrawer';
import DetailsPageTab from '../../../components/DetailsPageTab';
import DetailsPageTabMobile from '../../../components/DetailsPageTabMobile';
import { useOutsideClick } from '../../../hooks/useOutsideClick';
import { IPolicy } from '../../../interfaces/Policy';
import { MobileDetailsDiv } from '../../../styles/DetailsDiv';
import { StyledTab } from '../../../styles/StyledTab';
import ham from '../../../assets/logo/hamburger.png';
import cross from '../../../assets/logo/cross.svg';
import dropdown from '../../../assets/logo/dropdown.svg';
import contact from '../../../assets/logo/contact.png';
import eye from '../../../assets/logo/eye.svg';
import home from '../../../assets/logo/home.svg';
import moment from 'moment';

const MobilePolicyDetails = ({
  policyDetails,
  setShowEditModal,
  setShowLinkContactModal,
  setShowAddContactModal,
}: {
  policyDetails: IPolicy;
  setShowEditModal: Function;
  setShowLinkContactModal: Function;
  setShowAddContactModal: Function;
}) => {
  const [showDetailsDrawer, setShowDetailsDrawer] = useState('closed');
  const [activity, setActivity] = useState('activities');
  const { uuid } = useParams();
  const [showDropdown, setShowDropdown] = useState(false);
  const [showQuoteModal, setShowQuoteModal] = useState('closed');

  const PolicyCount = useSelector(selectPolicyCount);
  const navigate = useNavigate();
  const policyRef = useRef(null);
  useOutsideClick(policyRef, setShowDropdown);

  return (
    <MobileDetailsDiv>
      <div className='mobile-topbar d-flex align-items-center justify-content-between'>
        <div className='d-flex align-items-center'>
          <button
            onClick={() => setShowDetailsDrawer('open')}
            className='secondaryButton'
          >
            <img alt='' src={ham} />
          </button>
          <div className='profile-picdiv d-none d-sm-flex align-items-center justify-content-center'>
            <img className='home-img' src={home} alt='' />
          </div>
          <span>
            {' '}
            <h2>
              {' '}
              #
              {policyDetails?.policyNumber
                ? policyDetails?.policyNumber
                : '--'}{' '}
              |{' '}
              {policyDetails?.Product?.name
                ? policyDetails?.Product?.name
                : policyDetails?.CustomProduct?.name
                ? policyDetails?.CustomProduct?.name
                : '--'}
            </h2>
          </span>
          {moment().isBefore(
            moment(policyDetails?.effectiveDate).format('YYYY-MM-DD'),
            'day'
          ) && <span className='status statusNotInvited'>{'Inactive'}</span>}
          {moment().isBetween(
            moment(policyDetails?.effectiveDate).format('YYYY-MM-DD'),
            moment(policyDetails?.expiryDate).format('YYYY-MM-DD')
          ) && <span className='status statusPolicy'>{'Active'}</span>}
          {moment(
            moment(policyDetails?.expiryDate).format('YYYY-MM-DD')
          ).isBefore(moment().format('YYYY-MM-DD'), 'day') && (
            <span className='status statusInactive'>{'Expired'}</span>
          )}
        </div>
        <div className='d-flex align-items-center'>
          <button className='policy-button d-none d-sm-block'>
            View signed document
          </button>
          <button
            className='quote-button d-none d-sm-block'
            onClick={() => setShowQuoteModal('open')}
          >
            Quick quote
          </button>
          <button className='dropDownButton d-none d-sm-block'>
            <img src={dropdown} alt='' />
          </button>
          <button
            onClick={() => {
              navigate('/mybook/policies');
            }}
            className='secondaryButton'
          >
            <img src={cross} className='cross' alt='cross-icon' />
          </button>
        </div>
      </div>
      {showQuoteModal !== 'closed' && (
        <AddQuoteModal
          show={showQuoteModal}
          closeModal={setShowQuoteModal}
          type='Contact'
          page='quickQuote'
        />
      )}
      <StyledTab className='d-sm-none'>
        <div className='ActivityName' ref={policyRef}>
          <Link
            to={`/policy/${uuid}/${activity}`}
            onClick={(e) => {
              setShowDropdown(!showDropdown);
            }}
          >
            {activity.toLowerCase()}
            {activity !== 'histories' && (
              <span className='count'>{PolicyCount[activity]}</span>
            )}
            <img src={dropdown} alt='' />
          </Link>
          {showDropdown && (
            <DetailsPageTabMobile
              name='Policy'
              setActivity={setActivity}
              setShowDropdown={setShowDropdown}
            />
          )}
        </div>
      </StyledTab>
      <DetailsPageTab name='Policy' isMobile={true} />
      <div className='mobile-bottombar d-flex d-sm-none align-items-center justify-content-between'>
        <button className='dropDownButton'>
          <img src={dropdown} alt='' />
        </button>
        <button className='dropDownButton'>
          <img src={eye} alt='' />
        </button>
        <button
          className='primaryButton w-75'
          onClick={() => setShowQuoteModal('open')}
        >
          Quick quote
        </button>
      </div>
      {showDetailsDrawer !== 'closed' && (
        <Modal open={showDetailsDrawer} align='left'>
          <button
            onClick={() => {
              setShowDetailsDrawer('closing');
              setTimeout(() => {
                setShowDetailsDrawer('closed');
              }, 300);
            }}
            className='crossbutton secondaryButton'
          >
            <img src={cross} className='cross' alt='cross-icon' />
          </button>
          <div className='modal-content-div'>
            <div className='header-div d-flex align-items-center'>
              <div className='profile-picdiv d-flex align-items-center justify-content-center'>
                <img src={home} alt='' />
                {/* {policyDetails?.name
                  ?.split(' ')
                  .map((item: string) => item.substring(0, 1))
                  .join('')} */}
                {/* TODO : Change to assets */}
              </div>
              <div>
                <span>
                  <h2 className='mb-1'>
                    #
                    {policyDetails?.policyNumber
                      ? policyDetails?.policyNumber
                      : '--'}{' '}
                    |{' '}
                    {policyDetails?.Product?.name
                      ? policyDetails?.Product?.name
                      : policyDetails?.CustomProduct?.name
                      ? policyDetails?.CustomProduct?.name
                      : '--'}
                  </h2>
                </span>
                <div className='header-info'>
                  <span className='d-flex align-items-center'>
                    <img src={contact} alt='' className='contact-img' />

                    <span
                      className='nav-decor'
                      onClick={() =>
                        navigate(`/account/${policyDetails.Accounts.uuid}`)
                      }
                    >
                      {policyDetails?.Accounts?.name}
                    </span>
                  </span>
                </div>
              </div>
            </div>

            <div className='details-section d-flex justify-content-between'>
              <h5 className='section-title '>Premium</h5>
              <span className='amount'>
                $
                {policyDetails?.premium
                  ? policyDetails?.premium.toLocaleString('en-US')
                  : 0}
              </span>{' '}
            </div>

            <div className='details-section d-flex justify-content-between'>
              <h5 className='section-title '>Contact</h5>
              <span
                className='nav-decor'
                onClick={() =>
                  navigate(`/contacts/${policyDetails?.Contacts[0]?.uuid}`)
                }
              >
                {policyDetails.Contacts[0]?.firstName}{' '}
                {policyDetails.Contacts[0]?.lastName}
                {policyDetails.Contacts.length > 1 && (
                  <span className='count-div'>
                    +{policyDetails?.Accounts?.AccountContacts?.length - 1}
                  </span>
                )}
              </span>
            </div>

            <PolicyDetailsDrawer
              policyDetails={policyDetails}
              setShowEditModal={setShowEditModal}
              setShowLinkContactModal={setShowLinkContactModal}
              setShowAddContactModal={setShowAddContactModal}
            />
          </div>
        </Modal>
      )}
    </MobileDetailsDiv>
  );
};

export default MobilePolicyDetails;
