import { TimePickerDiv } from './styles';
import dropDown from '../../assets/logo/dropdown.svg';
import { useEffect, useRef, useState } from 'react';
import moment from 'moment';
import { useOutsideClick } from '../../hooks/useOutsideClick';

const TimePicker = ({
  setTaskDueTime,
  setDueTime,
  innerTab,
  dueTime,
}: {
  setTaskDueTime: Function;
  setDueTime: Function;
  innerTab?: boolean;
  dueTime: string;
}) => {
  const dropRef = useRef(null);
  useOutsideClick(dropRef, setTaskDueTime);
  const hours = [
    `01`,
    `02`,
    `03`,
    `04`,
    `05`,
    `06`,
    `07`,
    `08`,
    `09`,
    `10`,
    `11`,
    `12`,
  ];
  const minutes = [];
  for (let i = 0; i < 60; i++) {
    if (i < 10) {
      minutes.push(`0${i}`);
    } else {
      minutes.push(`${i}`);
    }
  }
  const ampm = ['AM', 'PM'];
  const [AMPM, setAMPM] = useState('');
  const [Hour, setHour] = useState('');
  const [Minute, setMinute] = useState('');
  const [showAMPM, setShowAMPM] = useState(false);
  const [showHour, setShowHour] = useState(false);
  const [showMinute, setShowMinute] = useState(false);
  const [convertedTime, setConvertedTime] = useState<any>();

  const convertTime = (timeStr: any) => {
    const [selectedTime, modifier] = timeStr.split(' ');
    let [hours, minutes] = selectedTime.split(':');
    if (hours === '12') {
      hours = '00';
    }
    if (modifier === 'PM') {
      hours = parseInt(hours, 10) + 12;
    }
    return setConvertedTime(`${hours}:${minutes}`);
  };

  const updateTime = (
    {
      hours,
      minutes,
      ampm,
    }: {
      hours?: string;
      minutes?: string;
      ampm?: string;
    } = {
      hours: Hour,
      minutes: Minute,
      ampm: AMPM,
    }
  ) => {
    let hour = hours;
    if (!hours) {
      hour = '12';
    }
    if (ampm === 'PM') {
      convertTime(`${hour}:${minutes} ${ampm}`);
    } else {
      setConvertedTime(`${hour}:${minutes} AM`);
    }
  };

  useEffect(() => {
    if (!dueTime) {
      setDueTime(
        moment(
          moment('2022-10-12').format('YYYY-MM-DD') +
            moment(`${convertedTime}`, ['h:mm A']).format('HH:mm A'),
          'YYYY-MM-DDLT'
        ).toDate()
      );
    } else {
      if (convertedTime) {
        setDueTime(
          moment(
            moment('2022-10-12').format('YYYY-MM-DD') +
              moment(`${convertedTime}`, ['h:mm A']).format('HH:mm A'),
            'YYYY-MM-DDLT'
          ).toDate()
        );
      } else {
        setHour(
          `${
            new Date(dueTime).getHours() % 12 === 0
              ? 12
              : new Date(dueTime).getHours() % 12
          }`
        );
        setAMPM(new Date(dueTime).getHours() > 11 ? 'PM' : 'AM');
        setMinute(`${new Date(dueTime).getMinutes()}`.padStart(2, '0'));
      }
    }
  }, [convertedTime]);

  return (
    <TimePickerDiv>
      <div
        className={`${
          innerTab ? 'innerTabPicker' : 'pickerModalContainer'
        } d-flex justify-content-between align-items-center`}
      >
        <div className='timeField '>
          <div
            className='fieldDiv'
            onClick={() => {
              setShowAMPM(false);
              setShowMinute(false);
              setShowHour(!showHour);
            }}
          >
            {Hour ? Hour : 'HH'}
            <img
              src={dropDown}
              alt=''
              className={`${showHour && 'inverted'}`}
            />
            <div className={showHour ? 'dropDownList' : 'd-none'}>
              {hours?.map((item: string) => (
                <>
                  <ul
                    onClick={() => {
                      setHour(item);
                      updateTime({ hours: item, minutes: Minute, ampm: AMPM });
                    }}
                  >
                    {item}
                  </ul>
                  <div className='borderDiv'></div>
                </>
              ))}
            </div>
          </div>
        </div>
        <div className='timeField'>
          <div
            className='fieldDiv'
            onClick={() => {
              setShowAMPM(false);
              setShowMinute(!showMinute);
              setShowHour(false);
            }}
          >
            {Minute ? Minute : 'MM '}
            <img
              src={dropDown}
              alt=''
              className={`${showMinute && 'inverted'}`}
            />
            <div className={showMinute ? 'dropDownList' : 'd-none'}>
              {minutes?.map((item: string) => (
                <>
                  <ul
                    onClick={() => {
                      setMinute(item);
                      updateTime({
                        hours: Hour,
                        minutes: item,
                        ampm: AMPM,
                      });
                    }}
                  >
                    {item}
                  </ul>
                  <div className='borderDiv'></div>
                </>
              ))}
            </div>
          </div>
        </div>
        <div className='timeField'>
          <div
            className='fieldDiv'
            onClick={() => {
              setShowAMPM(!showAMPM);
              setShowMinute(false);
              setShowHour(false);
            }}
          >
            {AMPM ? AMPM : 'Period'}
            <img
              src={dropDown}
              alt=''
              className={`${showAMPM && 'inverted'}`}
            />
            <div
              className={showAMPM ? 'dropDownList dropDownListAmPm' : 'd-none'}
            >
              {ampm?.map((item: string) => (
                <>
                  <ul
                    onClick={() => {
                      setAMPM(item);
                      updateTime({
                        hours: Hour,
                        minutes: Minute,
                        ampm: item,
                      });
                    }}
                  >
                    {item}
                  </ul>
                  <div className='borderDiv'></div>
                </>
              ))}
            </div>
          </div>
        </div>
      </div>
    </TimePickerDiv>
  );
};

export default TimePicker;
