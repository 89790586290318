import { useNavigate } from 'react-router-dom';

const ErrorContainer = ({
  code,
  title,
  description,
  illustration,
  navigateTo,
}: {
  code?: string;
  title: string;
  description: string;
  illustration: string;
  navigateTo: 'home' | 'reload';
}) => {
  const navigate = useNavigate();
  return (
    <>
      <div className='error-content'>
        {code && <div className='error-code'>ERROR {code}</div>}
        <h1>{title}</h1>
        <div className='description'>{description}</div>
        {/* TODO : Resend link functionality */}
        {title !== 'Verification link has expired!' && (
          <button
            className='primaryButton'
            onClick={() => {
              if (navigateTo === 'home') {
                navigate('/');
              } else {
                navigate(0);
              }
            }}
          >
            {navigateTo === 'home'
              ? code
                ? 'Back to home'
                : 'Log in to your account'
              : code
              ? 'Reload page'
              : 'Resend Link'}
          </button>
        )}
      </div>
      <div className='illustration d-flex align-items-center justify-content-center'>
        <img src={illustration} alt='clouds' />
      </div>
    </>
  );
};

export default ErrorContainer;
