import axios from './axios';
import {
  IOpportunity,
  IOption,
  IPipelineStage,
} from '../interfaces/Opportunity';

const fetchOpportunities = (
  sortBy: string,
  orderBy: string,
  searchBy: string,
  pipelineStageUUID: string,
  pipelineUUID: string,
  successCB: Function,
  opportunityStatusUUID: string,
  productCategoryUUID: string,
  limit: number,
  offset: number,
  accountUUID?: string,
  producerUUID?: string,
  productUUID?: string,
  customProductUUID?: string
) => {
  return axios
    .get('/opportunities', {
      params: {
        sortBy,
        searchBy,
        orderBy,
        pipelineStageUUID,
        pipelineUUID,
        opportunityStatusUUID,
        productCategoryUUID,
        limit,
        offset,
        accountUUID,
        producerUUID,
        productUUID,
        customProductUUID,
      },
    })
    .then((res) => {
      successCB(res.data);
      return res;
    });
};

const addOpportunity = (data: any, successCB: Function, errorCB: Function) => {
  return axios.post('/opportunities', data).then(
    (res) => {
      successCB(res);
      return res;
    },
    (error) => {
      errorCB(error?.response?.data.error);
      return error.res;
    }
  );
};

const getOpportunityStatus = (
  successCB: Function,
  sortBy?: string,
  orderBy?: string,
  searchBy?: string,
  agencyUUID?: string
) => {
  return axios
    .get(`/opportunities/opportunityStatus`, {
      params: { sortBy, searchBy, orderBy, agencyUUID },
    })
    .then((res) => {
      successCB(res.data);
      return res;
    });
};

const getOpportunityStatusDetail = (uuid: string, successCB: Function) => {
  return axios.get(`/opportunities/opportunityStatus/${uuid}`).then((res) => {
    successCB(res.data);
    return res;
  });
};

const fetchOpportunityDetails = (uuid?: string) => {
  return axios.get(`/opportunities/${uuid}`).then(
    (res) => res,
    (error) => error.response
  );
};

const UpdateOpportunityConatcts = (data: IOpportunity[], uuid: string) => {
  return axios.patch(`/opportunities/${uuid}/contact`, data).then(
    (res) => res,
    (error) => error.response
  );
};

const updateOpportunityDetails = (
  data: IOpportunity,
  uuid: string,
  successCB: Function,
  errorCB: Function
) => {
  return axios.patch(`/opportunities/${uuid}`, data).then(
    (res) => {
      successCB(res);
      return res;
    },
    (error) => {
      errorCB('Please try again.');
      return error.res;
    }
  );
};

const fetchPipelines = (
  sortBy: string,
  orderBy: string,
  searchBy: string,
  agencyUUID?: string,
  productCategoryUUID?: string,
  showAll?: boolean,
  pipelineStatus?: string,
  productUUID?: string,
  customProductUUID?: string
) => {
  let params: {
    sortBy: string;
    orderBy: string;
    searchBy: string;
    agencyUUID?: string;
    productCategoryUUID?: string;
    showAll?: boolean;
    pipelineStatus?: string;
    productUUID?: string;
    customProductUUID?: string;
  } = {
    sortBy,
    searchBy,
    orderBy,
    agencyUUID,
    productCategoryUUID,
    pipelineStatus,
    productUUID,
    customProductUUID,
  };
  if (showAll) {
    params = {
      ...params,
      showAll,
    };
  }
  return axios.get('/pipeline', {
    params: params,
  });
};

const getStageOpportuntities = async (
  sortBy: string,
  searchBy: string,
  orderBy: string,
  stages: IPipelineStage[],
  opportunityStatusUUID: string,
  productCategoryUUID: string,
  pipelineStatus: string,
  selectedStages: IOption[]
) =>
  await Promise.all(
    stages.map(async (stage) => {
      if (
        selectedStages.length === 0 ||
        selectedStages.find((item) => item.value === stage.uuid)
      ) {
        const response = await axios.get('/opportunities', {
          params: {
            sortBy,
            searchBy,
            orderBy,
            pipelineStageUUID: stage.uuid,
            pipelineUUID: '',
            opportunityStatusUUID,
            productCategoryUUID,
            limit: 25,
            offset: 0,
            pipelineStatus,
          },
        });
        return await response.data;
      } else {
        return { opportunities: [], count: 0, sum: 0 };
      }
    })
  );

const updateStage = (
  data: {
    action: string;
    pipelineStageUUID: string;
    reason?: string;
    xDate?: string;
  },
  uuid: string,
  successCB: Function
) => {
  return axios.patch(`/opportunities/${uuid}`, data).then((res) => {
    successCB(res.data);
    return res;
  });
};

const opportunitiesDetailsCount = (opportunityUUID: string) => {
  return axios
    .get(`/user/mybook/count`, { params: { opportunityUUID } })
    .then((res) => {
      return res;
    });
};

const deleteOpportunity = (uuid: String) => {
  return axios.delete(`/opportunities/${uuid}`);
};

export {
  fetchOpportunities,
  addOpportunity,
  getOpportunityStatus,
  fetchOpportunityDetails,
  updateOpportunityDetails,
  fetchPipelines,
  getOpportunityStatusDetail,
  getStageOpportuntities,
  updateStage,
  opportunitiesDetailsCount,
  UpdateOpportunityConatcts,
  deleteOpportunity,
};
