import { useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { CardDiv } from '../../../../styles/CardDiv';
import vector from '../../../../assets/logo/dropdown.svg';
import bin from '../../../../assets/logo/bin.svg';
import edit from '../../../../assets/logo/edit.svg';
import { useOutsideClick } from '../../../../hooks/useOutsideClick';
import { IPolicy } from '../../../../interfaces/Policy';
import Moment from 'react-moment';
const PolicyCard = ({ policy }: { policy: IPolicy }) => {
  const [showDropdown, setShowDropdown] = useState(false);
  const navigate = useNavigate();

  const dropRef = useRef(null);

  useOutsideClick(dropRef, setShowDropdown);
  return (
    <CardDiv>
      <td className='row-name name-td'>
        <b
          onClick={() => {
            navigate(`/policy/${policy.uuid}`);
          }}
        >
          {policy.Accounts.name}
        </b>
      </td>
      <td>
        {policy?.ProductCategory?.name ? policy?.ProductCategory?.name : '-'}
      </td>
      <td>{policy.Issuer.Carrier.name}</td>
      <td>
        {' '}
        <Moment format='MM/DD/YYYY'>{policy.effectiveDate}</Moment>
      </td>
      <td>
        {' '}
        <Moment format='MM/DD/YYYY'>{policy.createdAt}</Moment>
      </td>

      <td className='d-none d-xl-table-cell'>
        {policy.Agents?.User.avatar ? (
          <img
            alt='profilepic'
            className='user-pic'
            src={policy.Agents?.User.avatar}
          />
        ) : (
          <div className='user-pic'>
            {policy.Agents.User.firstName?.substring(0, 1).toUpperCase()}
            {policy.Agents.User.lastName?.substring(0, 1).toUpperCase()}
          </div>
        )}
      </td>

      <td className='d-none d-xl-table-cell'>
        <div style={{ fontWeight: 600 }}>
          ${policy.premium ? policy.premium : 0}
        </div>
      </td>
      <td className='actionField'>
        <div className='d-flex align-items-center justify-content-between'>
          <div className='actionButton'>View details</div>
          <div
            className='vector'
            onClick={() => setShowDropdown(!showDropdown)}
            ref={dropRef}
          >
            <img src={vector} alt='vector' />
          </div>

          {showDropdown && (
            <div className='dropdown-div'>
              <div className='edit-div'>
                Edit <img src={edit} alt='bin' />
              </div>
              <div className='delete-div'>
                Delete <img src={bin} alt='bin' />
              </div>
            </div>
          )}
        </div>
        {/* {showEditModal !== 'closed' && (
          <EditOpportunityModal
            show={showEditModal}
            closeModal={setShowEditModal}
            UUID={opportunity.uuid}
          />
        )} */}
      </td>
    </CardDiv>
  );
};

export default PolicyCard;
