import { useState, useRef } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import Modal from '../../../components/Modal';
import Link from '../../../components/Link';
import DetailsDrawer from './OpportunityDetailsDrawer';
import DetailsPageTabMobile from '../../../components/DetailsPageTabMobile';
import { selectOpportunitiesCount } from '../../../store/opportunities/opportunitiesSlice';
import DetailsPageTab from '../../../components/DetailsPageTab';
import { useOutsideClick } from '../../../hooks/useOutsideClick';
import LocalStorageService from '../../../utils/LocalStorageService';
import { IOpportunity } from '../../../interfaces/Opportunity';
import { MobileDetailsDiv } from '../../../styles/DetailsDiv';
import { StyledTab } from '../../../styles/StyledTab';
import ham from '../../../assets/logo/hamburger.png';
import cross from '../../../assets/logo/cross.svg';
import dropdown from '../../../assets/logo/dropdown.svg';
import arrow from '../../../assets/logo/right-arrow.svg';
import contact from '../../../assets/logo/contact.png';

const MobileOpportunityDetails = ({
  opportunityDetails,
  setShowEditModal,
  setShowLinkContactModal,
  setShowAddContactModal,
}: {
  opportunityDetails: IOpportunity;
  setShowEditModal: Function;
  setShowLinkContactModal: Function;
  setShowAddContactModal: Function;
}) => {
  const [showDetailsDrawer, setShowDetailsDrawer] = useState('closed');
  const [activity, setActivity] = useState('activities');
  const { uuid } = useParams();
  const [showDropdown, setShowDropdown] = useState(false);
  const opportunityDetailsCount = useSelector(selectOpportunitiesCount);
  const navigate = useNavigate();
  const opportunityRef = useRef(null);
  useOutsideClick(opportunityRef, setShowDropdown);

  const user = LocalStorageService.getUser();

  return (
    <MobileDetailsDiv>
      <div className='mobile-topbar d-flex align-items-center justify-content-between'>
        <div className='d-flex align-items-center'>
          <button
            onClick={() => setShowDetailsDrawer('open')}
            className='secondaryButton'
          >
            <img alt='' src={ham} />
          </button>
          <div className='profile-pic d-none d-sm-flex align-items-center justify-content-center'>
            {opportunityDetails?.name
              ?.split(' ')
              .map((item: string) => item.substring(0, 1))
              .join('')}
          </div>
          <h2>{opportunityDetails?.name}</h2>
          {opportunityDetails && (
            <span className='status statusInactive'>
              {opportunityDetails?.OpportunityStatus?.name}
            </span>
          )}
        </div>
        <div className='d-flex align-items-center'>
          <button className='primaryButton w-75 d-none d-sm-block'>
            Start Quoting
            <img src={arrow} alt='' className='ms-3' />
          </button>
          <button className='dropDownButton d-none d-sm-block'>
            <img src={dropdown} alt='' />
          </button>
          <button
            onClick={() => {
              navigate('/mybook/opportunities');
            }}
            className='secondaryButton'
          >
            <img src={cross} className='cross' alt='cross-icon' />
          </button>
        </div>
      </div>
      <StyledTab className='d-sm-none'>
        <div className='ActivityName' ref={opportunityRef}>
          <Link
            to={`/opportunities/${uuid}/${activity}`}
            onClick={(e) => {
              setShowDropdown(!showDropdown);
            }}
          >
            {activity.toLowerCase()}
            {activity !== 'histories' && (
              <span className='count'>{opportunityDetailsCount[activity]}</span>
            )}
            <img src={dropdown} alt='' />
          </Link>
          {showDropdown && (
            <DetailsPageTabMobile
              name='Opportunity'
              setActivity={setActivity}
              setShowDropdown={setShowDropdown}
            />
          )}
        </div>
      </StyledTab>

      <DetailsPageTab name='Opportunity' isMobile={true} />

      {/* <button className='addButton d-sm-none'>
        <img src={plus} alt='add' />
      </button> */}
      <div className='mobile-bottombar d-flex d-sm-none align-items-center justify-content-between'>
        <button className='dropDownButton'>
          <img src={dropdown} alt='' />
        </button>
        <button className='primaryButton w-50'>Start Quoting</button>
      </div>

      {showDetailsDrawer !== 'closed' && (
        <Modal open={showDetailsDrawer} align='left'>
          <button
            onClick={() => {
              setShowDetailsDrawer('closing');
              setTimeout(() => {
                setShowDetailsDrawer('closed');
              }, 300);
            }}
            className='crossbutton secondaryButton'
          >
            <img src={cross} className='cross' alt='cross-icon' />
          </button>
          <div className='modal-content-div'>
            <div className='header-div d-flex align-items-center'>
              <div className='profile-pic d-flex align-items-center justify-content-center'>
                {opportunityDetails?.name
                  ?.split(' ')
                  .map((item: string) => item.substring(0, 1))
                  .join('')}
                {/* TODO : Change to assets */}
              </div>
              <div>
                <h2 className='mb-1'>{opportunityDetails?.name}</h2>
                <div className='header-info'>
                  <span className='d-flex align-items-center'>
                    <img src={contact} alt='' className='contact-img' />

                    <span
                      className={`nav-decor ${
                        user.Agent.id !== opportunityDetails?.Account.AgentId &&
                        'disabled-decor'
                      }`}
                      onClick={() =>
                        navigate(`/account/${opportunityDetails.Account.uuid}`)
                      }
                    >
                      {opportunityDetails?.Account.name}
                    </span>
                  </span>
                </div>
              </div>
            </div>

            <div className='details-section d-flex justify-content-between'>
              <h5 className='section-title '>Premium</h5>
              <span className='amount'>
                $
                {opportunityDetails?.premium
                  ? opportunityDetails?.premium.toLocaleString('en-US')
                  : 0}
              </span>{' '}
            </div>

            <div className='details-section d-flex justify-content-between'>
              <h5 className='section-title '>Contact</h5>
              <span
                className={`nav-decor ${
                  user.Agent.id !== opportunityDetails?.Account.AgentId &&
                  'disabled-decor'
                }`}
                onClick={() =>
                  navigate(
                    `/contacts/${opportunityDetails.OpportunityAccountContacts[0]?.AccountContact?.Contact.uuid}`
                  )
                }
              >
                {
                  opportunityDetails.OpportunityAccountContacts[0]
                    ?.AccountContact?.Contact.firstName
                }{' '}
                {
                  opportunityDetails.OpportunityAccountContacts[0]
                    ?.AccountContact?.Contact.lastName
                }
                {opportunityDetails.OpportunityAccountContacts.length > 1 && (
                  <span className='count-div'>
                    +{opportunityDetails.OpportunityAccountContacts.length - 1}
                  </span>
                )}
              </span>
            </div>

            <DetailsDrawer
              opportunityDetails={opportunityDetails}
              setShowEditModal={setShowEditModal}
              setShowLinkContactModal={setShowLinkContactModal}
              setShowAddContactModal={setShowAddContactModal}
            />
          </div>
        </Modal>
      )}
    </MobileDetailsDiv>
  );
};

export default MobileOpportunityDetails;
