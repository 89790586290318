import { useState, useRef } from 'react';
import { useOutsideClick } from '../../../hooks/useOutsideClick';
import Skeleton from 'react-loading-skeleton';
import Moment from 'react-moment';
import EmptyResults from '../../../pages/MyBook/components/Empty';
import { AppDispatch } from '../../../store';
import { useDispatch, useSelector } from 'react-redux';
import sort from '../../../assets/logo/sort.svg';
import sortup from '../../../assets/logo/sortup.svg';
import sortdown from '../../../assets/logo/sortdown.svg';
import Table from '../../Table';
import vector from '../../../assets/logo/dropdown.svg';
import edit from '../../../assets/logo/edit.svg';
import bin from '../../../assets/logo/bin.svg';
import ErrorCard from '../../../pages/Pipeline/components/ErrorCard';
import {
  DocumentSliceState,
  selectAllDocuments,
  deleteADocument,
} from '../../../store/documents/documentsSlice';
import AddDocumentsModal from './components/AddDocumentsModal';
import DocumentTable from './components/DocumentsTable';
import { useNavigate } from 'react-router-dom';
const Document = ({ name, uuid }: { name: string; uuid: string }) => {
  const documents = useSelector(selectAllDocuments);
  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();
  const DeleteDoc = (uuid: string) => {
    dispatch(
      deleteADocument({
        uuid,
        successCB: () => {
          setTimeout(() => {
            setShowSuccess(true);
          }, 100);
          setTimeout(() => {
            setShowSuccess(false);
          }, 2000);
        },
      })
    );
  };

  const [sortBy, setSortBy] = useState('createdAt');
  const [showSuccess, setShowSuccess] = useState(false);
  const [orderBy, setOrderBy] = useState('desc');
  const [showEditModal, setShowEditModal] = useState('closed');
  const [UUID, setUUID] = useState('');
  const [limit, setLimit] = useState(10);
  const [page, setPage] = useState(0);
  const documentCount = 10;
  const loadingStatus = useSelector(
    (state: { documents: DocumentSliceState }) => state.documents?.status
  );
  const handleSort = (sort: string) => {
    if (sort === sortBy) {
      if (orderBy === 'asc') {
        setOrderBy('desc');
      } else {
        setOrderBy('asc');
      }
    } else {
      setSortBy(sort);
    }
  };

  return (
    <div>
      {showSuccess && (
        <ErrorCard
          message={`<b>${'Document'}</b> is <b> deleted</b> `}
          show='open'
        />
      )}
      <div className='mybook-content document-content d-none d-sm-block'>
        {loadingStatus === 'loading' &&
          [1, 2, 3].map((item) => (
            <div className='d-sm-none'>
              {' '}
              <Skeleton height={138} key={item} />
            </div>
          ))}
        {}
        <div className='d-none d-sm-block'>
          {loadingStatus === 'loading' || documents?.length === 0 ? (
            <DocumentTable
              Docuements={documents}
              setSortBy={setSortBy}
              sortBy={sortBy}
              setOrderBy={setOrderBy}
              orderBy={orderBy}
            />
          ) : (
            loadingStatus === 'succeeded' &&
            documents.length !== 0 && (
              <Table
                limit={limit}
                setLimit={setLimit}
                page={page}
                setPage={setPage}
                count={documentCount}
                columns={[
                  {
                    accessor: 'name',
                    resizable: true,
                    sortable: true,
                    minWidth: 180,
                    Header: () => (
                      <div
                        onClick={() => handleSort('name')}
                        style={{ cursor: 'pointer' }}
                        className='d-flex justify-content-between w-100'
                      >
                        <span>Document type</span>
                        <img
                          className='sort'
                          src={
                            sortBy !== 'name'
                              ? sort
                              : orderBy === 'asc'
                              ? sortdown
                              : sortup
                          }
                          alt='sort'
                        />
                      </div>
                    ),
                    Cell: ({ row }: { row: any }) => (
                      <div className='row-name d-flex align-items-center'>
                        <a
                          className='s3LocatnText'
                          target='_blank'
                          href={row.original?.s3Location}
                        >
                          <b>
                            {row.original?.DocumentType?.name
                              ? row.original?.DocumentType?.name
                              : '--'}
                          </b>
                        </a>
                      </div>
                    ),
                  },
                  {
                    accessor: 'Doc.name',
                    Header: () => (
                      <div
                        onClick={() => handleSort('name')}
                        style={{ cursor: 'pointer' }}
                        className='d-flex justify-content-between w-100'
                      >
                        <span>Doc. name</span>
                        <img
                          className='sort'
                          src={
                            sortBy !== 'name'
                              ? sort
                              : orderBy === 'asc'
                              ? sortdown
                              : sortup
                          }
                          alt='sort'
                        />
                      </div>
                    ),
                    resizable: true,
                    minWidth: 115,
                    Cell: ({ row }: { row: any }) => (
                      <div className='d-flex align-items-center h-100'>
                        <div
                        // className={`status ${
                        //   row.original.Policies?.length > 0
                        //     ? 'statusActive'
                        //     : 'statusInvited'
                        // }`}
                        >
                          {row.original.name}
                        </div>
                      </div>
                    ),
                  },
                  {
                    accessor: 'Added by',
                    resizable: true,
                    sortable: true,
                    minWidth: 155,
                    Header: () => (
                      <div
                        onClick={() => handleSort('createdAt')}
                        style={{ cursor: 'pointer' }}
                        className='d-flex justify-content-between w-100'
                      >
                        <span>Added by</span>
                        <img
                          className='sort'
                          src={
                            sortBy !== 'createdAt'
                              ? sort
                              : orderBy === 'asc'
                              ? sortdown
                              : sortup
                          }
                          alt='sort'
                        />
                      </div>
                    ),
                    Cell: ({ row }: { row: any }) => (
                      <div>
                        {row.original.UploadedBy?.firstName +
                          ' ' +
                          row.original.UploadedBy?.lastName}
                      </div>
                    ),
                  },
                  {
                    accessor: 'From',
                    resizable: true,
                    sortable: true,
                    hide: name !== 'Opportunity',
                    minWidth: 155,
                    Header: () => <span>From</span>,
                    Cell: ({ row }: { row: any }) => (
                      <div>
                        {row.original.QuoteId ? (
                          <span
                            className='QuoteName'
                            onClick={() => {
                              navigate(`/opportunities/${uuid}/quotes`);
                            }}
                          >
                            {row.original?.Quote?.Carrier?.name}
                          </span>
                        ) : (
                          'Opportunity'
                        )}
                      </div>
                    ),
                  },
                  {
                    accessor: 'updatedon',
                    Header: () => (
                      <div
                        onClick={() => handleSort('createdAt')}
                        style={{ cursor: 'pointer' }}
                        className='d-flex justify-content-between w-100'
                      >
                        <span>Updated on</span>
                        <img
                          className='sort'
                          src={
                            sortBy !== 'createdAt'
                              ? sort
                              : orderBy === 'asc'
                              ? sortdown
                              : sortup
                          }
                          alt='sort'
                        />
                      </div>
                    ),
                    minWidth: 150,
                    resizable: true,
                    Cell: ({ row }: { row: any }) => (
                      <div className=''>
                        {/* <span className='opp-num'>
                        {row.original.Opportunities.length}
                      </span> */}
                        <Moment format='MM/DD/YYYY'>
                          {row.original?.updatedAt}
                        </Moment>
                      </div>
                    ),
                  },
                  {
                    accessor: 'Actions',
                    Header: 'Actions',
                    sticky: 'right',
                    resizable: false,
                    minWidth: 100,
                    Cell: ({ row }: { row: any }) => (
                      <div className='d-flex align-items-center justify-content-end h-100'>
                        <div className='actionsDrop d-flex align-items-center  h-100'>
                          <ActionsDropdown
                            DeleteDoc={DeleteDoc}
                            uuid={row.original.uuid}
                            setShowEditModal={setShowEditModal}
                            setUUID={setUUID}
                          />
                        </div>
                      </div>
                    ),
                  },
                ]}
                data={documents}
                className='mybook-table'
              />
            )
          )}
        </div>
      </div>
      {showEditModal !== 'closed' && (
        <AddDocumentsModal
          name={name}
          uuid={UUID}
          resourceUUID={uuid}
          editUUID={UUID}
          isEdit={true}
          show={showEditModal}
          closeModal={setShowEditModal}
        />
      )}
    </div>
  );
};

export default Document;

const ActionsDropdown = ({
  uuid,
  setShowEditModal,
  setUUID,
  DeleteDoc,
}: {
  uuid: string;
  setShowEditModal: Function;
  setUUID: Function;
  DeleteDoc: Function;
}) => {
  const [showActionsDropdown, setShowActionsDropdown] = useState(false);
  const dropRef = useRef<HTMLButtonElement>(null);
  useOutsideClick(dropRef, setShowActionsDropdown);

  return (
    <button
      className='vector d-flex align-items-center justify-content-center position-relative'
      onClick={() => {
        setShowActionsDropdown(!showActionsDropdown);
        if (dropRef.current) {
          dropRef.current.focus();
        }
      }}
      ref={dropRef}
      onBlur={() => setShowActionsDropdown(false)}
    >
      <img src={vector} alt='vector' />
      {showActionsDropdown && (
        <div className='dropdown-div'>
          <div
            className='edit-div'
            onClick={() => {
              setShowEditModal('open');
              setUUID(uuid);
              setShowActionsDropdown(false);
            }}
          >
            Edit <img src={edit} alt='bin' />
          </div>
          <div
            className='delete-div'
            onClick={() => {
              DeleteDoc(uuid);
            }}
          >
            Delete <img src={bin} alt='bin' />
          </div>
        </div>
      )}
    </button>
  );
};
