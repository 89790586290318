import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { AppDispatch } from '../../../store';
import {
  AgentsSliceState,
  updateGoal,
} from '../../../store/agents/agentsSlice';
import { fetchGoals, updateGoalsDetails } from '../../../store/goals/goalSlice';
import { updateLeaderboardGoals } from '../../../store/dashboard/dashboardSlice';
import InputField from '../../../components/InputField';
import SuccessCard from '../../../components/SuccessCard';
import Modal from '../../../components/Modal';
import SelectField from '../../../components/CustomSelectField';
import { IUser } from '../../../interfaces/User';
import { IGoals } from '../../../interfaces/Goals';
import { StyledGoalModal } from './styles';
import { SuccessAlign } from '../../../styles/SuccessAlign';
import cross from '../../../assets/logo/cross.svg';
import tick from '../../../assets/logo/tick.svg';
import warning from '../../../assets/logo/warning.svg';

const GoalSchema = Yup.object().shape({
  premiumGoalAmount: Yup.string().required('Premium goal required'),
  policyGoalCount: Yup.string().required('Policy goal count required'),
});

const SetGoalModal = ({
  show,
  closeModal,
  agentUUID,
}: {
  show: string;
  closeModal: Function;
  agentUUID: string;
}) => {
  const dispatch = useDispatch<AppDispatch>();
  const location = useLocation();

  const [success, setSuccess] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [thisMonth, setthisMonth] = useState<any>();
  const [monthSelected, setMonthSelected] = useState<any>({});
  const [premiumGoalUUID, setPremiumGoalUUID] = useState('');
  const [policyGoalUUID, setPolicyGoalUUID] = useState('');

  const {
    register,
    handleSubmit,
    reset,
    setValue,
    watch,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(GoalSchema),
  });

  const watchAllFields = watch();

  const onCloseModal = () => {
    closeModal('closing');
    setTimeout(() => {
      closeModal('closed');
    }, 300);
  };

  const loading = useSelector(
    (state: { agents: AgentsSliceState }) => state.agents.isLoading
  );

  const monthNames = [
    {
      label: 'January',
      value: new Date(new Date().getFullYear(), 0, 15).toUTCString(),
      isDisabled: 0 < new Date(thisMonth).getMonth(),
    },
    {
      label: 'February',
      value: new Date(new Date().getFullYear(), 1, 15).toUTCString(),
      isDisabled: 1 < new Date(thisMonth).getMonth(),
    },
    {
      label: 'March',
      value: new Date(new Date().getFullYear(), 2, 15).toUTCString(),
      isDisabled: 2 < new Date(thisMonth).getMonth(),
    },
    {
      label: 'April',
      value: new Date(new Date().getFullYear(), 3, 15).toUTCString(),
      isDisabled: 3 < new Date(thisMonth).getMonth(),
    },
    {
      label: 'May',
      value: new Date(new Date().getFullYear(), 4, 15).toUTCString(),
      isDisabled: 4 < new Date(thisMonth).getMonth(),
    },
    {
      label: 'June',
      value: new Date(new Date().getFullYear(), 5, 15).toUTCString(),
      isDisabled: 5 < new Date(thisMonth).getMonth(),
    },
    {
      label: 'July',
      value: new Date(new Date().getFullYear(), 6, 15).toUTCString(),
      isDisabled: 6 < new Date(thisMonth).getMonth(),
    },
    {
      label: 'August',
      value: new Date(new Date().getFullYear(), 7, 15).toUTCString(),
      isDisabled: 7 < new Date(thisMonth).getMonth(),
    },
    {
      label: 'September',
      value: new Date(new Date().getFullYear(), 8, 15).toUTCString(),
      isDisabled: 8 < new Date(thisMonth).getMonth(),
    },
    {
      label: 'October',
      value: new Date(new Date().getFullYear(), 9, 15).toUTCString(),
      isDisabled: 9 < new Date(thisMonth).getMonth(),
    },
    {
      label: 'November',
      value: new Date(new Date().getFullYear(), 10, 15).toUTCString(),
      isDisabled: 10 < new Date(thisMonth).getMonth(),
    },
    {
      label: 'December',
      value: new Date(new Date().getFullYear(), 11, 15).toUTCString(),
      isDisabled: 11 < new Date(thisMonth).getMonth(),
    },
  ];

  useEffect(() => {
    setthisMonth(
      new Date(
        new Date().getFullYear(),
        new Date().getMonth(),
        15
      ).toUTCString()
    );
    setMonthSelected({ monthSelected: thisMonth });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [thisMonth]);

  const onSetItem = (name: string, value: string) => {
    setMonthSelected({ monthSelected: value });
    setErrorMessage('');
  };

  useEffect(() => {
    if (show === 'open' && monthSelected.monthSelected) {
      dispatch(
        fetchGoals({
          selectedMonthDate: monthSelected.monthSelected,
          agentUUID,
          successCB: (data: any) => {
            setValue(
              'premiumGoalAmount',
              data.agents[0].Goals.filter(
                (item: any) => item.GoalTypes.name === 'PREMIUM'
              )[0]?.value
            );
            setPremiumGoalUUID(
              data.agents[0]?.Goals.filter(
                (item: any) => item.GoalTypes.name === 'PREMIUM'
              )[0]?.uuid
            );
            setPolicyGoalUUID(
              data.agents[0]?.Goals.filter(
                (item: any) => item.GoalTypes.name === 'POLICY'
              )[0]?.uuid
            );

            setValue(
              'policyGoalCount',
              data.agents[0].Goals.filter(
                (item: any) => item.GoalTypes.name === 'POLICY'
              )[0]?.value
            );
          },
        })
      );
    }
  }, [monthSelected]);

  useEffect(() => {
    if (!monthSelected.monthSelected) {
      setValue('premiumGoalAmount', '');
      setValue('policyGoalCount', '');
    }
  }, [monthSelected]);

  const onSubmit = (value: any) => {
    if (!monthSelected.monthSelected) {
      setErrorMessage('Please select a month');
    } else {
      let data: any = {
        selectedMonthDate: monthSelected.monthSelected,
        agents: [
          {
            agentUUID: agentUUID,
            policyGoalCount: value.policyGoalCount,
            policyGoalUUID: policyGoalUUID,
            premiumGoalAmount: value.premiumGoalAmount,
            premiumGoalUUID: premiumGoalUUID,
          },
        ],
      };

      dispatch(
        updateGoalsDetails({
          data,
          successCB: (data: {
            agentsWithGoals: { User: IUser; Goals: IGoals[] }[];
          }) => {
            closeModal('closing');
            setTimeout(() => {
              setSuccess(true);
            }, 300);
            setTimeout(() => {
              reset();
              setSuccess(false);
              setTimeout(() => {
                closeModal('closed');
              }, 300);
            }, 4000);
            let respData = data.agentsWithGoals[0];
            if (respData.Goals.length > 0) {
              if (location.pathname === '/dashboard') {
                dispatch(updateLeaderboardGoals(data));
              } else {
                let userUUID = respData.User.uuid;
                let premiumGoal = respData.Goals.find(
                  (goal) => goal.GoalTypes.name === 'PREMIUM'
                )?.value;
                let policyGoal = respData.Goals.find(
                  (goal) => goal.GoalTypes.name === 'POLICY'
                )?.value;
                dispatch(updateGoal({ userUUID, premiumGoal, policyGoal }));
              }
            }
          },
        })
      );
    }
  };

  return (
    <>
      <SuccessAlign>
        <div
          className={`success-container ${success && show && 'open-drawer'} `}
        >
          <SuccessCard
            title={'Success'}
            message={`<b>Goals</b> has been updated`}
          />
          <button
            onClick={() => {
              reset();
              setSuccess(false);
            }}
            className='crossbutton'
          >
            <img src={cross} className='cross' alt='cross-icon' />
          </button>
        </div>
      </SuccessAlign>

      <Modal open={show} align='right'>
        <StyledGoalModal>
          <button className='crossbutton' onClick={() => onCloseModal()}>
            <img src={cross} className='cross' alt='' />
          </button>

          <div>
            <h2>Set Goal</h2>
          </div>
          <div>
            <div>
              {show && !success && (
                <form onSubmit={handleSubmit(onSubmit)}>
                  <div className='field-section'>
                    <div className='agency-fields'>
                      <SelectField
                        options={monthNames}
                        placeholder='Apply goal to '
                        name='monthSelected'
                        values={monthSelected}
                        onSetItem={onSetItem}
                      />
                      {}
                      {errorMessage ? (
                        <div className='error-div'>
                          <img
                            src={warning}
                            alt='warning'
                            className='warning'
                          />
                          {errorMessage}
                        </div>
                      ) : null}
                    </div>

                    <div className='agency-fields'>
                      <InputField
                        register={register}
                        name='premiumGoalAmount'
                        type='number'
                        prefix='$'
                        placeholder='Premium goal'
                        className={`${
                          errors.premiumGoalAmount && 'error-field'
                        } ${watchAllFields.premiumGoalAmount && 'filled'}`}
                      />
                      {errors.premiumGoalAmount ? (
                        <div className='error-div'>
                          <img
                            src={warning}
                            alt='warning'
                            className='warning'
                          />
                          {errors.premiumGoalAmount?.message}
                        </div>
                      ) : null}
                    </div>
                    <div className='agency-fields'>
                      <InputField
                        register={register}
                        name='policyGoalCount'
                        type='number'
                        placeholder='Policy goal'
                        className={`${
                          errors.policyGoalCount && 'error-field'
                        } ${watchAllFields.policyGoalCount && 'filled'}`}
                      />
                      {errors.policyGoalCount ? (
                        <div className='error-div'>
                          <img
                            src={warning}
                            alt='warning'
                            className='warning'
                          />
                          {errors.policyGoalCount?.message}
                        </div>
                      ) : null}
                    </div>
                  </div>
                  <div className='buttonsDiv d-flex align-items-center'>
                    <button
                      onClick={() => onCloseModal()}
                      className='cancel-button'
                      type='button'
                    >
                      Cancel
                    </button>
                    <button
                      type='submit'
                      className='agentButton '
                      disabled={loading || show === 'closing'}
                    >
                      Save <img className='tick' src={tick} alt='tick-sign' />
                    </button>
                  </div>
                </form>
              )}
            </div>
          </div>
        </StyledGoalModal>
      </Modal>
    </>
  );
};

export default SetGoalModal;
