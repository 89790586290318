import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';
import { useLocation } from 'react-router-dom';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { AppDispatch } from '../../store';
import {
  createOpportunity,
  OpportunitiesSliceState,
} from '../../store/opportunities/opportunitiesSlice';
import {
  clearAccountOpportunities,
  getAccountOpportunities,
  getCount as getCountAccount,
  updateAccountDetailOpportunity,
} from '../../store/accounts/accountsSlice';
import {
  clearOpportunities,
  getContactOpportunities,
  getCount as getContactCount,
  updateContactDetailOpportunity,
} from '../../store/contacts/contactsSlice';
import { getCount } from '../../store/book/bookSlice';
import { getPipelines } from '../../store/pipelines/pipelineSlice';
import SelectField from '../SelectField';
import InputField from '../InputField';
import DateField from '../DateField';
import MarkdownEditor from '../MarkdownEditor';
import SuccessCard from '../../components/SuccessCard';
import SearchDropdown from '../SearchDropdown';
import { fetchAgents } from '../../api/agents';
import { fetchAccounts } from '../../api/account';
import { fetchProducts } from '../../api/product';
import { getLeadSourceTypes } from '../../api/lead';
import { IProduct } from '../../interfaces/Product';
import { IContact } from '../../interfaces/Contact';
import { IProducer } from '../../interfaces/Agent';
import { IPipeline, IPipelineStage } from '../../interfaces/Opportunity';
import { IAccount } from '../../interfaces/Account';
import { FormModal } from '../../styles/FormModal';
import { ILeadSourceType } from '../../interfaces/Lead';
import { StyledQuickOpportunityModal } from './styles';
import { SuccessAlign } from '../../styles/SuccessAlign';
import tick from '../../assets/logo/tick.svg';
import warning from '../../assets/logo/warning.svg';
import cross from '../../assets/logo/cross.svg';

const OpportunitySchema = Yup.object().shape({
  opportunityName: Yup.string().required('Please enter the name.'),
  pipeline: Yup.object().shape({
    value: Yup.string().required('Please select a pipeline'),
  }),
  pipelineStage: Yup.object().shape({
    value: Yup.string().required('Please select a pipeline stage'),
  }),
});

const QuickAddOpportunityModal = ({
  show,
  closeModal,
  isInnerTab,
  assosiated,
  contactDetails,
  name,
  accountType,
  setLongOpportunityForm,
}: {
  show: string;
  closeModal: Function;
  isInnerTab?: boolean;
  assosiated?: IAccount;
  contactDetails?: IContact;
  name?: string;
  accountType?: string;
  setLongOpportunityForm: Function;
}) => {
  const dispatch = useDispatch<AppDispatch>();
  const {
    register,
    handleSubmit,
    control,
    reset,
    watch,
    setValue,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(OpportunitySchema),
  });
  const location = useLocation();

  const watchAllFields = watch();

  const [isCommercial, setCommercial] = useState(false);
  const [errorMessage, setError] = useState('');
  const [showSuccess, setshowSuccess] = useState(false);
  const [producers, setProducers] = useState<IProducer[]>([]);
  const [productCategory, setProductCategory] = useState<IProduct[]>([]);
  const [disabled, setDisabled] = useState(false);
  const [sourceTypes, setSourceTypes] = useState<ILeadSourceType[]>([]);

  const onCloseModal = () => {
    closeModal('closing');
    setTimeout(() => {
      closeModal('closed');
    }, 300);
  };

  const [policySearch, setPolicySearch] = useState('');
  const [policy, setPolicy] = useState('');
  const [productType, setProductType] = useState('');
  const [productUUID, setProductUUID] = useState('');
  const [loadingStatus, setLoadingStatus] = useState('idle');

  const [producerSearch, setProducerSearch] = useState('');
  const [producer, setProducer] = useState('');
  const [user, setUser] = useState<any>({});
  const [accounts, setAccounts] = useState<any[]>([]);

  const [accountSearch, setAccountSearch] = useState('');
  const [account, setAccount] = useState('');

  const [pipelines, setPipelines] = useState<any[]>([]);
  const watchPipeline = watch('pipeline');
  const [textCheck, setTextCheck] = useState('');
  const [noteError, setNoteError] = useState('');

  useEffect(() => {
    const user = JSON.parse(localStorage.getItem('user') || '{}');
    if (user) {
      setUser(user);
    }
  }, []);
  const [submit, setSubmit] = useState(false);
  const onSetPipelineValue = (item?: IPipeline) => {
    if (item) {
      const temp = item.PipelineStages.reduce((t: any, o) => {
        if (o.nextStageId) {
          t[o.nextStageId] = o;
        } else {
          t['last'] = o;
        }
        return t;
      }, {});

      let result: IPipelineStage[] = [];

      let p: any = 'last';

      while (temp[p]) {
        result.unshift(temp[p]);
        p = temp[p].id;
      }
      const Options = result.filter((item, index) => index < result.length - 2);
      return Options.map((el: any) => {
        return { label: el.name, value: el.uuid };
      });
    }
  };
  const loading = useSelector(
    (state: { opportunities: OpportunitiesSliceState }) =>
      state.opportunities.isLoading
  );

  useEffect(() => {
    if (policy !== '') {
      dispatch(
        getPipelines({
          sortBy: '',
          orderBy: '',
          searchBy: '',
          successCB: (data: any) => {
            setPipelines(data);
          },
          agencyUUID: '',
          productUUID: productType === 'PRODUCT' ? policy : '',
          customProductUUID: productType === 'CUSTOM_PRODUCT' ? policy : '',
        })
      );
    } else {
      setPipelines([]);
    }

    setValue('pipeline', '');
    setValue('pipelineStage', '');

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [policy]);

  useEffect(() => {
    if (!watchPipeline) {
      setValue('pipelineStage', '');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [watchPipeline]);

  const onSubmit = (value: any) => {
    setSubmit(true);
    if (!account) {
      setError('Account Required');
    } else if (policy) {
      setSubmit(false);
      let noteSubmit = true;
      if (notes) {
        if (textCheck && !textCheck.trim() && textCheck.length > 1) {
          noteSubmit = false;
          setNoteError('Note cannot be blank space');
        }
      }
      value.notes = notes;
      value.action = 'quickForm';
      value.accountType = isCommercial ? 'COMMERCIAL' : 'PERSONAL';
      value.pipelineStage = value.pipelineStage?.value;
      value.pipeline = value.pipeline?.value;
      value.agentUUID = producer;
      value.accountUUID = account;
      if (productType === 'PRODUCT') {
        value.productUUID = productUUID;
      } else if (productType === 'CUSTOM_PRODUCT') {
        value.customProductUUID = productUUID;
      }
      value.maritalStatus = value.maritalStatus?.value;
      value.driverlicenseState = value.driverlicenseState?.value;
      value.sourceName = value.sourceName?.label;
      if (noteSubmit) {
        dispatch(
          createOpportunity({
            data: value,
            successCB: (data: any) => {
              dispatch(getCount());
              if (isInnerTab && assosiated && name === 'Account') {
                dispatch(getCountAccount({ uuid: assosiated?.uuid }));
                dispatch(clearAccountOpportunities());
                dispatch(getCountAccount({ uuid: assosiated?.uuid }));
                dispatch(
                  getAccountOpportunities({
                    uuid: assosiated?.uuid,
                    offset: 0,
                  })
                );
                dispatch(
                  updateAccountDetailOpportunity({
                    opportunity: data.data,
                    type: 'add',
                  })
                );
              } else if (isInnerTab && contactDetails && name === 'Contact') {
                dispatch(clearOpportunities());
                dispatch(
                  getContactOpportunities({
                    uuid: contactDetails?.uuid,
                    offset: 0,
                  })
                );
                dispatch(getContactCount({ uuid: contactDetails?.uuid }));
                dispatch(
                  updateContactDetailOpportunity({
                    opportunity: data.data,
                    type: 'add',
                  })
                );
              }
              if (location.pathname === '/pipeline/kanban') {
                window.location.reload();
              }
              closeModal('closing');
              setTimeout(() => {
                setDisabled(true);
                setshowSuccess(true);
              }, 300);
              setTimeout(() => {
                reset();
                setshowSuccess(false);
                setTimeout(() => {
                  closeModal('closed');
                }, 300);
              }, 2000);
            },
            errorCB: (error: string) => {
              setDisabled(false);
              if (typeof error === 'string') setError(error);
            },
          })
        );
      }
    }
  };

  const loadPolicy = (value: string) => {
    if (loadingStatus === 'idle' || loadingStatus === 'succeeded') {
      setLoadingStatus('loading');
      fetchProducts(
        (data: IProduct[]) => {
          setProductCategory(data);
          setLoadingStatus('succeeded');
        },
        'name',
        'asc',
        value,
        '',
        true,
        isCommercial ? 'COMMERCIAL' : 'PERSONAL'
      );
    }
  };

  useEffect(() => {
    if (user?.UserRole?.name === 'AGENT') {
      setProducerSearch('Me');
    }
  }, [user]);

  useEffect(() => {
    setAccounts([]);
    setAccountSearch('');
    setAccount('');
  }, [isCommercial]);

  useEffect(() => {
    if (isInnerTab && assosiated && name === 'Account') {
      if (accountType === 'COMMERCIAL') {
        setCommercial(true);
      } else if (accountType === 'PERSONAL') {
        setCommercial(false);
      }
      setAccountSearch(assosiated?.name);
      setAccount(assosiated?.uuid);
    } //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [assosiated, isCommercial]);

  const loadProducer = (value: string) => {
    if (
      loadingStatus === 'idle' ||
      loadingStatus === 'succeeded' ||
      user?.UserRole?.name === 'PRINCIPAL_AGENT'
    ) {
      setLoadingStatus('loading');
      fetchAgents(
        (data: IProducer[]) => {
          setProducers(data);
          setLoadingStatus('succeeded');
        },
        'id',
        'asc',
        value,
        'True'
      );
    }
  };

  const loadAccount = (value: string) => {
    if (loadingStatus === 'idle' || loadingStatus === 'succeeded') {
      setLoadingStatus('loading');
      fetchAccounts(
        'name',
        'asc',
        value,
        25,
        0,
        isCommercial ? 'COMMERCIAL' : 'PERSONAL',
        (data: IAccount[]) => {
          setAccounts(data);
          setLoadingStatus('succeeded');
        },
        'True',
        contactDetails?.uuid
      );
    }
  };

  const [notes, setNotes] = useState<any>(['']);

  const onSetNotesValue = (value: any, index: number) => {
    let updatedNotes = [...notes];
    updatedNotes[index] = value;
    setNotes(updatedNotes);
  };

  useEffect(() => {
    getLeadSourceTypes((data: ILeadSourceType[]) => {
      setSourceTypes(data);
    });
  }, []);

  useEffect(() => {
    if (notes) {
      if (textCheck) {
        setNoteError('');
      }
    }
  }, [notes]);

  return (
    <>
      <SuccessAlign>
        <div className={`success-container ${showSuccess && 'open-drawer'} `}>
          <SuccessCard
            title={'Success adding Opportunity'}
            message={`<b>${watchAllFields.opportunityName}</b> has been added as opportunity<b>`}
          />
          <button
            onClick={() => {
              reset();
              setshowSuccess(false);
            }}
            className='crossbutton'
          >
            <img src={cross} className='cross' alt='cross-icon' />
          </button>
        </div>
      </SuccessAlign>
      <StyledQuickOpportunityModal>
        <FormModal
          open={show}
          align='right'
          containerClassName='fullwidth-container'
        >
          <div className='entire-div'>
            <div className='topdiv'>
              <div className='form-title'>
                <h2 className='head'>Add opportunity</h2>
              </div>
              <div>
                <button className='cross-btn'>
                  <img
                    className='cross'
                    src={cross}
                    alt='cross-icon'
                    onClick={() => {
                      reset();
                      onCloseModal();
                    }}
                  />
                </button>
              </div>
              <div className='d-flex'>
                <button
                  onClick={() => {
                    setCommercial(false);
                    setProductCategory([]);
                    setPolicySearch('');
                    setPolicy('');
                    setProductUUID('');
                    setProductType('');
                  }}
                  disabled={accountType === 'COMMERCIAL'}
                  className={
                    !isCommercial
                      ? 'naVigatorbuttonsActive'
                      : 'naVigatorbuttons'
                  }
                >
                  Personal
                </button>
                <div className='commercial-text'>
                  <button
                    onClick={() => {
                      setCommercial(true);
                      setProductCategory([]);
                      setPolicySearch('');
                      setPolicy('');
                      setProductUUID('');
                      setProductType('');
                    }}
                    disabled={accountType === 'PERSONAL'}
                    className={
                      isCommercial
                        ? 'naVigatorbuttonsActive'
                        : 'naVigatorbuttons'
                    }
                  >
                    Commercial
                  </button>
                </div>
              </div>
            </div>
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className='scroll-bar  '>
                <div>
                  <div className=' d-sm-flex justify-content-between'>
                    <div className='fields'>
                      <SelectField
                        options={sourceTypes.map((item) => {
                          return {
                            label: item.name,
                            options: item.LeadSources.map((source) => {
                              return {
                                label: source.name,
                                value: source.uuid,
                              };
                            }),
                          };
                        })}
                        name='sourceName'
                        placeholder='Opportunity source'
                        control={control}
                      />
                    </div>
                    <div className='fields'>
                      <SearchDropdown
                        name='account'
                        addName={true}
                        placeholder='Account associated to'
                        isEdit={true}
                        values={accountSearch}
                        searchValue={account?.length > 0 ? account : ['']}
                        setSearch={setAccountSearch}
                        status={loadingStatus}
                        className={`${
                          errors.account || (errorMessage && 'error-field')
                        } ${accountSearch && 'filled'}`}
                        register={register}
                        required
                        control={control}
                        disabled={name === 'Account'}
                        setUUID={setAccount}
                        loadFunction={loadAccount}
                        content={
                          <>
                            {accounts?.map((item) => (
                              <div
                                className='searchValuesCard d-flex align-items-center  account-search-card'
                                onClick={() => {
                                  setAccountSearch(item?.name);
                                  setAccount(item?.uuid);
                                }}
                              >
                                <div className='profile-pic d-flex align-items-center justify-content-center'>
                                  {item?.name?.charAt(0)}
                                </div>
                                <div>
                                  <div className='text-overflow-container'>
                                    <h6 className='name-text-overflow'>
                                      {item.name}
                                    </h6>
                                    <span className='tooltiptext'>
                                      <span className='tooltipInnertext'>
                                        {item.name}
                                      </span>
                                    </span>
                                  </div>
                                  <ul className='user-details'>
                                    <li>
                                      {isCommercial
                                        ? item?.businessEmail
                                        : item?.AccountContacts[0]?.Contact?.ContactDetails?.find(
                                            (value: any) =>
                                              value.type === 'EMAIL'
                                          )?.value}
                                    </li>
                                    <li>
                                      {isCommercial
                                        ? item?.businessPhoneNumber
                                        : item?.AccountContacts[0]?.Contact?.ContactDetails?.find(
                                            (value: any) =>
                                              value.type === 'PHONENUMBER'
                                          )?.value}
                                    </li>
                                  </ul>
                                </div>
                              </div>
                            ))}
                            <div
                              className='searchValuesCard d-flex align-items-center'
                              onClick={() => {
                                setLongOpportunityForm('open');
                                setTimeout(() => {
                                  closeModal('closed');
                                }, 500);
                              }}
                            >
                              <div className='profile-pic d-flex align-items-center justify-content-center'>
                                +
                              </div>
                              <div>
                                <h6> Add Account</h6>
                              </div>
                            </div>
                          </>
                        }
                      />
                      {!account && submit ? (
                        <div className='errorVal'>
                          <img
                            src={warning}
                            alt='warning'
                            className='warning'
                          />
                          Account Required
                        </div>
                      ) : null}
                    </div>
                  </div>
                  <div className=' d-sm-flex justify-content-between'>
                    <div className='fields'>
                      <SearchDropdown
                        name='productCategory'
                        placeholder='Select Policy'
                        isEdit={true}
                        values={policySearch}
                        searchValue={productCategory}
                        setSearch={setPolicySearch}
                        status={loadingStatus}
                        className={`${!policy && submit && 'error-field'} ${
                          policySearch && 'filled'
                        } text-capitalize`}
                        register={register}
                        control={control}
                        loadFunction={loadPolicy}
                        required
                        setUUID={setPolicy}
                        setProductUUID={setProductUUID}
                        content={productCategory?.map((item) => (
                          <div
                            className='searchValuesCard d-flex align-items-center'
                            onClick={() => {
                              setPolicySearch(
                                item?.name.toLowerCase().split('_').join(' ')
                              );
                              setPolicy(item?.uuid);
                              setProductUUID(item?.uuid);
                              setProductType(item?.type);
                            }}
                          >
                            <div>
                              <h6 className='text-capitalize'>
                                {item.name.toLowerCase().split('_').join(' ')}
                              </h6>
                            </div>
                          </div>
                        ))}
                      />
                      {!policy && submit ? (
                        <div className='errorVal'>
                          <img
                            src={warning}
                            alt='warning'
                            className='warning'
                          />
                          Please select a policy type
                        </div>
                      ) : null}
                    </div>
                    <div className='fields'>
                      <SelectField
                        options={pipelines.map((item) => {
                          return { label: item.name, value: item.uuid };
                        })}
                        name='pipeline'
                        placeholder='Pipeline'
                        control={control}
                        noOptionsMessage={() => 'Please select a policy first'}
                        required
                        className={`${errors.pipeline && 'error-field'} ${
                          watchAllFields.pipeline && 'filled'
                        }`}
                      />
                      {errors.pipeline ? (
                        <div className='errorVal'>
                          <img
                            src={warning}
                            alt='warning'
                            className='warning'
                          />
                          {errors.pipeline?.message
                            ? 'Please select a pipeline'
                            : errors.pipeline?.value?.message}
                        </div>
                      ) : null}
                    </div>
                  </div>
                  <div className='  d-sm-flex justify-content-between'>
                    <div className='fields'>
                      <SelectField
                        options={onSetPipelineValue(
                          pipelines.find(
                            (item) =>
                              item.uuid === watchAllFields.pipeline?.value
                          )
                        )}
                        name='pipelineStage'
                        placeholder='Pipeline stage'
                        required
                        control={control}
                        noOptionsMessage={() =>
                          'Please select a pipeline first'
                        }
                        className={`${errors.pipelineStage && 'error-field'} ${
                          watchAllFields.pipelineStage && 'filled'
                        }`}
                      />
                      {errors.pipelineStage ? (
                        <div className='errorVal'>
                          <img
                            src={warning}
                            alt='warning'
                            className='warning'
                          />
                          {errors.pipelineStage?.message
                            ? 'Please select a pipeline'
                            : errors.pipelineStage?.value?.message}
                        </div>
                      ) : null}
                    </div>
                    <div className='fields'>
                      <SearchDropdown
                        name='producer'
                        placeholder='Select producer'
                        isEdit={true}
                        addName={true}
                        values={producerSearch}
                        searchValue={producers}
                        setSearch={setProducerSearch}
                        status={loadingStatus}
                        className={`${producerSearch && 'filled'}`}
                        register={register}
                        control={control}
                        setUUID={setProducer}
                        loadFunction={loadProducer}
                        disabled={user?.UserRole?.name === 'AGENT'}
                        content={producers?.map((item) => (
                          <div
                            className='searchValuesCard d-flex align-items-center'
                            onClick={() => {
                              setProducerSearch(
                                `${item.User.firstName} ${
                                  item.User.lastName ? item.User.lastName : ''
                                }`
                              );
                              setProducer(item?.uuid);
                            }}
                          >
                            <div className='profile-pic d-flex align-items-center justify-content-center'>
                              {item?.User?.firstName?.charAt(0)}
                              {item?.User?.lastName?.charAt(0)}
                            </div>
                            <div>
                              <h6>
                                {`${item.User.firstName} ${
                                  item.User.lastName ? item.User.lastName : ''
                                }`}
                              </h6>
                              <ul className='user-details'>
                                <li>{item.User?.email}</li>
                                <li>{item?.User?.phoneNumber}</li>
                              </ul>
                            </div>
                          </div>
                        ))}
                      />
                    </div>
                  </div>
                  <div className='  d-sm-flex justify-content-between'>
                    <div className='fields'>
                      <InputField
                        name='opportunityName'
                        type='text'
                        placeholder='Opportunity name'
                        register={register}
                        required={true}
                        className={`${
                          errors.opportunityName && 'error-field'
                        } ${watchAllFields.opportunityName && 'filled'}`}
                      />
                      {errors.opportunityName ? (
                        <div className='errorVal'>
                          <img
                            src={warning}
                            alt='warning'
                            className='warning'
                          />
                          {errors.opportunityName?.message}
                        </div>
                      ) : null}
                    </div>
                    <div className='fields'>
                      <InputField
                        name='premium'
                        type='number'
                        placeholder='Opportunity value'
                        register={register}
                        prefix='$'
                        className={`${errors.premium && 'error-field'} ${
                          watchAllFields.premium && 'filled'
                        }`}
                      />
                      {errors.premium ? (
                        <div className='errorVal'>
                          <img
                            src={warning}
                            alt='warning'
                            className='warning'
                          />
                          {errors.premium?.message}
                        </div>
                      ) : null}
                    </div>
                  </div>
                  <div className='  d-sm-flex justify-content-between'>
                    <div className='fields'>
                      <DateField
                        name='effectiveDate'
                        placeholder='Effective date'
                        control={control}
                        value={watchAllFields.effectiveDate}
                      />
                    </div>
                    <div className='fields'>
                      <DateField
                        name='expectedCloseDate'
                        placeholder='Estimated close date'
                        control={control}
                        value={watchAllFields.expectedCloseDate}
                      />
                    </div>
                  </div>
                  <div>
                    {notes &&
                      notes?.map((item: any, index: number) => {
                        return (
                          <div className='d-flex align-items-center descriptionField  col'>
                            <div className='textarea'>
                              <MarkdownEditor
                                placeholder='Add notes here'
                                note={item}
                                multi={true}
                                index={index}
                                setNote={onSetNotesValue}
                                textCheckFunction={setTextCheck}
                              />
                            </div>
                          </div>
                        );
                      })}
                  </div>
                  {noteError ? (
                    <div className='errorVal'>
                      <img src={warning} alt='warning' className='warning' />
                      {noteError}
                    </div>
                  ) : null}
                </div>
              </div>
              <div className='buttonsDiv d-flex align-items-center '>
                <button
                  onClick={() => {
                    reset();
                    onCloseModal();
                  }}
                  className='cancel-button'
                  type='button'
                >
                  Cancel
                </button>
                <button
                  type='submit'
                  className='saveButton'
                  onClick={() => {
                    setSubmit(true);
                  }}
                  disabled={loading || show === 'closing' || disabled}
                >
                  Save <img className='tick' src={tick} alt='tick-sign' />
                </button>
              </div>
            </form>
          </div>
        </FormModal>
      </StyledQuickOpportunityModal>
    </>
  );
};

export default QuickAddOpportunityModal;
