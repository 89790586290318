import styled from '@emotion/styled';

export const PickerDiv = styled.div`
  .CallenderImg {
    position: absolute;
    right: 0px;
    cursor: pointer;
    pointer-events: none;
    top: 4px;
  }
  display: flex;
  height: 48px;
  align-items: center;
  background: #ffffff;
  padding-right: 10px;
  padding-left: 0px;
  border: 1px solid #cad9f3;
  position: relative;
  &.filled-picker {
    border: 1px solid #262758;
  }
  .Time-SelectorDiv {
    position: absolute;
    left: 0;
    right: 0;
    top: 246px;
    z-index: 5;
    width: 100%;
    box-shadow: none;
  }
  &:hover {
    border: 1px solid #cad9f3;
    box-shadow: 0px 1px 4px rgba(35, 42, 64, 0.2);
  }
  .date-input-placeholder {
    display: none;
  }
  .input-container {
    label {
      position: absolute;
      color: #262758;
      pointer-events: none;
      transform: translate(0, 2px);
      transform-origin: top left;
      transition: 200ms cubic-bezier(0, 0, 0.2, 1) 0ms;
      font-size: 16px;
      left: 16px;
      font-weight: 600;
    }
  }

  .date-input {
    cursor: pointer;
    width: 100%;
    height: 100%;
    padding: 5px 16px;
    outline: none;
    border-radius: 8px;
    border: 0;
    background: #fff !important;
    font-weight: 600;
    overflow: hidden;
    text-overflow: ellipsis;
    :focus-within {
      outline: none;
    }
  }
  .filled {
    label {
      font-size: 12px;
      transform: translate(0, -15px);
      font-weight: 400;
      padding-top: 7px;
    }
  }
  :focus-within {
    border: 1px solid #3c498a;
    outline: none;
    .input-container {
      border: none;
      label {
        font-size: 12px;
        transform: translate(0, -10px);
        font-weight: 400;
      }
    }
  }
  .input-placeholder {
    padding-top: 5px;
  }
  .react-datepicker-popper {
    padding-top: 8px;
    width: 100%;
    min-width: 288px;
    z-index: 4;
  }
  .react-datepicker {
    width: 100%;
    border-radius: 8px;
    right: 0px;
    display: flex;
    border: none;
    box-shadow: 0px 1.75092px 7.00366px rgba(35, 42, 64, 0.2);
    position: relative;
  }
  .react-datepicker__month-container {
    padding: 20px;
    max-height: 250px;
    border-radius: 8px;
    overflow: auto;

    width: 100%;
    background: #fff;
  }
  .react-datepicker__header {
    background: #fff;
    border: none;
    position: unset;
  }
  .react-datepicker__current-month {
    color: #262758;
    font-size: 20px;
    text-align: center;
    line-height: 17.5px;
    button {
      border: 0;
      background: #fff;
      width: 20px;
      height: 21px;
      padding: 0;

      :disabled {
        opacity: 0.2;
      }
    }

    .date-info {
      padding: 0 5px;
    }
  }
  .react-datepicker__month {
    text-align: unset;
    width: 100%;
    margin: 0;
  }
  .react-datepicker__day-names {
    margin: 0;
    width: 100%;
    display: flex;
    margin-top: 20px;
    justify-content: space-between;
  }
  .react-datepicker__day-name {
    color: #3c498a;
    font-size: 14px;
  }
  .react-datepicker__week {
    width: 100%;
    display: flex;
    justify-content: space-between;
  }
  .react-datepicker__navigation {
    right: 0;
    top: 33px;
  }
  .react-datepicker__triangle {
    display: none;
  }
  .react-datepicker__day--today {
    position: relative;
    :before {
      content: '';
      width: 4px;
      height: 4px;
      border-radius: 50%;
      background: #f04d46;
      display: block;
      position: absolute;
      top: 20px;
      left: 12px;
    }
    font-weight: 600;
  }
  .react-datepicker__day:hover {
    border-radius: 50%;
  }
  .react-datepicker__day--outside-month {
    color: #cad9f3;
  }
  .react-datepicker__day--selected {
    background: #c1dbeb;
    border-radius: 50%;
    font-weight: 700;
    color: #262758;
  }
  .react-datepicker__time-box {
    text-align: start;
  }
  .react-datepicker__time-container {
    text-align: start;
    display: none;
  }
  .react-datepicker__day--in-range {
    background: #ecf4f9;
    border-radius: 0px;
    height: 27px;
    color: #262758;
    position: relative;
    :hover {
      border-radius: 0px;
    }
    :after {
      content: '';
      width: 20px;
      height: 27px;
      background: #ecf4f9;
      display: block;
      position: absolute;
      bottom: 0px;
      left: 26px;
    }
  }

  .react-datepicker__day--selected {
    background: #c1dbeb;
    border-radius: 40px;
    z-index: 3;
    :hover {
      border-radius: 40px;
    }
    .react-datepicker__day--in-range {
      :after {
        width: 12px;
        left: 20px;
        height: 27px;
      }
    }
  }
  .react-datepicker__day--range-end {
    background: #c1dbeb;
    border-radius: 40px;
    font-weight: 700;
    z-index: 10;
    :after {
      display: none;
    }
    :hover {
      border-radius: 40px;
    }
  }

  .react-datepicker-time__caption {
    display: none;
  }
  .react-datepicker-time__input {
    width: 210px;
    border: none;
  }
  .react-datepicker__time-container {
    border: none;
    box-shadow: 0px 1.75092px 7.00366px rgba(35, 42, 64, 0.2);
    .react-datepicker__header {
      text-transform: capitalize;
    }
  }
  .react-datepicker__time-container
    .react-datepicker__time
    .react-datepicker__time-box
    ul.react-datepicker__time-list
    li.react-datepicker__time-list-item--selected {
    background-color: #ecf4f9;
    color: #262758;
  }

  @media screen and (min-width: 576px) {
    .react-datepicker-popper {
      width: 288px;
    }
    .react-datepicker__month-container {
      padding: 20px;
      max-height: 300px;
      overflow: auto;
      background: #fff;
      &::-webkit-scrollbar {
        width: 0px;
        background: transparent;
      }
    }
    .Time-SelectorDiv {
      top: 280px;
    }
    .react-datepicker {
      width: 100%;
    }
  }
  @media screen and (min-width: 1200px) {
    .react-datepicker-popper {
      width: 350px;
    }
    .react-datepicker__month-container {
      max-height: 100%;
      overflow: hidden;
    }
    .Time-SelectorDiv {
      top: 287px;
    }
  }
  @media screen and (min-width: 1900px) {
    .react-datepicker-popper {
      width: 374px;
    }
    padding-right: 20px;
    height: 64px;
  }
`;
