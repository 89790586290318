import { useState, ChangeEvent, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useDispatch } from 'react-redux';
import { AppDispatch } from '../../../store';
import { agentProfilePasswordUpdate } from '../../../store/user/userSlice';
import { resetPassword } from '../../../api';
import PasswordValidation from '../../../components/PasswordValidation';
import SuccessCard from '../../../components/SuccessCard';
import LogoutModal from './LogoutModal';
import PasswordField, {
  ConfirmPasswordField,
} from '../../../components/PasswordField';
import LocalStorageService from '../../../utils/LocalStorageService';
import { ErrorDiv } from '../../Register/elements/styles';
import warning from '../../../assets/logo/warning.svg';
import arrow from '../../../assets/logo/right-arrow.svg';
import eye from '../../../assets/logo/eye.svg';
import closedeye from '../../../assets/logo/closedeye.svg';

function ChangePassword() {
  const [oldPassword, setOldPassword] = useState('');
  const [newPassword, setPassword] = useState('');
  const [showCurrentPassword, setShowCurrentPassword] = useState(false);
  const [isPrevFilled, setPrevFilled] = useState(false);
  const [isNewFilled, setNewFilled] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [errorMessage, setError] = useState('');
  const [isReset, setShowIsReset] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [showLogOutModal, setShowLogoutModal] = useState(false);

  const dispatch = useDispatch<AppDispatch>();
  const passwordChange = (e: ChangeEvent<HTMLInputElement>) => {
    setPassword(e.target.value);
  };

  const oldPasswordChange = (e: ChangeEvent<HTMLInputElement>) => {
    setOldPassword(e.target.value);
    setError('');
  };

  const SignupSchema = Yup.object().shape({
    confirmPassword: Yup.string()
      .required('Please enter a password. ')
      .test('Passwords-match', 'Passwords must match', function (value) {
        return newPassword === value;
      }),
  });

  const user = LocalStorageService.getUser();
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
    watch,
  } = useForm({
    resolver: yupResolver(SignupSchema),
    defaultValues: {
      currentPassword: '',
      confirmPassword: '',
      newPassword: '',
    },
  });

  useEffect(() => {}, []);

  const onSubmit = (values: any) => {
    values.email = user?.email;
    values.currentPassword = oldPassword;
    values.newPassword = newPassword;
    values.oldPassword = oldPassword;
    if (!oldPassword) {
      setError('Please provide the current password.');
    } else if (oldPassword === newPassword) {
      setError(`Cant't set old password as the new password`);
    } else {
      dispatch(
        agentProfilePasswordUpdate({
          uuid: user.Agent?.uuid,
          data: values,
          successCB: (data: any) => {
            setPrevFilled(false);
            setNewFilled(false);
            setOldPassword('');
            setPassword('');
            reset();
            setError('');
            setShowLogoutModal(true);
          },
          errorCB: (msg: string) => {
            setError(msg);
          },
        })
      );
    }
  };

  const onResetPassword = () => {
    resetPassword(
      user.email,
      () => {
        setShowIsReset(true);
        setTimeout(() => {
          setShowIsReset(false);
        }, 5000);
      },
      (msg: string) => setError(msg)
    );
  };

  const watchAllFields = watch();

  const toggleVisibility = () => {
    setShowPassword(!showPassword);
  };
  const toggleCurrentVisibility = () => {
    setShowCurrentPassword(!showCurrentPassword);
  };

  const checks = [
    {
      check: (val: string) => val.length >= 8,
      message: 'atleast 8 characters',
    },
    {
      check: (val: string) => /(?=.*[A-Z])/.test(val),
      message: 'atleast one uppercase letter',
    },
    {
      check: (val: string) => /(?=.*[a-z])/.test(val),
      message: 'atleast one lowercase letter',
    },
    {
      check: (val: string) => /(?=.*\d)/.test(val),
      message: 'atleast one number',
    },
    {
      check: (val: string) => /(?=.*[@$!%*?&#])/.test(val),
      message: 'atleast one special character',
    },
  ];
  return (
    <>
      {showLogOutModal && (
        <LogoutModal
          setShowModal={setShowLogoutModal}
          showModal={showLogOutModal}
        />
      )}
      <div className='fieldContainer scroll-bar'>
        <h2 className='subText  d-none d-xl-block m-0'>Change password</h2>
        <p className='sub'>
          Forgot current password?{' '}
          <span className='d-block d-sm-inline-block'>
            You can{' '}
            <b
              className='resetPassText'
              onClick={() => {
                onResetPassword();
              }}
            >
              {' '}
              Reset Password via Email
            </b>
          </span>
        </p>
        {isReset ? (
          <div className='success-div'>
            <SuccessCard
              title={'Success'}
              message={`An email with the instructions to reset the password has been sent to <b>${user.email}</b>.`}
            />
          </div>
        ) : null}

        <div>
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className='Passwordfields'>
              <div className='password-div'>
                <PasswordField
                  name='currentPassword'
                  type={showCurrentPassword ? 'text' : 'password'}
                  passwordValidationProps={{
                    validationRequired: true,
                    value: oldPassword,
                    onPasswordChange: oldPasswordChange,
                  }}
                  isFilled={isPrevFilled}
                  setFilled={setPrevFilled}
                  placeholder='Current Password'
                  className={errorMessage ? 'error-field' : ''}
                />
                {oldPassword && (
                  <div
                    className='toggleButton d-flex align-items-center'
                    onClick={toggleCurrentVisibility}
                  >
                    {!showCurrentPassword ? (
                      <img className='eye' alt='eye-icon' src={eye} />
                    ) : (
                      <img
                        className='closedeye'
                        alt='closedeye-icon'
                        src={closedeye}
                      />
                    )}

                    {showCurrentPassword ? 'Hide' : 'Show'}
                  </div>
                )}
              </div>
            </div>
            <div className='Passwordfields'>
              <div className='password-div'>
                <PasswordField
                  name='newPassword'
                  type={showPassword ? 'text' : 'password'}
                  passwordValidationProps={{
                    validationRequired: true,
                    value: newPassword,
                    onPasswordChange: passwordChange,
                  }}
                  isFilled={isNewFilled}
                  setFilled={setNewFilled}
                  placeholder='Create New Password'
                />
                {newPassword && (
                  <div
                    className='toggleButton d-flex align-items-center'
                    onClick={toggleVisibility}
                  >
                    {!showPassword ? (
                      <img className='eye' alt='eye-icon' src={eye} />
                    ) : (
                      <img
                        className='closedeye'
                        alt='closedeye-icon'
                        src={closedeye}
                      />
                    )}

                    {showPassword ? 'Hide' : 'Show'}
                  </div>
                )}
              </div>
            </div>
            <div className='validationImage'>
              {newPassword ? (
                <PasswordValidation checks={checks} password={newPassword} />
              ) : null}
            </div>
            <div className='Passwordfields'>
              <div className='password-div'>
                <ConfirmPasswordField
                  name='confirmPassword'
                  type={showConfirmPassword ? 'text' : 'password'}
                  placeholder='Confirm New Password'
                  register={register}
                  className={`${errors.confirmPassword ? 'error-field' : ''} ${
                    watchAllFields.confirmPassword && 'filled'
                  }`}
                />

                {watchAllFields.confirmPassword && (
                  <div
                    className='toggleButton d-flex align-items-center'
                    onClick={() => {
                      setShowConfirmPassword(!showConfirmPassword);
                    }}
                  >
                    <img className='eye' alt='eye-icon' src={eye} />
                    Show
                  </div>
                )}
              </div>
              {errors.confirmPassword ? (
                <div className='errorVal errorValPassword'>
                  <img src={warning} alt='warning' className='warning' />
                  {errors.confirmPassword?.message}
                </div>
              ) : null}
              {errorMessage ? (
                <ErrorDiv>
                  <div className='errorVal fieldError'>
                    <img src={warning} alt='warning' className='warning' />
                    <div>{errorMessage}</div>
                  </div>
                </ErrorDiv>
              ) : null}
            </div>
            <button
              type='submit'
              className='submit-button primaryButton password-submit justify-content-between align-items-center d-flex'
              disabled={
                !checks.every((val: { check: Function; message: string }) =>
                  val.check(newPassword)
                )
              }
            >
              Change password
              <img className='arrowl' src={arrow} alt='arrow-function' />
            </button>
          </form>
        </div>
      </div>
    </>
  );
}

export default ChangePassword;
