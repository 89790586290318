import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import Skeleton from 'react-loading-skeleton';
import {
  getSearchResults,
  SearchSliceState,
  selectAllResults,
  selectResults,
} from '../../store/search/searchSlice';
import { AppDispatch } from '../../store';
import SearchCard from './components/SearchCard';
import SideBar from '../../components/SideBar';
import TopBar from '../../components/TopBar';
import MobileNav from '../../components/MobileTabNav';
import SearchBar from '../../components/SearchBar';
import LocalStorageService from '../../utils/LocalStorageService';
import { SearchDiv, SearchContainer } from './style';
import { EmptyDiv } from '../MyBook/elements/styles';
import back from '../../assets/logo/back.svg';
import empty from '../../assets/logo/emptySearch.svg';

const Search = () => {
  const dispatch = useDispatch<AppDispatch>();
  const params = useLocation();

  const navigate = useNavigate();
  const results = useSelector(selectResults);
  const allResults = useSelector(selectAllResults);
  const loadingStatus = useSelector(
    (state: { search: SearchSliceState }) => state.search.status
  );

  const [types, setTypes] = useState([
    'account',
    'contact',
    'opportunity',
    'policy',
    'user',
    'task',
  ]);
  const [key, setKey] = useState('all');

  const searchBy = new URLSearchParams(params.search).get('s') || '';

  useEffect(() => {
    if (searchBy) {
      dispatch(
        getSearchResults({
          searchBy,
          offset: 0,
          limit: 30,
          types,
        })
      );
      LocalStorageService.setHistory({
        text: searchBy,
        type: 'previous',
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [types]);

  const [contactShowAll, setContactShowAll] = useState(false);
  const [accountShowAll, setAccountShowAll] = useState(false);
  const [opportunityShowAll, setOpportunityShowAll] = useState(false);
  const [policyShowAll, setPolicyShowAll] = useState(false);
  const [showSearchDrop, setShowSearchDrop] = useState(false);

  return (
    <SearchContainer>
      <div className='d-none d-xl-block'>
        <SideBar />
      </div>
      <SearchDiv className='content-div'>
        <TopBar>
          <div className='d-none d-xl-flex align-items-center'>
            <button className='back-button' onClick={() => navigate(-1)}>
              <img src={back} alt='' className='back-button-icon' />
              Go back
            </button>
          </div>
          <div className={`d-flex w-100 justify-content-center d-xl-none`}>
            <SearchBar
              showSearchDrop={showSearchDrop}
              setShowSearchDrop={setShowSearchDrop}
              autofocus={true}
            />
          </div>
        </TopBar>
        <div className='search-content-container'>
          {loadingStatus === 'loading' ? (
            <>
              {[1, 2, 3].map((item) => (
                <Skeleton height={70} key={item} className='mb-2' />
              ))}
            </>
          ) : (
            <>
              <div className='nav nav-tabs mb-3'>
                <button
                  className={`nav-link ${key === 'all' ? 'active' : ''}`}
                  onClick={() => {
                    setKey('all');
                    setTypes([
                      'account',
                      'contact',
                      'opportunity',
                      'policy',
                      'user',
                      'task',
                    ]);
                  }}
                >
                  All
                </button>
                <button
                  onClick={() => {
                    setTypes(['account']);
                    setKey('account');
                  }}
                  className={`nav-link ${key === 'account' ? 'active' : ''}`}
                >
                  Accounts
                </button>
                <button
                  onClick={() => {
                    setKey('contact');
                    setTypes(['contact']);
                  }}
                  className={`nav-link ${key === 'contact' ? 'active' : ''}`}
                >
                  Contacts
                </button>
                <button
                  onClick={() => {
                    setKey('opportunity');
                    setTypes(['opportunity']);
                  }}
                  className={`nav-link ${
                    key === 'opportunity' ? 'active' : ''
                  }`}
                >
                  Opportunities
                </button>{' '}
                <button
                  onClick={() => {
                    setKey('policy');
                    setTypes(['policy']);
                  }}
                  className={`nav-link ${key === 'policy' ? 'active' : ''}`}
                >
                  Policies
                </button>
              </div>

              {results.length === 0 ? (
                <EmptyDiv>
                  <img src={empty} alt='' className='empty-img' />
                  <div className='empty-text'>
                    No results found for <b>"{searchBy}"</b>
                  </div>
                </EmptyDiv>
              ) : (
                <>
                  <div className='result-count'>
                    {results.length} results found
                  </div>
                </>
              )}

              {key === 'all' ? (
                <>
                  {allResults.contact?.length > 0 && (
                    <div className='search-result-subcard'>
                      <h5>Contacts</h5>
                      {results
                        .filter((result) => result.model === 'contact')
                        .map((item: any, index: number) =>
                          index < 2 || contactShowAll ? (
                            <SearchCard item={item} searchText={searchBy} />
                          ) : null
                        )}
                      {allResults.contact?.length > 2 ? (
                        <button
                          type='button'
                          className='show-more-button'
                          onClick={() => setContactShowAll(!contactShowAll)}
                        >
                          Show {contactShowAll ? 'less' : 'more'}
                        </button>
                      ) : null}
                    </div>
                  )}

                  {allResults.account?.length > 0 && (
                    <div className='search-result-subcard'>
                      <h5>Accounts</h5>
                      {results
                        .filter((result) => result.model === 'account')
                        .map((item: any, index: number) =>
                          index < 2 || accountShowAll ? (
                            <SearchCard item={item} searchText={searchBy} />
                          ) : null
                        )}
                      {allResults.account?.length > 2 ? (
                        <button
                          type='button'
                          className='show-more-button'
                          onClick={() => setAccountShowAll(!accountShowAll)}
                        >
                          Show {accountShowAll ? 'less' : 'more'}
                        </button>
                      ) : null}
                    </div>
                  )}
                  {allResults.opportunity?.length > 0 && (
                    <div className='search-result-subcard'>
                      <h5>Opportunities</h5>
                      {results
                        .filter((result) => result.model === 'opportunity')
                        .map((item: any, index: number) =>
                          index < 2 || opportunityShowAll ? (
                            <SearchCard item={item} searchText={searchBy} />
                          ) : null
                        )}
                      {allResults.opportunity?.length > 2 ? (
                        <button
                          type='button'
                          className='show-more-button'
                          onClick={() =>
                            setOpportunityShowAll(!opportunityShowAll)
                          }
                        >
                          Show {opportunityShowAll ? 'less' : 'more'}
                        </button>
                      ) : null}
                    </div>
                  )}
                  {allResults.policy?.length > 0 && (
                    <div className='search-result-subcard'>
                      <h5>Policies</h5>
                      {results
                        .filter((result) => result.model === 'policy')
                        .map((item: any, index: number) =>
                          index < 2 || policyShowAll ? (
                            <SearchCard item={item} searchText={searchBy} />
                          ) : null
                        )}
                      {allResults.policy?.length > 2 ? (
                        <button
                          type='button'
                          className='show-more-button'
                          onClick={() => setPolicyShowAll(!policyShowAll)}
                        >
                          Show {policyShowAll ? 'less' : 'more'}
                        </button>
                      ) : null}
                    </div>
                  )}
                  {allResults.task?.length > 0 && (
                    <div className='search-result-subcard'>
                      <h5>Tasks</h5>
                      {results
                        .filter((result) => result.model === 'task')
                        .map((item: any, index: number) =>
                          index < 2 || policyShowAll ? (
                            <SearchCard item={item} searchText={searchBy} />
                          ) : null
                        )}
                      {allResults.task?.length > 2 ? (
                        <button
                          type='button'
                          className='show-more-button'
                          onClick={() => setPolicyShowAll(!policyShowAll)}
                        >
                          Show {policyShowAll ? 'less' : 'more'}
                        </button>
                      ) : null}
                    </div>
                  )}
                </>
              ) : (
                <div className='search-result-subcard'>
                  {results.map((item: any) => (
                    <SearchCard item={item} searchText={searchBy} />
                  ))}
                </div>
              )}
            </>
          )}
        </div>
        <div className='d-block d-xl-none'>
          <MobileNav />
        </div>
      </SearchDiv>
    </SearchContainer>
  );
};

export default Search;
