import { useEffect, useState, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import Moment from 'react-moment';
import Skeleton from 'react-loading-skeleton';
import ContactAddDrawer from './components/ContactAddLongForm';
import { FilterContainer, MyBookDiv } from '../elements/styles';
import { SuccessAlign } from '../../../styles/SuccessAlign';
import ContactsTable from './components/ContactsTable';
import {
  ContactsSliceState,
  getContacts,
  onDeleteContact,
  selectAllContacts,
} from '../../../store/contacts/contactsSlice';
import { AppDispatch } from '../../../store';
import { useOutsideClick } from '../../../hooks/useOutsideClick';
import useDebounce from '../../../hooks/useDebounce';
import Link from '../../../components/Link';
import EmptyResults from '../components/Empty';
import ActionsCard from './components/ActionsCard';
import SuccessCard from '../../../components/SuccessCard';
import PaginationComponent from '../../../components/PaginationComponent';
import Table from '../../../components/Table';
import { StyledTab } from '../../../styles/StyledTab';
import plus from '../../../assets/logo/plus.svg';
import ContactMobileCard from './components/ContactMobileCard';
import { IContact } from '../../../interfaces/Contact';
import {
  getCount,
  selectCount,
  updateCount,
} from '../../../store/book/bookSlice';
import MobileTab from '../../../components/MobileTab';
import dropdown from '../../../assets/logo/dropdown.svg';
import search from '../../../assets/logo/search.svg';
import filter from '../../../assets/logo/filter.svg';
import filtermobile from '../../../assets/logo/filter-mobile.svg';
import close from '../../../assets/logo/cross.svg';
import sort from '../../../assets/logo/sort.svg';
import sortup from '../../../assets/logo/sortup.svg';
import sortdown from '../../../assets/logo/sortdown.svg';
import ContactsFilter from './components/FilterDrawer';
import DeleteModal from '../../../components/DeleteModal';
import ErrorCard from '../../Pipeline/components/ErrorCard';
import EditContactDetails from '../../ContactDetails/EditContactDetails';
const Contacts = () => {
  const tabRef = useRef(null);
  const searchRef = useRef(null);

  const navigate = useNavigate();

  const dispatch = useDispatch<AppDispatch>();
  const contacts = useSelector(selectAllContacts);
  const count = useSelector(selectCount);
  const loadingStatus = useSelector(
    (state: { contacts: ContactsSliceState }) => state.contacts.status
  );
  const contactsCount = useSelector(
    (state: { contacts: ContactsSliceState }) => state.contacts.count
  );

  const [showDropdown, setShowDropdown] = useState(false);
  const [showContact, setAddContact] = useState('closed');
  const [sortBy, setSortBy] = useState('createdAt');
  const [orderBy, setOrderBy] = useState('desc');
  const [searchBy, setSearchBy] = useState('');
  const [limit, setLimit] = useState(25);
  const [page, setPage] = useState(0);
  const [isSearchClicked, setIsSearchClicked] = useState(false);
  const [showEditModal, setShowEditModal] = useState('closed');
  const [UUID, setUUID] = useState('');
  const [emailCopied, setEmailCopied] = useState(false);

  const debouncedSearchTerm = useDebounce(searchBy, 500);
  useEffect(() => {
    dispatch(getCount());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (loadingStatus === 'idle' || loadingStatus === 'succeeded') {
      dispatch(
        getContacts({
          sortBy,
          orderBy,
          searchBy: debouncedSearchTerm,
          limit,
          offset: page * limit,
          type: accountType.map((item: any) => item.value),
          jurisdictionsUUID,
          producerUUID: producer,
          successCB: (data: any) => {
            dispatch(updateCount({ type: 'contactsCount', count: data.count }));
          },
        })
      );
    } // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, sortBy, orderBy, debouncedSearchTerm, limit, page]);

  useOutsideClick(tabRef, setShowDropdown, {
    ref: searchRef,
    setShow: setIsSearchClicked,
  });

  const onShowEditModal = (uuid: string) => {
    setShowEditModal('open');
    setUUID(uuid);
  };

  const handleSort = (sort: string) => {
    if (sort === sortBy) {
      if (orderBy === 'asc') {
        setOrderBy('desc');
      } else {
        setOrderBy('asc');
      }
    } else {
      setSortBy(sort);
    }
  };

  const handleCopy = (e: any, value: string) => {
    e.preventDefault();
    navigator.clipboard.writeText(value);
    setEmailCopied(true);
    setTimeout(() => {
      setEmailCopied(false);
    }, 2000);
  };

  const [showFilter, setShowFilter] = useState('closed');

  const filterContact = (
    type: any,
    jurisdictionsUUID: string,
    producerUUID: string
  ) => {
    setPage(0);
    dispatch(
      getContacts({
        sortBy,
        orderBy,
        searchBy: debouncedSearchTerm,
        limit,
        offset: page * limit,
        type,
        jurisdictionsUUID,
        producerUUID,
        successCB: (data: any) => {
          dispatch(updateCount({ type: 'contactsCount', count: data.count }));
        },
      })
    );
  };
  const [jurisdictionsUUID, setJurisdictionsUUID] = useState('');
  const [producer, setProducer] = useState('');
  const [accountType, setAccountType] = useState([]);
  const [producerSearch, setProducerSearch] = useState('');
  const [licenseSearch, setLicenseSearch] = useState('');

  const [name, setName] = useState('');
  const [showDeleteModal, setShowDeleteModal] = useState('closed');
  const [showSuccess, setShowSuccess] = useState(false);
  const onDelete = (uuid: string) => {
    dispatch(
      onDeleteContact({
        uuid,
        successCB: () => {
          dispatch(getCount());
          setShowDeleteModal('closing');
          setTimeout(() => {
            setShowSuccess(true);
          }, 100);
          setTimeout(() => {
            setShowDeleteModal('closed');
            setShowSuccess(false);
          }, 2000);
        },
      })
    );
  };

  return (
    <MyBookDiv>
      {showSuccess && (
        <ErrorCard message={`<b>${name}</b> is <b> deleted</b> `} show='open' />
      )}
      <button
        onClick={() => setAddContact('open')}
        className='addButton d-sm-none'
      >
        <img src={plus} alt='add' />
      </button>
      <div className='d-sm-none mb-4'>
        <StyledTab ref={tabRef}>
          <Link
            to='/mybook/contacts'
            onClick={(e) => {
              setShowDropdown(!showDropdown);
            }}
          >
            Contacts
            <span className='count'>{count.contacts}</span>
            <img src={dropdown} alt='' />
          </Link>
          {showDropdown && <MobileTab />}
        </StyledTab>
      </div>
      <div className='d-flex justify-content-between'>
        <button
          className='addButton d-none d-sm-block'
          onClick={() => setAddContact('open')}
          disabled={showContact !== 'closed'}
        >
          Add contact
        </button>
        <div className='book-filter d-flex ms-auto' ref={searchRef}>
          {isSearchClicked || searchBy ? (
            <div className='book-search d-flex align-items-center'>
              <img src={search} alt='' className='search-button' />
              <input
                onChange={(e) => {
                  setPage(0);
                  setSearchBy(e.target.value);
                }}
                autoFocus
                value={searchBy}
              />
              <img
                src={close}
                alt=''
                className='close-button'
                onClick={() => {
                  setPage(0);
                  setSearchBy('');
                  setIsSearchClicked(false);
                }}
              />
            </div>
          ) : (
            <div
              className='book-shrinked'
              onClick={() => setIsSearchClicked(true)}
            >
              <img src={search} alt='' />
            </div>
          )}

          <span className='divider d-none d-sm-block' />
          <div
            className='book-shrinked d-none d-sm-flex'
            onClick={() => setShowFilter('open')}
          >
            <img src={filter} alt='' />
            {accountType.length +
              accountType.length +
              (jurisdictionsUUID ? 1 : 0) +
              (producer ? 1 : 0) >
              0 && (
              <span className='filter-counter'>
                {accountType.length +
                  (jurisdictionsUUID ? 1 : 0) +
                  (producer ? 1 : 0)}
              </span>
            )}
          </div>
          <span
            className={`divider d-sm-none ${isSearchClicked && 'd-none'}`}
          />
          <div
            className={`book-shrinked d-sm-none ${isSearchClicked && 'd-none'}`}
            onClick={() => setShowFilter('open')}
          >
            <img src={filtermobile} alt='' />
            {accountType.length +
              (jurisdictionsUUID ? 1 : 0) +
              (producer ? 1 : 0) >
              0 && (
              <span className='filter-counter'>
                {accountType.length +
                  (jurisdictionsUUID ? 1 : 0) +
                  (producer ? 1 : 0)}
              </span>
            )}
          </div>
        </div>
      </div>
      <div className='mybook-content'>
        <div className='d-sm-none'>
          {loadingStatus === 'loading' ? (
            [1, 2, 3].map((item) => <Skeleton height={138} key={item} />)
          ) : loadingStatus === 'succeeded' && contacts.length > 0 ? (
            <>
              {contacts.map((item: IContact) => (
                <ContactMobileCard
                  contact={item}
                  key={item.uuid}
                  setShowDeleteModal={setShowDeleteModal}
                  setUUID={setUUID}
                  setName={setName}
                />
              ))}
              <PaginationComponent
                limit={limit}
                setLimit={setLimit}
                page={page}
                setPage={setPage}
                count={contactsCount}
              />
            </>
          ) : (
            <EmptyResults name='contact' />
          )}
        </div>

        <div className='d-none d-sm-block'>
          {loadingStatus === 'loading' || contacts?.length === 0 ? (
            <ContactsTable
              contacts={contacts}
              setSortBy={setSortBy}
              sortBy={sortBy}
              setOrderBy={setOrderBy}
              orderBy={orderBy}
            />
          ) : (
            <Table
              limit={limit}
              setLimit={setLimit}
              page={page}
              setPage={setPage}
              count={contactsCount}
              className='mybook-table'
              columns={[
                {
                  accessor: 'firstName',
                  resizable: true,
                  sortable: true,
                  minWidth: 180,
                  Header: () => (
                    <div
                      onClick={() => handleSort('firstName')}
                      style={{ cursor: 'pointer' }}
                      className='d-flex justify-content-between w-100'
                    >
                      <span>Contact name</span>
                      <img
                        className='sort'
                        src={
                          sortBy !== 'firstName'
                            ? sort
                            : orderBy === 'asc'
                            ? sortdown
                            : sortup
                        }
                        alt='sort'
                      />
                    </div>
                  ),
                  Cell: ({ row }: { row: any }) => (
                    <div className='row-name d-flex align-items-center text-overflow-container'>
                      <b
                        onClick={() => {
                          navigate(`/contacts/${row.original.uuid}`);
                        }}
                        className='overflowed-text '
                      >
                        {row.original.firstName
                          ? row.original.firstName + ' ' + row.original.lastName
                          : row.original.lastName}
                      </b>
                      <span className='tooltiptext tooltip-producer'>
                        <span className='tooltipInnertext'>
                          {row.original.firstName
                            ? row.original.firstName +
                              ' ' +
                              row.original.lastName
                            : '--'}
                        </span>
                      </span>
                    </div>
                  ),
                },
                {
                  accessor: 'type',
                  Header: 'Type',
                  resizable: true,
                  Cell: ({ row }: { row: any }) =>
                    row.original.AccountContacts?.length <= 0
                      ? 'No account connected'
                      : row.original.AccountContacts &&
                        row.original.AccountContacts[0]?.Account?.AccountType?.name.charAt(
                          0
                        ) +
                          row.original.AccountContacts[0]?.Account?.AccountType?.name
                            .slice(1)
                            .toLowerCase(),
                },
                {
                  accessor: 'createdAt',
                  resizable: true,
                  sortable: true,
                  minWidth: 140,
                  Header: () => (
                    <div
                      onClick={() => handleSort('createdAt')}
                      style={{ cursor: 'pointer' }}
                      className='d-flex justify-content-between w-100'
                    >
                      <span>Created date</span>
                      <img
                        className='sort'
                        src={
                          sortBy !== 'createdAt'
                            ? sort
                            : orderBy === 'asc'
                            ? sortdown
                            : sortup
                        }
                        alt='sort'
                      />
                    </div>
                  ),
                  Cell: ({ row }: { row: any }) => (
                    <Moment format='MM/DD/YYYY'>
                      {row.original.createdAt}
                    </Moment>
                  ),
                },
                {
                  accessor: 'email',
                  Header: 'Email',
                  resizable: true,
                  minWidth: 150,
                  Cell: ({ row }: { row: any }) => (
                    <div
                      className='row-name'
                      onClick={(event) => {
                        handleCopy(
                          event,
                          `${
                            row.original.ContactDetails?.find(
                              (item: any) => item.type === 'EMAIL'
                            )?.value
                          }`
                        );
                      }}
                    >
                      {
                        row.original.ContactDetails?.find(
                          (item: any) => item.type === 'EMAIL'
                        )?.value
                      }
                    </div>
                  ),
                },
                {
                  accessor: 'phone',
                  Header: 'Phone number',
                  resizable: true,
                  minWidth: 134,
                  Cell: ({ row }: { row: any }) =>
                    row.original.ContactDetails?.find(
                      (item: any) => item.type === 'PHONENUMBER'
                    )?.value,
                },
                {
                  accessor: 'actions',
                  Header: 'Actions',
                  sticky: 'right',
                  resizable: false,
                  width: 200,
                  minWidth: 200,
                  maxWidth: 100,
                  Cell: ({ row }: { row: any }) => (
                    <>
                      <ActionsCard
                        contact={row.original}
                        onShowEditModal={onShowEditModal}
                        setShowDeleteModal={setShowDeleteModal}
                        setUUID={setUUID}
                        setName={setName}
                      />
                    </>
                  ),
                },
              ]}
              data={contacts}
            />
          )}
        </div>
      </div>
      {showEditModal !== 'closed' && (
        <EditContactDetails
          show={showEditModal}
          closeModal={setShowEditModal}
          uuid={UUID}
        />
      )}
      {emailCopied && (
        <SuccessAlign>
          <div className={`success-container ${emailCopied && 'open-drawer'} `}>
            <SuccessCard
              title={'Success Copying Email'}
              message={`Email has been added to clipboard<b>`}
            />
            <button
              onClick={() => {
                setEmailCopied(false);
              }}
              className='crossbutton'
            >
              <img src={close} className='cross' alt='cross-icon' />
            </button>
          </div>
        </SuccessAlign>
      )}
      {showContact !== 'closed' && (
        <ContactAddDrawer show={showContact} closeModal={setAddContact} />
      )}
      {showDeleteModal !== 'closed' && (
        <DeleteModal
          showModal={showDeleteModal}
          closeModal={setShowDeleteModal}
          deleteFunction={onDelete}
          uuid={UUID}
          loading={loadingStatus}
          resource='contact'
          name={name}
        />
      )}
      <FilterContainer>
        {showFilter !== 'closed' && (
          <ContactsFilter
            show={showFilter}
            closeModal={setShowFilter}
            filterFunction={filterContact}
            jurisdictionsUUID={jurisdictionsUUID}
            setJurisdictionsUUID={setJurisdictionsUUID}
            producer={producer}
            setProducer={setProducer}
            accountType={accountType}
            setAccountType={setAccountType}
            producerSearch={producerSearch}
            setProducerSearch={setProducerSearch}
            licenseSearch={licenseSearch}
            setLicenseSearch={setLicenseSearch}
          />
        )}
      </FilterContainer>
    </MyBookDiv>
  );
};

export default Contacts;
