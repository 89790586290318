import styled from '@emotion/styled';

export const TableDiv = styled.table`
  width: 100%;
  .sort {
    cursor: pointer;
  }
  thead {
    tr {
      border-radius: 16px;
    }
    th {
      background: #f5f7fc;
      font-weight: 500;
      color: #262758;
      font-size: 14px;
      padding: 14px 16px;
      border-left: 1px solid #e6e9f0;
    }

    th:first-of-type {
      border-radius: 10px 0 0 0;
      border-left: none;
    }

    th:last-child {
      border-radius: 0 10px 0 0;
    }
  }

  tbody {
    background: #fff;
  }

  .form-check-input {
    height: 20px;
    width: 20px;
    margin-right: 16px;
  }

  .agencyNameHead {
    width: 24%;
  }
  .statusContainerHead {
    width: 14%;
  }
  .dateFieldHead {
    width: 17%;
  }
  .userGroupFieldHead {
    width: 20%;
  }
  .actionFieldHead {
    width: 25%;
  }
  .roleFieldHead {
    width: 17%;
  }
  .contactFieldHead {
    width: 16%;
  }
  .leadsEmailFieldHead {
    width: 15%;
  }
  .leadsPhoneFieldHead {
    width: 13%;
  }
  .opportunityFieldHead {
    width: 18%;
  }
  .leadActionsFieldHead {
    width: 20%;
  }

  @media screen and (min-width: 1200px) {
    .agencyNameHead {
      width: 20%;
    }
    .statusContainerHead {
      width: 11%;
    }
    .dateFieldHead {
      width: 13%;
    }
    .userGroupFieldHead {
      width: 20%;
    }
    .usersFieldHead {
      width: 15%;
    }
    .actionFieldHead {
      width: 20%;
    }
    .roleFieldHead {
      width: 14%;
    }
    .emailFieldHead {
      width: 17%;
    }
    .phoneFieldHead {
      width: 16%;
    }
    .producerFieldHead {
      width: 9.5%;
    }
    .leadActionsFieldHead {
      width: 17%;
    }
  }

  @media screen and (min-width: 1900px) {
    thead {
      th {
        font-size: 16px;
        padding: 16px;
      }
    }
  }

  @media screen and (min-width: 2800px) {
    thead {
      th {
        font-size: 32px;
        padding: 32px;
      }
    }

    .form-check-input {
      width: 40px;
      height: 40px;
    }

    .sort {
      width: 40px;
    }

    .filter-icon {
      width: 21px;
    }
  }
`;
