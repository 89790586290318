import sort from '../../../../assets/logo/sort.svg';
import sortup from '../../../../assets/logo/sortup.svg';
import sortdown from '../../../../assets/logo/sortdown.svg';
import OpportunityCard from './OppurtunityCard';
import { IOpportunity } from '../../../../interfaces/Opportunity';
import { TableDiv } from '../../../../styles/TableDiv';
import LoadingSkeleton from '../../components/Loading';
import EmptyResults from '../../components/Empty';
import { useSelector } from 'react-redux';
import { OpportunitiesSliceState } from '../../../../store/opportunities/opportunitiesSlice';

const OpportunityTable = ({
  opportunities,
  setSortBy,
  setOrderBy,
  sortBy,
  orderBy,
}: {
  opportunities: IOpportunity[];
  setSortBy: Function;
  setOrderBy: Function;
  sortBy: string;
  orderBy: string;
}) => {
  const loadingStatus = useSelector(
    (state: { opportunities: OpportunitiesSliceState }) =>
      state.opportunities.status
  );

  const handleSort = (sort: string) => {
    if (sort === sortBy) {
      if (orderBy === 'asc') {
        setOrderBy('desc');
      } else {
        setOrderBy('asc');
      }
    } else {
      setSortBy(sort);
    }
  };

  return (
    <TableDiv>
      <thead>
        <tr>
          <th className='agencyNameHead'>
            <div className='d-flex'>
              <div className='w-75 d-flex justify-content-between'>
                Opportunity name
                <img
                  className='sort'
                  src={
                    sortBy !== 'name'
                      ? sort
                      : orderBy === 'asc'
                      ? sortdown
                      : sortup
                  }
                  alt='sort'
                  onClick={() => handleSort('name')}
                />
              </div>
            </div>
          </th>
          <th className='statusContainerHead'>Account name</th>
          <th className='statusContainerHead'>Type</th>
          <th className='dateFieldHead'>Stage</th>
          <th className='leadsEmailFieldHead d-none d-xl-table-cell'>
            Producer
          </th>
          <th className='leadsPhoneFieldHead d-none d-xl-table-cell'>
            <div className=' d-flex justify-content-between '>
              Premium{' '}
              <img
                className='sort'
                src={
                  sortBy !== 'premium'
                    ? sort
                    : orderBy === 'asc'
                    ? sortdown
                    : sortup
                }
                alt='sort'
                onClick={() => handleSort('premium')}
              />
            </div>
          </th>
          <th className='leadActionsFieldHead'>Actions</th>
        </tr>
      </thead>
      <tbody>
        {loadingStatus === 'loading' ? (
          <LoadingSkeleton columns={7} />
        ) : loadingStatus === 'succeeded' && opportunities.length > 0 ? (
          opportunities.map((el) => (
            <OpportunityCard opportunity={el} key={el.uuid} />
          ))
        ) : (
          <tr className='empty-div'>
            <td colSpan={5} className='d-xl-none'>
              <EmptyResults name='opportunity' />
            </td>
            <td colSpan={7} className='d-none d-xl-table-cell'>
              <EmptyResults name='opportunity' />
            </td>
          </tr>
        )}
      </tbody>
    </TableDiv>
  );
};

export default OpportunityTable;
