import styled from '@emotion/styled';
import { Modal } from 'react-bootstrap';
import circle from '../../../assets/logo/circle.svg';

export const KanbanDiv = styled.div`
  .scroll-div {
    height: 60vh;

    overflow-y: auto;
    &::-webkit-scrollbar {
      width: 4px;
      height: 159px;
      display: none;
    }

    &::-webkit-scrollbar-track {
      border-radius: 8px;
    }

    &::-webkit-scrollbar-thumb {
      background: #e6e9f0;
    }
  }
  .stages-div {
    border-bottom: 1px solid #e6e9f0;
    border-top: 1px solid #e6e9f0;
    margin: 0px;

    @media screen and (max-width: 576px) {
      border: none;
      text-transform: uppercase;
    }
  }

  .stage-header {
    padding: 16px 12px 10px 12px;
    font-size: 12px;
    color: #000000;

    .count-div {
      background: #e6e9f0;
      padding: 2px 12px;
      border-radius: 16px;
      font-size: 12px;
      margin-left: 12px;
    }
  }

  .slider-wrapper {
    padding: 2px 25px 0 2px;
  }
  .carousel .slider-wrapper {
    overflow: visible;
  }
  .carousel .carousel-slider {
    overflow: visible;
    z-index: 0;
  }

  .carousel .slide img {
    width: fit-content;
    overflow: scroll;
  }

  .draggable {
    background: #ffffff;
    cursor: pointer;
    border-radius: 8px;
    box-shadow: 0px 1px 4px 0px #26275833;
    color: #5a5f70;
    padding: 12px;
    margin-bottom: 16px;
    height: 84px;
    margin-right: 21px;
    font-size: 12px;
    :hover {
      box-shadow: 2px 4px 4px 2px #26275833;
    }

    .lastupdate {
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    @media screen and (max-width: 576px) {
      margin-bottom: 40px;
    }
  }

  .card-title {
    font-size: 12px;
    color: #191919;
    font-weight: 600;
    margin-bottom: 4px;
    padding-right: 8px;
    margin-right: 8px;
    max-width: 400px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;

    :hover {
      text-decoration: underline;
      cursor: pointer;
      text-decoration-color: #3c498a;
      text-decoration-thickness: 2px;
    }
  }

  .amount {
    font-size: 12px;
    color: #262758;
    font-weight: 600;
  }

  .hover-div {
    display: flex;
    position: fixed;
    padding: 0px 32px 20px 32px;
    width: 100%;
    left: 0;
    bottom: 0;
    height: 70px;
    background: #fafafa;
    z-index: 3;

    button {
      height: 40px;
      border-radius: 8px;
      background: #ffffff;
      font-weight: 600;
      max-width: 100%;

      img {
        margin-right: 16px;
      }

      @media screen and (max-width: 576px) {
        max-width: 60%;
        margin: 0 7px;
      }
    }
  }

  .showButton {
    background: none;
    border: 0;
    font-weight: 600;
  }

  .deleteButton {
    border: 1.5px solid #cc0000;
    color: #cc0000;
    width: 100%;
    :hover {
      background: #cc0000;
      color: #fff;
      img {
        filter: brightness(0) saturate(100%) invert(100%) sepia(0%)
          saturate(7500%) hue-rotate(134deg) brightness(98%) contrast(105%);
      }
    }
  }
  .xDateButton {
    display: inline-block;
    white-space: nowrap;
    text-overflow: ellipsis;
    border: 1.5px solid #262758;
    color: #262758;
    margin-right: 14px;
    width: 100%;
    img {
      filter: brightness(0) saturate(100%) invert(12%) sepia(8%) saturate(4027%)
        hue-rotate(188deg) brightness(95%) contrast(86%);
    }
    :hover {
      background: #262758;
      color: #fff;
      img {
        filter: brightness(0) saturate(100%) invert(100%) sepia(0%)
          saturate(7500%) hue-rotate(134deg) brightness(98%) contrast(105%);
      }
    }
  }
  .vector {
    background: #f5f7fc;
    border-radius: 8px;
    width: 32px;
    height: 32px;
    text-align: center;
    cursor: pointer;
    border: none;
    bottom: 10px;
  }
  .dropdown-div {
    position: absolute;
    background: #fff;
    box-shadow: 0px 1px 4px 0px #26275833;
    border-radius: 8px;
    padding: 4px 16px;
    right: 0;
    top: 35px;
    font-size: 14px;
    z-index: 4;
    width: 170px;
    cursor: pointer;

    div {
      padding: 4px 0;
    }

    .delete-div {
      color: #9a0a0d;
      display: flex;
      justify-content: space-between;
      align-items: center;
      border-top: 1px solid #e6e9f0;
    }

    .quotediv {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
    .edit-div {
      display: flex;
      justify-content: space-between;
      align-items: center;
      border-top: 1px solid #e6e9f0;
    }
  }
  @media screen and (min-width: 576px) {
    .stage-header {
      padding: 15px 12px 12px 12px;
    }
    .stages-div {
      margin: 0px 0 12px 0;
    }
  }

  @media screen and (min-width: 1200px) {
    .hover-div {
      padding: 0px 48px 32px 122px;
    }
    .dropdown-div {
      position: absolute;
      background: #fff;
      box-shadow: 0px 1px 4px 0px #26275833;
      border-radius: 8px;
      padding: 4px 16px;
      bottom: -128px;
      font-size: 14px;
      z-index: 1;
      width: 194px;
      height: 139px;
      cursor: pointer;

      div {
        padding: 4px 0;
      }

      .delete-div {
        color: #9a0a0d;
        display: flex;
        justify-content: space-between;
        align-items: center;
        border-top: 1px solid #e6e9f0;
      }

      .quotediv {
        display: flex;
        justify-content: space-between;
        align-items: center;
      }
      .edit-div {
        display: flex;
        justify-content: space-between;
        align-items: center;
        border-top: 1px solid #e6e9f0;
      }
    }
    .Wonopportunity {
      height: 107px;
    }
  }

  @media screen and (min-width: 1900px) {
    .hover-div {
      padding: 0px 120px 32px 208px;

      button {
        height: 48px;
      }
    }
    .deleteButton {
      margin-right: 40px;
    }
  }

  @media screen and (max-width: 576px) {
    .hover-div {
      padding: 0;
      box-shadow: 0px 2px 8px 0px #26275833;

      button {
        border: 0;
        border-radius: 0;
        height: 100%;
        margin: 0;
        padding: 1px 50px;
      }

      .deleteButton {
        border-right: 1px solid #d1d6e5;
      }
    }

    .active-carousel {
      .carousel-slider {
        z-index: 1;
        overflow: visible;
      }
      .control-dots {
        z-index: 0;
      }
    }

    .carousel .control-dots {
      display: flex;
      justify-content: center;

      overflow-x: auto;
      padding-bottom: 10px;

      &::-webkit-scrollbar {
        display: none;
      }
    }
    .carousel .control-dots .dot {
      background: #d9d9d9;
      box-shadow: none;
      min-width: 8px;
    }
    .carousel .control-dots .dot.selected,
    .carousel .control-dots .dot:hover {
      background: #5c698c;
    }
    .stage-container {
      margin-bottom: 60px;
    }
    .stage-singles {
      max-height: 180px;
    }
  }
`;

export const PipelineDiv = styled.div`
  .contents-div {
    padding: 12px 32px 60px 32px;
    @media screen and (max-width: 576px) {
      overflow: hidden;
      padding-right: 0px;
    }
  }
  .addOppostunityButtonMobile {
    position: fixed;
    bottom: 80px;
    right: 20px;
    width: 48px;
    height: 48px;
    background: #000;
    border-radius: 50px;
    border: none;
    z-index: 1;
  }
  .pipelineBody {
    &::-webkit-scrollbar {
      height: 4px;
      width: 129px;
    }
    &::-webkit-scrollbar-track {
      border-radius: 8px;
    }
    &::-webkit-scrollbar-thumb {
      background: #e6e9f0;
    }
  }

  .filter-counter {
    background: #cc0000;
    color: #fff;
    font-size: 12px;
    line-height: 12px;
    border: 1px solid #fff;
    border-radius: 50px;
    position: absolute;
    top: 2px;
    right: 0px;
    display: flex;
    justify-content: center;
    width: 17px;
    height: 17px;
    align-items: center;
  }

  .pipeline-top-nav {
    @media screen and (max-width: 576px) {
      width: 100%;
    }
  }

  .topButtonsDiv {
    margin: 20px 0;

    @media screen and (max-width: 576px) {
      padding-right: 32px;
    }
  }

  .activeNav {
    background: #c1dbeb;
  }

  .toggleButtons {
    margin-right: 16px;
    background: #f5f7fc;
    height: 40px;
    border-radius: 8px;
    padding: 4px;
  }

  .navbuttons {
    width: 32px;
    height: 32px;
    border-radius: 10px;

    img {
      width: 20px;
    }
  }

  .tertiaryButton {
    width: 176px;
    height: 32px;
  }

  .dropDownMenu {
    position: relative;
    background: none;
    border: none;
    border-radius: 8px;
    margin-top: 24px;
    background: #e6e9f0;

    .menu-button {
      :hover {
        background: #e6e9f0;
        border-radius: 8px;
      }
    }

    .dropdownValue {
      font-size: 14px;
      font-weight: 600;
      margin-right: 13px;
    }

    .pipelineDropDown {
      position: absolute;
      z-index: 2;
      top: 40px;
      right: 0;
      border-radius: 8px;
      width: 300px;
      box-shadow: 0px 4px 16px 0px #26275833;
      background: #fff;
      padding: 16px;
      @media screen and (max-width: 576px) {
        right: unset;
      }
      .optionsDiv {
        text-align: initial;
        padding-bottom: 13px;
        border-bottom: 1px solid #e6e9f0;
        max-height: 30vh;
        overflow: hidden;

        h6 {
          color: #5c698c;
          font-weight: 600;
          font-size: 12px;
          letter-spacing: 2px;
        }
      }

      .option {
        padding: 8px 12px;
        height: 40px;
        border-radius: 8px;
        margin-top: 4px;

        .pipelineEditIcon {
          display: none;
        }
        :hover {
          background: #e6e9f0;
          .pipelineEditIcon {
            display: block;
          }
        }
      }
      .active {
        background: #c1dbeb;
        font-weight: 600;
        .pipelineEditIcon {
          display: block;
        }
      }
      .addOptionButton {
        display: flex;
        padding-top: 12px;
        text-align: initial;
        align-items: center;
      }
    }
  }

  .divider {
    border: 1px solid #e6e9f0;
    height: 26px;
    margin: 0 5px;
  }

  .widgets {
    width: 32px;
    height: 32px;
    display: flex;
    justify-content: center;

    img {
      width: 20px;
    }

    :hover {
      background: #e6e9f0;
      border-radius: 8px;
      cursor: pointer;
    }
  }

  .searchActive {
    background: #e6e9f0;
    border-radius: 8px;
    height: 32px;
    width: 300px;
    padding: 0 10px;
    input {
      padding: 0 5px;
    }
    input {
      outline: none;
      border: none;
      background: #e6e9f0;
      width: 100%;
      z-index: 4;
    }
  }
  .pipeline-options {
    text-align: initial;
    padding-bottom: 20px;
    max-height: 30vh;
    overflow: scroll;
    &::-webkit-scrollbar {
      width: 0px;
      background: transparent;
    }
  }
  @media screen and (min-width: 576px) {
    .contents-div {
      padding: 85px 32px 60px 32px;
      @media screen and (max-width: 576px) {
        overflow: hidden;
      }
    }
    .dropDownMenu {
      margin-top: 0px;
      background: transparent;
      .dropdownValue {
        font-size: 16px;
      }
      :hover {
        background: #e6e9f0;
        padding-left: 10px;
      }
    }
  }

  @media screen and (min-width: 1200px) {
    .contents-div {
      padding: 72px 48px 20px 122px;
    }

    .topButtonsDiv {
      margin: 28px 0 24px 0;
    }

    .tertiaryButton {
      width: 214px;
      height: 40px;
    }

    .widgets {
      width: 40px;
      height: 40px;
    }

    .searchActive {
      height: 40px;
      width: 300px;
    }
  }
  @media screen and (min-width: 1900px) {
    .contents-div {
      padding: 84px 120px 32px 208px;
    }

    .topButtonsDiv {
      margin: 36px 0 29px 0;
    }

    .toggleButtons {
      margin-right: 20px;
      height: 48px;
    }

    .navbuttons {
      height: 40px;
      width: 40px;

      img {
        width: 25px;
      }
    }

    .tertiaryButton {
      width: 277px;
      height: 48px;
    }

    .divider {
      margin: 0 12px;
    }

    .widgets {
      width: 48px;
      height: 48px;
    }

    .searchActive {
      height: 48px;
      width: 300px;
    }
  }
`;

export const PipeLineOpportunity = styled.div`
  .book-search {
    background: #e6e9f0;
    height: 30px;
    border-radius: 8px;
    input {
      border: none;
      background: #e6e9f0;
    }
  }

  .addButton {
    position: fixed;
    bottom: 80px;
    width: 48px;
    height: 48px;
    right: 20px;
    border: none;
    background: #262758;
    border-radius: 50%;
    z-index: 1;
  }
`;

export const StyledModal = styled(Modal)`
  .closeButton {
    position: absolute;
    right: 18px;
    top: 18px;
    cursor: pointer;
  }

  h2 {
    margin-bottom: 0;
  }

  .sub-heading {
    font-size: 14px;
    margin-bottom: 24px;
  }

  .action-div {
    font-size: 16px;
    font-weight: 600;
    padding-bottom: 16px;
    margin-bottom: 16px;
    border-bottom: 1px solid #e6e9f0;
  }

  .action {
    margin-top: 16px;
    border: 1px solid #cad9f3;
    padding: 14px 18px;
    border-radius: 8px;
    cursor: pointer;
    display: flex;
    align-items: center;
  }

  .action.active {
    border: 1px solid #262758;
  }

  .form-check-input {
    margin-right: 16px;
    width: 20px;
    height: 20px;
  }

  .form-check-input:checked[type='radio'] {
    background-image: url(${circle});
    background-color: #fff;
    box-shadow: none;
  }

  .buttonsDiv {
    border-top: 1px solid #e6e9f0;
    padding: 32px 0px 5px 0px;
    justify-content: right;
  }

  .primaryButton {
    height: 48px;
    width: 168px;
    margin-left: 30px;
  }
  .fields {
    margin: 20px 0px;
  }
  .Xdate-fields {
    margin: 20px 0px;
  }
  .textarea {
    width: 100%;
    height: 116px;
    border: 1px solid #cad9f3;
    border-radius: 8px;
    padding: 14px 16px 4px;
    ::placeholder {
      padding-top: 2px;
      font-size: 14px;
    }
  }

  .secondaryButton {
    border: none;
    background: none;
    font-size: 16px;
    color: #262758;
    font-weight: 600;
  }
  .removeButton {
    height: 64px;
    width: 262px;
    color: #f5f7fc;
    border-radius: 8px;
    border: none;
    background: #cc0000;
    margin-left: 30px;
    font-weight: 600;
  }
  .DeleteButton {
    width: 219px;
    height: 48px;
    color: #f5f7fc;
    border-radius: 8px;
    border: none;
    background: #cc0000;
    margin-left: 30px;
  }
  .arrowl {
    margin-left: 28px;
  }
  .modalContainer {
    max-width: 580px;
    height: 420px;
  }
  .DeleteModalContainer {
    max-width: 457px;
    height: 384px;
  }
  .SubmitButton {
    width: 219px;
    height: 48px;
    color: #f5f7fc;
    padding: 0 20px;
    border-radius: 8px;
    border: none;
    background: #262758;
    margin-left: 30px;
  }

  .errorVal {
    margin-top: 12px;
    font-size: 14px;
    color: #cc0000;
    display: flex;
    align-items: center;

    .warning {
      width: 20px;
      margin-right: 10px;
    }
  }

  @media screen and (max-width: 576px) {
    .modal-content {
      height: 100vh;
      border: none;
      padding: 20px;
    }
    .modal-dialog {
      margin: 0;
    }
    .buttonsDiv {
      margin-top: auto;
    }

    &.fade {
      .drawer-dialog {
        transform: translate(0, 50px);
      }
    }
    &.show {
      .drawer-dialog {
        transform: none;
      }
    }
    .drawer-content {
      position: fixed;
      height: fit-content;
      bottom: 0;
      width: 100vw;
      border-radius: 16px 16px 0px 0px;
      padding: 56px 20px 32px 20px;
      border: 0;
      box-shadow: 0px 4px 16px 0px #26275833;
    }
  }
`;

export const StyledModalMobile = styled(Modal)`
  .modal-dialog {
    position: fixed;
    margin: 0;
    bottom: -2px;
  }
  &.fade {
    .modal-dialog {
      transform: translate(0, 50px);
    }
  }
  &.show {
    .modal-dialog {
      transform: none;
    }
  }
  .modal-content {
    width: 100vw;
    border-radius: 16px 16px 0px 0px;
    padding: 56px 20px 40px 20px;
  }
  .top-notch {
    position: absolute;
    width: 88px;
    top: 18px;
    right: 38%;
    border: 3px solid #e6e9f0;
  }
  .closeButton {
    position: absolute;
    right: 18px;
    top: 18px;
    cursor: pointer;
  }

  h2 {
    margin-bottom: 20px;
  }

  .sub-heading {
    font-size: 14px;
    margin-bottom: 24px;
  }

  .action-div {
    font-size: 16px;
    font-weight: 600;
    padding-bottom: 16px;
    margin-bottom: 16px;
    border-bottom: 1px solid #e6e9f0;
  }

  .action {
    margin-top: 16px;
    border: 1px solid #cad9f3;
    padding: 14px 18px;
    border-radius: 8px;
    cursor: pointer;
    display: flex;
    align-items: center;
  }
  .action.active {
    border: 1px solid #262758;
  }

  .form-check-input {
    margin-right: 16px;
    width: 20px;
    height: 20px;
  }
  .errorVal {
    margin-top: 12px;
    font-size: 14px;
    color: #cc0000;
    display: flex;
    align-items: center;
  }

  .form-check-input:checked[type='radio'] {
    background-image: url(${circle});
    background-color: #fff;
    box-shadow: none;
  }

  .buttonsDiv {
    margin-top: 32px;
    border-top: 1px solid #e6e9f0;
    padding-top: 32px;
  }

  .primaryButton {
    height: 48px;
    width: 168px;
    margin-left: 30px;
  }
  .fields {
    margin: 20px 0px;
  }
  .textarea {
    width: 100%;
    height: 136px;
    border: 1px solid #cad9f3;
    border-radius: 8px;
    padding: 14px 16px 4px;
  }
  .secondaryButton {
    border: none;
    background: none;
    font-size: 16px;
    color: #262758;
    font-weight: 600;
  }
  .removeButton {
    height: 64px;
    width: 262px;
    color: #f5f7fc;
    border-radius: 8px;
    border: none;
    background: #cc0000;
    margin-left: 32px;
    font-weight: 600;
  }

  .modalContainer {
    max-width: 580px;
    height: 420px;
  }
  .DeleteButton {
    height: 64px;
    width: 262px;
    border-radius: 8px;
    border: none;
    margin-left: 30px;
    color: #f5f7fc;
    background: #cc0000;
  }
  .SubmitButton {
    width: 219px;
    height: 48px;
    color: #f5f7fc;
    padding: 0 20px;
    border-radius: 8px;
    border: none;
    background: #262758;
    margin-left: 30px;
  }
`;

export const StyledCard = styled.div`
  @keyframes rightTransit {
    0% {
      transform: translate(100%);
    }
    40% {
      transform: translate(0px);
    }
    60% {
      transform: translate(0px);
    }
    100% {
      transform: translate(100%);
    }
  }

  position: fixed;
  top: 180px;
  right: 0;
  padding: 18px 18px 18px 26px;
  background: #f8e3df;
  border-radius: 8px 0px 0px 8px;
  font-size: 14px;
  color: #212121;
  z-index: 10000;
  animation-duration: 3s;
  animation-name: rightTransit;

  .border {
    position: absolute;
    color: #58c070;
    width: 10px;
    background: #cc0000;
    border-radius: 8px 0px 0px 8px;
    top: 0px;
    left: 0px;
    height: 100%;
  }
  img {
    margin-right: 12px;
  }
`;

export const WarningCardStyle = styled.div`
  @keyframes rightTransit {
    0% {
      transform: translate(100%);
    }
    40% {
      transform: translate(0px);
    }
    60% {
      transform: translate(0px);
    }
    100% {
      transform: translate(100%);
    }
  }

  position: fixed;
  top: 180px;
  right: 0;
  padding: 18px 18px 18px 26px;
  background: #fcf2cf;
  border-radius: 8px 0px 0px 8px;
  font-size: 14px;
  color: #212121;
  z-index: 3;
  animation-duration: 3s;
  animation-name: rightTransit;

  .border {
    position: absolute;
    color: #58c070;
    width: 10px;
    background: #fac712;
    border-radius: 8px 0px 0px 8px;
    top: 0px;
    left: 0px;
    height: 100%;
  }
  img {
    margin-right: 12px;
  }
`;

export const SuccessCardStyle = styled.div`
  @keyframes rightTransit {
    0% {
      transform: translate(100%);
    }
    40% {
      transform: translate(0px);
    }
    60% {
      transform: translate(0px);
    }
    100% {
      transform: translate(100%);
    }
  }

  position: fixed;
  top: 180px;
  right: 0;
  padding: 18px 18px 18px 26px;
  background: #d4fad4;
  border-radius: 8px 0px 0px 8px;
  font-size: 14px;
  color: #262758;
  z-index: 3;
  animation-duration: 3s;
  animation-name: rightTransit;

  .border {
    position: absolute;
    color: #58c070;
    width: 10px;
    background: #58c070;
    border-radius: 8px 0px 0px 8px;
    top: 0px;
    left: 0px;
    height: 100%;
  }
  img {
    margin-right: 12px;
  }
`;

export const PolicyInfo = styled.div`
  .entireModal {
    width: 100%;
  }
  .cross-btn {
    position: absolute;
    background: #ffffff;
    right: 0;
    top: 0;
    border: none;
    padding: 20px 22px;
  }
  .content-div {
    padding-right: 15px;
    height: 90vh;
    overflow-y: auto;
    overflow-x: hidden;
    padding-bottom: 185px;
    &::-webkit-scrollbar {
      width: 4px;
      height: 159px;
    }

    &::-webkit-scrollbar-track {
      border-radius: 8px;
    }

    &::-webkit-scrollbar-thumb {
      background: #e6e9f0;
    }
  }
  .fields {
    width: 100%;
    margin-top: 16px;
  }
  .OpportunityDetails {
    width: 100%;
    border: 1px solid #e6e9f0;
    border-radius: 12px;
    height: 112px;
    padding: 20px 32px;
  }
  .imageCard {
    background: #ecf4f9;
    border-radius: 50%;
    padding: 0px 5px;
    margin-right: 20px;
  }
  .Oppdetails {
    padding: 0 12px;
    border-right: 1px solid #e6e9f0;
  }
  .carrier-info {
    font-size: 18px;
    font-weight: 600;
    margin-top: 42px;
  }
  .radio-container {
    display: flex;
    align-items: center;
    padding-left: 15px;
    height: 48px;
    background: #ffffff;
    border: 1px solid #cad9f3;
    border-radius: 8px;
    width: 100%;

    .form-check-input {
      width: 18px;
      height: 18px;
      margin-right: 10px;
    }
    .form-check-input:checked[type='radio'] {
      background-image: url(${circle});
      background-color: #fff;
      box-shadow: none;
    }
  }
  .warning {
    margin-right: 10px;
  }

  .buttonsDiv {
    border-top: 1px solid #e6e9f0;
    position: fixed;
    width: 100%;
    bottom: 0;
    left: 0;
    background: #fff;
    z-index: 2;
    padding: 20px;
  }
  .cancelButton {
    justify-content: end;
    background: none;
    color: #262758;
  }
  .DraftButton {
    background: #ecf4f9;
    color: #262758;
    .qualifyArrow {
      filter: invert(0%) sepia(14%) saturate(2240%) hue-rotate(295deg)
        brightness(0%) contrast(103%);
    }
  }
  .status {
    width: 102px;
    height: 20px;
    font-size: 10px;
    text-align: center;
    border-radius: 16px;
    padding: 4px 0;
    margin-left: 10px;
    font-weight: 600;
  }
  .statusActive {
    background: #d4fad4;
    color: #3d8a68;
  }
  .statusInactive {
    background: #f9e4d2;
    color: #9a0a0d;
  }
  .statusInvited {
    background: #fcf2cf;
    color: #914123;
  }
  .statusNotInvited {
    background: #e6e9f0;
    color: #191919;
  }
  .textarea {
    width: 100%;
    margin-top: 16px;
  }
  .addNotesButton {
    margin-top: 40px;
    border: none;
    background: #fff;
  }
  .errorVal {
    margin-top: 12px;
    font-size: 14px;
    color: #cc0000;
    display: flex;
    align-items: center;

    .warning {
      width: 20px;
      margin-right: 10px;
    }
  }
  .switch {
    position: relative;
    display: inline-block;
    width: 44px;
    height: 24px;
    float: right;
  }

  .switch input {
    opacity: 0;
    width: 0;
    height: 0;
  }

  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: 0.4s;
    transition: 0.4s;
  }

  .slider:before {
    position: absolute;
    content: '';
    height: 18px;
    width: 18px;
    left: 3px;
    bottom: 3px;
    background-color: white;
    -webkit-transition: 0.4s;
    transition: 0.4s;
  }

  input:checked + .slider {
    background-color: #58c070;
  }

  input:focus + .slider {
    box-shadow: 0 0 1px #58c070;
  }

  input:checked + .slider:before {
    -webkit-transform: translateX(20px);
    -ms-transform: translateX(20px);
    transform: translateX(20px);
  }

  .slider.round {
    border-radius: 24px;
  }

  .slider.round:before {
    border-radius: 50%;
  }
  .documnetsAdd {
    padding-bottom: 32px;
    margin-top: 50px;
    font-weight: 600;
    font-size: 18px;
    border-bottom: 1px solid #e6e4e4;
  }
  .contact-info {
    color: #000000;
    font-size: 16px;
    line-height: 24px;
    margin-top: 15px;
  }
  @media screen and (min-width: 576px) {
    paddong: 0;
    .cross-btn {
      padding: 26.68px 46px;
    }
    .fields {
      width: 310px;

      margin-top: 16px;
    }
    .firstField {
      margin-top: 20px;
    }
  }
  .nextButton {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: #262758;
    color: #f5f7fc;
    font-weight: 600;
    border-radius: 8px;
    min-width: 188px;
    height: 48px;
    margin-right: 0;
    padding: 0 16px;
    border: none;
    img {
      margin-left: 8px;
    }
    &:disabled {
      background: #d1d6e5;
    }
  }
  .cancelButton {
    justify-content: end;
    background: none;
    color: #262758;
  }
  .DraftButton {
    background: #ecf4f9;
    color: #262758;
    .qualifyArrow {
      filter: invert(0%) sepia(14%) saturate(2240%) hue-rotate(295deg)
        brightness(0%) contrast(103%);
    }
  }
  @media screen and (min-width: 576px) {
    .nextButton {
      margin-right: 40px;
    }
  }
  @media screen and (min-width: 1200px) {
    padding: 30px 100px;

    .content-div {
      margin-top: 28px;
    }
    .cross-btn {
      padding: 56px 44px;
    }
    .fields {
      width: 420px;
      margin-top: 24px;
    }

    .opportunityName {
      font-size: 20px;
    }

    .firstDe {
      padding-left: 0;
    }
    .premium {
      color: #3d8a68;
      font-weight: 600;
    }
    .firstField {
      margin-top: 24px;
    }
  }
  @media screen and (min-width: 1900px) {
    padding: 22px 280px;
    .form-check-input {
      width: 24px;
      height: 24px;
      margin-right: 10px;
    }
    .radio-container {
      padding: 19px 15px;
    }
    .fields {
      width: 576px;
      margin-top: 14px;
    }
    .firstField {
      margin-top: 24px;
    }
    .imageCard {
      padding: 10px 8px;
      width: 56px;
      height: 56px;
    }
  }
  @media screen and (min-width: 2800px) {
    padding: 22px 360px;
  }
`;

export const StyledStageModal = styled.div`
  width: 979px;
  .opp-pic {
    background: #ecf4f9;
    height: 32px;
    width: 32px;
    border-radius: 50%;
    margin: 0 8px 0 13px;
    img {
      width: 20px;
    }
  }

  h2 {
    font-size: 16px;
  }

  .status {
    background: #f9e4d2;
    padding: 2px 16px;
    border-radius: 16px;
    color: #9a0a0d;
    font-weight: 600;
    font-size: 12px;
    margin-left: 16px;
  }

  .moveButton {
    width: 140px;
    padding: 8px 20px;
    background: #e6e9f0;
    border: 0;
    border-radius: 8px;
    font-size: 14px;
    justify-content: space-between;
    align-items: center;
    color: #262758;
    font-weight: 600;
  }

  .quotingButton {
    justify-content: space-between;
    align-items: center;
    padding: 8px 20px;
    font-size: 14px;
    width: 172px;
  }

  .action-button {
    border: 0;
    width: 32px;
    height: 32px;
    border-radius: 8px;
    margin-right: 25px;
    background: #f5f7fc;

    img {
      width: 16px;
    }
  }

  .tab-div {
    border-bottom: 1px solid #e6e9f0;
    margin: 80px 0 24px 0;
  }

  .tab {
    background: 0;
    border: 0;
    margin-right: 9px;
    padding-bottom: 8px;
    font-size: 14px;
  }

  .count {
    background: #e6e9f0;
    border-radius: 8px;
    margin-left: 4px;
    padding: 2px 8px;
    color: #191919;
    font-size: 12px;
    font-weight: 600;
  }

  .active {
    font-weight: 600;
    border-bottom: 3px solid #262758;
    position: relative;
    .count {
      background: #c1dbeb;
    }
  }

  .tab-dropdown {
    position: absolute;
    background: #fff;
    z-index: 1;
    top: 35px;
    left: 0;
    box-shadow: 0px 1px 4px 0px #26275833;
    border-radius: 8px;
    padding: 10px;

    .tab {
      font-size: 12px;
      padding-top: 10px;
    }
    .active {
      border: none;
    }
    .count {
      background: #e6e9f0;
    }
  }
  .profile-pic {
    margin-left: -16px;
    background: #262758;
    border-radius: 46px;
    margin-left: 12px;
    color: #fff;
    text-transform: uppercase;
    margin-right: 12px;
    border: 1px solid #fff;
    width: 56px;
    height: 56px;
  }

  .scroll-bar {
    height: 60vh;
    padding: 0 6px 40px 0;
    overflow-y: auto;
    overflow-x: hidden;
    &::-webkit-scrollbar {
      width: 4px;
    }

    &::-webkit-scrollbar-track {
      border-radius: 8px;
    }

    &::-webkit-scrollbar-thumb {
      background: #e6e9f0;
    }
  }

  .opp-fields {
    margin-bottom: 16px;
  }

  .errorVal {
    margin-top: 8px;
    font-size: 12px;
    color: #cc0000;
    display: flex;
    align-items: center;

    img {
      margin-right: 5px;
    }
  }

  .buttonsDiv {
    position: fixed;
    width: 100%;
    left: 0;
    bottom: 0;
    padding: 20px 48px;
    border-top: 1px solid #e6e9f0;
    justify-content: flex-end;
    background: #fff;
    button {
      font-size: 16px;
    }
    .saveButton {
      width: 140px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 12px 20px;
      margin-left: 40px;
    }
  }

  @media screen and (min-width: 576px) {
    .status {
      margin-left: 12px;
    }

    .tab-div {
      border-bottom: 1px solid #e6e9f0;
      margin: 80px 0 24px 0;
    }

    .tab {
      margin-right: 40px;
      padding-bottom: 10px;
    }

    .count {
      padding: 2px 10px;
    }

    .opp-fields {
      width: 420px;
    }

    .errorVal {
      font-size: 14px;
    }

    .buttonsDiv {
      position: fixed;
      width: 100%;
      left: 0;
      bottom: 0;
      padding: 20px 48px;
      border-top: 1px solid #e6e9f0;
      justify-content: flex-end;
      background: #fff;
      button {
        font-size: 16px;
      }
      .saveButton {
        width: 140px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 12px 20px;
        margin-left: 40px;
      }
    }
  }

  @media screen and (min-width: 1200px) {
    width: 883px;

    h2 {
      font-size: 24px;
    }

    .action-button {
      width: 40px;
      height: 40px;

      img {
        width: 20px;
      }
    }

    .tab-div {
      margin: 32px 0 24px 0;
    }

    .tab {
      font-size: 16px;
    }

    .buttonsDiv {
      position: absolute;
      padding: 20px 0 0 0;
      .saveButton {
        width: 220px;
      }
    }
  }
  @media screen and (min-width: 1900px) {
    width: 1200px;

    h2 {
      font-size: 28px;
    }

    .status {
      padding: 4px 18px;
    }

    .action-button {
      width: 48px;
      height: 48px;
      margin-right: 36px;
    }

    .tab-div {
      margin-bottom: 31px;
    }

    .tab {
      margin-right: 60px;
    }

    .opp-fields {
      width: 576px;
      margin-bottom: 20px;
    }
  }

  @media screen and (max-width: 1200px) {
    .header-div {
      position: fixed;
      border-bottom: 1px solid #e6e9f0;
      padding: 24px 20px;
      width: 100%;
      left: 0;
      top: 0;
    }

    @media screen and (min-width: 576px) {
      .header-div {
        padding: 24px 48px;
      }
    }
  }
`;
