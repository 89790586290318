import 'react-datepicker/dist/react-datepicker.css';
import SelectField from '../SelectField';
import InputField from '../InputField';
import DateField from '../DateField';
import { CheckBoxDIv } from './style';
import SelectFieldEdit from '../SelectFieldEdit';
import DateFieldEdit from '../DateFieldEdit';
import InputFieldEdit from '../DetailsEditFormInput';

const CustomFieldInput = ({
  item,
  register,
  className,
  control,
  value,
  isEdit,
  disabled,
}: {
  item: any;
  register: Function;
  className?: string;
  control: any;
  value: any;
  isEdit?: boolean;
  disabled?: boolean;
}) => {
  return (
    <>
      {isEdit ? (
        <>
          {(item.FieldType?.name === 'TEXT_INPUT' ||
            item.FieldType?.name === 'NUMBER_INPUT') && (
            <InputFieldEdit
              name={item.uuid}
              type={item.FieldType?.name === 'TEXT_INPUT' ? 'text' : 'number'}
              placeholder={item.name}
              register={register}
              className={`${value && 'filled'}`}
              disabled={disabled}
            />
          )}
          {item.FieldType?.name === 'CURRENCY_INPUT' && (
            <InputFieldEdit
              name={item.uuid}
              type='number'
              placeholder={item.name}
              register={register}
              className={`${value && 'filled'}`}
              prefix='$'
              disabled={disabled}
            />
          )}
          {item.FieldType?.name === 'DATEPICKER' && (
            <DateFieldEdit
              name={item.uuid}
              placeholder={item.name}
              control={control}
              disabled={disabled}
            />
          )}
          {item.FieldType?.name === 'TIME' && (
            <DateFieldEdit
              name={item.uuid}
              placeholder={item.name}
              control={control}
              isTime={true}
              disabled={disabled}
            />
          )}
          {item.FieldType?.name === 'YES/NO_INPUT' && (
            <CheckBoxDIv className='edit-check'>
              <div className=' input-container w-100 d-flex form-appear-check h-100'>
                <input
                  type='checkbox'
                  className='form-check-input'
                  name={item.uuid}
                  {...register(item.uuid)}
                  id={item.uuid}
                  disabled={disabled}
                />
                <label className='form-check-label' htmlFor={item.uuid}>
                  {item.name}
                </label>
              </div>
            </CheckBoxDIv>
          )}
        </>
      ) : (
        <>
          {(item.FieldType?.name === 'TEXT_INPUT' ||
            item.FieldType?.name === 'NUMBER_INPUT') && (
            <InputField
              name={item.uuid}
              type={item.FieldType?.name === 'TEXT_INPUT' ? 'text' : 'number'}
              placeholder={item.name}
              register={register}
              className={`${value && 'filled'}`}
              disabled={disabled}
            />
          )}
          {item.FieldType?.name === 'CURRENCY_INPUT' && (
            <InputField
              name={item.uuid}
              type='number'
              placeholder={item.name}
              register={register}
              className={`${value && 'filled'}`}
              prefix='$'
              disabled={disabled}
            />
          )}
          {item.FieldType?.name === 'DATEPICKER' && (
            <DateField
              name={item.uuid}
              placeholder={item.name}
              control={control}
              value={value}
              disabled={disabled}
            />
          )}
          {item.FieldType?.name === 'TIME' && (
            <DateField
              name={item.uuid}
              placeholder={item.name}
              control={control}
              value={value}
              isTime={true}
              disabled={disabled}
            />
          )}
          {item.FieldType?.name === 'YES/NO_INPUT' && (
            <CheckBoxDIv className={`${value ? 'filled' : ''}`}>
              <div className=' input-container w-100 d-flex form-appear-check h-100'>
                <input
                  type='checkbox'
                  className='form-check-input'
                  name={item.uuid}
                  {...register(item.uuid)}
                  id={item.uuid}
                  disabled={disabled}
                />
                <label className='form-check-label' htmlFor={item.uuid}>
                  {item.name}
                </label>
              </div>
            </CheckBoxDIv>
          )}
        </>
      )}

      {isEdit ? (
        <>
          {item.FieldType?.name === 'SINGLE_CHOICE' && (
            <SelectFieldEdit
              name={item.uuid}
              control={control}
              placeholder={item.name}
              options={item.data.options.map((item: any) => {
                return { value: item.order, label: item.value };
              })}
              isEdit={true}
              disabled={disabled}
            />
          )}
          {item.FieldType?.name === 'MULTIPLE_CHOICE' && (
            <SelectFieldEdit
              name={item.uuid}
              control={control}
              placeholder={item.name}
              options={item.data.options.map((item: any) => {
                return { value: item.order, label: item.value };
              })}
              isMulti={true}
              isEdit={true}
              disabled={disabled}
            />
          )}
        </>
      ) : (
        <>
          {item.FieldType?.name === 'SINGLE_CHOICE' && (
            <SelectField
              name={item.uuid}
              control={control}
              placeholder={item.name}
              options={item.data.options.map((item: any) => {
                return { value: item.order, label: item.value };
              })}
              disabled={disabled}
            />
          )}
          {item.FieldType?.name === 'MULTIPLE_CHOICE' && (
            <SelectField
              name={item.uuid}
              control={control}
              placeholder={item.name}
              options={item.data.options.map((item: any) => {
                return { value: item.order, label: item.value };
              })}
              isMulti={true}
              disabled={disabled}
            />
          )}
        </>
      )}
    </>
  );
};

export default CustomFieldInput;
