import axios from './axios';

const fetchQuoteStages = (successCB: Function) => {
  return axios.get(`/quote/stage`).then((res) => {
    successCB(res.data);
    return res;
  });
};

const fetchQuoteStatus = (successCB: Function) => {
  return axios.get(`/quote/status`).then((res) => {
    successCB(res.data);
    return res;
  });
};

const fetchQuoteTypes = (successCB: Function) => {
  return axios.get(`/quote/type`).then((res) => {
    successCB(res.data);
    return res;
  });
};

const fetchCoverageTypes = (successCB: Function) => {
  return axios.get(`/quote/coverage-type`).then((res) => {
    successCB(res.data);
    return res;
  });
};

const addQuote = (data: any, successCB: Function, errorCB: Function) => {
  return axios
    .post('/quote', data, {
      headers: { 'Content-Type': 'multipart/form-data' },
    })
    .then(
      (res) => {
        successCB(res);
        return res;
      },
      (error) => {
        errorCB('Quote not found');
        return error.res;
      }
    );
};

const getQuotes = (
  opportunityUUID: string,
  successCB: Function,
  sortBy: string,
  orderBy: string,
  searchBy: string,
  limit: number,
  offset: number,
  getQuote?: string,
  quoteTypeUUID?: string,
  quoteStageUUID?: string[],
  quoteStatusUUID?: string,
  coverageTypeUUID?: string[]
) => {
  return axios
    .get(`/quote`, {
      params: {
        sortBy,
        searchBy,
        orderBy,
        limit,
        offset,
        opportunityUUID,
        getQuote,
        quoteTypeUUID,
        quoteStageUUID,
        quoteStatusUUID,
        coverageTypeUUID,
      },
    })
    .then((res) => {
      successCB(res.data);
      return res;
    });
};

const updateQuoteDetails = (
  data: any,
  uuid: string,
  successCB: Function,
  errorCB: Function
) => {
  return axios.patch(`/quote/${uuid}`, data).then(
    (res) => {
      successCB(res.data);
      return res;
    },
    (error) => {
      errorCB('Please try again.');
      return error.res;
    }
  );
};

const getQuoteDetails = (uuid?: string, successCB?: Function) => {
  return axios.get(`/quote/${uuid}`).then(
    (res) => {
      if (successCB) {
        successCB(res);
      }
      return res;
    },
    (error) => {
      return error.res;
    }
  );
};

const deleteQuote = (uuid: String) => {
  return axios.delete(`/quote/${uuid}`);
};

export {
  fetchQuoteStages,
  fetchQuoteTypes,
  fetchCoverageTypes,
  addQuote,
  getQuotes,
  updateQuoteDetails,
  getQuoteDetails,
  fetchQuoteStatus,
  deleteQuote,
};
