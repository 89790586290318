import sort from '../../../../assets/logo/sort.svg';
import { TableDiv } from '../../../../styles/TableDiv';
import { CardDiv } from '../../../../styles/CardDiv';
import Skeleton from 'react-loading-skeleton';
import EmptyResults from '../../../MyBook/components/Empty';
import { useSelector } from 'react-redux';
import { CustomFieldsSliceState } from '../../../../store/customFields/customFieldsSlice';

const TableSkeleton = () => {
  const loadingStatus = useSelector(
    (state: { customFields: CustomFieldsSliceState }) =>
      state.customFields?.status
  );
  return (
    <TableDiv>
      <thead>
        <tr>
          <th className='agencyNameHead'>
            <div
              style={{ cursor: 'pointer' }}
              className='d-flex justify-content-between w-100'
            >
              <span>Field Name</span>
              <img className='sort' src={sort} alt='sort' />
            </div>
          </th>
          <th className='statusContainerHead'>Type</th>
          <th className='dateFieldHead'>Choices</th>
          <th className='opportunityFieldHead'>Appear in form</th>
          <th className='actionFieldHead'>Actions</th>
        </tr>
      </thead>
      <tbody>
        {loadingStatus === 'loading' ? (
          <>
            {[1, 2, 3, 4].map((index) => (
              <CardDiv key={index}>
                {[1, 2, 3, 4].map((item) => (
                  <td key={item}>
                    <Skeleton height={24} />
                  </td>
                ))}
                <td className='d-none d-xl-table-cell'>
                  <Skeleton height={24} />
                </td>
              </CardDiv>
            ))}
          </>
        ) : (
          <tr className='empty-div'>
            <td colSpan={5} className='d-xl-none'>
              <EmptyResults name='custom field' />
            </td>
            <td colSpan={7} className='d-none d-xl-table-cell'>
              <EmptyResults name='custom field' />
            </td>
          </tr>
        )}
      </tbody>
    </TableDiv>
  );
};

export default TableSkeleton;
