import React from 'react';
import Select, { components } from 'react-select';
import { Controller } from 'react-hook-form';
import { SelectStyle } from './styles';
const { ValueContainer, Placeholder } = components;

// @ts-ignore
export const CustomValueContainer = ({ children, ...props }) => {
  return (
    // @ts-ignore
    <ValueContainer {...props}>
      {/*
      // @ts-ignore */}
      <Placeholder {...props} isFocused={props.isFocused}>
        {props.selectProps.placeholder}
      </Placeholder>
      {React.Children.map(children, (child) =>
        child && child.type !== Placeholder ? child : null
      )}
    </ValueContainer>
  );
};
interface IProps {
  name: string;
  [x: string]: any;
  placeholder: string;
  options?: any[];
  control: any;
  disabled?: boolean;
}

export const customSelectStyles = {
  option: (provided: object, state: any) => ({
    ...provided,
    color: '#262758',
    fontWeight: state.isSelected ? '600' : '400',
    background: '#fff',
    fontSize: '16px',
    borderBottom: ' 1px solid #E6E9F0',
  }),
  menu: (provided: object, state: any) => ({
    ...provided,
    borderRadius: '8px',
    padding: '4px 16px',
    boxShadow: '0px 2px 8px 0px #26275833',
    backgroundColor: '#fff',
    border: state.isSelected ? '#3c498a' : '#cad9f3',
  }),
  dropdownIndicator: (base: any, state: any) => ({
    ...base,
    transform: state.isFocused ? 'rotate(180deg)' : null,
    display: state.isDisabled
      ? 'block'
      : (state.hasValue || state.selectProps.inputValue) && 'none',
    marginRight: '3px',
  }),
  indicatorSeparator: () => ({ display: 'none' }),
  control: (provided: object, state: any) => ({
    ...provided,
    '&:hover': {
      borderColor: 'none',
      backgroundColor: '#f5f7fc',
    },
    display: 'flex',
    boxShadow: 'none',
    borderRadius: '8px',

    height: '60px',
    backgroundColor: state.isFocused ? '#f5f7fc' : '#fff',
    border: 'none',
    '@media only screen and (min-width: 1900px)': {
      height: '64px',
    },
    '@media only screen and (min-width: 2800px)': {
      height: '128px',
    },
  }),
  singleValue: (provided: any, state: any) => {
    return {
      ...provided,
      paddingTop: (state.hasValue || state.selectProps.inputValue) && '14px',
      color: '#191919',
      fontWeight: 600,
    };
  },
  valueContainer: (provided: any, state: any) => ({
    ...provided,
    overflow: 'visible',
  }),
  placeholder: (provided: any, state: any) => ({
    ...provided,
    position: 'absolute',
    color: '#5A5F70',
    top: '10px',
    fontSize: (state.hasValue || state.selectProps.inputValue) && 16,
  }),
};

const SelectField = ({
  name,
  placeholder,
  options,
  control,
  disabled,
  ...props
}: IProps) => {
  return (
    <div>
      <SelectStyle>
        <div className='SlelectField'>
          <Controller
            control={control}
            name={name}
            render={({ field }) => (
              <Select
                classNamePrefix={'select'}
                styles={customSelectStyles}
                options={options}
                placeholder={placeholder}
                components={{ ValueContainer: CustomValueContainer }}
                isClearable
                isDisabled={disabled}
                {...field}
                isMulti
              />
            )}
          />
        </div>
      </SelectStyle>
    </div>
  );
};

export default SelectField;
